/* React modules */
import { useCallback } from 'react';

/* Our modules */
import { PreviewData } from 'modules/ticket/ticket.types';
import useStores from 'common/hooks/useStores';
import { Accordion, Icon } from 'components';
import TicketStatus from 'modules/ticket/ui/TicketStatus';
import { formatDate, formatTime, toDate } from 'libs/datetime';
import './StatusAccordion.scss';

/* 3rd Party modules */
// import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

interface StatusAccordionRecordProps {
  value: string | number;
  label: string;
}

export interface StatusAccordionProps {
  ticket: any;
}

// const StatusAccHeader = observer(
const StatusAccHeader = ({
  toggleAccordion,
  shortUuid,
  id,
  date,
  status,
}: any) => {
  const { overlayStore, ticketsStore } = useStores();
  const { t } = useTranslation();

  const openPreview = useCallback(
    (event) => {
      event.stopPropagation();
      ticketsStore
        .getTicket(shortUuid, { disableTimer: true })
        .then((success: PreviewData | null | undefined) => {
          if (success) {
            overlayStore.openModal(<TicketStatus />, {
              className: 'visibe-tablet-down',
              wrapClassName: 'ticket-preview-modal-wrap',
              name: 'ticket-preview',
              width: 'large',
            });
          }
        });
    },
    [shortUuid, overlayStore, ticketsStore]
  );

  const setIdColor = () => {
    if (status === 'Dobitni') return 'text-green-600';
    if (status === 'Gubitni') return 'text-red';
  };

  return (
    <div className="status-accordion__header" onClick={toggleAccordion}>
      <div className="status-accordion__section">
        <div className="mb-2 text-strong">{t('slips.ticket-id-search')}</div>
        <div className="text-strong text-ellipsis">{t('slips.payin-time')}</div>
      </div>
      <div className="status-accordion__section mr-1">
        <div className={`text-ellipsis mb-2 ${setIdColor()}`}>{shortUuid}</div>
        <div className="d-flex">
          <div className="mr-2">{formatDate(date)}</div>
          <div>{formatTime(date)}</div>
        </div>
      </div>
      <div className="status-accordion__icon" onClick={openPreview}>
        <Tooltip
          overlay="Vidi tiket"
          overlayClassName="tooltip"
          placement="bottom"
        >
          <span>
            <Icon name="magnifyingGlass" variant="light" size="large" />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
//);

const StatusAccordionRecord = ({
  value,
  label,
}: StatusAccordionRecordProps) => {
  return (
    <div className="status-accordion__record">
      <div className="text-strong">{label}</div>
      <div>{`${value} EUR`}</div>
    </div>
  );
};

const StatusAccordion = ({ ticket }: StatusAccordionProps) => {
  const { t } = useTranslation();
  const { id, shortUuid, bettingTime, bettingAmount, maxWinningAmount } =
    ticket;
  const ticketDate = toDate(bettingTime.seconds);

  return (
    <div className="status-accordion">
      <Accordion
        customHeaderProps={{ id, shortUuid, date: ticketDate }}
        customHeader={StatusAccHeader}
        testId="ticket-status-acc"
      >
        <div className="status-accordion__body">
          <StatusAccordionRecord
            label={t('slips.payin')}
            value={bettingAmount.toFixed(2)}
          />
          <StatusAccordionRecord
            label={t('payments.potential-payout')}
            value={maxWinningAmount.toFixed(2)}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default StatusAccordion;
