/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { QuickGame } from 'modules/quick-games/quick-games.types';
import QuickGamesService from 'modules/quick-games/quick-games.service';
import useQuery from 'libs/useQuery';
import { logger } from 'libs/common-helpers';
import './QuickGamesPlaceholder.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const QuickGamesPlaceholder = observer(() => {
  const service = new QuickGamesService();
  const { t } = useTranslation();
  const { authStore } = useStores();
  const [error, setError] = useState('');
  const [gameName, setGameName] = useState('');
  const [gameUrl, setGameUrl] = useState('');
  let queryParams = useQuery();
  const [lang, setLang] = useState<string | null>('');

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  const handleBadParams = () => {
    setError(t('errors.system-error'));
  };

  const init = async (
    token: string | null,
    gameName: null | QuickGame,
    lang: string
  ) => {
    if (token) {
      authStore.setToken(token);
    }

    if (gameName) {
      try {
        const url = await service.getGameUrl(gameName, {
          lang,
          isDemo: !token,
        });
        setGameUrl(url);
        setGameName(gameName);
      } catch (exception: any) {
        handleBadParams();

        logger('QuickGamesPlaceholder -> init -> exception', exception);
      }
    }
  };

  useEffect(() => {
    const token = getParam('token') || '';
    const gameName = getParam('game_name');
    setLang(getParam('lang'));

    if (!gameName) {
      handleBadParams();
    } else {
      init(
        token,
        gameName as QuickGame,
        lang && lang.toLocaleLowerCase() === 'en' ? 'en' : 'me'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onIframeLoad = () => {
    if (service.isSpribe(gameName as QuickGame)) return;
    if (window.innerWidth < 769) {
      // @ts-ignore
      iFrameResize({
        checkOrigin: false,
        autoResize: false,
        initCallback: function (iframe: HTMLIFrameElement) {
          if (iframe) {
            iframe.style.height = window.innerHeight + 'px';
          }

          /*
          This is removed for now, as the same height for the iframe is already set
          and also to prevent memory leak because there is no appropriate removeEventListener call to do the cleanup.

          window.addEventListener("resize", function(e) {
              iframe.style.height = window.innerHeight + "px";
          });
          */
        },
        messageCallback: function (data: any) {
          if (data.message && data.message.type) {
            switch (data.message.type) {
              case 'loginRequired':
                if (lang === 'en')
                  toast.error('Please, return to the app to log in');
                else
                  toast.error(
                    'Molimo Vas, vratite se na aplikaciju kako bi se ulogovali'
                  );
                break;
            }
          }
        },
      });
    } else {
      // @ts-ignore
      iFrameResize({
        checkOrigin: false,
        heightCalculationMethod: 'lowestElement',
        messageCallback: function (data: any) {
          if (data.message && data.message.type) {
            switch (data.message.type) {
              case 'loginRequired':
                if (lang === 'en')
                  toast.error('Please, return to the app to log in');
                else
                  toast.error(
                    'Molimo Vas, vratite se na aplikaciju kako bi se ulogovali'
                  );
                break;
            }
          }
        },
      });
    }
  };

  return (
    <div className="quick-game-placeholder">
      {gameUrl ? (
        <iframe
          title="number-games-mobile"
          id="seven-plugin"
          src={gameUrl}
          scrolling="yes"
          width="100%"
          height="100%"
          onLoad={() => onIframeLoad()}
          style={{ border: 'none' }}
        />
      ) : (
        <div className="quick-game-placeholder__error text-center">{error}</div>
      )}
    </div>
  );
});

export default QuickGamesPlaceholder;
