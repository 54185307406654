/* React modules */
import { useEffect, Fragment } from 'react';

/* Our modules */
import { EVENT_TYPE, EventType } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import Event from 'modules/sports/store/event.store';
import {
  TableRow,
  TableSeparatorData,
} from 'modules/offer/services/offer-builder.service';
import { formatFootballPlayerOutcomes } from 'modules/offer/services/offer-helpers.service';
import {
  getEventsCountPerType,
  getCompetitionEventsCount,
  getDateEventsCount,
} from 'modules/offer/services/offer-helpers.service';
import OfferHeader from 'modules/offer/ui/Offer/OfferHeader';
import OfferRow from 'modules/offer/ui/Offer/OfferRow';
import Footer from 'components/Footer';
import NoEvents from 'components/NoEvents';
import Loader from 'components/Loader';
import useWindowSize from 'libs/viewport';
import './Offer.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso } from 'react-virtuoso';
import AutoSizer from 'react-virtualized-auto-sizer';

interface SportOffer {
  sport: string;
  type: EventType;
  events: Array<Event>;
  rows: TableRow[];
}

interface RenderGroupContentProps {
  index: number;
  tables: SportOffer[];
}

interface RenderEventProps {
  index: number;
  groupIndex: number;
  tables: SportOffer[];
}

function RenderGroupContent({ index, tables }: RenderGroupContentProps) {
  const data = tables[index];
  const { events } = data;
  const event = events[0];
  let footballOutcomes: any = [];

  if (data.sport === 'fudbal' && event.type === EVENT_TYPE.PLAYER) {
    footballOutcomes = formatFootballPlayerOutcomes(events);

    return (
      <OfferHeader
        key={`RenderGroupContent-OfferHeader-1-${index}`}
        data={data}
        periods={event}
        footballOutcomes={footballOutcomes}
        playerFootball={true}
      />
    );
  }

  return (
    <OfferHeader
      key={`RenderGroupContent-OfferHeader-2-${index}`}
      data={data}
      periods={event}
      footballOutcomes={footballOutcomes}
      basketballPlayer={true}
    />
  );
}

const countPrevGroupsRows = (
  currentGroupIndex: number,
  groups: SportOffer[]
) => {
  return groups
    .slice(0, currentGroupIndex)
    .reduce((count: number, group: SportOffer) => count + group.rows.length, 0);
};

const RenderEvent = observer(
  ({ index, groupIndex, tables }: RenderEventProps) => {
    const { t } = useTranslation();

    const { sportsStore, offerFilters, offerSort } = useStores();
    const { allEventsLoaded, offerCounters } = sportsStore;

    const prevGroupsRowsCount = countPrevGroupsRows(groupIndex, tables);
    const rowIndex = groupIndex > 0 ? index - prevGroupsRowsCount : index;
    const table = tables[groupIndex];

    const { rows } = table;

    const row = rows[rowIndex];

    if (row && row.type === 'separator') {
      const prevRow = rows[rowIndex - 1];

      let streamEventsCount = 0;
      let localEventsCount = 0;

      if (prevRow !== undefined && offerSort.activeSort === 1) {
        //@ts-ignore
        streamEventsCount = getCompetitionEventsCount(
          // @ts-ignore
          table.sportId,
          // @ts-ignore
          prevRow.data.locationId,
          // @ts-ignore
          prevRow.data.competitionId,
          offerCounters,
          offerFilters.timeFilter
        );
        //@ts-ignore
        localEventsCount = table.groupedEvents[prevRow.group].length;
      }

      if (prevRow !== undefined && offerSort.activeSort === 0) {
        //@ts-ignore
        streamEventsCount = getDateEventsCount(
          // @ts-ignore
          table.sportId,
          prevRow.group,
          offerCounters,
          offerFilters.timeFilter
        );
        //@ts-ignore
        localEventsCount = table.groupedEvents[prevRow.group].length;
      }

      return (
        <Fragment key={`RenderEvent-Fragment-1-${rowIndex + groupIndex}`}>
          {!allEventsLoaded &&
            rowIndex !== 0 &&
            streamEventsCount !== localEventsCount && (
              <div style={{ height: '140px' }}>
                <Loader height={'0px'} />
              </div>
            )}

          <div className="px-4 py-2 bg-grey-800 text-yellow">
            {/* @ts-ignore */}
            {row.data.group.includes('=')
              ? (row.data as TableSeparatorData).group
                  .toUpperCase()
                  .split('=')[1]
              : `${t(
                  `globals.${
                    (row.data as TableSeparatorData).group.split(',')[0]
                  }`
                )},${(row.data as TableSeparatorData).group.split(',')[1]}`}
          </div>
        </Fragment>
      );
    }

    // NOTE: This should be refactored. For now, this type of condition is used to solve the problem with order of the columns.
    if (
      (table.sport === 'košarka' ||
        table.sport === 'rukomet' ||
        table.sport === 'vaterpolo') &&
      table.type === 'player'
    ) {
      return (
        <OfferRow
          key={`RenderEvent-OfferRow-${rowIndex + groupIndex}`}
          data={row.data}
          table={table}
          basketballPlayer={true}
        />
      );
    }

    //@ts-ignore
    const streamCount = getEventsCountPerType(
      // @ts-ignore
      table.sportId,
      offerCounters,
      offerFilters.timeFilter,
      table.type
    );

    return (
      <Fragment key={`RenderEvent-Fragment-2-${rowIndex + groupIndex}`}>
        <OfferRow data={row.data} table={table} />

        {!allEventsLoaded &&
          rowIndex === rows.length - 1 &&
          rowIndex === rows.length - 1 &&
          streamCount !== rows.length && (
            <div className="stream-loader">
              <Loader height={'0px'} />
            </div>
          )}
      </Fragment>
    );
  }
);

const Offer = observer(({ tables }: { tables: SportOffer[] }) => {
  const { marketSwitcher } = useStores();
  const viewport = useWindowSize();
  const groupsCount = tables.map((t) => t.rows.length);

  useEffect(() => {
    marketSwitcher.updateColumnsNumber(viewport.width);
  }, [viewport.width, marketSwitcher]);

  return (
    <div className="offer">
      <AutoSizer>
        {({ height, width }: any) => (
          <GroupedVirtuoso
            components={{ Footer, EmptyPlaceholder: () => <NoEvents /> }}
            style={{ height, width }}
            groupCounts={groupsCount}
            groupContent={(index) => (
              <RenderGroupContent index={index} tables={tables} />
            )}
            itemContent={(index, groupIndex) => (
              <RenderEvent
                key={`Offer-RenderEvent-${groupIndex}-${index}`}
                index={index}
                groupIndex={groupIndex}
                tables={tables}
              />
            )}
          />
        )}
      </AutoSizer>
    </div>
  );
});

export default Offer;
