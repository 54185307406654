/* React modules */

/* Our modules */
import {
  EVENT_CHANNEL,
  AMUSNET_JACKPOT_CHANNEL,
} from 'modules/feed/feed-constants';
import FeedApi, { FeedMessageType } from 'modules/feed/feed.api';

/* 3rd Party modules */

class LiveFeed {
  api: FeedApi;
  handlers: any = {};

  constructor() {
    this.api = new FeedApi();
  }

  registerHandler = (
    messageType: FeedMessageType,
    handler: (message: any) => void
  ) => {
    const handlers = this.handlers[messageType];

    if (handlers) {
      this.handlers[messageType] = [...handlers, handler];
    } else {
      this.handlers[messageType] = [handler];
    }
  };

  onMessage = (type: string, message: any) => {
    const handlers = this.handlers[type];

    if (handlers) {
      handlers.forEach((handler: any) => handler(message));
    }
  };

  start = () => {
    this.api.connectFeedSocket(this.onMessage);
  };

  close = () => {
    this.api.disconnectFeedSocket();
  };

  subscribeEvent = (eventId: string) => {
    if (eventId) {
      this.api.subscribeFeedSocketChannel(
        `${EVENT_CHANNEL}:${eventId}`,
        this.onMessage
      );
    }
  };

  unsubscribeEvent = (eventId: string) => {
    if (eventId) {
      this.api.unsubscribeFeedSocketChannel(`${EVENT_CHANNEL}:${eventId}`);
    }
  };

  subscribeAmusnetJackpot = () => {
    if (!this.api.isFeedSocketInitialized()) {
      this.api.initFeedSocket();
    }

    this.api.subscribeFeedSocketChannel(
      AMUSNET_JACKPOT_CHANNEL,
      this.onMessage
    );
  };

  unsubscribeAmusnetJackpot = () => {
    this.api.unsubscribeFeedSocketChannel(AMUSNET_JACKPOT_CHANNEL);
  };
}

export default new LiveFeed();
