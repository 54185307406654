/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import GamesGrid from 'modules/gambling/ui/GamesGrid';
import './GamblingHomepage.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

interface GamblingHomePageProps {
  live: boolean;
  mobile: boolean;
}

// left for later user when BE completes API
// commented to remove console errors

// const SlotRaceLeaderboardSlider = observer(() => {
//   const { gamblingStore } = useStores();
//   return (
//     <Carousel sliderConfig={SLIDER_CONFIG_LEADERBOARD}>
//       <LoaderWrapper name="leaderBoard">
//         {gamblingStore.leaderBoards?.rankings.map((rank: any, index: number) => (
//           <div className="rank-slide text-ellipsis" key={index}>
//             <span className="rank-slide__rank">{`${rank.rank}.`}&nbsp;</span>
//             <span className="rank-slide__username text-ellipsis">
//               {rank.user}
//             </span>
//           </div>
//         ))}
//       </LoaderWrapper>
//     </Carousel>
//   );
// });

const GamblingHomepage = ({ live, mobile }: GamblingHomePageProps) => {
  const { gamblingStore } = useStores();
  const { filters } = gamblingStore;

  return (
    <div className="gambling-homepage-mobile">
      <GamesGrid
        isLive={live}
        isMobile={mobile}
        isGamesOnly={!!filters.hasActiveFilters || live}
      />
    </div>
  );
};

export default observer(GamblingHomepage);
