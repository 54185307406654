/* React modules */
import { useState } from 'react';

/* Our modules */
import { TICKET_STATUS_OPTIONS } from 'modules/ticket/ticket.constants';
import { SelectOption } from 'modules/user/ui/TicketOverview/TicketStatusFilter/TicketStatusFilter';
import { DatePicker, Icon, Select } from 'components';
import { formatDatePickerDate } from 'libs/datetime';
import './TicketMobileFilter.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

export interface Filters {
  label: string;
  value: number;
}

type FilterDate = Date | null;

export interface TicketMobileFilterProps {
  onStatusChange: (filter: any) => void;
  onDateChange: (filter: [Date, Date]) => void;
  resetDateFilter: () => void;
}

const TicketMobileFilter = ({
  onDateChange,
  resetDateFilter,
  onStatusChange,
}: TicketMobileFilterProps) => {
  const { t } = useTranslation();
  const ticketStatuses = TICKET_STATUS_OPTIONS(t);

  const [ticketStatusFilter, setTicketStatusFilter] = useState(
    ticketStatuses[0]
  );
  const ticketStatusFilterHandler = (filter: SelectOption) => {
    setTicketStatusFilter(filter);
    onStatusChange(filter.value);
  };

  const [dateRange, setDateRange] = useState<[FilterDate, FilterDate]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;

  const onDateFilterChange = (range: [FilterDate, FilterDate]) => {
    setDateRange(range);

    if (range[0] && range[1]) {
      onDateChange(range as [Date, Date]);
    } else if (!range[0] && !range[1]) {
      resetDateFilter();
    }
  };

  return (
    <div className="visible-mobile-down ticket-mobile-filter pt-3 pb-5">
      <section className="sb-text-small mt-1">
        <Select
          className="mb-1 select w-100"
          options={ticketStatuses}
          bg="sb-dark"
          size="sb-market"
          placeholder={t('slips.ticket-status')}
          value={ticketStatusFilter}
          shape="squared"
          onChange={ticketStatusFilterHandler}
        />
      </section>
      <section className="w-100 mx-auto">
        <div className="d-flex align-items ticket-mobile-filter__datepicker-wrapper mt-6 w-90 mx-auto">
          <label
            htmlFor="filter-datepicker"
            className="d-flex align-items justify-center w-100"
          >
            <Icon name="calendar" variant="lightgrey" className="mr-2" />
            {/*Mobile picker*/}
            <DatePicker
              placeholderText={t('globals.filter-by-date')}
              className="ticket-status-filter__datepicker ml-3"
              value={{
                from: formatDatePickerDate(startDate),
                to: formatDatePickerDate(endDate),
              }}
              onChange={onDateFilterChange}
              isRange
              id="filter-datepicker-transactions"
            />
          </label>
        </div>
      </section>
    </div>
  );
};

// @ts-ignore
export default TicketMobileFilter;
