/* React modules */

/* Our modules */
import { TicketStatuses, TicketStatus } from 'modules/ticket/ticket.types';
import { formatDate, formatTime, toDate } from 'libs/datetime';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

interface TicketStatusHeaderProps {
  id?: number;
  type: string;
  status: TicketStatuses | any;
  created_at: any;
  quickTicket: boolean;
  hideTime: boolean;
}

const TicketStatusHeader = ({
  id,
  type,
  status,
  created_at,
  quickTicket,
  hideTime,
}: TicketStatusHeaderProps) => {
  const { t } = useTranslation();

  const date = toDate(created_at?.seconds);
  const unconfirmed = status === TicketStatus.UNCONFIRMED;

  return (
    <div className="ticket-status__header">
      <h3 className="ticket-status__title">
        {unconfirmed && !quickTicket
          ? t('slips.confirm-ticket')
          : t('slips.ticket-details')}
      </h3>

      <div className="ticket-status__creation-info">
        <span className="ticket-status__type">
          {`${type.toUpperCase()} ${t('slips.ticket')} ${id ? `(${id})` : ''}`}
        </span>

        {!unconfirmed && !hideTime && (
          <span className="ticket-status__time">
            {`${t('slips.payin-time')} ${formatDate(date)} ${formatTime(date)}`}
          </span>
        )}
      </div>
    </div>
  );
};

export { TicketStatusHeader };
