/* React modules */

/* Our modules */
import { MarketGroup, Market } from 'modules/sports/sports.types';

/* 3rd Party modules */

export default class SportMarketBase {
  public id: number;

  public name: string;

  public englishName: string;

  public albanianName: string;

  public turkishName: string;

  public russianName: string;

  public ukrainianName: string;

  public italianName: string;

  public germanName: string;

  constructor(data: Market | MarketGroup) {
    this.id = data.id;
    this.name = data.name;
    this.englishName = data.englishName;
    this.albanianName = data.albanianName;
    this.turkishName = data.turkishName;
    this.russianName = data.russianName;
    this.ukrainianName = data.ukrainianName;
    this.italianName = data.italianName;
    this.germanName = data.germanName;
  }

  getName(lang: string) {
    switch (lang) {
      case 'me':
        return this.name;
      case 'en':
        return this.englishName;
      case 'al':
        return this.albanianName;
      case 'tr':
        return this.turkishName;
      case 'ru':
        return this.russianName;
      case 'ua':
        return this.ukrainianName;
      case 'it':
        return this.italianName;
      case 'de':
        return this.germanName;
      default:
        return '';
    }
  }
}
