export const RESULTS_MAP: any = {
  fudbal: {
    currentScoringTypes: [{ key: 'G', name: 'R' }],
    periodScoringKey: 'G',
    shortPeriodKeys: ['FH', 'OT'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  'virtualni fudbal': {
    currentScoringTypes: [{ key: 'G', name: 'R' }],
    periodScoringKey: 'G',
    shortPeriodKeys: ['FH', 'OT'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  tenis: {
    currentScoringTypes: [
      { key: 'P', name: 'P' },
      { key: 'G', name: 'G' },
      { key: 'S', name: 'S' },
    ],
    finalScoringTypes: [{ key: 'S', name: 'S' }],
    periodScoringKey: 'G',
    shortPeriodKeys: [],
    fullPeriodKeys: [],
    replacingScoreValues: {
      '50': 'A',
    },
    pausePhaseValues: {},
  },
  'stoni tenis': {
    currentScoringTypes: [
      { key: 'P', name: 'P' },
      { key: 'S', name: 'S' },
    ],
    finalScoringTypes: [{ key: 'S', name: 'S' }],
    periodScoringKey: 'P',
    shortPeriodKeys: [],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  odbojka: {
    currentScoringTypes: [
      { key: 'P', name: 'P' },
      { key: 'S', name: 'S' },
    ],
    finalScoringTypes: [{ key: 'S', name: 'S' }],
    periodScoringKey: 'P',
    shortPeriodKeys: [],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  košarka: {
    currentScoringTypes: [{ key: 'P', name: 'R' }],
    periodScoringKey: 'P',
    shortPeriodKeys: ['Q1'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {
      '1Q_END': '2Q',
      '2Q_END': 'HT',
      '3Q_END': '4Q',
      '4Q_END': 'FT',
    },
  },
  rukomet: {
    currentScoringTypes: [{ key: 'G', name: 'R' }],
    periodScoringKey: 'G',
    shortPeriodKeys: ['FH'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  hokej: {
    currentScoringTypes: [{ key: 'G', name: 'R' }],
    periodScoringKey: 'G',
    shortPeriodKeys: ['1/3'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {
      P1_END: '1P',
      P2_END: '3P',
      P3_END: 'FT',
    },
  },
  vatepolo: {
    currentScoringTypes: [{ key: 'G', name: 'R' }],
    periodScoringKey: 'G',
    shortPeriodKeys: [],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {
      '1Q_END': '2Q',
      '2Q_END': 'HT',
      '3Q_END': '4Q',
      '4Q_END': 'FT',
    },
  },
  futsal: {
    currentScoringTypes: [{ key: 'G', name: 'R' }],
    periodScoringKey: 'G',
    shortPeriodKeys: ['FH'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  snuker: {
    currentScoringTypes: [
      { key: 'S', name: 'S' },
      { key: 'P', name: 'P' },
    ],
    periodScoringKey: 'P',
    shortPeriodKeys: [],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
  bejzbol: {
    currentScoringTypes: [{ key: 'P', name: 'R' }],
    periodScoringKey: 'P',
    shortPeriodKeys: ['I1'],
    fullPeriodKeys: [],
    replacingScoreValues: {},
    pausePhaseValues: {},
  },
};
