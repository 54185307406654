/* React modules */

/* Our modules */
import { EVENT_TYPE, EventType } from 'modules/sports/sports.types';
import Event from 'modules/sports/store/event.store';
import sportsStore from 'modules/sports/store/sports.store';
import offerSort from 'modules/offer/store/offer.sort';
import offerEvents from 'modules/offer/services/offer-events.service';
import SportNode from 'modules/sports/services/sport-node.service';

/* 3rd Party modules */

export interface TableSeparatorData {
  group: string;
}

export class TableRow {
  type: 'row' | 'separator';
  data: Event | TableSeparatorData;
  group?: string;

  constructor(
    type: 'row' | 'separator',
    data: Event | TableSeparatorData,
    group?: string
  ) {
    this.type = type;
    this.data = data;
    this.group = group;
  }
}

class Table {
  sport: string | null = null;
  sportId: number | null = null;
  type: EventType | null = null;
  events: Event[] = [];
  rows: TableRow[] = [];
  groupedEvents: any[] = [];
}

class OfferBuilder {
  private table: Table;
  private sport: SportNode | null = null;

  constructor() {
    this.table = new Table();
  }

  getTable() {
    const table = { ...this.table };
    this.reset();
    return table;
  }

  private reset() {
    this.table = new Table();
  }

  setSport(sportId: number) {
    const sport = sportsStore.sportsData?.findSportById(sportId);

    if (sport) {
      this.sport = sport;
      this.table.sport = this.sport.name.toLowerCase();
      this.table.sportId = this.sport.id;
    }
  }

  setType(type: EventType) {
    this.table.type = type;
  }

  setEvents() {
    if (!this.sport) return [];
    this.table.events = offerEvents
      // @ts-ignore
      .getEvents(this.sport, this.table.type)
      .filter(
        // eslint-disable-next-line eqeqeq
        (item: any, index: number, self: any) => self.indexOf(item) == index
      );
  }

  groupEvents(): void {
    const { type, events } = this.table;
    const { isSortedByTime } = offerSort;

    if (
      type === EVENT_TYPE.LIVE ||
      type === EVENT_TYPE.PLAYER ||
      type === EVENT_TYPE.SPECIAL ||
      type === EVENT_TYPE.ANTEPOST
    ) {
      this.table.groupedEvents = offerEvents.groupByCompetition(events);
    }

    if (type === EVENT_TYPE.UPCOMING) {
      this.table.groupedEvents = isSortedByTime
        ? offerEvents.groupByDate(events)
        : offerEvents.groupByCompetition(events);
    }
  }

  applySort(): void {
    const { type } = this.table;
    this.table.events = offerSort.sort(
      (this.table.events as Event[]) || [],
      // @ts-ignore
      type
    );
  }

  buildRows(): void {
    const { groupedEvents, type } = this.table;

    if (type === EVENT_TYPE.SPECIAL) {
      this.table.rows = this.table.events.map(
        (e: any) => new TableRow('row', e)
      );
    } else {
      this.table.rows = Object.keys(groupedEvents).reduce(
        (tableRows: TableRow[], group: any) => {
          const separator =
            type === EVENT_TYPE.LIVE
              ? []
              : [new TableRow('separator', { group })];

          return [
            ...tableRows,
            ...separator,
            ...groupedEvents[group].map(
              (event: Event) => new TableRow('row', event, group)
            ),
          ];
        },
        []
      );
    }
  }
}

export default OfferBuilder;
