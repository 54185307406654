/* React modules */

/* Our modules */
import { getAxiosBaseUrl, getAxiosBannerUrl } from 'libs/urlBuilder';
import MockApi from 'mocks/mock-api';

/* 3rd Party modules */
import axios, { InternalAxiosRequestConfig } from 'axios';

function getConfig(isBanner?: boolean) {
  return {
    baseURL: isBanner ? getAxiosBannerUrl() : getAxiosBaseUrl(),
  };
}

let headers: any = {};

const setHeaders = (newHeaders: any) => {
  headers = { ...headers, ...newHeaders };
};

const removeHeader = (header: string) => {
  delete headers[header];
};

const http = axios.create(getConfig());
const httpBanner = axios.create(getConfig(true));

const interceptorRequestFulfilledHandler = (
  config: InternalAxiosRequestConfig
) => {
  const { baseURL } = config;

  for (const header in headers) {
    if (!(baseURL || '').includes('/get-ip')) {
      config.headers[header] = headers[header];
    }
  }

  return config;
};

http.interceptors.request.use(interceptorRequestFulfilledHandler);
httpBanner.interceptors.request.use(interceptorRequestFulfilledHandler);

const interceptors: Array<(arg: any) => void> = [];

const responseRejectedHandler = (error: any) => {
  interceptors.forEach((fn: (arg: any) => void) => fn(error));
  return Promise.reject(error.response);
};

http.interceptors.response.use(
  (response: any) => response,
  responseRejectedHandler
);

const bannerInterceptors: Array<(arg: any) => void> = [];

const bannerResponseRejectedHandler = (error: any) => {
  bannerInterceptors.forEach((fn: (arg: any) => void) => fn(error));
  return Promise.reject(error.response);
};

httpBanner.interceptors.response.use(
  (response: any) => response,
  bannerResponseRejectedHandler
);

// Initiate Axios mock adapter
const mockApi = new MockApi(http);
mockApi.initiateMocks();

export {
  setHeaders,
  removeHeader,
  interceptors,
  bannerInterceptors,
  http,
  httpBanner,
};
