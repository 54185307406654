/* React modules */

/* Our modules */
import { TicketStatus, TicketStatuses } from 'modules/ticket/ticket.types';
import { CashoutButton } from 'modules/ticket/ui/TicketStatus';
import { currencyWithoutSymbol } from 'libs/currency-formatter';

/* 3rd Party modules */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface TicketStatusProps {
  bonus: number | null;
  min_bonus: number | null;
  max_bonus: number | null;
  odd_sum: number | null;
  min_odd: number | null;
  max_odd: number | null;
  bet_amount: number | null;
  old_bet_amount: number | undefined;
  event_length: number | null;
  potential_winning: number | null;
  min_winning_amount: number | null;
  min_winning_total: number | null;
  actual_winning_amount: number | null;
  actual_winning_no_bonus: number | null;
  potential_cashout: number | null;
  cashout_amount: number | null;
  status: TicketStatuses | '';
  bonusInPercent: any;
  quickTicket: boolean;
  cashoutPaidOut?: boolean;
  isFix?: boolean;
}

const STATUS_DISPLAY = {
  [TicketStatus.UNCONFIRMED]: {
    labelKey: 'slips.unconfirmed',
  },
  [TicketStatus.NOT_RESOLVED]: {
    labelKey: 'slips.active',
  },
  [TicketStatus.PASSED]: {
    labelKey: 'slips.passed',
  },
  [TicketStatus.FAILED]: {
    labelKey: 'slips.failed',
  },
  [TicketStatus.DENIED]: {
    labelKey: 'slips.denied',
  },
  [TicketStatus.APPROVING]: {
    labelKey: 'slips.approving',
  },
  [TicketStatus.PAYED_OUT]: {
    labelKey: 'slips.paid',
  },
  [TicketStatus.MANUAL_CHANGED]: {
    labelKey: 'slips.changed',
  },
  [TicketStatus.USER_BLOCKED]: {
    labelKey: 'user blocked',
  },
  default: {
    labelKey: '',
  },
};

const TicketStatusInfo = ({
  bet_amount,
  old_bet_amount,
  odd_sum,
  min_odd,
  max_odd,
  bonus,
  min_bonus,
  max_bonus,
  event_length,
  potential_cashout,
  potential_winning,
  min_winning_amount,
  actual_winning_no_bonus,
  actual_winning_amount,
  cashout_amount,
  status,
  bonusInPercent,
  quickTicket,
  cashoutPaidOut,
  isFix,
}: TicketStatusProps) => {
  const { t } = useTranslation();

  const statusDisplay =
    STATUS_DISPLAY[status || 'default'] || STATUS_DISPLAY.default;

  const calculateFinalBonus = (
    actualWinningAmount: number,
    actualWinningNoBonus: number
  ) => {
    let bonus: number = 0;
    let totalWin: number = actualWinningAmount || 0;
    let totalWinNoBonus: number = actualWinningNoBonus || 0;
    let totalDifference: number = totalWin - totalWinNoBonus;
    if (totalDifference > 0) bonus = totalDifference;
    return currencyWithoutSymbol(bonus);
  };

  return (
    <div className={`ticket-status__info`}>
      <div className="ticket-status__info--wrapper">
        <div className="ticket-status__info--row">
          <div className="ticket-status__info--pair">
            <span>{t('slips.payin')}</span>
            <span>
              <span
                className={classNames('text-strong', {
                  'text-red':
                    bet_amount && old_bet_amount && bet_amount < old_bet_amount,
                  'text-green-600':
                    bet_amount && old_bet_amount && bet_amount > old_bet_amount,
                })}
              >
                {bet_amount ? currencyWithoutSymbol(bet_amount) : '/'} EUR
              </span>
            </span>
          </div>
          <div className="ticket-status__info--pair">
            {isFix ? (
              <>
                <span>{t('slips.total-odd')}</span>
                <span className="text-strong">
                  {odd_sum ? odd_sum.toFixed(2) : '/'}
                </span>
              </>
            ) : (
              <>
                <span>{t('slips.min-max-odd')}:</span>
                <span className="text-strong">
                  {min_odd?.toFixed(2)} /{' '}
                  {status === TicketStatus.UNCONFIRMED
                    ? max_odd?.toFixed(2)
                    : odd_sum?.toFixed(2)}
                </span>
              </>
            )}
          </div>
          <div className="ticket-status__info--pair">
            <span>{t('slips.number-of-matches')}</span>
            <span className="text-strong">{event_length}</span>
          </div>

          {!quickTicket && !isFix && !cashoutPaidOut && (
            <div className="ticket-status__info--pair">
              <span>{t('slips.status-capitalize')}</span>
              <span
                className={classNames('text-strong', {
                  'text-red': status === TicketStatus.FAILED,
                  'text-green-600': status === TicketStatus.PASSED,
                  'text-orange-800': status === TicketStatus.NOT_RESOLVED,
                })}
              >
                {t(statusDisplay.labelKey)}
              </span>
            </div>
          )}
          {cashoutPaidOut && (
            <div className="ticket-status__info--pair">
              <span>{t('slips.status-capitalize')}</span>
              <span className="text-green-600">Cashout</span>
            </div>
          )}
        </div>

        <div className="ticket-status__info--row">
          {!quickTicket && isFix && !cashoutPaidOut && (
            <div className="ticket-status__info--pair">
              <span>{t('slips.status-capitalize')}</span>
              <span
                className={classNames('text-strong', {
                  'text-red':
                    status === TicketStatus.FAILED ||
                    status === TicketStatus.DENIED,
                  'text-green-600': status === TicketStatus.PASSED,
                  'text-yellow': status === TicketStatus.NOT_RESOLVED,
                })}
              >
                {t(statusDisplay.labelKey)}
              </span>
            </div>
          )}

          {/* FIX TICKETS CASES */}
          {isFix && (
            <>
              {/* ACTIVE TICKETS */}
              {status !== TicketStatus.PASSED &&
                status !== TicketStatus.PAYED_OUT &&
                status !== TicketStatus.FAILED && (
                  <>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.potential-bonus')}:</span>
                      <span className="text-strong text-yellow-500">
                        {bonus ? currencyWithoutSymbol(bonus) : '0.00'} EUR (
                        {bonusInPercent}%)
                      </span>
                    </div>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.pot-winning-amount')}:</span>
                      <span className="text-strong text-yellow-500">
                        {potential_cashout
                          ? currencyWithoutSymbol(potential_cashout)
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                  </>
                )}

              {/* PASSED, CASHOUT, PAID OUT(PAYED_OUT) RESOLVED TICKETS AND NOT DENIED */}
              {status !== TicketStatus.NOT_RESOLVED &&
                status !== TicketStatus.FAILED &&
                status !== TicketStatus.APPROVING &&
                status !== TicketStatus.DENIED &&
                status !== TicketStatus.UNCONFIRMED && (
                  <>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.bonus')}</span>
                      <span className="text-strong text-yellow-500">
                        {bonus ? currencyWithoutSymbol(bonus) : '0.00'} EUR
                      </span>
                    </div>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.actual-winning-amount')}:</span>
                      <span className="text-strong text-yellow-500">
                        {actual_winning_amount
                          ? currencyWithoutSymbol(actual_winning_amount)
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                  </>
                )}

              {/* FAILED RESOLVED TICKETS */}
              {status === TicketStatus.FAILED && (
                <>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.bonus')}</span>
                    <span className="text-strong text-yellow-500">
                      {bonus ? currencyWithoutSymbol(bonus) : '0.00'} EUR
                    </span>
                  </div>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.actual-winning-amount')}:</span>
                    <span className="text-strong text-yellow-500">
                      {actual_winning_amount
                        ? currencyWithoutSymbol(actual_winning_amount)
                        : '0.00'}{' '}
                      EUR
                    </span>
                  </div>
                </>
              )}
            </>
          )}

          {/* SYSTEM TICKETS CASES */}
          {!isFix && (
            <>
              {/* ACTIVE TICKETS */}
              {status !== TicketStatus.PASSED &&
                status !== TicketStatus.PAYED_OUT &&
                status !== TicketStatus.FAILED &&
                status !== TicketStatus.UNCONFIRMED && (
                  <>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.min-potential-bonus')}:</span>
                      <span className="text-strong text-yellow-500">
                        {min_bonus ? currencyWithoutSymbol(min_bonus) : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.max-potential-bonus')}:</span>
                      <span className="text-strong text-yellow-500">
                        {bonus ? currencyWithoutSymbol(bonus) : '0.00'} EUR
                      </span>
                    </div>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.min-winning-total')}:</span>
                      <span className="text-strong text-yellow-500">
                        {min_winning_amount
                          ? currencyWithoutSymbol(min_winning_amount)
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>

                    {bonus === 0 ? (
                      <div className="ticket-status__info--pair">
                        <span>{t('slips.max-pot-payout')}:</span>
                        <span className="text-strong text-yellow-500">
                          {potential_winning
                            ? currencyWithoutSymbol(potential_winning)
                            : '0.00'}{' '}
                          EUR
                        </span>
                      </div>
                    ) : (
                      <div className="ticket-status__info--pair">
                        <span>{t('slips.max-pot-payout')}:</span>
                        <span className="text-strong text-yellow-500">
                          {potential_cashout
                            ? currencyWithoutSymbol(potential_cashout)
                            : '0.00'}{' '}
                          EUR
                        </span>
                      </div>
                    )}
                  </>
                )}

              {status === TicketStatus.UNCONFIRMED && (
                <>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.min-potential-bonus')}:</span>
                    <span className="text-strong text-yellow-500">
                      {min_bonus ? currencyWithoutSymbol(min_bonus) : '0.00'}{' '}
                      EUR
                    </span>
                  </div>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.max-potential-bonus')}:</span>
                    <span className="text-strong text-yellow-500">
                      {max_bonus ? currencyWithoutSymbol(max_bonus) : '0.00'}{' '}
                      EUR
                    </span>
                  </div>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.min-winning-total')}:</span>
                    <span className="text-strong text-yellow-500">
                      {min_winning_amount
                        ? currencyWithoutSymbol(min_winning_amount)
                        : '0.00'}{' '}
                      EUR
                    </span>
                  </div>

                  {max_bonus === 0 ? (
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.max-pot-payout')}:</span>
                      <span className="text-strong text-yellow-500">
                        {potential_winning
                          ? currencyWithoutSymbol(potential_winning)
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                  ) : (
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.max-pot-payout')}:</span>
                      <span className="text-strong text-yellow-500">
                        {potential_winning && max_bonus
                          ? currencyWithoutSymbol(potential_winning + max_bonus)
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                  )}
                </>
              )}

              {/* PASSED, CASHOUT, PAID OUT(PAYED_OUT) RESOLVED TICKETS AND NOT DENIED */}
              {status !== TicketStatus.NOT_RESOLVED &&
                status !== TicketStatus.FAILED &&
                status !== TicketStatus.APPROVING &&
                status !== TicketStatus.DENIED &&
                status !== TicketStatus.UNCONFIRMED && (
                  <>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.bonus')}</span>
                      <span className="text-strong text-yellow-500">
                        {actual_winning_amount && actual_winning_no_bonus
                          ? calculateFinalBonus(
                              actual_winning_amount,
                              actual_winning_no_bonus
                            )
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                    <div className="ticket-status__info--pair">
                      <span>{t('slips.actual-winning-amount')}:</span>
                      <span className="text-strong text-yellow-500">
                        {actual_winning_amount
                          ? currencyWithoutSymbol(actual_winning_amount)
                          : '0.00'}{' '}
                        EUR
                      </span>
                    </div>
                  </>
                )}

              {/* FAILED RESOLVED TICKETS */}
              {status === TicketStatus.FAILED && (
                <>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.bonus')}</span>
                    <span className="text-strong text-yellow-500">
                      {bonus ? currencyWithoutSymbol(bonus) : '0.00'} EUR
                    </span>
                  </div>
                  <div className="ticket-status__info--pair">
                    <span>{t('slips.actual-winning-amount')}:</span>
                    <span className="text-strong text-yellow-500">
                      {actual_winning_amount
                        ? currencyWithoutSymbol(actual_winning_amount)
                        : '0.00'}{' '}
                      EUR
                    </span>
                  </div>
                </>
              )}
            </>
          )}

          {status === TicketStatus.NOT_RESOLVED && cashout_amount !== null && (
            <CashoutButton
              className="visible-mobile-down"
              cashoutAmount={cashout_amount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TicketStatusInfo.defaultProps = {
  isFix: true,
};

export { TicketStatusInfo };
