// source: proto/notifications/notifications.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_odds_odds_pb = require('../../proto/odds/odds_pb.js');
goog.object.extend(proto, proto_odds_odds_pb);
var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.notifications.BettingPlacesNotification', null, global);
goog.exportSymbol('proto.notifications.BroadcastBlankSlipRequest', null, global);
goog.exportSymbol('proto.notifications.BroadcastDoublePrintRequest', null, global);
goog.exportSymbol('proto.notifications.CashOutOdd', null, global);
goog.exportSymbol('proto.notifications.ConfirmBmMessageRequest', null, global);
goog.exportSymbol('proto.notifications.GetPushNotificationsResponse', null, global);
goog.exportSymbol('proto.notifications.LimitsRequest', null, global);
goog.exportSymbol('proto.notifications.ManualApprovalRequest', null, global);
goog.exportSymbol('proto.notifications.ManualCashOutRequest', null, global);
goog.exportSymbol('proto.notifications.Notification', null, global);
goog.exportSymbol('proto.notifications.NotificationResponse', null, global);
goog.exportSymbol('proto.notifications.OutcomeUpdateRequest', null, global);
goog.exportSymbol('proto.notifications.SystemNotificationRequest', null, global);
goog.exportSymbol('proto.notifications.UserConfirmation', null, global);
goog.exportSymbol('proto.notifications.UserNotification', null, global);
goog.exportSymbol('proto.notifications.WebNotification', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.ConfirmBmMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.ConfirmBmMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.ConfirmBmMessageRequest.displayName = 'proto.notifications.ConfirmBmMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.WebNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.WebNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.WebNotification.displayName = 'proto.notifications.WebNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.UserConfirmation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.UserConfirmation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.UserConfirmation.displayName = 'proto.notifications.UserConfirmation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.SystemNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.SystemNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.SystemNotificationRequest.displayName = 'proto.notifications.SystemNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.UserNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.UserNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.UserNotification.displayName = 'proto.notifications.UserNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.NotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.NotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.NotificationResponse.displayName = 'proto.notifications.NotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.OutcomeUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.OutcomeUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.OutcomeUpdateRequest.displayName = 'proto.notifications.OutcomeUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.ManualApprovalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.ManualApprovalRequest.repeatedFields_, null);
};
goog.inherits(proto.notifications.ManualApprovalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.ManualApprovalRequest.displayName = 'proto.notifications.ManualApprovalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.ManualCashOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.ManualCashOutRequest.repeatedFields_, null);
};
goog.inherits(proto.notifications.ManualCashOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.ManualCashOutRequest.displayName = 'proto.notifications.ManualCashOutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.LimitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.LimitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.LimitsRequest.displayName = 'proto.notifications.LimitsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.CashOutOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.CashOutOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.CashOutOdd.displayName = 'proto.notifications.CashOutOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.BroadcastDoublePrintRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.BroadcastDoublePrintRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.BroadcastDoublePrintRequest.displayName = 'proto.notifications.BroadcastDoublePrintRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.BroadcastBlankSlipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.BroadcastBlankSlipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.BroadcastBlankSlipRequest.displayName = 'proto.notifications.BroadcastBlankSlipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.BettingPlacesNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.BettingPlacesNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.BettingPlacesNotification.displayName = 'proto.notifications.BettingPlacesNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.Notification.displayName = 'proto.notifications.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.GetPushNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.GetPushNotificationsResponse.repeatedFields_, null);
};
goog.inherits(proto.notifications.GetPushNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.GetPushNotificationsResponse.displayName = 'proto.notifications.GetPushNotificationsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.ConfirmBmMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.ConfirmBmMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.ConfirmBmMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.ConfirmBmMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.ConfirmBmMessageRequest}
 */
proto.notifications.ConfirmBmMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.ConfirmBmMessageRequest;
  return proto.notifications.ConfirmBmMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.ConfirmBmMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.ConfirmBmMessageRequest}
 */
proto.notifications.ConfirmBmMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.ConfirmBmMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.ConfirmBmMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.ConfirmBmMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.ConfirmBmMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message_uuid = 1;
 * @return {string}
 */
proto.notifications.ConfirmBmMessageRequest.prototype.getMessageUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ConfirmBmMessageRequest} returns this
 */
proto.notifications.ConfirmBmMessageRequest.prototype.setMessageUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.WebNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.WebNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.WebNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.WebNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.WebNotification}
 */
proto.notifications.WebNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.WebNotification;
  return proto.notifications.WebNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.WebNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.WebNotification}
 */
proto.notifications.WebNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.WebNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.WebNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.WebNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.WebNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.notifications.WebNotification.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.WebNotification} returns this
 */
proto.notifications.WebNotification.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.UserConfirmation.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.UserConfirmation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.UserConfirmation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.UserConfirmation.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.UserConfirmation}
 */
proto.notifications.UserConfirmation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.UserConfirmation;
  return proto.notifications.UserConfirmation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.UserConfirmation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.UserConfirmation}
 */
proto.notifications.UserConfirmation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.UserConfirmation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.UserConfirmation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.UserConfirmation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.UserConfirmation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.notifications.UserConfirmation.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.UserConfirmation} returns this
 */
proto.notifications.UserConfirmation.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.SystemNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.SystemNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.SystemNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.SystemNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.SystemNotificationRequest}
 */
proto.notifications.SystemNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.SystemNotificationRequest;
  return proto.notifications.SystemNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.SystemNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.SystemNotificationRequest}
 */
proto.notifications.SystemNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.SystemNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.SystemNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.SystemNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.SystemNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.notifications.SystemNotificationRequest.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.SystemNotificationRequest} returns this
 */
proto.notifications.SystemNotificationRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.UserNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.UserNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.UserNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.UserNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    roleId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.UserNotification}
 */
proto.notifications.UserNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.UserNotification;
  return proto.notifications.UserNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.UserNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.UserNotification}
 */
proto.notifications.UserNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.UserNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.UserNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.UserNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.UserNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.notifications.UserNotification.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.UserNotification} returns this
 */
proto.notifications.UserNotification.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.notifications.UserNotification.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.UserNotification} returns this
 */
proto.notifications.UserNotification.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 role_id = 3;
 * @return {number}
 */
proto.notifications.UserNotification.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.UserNotification} returns this
 */
proto.notifications.UserNotification.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.NotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.NotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.NotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.NotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.NotificationResponse}
 */
proto.notifications.NotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.NotificationResponse;
  return proto.notifications.NotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.NotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.NotificationResponse}
 */
proto.notifications.NotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.NotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.NotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.NotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.NotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.notifications.NotificationResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.NotificationResponse} returns this
 */
proto.notifications.NotificationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.OutcomeUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.OutcomeUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.OutcomeUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.OutcomeUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    outcomeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outcomeCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outcomeTic: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.OutcomeUpdateRequest}
 */
proto.notifications.OutcomeUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.OutcomeUpdateRequest;
  return proto.notifications.OutcomeUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.OutcomeUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.OutcomeUpdateRequest}
 */
proto.notifications.OutcomeUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeTic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.OutcomeUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.OutcomeUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.OutcomeUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.OutcomeUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutcomeCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutcomeTic();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 outcome_id = 1;
 * @return {number}
 */
proto.notifications.OutcomeUpdateRequest.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.OutcomeUpdateRequest} returns this
 */
proto.notifications.OutcomeUpdateRequest.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string outcome_name = 2;
 * @return {string}
 */
proto.notifications.OutcomeUpdateRequest.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.OutcomeUpdateRequest} returns this
 */
proto.notifications.OutcomeUpdateRequest.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outcome_code = 3;
 * @return {string}
 */
proto.notifications.OutcomeUpdateRequest.prototype.getOutcomeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.OutcomeUpdateRequest} returns this
 */
proto.notifications.OutcomeUpdateRequest.prototype.setOutcomeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outcome_group = 4;
 * @return {string}
 */
proto.notifications.OutcomeUpdateRequest.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.OutcomeUpdateRequest} returns this
 */
proto.notifications.OutcomeUpdateRequest.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string outcome_tic = 5;
 * @return {string}
 */
proto.notifications.OutcomeUpdateRequest.prototype.getOutcomeTic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.OutcomeUpdateRequest} returns this
 */
proto.notifications.OutcomeUpdateRequest.prototype.setOutcomeTic = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.ManualApprovalRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.ManualApprovalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.ManualApprovalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.ManualApprovalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.ManualApprovalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    approvalErrorsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    odds: (f = msg.getOdds()) && proto_odds_odds_pb.GetOddsResponse.toObject(includeInstance, f),
    slipData: jspb.Message.getFieldWithDefault(msg, 3, ""),
    approvalId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    slipId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    slipType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shortUuid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    auto: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.ManualApprovalRequest}
 */
proto.notifications.ManualApprovalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.ManualApprovalRequest;
  return proto.notifications.ManualApprovalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.ManualApprovalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.ManualApprovalRequest}
 */
proto.notifications.ManualApprovalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addApprovalErrors(value);
      break;
    case 2:
      var value = new proto_odds_odds_pb.GetOddsResponse;
      reader.readMessage(value,proto_odds_odds_pb.GetOddsResponse.deserializeBinaryFromReader);
      msg.setOdds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipData(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovalId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortUuid(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.ManualApprovalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.ManualApprovalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.ManualApprovalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.ManualApprovalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApprovalErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOdds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_odds_odds_pb.GetOddsResponse.serializeBinaryToWriter
    );
  }
  f = message.getSlipData();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApprovalId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSlipType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShortUuid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuto();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated string approval_errors = 1;
 * @return {!Array<string>}
 */
proto.notifications.ManualApprovalRequest.prototype.getApprovalErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setApprovalErrorsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.addApprovalErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.clearApprovalErrorsList = function() {
  return this.setApprovalErrorsList([]);
};


/**
 * optional odds.GetOddsResponse odds = 2;
 * @return {?proto.odds.GetOddsResponse}
 */
proto.notifications.ManualApprovalRequest.prototype.getOdds = function() {
  return /** @type{?proto.odds.GetOddsResponse} */ (
    jspb.Message.getWrapperField(this, proto_odds_odds_pb.GetOddsResponse, 2));
};


/**
 * @param {?proto.odds.GetOddsResponse|undefined} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
*/
proto.notifications.ManualApprovalRequest.prototype.setOdds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.clearOdds = function() {
  return this.setOdds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.ManualApprovalRequest.prototype.hasOdds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string slip_data = 3;
 * @return {string}
 */
proto.notifications.ManualApprovalRequest.prototype.getSlipData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setSlipData = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 approval_id = 4;
 * @return {number}
 */
proto.notifications.ManualApprovalRequest.prototype.getApprovalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setApprovalId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string slip_id = 5;
 * @return {string}
 */
proto.notifications.ManualApprovalRequest.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string slip_type = 6;
 * @return {string}
 */
proto.notifications.ManualApprovalRequest.prototype.getSlipType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setSlipType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string short_uuid = 7;
 * @return {string}
 */
proto.notifications.ManualApprovalRequest.prototype.getShortUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setShortUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool auto = 8;
 * @return {boolean}
 */
proto.notifications.ManualApprovalRequest.prototype.getAuto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notifications.ManualApprovalRequest} returns this
 */
proto.notifications.ManualApprovalRequest.prototype.setAuto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.ManualCashOutRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.ManualCashOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.ManualCashOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.ManualCashOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.ManualCashOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approvalId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    slipData: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.notifications.CashOutOdd.toObject, includeInstance),
    auto: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.ManualCashOutRequest}
 */
proto.notifications.ManualCashOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.ManualCashOutRequest;
  return proto.notifications.ManualCashOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.ManualCashOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.ManualCashOutRequest}
 */
proto.notifications.ManualCashOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipData(value);
      break;
    case 4:
      var value = new proto.notifications.CashOutOdd;
      reader.readMessage(value,proto.notifications.CashOutOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.ManualCashOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.ManualCashOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.ManualCashOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.ManualCashOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApprovalId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSlipData();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.notifications.CashOutOdd.serializeBinaryToWriter
    );
  }
  f = message.getAuto();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string slip_id = 1;
 * @return {string}
 */
proto.notifications.ManualCashOutRequest.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ManualCashOutRequest} returns this
 */
proto.notifications.ManualCashOutRequest.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 approval_id = 2;
 * @return {number}
 */
proto.notifications.ManualCashOutRequest.prototype.getApprovalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.ManualCashOutRequest} returns this
 */
proto.notifications.ManualCashOutRequest.prototype.setApprovalId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string slip_data = 3;
 * @return {string}
 */
proto.notifications.ManualCashOutRequest.prototype.getSlipData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.ManualCashOutRequest} returns this
 */
proto.notifications.ManualCashOutRequest.prototype.setSlipData = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CashOutOdd odds = 4;
 * @return {!Array<!proto.notifications.CashOutOdd>}
 */
proto.notifications.ManualCashOutRequest.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.notifications.CashOutOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.CashOutOdd, 4));
};


/**
 * @param {!Array<!proto.notifications.CashOutOdd>} value
 * @return {!proto.notifications.ManualCashOutRequest} returns this
*/
proto.notifications.ManualCashOutRequest.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.notifications.CashOutOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.CashOutOdd}
 */
proto.notifications.ManualCashOutRequest.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.notifications.CashOutOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.ManualCashOutRequest} returns this
 */
proto.notifications.ManualCashOutRequest.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional bool auto = 5;
 * @return {boolean}
 */
proto.notifications.ManualCashOutRequest.prototype.getAuto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notifications.ManualCashOutRequest} returns this
 */
proto.notifications.ManualCashOutRequest.prototype.setAuto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.LimitsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.LimitsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.LimitsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.LimitsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bettingPlaceName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.LimitsRequest}
 */
proto.notifications.LimitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.LimitsRequest;
  return proto.notifications.LimitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.LimitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.LimitsRequest}
 */
proto.notifications.LimitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBettingPlaceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.LimitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.LimitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.LimitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.LimitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBettingPlaceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 betting_place_id = 1;
 * @return {number}
 */
proto.notifications.LimitsRequest.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.LimitsRequest} returns this
 */
proto.notifications.LimitsRequest.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string betting_place_name = 2;
 * @return {string}
 */
proto.notifications.LimitsRequest.prototype.getBettingPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.LimitsRequest} returns this
 */
proto.notifications.LimitsRequest.prototype.setBettingPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.CashOutOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.CashOutOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.CashOutOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.CashOutOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddId: jspb.Message.getFieldWithDefault(msg, 27, ""),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    currentValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    eventName: jspb.Message.getFieldWithDefault(msg, 31, ""),
    marketName: jspb.Message.getFieldWithDefault(msg, 32, ""),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 33, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResultPeriod.toObject(includeInstance, f),
    sportId: jspb.Message.getFieldWithDefault(msg, 43, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 45, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.CashOutOdd}
 */
proto.notifications.CashOutOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.CashOutOdd;
  return proto.notifications.CashOutOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.CashOutOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.CashOutOdd}
 */
proto.notifications.CashOutOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setOddId(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentValue(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    case 34:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 35:
      var value = new proto_common_common_pb.EventResultPeriod;
      reader.readMessage(value,proto_common_common_pb.EventResultPeriod.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.CashOutOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.CashOutOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.CashOutOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.CashOutOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getCurrentValue();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto_common_common_pb.EventResultPeriod.serializeBinaryToWriter
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      43,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string odd_id = 27;
 * @return {string}
 */
proto.notifications.CashOutOdd.prototype.getOddId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setOddId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional double odd_value = 28;
 * @return {number}
 */
proto.notifications.CashOutOdd.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double current_value = 29;
 * @return {number}
 */
proto.notifications.CashOutOdd.prototype.getCurrentValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setCurrentValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double limit = 30;
 * @return {number}
 */
proto.notifications.CashOutOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional string event_name = 31;
 * @return {string}
 */
proto.notifications.CashOutOdd.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string market_name = 32;
 * @return {string}
 */
proto.notifications.CashOutOdd.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string outcome_name = 33;
 * @return {string}
 */
proto.notifications.CashOutOdd.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional google.protobuf.Timestamp start = 34;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.notifications.CashOutOdd.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 34));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.notifications.CashOutOdd} returns this
*/
proto.notifications.CashOutOdd.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.CashOutOdd.prototype.hasStart = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional common.EventResultPeriod result = 35;
 * @return {?proto.common.EventResultPeriod}
 */
proto.notifications.CashOutOdd.prototype.getResult = function() {
  return /** @type{?proto.common.EventResultPeriod} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResultPeriod, 35));
};


/**
 * @param {?proto.common.EventResultPeriod|undefined} value
 * @return {!proto.notifications.CashOutOdd} returns this
*/
proto.notifications.CashOutOdd.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.CashOutOdd.prototype.hasResult = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional int32 sport_id = 43;
 * @return {number}
 */
proto.notifications.CashOutOdd.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};


/**
 * optional string landbase_code = 45;
 * @return {string}
 */
proto.notifications.CashOutOdd.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.notifications.CashOutOdd.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.CashOutOdd} returns this
 */
proto.notifications.CashOutOdd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.BroadcastDoublePrintRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.BroadcastDoublePrintRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.BroadcastDoublePrintRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 36, ""),
    betPlaceName: jspb.Message.getFieldWithDefault(msg, 41, ""),
    doublePrint: jspb.Message.getBooleanFieldWithDefault(msg, 37, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.BroadcastDoublePrintRequest}
 */
proto.notifications.BroadcastDoublePrintRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.BroadcastDoublePrintRequest;
  return proto.notifications.BroadcastDoublePrintRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.BroadcastDoublePrintRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.BroadcastDoublePrintRequest}
 */
proto.notifications.BroadcastDoublePrintRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetPlaceName(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoublePrint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.BroadcastDoublePrintRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.BroadcastDoublePrintRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.BroadcastDoublePrintRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getBetPlaceName();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getDoublePrint();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
};


/**
 * optional string name = 36;
 * @return {string}
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.BroadcastDoublePrintRequest} returns this
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string bet_place_name = 41;
 * @return {string}
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.getBetPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.BroadcastDoublePrintRequest} returns this
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.setBetPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional bool double_print = 37;
 * @return {boolean}
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.getDoublePrint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notifications.BroadcastDoublePrintRequest} returns this
 */
proto.notifications.BroadcastDoublePrintRequest.prototype.setDoublePrint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.BroadcastBlankSlipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.BroadcastBlankSlipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.BroadcastBlankSlipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 36, ""),
    betPlaceName: jspb.Message.getFieldWithDefault(msg, 41, ""),
    blankSlip: jspb.Message.getBooleanFieldWithDefault(msg, 42, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.BroadcastBlankSlipRequest}
 */
proto.notifications.BroadcastBlankSlipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.BroadcastBlankSlipRequest;
  return proto.notifications.BroadcastBlankSlipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.BroadcastBlankSlipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.BroadcastBlankSlipRequest}
 */
proto.notifications.BroadcastBlankSlipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetPlaceName(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlankSlip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.BroadcastBlankSlipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.BroadcastBlankSlipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.BroadcastBlankSlipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getBetPlaceName();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getBlankSlip();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
};


/**
 * optional string name = 36;
 * @return {string}
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.BroadcastBlankSlipRequest} returns this
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string bet_place_name = 41;
 * @return {string}
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.getBetPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.BroadcastBlankSlipRequest} returns this
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.setBetPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional bool blank_slip = 42;
 * @return {boolean}
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.getBlankSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notifications.BroadcastBlankSlipRequest} returns this
 */
proto.notifications.BroadcastBlankSlipRequest.prototype.setBlankSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.BettingPlacesNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.BettingPlacesNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.BettingPlacesNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.BettingPlacesNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 38, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.BettingPlacesNotification}
 */
proto.notifications.BettingPlacesNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.BettingPlacesNotification;
  return proto.notifications.BettingPlacesNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.BettingPlacesNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.BettingPlacesNotification}
 */
proto.notifications.BettingPlacesNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.BettingPlacesNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.BettingPlacesNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.BettingPlacesNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.BettingPlacesNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
};


/**
 * optional string text = 38;
 * @return {string}
 */
proto.notifications.BettingPlacesNotification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.BettingPlacesNotification} returns this
 */
proto.notifications.BettingPlacesNotification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    text: jspb.Message.getFieldWithDefault(msg, 38, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.Notification}
 */
proto.notifications.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.Notification;
  return proto.notifications.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.Notification}
 */
proto.notifications.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.notifications.Notification.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.Notification.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string text = 38;
 * @return {string}
 */
proto.notifications.Notification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.GetPushNotificationsResponse.repeatedFields_ = [39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.GetPushNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.GetPushNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.GetPushNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.GetPushNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.notifications.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.GetPushNotificationsResponse}
 */
proto.notifications.GetPushNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.GetPushNotificationsResponse;
  return proto.notifications.GetPushNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.GetPushNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.GetPushNotificationsResponse}
 */
proto.notifications.GetPushNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 39:
      var value = new proto.notifications.Notification;
      reader.readMessage(value,proto.notifications.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.GetPushNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.GetPushNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.GetPushNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.GetPushNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      39,
      f,
      proto.notifications.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Notification notifications = 39;
 * @return {!Array<!proto.notifications.Notification>}
 */
proto.notifications.GetPushNotificationsResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.notifications.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.Notification, 39));
};


/**
 * @param {!Array<!proto.notifications.Notification>} value
 * @return {!proto.notifications.GetPushNotificationsResponse} returns this
*/
proto.notifications.GetPushNotificationsResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 39, value);
};


/**
 * @param {!proto.notifications.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.Notification}
 */
proto.notifications.GetPushNotificationsResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 39, opt_value, proto.notifications.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.GetPushNotificationsResponse} returns this
 */
proto.notifications.GetPushNotificationsResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};


goog.object.extend(exports, proto.notifications);
