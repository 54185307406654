/* React modules */
import { ReactNode } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Loader from 'components/Loader/Loader';
import './LoaderWrapper.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

export interface LoaderWrapperProps {
  children: ReactNode;
  name: string | string[];
}

const LoaderWrapper = ({ children, name }: LoaderWrapperProps) => {
  const { loaderStore } = useStores();
  const { isActiveLoader } = loaderStore;

  const isActive = Array.isArray(name)
    ? name.some(isActiveLoader)
    : isActiveLoader(name);

  return (
    <div className={classnames('loader-wrapper', { active: isActive })}>
      {isActive ? <Loader /> : children}
    </div>
  );
};

export default observer(LoaderWrapper);
