/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Button } from 'components';
import TicketController from 'modules/ticket/ui/TicketSidebar/Ticket/TicketController';
import './Ticket.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface TicketProps {
  games?: any[];
  regular?: number;
  sistem?: any[];
}

enum TicketTypes {
  REGULAR = 'regular',
  SYSTEM = 'system',
}

const TicketSwitchConfirm = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="ticket-switch__content">
      <p>{t('slips.erase-multiple-odds')}</p>
      <p className="mt-4">{t('slips.switch-ticket')}</p>
      <div className="d-flex mt-5 justify-center">
        <Button className="mr-2 text-strong" bg="grey" onClick={onCancel}>
          {t('globals.no')}
        </Button>
        <Button className="ml-2 text-strong" bg="success" onClick={onConfirm}>
          {t('globals.yes')}
        </Button>
      </div>
    </div>
  );
};

const Ticket = observer(() => {
  const [activeTab, setActiveTab] = useState(TicketTypes.REGULAR);
  const { ticketBuilder, overlayStore } = useStores();

  const { t } = useTranslation();

  const canSwitchToRegular = () => {
    return ticketBuilder.events.every((e: any) => e.selectedOdds.length < 2);
  };

  const closeConfirmModal = () => {
    overlayStore.closeModal();
  };

  useEffect(() => {
    if (ticketBuilder.isSystem) {
      setActiveTab(TicketTypes.SYSTEM);
    } else {
      setActiveTab(TicketTypes.REGULAR);
    }
  }, [ticketBuilder.isSystem]);

  const onTicketSwitchConfirm = () => {
    ticketBuilder.clearMultipleOdds();
    ticketBuilder.setSystemTicket(false);
    setActiveTab(TicketTypes.REGULAR);
    closeConfirmModal();
  };

  const onTicketTypeChange = (key: string) => {
    if (key === TicketTypes.REGULAR && !canSwitchToRegular()) {
      overlayStore.openModal(
        <TicketSwitchConfirm
          onConfirm={onTicketSwitchConfirm}
          onCancel={closeConfirmModal}
        />,
        {
          width: 'medium',
          title: t('slips.regular-ticket'),
          className: 'ticket-switch',
        }
      );
    } else {
      ticketBuilder.setSystemTicket(key === TicketTypes.SYSTEM);
      setActiveTab(key as TicketTypes);
    }
  };

  return (
    <div className="ticket" data-testid="ticket">
      <div className="ticket__type">
        <div
          onClick={() => onTicketTypeChange('regular')}
          className={classnames('w-50', 'text-center', 'pointer', {
            active: activeTab === TicketTypes.REGULAR,
          })}
        >
          {t('slips.regular')}
        </div>
        <div
          onClick={() => onTicketTypeChange('system')}
          className={classnames('w-50', 'text-center', 'pointer', {
            active: activeTab === TicketTypes.SYSTEM,
          })}
        >
          {t('slips.system')}
        </div>
      </div>
      {activeTab === TicketTypes.REGULAR && <TicketController />}
      {activeTab === TicketTypes.SYSTEM && <TicketController isFix={false} />}
    </div>
  );
});

export default Ticket;
