/* React modules */

/* Our modules */
import './DisplayBetween.scss';

/* 3rd Party modules */

type displaySize = 'regular' | 'small';

interface DisplayBetweenProps {
  data: BeetweenRecord[];
  size: displaySize;
}

interface BeetweenRecord {
  action?: () => void;
  label: string;
  value: string | number;
}

const DisplayBetween = ({ data }: DisplayBetweenProps) => {
  return data.map(({ label, action, value }) => (
    <div key={`DisplayBetween-div-${value}`} className="display-between">
      <div>{label}</div>
      <>
        <span>{value}</span>
        <span onClick={action}>x</span>
      </>
    </div>
  ));
};

DisplayBetween.defaultProps = {
  size: 'regular',
};

export default DisplayBetween;
