/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import i18n from 'app/localization/i18n';
import { ChangePassErrors } from 'modules/auth/auth.models';
import useStores from 'common/hooks/useStores';
import { Input, Button } from 'components';
import SectionHeader from 'modules/user/ui/SectionHeader';
import Validators from 'libs/validations';
import { logger } from 'libs/common-helpers';
import './ChangePassword.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ERROR_MESSAGES: any = {
  [ChangePassErrors.WRONG_PASSWORD]: 'errors.bad-password',
  [ChangePassErrors.UNKNOWN]: 'errors.error-unknown',
};

const ChangePassword = observer(() => {
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState<any>({});

  const { authStore } = useStores();

  const onSubmit = (data: object) => {
    authStore
      .changePassword(data)
      .then(() => {
        toast.success(i18n.t('verification.successfully-changed-password'));
      })
      .catch((error: any) => {
        if (error.status === 400) {
          toast.error(t(ERROR_MESSAGES[ChangePassErrors.WRONG_PASSWORD]));
        } else if (error.status === 401) {
          toast.error(t(ERROR_MESSAGES[ChangePassErrors.UNKNOWN]));
        }

        logger('ChangePassword -> onSubmit -> error', error);
      });
  };

  const fieldValidations = {
    oldPassword: [Validators.required],
    newPassword: [
      Validators.required,
      Validators.minLength(6, t('errors.min-password')),
    ],
    confirmPassword: [
      Validators.required,
      Validators.match(t('errors.passwords-dont-match'), {
        value: newPassword,
        confirmValue: confirmPassword,
      }),
    ],
  };

  const updateField = (key: string, checkboxId?: string) => (e: any) => {
    const value = e.target.value;
    switch (key) {
      case 'oldPassword':
        setOldPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
    }
    validateField(key)({ target: { value: value } });
  };

  const validateField = (key: string) => (e?: any) => {
    const validations = fieldValidations[key as keyof typeof fieldValidations];

    if (validations) {
      const error = (validations as Array<any>).find(
        (v: any) => v && !v.validate(e.target.value)
      );
      if (error) {
        setErrors({ ...errors, [key]: error.message });
      } else {
        setErrors({ ...errors, [key]: null });
      }
    }
  };

  useEffect(() => {
    if (newPassword !== '') {
      setNewPassword(newPassword);
      validateField('password')({ target: { value: newPassword } });
      if (confirmPassword !== '') {
        validateField('confirmPassword')({
          target: { value: confirmPassword },
        });
      }
    }
  }, [newPassword]);

  useEffect(() => {
    if (confirmPassword !== '') {
      setConfirmPassword(confirmPassword);
      validateField('confirmPassword')({ target: { value: confirmPassword } });
    }
  }, [confirmPassword]);

  const isSubmitDisabled =
    errors.confirmPassword ||
    confirmPassword === '' ||
    errors.newPassword ||
    newPassword === '' ||
    errors.oldPassword ||
    oldPassword === '';

  return (
    <div>
      <SectionHeader title={t('userData.change-password')} />
      <div className="change-password">
        <div className="change-password__content">
          <div className="mb-3">
            <label className="text-small text-white">
              {t('userData.current-password')}
            </label>
            <Input
              bg="light"
              name="oldPassword"
              type="password"
              value={oldPassword}
              onChange={updateField('oldPassword')}
              onBlur={validateField('oldPassword')}
            />
          </div>
          <div className="mb-3">
            <label className="text-small text-white">
              {t('userData.new-password')}
            </label>
            <Input
              bg="grey"
              name="new-password"
              type="password"
              error={errors.newPassword}
              value={newPassword}
              onChange={updateField('newPassword')}
              onBlur={validateField('newPassword')}
            />
          </div>
          <div className="mb-3">
            <label className="text-small text-white">
              {t('userData.confirm-password')}
            </label>
            <Input
              bg="grey"
              name="confirmPassword"
              type="password"
              error={errors.confirmPassword}
              value={confirmPassword}
              onChange={updateField('confirmPassword')}
              onBlur={validateField('confirmPassword')}
            />
          </div>
          <div className="d-flex justify-center mt-8">
            <Button
              className="w-50 uppercase"
              bg="success"
              disabled={isSubmitDisabled}
              size="large"
              onClick={() =>
                onSubmit({
                  old_password: oldPassword,
                  new_password: newPassword,
                })
              }
            >
              {t('globals.save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChangePassword;
