/* React modules */

/* Our modules */
import { CasinoErrors } from 'modules/gambling/gambling.types';
import useStores from 'common/hooks/useStores';
import Login from 'modules/auth/ui/Login/Login';
import GamblingCasinoModal from 'modules/gambling/ui/GamblingCasinoModal/GamblingCasinoModal';
import { logger } from 'libs/common-helpers';
import './SingleBanner.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const SingleBanner = observer(({ banner }: any) => {
  const { authStore, overlayStore, gamblingStore, userStore } = useStores();

  const { t } = useTranslation();

  const playGame = () => {
    if (authStore.isLoggedIn) {
      startGame();
    } else {
      openLoginModal();
    }
  };

  const startGame = async () => {
    overlayStore.openModal(<GamblingCasinoModal />, {
      wrapClassName: 'gambling-casino-modal-wrap',
      gameName: banner.casino_game.game_name,
      onClose: () => userStore.getUserData(),
      width: 'full-screen',
    });
    try {
      await gamblingStore.getGameUrl(banner.casino_game.id, authStore.token);
    } catch (exception: any) {
      overlayStore.closeModal();
      handleCasinoError(exception);

      logger('SingleBanner -> startGame -> exception', exception);
    }
  };

  const openLoginModal = () => {
    const modalContent = (
      <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
    );

    overlayStore.openModal(modalContent, {
      className: 'login-modal',
      width: 'small',
    });
  };

  const handleCasinoError = (error: any) => {
    if (error?.data.detail === CasinoErrors.USER_BLOCKED) {
      toast.error(t('errors.user-blocked-error'));
    }
    if (error.status === 500) {
      toast.error(t('errors.error-starting-game'));
    }
  };

  return (
    <>
      {banner?.banner_type === 'no_action' && (
        <a href="#" target="_self">
          <img
            className="single-banner"
            src={process.env.REACT_APP_BANNER_URL + '/' + banner.img_url}
            alt=""
          />
        </a>
      )}

      {banner?.banner_type === 'external_link' && (
        <a href={banner.link} target="_blank">
          <img
            className="single-banner"
            src={process.env.REACT_APP_BANNER_URL + '/' + banner.img_url}
            alt=""
          />
        </a>
      )}

      {banner?.banner_type === 'internal_link' && (
        <a href={banner.link} target="_self">
          <img
            className="single-banner"
            src={process.env.REACT_APP_BANNER_URL + '/' + banner.img_url}
            alt=""
          />
        </a>
      )}

      {banner?.banner_type === 'casino_game' && (
        <a onClick={playGame}>
          <img
            className="single-banner"
            src={process.env.REACT_APP_BANNER_URL + '/' + banner.img_url}
            alt=""
          />
        </a>
      )}
    </>
  );
});

export default SingleBanner;
