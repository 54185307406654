/* React modules */

/* Our modules */
import LoginForm, { LoginFormProps } from 'modules/auth/ui/Login/LoginForm';
import { LoaderWrapper } from 'components/Loader';
import './Login.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

interface LoginProps extends LoginFormProps {}

const Login = ({ onSuccess, isModal }: LoginProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isModal && (
        <div className="login-modal__header">
          <h3 className="heading-lg text-center">{t('login.login')}</h3>
        </div>
      )}
      <LoaderWrapper name="login-form">
        <div className="login-modal__content">
          <LoginForm isModal={isModal} onSuccess={onSuccess} />
        </div>
      </LoaderWrapper>
    </>
  );
};

export default Login;
