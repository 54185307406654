/* React modules */

/* Our modules */
import Icon from 'components/Icon';
import './_home-footer.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

const HomeFooter = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="bg-green-700 home-footer">
      <div className="d-flex justify-center web-address-container">
        <p className="text-center pl-10 pr-20 web-address">www.sbbet.me</p>
        <p className="d-flex align-items bg-green-700 text-black-400 established">
          EST.1997
        </p>
      </div>

      <div className="d-flex space-between align-items footer-content">
        <div className="d-flex align-items fw-bold follow-us">
          <p>{t('home.follow-us')}:</p>

          <div className="d-flex">
            <a
              href="https://www.instagram.com/sbbet.me/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-network-icon">
                <Icon name="instagram2" size="extra-large" />
              </div>
            </a>

            <a
              href="https://www.facebook.com/sbbet.me/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-network-icon">
                <Icon name="facebook2" size="extra-large" />
              </div>
            </a>

            <a
              href="https://me.linkedin.com/company/sporting-group"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-network-icon">
                <Icon name="linkedIn" size="extra-large" />
              </div>
            </a>

            <a
              href="https://www.youtube.com/@sbbet6754"
              target="_blank"
              rel="noreferrer"
            >
              <div className="social-network-icon">
                <Icon name="youTube" size="extra-large" />
              </div>
            </a>
          </div>
        </div>

        <div className="d-flex align-items">
          <div>
            <Icon
              name="callCenter"
              size="extra-large"
              className="icon-black-400 call-center-icon"
            />
          </div>

          <div className="text-center call-center">
            <p>{t('home.call-center')}</p>
            <p>19975</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HomeFooter;
