/* React modules */
import { useEffect } from 'react';

/* Our modules */
import './LogateChat.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

export default function LogateChat() {
  const { t } = useTranslation();

  useEffect(() => {
    const currentWindow = window as any;

    window.setTimeout(() => {
      if (currentWindow.LogateChat && currentWindow.LogateChat.showChat) {
        currentWindow.LogateChat.showChat();
      }
    }, 1000);
  }, []);

  return (
    <div className="logate-chat">
      <p className="text-strong">{t('globals.loading')}</p>
    </div>
  );
}
