/* React modules */

/* Our modules */
import { Sport, Location } from 'modules/sports/sports.types';

/* 3rd Party modules */

const sportSort = {
  fudbal: 1,
  košarka: 2,
  tenis: 3,
  rukomet: 4,
  hokej: 5,
  odbojka: 6,
} as any;

export const parseSportsMapToArray = (sports: any) => {
  const sportsMap = new Map(sports?.sportsMap);
  const sportsList: any = [];
  sportsMap.forEach((sport: any) => {
    sportsList.push(sport);
    const locations = new Map(sport.locationsMap);
    sport.locationsList = [];
    locations.forEach((location: any) => {
      sport.locationsList.push(location);
      location.competitionsList = [];
      const competitions = new Map(location.competitionsMap);
      competitions.forEach((competition: any) => {
        location.competitionsList.push(competition);
      });
    });
  });

  return sportsList;
};

export const parseMarketsMapToArray = (markets: any) => {
  const marketGroupsList = new Map(markets?.marketGroupsMap);
  let marketGroups: any = [];
  marketGroupsList.forEach((mg: any) => {
    marketGroups.push(mg);
    const markets = new Map(mg.marketsMap);
    mg.marketsList = [];
    markets.forEach((market: any) => {
      mg.marketsList.push(market);
      const outcomes = new Map(market.outcomesMap);
      market.outcomesList = [];
      outcomes.forEach((outcome: any) => {
        market.outcomesList.push(outcome);
      });
    });
  });

  return marketGroups;
};

const sortSports = (a: Sport, b: Sport) => {
  if (!sportSort[a.name] && sportSort[b.name]) {
    return 7 - sportSort[b.name];
  }

  if (sportSort[a.name] && !sportSort[b.name]) {
    return sportSort[a.name] - 7;
  }

  return sportSort[a.name] - sportSort[b.name];
};

const sortLocations = (a: Location, b: Location) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const sortCompetitions = (sportsList: any) => {
  // locationOrder
  // SORT COMPETITIONS PER COMPETITION ORDER, THEN PER COMPETITION NAME
  sportsList.forEach((sport: any) => {
    sport.locationsList.forEach((location: any) => {
      location.competitionsList.sort((a: any, b: any) => {
        if (a.competitionOrder > b.competitionOrder)
          return a.competitionOrder > b.competitionOrder ? 1 : -1;
        return a.locationOrder > b.locationOrder ? 1 : -1;
      });
    });
  });

  return sportsList;
};

export const sortSportsList = (sportList: Array<Sport>) =>
  sportList
    .map((sport) => ({
      ...sport,
      locationsList: sport.locationsList.sort(sortLocations),
    }))
    .sort(sortSports);

interface TopLeaguesTypes {
  fudbal: string[];
  košarka: string[];
  rukomet: string[];
  hokej: string[];
}

const topLeagues: TopLeaguesTypes = {
  fudbal: [
    'ENGLESKA 1',
    'FRANCUSKA 1',
    'ITALIJA 1',
    'NJEMAČKA 1',
    'NJEMAČKA KUP',
    'PORTUGAL 1',
    'ITALIJA KUP',
    'SRBIJA 1',
    'ŠPANIJA 1',
    'ŠPANIJA KUP',
  ],
  košarka: ['NBA', 'EUROCUP', 'EUROLEAGUE', 'ŠPANIJA 1'],
  rukomet: ['CHAMPIONS LEAGUE'],
  hokej: ['NHL'],
};

export const isTopLeague = (sportName: string, leagueName: string) => {
  const sportTopLeagues = topLeagues[sportName as keyof TopLeaguesTypes];
  if (sportTopLeagues) {
    return sportTopLeagues.includes(leagueName.toUpperCase());
  }
};

export const getAllLeaguesReducer = (accumulator: any, value: any) => {
  return [...accumulator, ...value.competitionsList];
};
