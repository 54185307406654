import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './Responsive.scss';

export enum Devices {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
  DESKTOP = 'desktop',
}

export type Device =
  | Devices.MOBILE
  | Devices.TABLET
  | Devices.LAPTOP
  | Devices.DESKTOP;

interface BaseProps {
  display?: 'flex' | 'block';
  containerClassName?: string;
  className?: string;
}

interface ResponsiveWithChildren extends BaseProps {
  children: ReactNode;
  visibleFrom?: Device;
  visibleTo?: Device;
  toMobile?: never;
  toTablet?: never;
  fromTablet?: never;
  toLaptop?: never;
  fromLaptop?: never;
  toDesktop?: never;
  fromDesktop?: never;
}

interface CustomConfig extends BaseProps {
  toMobile?: ReactNode;
  toTablet?: ReactNode;
  fromTablet?: ReactNode;
  toLaptop?: ReactNode;
  fromLaptop?: ReactNode;
  toDesktop?: ReactNode;
  fromDesktop?: ReactNode;
  children?: never;
  visibleFrom?: never;
  visibleTo?: never;
}

type ResponsiveProps = ResponsiveWithChildren | CustomConfig;

const Responsive = (props: ResponsiveProps) => {
  const { display, containerClassName, className } = props;

  const visibleFrom = 'visibleFrom' in props ? props.visibleFrom : null;
  const visibleTo = 'visibleTo' in props ? props.visibleTo : null;
  const children = 'children' in props ? props.children : null;
  const toMobile = 'toMobile' in props ? props.toMobile : null;
  const fromTablet = 'fromTablet' in props ? props.fromTablet : null;
  const toTablet = 'toTablet' in props ? props.toTablet : null;
  const fromLaptop = 'fromLaptop' in props ? props.fromLaptop : null;
  const toLaptop = 'toLaptop' in props ? props.toLaptop : null;
  const fromDesktop = 'fromDesktop' in props ? props.fromDesktop : null;
  const toDesktop = 'toDesktop' in props ? props.toDesktop : null;

  const visibleFromClass = visibleFrom ? `from-${visibleFrom}` : '';
  const visibleToClass = visibleTo ? `to-${visibleTo}` : '';

  return (
    <div className={classnames('responsive', containerClassName)}>
      {children && (
        <div
          className={classnames(
            visibleFromClass,
            visibleToClass,
            display,
            className
          )}
        >
          {children}
        </div>
      )}

      {toMobile && (
        <div className={classnames('to-mobile', display, className)}>
          {toMobile}
        </div>
      )}

      {fromTablet && (
        <div className={classnames('from-tablet', display, className)}>
          {fromTablet}
        </div>
      )}

      {toTablet && (
        <div className={classnames('to-tablet', display, className)}>
          {toTablet}
        </div>
      )}

      {toLaptop && (
        <div className={classnames('to-laptop', display, className)}>
          {toLaptop}
        </div>
      )}

      {fromLaptop && (
        <div className={classnames('from-laptop', display, className)}>
          {fromLaptop}
        </div>
      )}

      {toDesktop && (
        <div className={classnames('to-desktop', display, className)}>
          {toDesktop}
        </div>
      )}

      {fromDesktop && (
        <div className={classnames('from-desktop', display, className)}>
          {fromDesktop}
        </div>
      )}
    </div>
  );
};

Responsive.defaultProps = {
  display: 'flex',
  containerClassName: '',
  className: '',
};

export default Responsive;
