/* React modules */

/* Our modules */
import { NotificationsServiceClient } from 'proto/notifications/NotificationsServiceClientPb';
import { UserConfirmation } from 'proto/notifications/notifications_pb';
import { NOTIFICATIONS_SOCKET_ENDPOINT } from 'modules/notifications/notifications-constants';
import ProtobufApi from 'libs/protobufApi';
import { getApiUrl, getSocketUrl } from 'libs/urlBuilder';
import { parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */

class NotificationsApi {
  private socket: WebSocket | null = null;
  private client: NotificationsServiceClient;
  private api: ProtobufApi;
  private reconnecting = false;

  constructor() {
    this.client = new NotificationsServiceClient(getApiUrl(), {}, {});
    this.api = new ProtobufApi(this.client);
  }

  initSocket(token: string) {
    const socketUrl = getSocketUrl(
      `${NOTIFICATIONS_SOCKET_ENDPOINT}/${token}/`
    );

    this.socket = new WebSocket(socketUrl);
  }

  reconnect = (onMessage: any, token: any) => {
    this.reconnecting = false;
    setTimeout(() => {
      this.connect(onMessage, token);
    }, 3000);
  };

  confirmMessageReceived = (id: string, accessToken: string) => {
    const request = new UserConfirmation();
    request.setUuid(id);
    return this.api.request('confirmNotification', [request, { accessToken }]);
  };

  connect(onMessage: (message: any) => void, token: string) {
    this.initSocket(token);

    if (this.socket) {
      this.socket.addEventListener('message', ({ data }: any) => {
        const message = parseJSONString(data);

        if (message) {
          onMessage(message);
        }
      });

      this.socket.onopen = (event) => {
        this.reconnecting = false;
      };

      this.socket.onclose = (event) => {
        if (event.code === 1006 && !this.reconnecting)
          this.reconnect(onMessage, token);
      };
      this.socket.onerror = (event) => {
        if (!this.reconnecting) this.reconnect(onMessage, token);
      };
    }
  }

  disconnect() {
    if (this.socket) this.socket.close();
  }
}

export default NotificationsApi;
