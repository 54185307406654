/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { FOOTER_THUMBNAILS } from 'modules/payment/payment.constants';
import Responsive, { Devices } from 'components/Responsive/Responsive';
import Icon from 'components/Icon';
import { useExternalLink } from 'libs/useExternalLink';

import RSCG from 'images/RSCG.svg';
import './Footer.scss';

/* 3rd Party modules */
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Footer = observer(() => {
  const { t } = useTranslation();
  const { authStore, sportsStore } = useStores();
  const { handleExternalLink } = useExternalLink();

  const onOpenExternalLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    handleExternalLink(event, 'poker', null);
  };

  return (
    <Responsive visibleFrom={Devices.LAPTOP} className="footer">
      <div className="d-block text-center space-between w-100">
        <div className="site-map">
          <div className="d-flex space-between site-map__inner">
            <h5 className="heading-regular">{t('footer.site-map')}</h5>
            <h5 className="heading-regular">{t('footer.informations')}</h5>
          </div>
        </div>
        <div className="d-flex pt-4 text-left sb-text-regular site-map">
          <div className="site-map__inner d-flex space-between ">
            <div className="w-30">
              <p className="item">
                <Link to="/sport">{t('footer.betting')}</Link>
              </p>
              <p className="item">
                <Link to="/gambling">{t('footer.casino')}</Link>
              </p>
              <p className="item">
                <Link to="/gambling-live">{t('footer.casino-live')}</Link>
              </p>
              <p className="item">
                <Link to="/quick-games">{t('footer.lucky-6')}</Link>
              </p>
              <p className="item">
                <Link to="/aviator">{t('footer.aviator')}</Link>
              </p>
              {sportsStore.isPokerEnabled ? (
                <p className="item">
                  <Link to="#" onClick={onOpenExternalLink}>
                    {t('footer.poker')}
                  </Link>
                </p>
              ) : null}
              <p className="item">
                <a target="blank" href="https://promo.sbbet.me/">
                  {t('footer.promotions')}
                </a>
              </p>
            </div>
            <div className="text-center">
              {authStore.isLoggedIn && (
                <>
                  <p className="item">
                    <Link to="/profil/transakcije">
                      {t('footer.transactions')}
                    </Link>
                  </p>
                  <p className="item">
                    <Link to="/profil/promo-kod">{t('footer.promo-code')}</Link>
                  </p>
                </>
              )}
              <p className="item">
                <a target="blank" href="https://help.sbbet.me/pitanja/">
                  {t('footer.frequently-questions')}
                </a>
              </p>
              <p className="item">
                <a target="blank" href="https://promo.sbbet.me/preuzimanje/">
                  {t('footer.download-list')}
                </a>
              </p>
            </div>
            <div className="w-30 text-right">
              <p className="item">
                <a target="blank" href="https://help.sbbet.me/o-nama/">
                  {t('footer.about-us')}
                </a>
              </p>
              <p className="item">
                <a href="https://help.sbbet.me/kontakt/" target="blank">
                  {t('footer.contact')}
                </a>
              </p>
              <p className="item">
                <a target="blank" href="https://help.sbbet.me/privatnost/">
                  {t('footer.privacy')}
                </a>
              </p>
              <p className="item">
                <a target="blank" href="https://help.sbbet.me/pravila/">
                  {t('footer.game-rules')}
                </a>
              </p>
              <p className="item">
                <a target="blank" href="https://help.sbbet.me/kolacici/">
                  {t('footer.cookies')}
                </a>
              </p>
              <p className="item">
                <a
                  target="blank"
                  href="https://help.sbbet.me/uslovikoristenja/"
                >
                  {t('footer.using-conditions')}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-center align-items w-100 pt-5 footer__payments">
          {FOOTER_THUMBNAILS.map((thumbnail, index) => (
            <a
              key={`Footer-img-${index}`}
              className="d-flex"
              href={thumbnail.link}
              target="blank"
            >
              <img
                src={thumbnail.src}
                alt={thumbnail.alt}
                className="px-3 footer__payment-thumbnails"
                data-testid="footer-thumbnail"
              />
            </a>
          ))}
        </div>
        <div className="social pt-5 text-grey-300">
          <div>
            <h6 className="text-sb-heading">{t('footer.follow-us')}</h6>
            <div className="d-flex space-around pt-1">
              <a
                href="https://www.facebook.com/sbbet.me/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  size="extra-large"
                  name="facebook"
                  className="social__icon"
                />
              </a>
              <a
                href="https://www.instagram.com/sbbet.me/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  size="extra-large"
                  name="instagram"
                  className="social__icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="description pb-6 pt-6 sb-text-small">
          <p>{t('footer.description')}</p>
        </div>
        <div className="footer-bar bg-black d-flex align-items">
          <div className="logo-wrapper pl-10">
            <Link to="/sport">
              <img
                src={process.env.PUBLIC_URL + '/assets/logo.svg'}
                className="logo"
                alt="SBbet"
              />
            </Link>
            <p className="pt-2 pl-3 sb-heading">
              {new Date().getFullYear()} © {t('footer.all-rights-reserved')}
            </p>
          </div>
          <div className="d-flex align-items justify-center pr-10">
            <a href="https://www.rscg.me" target="_blank" rel="noreferrer">
              <img src={RSCG} alt="RSCG" className="footer-bar__icon" />
            </a>
            <div className="play-responsibly text-grey-300">18+</div>
            <p className="pl-3 sb-heading text-grey-300">
              {t('footer.play-responsibly')}
            </p>
          </div>
        </div>
      </div>
    </Responsive>
  );
});

export { Footer };
