/* React modules */

/* Our modules */
import { CasinoSectionGame } from 'modules/gambling/gambling.types';
import useStores from 'common/hooks/useStores';
import GamesCarousel from 'modules/gambling/ui/GamesCarousel';
import GamesListHeader from 'modules/gambling/ui/GamesListHeader';
import Leaderboard from 'modules/gambling/ui/GamblingHomepage/Leaderboard/Leaderboard';
import './GamesSlider.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

interface GamesSliderProps {
  onClick: () => void;
  title: string;
  sliderConfig: object;
  gamesList: CasinoSectionGame[];
  isMobile: boolean;
  hover: boolean;
  sectionId?: number;
  variant?: 'horizontal' | 'biggest-win';
}

const GamesSlider = ({
  onClick,
  title,
  sliderConfig,
  gamesList,
  isMobile,
  hover,
  sectionId,
  variant,
}: GamesSliderProps) => {
  let slider: any;
  const nextSlide = () => slider.slickNext();
  const prevSlide = () => slider.slickPrev();
  const { gamblingStore } = useStores();
  const { leaderBoards } = gamblingStore;

  const tag = leaderBoards?.find(
    (tag: any) => tag?.web_tag === sectionId || tag?.mobile_tag === sectionId
  );

  if (sectionId) {
    return (
      <div className="games-list__slider">
        <GamesListHeader
          onClick={onClick}
          title={title}
          nextHandler={nextSlide}
          previousHandler={prevSlide}
          isMobile={isMobile}
        />
        {tag && <Leaderboard tag={tag} />}
        <GamesCarousel
          variant={variant}
          carouselConfig={sliderConfig}
          gamesList={gamesList}
          hasJackpot
          refHandler={(reference) => (slider = reference)}
          size={isMobile ? 'small' : 'large'}
          hover={hover}
          controls="horizontal"
          nextHandler={nextSlide}
          previousHandler={prevSlide}
        />
      </div>
    );
  }
  return <div></div>;
};

GamesSlider.defaultProps = {
  hover: true,
};

export default observer(GamesSlider);
