function isNumber(data: any) {
  return typeof data === 'number' && Number.isFinite(data);
}

function hasProperty(data: any, key: string) {
  return data && key ? Object.prototype.hasOwnProperty.call(data, key) : false;
}

function logger(messageText: string, data?: any) {
  console.log(
    `%c\n========== ${messageText} ==========${
      arguments.length === 1 ? '\n' : ''
    }`,
    'background: #222; color: #bada55'
  );

  if (arguments.length === 2) {
    console.log(data);
    console.log(typeof data);
    console.log('\n');
  }
}

function parseJSONString(text: string) {
  try {
    return JSON.parse(text);
  } catch {
    logger('parseJSONString -> text', text);

    return null;
  }
}

export { isNumber, hasProperty, logger, parseJSONString };
