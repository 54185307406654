/* React modules */
import { useEffect, useContext } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Odd from 'modules/sports/store/odd.store';
import useHighlight from 'modules/offer/ui/Offer/OddCell/use-highlight';
import { OddsMarketsContext } from 'pages/sport/EventAllOddsOverlay/EventAllOddsOverlay';
import { Icon } from 'components';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const SingleOdd = ({ odd }: any) => {
  const event = useContext(OddsMarketsContext);
  const { ticketBuilder } = useStores();
  const { t } = useTranslation();

  const { isUp, isDown } = useHighlight(odd);
  const isSuspended = odd.isDisabled;
  /** Start listening for odd updates in live feed */
  useEffect(() => {
    odd.connectToFeed();

    return () => {
      const initial = event.odds.find((o: Odd) => o.id === odd.id);

      if (!odd.isSelected && !initial) {
        odd.disconnectFromFeed();
      }
    };
  }, [odd, event.odds]);

  const outcomeGroupsToShow = [1509, 1510, 1511, 1512, 1513, 1514, 1515, 1516];

  const handleSelect = () => {
    if (!isSuspended) {
      ticketBuilder.onOddSelected({ ...event, odd });
    }
  };

  const classes = {
    down: !isSuspended && isDown(),
    up: !isSuspended && isUp(),
  };

  return (
    <>
      <div
        onClick={handleSelect}
        className={classNames(`all-odds-overlay__odd-wrapper`, {
          active: odd.isSelected,
          'not-suspended': !isSuspended,
          suspended: isSuspended,
        })}
      >
        {outcomeGroupsToShow.includes(odd.outcome.id) && (
          <div className="d-flex align-items text-elipsis">
            <p className="text-ellipsis">
              {t(`globals.${odd.outcome.outcomeGroup}`)} &nbsp;
            </p>
          </div>
        )}
        <div className="d-flex align-items">{odd.outcomeName}</div>

        {isSuspended && (
          <Icon name="lockSmall" variant="warning" size="small" />
        )}

        <div className={classNames('all-odds-overlay__odd', classes)}>
          <span className="all-odds-overlay__odd-value">
            {odd.displayValue}
          </span>

          {!isSuspended && (
            <span className="all-odds-overlay__odd-caret ml-1">
              {isDown() && (
                <Icon name="caretDown" size="extra-small" variant="danger" />
              )}
              {isUp() && (
                <Icon name="caretUp" size="extra-small" variant="success" />
              )}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(SingleOdd);
