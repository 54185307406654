import React from 'react';

import './Spinner.scss';

type SpinnerSize = 'small' | 'regular' | 'large';

interface SpinnerProps {
  size: SpinnerSize;
}

const Spinner = ({ size }: SpinnerProps) => (
  <div className="spinner">
    <div className="shadow" />
    <div className="gravity">
      <div className="ball" />
    </div>
  </div>
);

export { Spinner };
