import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './Anchor.scss';

export interface AnchorProps {
  url: string;
  text: string;
  className?: string;
  testid: string;
}

const Anchor = ({ url, className, text, testid }: AnchorProps) => {
  return (
    <Link to={url}>
      <span
        data-testid={testid}
        className={classnames('anchor', { className })}
      >
        {text}
      </span>
    </Link>
  );
};

export default Anchor;
