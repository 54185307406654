/* React modules */
import { useState, useEffect, useCallback } from 'react';

/* Our modules */
import { WithdrawalErrors } from 'modules/payment/payment.types';
import useStores from 'common/hooks/useStores';
import loaderStore from 'components/Loader/loader.store';
import { paymentStore } from 'modules/payment/payment.store';
import { Input, Button } from 'components';
import CreditDebitInfoBar from 'modules/user/ui/UserCreditDebit/CreditDebitInfoBar';
import BankAccountInput from 'modules/user/ui/BankAccountInput';
import CancelWithdrawalConfirm from 'modules/payment/ui/CancelWithdrawalConfirm';
import Validators from 'libs/validations';
import { logger } from 'libs/common-helpers';
import '../Credit.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const WithdrawForm = ({ user, onSuccess }: any) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const [formErrors, setFromErrors] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [bankAccountNumber, setBankAccountNumber] = useState<string[]>([]);
  const bankAccountNumberHandler = (value: any) => {
    setBankAccountNumber(value);
  };

  const [bankName, setBankName] = useState();
  const bankNameHandler = (e: any) => {
    setBankName(e.target.value);
  };

  const [amount, setAmount] = useState();
  const setAmountHandler = (e: any) => {
    setAmount(e.target.value);
  };

  const fieldValidations = {
    bank_account_number: [Validators.required],
    bank_name: [Validators.required],
    amount: [Validators.required],
  };

  const validateField = (key: string) => (e?: any) => {
    const validations = fieldValidations[key as keyof typeof fieldValidations];

    if (validations) {
      const error = (validations as Array<any>).find(
        (v: any) => v && !v.validate(e.target.value)
      );
      if (error) {
        setFromErrors({ ...formErrors, [key]: t(error.message) });
      } else {
        setFromErrors({ ...formErrors, [key]: null });
      }
    }
  };

  const handleWithdrawalError = (error: string | any) => {
    if (error === WithdrawalErrors.NOT_ENOUGH_FUNDS) {
      setError(t('validations.validation-balance'));
    } else if (error?.includes('ERR_AMOUNT_OVER_EXCEEDED:')) {
      setError(
        t('errors.withdrawal-max-amount-exceeded', { a: error.split(':')[1] })
      );
    } else if (error?.includes('ERR_AMOUNT_UNDER_ALLOWED:')) {
      setError(
        t('errors.withdrawal-min-amount-exceeded', { a: error.split(':')[1] })
      );
    } else if (error === WithdrawalErrors.USER_BLOCKED) {
      setError(t('errors.user-blocked-error'));
    } else if (error === WithdrawalErrors.USER_NOT_VALIDATED) {
      setError(t('validations.validation-not-validated'));
    } else if (error === WithdrawalErrors.TEST_USERS_CANNOT_WITHDRAW_MONEY) {
      setError(
        t(`errors.${WithdrawalErrors.TEST_USERS_CANNOT_WITHDRAW_MONEY}`)
      );
    } else {
      setError(t('errors.system-error'));
    }
  };

  const submitWithdrawal = async () => {
    loaderStore.addLoader('withdraw-bank');
    setError(null);

    try {
      await paymentStore.submitBankWithdrawal({
        amount,
        bank_number: bankAccountNumber.join('-'),
        bank_name: bankName,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
      });

      toast.success(t('payments.withdrawal-success'));
      onSuccess();
    } catch (exception: any) {
      handleWithdrawalError(exception.detail);

      logger('WithdrawForm -> submitWithdrawal -> exception', exception);
    } finally {
      loaderStore.removeLoader('withdraw-bank');
    }
  };

  const hasErrors = useCallback(
    () => Object.keys(formErrors).filter((key) => formErrors[key]).length,
    [formErrors]
  );

  const hasValues = useCallback(
    () => bankAccountNumber.length && bankName && amount,
    [bankAccountNumber, bankName, amount]
  );

  useEffect(() => {
    if (hasErrors() || !hasValues()) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [hasErrors, hasValues]);

  return (
    <div className="credit__bank-form">
      <div className="sb-text-regular text-white">
        <div className="d-flex">
          <div className="mr-2">{t('userData.first-name')}:</div>
          <div>{user?.first_name}</div>
        </div>
        <div className="d-flex">
          <div className="mr-2">{t('userData.last-name')}:</div>
          <div>{user?.last_name}</div>
        </div>
        <div className="d-flex">
          <div className="mr-2">{t('userData.address')}:</div>
          <div>{user?.address}</div>
        </div>
        <div className="d-flex">
          <div className="mr-2">{t('userData.city')}:</div>
          <div>{user?.city}</div>
        </div>
      </div>
      <div className="bg-black-400 pb-10">
        <section>
          <div className="mt-6">
            <label className="text-white sb-text-regular">
              {t('userData.bank-account-number')}
            </label>
            <BankAccountInput
              className="mt-1 mr-1"
              onChange={bankAccountNumberHandler}
              value={bankAccountNumber}
              height="large"
              onBlur={validateField('bank_account_number')}
              error={formErrors.bank_account_number}
            />
          </div>
          <div className="mt-6">
            <Input
              className="w-100 custom_amount__input mt-1 mr-1"
              name="amount-custom-input"
              height="large"
              bg="light"
              placeholder={t('userData.bank-name')}
              onChange={bankNameHandler}
              value={bankName}
              onBlur={validateField('bank_name')}
              error={formErrors.bank_name}
            />
          </div>
          <div className="mt-6">
            <Input
              className="w-100 custom_amount__input mt-1 mr-1"
              name="amount-custom-input"
              height="large"
              bg="light"
              placeholder={t('payments.amount')}
              onChange={setAmountHandler}
              value={amount}
              type="number"
              onBlur={validateField('amount')}
              error={formErrors.amount}
            />
          </div>

          <div className="d-flex mx-12 mt-3">
            <span className="text-red">{error}</span>
          </div>

          <div className="sb-text-small text-white mt-3">
            {t('payments.in-the-bank-note')}
          </div>

          <div className="text-center mt-8">
            <Button
              className="__btn mt-1 w-40"
              style={{ color: 'black' }}
              size="large"
              bg="warning"
              onClick={submitWithdrawal}
              disabled={isDisabled}
            >
              {t('globals.submit')}
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

const BankCredit = observer(() => {
  const { t } = useTranslation();
  const { userStore, overlayStore } = useStores();
  const [activeWithdrawal, setActiveWithdrawal] = useState<any | null>(null);

  const [minCreditBank, setMinCreditBank] = useState<number | null>(null);
  const [maxCreditBank, setMaxCreditBank] = useState<number | null>(null);

  const getActiveBankWithdrawal = () => {
    paymentStore.getBankWithdrawalReservation().then(({ data }: any) => {
      setActiveWithdrawal(data);
    });
  };

  const onWithdrawalSuccess = () => {
    userStore.getUserData();
    getActiveBankWithdrawal();
  };

  const confirmCancelWithdrawal = async () => {
    loaderStore.addLoader('withdraw-bank');

    try {
      const { data } = await paymentStore.cancelBankWithdrawal();
      if (data.detail === 'ERR_RESERVATION_LOCKED') {
        userStore.getUserData();
        getActiveBankWithdrawal();
        toast.error(t('errors.reservation-locked'));
      } else {
        setActiveWithdrawal(null);
        userStore.getUserData();
        toast.success(t('payments.cancel-withdrawal-success'));
      }
    } catch (exception: any) {
      toast.error(t('errors.system-error'));

      logger('WithdrawForm -> confirmCancelWithdrawal -> exception', exception);
    } finally {
      loaderStore.removeLoader('withdraw-bank');
    }
  };

  const cancelWithdrawal = () => {
    overlayStore.openModal(
      <CancelWithdrawalConfirm onConfirm={confirmCancelWithdrawal} />,
      { width: 'medium' }
    );
  };

  useEffect(() => {
    getActiveBankWithdrawal();

    async function fetchMinMaxWithdrawal() {
      const { data } = await paymentStore.getMinMaxWithdrawal();

      if (data) {
        const { min_bank_payment, max_bank_payment } = data;

        setMinCreditBank(min_bank_payment);
        setMaxCreditBank(max_bank_payment);
      }
    }

    fetchMinMaxWithdrawal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="credit">
      <div className="mt-8">
        <CreditDebitInfoBar
          min={minCreditBank}
          max={maxCreditBank}
          isDebit={false}
        />
      </div>
      <div className="d-flex justify-end">
        <div className="credit__amount px-10 py-10">
          <p className="text-white d-flex justify-end">
            {t('payments.funds-that-can-be-raised')}
          </p>
          <p className="text-green-200 d-flex justify-end mt-3">
            {userStore.standardWallet?.availableFunds}
          </p>
        </div>
      </div>
      {activeWithdrawal && (
        <div className="w-100 bg-grey-800 py-5 d-flex justify-center">
          <div className="text-white text-regular" style={{ width: '350px' }}>
            <h3 className="heading-m mb-4">
              {t('payments.withdrawal-reserved').toUpperCase()}
            </h3>

            <div className="d-flex mb-4">
              <span className="w-50">{t('userData.bank-name')}:</span>
              <span className="text-yellow text-strong">
                {activeWithdrawal?.bank_name}
              </span>
            </div>

            <div className="d-flex mb-4">
              <span className="w-50">{t('userData.bank-account-number')}:</span>
              <span className="text-yellow text-strong">
                {activeWithdrawal?.bank_number}
              </span>
            </div>

            <div className="d-flex mb-4">
              <span className="w-50">{t('payments.amount')}:</span>
              <span className="text-yellow text-strong">
                {activeWithdrawal?.amount.toFixed(2)}
              </span>
            </div>

            {!activeWithdrawal?.is_locked && (
              <Button
                bg="success"
                onClick={cancelWithdrawal}
                disabled={activeWithdrawal?.is_locked}
              >
                {t('payments.cancel-withdrawal').toUpperCase()}
              </Button>
            )}
          </div>
        </div>
      )}
      {!activeWithdrawal && (
        <WithdrawForm user={userStore.user} onSuccess={onWithdrawalSuccess} />
      )}
    </div>
  );
});

export default BankCredit;
