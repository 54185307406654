import ReactGA from 'react-ga';

export const initializeGoogleAnalytics = () => {
  const id = 'UA-73391185-1';
  ReactGA.initialize(id);
};

export const setGoogleAnalyticsEvent = (category: string, action: string) => {
  // TODO: this is the code for tracking the number of successful paymants, uncoment when we go live
  ReactGA.event({
    category: category,
    action: action,
  });
};
