/**
 * @fileoverview gRPC-Web generated client stub for loyalty
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_common_common_pb from '../../proto/common/common_pb';
import * as proto_loyalty_loyalty_pb from '../../proto/loyalty/loyalty_pb';


export class LoyaltyBOServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetLoyaltyConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.LoyaltyConfig,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.LoyaltyConfig.deserializeBinary
  );

  getLoyaltyConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.LoyaltyConfig>;

  getLoyaltyConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.LoyaltyConfig) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.LoyaltyConfig>;

  getLoyaltyConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.LoyaltyConfig) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetLoyaltyConfig',
        request,
        metadata || {},
        this.methodInfoGetLoyaltyConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetLoyaltyConfig',
    request,
    metadata || {},
    this.methodInfoGetLoyaltyConfig);
  }

  methodInfoSetLoyaltyConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.LoyaltyConfig,
    (request: proto_loyalty_loyalty_pb.LoyaltyConfig) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.LoyaltyConfig.deserializeBinary
  );

  setLoyaltyConfig(
    request: proto_loyalty_loyalty_pb.LoyaltyConfig,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.LoyaltyConfig>;

  setLoyaltyConfig(
    request: proto_loyalty_loyalty_pb.LoyaltyConfig,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.LoyaltyConfig) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.LoyaltyConfig>;

  setLoyaltyConfig(
    request: proto_loyalty_loyalty_pb.LoyaltyConfig,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.LoyaltyConfig) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/SetLoyaltyConfig',
        request,
        metadata || {},
        this.methodInfoSetLoyaltyConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/SetLoyaltyConfig',
    request,
    metadata || {},
    this.methodInfoSetLoyaltyConfig);
  }

  methodInfoDisableLoyalty = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.DisableLoyaltyMessage,
    (request: proto_loyalty_loyalty_pb.DisableLoyaltyMessage) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.DisableLoyaltyMessage.deserializeBinary
  );

  disableLoyalty(
    request: proto_loyalty_loyalty_pb.DisableLoyaltyMessage,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.DisableLoyaltyMessage>;

  disableLoyalty(
    request: proto_loyalty_loyalty_pb.DisableLoyaltyMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.DisableLoyaltyMessage) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.DisableLoyaltyMessage>;

  disableLoyalty(
    request: proto_loyalty_loyalty_pb.DisableLoyaltyMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.DisableLoyaltyMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/DisableLoyalty',
        request,
        metadata || {},
        this.methodInfoDisableLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/DisableLoyalty',
    request,
    metadata || {},
    this.methodInfoDisableLoyalty);
  }

  methodInfoDisableUserLoyalty = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage,
    (request: proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage.deserializeBinary
  );

  disableUserLoyalty(
    request: proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage>;

  disableUserLoyalty(
    request: proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage>;

  disableUserLoyalty(
    request: proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.DisableUserLoyaltyMessage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/DisableUserLoyalty',
        request,
        metadata || {},
        this.methodInfoDisableUserLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/DisableUserLoyalty',
    request,
    metadata || {},
    this.methodInfoDisableUserLoyalty);
  }

  methodInfoGetUserLoyaltyBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.UserLoyalty,
    (request: proto_loyalty_loyalty_pb.UserLoyalty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.UserLoyalty.deserializeBinary
  );

  getUserLoyaltyBO(
    request: proto_loyalty_loyalty_pb.UserLoyalty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.UserLoyalty>;

  getUserLoyaltyBO(
    request: proto_loyalty_loyalty_pb.UserLoyalty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.UserLoyalty) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.UserLoyalty>;

  getUserLoyaltyBO(
    request: proto_loyalty_loyalty_pb.UserLoyalty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.UserLoyalty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetUserLoyaltyBO',
        request,
        metadata || {},
        this.methodInfoGetUserLoyaltyBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetUserLoyaltyBO',
    request,
    metadata || {},
    this.methodInfoGetUserLoyaltyBO);
  }

  methodInfoCreateStoreItem = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.StoreItem,
    (request: proto_loyalty_loyalty_pb.StoreItem) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.StoreItem.deserializeBinary
  );

  createStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.StoreItem>;

  createStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItem) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.StoreItem>;

  createStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/CreateStoreItem',
        request,
        metadata || {},
        this.methodInfoCreateStoreItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/CreateStoreItem',
    request,
    metadata || {},
    this.methodInfoCreateStoreItem);
  }

  methodInfoUpdateStoreItem = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.StoreItem,
    (request: proto_loyalty_loyalty_pb.StoreItem) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.StoreItem.deserializeBinary
  );

  updateStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.StoreItem>;

  updateStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItem) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.StoreItem>;

  updateStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/UpdateStoreItem',
        request,
        metadata || {},
        this.methodInfoUpdateStoreItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/UpdateStoreItem',
    request,
    metadata || {},
    this.methodInfoUpdateStoreItem);
  }

  methodInfoDeleteStoreItem = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.StoreItem,
    (request: proto_loyalty_loyalty_pb.StoreItem) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.StoreItem.deserializeBinary
  );

  deleteStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.StoreItem>;

  deleteStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItem) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.StoreItem>;

  deleteStoreItem(
    request: proto_loyalty_loyalty_pb.StoreItem,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/DeleteStoreItem',
        request,
        metadata || {},
        this.methodInfoDeleteStoreItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/DeleteStoreItem',
    request,
    metadata || {},
    this.methodInfoDeleteStoreItem);
  }

  methodInfoUpdateStoreItemOrdering = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_loyalty_loyalty_pb.StoreItemOrdering) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateStoreItemOrdering(
    request: proto_loyalty_loyalty_pb.StoreItemOrdering,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateStoreItemOrdering(
    request: proto_loyalty_loyalty_pb.StoreItemOrdering,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateStoreItemOrdering(
    request: proto_loyalty_loyalty_pb.StoreItemOrdering,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/UpdateStoreItemOrdering',
        request,
        metadata || {},
        this.methodInfoUpdateStoreItemOrdering,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/UpdateStoreItemOrdering',
    request,
    metadata || {},
    this.methodInfoUpdateStoreItemOrdering);
  }

  methodInfoGetAllStoreItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.StoreItems,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.StoreItems.deserializeBinary
  );

  getAllStoreItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.StoreItems>;

  getAllStoreItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItems) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.StoreItems>;

  getAllStoreItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetAllStoreItems',
        request,
        metadata || {},
        this.methodInfoGetAllStoreItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetAllStoreItems',
    request,
    metadata || {},
    this.methodInfoGetAllStoreItems);
  }

  methodInfoGetOrdersBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Orders,
    (request: proto_loyalty_loyalty_pb.GetOrdersBORequest) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Orders.deserializeBinary
  );

  getOrdersBO(
    request: proto_loyalty_loyalty_pb.GetOrdersBORequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Orders>;

  getOrdersBO(
    request: proto_loyalty_loyalty_pb.GetOrdersBORequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Orders) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Orders>;

  getOrdersBO(
    request: proto_loyalty_loyalty_pb.GetOrdersBORequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Orders) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetOrdersBO',
        request,
        metadata || {},
        this.methodInfoGetOrdersBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetOrdersBO',
    request,
    metadata || {},
    this.methodInfoGetOrdersBO);
  }

  methodInfoGetSingleOrderBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  getSingleOrderBO(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  getSingleOrderBO(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  getSingleOrderBO(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetSingleOrderBO',
        request,
        metadata || {},
        this.methodInfoGetSingleOrderBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetSingleOrderBO',
    request,
    metadata || {},
    this.methodInfoGetSingleOrderBO);
  }

  methodInfoApproveOrder = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  approveOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  approveOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  approveOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/ApproveOrder',
        request,
        metadata || {},
        this.methodInfoApproveOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/ApproveOrder',
    request,
    metadata || {},
    this.methodInfoApproveOrder);
  }

  methodInfoDeliverOrder = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  deliverOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  deliverOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  deliverOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/DeliverOrder',
        request,
        metadata || {},
        this.methodInfoDeliverOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/DeliverOrder',
    request,
    metadata || {},
    this.methodInfoDeliverOrder);
  }

  methodInfoConfirmOrder = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  confirmOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  confirmOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  confirmOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/ConfirmOrder',
        request,
        metadata || {},
        this.methodInfoConfirmOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/ConfirmOrder',
    request,
    metadata || {},
    this.methodInfoConfirmOrder);
  }

  methodInfoCancelOrderBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  cancelOrderBO(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  cancelOrderBO(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  cancelOrderBO(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/CancelOrderBO',
        request,
        metadata || {},
        this.methodInfoCancelOrderBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/CancelOrderBO',
    request,
    metadata || {},
    this.methodInfoCancelOrderBO);
  }

  methodInfoGetUsersBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Users,
    (request: proto_loyalty_loyalty_pb.GetUsersRequest) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Users.deserializeBinary
  );

  getUsersBO(
    request: proto_loyalty_loyalty_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Users>;

  getUsersBO(
    request: proto_loyalty_loyalty_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Users) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Users>;

  getUsersBO(
    request: proto_loyalty_loyalty_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Users) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetUsersBO',
        request,
        metadata || {},
        this.methodInfoGetUsersBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetUsersBO',
    request,
    metadata || {},
    this.methodInfoGetUsersBO);
  }

  methodInfoGetLevelCount = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.LevelCount,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.LevelCount.deserializeBinary
  );

  getLevelCount(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.LevelCount>;

  getLevelCount(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.LevelCount) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.LevelCount>;

  getLevelCount(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.LevelCount) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetLevelCount',
        request,
        metadata || {},
        this.methodInfoGetLevelCount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetLevelCount',
    request,
    metadata || {},
    this.methodInfoGetLevelCount);
  }

  methodInfoGetUserTransactions = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Transactions,
    (request: proto_loyalty_loyalty_pb.UserTransactionsRequest) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Transactions.deserializeBinary
  );

  getUserTransactions(
    request: proto_loyalty_loyalty_pb.UserTransactionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Transactions>;

  getUserTransactions(
    request: proto_loyalty_loyalty_pb.UserTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Transactions) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Transactions>;

  getUserTransactions(
    request: proto_loyalty_loyalty_pb.UserTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Transactions) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyBOService/GetUserTransactions',
        request,
        metadata || {},
        this.methodInfoGetUserTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyBOService/GetUserTransactions',
    request,
    metadata || {},
    this.methodInfoGetUserTransactions);
  }

}

export class LoyaltyWebServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetLoyalty = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.UserLoyalty,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.UserLoyalty.deserializeBinary
  );

  getLoyalty(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.UserLoyalty>;

  getLoyalty(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.UserLoyalty) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.UserLoyalty>;

  getLoyalty(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.UserLoyalty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/GetLoyalty',
        request,
        metadata || {},
        this.methodInfoGetLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/GetLoyalty',
    request,
    metadata || {},
    this.methodInfoGetLoyalty);
  }

  methodInfoGetStoreItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.StoreItems,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.StoreItems.deserializeBinary
  );

  getStoreItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.StoreItems>;

  getStoreItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItems) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.StoreItems>;

  getStoreItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.StoreItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/GetStoreItems',
        request,
        metadata || {},
        this.methodInfoGetStoreItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/GetStoreItems',
    request,
    metadata || {},
    this.methodInfoGetStoreItems);
  }

  methodInfoPlaceOrder = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  placeOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  placeOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  placeOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/PlaceOrder',
        request,
        metadata || {},
        this.methodInfoPlaceOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/PlaceOrder',
    request,
    metadata || {},
    this.methodInfoPlaceOrder);
  }

  methodInfoCancelOrder = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  cancelOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  cancelOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  cancelOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/CancelOrder',
        request,
        metadata || {},
        this.methodInfoCancelOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/CancelOrder',
    request,
    metadata || {},
    this.methodInfoCancelOrder);
  }

  methodInfoGetOrders = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Orders,
    (request: proto_loyalty_loyalty_pb.GetOrdersRequest) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Orders.deserializeBinary
  );

  getOrders(
    request: proto_loyalty_loyalty_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Orders>;

  getOrders(
    request: proto_loyalty_loyalty_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Orders) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Orders>;

  getOrders(
    request: proto_loyalty_loyalty_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Orders) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/GetOrders',
        request,
        metadata || {},
        this.methodInfoGetOrders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/GetOrders',
    request,
    metadata || {},
    this.methodInfoGetOrders);
  }

  methodInfoGetSingleOrder = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.Order,
    (request: proto_loyalty_loyalty_pb.Order) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.Order.deserializeBinary
  );

  getSingleOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.Order>;

  getSingleOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.Order>;

  getSingleOrder(
    request: proto_loyalty_loyalty_pb.Order,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.Order) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/GetSingleOrder',
        request,
        metadata || {},
        this.methodInfoGetSingleOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/GetSingleOrder',
    request,
    metadata || {},
    this.methodInfoGetSingleOrder);
  }

  methodInfoGetWebLoyaltyConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_loyalty_pb.WebLoyaltyConfig,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_loyalty_pb.WebLoyaltyConfig.deserializeBinary
  );

  getWebLoyaltyConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_loyalty_pb.WebLoyaltyConfig>;

  getWebLoyaltyConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.WebLoyaltyConfig) => void): grpcWeb.ClientReadableStream<proto_loyalty_loyalty_pb.WebLoyaltyConfig>;

  getWebLoyaltyConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_loyalty_pb.WebLoyaltyConfig) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/loyalty.LoyaltyWebService/GetWebLoyaltyConfig',
        request,
        metadata || {},
        this.methodInfoGetWebLoyaltyConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/loyalty.LoyaltyWebService/GetWebLoyaltyConfig',
    request,
    metadata || {},
    this.methodInfoGetWebLoyaltyConfig);
  }

}

