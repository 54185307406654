/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Button } from 'components';
import activationSuccess from 'images/account-verified.svg';
import './VerificationSuccessModal.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const VerificationSuccessModal = observer(() => {
  const { t } = useTranslation();

  const {
    overlayStore: { closeModal },
  } = useStores();

  const close = () => closeModal();

  return (
    <>
      <h3 className="verification-success-modal__header">
        {t('globals.activation')}
      </h3>
      <div className="verification-success-modal__body">
        <div className="verification-success-modal__image-wrapper">
          <img src={activationSuccess} alt="Activation" />
          <p>{t('verification.email-verified-success')}</p>
          <Button onClick={close}>OK</Button>
        </div>
      </div>
    </>
  );
});

export default VerificationSuccessModal;
