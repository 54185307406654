export enum WalletTypes {
  STANDARD,
  PROMO,
  RESERVED_FUNDS,
  FREE_BET_BONUS,
  CASINO_BONUS,
  LOYALTY,
  FREE_CASINO,
  FREE_CASINO_TO_UNLOCK,
  PROMO_BET_BONUS,
  PROMO_BET_BONUS_TO_UNLOCK,
}

export type WalletType =
  | WalletTypes.STANDARD
  | WalletTypes.PROMO
  | WalletTypes.RESERVED_FUNDS
  | WalletTypes.FREE_BET_BONUS
  | WalletTypes.CASINO_BONUS
  | WalletTypes.LOYALTY
  | WalletTypes.FREE_CASINO
  | WalletTypes.FREE_CASINO_TO_UNLOCK
  | WalletTypes.PROMO_BET_BONUS
  | WalletTypes.PROMO_BET_BONUS_TO_UNLOCK;

export enum WalletCurrencies {
  EUR = 'EUR',
}

export type WalletCurrency = WalletCurrencies.EUR;

export enum BonusTypes {
  FREE_BET = 'free_bet',
  CASINO = 'casino',
}

export type BonusType = BonusTypes.FREE_BET | BonusTypes.CASINO;
