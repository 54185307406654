import React, { ReactNode } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

interface CarouselProps {
  children: ReactNode;
  sliderConfig: object;
  refHandler?: (arg: any) => any;
}

const Carousel = ({ children, sliderConfig, refHandler }: CarouselProps) => {
  return (
    <div className="carousel-wrapper">
      <Slider {...sliderConfig} ref={refHandler}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
