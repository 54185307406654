const ua = {
  translation: {
    casino: {
      'all-games': 'ВСІ ІГРИ',
      award: 'Нагорода',
      back: 'НАЗАД',
      'biggest-wins': 'НАЙБІЛЬШІ ПРИБУТКИ',
      'casino-brands': 'БРЕНДИ КАЗИНО',
      categories: 'КАТЕГОРІЇ',
      category: 'КАТЕГОРІЯ',
      close: 'Закрити',
      demo: 'ДЕМО',
      'favourite-games': 'УЛЮБЛЕНІ ІГРИ',
      'game-of-week': 'ГРА НЕДІЛІ',
      'last-played': 'ОСТАННЯ ГРА',
      'most-played': 'НАЙГРАЙЛІШИЙ',
      play: 'ГРАТИ',
      points: 'Очки',
      provider: 'БРЕНД',
      sections: 'РОЗДІЛИ',
      'see-all': 'ДИВИТИСЯ ВСЕ',
      'use-free-casino': 'Використовуйте фріспіни',
      user: 'Користувач',
    },
    countries: {
      albania: 'Албанія',
      'bosnia-and-herzegovina': 'Боснія і Герцеговина',
      croatia: 'Хорватія',
      kosovo: 'Косово',
      montenegro: 'Чорногорія',
      'republic-of-north-macedonia': 'Північна Македонія',
      russia: 'Росія',
      serbia: 'Сербія',
      turkey: 'Туреччина',
      ukraine: 'Україна',
    },
    errorPage: {
      'reload-page': 'Будь ласка, оновіть сторінку.',
      'something-went-wrong': 'Виникла помилка.',
    },
    errors: {
      'activation-link-exp':
        'Щоб завершити реєстрацію, необхідно активувати обліковий запис. Посилання для активації надіслано на вашу електронну адресу.',
      'another-order-exists': 'Інший порядок вже існує',
      'bad-password': 'Невірний пароль',
      'bet-not-possible': 'Ставки на цю подію наразі неможливі',
      'betting-blocked': 'Ставки заблоковані.',
      'can-not-find-user': 'Не вдалося знайти користувача',
      'cancel-funds': 'Запланований платіж не знайдено',
      'cannot-add-more-than-one-digital-item':
        'Ви не можете додати більше одного цифрового продукту в кошик',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Ви не можете придбати бонус казино, якщо у вас уже є такий',
      'cannot-cancel-order': 'Ви не можете скасувати замовлення',
      'cannot-order-mixed-items': 'Ви не можете замовляти різні види продукції',
      'cannot-order-more-than-one-digital-item':
        'Ви не можете замовити більше одного цифрового продукту',
      'choose-betting-place': 'Виберіть місце отримання',
      'connection-offline': 'Втрачено з’єднання з Інтернетом!',
      'credentials-error': "Ім'я користувача або пароль введено неправильно.",
      'data-error-reading':
        'Помилка збереження даних. Будь ласка спробуйте ще раз.',
      'email-exists': 'Користувач із цією електронною адресою вже існує.',
      'email-invalid': 'Електронна адреса недійсна',
      'err-cash-drop-already-collected':
        'Хтось був швидше вас. CashDrop використовувався та більше не доступний.',
      'err-cash-drop-already-collected-by-user': 'CashDrop вже забрали',
      'err-cash-drop-group-already-collected-by-user':
        'Ви не можете забрати CashDrop із цієї групи',
      'err-cash-drop-not-active':
        'Термін дії програми CashDrop закінчився, і вона більше не доступна.',
      'err-cash-drop-not-found': 'CashDrop не знайдено',
      'err-cash-drop-user-min-balance':
        'Ви повинні мати принаймні {{a}} євро, щоб отримати CashDrop',
      'err-cash-drop-user-min-bet':
        'Ви повинні витратити принаймні {{a}} євро за останні {{b}} годин, щоб мати можливість вимагати CashDrop',
      ERR_BETTING_MACHINE_BLOCKED: 'Цю дію заблоковано',
      ERR_CANT_PAYOUT_BETTING_PLACE:
        'Ваучер не можна використати в цій платіжній точці',
      ERR_CANT_PAYOUT_MUNICIPALITY:
        'Ваучер не можна використати в цьому муніципалітеті',
      ERR_CASINO_BLOCKED: 'Ігри в казино заблоковано',
      ERR_DEPOSIT_BLOCKED: 'Платіж користувача заблоковано',
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE: 'Оплата в касу заблокована',
      ERR_INVALID_GAME: 'Гра недійсна',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        "Квиток більше не дійсний для оплати. Будь ласка, зв'яжіться з оператором.",
      ERR_USER_BLOCKED_ALREADY: 'Ваш обліковий запис уже заблоковано.',
      ERR_USER_NOT_VALIDATED: 'Користувач не перевірений',
      ERR_VIRTUAL_GAMES_BLOCKED: 'Віртуальні ігри заблоковано',
      ERR_VOUCHER_CONFIG_NOT_FOUND: 'Конфігурація ваучера не існує',
      ERR_VOUCHER_EXPIRED: 'Термін дії ваучера закінчився',
      ERR_VOUCHER_NOT_FOUND: 'Ваучер не існує',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT: 'Ваучер недійсний для оплати',
      ERR_STREAM_NOT_AVAILABLE: 'Пряма трансляція наразі недоступна',
      ERR_STREAM_TYPE_NOT_AVAILABLE:
        'Цей тип прямої трансляції не підтримується',
      ERR_LOCATION_NOT_SUPPORTED:
        'Пряма трансляція не підтримується у вашому регіоні',
      ERR_IP_NOT_ALLOWED: 'Пряма трансляція не дозволена для вашої IP-адреси',
      ERR_STREAM_NOT_ALLOWED: 'Пряма трансляція заборонена.',
      ERR_STREAM_NOT_ALLOWED_FOR_UNVALIDATED_USER:
        'Пряма трансляція заборонена для неперевірених користувачів',
      ERR_MINIMUM_STREAMING_BALANCE:
        'Пряма трансляція доступна, якщо на вашому рахунку є мінімум {{errorArgument}} євро',
      'error-starting-game': 'Гру не можна запустити',
      'error-unknown': 'Сталася помилка, спробуйте ще раз.',
      'field-invalid': 'Запис недійсний',
      'field-required': "Це поле є обов'язковим",
      'fields-obligated': "Поля, позначені (*), обов'язкові для заповнення.",
      'invalid-system-slip': 'Системний квиток недійсний',
      'invalid-user': 'Користувач недійсний',
      'item-not-available': 'Елемент  відсутній',
      'item-not-found': 'Елемент  не знайдено',
      'jmbg-max': 'JMBG не може містити більше 13 символів.',
      'jmbg-min': 'JMBG має містити щонайменше 13 символів.',
      'min-password': 'Пароль має містити мінімум 6 символів.',
      'no-betting-place-id': 'Виберіть гілку',
      'no-events-for-copy': 'Немає подій, які можна скопіювати',
      'no-items-in-order': 'У замовленні немає продуктів',
      'not-enough-points': 'Вам не вистачає балів',
      'order-does-not-exist': 'Замовлення не існує',
      'passwords-dont-match': 'Пароль не збігається.',
      'payout-error': 'Під час спроби здійснити платіж сталася помилка.',
      'place-bet-error': 'Помилка оплати квитка. Будь ласка спробуйте ще раз.',
      'poker-not-responding': 'Покерний сервер наразі недоступний',
      'poker-success-false': 'Дія покерного сервера не була успішною',
      'print-error':
        'Під час спроби друку сталася помилка. Якщо квиток не роздрукований, зверніться до оператора.',
      'print-not-operational': 'ПРИНТЕР НЕ ПРАЦЮЄ',
      'promo-code-invalid': 'Промокод недійсний',
      'reservation-locked': 'Зарезервовані кошти заблоковані',
      'session-expired': 'Ваш сеанс закінчився, увійдіть знову.',
      'system-error': 'Сталася системна помилка. Будь ласка спробуйте ще раз.',
      TEST_USER_CANNOT_DEPOSIT_MONEY_WITH_CARD:
        'Тестовий користувач не може внести гроші за допомогою картки.',
      TEST_USERS_CANNOT_WITHDRAW_MONEY:
        'Тестові користувачі не можуть виводити гроші.',
      'ticket-changed': 'Ваш квиток було змінено.',
      'ticket-denied': 'Ваш квиток відхилено.',
      'ticket-error-reading': 'Помилка читання квитка.',
      TICKET_NOT_FOUND: 'Квиток не знайдено',
      TICKET_NOT_VALID_FOR_PAYOUT:
        "Квиток більше не дійсний для оплати. Будь ласка, зв'яжіться з оператором.",
      'token-expired': 'Термін дії токена закінчився',
      'user-blocked-error':
        'Ваш обліковий запис заблоковано. Зверніться до служби підтримки.',
      'user-exists': 'Користувач вже існує.',
      'user-not-active':
        'Користувач не активований. Будь ласка, активуйте свій обліковий запис.',
      'user-update-error':
        'Помилка збереження даних. Будь ласка спробуйте ще раз.',
      'username-exists': 'Користувач із таким іменем уже існує.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Антепостові події не можна поєднувати в одному квитку',
      'withdrawal-canceled-or-paid':
        'Платіж скасовано або оплачено. Будь ласка, оновіть сторінку.',
      'withdrawal-max-amount-exceeded':
        'Максимальна дозволена виплата становить {{a}}€',
      'withdrawal-min-amount-exceeded':
        'Мінімальна дозволена виплата становить {{a}}€',
      'zero-item-quantity': 'Товарів на складі немає',
    },
    footer: {
      'about-us': 'Про нас',
      'account-id': 'НОМЕР РАХУНКУ',
      'all-rights-reserved': 'Всі права захищені',
      'available-for-payment': 'Доступна до оплати',
      aviator: 'Авіатор',
      betting: 'Ставки',
      bonuses: 'Бонуси',
      casino: 'Казино',
      'casino-live': 'Живе казино',
      contact: 'Контакт ',
      cookies: 'Cookies',
      description:
        'SBbet організовує азартні ігри з 1997 року і надає своїм клієнтам послуги на найвищому рівні. Маючи мережу з понад 100 платіжних пунктів у Чорногорії, ми позиціонуємося як лідери ринку, що ще більше спонукає нас спрямовувати наш бізнес на створення розваг світового рівня у сфері азартних ігор. Сайт SBbet розроблений, щоб дозволити вам робити ставки з найкращим користувацьким досвідом. Величезний вибір видів спорту та змагань, привабливі коефіцієнти, бонуси, безкоштовні ставки та можливість виграти великі суми грошей через наші джекпоти – лише деякі з причин довіряти найстарішій букмекерській конторі в Чорногорії з понад 20-річним досвідом. Випробуйте свою удачу в одній із багатьох ігор казино, нашому бінго, віртуальних спортивних подіях або за справжнім столом казино. SBbet.me є частиною Sporting Group doo, Mediteranska 21, Будва, Чорногорія, регулюється законодавством Чорногорії та є власником ліцензії, виданої Управлінням азартних ігор: 02/01-702/4. Команда SBbet бажає вам удачі - впевнено ...',
      'download-list': 'Завантажте список',
      'follow-us': 'Слідкуйте за нами',
      'frequently-questions': 'Часті запитання',
      'game-rules': 'Правила гри',
      informations: 'Інформація',
      'lucky-6': 'Lucky6',
      'pay-all': 'ОПЛАТИ ВСЕ',
      'payment-to-web': 'Оплата через WEB',
      'play-responsibly': 'Грайте відповідально',
      poker: 'Покер',
      privacy: 'Конфіденційність',
      'promo-code': 'Промокод',
      promotions: 'Акції',
      'site-map': 'Карта сайту',
      sport: 'Cпорт ',
      'ticket-check': 'Перевірка квитка',
      transactions: 'Tранзакції',
      'using-conditions': 'Умови використання',
    },
    globals: {
      accept: 'ПРИЙНЯТИ',
      'accept-cookies':
        'Цей сайт використовує файли cookie, щоб покращити послуги та показати вам релевантний вміст. Якщо ви продовжуєте переглядати сайт, ми вважаємо, що ви погоджуєтеся на використання файлів cookie.',
      account: 'Pахунок',
      'account-type': 'Тип рахунку',
      activation: 'АКТИВАЦІЯ',
      'add-to-cart': 'Додати в кошик',
      all: 'Bсе',
      'amount-caps': 'СУМА',
      'app-idle-msg':
        'Ви деякий час не були активними. Натисніть оновити, щоб отримати актуальну ціну.',
      approved: 'Затверджено',
      'betting-history': 'Історія ставок',
      'booking-an-order': 'Бронювання замовлення',
      braon: 'Коричневий',
      cancel: 'ВІДКАЗАЙТЕСЯ',
      canceled: 'Відхилено',
      cart: 'Кошик',
      'cart-is-empty': 'Кошик порожній',
      Cet: 'чт',
      'choose-a-betting-place': 'Виберіть офіс',
      'choose-a-city': 'Виберіть місто',
      'choose-order-place':
        'Виберіть місце доставки в обраному відділенні SBbet',
      'click-for-more-info': 'Натисніть для отримання додаткової інформації',
      close: 'ЗАКРИТИ',
      'code-caps': 'КОД',
      collect: 'Взяти',
      copy: 'Копіювати',
      'copy-ticket': 'Скопіюйте квиток',
      'customer-support': 'Підтримка клієнтів',
      date: 'Дата',
      'date-caps': 'ДАТА',
      delivered: 'Доставлено',
      'filter-by-date': 'Фільтрувати за датою',
      free: 'Безкоштовно',
      'id-caps': 'ID',
      immediately: 'Негайно',
      'installation-guide': 'Інструкції з Інсталяції',
      loading: 'Завантаження...',
      'mark-all-as-read': 'Позначити все як прочитане',
      'my-account': 'Мій рахунок',
      'necessary-reload': 'Необхідно синхронізувати пропозицію',
      Ned: 'нед',
      nepar: 'Непарний',
      no: 'НІ',
      'no-info-about-event': 'Інформації про подію немає',
      notifications: 'Сповіщення',
      numbers: 'ЦИФРИ',
      'order-history': 'Історія Замовлень',
      par: 'Пар',
      pending: 'На утриманні',
      Pet: 'пт',
      'pick-date': 'Виберіть дату',
      'place-order': 'Замовляйте продукти',
      Pon: 'Пн',
      proceed: 'Оновити',
      profile: 'Профіль',
      'promo-code': 'Промокод',
      register: 'РЕЄСТРАЦІЯ',
      'remove-from-cart': 'Вилучити з кошика',
      save: 'Зберегти ',
      'sbbet-promo-code': 'ПРОМО-КОД SBbet',
      search: 'Пошук',
      'search-1': 'Шукати',
      'search-events': 'Пошук подій',
      send: 'ВПЕРЕД',
      'send-1': 'НАДІСЛАТИ',
      Sre: 'сер',
      statistics: 'СТАТИСТИКА',
      'status-caps': 'СТАТУС',
      step: 'Крок',
      Sub: 'сб',
      submit: 'ПІДТВЕРДИТИ',
      success: 'Завантажено',
      'successfully-collected-cash-drop': 'Ви успішно отримали грошову суму',
      'ticket-copied': 'Квиток скопійовано',
      time: 'Час ',
      'transaction-history': 'Історія транзакцій',
      type: 'Тип',
      'type-caps': 'ТИП',
      'user-id-caps': 'ІДЕНТИФІКАТОР КОРИСТУВАЧА',
      Uto: 'вівт',
      wallets: 'Рахунки',
      welcome: 'Ласкаво просимо!',
      yes: 'ТАК',
      zelena: 'Зелений',
      žuta: 'Жовтий',
    },
    header: {
      betting: 'Ставки',
      casino: 'Казино',
      'casino-live': 'Онлайн казино',
      live: 'Онлайн',
      'quick-games': 'Швидкі ігри',
    },
    home: {
      '100-spins': '100 СПІНІВ',
      'be-in-the-game-with-app': 'БУДЬ У ГРІ З ДОДАТКОМ SBBET',
      betting: 'Ставки',
      'betting-description':
        'На вас чекають найвищі коефіцієнти та найрізноманітніша пропозиція ігор та спеціальних пропозицій.',
      'call-center': 'КОЛЛЦЕНТР',
      'casino-description':
        'Найпопулярніші ігри казино приносять великі джекпоти',
      'follow-us': 'СЛІДУЙ ЗА НАМИ',
      'live-betting': 'LIVE СТАВКИ',
      'live-betting-description':
        'Ставки в прямому ефірі тепер вдвічі кращі, адже на SBbet вас чекає найбільший вибір живих видів спорту та широкий вибір ігор!',
      'place-bet': 'ЗРОБИТИ СТАВКУ',
      'promotions-description': 'Дізнайтеся все про нові акції та новини.',
      'quick-games-description':
        'Найбільший вибір віртуального: цифри, футбол, гонки та багато іншого!',
      register: 'Зареєструватися',
      'up-to-250': 'ДО 250',
      'we-double-your-first-deposit': 'МИ ПОДВОЮЄМО ВАШ ПЕРШИЙ ДЕПОЗИТ',
    },
    language: {
      english: 'Англійська',
      language: 'Мова',
      montenegrin: 'Чорногорський',
      'select-language': 'Виберіть мову',
    },
    login: {
      'forgot-password': 'Забули свій пароль?',
      'forgot-password-caps': 'ЗАБУЛИ СВІЙ ПАРОЛЬ?',
      'log-in': 'УВІЙТИ',
      'log-out': 'Вийти з аккаунта',
      login: 'УВІЙТИ B АККАУНТ',
      logout: 'ВИЙТИ З АККАУНТА',
      'logout-1': 'ВИХІД',
      'logout-question': 'Ви впевнені, що хочете скасувати підписку?',
      'sign-in': 'ЗАСТОСУВАТИ',
    },
    navigation: {
      aviator: 'АВІАТОР',
      betting: 'СТАВКИ',
      casino: 'КАЗИНО',
      liveCasino: 'ЛАЙВ КАЗИНО',
      lucky6: 'lucky6',
      numbers: 'ЦИФРИ',
      offer: 'ПРОПОЗИЦІЯ',
      poker: 'ПОКЕР',
      promotions: 'АКЦІЇ',
      quickGames: 'ШВИДКІ ІГРИ',
      sport: 'СПОРТ',
    },
    payments: {
      'account-id-confirm':
        'Будь ласка, підтвердьте,  ваш ID облікового запису:',
      amount: 'Сума',
      'available-for-payment': 'Доступнo до оплати',
      balance: 'Баланс',
      'balance-after': 'Сума після',
      'balance-before': 'Сума раніше',
      'bonus-balance': 'Бонусний баланс',
      'cancel-withdrawal': 'Скасувати платіж',
      'cancel-withdrawal-question': 'Ви впевнені, що бажаєте скасувати платіж?',
      'cancel-withdrawal-success': 'Ви успішно скасували платіж.',
      'card-expired': 'Трансакцію відхилено. Термін дії картки закінчився.',
      'card-not-found': 'Картка не знайдена. Будь ласка спробуйте ще раз.',
      'card-suspended': 'Трансакцію відхилено. Картку призупинено.',
      commission: 'Комісія',
      credit: 'Оплата',
      'credit-card': 'Кредитна карта',
      debit: 'Оплата',
      'e-wallet': 'Електронний гаманець',
      'funds-available-for-payment': 'Кошти доступні для оплати',
      'funds-that-can-be-raised': 'КОШТИ, ЯКІ МОЖНА ЗНЯТИ',
      'in-the-bank': 'В БАНКУ',
      'in-the-bank-note':
        'Якщо ваше ім’я та прізвище неправильні, зверніться до служби підтримки',
      'in-the-office': 'В ОФІСІ',
      'invalid-amount': 'Трансакцію відхилено. Сума недійсна.',
      'invalid-card-number': 'Трансакцію відхилено. Номер картки недійсний.',
      'maximum-credit': 'Максимальна виплата',
      'maximum-debit': 'Максимальна оплата',
      'minimum-credit': 'Мінімальна виплата',
      'minimum-debit': 'Мінімальний платіж',
      pay: 'Здійснювати платежі',
      'payment-default-error':
        'Трансакцію відхилено. Будь ласка спробуйте ще раз.',
      'payment-destination': 'Призначення платежу',
      'payment-success': 'Платіж успішно здійснено.',
      'physical-payment': 'Фізична оплата',
      'poker-withdrawal-success': 'Платіж успішно здійснено.',
      'potential-payout': 'Потенційна виплата:',
      'proceed-with-payment': 'Продовжити оплату, натиснувши кнопку',
      skrill: 'Скрілл   ',
      tabaccopress: {
        'deposit-step-1':
          'Попросіть продавця в одному з відділень Tobacco S Press внести депозит на ваш рахунок Sbbet через платіжні термінали',
        'deposit-step-2':
          'Необхідно повідомити продавцю ідентифікаційний номер вашого рахунку SBBet і суму, яку ви бажаєте внести.',
        'deposit-step-3':
          'Сплачена вами сума буде доступна вам у вашому обліковому записі SBBet, як правило, протягом кількох хвилин із моменту оплати.',
        'deposit-steps': 'Як оплатити депозит через платіжні термінали?',
        'instruction-1':
          'Для оплати через платіжні термінали потрібен тільки номер користувача вашого рахунку SBbet (ID номер).',
        'instruction-2':
          'Здійснити оплату можна через платіжні термінали в одній із 300+ точок продажу Tobacco S Press',
        'instruction-3':
          'Мінімальна сума платежу через платіжні термінали становить 1,00 євро, а максимальна сума платежу за транзакцію – 500,00 євро.',
        'instruction-4':
          'У разі помилки час сторнування операції в торговій точці становить 10 хвилин (якщо гроші з рахунку не витрачені).',
        'instruction-5':
          'Перегляньте заклади TOBACCO SPRESS, де можна внести заставу, за посиланням.',
      },
      terminals: {
        note: 'ПРИМІТКА: Якщо з якоїсь причини кошти не надійшли на ваш рахунок SBBet, будь ласка, зв’яжіться зі службою J&A за телефоном 067/788-588 з 9:00 до 17:00, а також після 17:00 до 21:00 та у вихідні дні за номером 068/488-588.',
        'step-1': 'На терміналі J&A виберіть логотип SBbet',
        'step-2':
          'Введіть ідентифікатор свого облікового запису користувача та натисніть кнопку «ОПЛАТИТИ».',
        'step-3':
          'Вставте потрібну суму грошей у термінал, і пристрій прочитає та відобразить суму, яку ви ввели.',
        'step-4':
          'Натиснувши кнопку «ПІДТВЕРДИТИ», ви успішно здійсните платіж і отримаєте роздруковану квитанцію про вашу операцію.',
      },
      'transaction-date': 'Дата здійснення операції',
      'transaction-deposit': 'Оплата',
      'transaction-id': 'ID транзакції',
      'transaction-type': 'Тип транзакції',
      'transaction-web-bet': 'Веб-ставка',
      'transaction-web-bet-rollback': 'Відкат Web bet',
      'transaction-web-casino-credit': 'Кредит казино',
      'transaction-web-casino-debit': 'Дебет казино',
      'transaction-web-casino-rollback': 'Відкат казино',
      'transaction-web-game-bet': 'Веб-ігри ставки',
      'transaction-web-game-rollback': 'Відкат веб-ігри',
      'transaction-web-game-win': 'Перемога в веб-грі',
      'transaction-web-win': 'Веб-перемога',
      'transaction-withdraw': 'Оплата',
      transactions: 'Транзакції ',
      withdraw: 'ВИПЛАТИ',
      'withdraw-on-the-bank': 'ОПЛАТА ЧЕРЕЗ БАНК',
      'withdraw-on-the-betting-place': 'ОПЛАТА В БІЗНЕС-ОФІСІ',
      'withdrawal-pending': 'На затвердження',
      'withdrawal-ready': 'Готовий зібрати',
      'withdrawal-reserved': 'Планова оплата',
      'withdrawal-success': 'Ви успішно запланували платіж.',
      'your-transaction-has-been-confirmed': 'Вашу транзакцію підтверджено',
    },
    promoBanner: {
      aviator: 'Авіатор',
      betting: 'Ставки',
      blackjack: 'Блек Джек',
      cashout: 'Перевести в готівку',
      'casino-tournament': 'Турнір казино',
      'defeat-goalkeeper-for-bonuses': 'Перемагайте воротаря за БОНУСИ',
      'double-first-payin': 'Кожен перший платіж ми подвоюємо',
      'download-app': 'Завантажте програму',
      'fly-to-win': 'Лети до перемоги',
      'happy-monday-bonus': 'Бонус щасливого понеділка',
      'lucky-6': 'Lucky  6',
      'netent-casino-games': 'Ігри казино NetEnt',
      new: 'Hовий',
      'pay-the-ticket-at-any-time': 'Оплатіть квиток у будь-який час',
      popular: 'Популярний',
      promotion: 'Акція',
      roulette: 'Рулетка',
      'win-5000-jackpot': 'Виграйте джекпот у 5000 євро',
      'win-apartment': 'Виграйте квартиру в Подгориці',
      'win-iphone-13': 'Виграй iPhone 13',
      'win-jackpot-up-to-5000': 'Виграйте джекпот до 5000 євро',
    },
    slips: {
      'accept-all-changes': 'Я приймаю всі зміни',
      'accept-cashout': 'ЗАБРАТИ  CASHOUT',
      'accept-changes': 'Прийняти зміни',
      'accept-changes-after-place-bet': 'Прийняти зміни після оплати',
      active: 'Активний',
      'actual-winning-amount': 'Оплата',
      'all-tickets': 'УСІ КВИТКИ',
      approving: 'Очікує Схвалення',
      bet: 'Ставка',
      'bet-type': 'Типи і бастит',
      bonus: 'Бонус:',
      'cancel-cashout': 'Скасувати виведення коштів',
      cashout: 'ОБНАЛИЧИТЬ',
      'cashout-accepted': 'Виведення коштів прийнято',
      'cashout-denied': 'Ви успішно відхилили виведення коштів',
      'cashout-denied-timer':
        'Виведення коштів відхилено через час очікування для прийняття',
      changed: 'Змінено',
      'confirm-ticket': 'ПІДТВЕРДИТИ КВИТОК',
      'delete-all': 'Очистити все',
      'delete-ticket': 'Видалити квиток',
      'delete-ticket-info': 'Ви бажаєте видалити цей квиток?',
      denied: 'Відхилено',
      'deny-cashout': 'ВІДМОВИТИ ВИВЕДЕННЯ ГРОШІВ',
      'erase-multiple-odds':
        'Ця дія видалить кілька символів, залишивши лише перший символ у кожному збігу.',
      failed: 'Втрачено',
      fix: 'FIX',
      'group-number': 'Група №',
      id: 'Код',
      'max-pot-payout': 'Максимальна потенційна виплата',
      'max-pot-win': 'Максимальний прибуток',
      'max-potential-bonus': 'Максимально потенційний бонус',
      'min-max-bonus': 'Мінімальний/максимальний бонус',
      'min-max-odd': 'Мінімальна/максимальна квота',
      'min-max-pot-win': 'Мінімальний/максимальний потенційний прибуток',
      'min-pot-win': 'Мінімальний прибуток',
      'min-potential-bonus': 'Мінімальний потенційний бонус',
      'min-winning-total': 'Мінімальна потенційна виплата',
      multiplier: 'Квота',
      'my-tickets': 'МОЇ КВИТКИ',
      'no-slip-events': 'Подій у листку немає',
      'no-tickets': 'Квитків немає.',
      'number-of-combinations': 'Номер комбінації:',
      'number-of-matches': 'Кількість пар:',
      'number-of-pairs': 'Кількість пар:',
      'number-of-tickets': 'Номер квитка:',
      odd: 'Квота:',
      paid: 'Платний',
      passed: 'Отримано',
      payin: 'Оплата:',
      'payin-time': 'Час оплати:',
      'payment-time': 'Час оплати',
      payout: 'Оплата:',
      'place-bet': 'Оплатіть квиток',
      'possible-winning': 'Можливий виграш',
      'pot-winning-amount': 'Потенційна виплата',
      'pot-winning-amount-short': 'Потенційна виплата',
      'potential-bonus': 'Потенційний бонус',
      'potential-gain': 'Потенційний виграш',
      'quick-ticket-code': 'КОД ДЛЯ ШВИДКОГО КВИТКА',
      'quick-ticket-live':
        'Неможливо розіграти швидкий квиток з матчами в прямому ефірі',
      'quick-ticket-pay':
        'Оплатити швидкий квиток можна в будь-якому платіжному пункті SBBet за кодом:',
      'quick-ticket-terms':
        'Умови та можливі обмеження уточнюються в наших пунктах оплати при оплаті квитка.',
      'quick-tickets': 'Швидкі квитки',
      regular: 'ЗВИЧАЙНИЙ',
      'regular-ticket': 'ЗВИЧАЙНИЙ КВИТОК',
      'remove-match': 'Видалити збіг',
      'remove-odd': 'Видалити квоту',
      round: 'Раунд',
      'save-games': 'Зберегти збіги',
      'share-ticket-success': 'Посилання на заявку успішно скопійовано',
      'sign-in-status': 'Увійдіть, щоб переглянути статус квитка.',
      'slip-not-eligible-for-cashout': 'Квиток не підлягає виведенню.',
      'slip-payout': 'Оплата квитка',
      stake: 'Ставка',
      'stake-per-combination': 'Оплата за комбінацію:',
      status: 'Статус',
      'status-capitalize': 'Статус:',
      'switch-ticket': 'Перевести квиток на звичайний?',
      system: 'СИСТЕМНІСТЬ',
      'system-bonus-calculation':
        'Розрахунок бонусу за системним квитком буде проведено після реалізації всіх подій.',
      ticket: 'КВИТОК',
      'ticket-date': 'Дата квитанції',
      'ticket-details': 'ДЕТАЛІ КВИТКА',
      'ticket-id': 'ID квитка',
      'ticket-id-search': 'ID квитка:',
      'ticket-overview': 'Огляд квитків',
      'ticket-status': 'Статус квитка',
      'ticket-type': 'Тип квитанції',
      tickets: 'КВИТКИ',
      'top-tickets': 'ТОП-КВИТКИ',
      'total-odd': 'Загальна квота:',
      unconfirmed: 'Непідтверджено',
      win: 'Виграш',
      'winning-amount-caps': 'ВИГРАШ',
    },
    sports: {
      'americki fudbal': 'американський футбол',
      atletika: 'Легка атлетика',
      badminton: 'Бадмінтон',
      bejzbol: 'Бейсбол',
      biciklizam: 'Велоспорт',
      boks: 'Бокс',
      efudbal: 'eFootball',
      esport: 'кіберспорт',
      evrovizija: 'Євробачення',
      fudbal: 'футбол',
      futsal: 'футзал',
      golf: 'Гольф',
      hokej: 'Хокей',
      košarka: 'Баскетбол',
      mma: 'ММА',
      odbojka: 'Волейбол',
      olimpijada: 'Олімпіада',
      oskar: 'Оскар',
      pikado: 'Дартс',
      ragbi: 'Регбі',
      'ragbi liga': 'Ліга регбі',
      rukomet: 'Гандбол',
      snuker: 'Снукер',
      'stoni tenis': 'Настільний теніс',
      tenis: 'теніс',
      trke: 'Перегони',
      'trke konja': 'Скачки',
      vaterpolo: 'Водне поло',
      'virtuelni fudbal': 'Віртуальний футбол',
      'zimski sportovi': 'Зимові види спорту',
    },
    sportsPage: {
      '1d': '1 д',
      '1h': '1 година',
      '3d': '3 д',
      '3h': '3 години',
      all: 'ВСЕ',
      antepost: 'АНТЕПОСТ',
      competition: 'Ліга',
      'configure-offer': 'Налаштуйте пропозицію',
      event: 'Подія',
      limit: 'GR',
      live: 'НАЖИВО',
      'live-match-tracker': 'Слідкуйте за матчем у прямому ефірі',
      'no-events': 'Подій немає',
      'no-odds': 'Наразі немає пропозицій',
      odds: 'КВОТИ',
      player: 'ГРАВЕЦЬ',
      'sort-by-competition': 'По лігах',
      'sort-by-time': 'За попереднім записом',
      special: 'СПЕЦІАЛЬНІ АКЦІЇ',
      upcoming: 'ДАЛІ',
    },
    userData: {
      address: 'Адреса',
      adress: 'Адреса',
      'bank-account-number': 'Номер поточного рахунку',
      'bank-name': 'Назва  банку',
      'betting-win-loss': 'Ставки виграш/програш',
      'casino-and-virtuals-win-loss': 'Казино та віртуальний виграш/програш',
      'change-password': 'Змінити пароль',
      'changes-active-in-24-hrs':
        'Ваші зміни стануть активними через 24 години',
      city: 'Місто',
      code: 'Код',
      'confirm-password': 'Підтвердьте пароль',
      country: 'Країна',
      'current-password': 'Актуальний пароль',
      'current-spending': 'Поточне споживання',
      'daily-limits': 'Добові ліміти',
      'date-of-birth': 'Дата народження',
      deposit: 'депозит',
      'document-id': 'ID паспорта',
      documents: 'Документи',
      'driver-license': 'Водійські права',
      'email-address': 'Адреса електронної пошти',
      'email-adress': 'Адреса електронної пошти',
      female: 'Жіночий',
      'first-name': "Ім'я",
      gender: 'Стать',
      'i-have-jmbg': 'У мене є JMBG (тільки для громадян Чорногорії)',
      jmbg: 'JMBG',
      'last-name': 'Прізвище',
      male: 'Чоловічий ',
      'monthly-limits': 'Місячні ліміти',
      'new-limit-value-is': 'Нове граничне значення становить',
      'new-limits-take-effect-in': 'Набувають чинності нові обмеження для',
      'new-password': 'Новий пароль',
      passport: 'Паспорт',
      password: 'Код',
      'pause-gambling-confirmation':
        'Це призведе до вимкнення спортивних ставок, казино та віртуальних програм до вибраної дати. Ви впевнені, що бажаєте продовжити?',
      'pause-gambling-until': 'Призупинення ставок до',
      'personal-data': 'Особисті дані',
      'personal-id': 'Посвідчення  особи',
      phone: 'Телефон',
      place: 'Місце',
      'responsible-gambling': 'Відповідальні ставки',
      save: 'зберегти',
      'street-and-number': 'Вулиця і номер',
      telephone: 'Телефон',
      'user-details': 'Особисті дані',
      username: "Ім'я користувача",
      'weekly-limits': 'Тижневі ліміти',
    },
    userMarket: {
      cancel: 'Скасуйте це',
      code: 'Код',
      doctor: 'Лікар ',
      expert: 'Експерт',
      items: 'Ставки ',
      order: 'Замовлення ',
      'order-success': 'Успішне замовлення',
      place: 'Місце',
      professor: 'Професор ',
      'sbbet-points': 'Очки SBBet',
      'successfuly-canceled-order': 'Ви успішно скасували своє замовлення',
    },
    validations: {
      'amount-greater-than-available': 'Введена сума більша за доступну',
      'cannot-mix-group-events':
        'Неможливо грати в групові та групові події за одним квитком',
      'event-disabled': 'Ставки на подію: {{a}} неможливі.',
      'event-starts-in-less-than':
        'Подія {{a}} починається менш ніж за {{b}} секунд',
      'free-bet-error':
        'Мінімальна кількість подій на квитку безкоштовної ставки становить {{minEvents}}. Мінімальний загальний коефіцієнт становить {{minTotalOdd}}.',
      'free-bet-unvalidated':
        'Підтвердьте свій обліковий запис, щоб скористатися акційними коштами.',
      'live-odds-change': 'Будь ласка, погодьтеся з усіма змінами.',
      'max-bet-amount': 'Максимальний платіж становить {{a}}',
      'max-credit': 'Максимальна виплата становить {{max}} €',
      'max-debit': 'Максимальний платіж становить {{max}}',
      'max-number-of-combinations':
        'Максимальна кількість комбінацій становить {{a}}',
      'max-number-of-events': 'Максимальна кількість подій становить {{a}}',
      'max-number-of-same-events': 'Максимальна кількість символів: {{a}}',
      'max-same-slips': 'Ви зіграли {{a}} одного квитка.',
      'min-amount-per-combination':
        'Мінімальний платіж за комбінацію становить {{a}}',
      'min-credit': 'Мінімальна виплата становить {{min}} €',
      'min-debit': 'Мінімальний платіж становить {{min}}',
      'min-payment-for-number-of-matches':
        'Мінімальна виплата за кількість подій менше {{b}} становить {{a}}',
      'no-space': 'Пробіли не допускаються',
      'odd-disabled': 'Ставки на коефіцієнти {{a}} неможливі.',
      'odd-higher-than-one': 'Будь-який коефіцієнт має бути більшим за 1.',
      'only-letters-and-spaces-allowed': 'Допускаються лише літери та пробіли',
      'only-numbers-allowed': 'Дозволяються лише цифри',
      'repeat-password': 'Повторіть пароль',
      'validation-antepost-system':
        'Неможливо грати в події antepost за системним квитком.',
      'validation-balance': 'На вашому рахунку недостатньо коштів.',
      'validation-max-payment':
        'Виплата перевищує визначений максимальний платіж.',
      'validation-max-systems': 'Максимальна кількість систем становить {{a}}',
      'validation-max-win': 'Максимальний потенційний  виграш становить {{a}}',
      'validation-min-payment':
        'Мінімальний платіж становить {{minAmount}} євро',
      'validation-mix-long-short-term':
        'Неможливо зіграти антепост do+D482:D492target {{a}} і коротку подію {{b}} за одним квитком.',
      'validation-mixed-event-types':
        'Неможливо грати події antepost разом з іншими типами подій',
      'validation-mixed-markets':
        'Неможливо грати на маркетплейс {{a}} і маркетплейс {{b}} за одним квитком.',
      'validation-not-validated':
        'Неперевірений користувач не може здійснити платіж.',
      'validation-only-singles':
        'Подію {{a}} можна грати лише в одиночному розряді.',
      'validation-single-choice':
        'Подія {{a}} не може містити кілька символів.',
    },
    verification: {
      'accept-terms': 'Я приймаю умови використання',
      'account-activated': 'Обліковий запис успішно активовано',
      'account-success': 'Ви успішно активували свій акаунт!',
      'agree-email-notif': 'Я хочу отримувати сповіщення електронною поштою',
      'agree-sms-notif': 'Я хочу отримувати сповіщення через SMS',
      confirm: 'ПІДТВЕРДИТИ',
      'connection-online': 'Підключення до Інтернету встановлено!',
      'data-saved': 'Дані успішно збережено',
      'deny-changes': 'Ви успішно відхилили зміни в заявці.',
      'deny-changes-timer':
        'Зміни відхилено через час очікування для прийняття змін.',
      'email-verified-success':
        'Ви успішно підтвердили свій обліковий запис електронної пошти',
      'enter-recovery-email':
        'Введіть адресу електронної пошти, з якою ви створили обліковий запис.',
      instruction:
        'Надішліть SMS-повідомлення на номер 1818 у форматі: SbbIDномерСума депозиту',
      'login-activation': 'Увійдіть, щоб використовувати програму',
      'promo-code-activated':
        'Ви успішно активували промокод. Ви отримали {{amount}} {{currency}} на свій промо-акаунт.',
      'promo-code-activated-multiple-wallets':
        'Ви успішно активували промокод. Ви отримали {{amount}} {{currency}} на свій обліковий запис Free bet і {{amount_fc}} {{currency}} на свій рахунок Free spin.',
      'promo-code-already-activated': 'Промокод вже активовано',
      'promo-code-not-for-existing-users':
        'Промокод не призначений для старих користувачів',
      'promo-code-not-for-new-users':
        'Промокод не призначений для нових Corinthians',
      'promo-code-used-up': 'Промокод вже використано',
      'recovery-email-sent':
        'Ми надіслали вам електронний лист на вашу електронну адресу',
      'successfully-changed-password': 'Ви успішно змінили свій пароль',
      'ticket-approved':
        'Ваш квиток схвалено. Натисніть, щоб переглянути квиток.',
      'ticket-paid': 'Квиток успішно оплачено.',
      'ticket-waiting-approval': 'Ваш квиток затверджується.',
      'upload-document': {
        'choose-document': 'Виберіть документ',
        'document-type': 'Тип підтверджуючого документа:',
        'first-page': 'Лицьова/перша сторінка документа:',
        'second-page': 'Задня/друга сторінка документа:',
        submit: 'Надіслати',
        title: 'Завантаження документа',
        'upload-first-page': 'Завантажте передню/першу сторінку документа',
        'upload-second-page': 'Завантажте тильну/другу сторону документа',
        'user-update-success': 'Дані успішно збережено',
      },
      'validation-18-year': 'Вам немає 18 років.',
      'verification-complete': 'Перевірка замовлення успішно завершена',
      'verification-in-progress': 'Виконується перевірка',
      'verification-modal': {
        activate: 'Підтвердження замовлення',
        desc: 'Ви можете будь-коли підтвердити своє замовлення на сторінці свого облікового запису в розділі Мій обліковий запис > ОБЛІКОВИЙ ЗАПИС > Документи',
        'doc-uploaded': 'Виконується перевірка',
        Name: "Ім'я",
        proceed: 'Продовжити без перевірки',
        validate: 'Перевірка користувача',
      },
    },
    wallet: {
      'activate-bonus-info': 'Активуйте промо-бонус у розмірі {{bonus}} євро',
      'casino-bonus-info':
        'Вам потрібно витратити ще {{amountToUnlock}} євро, щоб розблокувати бонус казино',
      'casino-to-unlock': 'Бонус казино на розблокування',
      'free-bet': 'Безкоштовна ставка',
      'free-bet-bonus': 'Бонус за безкоштовні ставки',
      'free-bet-bonus-info':
        'Вам потрібно витратити ще {{amountToUnlock}} євро, щоб розблокувати безкоштовну ставку',
      'free-bet-bonus-to-unlock': 'Безкоштовний бонус при розблокуванні',
      'free-bet-bonus-unlock-message':
        'Вам потрібно витратити ще {{amountToUnlock}} євро, щоб розблокувати бонус безкоштовної ставки',
      'free-spin': 'Безкоштовні спіни',
      'free-spin-to-unlock': 'Безкоштовне обертання при розблокуванні',
      'free-spin-unlock-message':
        'Вам потрібно витратити ще {{amountToUnlock}} євро, щоб розблокувати безкоштовне обертання',
      'games-ticket-payout-info': 'Ви хочете оплатити наступний квиток?',
      'payout-success': 'Платіж успішно здійснено.',
      'poker-balance': 'Покерний баланс',
      'promo-bill': 'Промо рахунок',
      'promo-bonus': 'Промо бонус',
      'promo-to-unlock': 'Розблокування промо',
      'reserved-funds': 'Зарезервовані кошти',
      'standard-account': 'Стандартний рахунок',
      'standard-bill': 'Баланс',
      'submit-for-print-success': 'Успішно передано до друку',
      'ticket-successfully-paid': 'Квиток успішно оплачено',
      'valid-to': 'Дійсний до {{validTo}}',
      'voucher-payout-info': 'Бажаєте оплатити наступний ВАУЧЕР',
      'voucher-print': 'ДРУКУВАННЯ ВАУЧЕРА',
      'voucher-print-confirm': 'Ви хочете продовжити?',
      'voucher-print-info':
        'Усі кошти з Балансу буде видалено, а ВАУЧЕР на таку саму вартість буде надруковано',
      wallet: 'Обліковий запис',
      'wallet-locked': 'Обліковий запис заблоковано',
      'wallets-refresh': 'Оновіть інформацію про гаманець',
    },
  },
};

export default ua;
