import { ReactNode } from 'react';
import RcDrawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';

import './Drawer.scss';
import overlayStore from 'libs/overlay-store';

type DrawerPlacement = 'left' | 'top' | 'right' | 'bottom';

interface DrawerProps {
  visible: boolean;
  placement?: DrawerPlacement;
  children: ReactNode;
  onClose: any;
  className?: string;
  height: string;
  width: string;
}

const Drawer = ({
  visible,
  children,
  className,
  placement,
  height,
  width,
}: DrawerProps) => {
  return (
    <RcDrawer
      className={`${className} app-drawer`}
      mask={false}
      // handler={false}
      // level={null}
      open={visible}
      placement={placement}
      onClose={() => overlayStore.closeDrawer()}
      width={width}
      height={height}
    >
      {children}
    </RcDrawer>
  );
};

Drawer.defaultProps = {
  className: '',
  placement: 'left',
  width: '50vw',
  height: '100vh',
};

export { Drawer };
