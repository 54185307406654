type Gender = 'male' | 'female';

enum UserStatuses {
  REGISTERED = 'registered',
  ACTIVATED = 'activated',
  DOCUMENTS_UPLOADED = 'documents-uploaded',
  VALIDATED = 'validated',
}

type UserStatus =
  | UserStatuses.REGISTERED
  | UserStatuses.ACTIVATED
  | UserStatuses.DOCUMENTS_UPLOADED
  | UserStatuses.VALIDATED;

type GenderType = 'male' | 'female';

enum GenderEnum {
  male = 'Muški',
  female = 'Ženski',
}

interface User {
  id?: number;
  username?: string;
  password: string;
  first_name: string;
  last_name: string;
  is_active?: boolean;
  email: string;
  gender: Gender;
  country: string;
  city: string;
  address?: string;
  date_of_birth?: string | undefined;
  phone_number: string;
  ssn?: string;
  document_id?: string;
  nickname: string;
  terms_and_conditions: boolean;
  subscribed: boolean;
  subscribed_sms: boolean;
  subscribed_email: boolean;
  status?: UserStatus;
  promo_code?: string;
  eligible_for_bonus?: boolean;
  eligible_bonus_amount?: number;
}

enum TransactionTypes {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  WEB_BET = 'web_bet',
  WEB_BET_ROLLBACK = 'web_bet_rollback',
  WEB_WIN = 'web_win',
  FAIL = 'fail',
  WEB_CASINO_DEBIT = 'web_casino_debit',
  WEB_CASINO_CREDIT = 'web_casino_credit',
  WEB_CASINO_ROLLBACK = 'web_casino_rollback',
  WEB_GAME_BET = 'web_game_bet',
  WEB_GAME_WIN = 'web_game_win',
  WEB_GAME_ROLLBACK = 'web_game_rollback',
}

type TransactionType =
  | TransactionTypes.DEPOSIT
  | TransactionTypes.WITHDRAW
  | TransactionTypes.WEB_BET
  | TransactionTypes.WEB_BET_ROLLBACK
  | TransactionTypes.WEB_WIN
  | TransactionTypes.FAIL
  | TransactionTypes.WEB_CASINO_DEBIT
  | TransactionTypes.WEB_CASINO_CREDIT
  | TransactionTypes.WEB_CASINO_ROLLBACK
  | TransactionTypes.WEB_GAME_BET
  | TransactionTypes.WEB_GAME_WIN
  | TransactionTypes.WEB_GAME_ROLLBACK;

interface Transaction {
  amount: number;
  wallet_prefix: string | null;
  balance_after: number;
  balance_before: number;
  transaction_type: TransactionType;
  payment_provider: string;
  created_at: string;
}

interface ResponsibleGamblingRequest {
  // Reference: services2/user2/user2/serializers/user.py
  daily_deposit: number;
  daily_net_bet: number;
  daily_net_games: number;
  weekly_deposit: number;
  weekly_net_bet: number;
  weekly_net_games: number;
  monthly_deposit: number;
  monthly_net_bet: number;
  monthly_net_games: number;
  user_id?: number;
}

type ResponsibleGamblingRequestKeys = keyof ResponsibleGamblingRequest;

interface ResponsibleGamblingResponse {
  current_limits: ResponsibleGamblingRequest;
  new_limits: ResponsibleGamblingRequest;
  daily_spent: {
    daily_deposit: number;
    daily_net_bet: number;
    daily_net_games: number;
  };
  weekly_spent: {
    weekly_deposit: number;
    weekly_net_bet: number;
    weekly_net_games: number;
  };
  monthly_spent: {
    monthly_deposit: number;
    monthly_net_bet: number;
    monthly_net_games: number;
  };
  valid: number;
  timeout_until: string | null;
}

interface ResponsibleGamblingPauseResponse {
  blocked_actions: {
    betting: string;
    casino: string;
    timeout: boolean;
    virtual_games: string;
  };
  id: number;
}

export { UserStatuses, GenderEnum, TransactionTypes };

export type {
  Gender,
  UserStatus,
  GenderType,
  User,
  TransactionType,
  Transaction,
  ResponsibleGamblingRequest,
  ResponsibleGamblingResponse,
  ResponsibleGamblingRequestKeys,
  ResponsibleGamblingPauseResponse,
};
