/* React modules */

/* Our modules */
import monriPayment from 'images/monri-payment.png';
import footerSample0 from 'images/footer-sample-0.png';
import footerSample3 from 'images/footer-sample-3.png';
import footerSample7 from 'images/footer-sample-7.png';

/* 3rd Party modules */

const MAX_DEBIT_BANK = 5000;
const MIN_DEBIT_BANK = 5;

const AMOUNT_OPTIONS = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '250', value: 250 },
  { label: '500', value: 500 },
];

const CREDIT_CARD_FOOTER_THUMBNAILS = [
  { src: monriPayment, alt: 'M Pay', link: 'http://monri.com/' },
];

const FOOTER_THUMBNAILS = [
  { src: footerSample0, alt: 'MasterCard', link: 'http://www.mastercard.com/' },
  { src: footerSample7, alt: 'VISA', link: 'http://www.visa.com/' },
  { src: footerSample3, alt: 'monri', link: 'http://monri.com/' },
];

export {
  MAX_DEBIT_BANK,
  MIN_DEBIT_BANK,
  AMOUNT_OPTIONS,
  CREDIT_CARD_FOOTER_THUMBNAILS,
  FOOTER_THUMBNAILS,
};
