/* React modules */
import { ReactNode, useState, useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Icon from 'components/Icon';
import Button from 'components/Button';
import './SectionHeader.scss';

/* 3rd Party modules */
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface SectionHeaderProps {
  title: string;
  children?: ReactNode;
  hasCart?: boolean;
  hasBackBtn?: boolean;
}

const SectionHeader = observer(
  ({
    title,
    children,
    hasCart = false,
    hasBackBtn = false,
  }: SectionHeaderProps) => {
    const { t } = useTranslation();
    const { marketStore } = useStores();
    const { cartCount } = marketStore;
    const [isAnimatedCart, setIsAnimatedCart] = useState(false);

    useEffect(() => {
      if (cartCount === 0) return;
      setIsAnimatedCart(true);

      const timer = setTimeout(() => {
        setIsAnimatedCart(false);
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }, [cartCount]);

    return (
      <div className="section-header">
        <div className="heading-l uppercase">{title}</div>
        {children}

        {hasCart && (
          <Link
            to="/profil/korpa"
            className={`ml-auto section-header__cart-icon ${
              isAnimatedCart ? 'bumpAnimation' : ''
            }`}
          >
            {cartCount > 0 && (
              <span className="section-header__cart-icon-count">
                {cartCount}
              </span>
            )}
            <Icon size="regular" name="cart" />
          </Link>
        )}

        {hasBackBtn && (
          <Link to="/profil/market" className="ml-auto">
            <Button
              bg="green"
              size="regular"
              className="text-sb-extra-small"
              onClick={() => null}
            >
              {t('casino.back')}
            </Button>
          </Link>
        )}
      </div>
    );
  }
);

export default SectionHeader;
