import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import mockUserTickets from './mockUserTickets.json';
import mockTransactions from './mockTransactions.json';
import mockTicketStatus from './mockTicketStatus.json';

class MockApi {
  axios: any;

  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
  }

  initiateMocks() {
    const mock = new MockAdapter(this.axios, {
      onNoMatch: 'passthrough',
      delayResponse: 300,
    });

    mock.onGet('/user/tickets').reply(200, mockUserTickets);

    mock.onGet('/user/transactions').reply(200, mockTransactions);

    mock.onGet('/user/tickets-status').reply(200, mockTicketStatus);

    mock.onGet('/api/slip/slip/user').reply(200, mockUserTickets);
  }
}

export default MockApi;
