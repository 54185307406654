import { makeAutoObservable } from 'mobx';
import TicketBuilder from 'modules/ticket/store/ticket-builder';

export interface SystemGroupEvent {
  id: string;
  oddsCount: number;
}

export interface SystemGroup {
  name: number;
  system: number[];
  events: SystemGroupEvent[];
}

const initialGroups: SystemGroup[] = [
  { name: 1, system: [1], events: [] },
  { name: 2, system: [1], events: [] },
  { name: 3, system: [1], events: [] },
];

class SystemTicket {
  groups: SystemGroup[];
  bonusPercent: number = 0;
  maxBonusAmount: number = 0;
  minBonusAmount: number = 0;
  minOddsValue: number = 0;
  maxOddsValue: number = 0;
  numberOfCombinations: number = 0;
  minWinningAmount: number = 0;
  maxWinningAmount: number = 0;

  constructor() {
    this.groups = initialGroups;
    makeAutoObservable(this);
  }

  isSystemSelected = (group: SystemGroup, system: number) => {
    return group.system.includes(system);
  };

  toggleSystem = (group: SystemGroup, system: number) => {
    if (this.isSystemSelected(group, system)) {
      group.system = group.system.filter((s) => s !== system);
      if (!group.system[0]) {
        group.system.push(group.events.length);
      }
    } else {
      group.system.push(system);
      group.system.sort();
    }

    TicketBuilder.setSystemSlip();
  };

  add = (event: SystemGroupEvent, group: SystemGroup) => {
    group.events.push(event);
  };

  update = (eventId: string, oddsCount: number) => {
    const group = this.findGroupWithEvent(eventId);
    if (!group) return;

    group.events = group.events.map((e) => {
      if (e.id !== eventId) return e;
      return { id: eventId, oddsCount };
    });
  };

  removeAll = () => {
    this.groups = initialGroups;
  };

  remove = (eventId: string) => {
    const group = this.findGroupWithEvent(eventId);

    if (group) {
      const event = group.events.find((e) => e.id === eventId);
      if (!event) return;
      event.oddsCount -= 1;
      group.events = group.events.filter((e) => e.oddsCount !== 0);
      group.system = group.system.filter(
        (system) => system <= group.events.length
      );
    }
  };

  removeEvent = (eventId: string) => {
    const group = this.findGroupWithEvent(eventId);

    if (group) {
      group.events = group.events.filter((e) => e.id !== eventId);
      group.system = group.system.filter(
        (system) => system <= group.events.length
      );
    }
  };

  move = (eventId: string, group: SystemGroup) => {
    const currentGroup = this.findGroupWithEvent(eventId);
    const canMove = this.canMoveToGroup(eventId, group);
    const event = Object.assign(
      {},
      currentGroup?.events.find((e) => e.id === eventId)
    );
    if (!event) return;

    if (currentGroup && canMove) {
      this.removeEvent(eventId);
      this.add(event, group);

      if (this.isEmpty(currentGroup)) {
        this.shiftGroup(currentGroup);
      }

      if (
        currentGroup.system[currentGroup.system.length - 1] >
        currentGroup.events.length
      ) {
        currentGroup.system.pop();
      }
    }
  };

  setSystem = (arr: number[], group: SystemGroup) => {
    group.system = arr;
  };

  getAvailableGroups = (eventId: string) => {
    return this.groups.filter((group: SystemGroup) =>
      this.canMoveToGroup(eventId, group)
    );
  };

  shiftGroup = (group: SystemGroup) => {
    const next = this.findNext(group);

    if (next) {
      group.events = [...next.events];
      next.events = [];
    }
  };

  isBefore = (source: SystemGroup, target: SystemGroup): boolean => {
    const sourcePosition = this.groups.indexOf(source);
    const targetPosition = this.groups.indexOf(target);

    return sourcePosition < targetPosition;
  };

  findPrevious = (group: SystemGroup): SystemGroup => {
    const position = this.groups.indexOf(group);
    return this.groups[position - 1];
  };

  findNext = (group: SystemGroup): SystemGroup => {
    const position = this.groups.indexOf(group);
    return this.groups[position + 1];
  };

  isEmpty = (group: SystemGroup): boolean => {
    return group.events.length === 0;
  };

  canMoveToGroup = (event: string, targetGroup: SystemGroup) => {
    const currentGroup = this.findGroupWithEvent(event);
    if (!currentGroup) return false;

    const isBefore = this.isBefore(currentGroup, targetGroup);
    const groupBeforeTarget = this.findPrevious(targetGroup);

    if (currentGroup === targetGroup) return false;
    if (currentGroup.events.length === 1 && isBefore) return false;
    if (groupBeforeTarget && this.isEmpty(groupBeforeTarget)) return false;

    return true;
  };

  findGroupWithEvent = (event: string) => {
    return this.groups.find((g) => g.events.some((e) => e.id === event));
  };
}

export default SystemTicket;
