/* React modules */
import { useState, useEffect, useCallback, Fragment } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import overlayStore from 'libs/overlay-store';
import Button from 'components/Button';
import Select from 'components/Select';
import { LoaderWrapper } from 'components/Loader';
import SectionHeader from 'modules/user/ui/SectionHeader';
import MobileItem from 'modules/user/ui/UserMarket/Market/MobileItem/MobileItem';
import './Cart.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const OrderPlaceModal = () => {
  const { t } = useTranslation();
  const { bettingPlacesStore, marketStore } = useStores();
  const { getCart, placeOrder, emptyCart } = marketStore;
  const navigate = useNavigate();

  const [bettingPlaces, setBettingPlaces] = useState<any>({});
  const [addresses, setAddresses] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [selectedBettingPlace, setSelectedBettingPlace] = useState<any>(null);

  const sortBettingPlaces = (bettingPlaces: any) => {
    return Object.keys(bettingPlaces)
      .sort()
      .reduce((result: any, key) => {
        result[key] = bettingPlaces[key];
        return result;
      }, {});
  };

  useEffect(() => {
    const bettingPlacesOptions =
      bettingPlacesStore.getBettingPlacesByMunicipalities();
    const sortedBettingPlaces = sortBettingPlaces(bettingPlacesOptions);
    setBettingPlaces(sortedBettingPlaces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCity) {
      setSelectedBettingPlace(null);
      setAddresses(
        bettingPlaces[selectedCity.value].filter(
          (address: any) => !address.isTesting
        )
      );
    }
  }, [selectedCity, bettingPlaces]);

  const getCitiesOptions = () => {
    return Object.keys(bettingPlaces).map((place: string) => ({
      label: place,
      value: place,
    }));
  };

  const sortAdresses = (adressOptions: any) => {
    return adressOptions.sort((prevAdress: any, nextAdress: any) => {
      // use localeCompare to sort combined strings and numbers
      return prevAdress.label.localeCompare(nextAdress.label, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  };

  const formatName = (name: string) => {
    if (name.substr(-1) === 'A') {
      name = name.slice(0, -1);
    }
    const extractDigit = name.replace(/[^0-9]/g, '');
    if (extractDigit.length < 2) {
      name = name.replace(/[0-9]/g, '');
      name = name + '0' + extractDigit;
    }

    return name;
  };

  const getAddressesOptions = () => {
    const adressOptions = addresses.map(({ id, address, name }: any) => ({
      value: id,
      label: `${formatName(name)} - ${address}`,
    }));
    return sortAdresses(adressOptions);
  };

  const placeOrderHandler = async () => {
    const items: any = [];
    getCart.forEach((item: any, i: number) => {
      items[i] = { item: { id: item.id }, quantity: 1 };
    });

    const payload = {
      items,
      betting_place_id: selectedBettingPlace.value,
    };

    placeOrder(payload).then((resp: any) => {
      navigate('/profil/market');
      emptyCart();
    });
  };

  return (
    <div className="bg-black-400 pb-10">
      <section className="py-6 bg-black">
        <h2 className="text-center sb-heading-large uppercase">
          {t('globals.booking-an-order')}
        </h2>
      </section>
      <LoaderWrapper name="withdraw-betting-place">
        <section>
          <div className="text-center py-10">
            <p className="heading-regular">{t('globals.choose-order-place')}</p>
          </div>
        </section>
        <section>
          <div className="mx-12">
            <Select
              className="mb-1"
              options={getCitiesOptions()}
              bg="grey"
              size="regular"
              placeholder={t('globals.choose-a-city')}
              value={selectedCity}
              shape="squared"
              onChange={setSelectedCity}
            />
          </div>
          <div className="mx-12 mt-8">
            <Select
              className="mb-1"
              options={getAddressesOptions()}
              bg="grey"
              size="regular"
              placeholder={t('globals.choose-a-betting-place')}
              value={selectedBettingPlace}
              shape="squared"
              onChange={setSelectedBettingPlace}
            />
          </div>

          <div className="text-center mt-12">
            <Button
              className="__btn mt-1 w-40"
              style={{ color: 'black' }}
              size="large"
              bg="warning"
              onClick={placeOrderHandler}
            >
              {t('globals.submit')}
            </Button>
          </div>
        </section>
      </LoaderWrapper>
    </div>
  );
};

const Cart = () => {
  const { t } = useTranslation();
  const { marketStore, bettingPlacesStore } = useStores();
  const {
    getCart,
    removeFromCart,
    cartCount,
    totalPointsAmount,
    isDigital,
    placeOrder,
    emptyCart,
  } = marketStore;
  const navigate = useNavigate();

  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const handleExpand = (id: number) => {
    if (!expandedItems.includes(id)) {
      setExpandedItems([...expandedItems, id]);
    } else {
      const newArray = expandedItems.filter((item: any) => item !== id);
      setExpandedItems(newArray);
    }
  };

  const init = useCallback(async () => {
    await bettingPlacesStore.getBettingPlaces();
  }, [bettingPlacesStore]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOrderPlaceModal = () => {
    overlayStore.openModal(<OrderPlaceModal />, {
      width: 'medium',
    });
  };

  const placeOrderHadler = () => {
    const items: any = [];

    getCart.forEach((item: any, i: number) => {
      items[i] = { item: { id: item.id }, quantity: 1 };
    });
    const payload = { items: items };

    placeOrder(payload).then((resp: any) => {
      navigate('/profil/market');
      emptyCart();
    });
  };

  return (
    <div>
      <SectionHeader title={t('globals.cart')} hasBackBtn />

      <div className="cart-wrapper">
        {cartCount === 0 && (
          <div className="d-flex align-items justify-center text-white mt-20 uppercase sb-heading">
            {t('globals.cart-is-empty')}
          </div>
        )}

        <div className="cart">
          {getCart.map((item: any, index: number) => (
            <Fragment key={`Cart-Fragment-${index}`}>
              <MobileItem
                expandedItems={expandedItems}
                item={item}
                onClick={handleExpand}
              />

              <div className="cart__item">
                <img
                  className="cart__item-image"
                  src={item.imageUrl}
                  alt={item.name}
                />
                <div className="cart__item-content">
                  <div className="cart__item-content-title">
                    <p className="text-yellow text-bold">{item.name}</p>
                    <p className="text-white">
                      Poena:{' '}
                      <span className="text-green-800 text-extra-strong">
                        {item.price}
                      </span>
                    </p>
                  </div>
                  <div className="cart__item-content-description mt-1">
                    <div className="w-65 sb-text-small pr-2 text-white">
                      <p>{item.description}</p>
                    </div>
                    <div className="w-35 d-flex justify-end align-end sb-text-small">
                      <Button
                        bg="grey"
                        size="small"
                        className="text-sb-extra-small uppercase"
                        onClick={() => removeFromCart(item.id)}
                      >
                        {t('globals.remove-from-cart')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>

        {cartCount > 0 && (
          <div className="actions">
            <div className="d-flex justify-end mt-4 mr-3 sb-text-regular text-white">
              Ukupno poena: &nbsp;
              <span className="text-green-800">{totalPointsAmount}</span>
            </div>
            <div className="d-flex justify-center mt-2">
              <Button
                bg="green"
                size="regular"
                className="text-sb-extra-small uppercase"
                onClick={isDigital ? placeOrderHadler : selectOrderPlaceModal}
              >
                {t('globals.place-order')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Cart);
