/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import {
  DropItem,
  CashDropError,
  CashDropStatus,
} from 'modules/user/cash-drop.types';
import CashDropApi from 'modules/user/cash-dorp.api';
import authStore from 'modules/auth/auth.store';
import { toDate } from 'libs/datetime';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';

class CashDropStore {
  api: CashDropApi;
  cashDrops: any = [];
  activeDrop: DropItem | null = null;
  status: CashDropStatus.ENABLED | CashDropStatus.DISABLED =
    CashDropStatus.DISABLED;

  constructor() {
    makeAutoObservable(this);
    this.api = new CashDropApi();
  }

  get dropsList() {
    return this.cashDrops;
  }

  get cashDropStatus() {
    return this.status;
  }

  getCashDrops = async () => {
    try {
      const response = await this.api.getDailyDrops(authStore.token);
      if (response) {
        const dropsList = this.parseDateTimeDrops(response.dropsList);
        this.cashDrops = dropsList;
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('CashDropStore -> getCashDrops -> exception', exception);
    }
  };

  collect = async (dropId: number) => {
    try {
      const response = await this.api.collect(dropId, authStore.token);

      if (response.error === undefined) {
        toast.success(i18next.t('globals.successfully-collected-cash-drop'));
      }

      if (
        !response.status &&
        response?.error?.messageList[0].includes(CashDropError.USER_MIN_BALANCE)
      ) {
        const minBalance = response.error.messageList[0]
          .split(':')[1]
          .substr(0, 5);
        toast.error(
          i18n.t('errors.err-cash-drop-user-min-balance', { a: minBalance })
        );
      } else if (
        !response.status &&
        response?.error?.messageList[0].includes(CashDropError.USER_MIN_BET)
      ) {
        const minBet = response.error.messageList[0]
          .split('USER_MIN_BET:')[1]
          .substr(0, 3);
        const hours = response.error.messageList[0]
          .split('IN_HOURS:')[1]
          .substr(0, 3);
        toast.error(
          i18n.t('errors.err-cash-drop-user-min-bet', { a: minBet, b: hours })
        );
      } else if (
        !response.status &&
        response?.error?.messageList[0].includes(CashDropError.DROP_NOT_ACTIVE)
      ) {
        toast.error(i18n.t('errors.err-cash-drop-not-active'));
      } else if (
        !response.status &&
        response?.error?.messageList[0].includes(CashDropError.DROP_NOT_FOUND)
      ) {
        toast.error(i18n.t('errors.err-cash-drop-not-found'));
      } else if (
        !response.status &&
        response?.error?.messageList[0].includes(
          CashDropError.DROP_ALREADY_COLLECTED_BY_USER
        )
      ) {
        toast.error(i18n.t('errors.err-cash-drop-already-collected-by-user'));
      } else if (
        !response.status &&
        response?.error?.messageList[0].includes(
          CashDropError.GROUP_ALREADY_COLLECTED_BY_USER
        )
      ) {
        toast.error(
          i18n.t('errors.err-cash-drop-group-already-collected-by-user')
        );
      } else if (
        !response.status &&
        response?.error?.messageList[0].includes(CashDropError.DROP_COLLECTED)
      ) {
        toast.error(i18n.t('errors.err-cash-drop-already-collected'));
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('CashDropStore -> collect -> exception', exception);
    } finally {
      this.getCashDrops();
    }
  };

  getCashDropStatus = async () => {
    try {
      const response = await this.api.getCashDropStatus(authStore.token);
      if (response) {
        this.status = response.status;
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('CashDropStore -> getCashDropStatus -> exception', exception);
    }
  };

  setNewCashDrop = async (drop: any) => {
    const dateTime = toDate(drop.msg.start);
    const rawTime = dateTime.toLocaleTimeString();
    const time =
      rawTime.split(':')[0] + ':' + rawTime.split(':')[1].substr(0, 2);
    const date = dateTime.toLocaleDateString();

    const newCashDrop = {
      ...drop.msg,
      active: drop.msg.active,
      awardAmount: drop.msg.award_amount,
      awardType: drop.msg.award_type,
      collected: drop.msg.collected,
      date: date,
      time: time,
    };

    var result = this.cashDrops.find((item: any) => item.id === newCashDrop.id);
    if (!result) {
      this.cashDrops.push(newCashDrop);
    } else {
      const index = this.cashDrops.findIndex(
        (item: any) => item.id === result.id
      );
      this.cashDrops[index] = newCashDrop;
    }
  };

  parseDateTimeDrops = (dropsList: any) => {
    const list = dropsList.map((drop: any) => {
      const dateTime = toDate(drop.start);
      const rawTime = dateTime.toLocaleTimeString();
      const time =
        rawTime.split(':')[0] + ':' + rawTime.split(':')[1].substr(0, 2);
      const date = dateTime.toLocaleDateString();

      return {
        ...drop,
        date: date,
        time: time,
      };
    });
    const sortedListPerDate = list.sort((a: any, b: any) => b.start - a.start);
    return sortedListPerDate;
  };

  resetCashDrop = () => {
    this.cashDrops = [];
  };
}

export default new CashDropStore();
