/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import useQuery from 'libs/useQuery';
import { logger } from 'libs/common-helpers';
import './GamblingGamePlaceholder.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const GamblingGamePlaceholder = () => {
  const { t, i18n } = useTranslation();
  const { authStore, gamblingStore } = useStores();
  const { getGameUrl, gameUrl, tryGameUrl } = gamblingStore;
  const [error, setError] = useState('');

  let queryParams = useQuery();

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  const handleBadParams = () => {
    setError(t('errors.system-error'));
  };

  const init = async (token: string | null, gameId: string) => {
    const request = token ? getGameUrl : tryGameUrl;

    if (token) {
      authStore.setToken(token);
    }

    try {
      const response = await request(Number.parseInt(gameId, 10), token || '');

      if (!response.game_url) {
        handleBadParams();
      }
    } catch (exception: any) {
      handleBadParams();

      logger('GamblingGamePlaceholder -> init -> exception', exception);
    }
  };

  useEffect(() => {
    const token = getParam('token') || '';
    const gameId = getParam('game_id');
    const lang = getParam('lang') || 'me';

    i18n.changeLanguage(lang as string);

    if (!gameId) {
      handleBadParams();
    } else {
      init(token, gameId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gambling-game-placeholder">
      {gameUrl ? (
        <iframe src={gameUrl} title="Casino" width="100%" height="100%" />
      ) : (
        <div className="gambling-game-placeholder__error text-center">
          {error}
        </div>
      )}
    </div>
  );
};

export default observer(GamblingGamePlaceholder);
