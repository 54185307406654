/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import Button from 'components/Button';
import overlayStore from 'libs/overlay-store';
import './Logout.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Logout = observer(() => {
  const {
    authStore: { logout },
  } = useStores();

  const handleLogout = () => {
    logout();
    overlayStore.closeModal();
  };

  const { t } = useTranslation();

  return (
    <div className="logout">
      <div className="logout__header">
        <p>{t('login.logout-1')}</p>
      </div>
      <div className="logout__body py-6">
        <p className="px-2 text-center">{t('login.logout-question')}</p>
        <div className="d-flex justify-center align-items pt-8 pb-2">
          <Button bg="grey" className="mr-8" onClick={overlayStore.closeModal}>
            <div className="d-flex align-items">
              <span className="text-regular text-bold">
                {t('globals.cancel')}
              </span>
            </div>
          </Button>
          <Button bg="green" onClick={handleLogout}>
            <div className="d-flex align-items">
              <span className="text-regular text-bold">
                {t('login.logout')}
              </span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
});

export default Logout;
