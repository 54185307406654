/* React modules */
import { ReactNode, useState } from 'react';

/* Our modules */
import Icon from 'components/Icon';
import './Dropdown.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { Popover } from 'react-tiny-popover';

export interface DropdownOption {
  label: string;
  value: number | string;
  icon?: string;
}

interface DropdownProps {
  title?: string;
  children: ReactNode;
  options?: DropdownOption[];
  template?: ReactNode;
  handleSelect?: (value: any) => void;
  className?: string;
  bg?: 'dark' | 'transparent';
  style?: any;
}

const Dropdown = ({
  title,
  children,
  options,
  template,
  handleSelect,
  bg,
  className,
  style,
}: DropdownProps) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => setIsActive(!isActive);

  return (
    <Popover
      isOpen={isActive}
      positions={['bottom']}
      reposition={true}
      onClickOutside={() => toggleActive()}
      content={() => (
        <div
          style={style}
          className={classNames(
            'dropdown__content',
            `dropdown__content-${bg}`,
            className
          )}
        >
          {title ? (
            <div onClick={toggleActive} className="dropdown__title pointer">
              {title}
            </div>
          ) : null}

          {template ? template : null}

          {options && (
            <ul>
              {options.map((option: any) => (
                <li
                  key={`Dropdown-li-${option.label}`}
                  className="dropdown__option"
                  onClick={() => {
                    if (handleSelect) {
                      handleSelect(option.value);
                      setIsActive(false);
                    }
                  }}
                >
                  <span />
                  {option.label}
                  {option.icon ? (
                    <Icon name={option.icon} size="extra-small" />
                  ) : (
                    <span />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    >
      <div onClick={toggleActive} className="dropdown__trigger">
        {children}
      </div>
    </Popover>
  );
};

Dropdown.defaultProps = {
  bg: 'transparent',
  className: '',
};

export default Dropdown;
