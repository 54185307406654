/* React modules */

/* Our modules */
import resources from './resources';

/* 3rd Party modules */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  lng: 'me',
  resources,
  fallbackLng: ['en', 'me'],
});

export default i18next;
