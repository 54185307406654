/* React modules  */
import React, {
  useState,
  useCallback,
  InputHTMLAttributes,
  ChangeEvent,
  ReactNode,
} from 'react';

/* Our modules */
import './Input.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type InputBg =
  | 'light'
  | 'dark'
  | 'grey'
  | 'transparent'
  | 'gambling-dark'
  | 'white'
  | 'black';
type InputHeight = 'regular' | 'small' | 'large';
type InputTheme = 'form' | 'search' | 'search-small';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  bg?: InputBg;
  className?: string;
  id?: string;
  height?: InputHeight;
  name: string;
  error?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  theme?: InputTheme;
  placeholder?: string;
  selectOnFocus?: boolean;
  rounded?: boolean;
  addon?: ReactNode;
  decimal?: boolean;
  number?: boolean;
  onDecimalChange?: (val: string) => void;
}

const Input = React.forwardRef(
  (
    {
      height,
      bg,
      className,
      name,
      error,
      onChange,
      onFocus,
      onKeyPress,
      theme,
      placeholder,
      rounded,
      addon,
      selectOnFocus,
      decimal,
      number,
      onDecimalChange,
      ...props
    }: InputProps,
    ref
  ) => {
    const [dirty, setDirty] = useState(false);

    const selectInputValue = (e: any) => {
      // @ts-ignore
      if (selectOnFocus) document.getElementById('amount')?.select();
    };

    const handleChange = useCallback(
      (event) => {
        if (!dirty) {
          setDirty(true);
        }

        if (!decimal) {
          onChange(event);
        } else {
          const start = event.target.selectionStart;
          let val = event.target.value;
          val = val.replace(/([^0-9.]+)/, '');
          val = val.replace(/^(0|\.)/, '');
          const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val) || [];
          let value = match[1] + match[2];

          event.target.value = value;

          if (val.length > 0) {
            event.target.value = Number(value).toFixed(2);
            event.target.setSelectionRange(start, start);

            if (onDecimalChange) {
              onDecimalChange(Number(value).toFixed(2));
            }
          }
        }
      },
      [onChange, dirty, decimal, onDecimalChange]
    );

    const { t } = useTranslation();

    return (
      <>
        <div className={`input-wrapper ${className}`}>
          <input
            //@ts-ignore
            ref={ref}
            placeholder={placeholder}
            data-testid={name}
            spellCheck={false}
            className={classNames(
              `input input-${bg}`,
              `input-${theme}`,
              `input-${height}`,
              {
                'input-invalid': !!error,
                'input-rounded': rounded,
              }
            )}
            onFocus={selectInputValue}
            onKeyPress={handleChange}
            onChange={handleChange}
            name={name}
            {...props}
          />
          {addon && <span className="input__addon">{addon}</span>}
        </div>
        {error ? <div className="input__error">{t(error)}</div> : null}
      </>
    );
  }
);

Input.defaultProps = {
  type: 'text',
  bg: 'dark',
  height: 'regular',
  placeholder: '',
  className: '',
  theme: 'form',
  rounded: false,
  decimal: false,
};

export { Input };
