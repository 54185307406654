import { useState, useEffect } from 'react';

export const breakpoints = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1440,
};

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  // If Effect subscribes to something, the cleanup function should unsubscribe (https://react.dev/learn/synchronizing-with-effects#subscribing-to-events)
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
