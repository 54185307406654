/* React modules */

/* Our modules */
import AuthApi from 'modules/auth/auth.api';
import { User } from 'modules/user/user.models';
import { Credentials, LoginErrors, LoginError } from 'modules/auth/auth.models';
import overlayStore from 'libs/overlay-store';
import userStore from 'modules/user/user.store';
import feedStore from 'modules/feed/feed.store';
import gamblingStore from 'modules/gambling/gambling.store';
import loaderStore from 'components/Loader/loader.store';
import { setHeaders, removeHeader, interceptors } from 'app/axios-config';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable, runInAction } from 'mobx';

class AuthStore {
  api: AuthApi;

  token: string = '';
  error: LoginError = null;
  loginSuccess = false;
  registerError = null;

  constructor() {
    interceptors.push(this.onAuthError.bind(this));

    this.api = new AuthApi();
    this.loadToken();
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.token;
  }

  loadToken = () => {
    const token = localStorage.getItem('token') || '';

    if (token) {
      this.token = token;
      setHeaders({ Authorization: this.getFullToken() });
    }
  };

  login = (credentials: Credentials) => {
    this.error = null;
    loaderStore.addLoader('login-form');

    return this.api.login(credentials).then(this.onLoginSuccess);
  };

  onLoginSuccess = (response: any) => {
    loaderStore.removeLoader('login-form');
    const { data } = response;
    userStore.setUser(data.user);
    const { token } = data;
    overlayStore.closeModal();
    this.setToken(token);
    this.loginSuccess = true;
    this.error = null;
    if (window.location.pathname.includes('gambling')) {
      gamblingStore.getLeaderBoard();
      gamblingStore.getFavouriteGames();
    }
    feedStore.close();
    feedStore.start();
  };

  onAuthError = (error: any) => {
    loaderStore.removeLoader('login-form');
    const { response } = error;
    runInAction(() => {
      if (response?.status === 400) {
        if (response?.data?.detail === 'INVALID PASSWORD') {
          this.error = LoginErrors.WRONG_CREDENTIALS;
        } else if (response?.data?.detail === 'ERR_USER_BLOCKED') {
          this.error = LoginErrors.USER_BLOCKED;
        } else {
          this.error = LoginErrors.SESSION_EXPIRED;
        }
      } else if (response?.status === 401) {
        if (response?.data?.detail === 'ERR_USER_NOT_ACTIVE') {
          this.error = LoginErrors.USER_NOT_ACTIVE;
        } else {
          this.error = LoginErrors.WRONG_CREDENTIALS;
        }

        this.logout();
      } else {
        this.error = LoginErrors.UNKNOWN;
      }
    });
  };

  logout = () => {
    this.token = '';
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    removeHeader('Authorization');
    feedStore.close();
    feedStore.start();
    this.loginSuccess = false;
  };

  changePassword = (data: object) => {
    return this.api.changePassword(data);
  };

  resetPassword = (data: object) => {
    return this.api.resetPassword(data);
  };

  submitResetPassword = (data: object, token: string) => {
    return this.api.submitResetPassword(data, token);
  };

  setToken = (token: string) => {
    localStorage.setItem('token', token);
    this.token = token;
    setHeaders({ Authorization: this.getFullToken() });
  };

  clearError = () => {
    this.error = null;
  };

  register = (user: User) => {
    return this.api.register(user);
  };

  activateAccount = (token: string, cb: () => void) => {
    this.api.activateAccount(token).then(() => cb());
  };

  getFullToken = () => {
    return `Bearer ${this.token}`;
  };

  userExistsCheck = async (params: any) => {
    try {
      const { data } = await this.api.userExistsCheck(params);
      return data.exists;
    } catch (exception) {
      logger('AuthStore -> userExistsCheck -> exception', exception);
    }
  };
}

export default new AuthStore();
