/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import Button from 'components/Button';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const GamblingPause = observer(
  ({ onConfirm }: { onConfirm: () => Promise<void> }) => {
    const { t } = useTranslation();

    const {
      overlayStore: { closeModal },
    } = useStores();

    const onConfirmPause = async () => {
      await onConfirm();

      closeModal();
    };

    return (
      <div className="d-flex justify-center align-items py-16">
        <div>
          <h4 className="text-center px-5 mb-4">
            {t('userData.pause-gambling-confirmation')}
          </h4>

          <Button
            className="cookies-banner__btn"
            size="regular"
            bg="warning"
            onClick={onConfirmPause}
          >
            {t('globals.submit')}
          </Button>
        </div>
      </div>
    );
  }
);

export default GamblingPause;
