/* React modules */

/* Our modules */
import { CasinoGame } from 'modules/gambling/gambling.types';
import GamesCarousel from 'modules/gambling/ui/GamesCarousel';
import { Icon } from 'components';
import './PromoCarousel.scss';

/* 3rd Party modules */
import classNames from 'classnames';

const carouselConfig = {
  infinite: true,
  vertical: true,
  verticalSwiping: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'linear',
  arrows: false,
  draggable: false,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 800,
  centerMode: true,
};

const PromoCarousel = ({
  games,
  title,
  variant,
  displayName,
  biggestWin,
  displayAmount,
}: {
  games: CasinoGame[];
  title: string;
  variant?: 'horizontal' | 'biggest-win' | 'most-played';
  displayName?: boolean;
  biggestWin?: boolean;
  displayAmount?: boolean;
}) => {
  let slider: any;
  const nextSlide = () => slider.slickNext();
  const prevSlide = () => slider.slickPrev();

  return (
    <div
      className={classNames('promo-carousel', {
        horizontal: variant === 'horizontal',
        'biggest-win': variant === 'biggest-win',
        'most-played': variant === 'most-played',
      })}
    >
      <span>{title}</span>
      <div className="promo-carousel__controls">
        <div className="promo-carousel__previous">
          <Icon
            size="small"
            container="gambling"
            name="caretUp"
            onClick={prevSlide}
          />
        </div>
        <div className="promo-carousel__next">
          <Icon
            size="small"
            container="gambling"
            name="caretDown"
            onClick={nextSlide}
          />
        </div>
      </div>
      <div>
        <GamesCarousel
          biggestWin={biggestWin}
          displayName={displayName}
          refHandler={(reference) => (slider = reference)}
          variant={variant}
          carouselConfig={carouselConfig}
          gamesList={games as any}
          size="large"
          hover
          displayAmount={displayAmount}
        />
      </div>
    </div>
  );
};

export { PromoCarousel };
