/* React modules */

/* Our modules */
import COUNTRY_CODES from 'components/CountryIcon/countryCodes';
import './CountryIcon.scss';

/* 3rd Party modules */
import classNames from 'classnames';

type CountryIconSize = 'regular' | 'large' | 'extra-large';

const COUNTRY_ICONS = COUNTRY_CODES as any;

interface CountryIconProps {
  country: string;
  size: CountryIconSize;
  original: boolean;
}

const CountryIcon = ({ country, size, original }: CountryIconProps) => (
  <span
    className={classNames(
      'flag-icon',
      { 'flag-icon-squared': !original },
      `flag-icon-${country}`,
      'country-icon',
      { original },
      `${size}`
    )}
  ></span>
);

CountryIcon.defaultProps = {
  size: 'regular',
  original: false,
};

export { CountryIcon, COUNTRY_ICONS };
