/* React modules */

/* Our modules */
import { TransactionType } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import { TYPE_FILTER_OPTIONS } from 'modules/user/ui/UserTransactions/TransactionsFilter/TransactionsFilter';
import { toDateObj, formatDate, formatTime } from 'libs/datetime';
import './TransactionsMobile.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';

const TransactionsMobile = () => {
  const {
    userStore: { transactionFilters },
  } = useStores();

  const { t } = useTranslation();

  const { transactions, loadNextPage, totalCount, hasNext } =
    transactionFilters;

  const scrollableTransactionsMobileRef = useInfiniteScroll<HTMLDivElement>({
    next: loadNextPage,
    rowCount: totalCount,
    hasMore: { down: hasNext },
  });

  const typeFilterOptions = TYPE_FILTER_OPTIONS(t);

  const renderTransactionType = (value: TransactionType) => {
    return typeFilterOptions.find((type: any) => type.value === value)?.label;
  };

  const renderTransactionDate = (value: string) => {
    const date = toDateObj(value);
    return `${formatDate(date)} ${formatTime(date)}`;
  };

  return (
    <div className="transactions-mobile sb-text-small">
      <div className="w-100 bg-grey-800 text-white transactions-mobile__header">
        <div className="w-90 h-full mx-auto d-flex align-items uppercase">
          <div className="w-35 d-flex">{t('globals.date')}</div>
          <div>{t('globals.type')}</div>
          <div className="ml-auto">{t('payments.amount')}</div>
        </div>
      </div>

      <div
        ref={scrollableTransactionsMobileRef}
        className="user-ticket-overview__body"
      >
        {transactions.map((transaction: any, index: number) => (
          <div
            key={`TransactionsMobile-div-${index}`}
            className="w-100 text-white border-b transactions-mobile__body"
          >
            <div className="w-90 h-full mx-auto d-flex align-items">
              <div className="w-35">
                {renderTransactionDate(transaction.created_at)}
              </div>
              <div>{renderTransactionType(transaction.transaction_type)}</div>
              <div className="ml-auto">{transaction.amount} EUR</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(TransactionsMobile);
