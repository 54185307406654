/* React modules */

/* Our modules */
import { EventMessageActions, FeedMessageType } from 'modules/feed/feed.api';
import feedStore from 'modules/feed/feed.store';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import { normalizeFeedEvent } from 'modules/sports/services/sports-data.service';

/* 3rd Party modules */

class EventFeedService {
  private connectedEvents: Map<string, any> = new Map();

  constructor() {
    feedStore.registerHandler(FeedMessageType.EVENT, this.onEventUpdate);
  }

  onEventUpdate = (event: any) => {
    const { a, e_id, r } = event;
    const handlers = this.connectedEvents.get(e_id);
    if (!handlers) return;

    const isUpdate = [
      EventMessageActions.ENABLE,
      EventMessageActions.DISABLE,
    ].includes(a);

    if (a === EventMessageActions.DELETE) {
      handlers.onDelete();
      const data = normalizeFeedEvent(event);
      ticketBuilder.checkDoesEventDeleted(data);
    }

    if (isUpdate) {
      const isDisabled = a === EventMessageActions.DISABLE;
      handlers.toggleDisabled(isDisabled);
      // handlers.onUpdate(mon);
      if (r) {
        handlers.onTimeChange(r.c_p);
        handlers.onResultChange(r);
      }
    }
  };

  connectEvent = (id: string, handlers: any) => {
    this.connectedEvents.set(id, handlers);
  };
}

export default new EventFeedService();
