const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const isEmail = (value: string) => {
  return EMAIL_REGEX.test(value);
};

export const required = (value: string) => {
  return !!value;
};

export const match = (value: string, confirmValue: string) => {
  return value === confirmValue;
};

export const maxDate = (years: number): Date => {
  let today = new Date();
  today.setFullYear(new Date().getFullYear() - years);
  return today;
};

const Validators = {
  required: {
    message: 'errors.field-required',
    validate: required,
  },
  email: {
    message: 'errors.email-invalid',
    validate: isEmail,
  },
  match: (customMessage: string, values: any) => ({
    message: customMessage || 'errors.passwords-dont-match',
    validate: () => match(values.value, values.confirmValue),
  }),
  minLength: (len: number, customMessage?: string) => ({
    message: customMessage || 'errors.field-invalid',
    validate: (val: string) => val.length >= len,
  }),
  maxLength: (len: number, customMessage?: string) => ({
    message: customMessage || 'errors.field-invalid',
    validate: (val: string) => val.length <= len,
  }),
  onlyLetters: {
    message: 'validations.only-letters-and-spaces-allowed',
    validate: (val: string) => val.match(/^[a-zA-Za-žA-Ž\s]*$/g),
  },
  noSpace: {
    message: 'validations.no-space',
    validate: (val: string) => val.match(/^\S+$/g),
  },
  onlyNumbers: {
    message: 'validations.only-numbers-allowed',
    validate: (val: string) => val.match(/^[0-9]*$/g),
  },
};

export default Validators;
