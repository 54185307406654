/* React modules */
import { Fragment, useState } from 'react';

/* Our modules */
import { CashDropType } from 'modules/user/cash-drop.types';
import useStores from 'common/hooks/useStores';
import Button from 'components/Button';
import freeSpinBackground from '../../../../../images/free-spin.png';
import freeBetBackground from '../../../../../images/free-bet.png';
import './SingleDrop.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SpinnerCircularSplit } from 'spinners-react';

interface SingleDropProps {
  id: number;
  active: boolean;
  awardAmount: number;
  awardType: string;
  collected: number;
  collectedByUser?: boolean;
  total: number;
  date?: string;
  time?: string;
}

const SingleDrop = observer(
  ({
    id,
    date,
    time,
    active,
    collected,
    awardAmount,
    awardType,
    collectedByUser,
    total,
  }: SingleDropProps) => {
    const { t } = useTranslation();
    const { cashDropStore } = useStores();
    const { collect } = cashDropStore;
    const title =
      awardType === CashDropType.FREE_BET
        ? `FREE BET - ${awardAmount} EUR`
        : `FREE SPIN - ${awardAmount} EUR`;
    const backgroundImage = CashDropType.FREE_BET
      ? freeBetBackground
      : freeSpinBackground;
    const [loading, setLoading] = useState(false);

    const collectHandler = async () => {
      setLoading(true);
      try {
        collect(id);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Fragment>
        <div className="shadow">
          <li
            style={{ backgroundImage: `url(${backgroundImage})` }}
            className={`single-drop 
            ${!active ? 'single-drop__not-active' : ''}
            ${collectedByUser ? 'single-drop__collected' : ''}`}
          >
            <div>
              <p className="sb-text-small text-bold">{title}</p>
              <p className="text-super-small mt-1">
                {collected} / {total}
              </p>
            </div>
            {loading && (
              <SpinnerCircularSplit
                enabled={true}
                color="#acf439"
                size="100%"
              />
            )}
            {active && !collectedByUser && !loading && (
              <Button
                bg="green"
                size="small"
                onClick={collectHandler}
                className="uppercase"
              >
                {t('globals.collect')}
              </Button>
            )}
          </li>
        </div>
        <p
          className={`d-flex justify-end align-items mt-1 sb-text-small ${
            !active ? 'single-drop__not-active-time' : ''
          }`}
        >
          {time}
        </p>
      </Fragment>
    );
  }
);

export default SingleDrop;
