import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Sidebar.scss';

export interface SidebarProps {
  children: ReactNode;
  borderLeft?: boolean;
  borderRight?: boolean;
}

const Sidebar = ({ children, borderLeft, borderRight }: SidebarProps) => {
  return (
    <div
      className={classNames('sidebar', {
        'sidebar__bordered-left': borderLeft,
        'sidebar__bordered-right': borderRight,
      })}
      data-testid="sidebar"
    >
      {children}
    </div>
  );
};

Sidebar.defaultProps = {
  borderLeft: false,
  borderRight: false,
};

export default Sidebar;
