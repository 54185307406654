const al = {
  translation: {
    casino: {
      'all-games': 'TË GJITHA LOJRAT',
      award: 'Shpërblimi',
      back: 'Mbrapa',
      'biggest-wins': 'FITIMET MË TË MËDHA',
      'casino-brands': 'BREDET E KAZINOSË',
      categories: 'KATEGORITË',
      category: 'KATEGORIA',
      close: 'Mbylle',
      demo: 'PROVO',
      'favourite-games': 'LOJRAT QË PËLQEN',
      'game-of-week': 'LOJA E JAVËS',
      'last-played': 'LOJA E FUNDIT E LOZUR',
      'most-played': 'LOJA QË LUAN MË SHUMË',
      play: 'LUAJ',
      points: 'Pikët',
      provider: 'OFRUESI',
      sections: 'SEKSIONET',
      'see-all': 'SHIHI TË GJITHA',
      'use-free-casino': 'Përdor free spin',
      user: 'Përdoruesi',
    },
    countries: {
      albania: 'Shqipëria',
      'bosnia-and-herzegovina': 'Bosnja dhe Hercegovina',
      croatia: 'Kroacia',
      kosovo: 'Kosova',
      montenegro: 'Mali i Zi',
      'republic-of-north-macedonia': 'Republika e Maqedonisë së Veriut',
      russia: 'Rusia',
      serbia: 'Serbia',
      turkey: 'Turqi',
      ukraine: 'Ukraina',
    },
    errorPage: {
      'reload-page': 'Ju lutemi ta rifreskoni faqen',
      'something-went-wrong': 'Ndodhi një gabim',
    },
    errors: {
      'activation-link-exp':
        'Në mënyrë që të kompletoni regjistrimin, është e nevojshme që të aktivizoni llogarinë tuaj. Linku i aktivizimit ju është dërguar në adresën e e-mail-it',
      'another-order-exists': 'Tashmë ekziston një porosi tjetër',
      'bad-password': 'Fjalëkalim i gabuar',
      'bet-not-possible': 'Momentalisht nuk mund të bëni bast për këtë ngjarje',
      'betting-blocked': 'Vënia e Basteve është bllokuar',
      'can-not-find-user': 'Nuk është e mundur që të gjendet përdoruesi',
      'cancel-funds': 'Pagesa e planifikuar nuk u gjet',
      'cannot-add-more-than-one-digital-item':
        'Nuk mund të shtoni në shportë më shumë se një prodhim të tipit dixhital ',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Nuk mund të blini bonus kazinoje në rast se keni një bonus ekzistues ',
      'cannot-cancel-order': 'Nuk mund të prapësoni porosinë',
      'cannot-order-mixed-items':
        'Nuk mund të porositni tipe të ndryshme prodhimesh',
      'cannot-order-more-than-one-digital-item':
        'Nuk mund të porositni më shumë se një prodhim të tipit dixhital',
      'choose-betting-place': 'Zgjidhni vendin e marrjes së porosisë',
      'connection-offline': 'Është ndërprerë lidhja me Internetin',
      'credentials-error':
        'Emri i përdoruesit dhe fjalëkalimi nuk janë futur në rregull',
      'data-error-reading':
        'Gabin gjatë ruajtjes së të dhënave. Ju lutemi të tentoni përsëri',
      'email-exists': 'Përdorues me këtë adresë e-maili  ekziston tashmë',
      'email-invalid': 'E-mail-i nuk është i vlefshëm',
      'err-cash-drop-already-collected':
        'Dikush ka qenë më i shpejtë se Ju. CashDrop është përdorur dhe nuk është më në dispozicion',
      'err-cash-drop-already-collected-by-user': 'CashDrop është marrë tashmë',
      'err-cash-drop-group-already-collected-by-user':
        'Ju nuk mund të merrni CashDrop nga ky grup',
      'err-cash-drop-not-active':
        'CashDrop ka skaduar dhe nuk është më në dispozicion',
      'err-cash-drop-not-found': 'CashDrop nuk u gjet',
      'err-cash-drop-user-min-balance':
        'Duhet që të kini të paktën {{a}} EUR që të merrni CashDrop',
      'err-cash-drop-user-min-bet':
        'Duhet të shpenzoni të paktën {{a}} EUR në  {{b}} orët e fundit që të mund të merrni CashDrop',
      ERR_BETTING_MACHINE_BLOCKED: 'Ky veprim është bllokuar',
      ERR_CANT_PAYOUT_BETTING_PLACE:
        'Kuponi nuk mund tu paguhet në këtë vend pagesash',
      ERR_CANT_PAYOUT_MUNICIPALITY: 'Kuponi nuk mund tu paguhet në këtë komunë',
      ERR_CASINO_BLOCKED: 'Lojërat e kazinosë janë bllokuar',
      ERR_DEPOSIT_BLOCKED: 'Pagesa e përdoruesit është bllokuar',
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE:
        'Pagesa në automatin e basteve është bllokuar',
      ERR_INVALID_GAME: 'Loja nuk është e vlefshme',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        'Bileta nuk është më e vlefshme për pagesë. Ju lutemi kontaktoni operatorin.',
      ERR_USER_BLOCKED_ALREADY: 'Llogaria juaj tashmë është bllokuar.',
      ERR_USER_NOT_VALIDATED: 'Përdoruesi nuk është verifikuar',
      ERR_VIRTUAL_GAMES_BLOCKED: 'Lojërat virtuale janë bllokuar',
      ERR_VOUCHER_CONFIG_NOT_FOUND: 'Nuk ekziston konfiguracion për kuponin',
      ERR_VOUCHER_EXPIRED: 'Kuponi ka skaduar',
      ERR_VOUCHER_NOT_FOUND: 'Kuponi nuk ekziston',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT:
        'Kuponi nuk është i vlefshëm për tu paguar',
      ERR_STREAM_NOT_AVAILABLE:
        'Transmetimi i drejtpërdrejtë nuk ofrohet aktualisht',
      ERR_STREAM_TYPE_NOT_AVAILABLE:
        'Ky lloj transmetimi i drejtpërdrejtë nuk mbështetet',
      ERR_LOCATION_NOT_SUPPORTED:
        'Transmetimi i drejtpërdrejtë nuk mbështetet në vendndodhjen tuaj',
      ERR_IP_NOT_ALLOWED:
        'Transmetimi i drejtpërdrejtë nuk lejohet për adresën tuaj IP',
      ERR_STREAM_NOT_ALLOWED: 'Transmetimi i drejtpërdrejtë nuk lejohet.',
      ERR_STREAM_NOT_ALLOWED_FOR_UNVALIDATED_USER:
        'Transmetimi i drejtpërdrejtë nuk lejohet për përdoruesit e paverifikuar',
      ERR_MINIMUM_STREAMING_BALANCE:
        'Transmetimi i drejtpërdrejtë është i disponueshëm nëse keni një minimum prej {{errorArgument}} EUR në llogarinë tuaj',
      'error-starting-game': 'Loja nuk mund të fillojë',
      'error-unknown': 'U arrit në një gabim, Ju lutemi që të tentoni përsëri.',
      'field-invalid': 'Futja nuk është e vlefshme',
      'field-required': 'Kjo kuti është e detyrueshme',
      'fields-obligated': 'Kutitë e shënuara me (*) janë të detyrueshme.',
      'invalid-system-slip': 'Bileta e sistemit nuk është e vlefshme',
      'invalid-user': 'Përdoruesi nuk është i vlefshëm',
      'item-not-available': 'Prodhimi nuk është në dispozicion',
      'item-not-found': 'Prodhimi nuk u gjet',
      'jmbg-max':
        'Numri i identifikimit nuk mund të përmbajë më shumë se 13 karaktere',
      'jmbg-min':
        'Numri i identifikimit duhet të përmbajë të paktën 13 karaktere',
      'min-password': 'Fjalëkalimi duhet të përmbajë të paktën 6 karaktere',
      'no-betting-place-id': 'Zgjidhni filialin',
      'no-events-for-copy': 'Nuk ka ngjarje që mund të kopjohen',
      'no-items-in-order': 'Nuk ka prodhime në porosi',
      'not-enough-points': 'Nuk keni pikë të mjaftueshme',
      'order-does-not-exist': 'Porosia nuk ekziston',
      'passwords-dont-match': 'Fjalëkalimi nuk përputhet',
      'payout-error': 'U arrit në gabim me rastin e tentativës për pagimin',
      'place-bet-error':
        'Gabim gjatë pagimit të tiketës. Ju lutemi të tentoni përsëri.',
      'poker-not-responding':
        'Serveri i pokerit është aktualisht i padisponueshëm',
      'poker-success-false':
        'Veprimi i serverit të pokerit nuk ishte i suksesshëm',
      'print-error':
        'U arrit në gabim me rastin e tentativës për printim. Në rast se bileta nuk është printuar drejtojuni operatorit.',
      'print-not-operational': 'PRINTERI NUK ËSHTË NË FUNKSION',
      'promo-code-invalid': 'Kodi promo nuk është i vlefshëm',
      'reservation-locked': 'Mjetet e rezervuara janë të mbyllura',
      'session-expired':
        'Sesioni ka skaduar, Ju lutemi që të identifikoheni përsëri.',
      'system-error': 'U arrit në një gabim, Ju lutemi që të tentoni përsëri.',
      TEST_USER_CANNOT_DEPOSIT_MONEY_WITH_CARD:
        'Përdoruesi testues nuk mund të depozitojë para me kartë.',
      TEST_USERS_CANNOT_WITHDRAW_MONEY:
        'Përdoruesit e testit nuk mund të tërheqin para.',
      'ticket-changed': 'Tiketa Juaj është ndryshuar.',
      'ticket-denied': 'Tiketa Juaj është refuzuar.',
      'ticket-error-reading': 'Gabim gjatë leximit të tiketës.',
      TICKET_NOT_FOUND: 'Bileta nuk u gjet',
      TICKET_NOT_VALID_FOR_PAYOUT:
        'Bileta nuk është më e vlefshme për pagesë. Ju lutemi kontaktoni operatorin.',
      'token-expired': 'Tokeni ka skaduar.',
      'user-blocked-error':
        'Llogaria Juaj është bllokuar. Kontaktoni me ndihmën për klientët.',
      'user-exists': 'Përdoruesi ekziston tashmë.',
      'user-not-active':
        'Përdoruesi nuk është aktivizuar. Ju lutemi që të aktivizoni llogarinë Tuaj.',
      'user-update-error':
        'Gabim gjatë ruajtjes së të dhënave. Ju lutemi që të tentoni përsëri.',
      'username-exists': 'Përdorues me këtë emër përdoruesi ekziston tashmë.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Ngjarjet antepost nuk është e mundur të kombinohen në një tiketë.',
      'withdrawal-canceled-or-paid':
        'Pagesa është refuzuar ose është paguar. Ju lutemi që të rifreskoni faqen.',
      'withdrawal-max-amount-exceeded':
        'Pagesa maksimale e lejuar është {{a}}€',
      'withdrawal-min-amount-exceeded': 'Pagesa minimale e lejuar është {{a}}€',
      'zero-item-quantity': 'Nuk ka prodhime në gjendje.',
    },
    footer: {
      'about-us': 'Rreth nesh',
      'account-id': 'ID I MANDATIT',
      'all-rights-reserved': 'Të gjitha të drejtat janë të rezervuara',
      'available-for-payment': 'Në dispozicion për tu paguar',
      aviator: 'Aviator',
      betting: 'Baste',
      bonuses: 'Bonuset',
      casino: 'Kazinoja',
      'casino-live': 'Kazinoja direkt',
      contact: 'Kontakti',
      cookies: 'Biskotat (Cookies)',
      description:
        "SBbet organizon lojëra fati që nga viti 1997 dhe ju ofron klientëve të saj shërbime në nivelin më të lartë. Me një rrjetë prej mbi 100 pikash pagese në Mal të Zi, ne jemi të pozicionuar si lider në treg, gjë që na motivon më tej ta drejtojmë biznesin tonë drejt krijimit të argëtimit të klasit botëror në fushën e lojërave të fatit. Faqja SBbet është krijuar për t'ju lejuar të vënie bastesh me përvojën më të mirë të përdoruesit. Gama e madhe e sporteve dhe garave, shanset atraktive, bonuset, bastet falas dhe mundësia për të fituar një shumë të madhe parash përmes Jackpot-eve tona, janë vetëm disa nga arsyet për t'i besuar llotos sportive më të vjetër në Mal të Zi, me mbi 20 vjet përvojë. Provoni fatin tuaj në një nga lojërat e shumta të kazinosë, në bingon tonë, në ngjarjet sportive virtuale ose në një tavolinë të vërtetë kazinoje. SBbet.me është pjesë e Sporting Group doo, Mediteranska 21, Budva, Mali i Zi dhe rregullohet nga ligjet e Malit të Zi dhe është mbajtës i licencës 02/01-702/4, të lëshuar nga Administrata për Lojëra të Fatit. Ekipi i SBbet ju uron fat të mirë - Sigurisht...",
      'download-list': 'Merr listën',
      'follow-us': 'Na ndiqni',
      'frequently-questions': 'Pyetjet të shpeshta',
      'game-rules': 'Rregullat e lojës',
      informations: 'Informacione',
      'lucky-6': 'Lucky6',
      'pay-all': 'PAGUAJI TË GJITHA',
      'payment-to-web': 'Pagesa në WEB',
      'play-responsibly': 'Luaj në mënyrë të përgjegjshme',
      poker: 'Poker',
      privacy: 'Privatësia',
      'promo-code': 'Kodi promo',
      promotions: 'Promocionet',
      'site-map': 'Harta e sajtit',
      sport: 'Sporti',
      'ticket-check': 'Verifikimi i biletës',
      transactions: 'Transaksionet',
      'using-conditions': 'Kushtet e përdorimit',
    },
    globals: {
      accept: 'PRANO',
      'accept-cookies':
        "Kjo faqe përdor biskota (cookies) për të përmirësuar shërbimet, e kjo për t'ju treguar përmbajtjen përkatëse. Nëse vazhdoni të shfletoni faqen, ne konsiderojmë se jeni dakord me përdorimin e biskotave (cookies).",
      account: 'Llogaria',
      'account-type': 'Tipi i llogarisë',
      activation: 'AKTIVIZIMI',
      'add-to-cart': 'Shto në shportë',
      all: 'Të gjitha',
      'amount-caps': 'SHUMA',
      'app-idle-msg':
        'Ju nuk keni qenë aktivë për njëfarë kohe.  Klikoni rifresko për të rifreskuar ofertën.',
      approved: 'U miratua',
      'betting-history': 'Historia e basteve',
      'booking-an-order': 'Rezervimi i porosisë',
      braon: 'Ngjyrë kafe',
      cancel: 'ANULO',
      canceled: 'U anulua',
      cart: 'Shporta',
      'cart-is-empty': 'Shporta është bosh',
      Cet: 'Ejt',
      'choose-a-betting-place': 'Zgjidhni filialin e SBbet',
      'choose-a-city': 'Zgjidhni qytetin',
      'choose-order-place':
        'Zgjidhni vendin e dërgimit në filialin SBbet të zgjedhur',
      'click-for-more-info': 'Klikoni për më shumë informacione',
      close: 'MBYLLE',
      'code-caps': 'KODI',
      collect: 'Merri',
      copy: 'Kopjo',
      'copy-ticket': 'Kopjo tiketën',
      'customer-support': 'Mbështetja e përdoruesit',
      date: 'Data',
      'date-caps': 'DATA',
      delivered: 'Dorëzuar',
      'filter-by-date': 'Filtro sipas datave',
      free: 'Falas',
      'id-caps': 'ID',
      immediately: 'Menjëherë',
      'installation-guide': 'Udhëzime për instalimin',
      loading: 'Lexim...',
      'mark-all-as-read': 'Shëno gjithçka si të lexuar',
      'my-account': 'Llogaria ime',
      'necessary-reload':
        'Është e nevojshme që të kryeni sinkronizimin e ofertës',
      Ned: 'Die',
      nepar: 'Tek',
      no: 'JO',
      'no-info-about-event': 'Nuk ka njoftime për ngjarjen',
      notifications: 'Njoftime',
      numbers: 'NUMRAT',
      'order-history': 'Historia e porosive',
      par: 'Çift',
      pending: 'Në pritje',
      Pet: 'Pre',
      'pick-date': 'Zgjidh datën',
      'place-order': 'Porosit prodhimet',
      Pon: 'Hën',
      proceed: 'Procedo',
      profile: 'Profili',
      'promo-code': 'Kodi promo',
      register: 'REGJISTRIMI',
      'remove-from-cart': 'Hiq nga shporta',
      save: 'Ruaj',
      'sbbet-promo-code': 'KODI PROMO SBbet',
      search: 'Kërkim',
      'search-1': 'Kërko',
      'search-events': 'Kërko ngjarje',
      send: 'KALOI',
      'send-1': 'DËRGO',
      Sre: 'Mër',
      statistics: 'STATISTIKA',
      'status-caps': 'STATUSI',
      step: 'Hap',
      Sub: 'Sht',
      submit: 'KONFIRMO',
      success: 'U mor',
      'successfully-collected-cash-drop': 'Ju morët me sukses cash drop',
      'ticket-copied': 'Tiketa u kopjua',
      time: 'Koha',
      'transaction-history': 'Historia e transaksioneve',
      type: 'Tipi',
      'type-caps': 'TIPI',
      'user-id-caps': 'ID E PËRDORUESIT',
      Uto: 'Mar',
      wallets: 'Llogaritë',
      welcome: 'Mirëseerdhët!',
      yes: 'PO',
      zelena: 'E gjelbër',
      žuta: 'E verdhë',
    },
    header: {
      betting: 'Vënie basti',
      casino: 'Kazino',
      'casino-live': 'Live kazino',
      live: 'Live',
      'quick-games': 'Lojëra të shpejta',
    },
    home: {
      '100-spins': '100 RROTULLIME',
      'be-in-the-game-with-app': 'BËHUNI PJESË E LOJËS ME APLIKACIONIN SBBET',
      betting: 'BASTE',
      'betting-description':
        'Shanset më të larta dhe oferta më e larmishme e lojërave dhe specialeve ju presin.',
      'call-center': 'CALL CENTER',
      'casino-description':
        'Lojërat më të njohura të kazinosë sjellin jackpot të mëdha',
      'follow-us': 'NA NDIQNI',
      'live-betting': 'BASTE LIVE',
      'live-betting-description':
        'Bastet live tani janë dy herë më të mira, sepse përzgjedhja më e madhe e sporteve live dhe një gamë e gjerë lojërash ju presin në SBbet!',
      'place-bet': 'BËNI BASTE',
      'promotions-description':
        'Zbuloni gjithçka rreth promovimeve dhe lajmeve të reja.',
      'quick-games-description':
        'Zgjedhja më e madhe e virtualeve: numra, futboll, gara dhe shumë më tepër!',
      register: 'Regjistrohuni',
      'up-to-250': 'DERI 250',
      'we-double-your-first-deposit': 'JU DYFISHOJMË DEPOZITËN TUAJ TË PARË',
    },
    language: {
      english: 'Anglisht',
      language: 'Gjuha',
      montenegrin: 'Malazeze',
      'select-language': 'Zgjidh gjuhën',
    },
    login: {
      'forgot-password': 'A e keni harruar fjalëkalimin?',
      'forgot-password-caps': 'A E KENI HARRUAR FJALËKALIMIN?',
      'log-in': 'IDENTIFIKOHUNI',
      'log-out': 'Dilni',
      login: 'Identifikohuni',
      logout: 'DILNI',
      'logout-1': 'DALJA',
      'logout-question': 'A jeni i sigurtë që doni të dilni?',
      'sign-in': 'HYNI',
    },
    navigation: {
      aviator: 'AVIATOR',
      betting: 'BASTE',
      casino: 'KAZINO',
      liveCasino: 'KAZINO DIREKT',
      lucky6: 'LUCKY6',
      numbers: 'NUMRAT',
      offer: 'OFERTA',
      poker: 'POKER',
      promotions: 'PROMOCIONET',
      quickGames: 'LOJRAT E SHPEJTA',
      sport: 'SPORTI',
    },
    payments: {
      'account-id-confirm': 'Konfirmoni që ID I MANDATIT është i juaji:',
      amount: 'Shuma',
      'available-for-payment': 'Në dispozicion për tu paguar',
      balance: 'Bilanci',
      'balance-after': 'Shuma pas',
      'balance-before': 'Shuma para',
      'bonus-balance': 'Gjendja e bonusit',
      'cancel-withdrawal': 'Anulo pagesën',
      'cancel-withdrawal-question':
        'A jeni i sigurtë që dëshironi ta anuloni pagesën?',
      'cancel-withdrawal-success': 'E anuluat me sukses pagesën.',
      'card-expired': 'Transaksioni është refuzuar. Karta ka skaduar.',
      'card-not-found': 'Karta nuk u gjet. Ju lutemi që të tentoni përsëri.',
      'card-suspended': 'Transaksioni u refuzua. Karta është pezulluar.',
      commission: 'Përqindja',
      credit: 'Pagimi',
      'credit-card': 'Karta e kreditit',
      debit: 'Pagesa',
      'e-wallet': 'E-kuleta',
      'funds-available-for-payment': 'Mjete në dispozicion për tu paguar',
      'funds-that-can-be-raised': 'MJETE QË MUND TI TËRHIQNI',
      'in-the-bank': 'LLOGARI BANKARE',
      'in-the-bank-note':
        'Nëse emri dhe mbiemri Juaj nuk janë të regullt, ju lutemi që të kontaktoni me ekipin e mbështetjes',
      'in-the-office': 'NË FILIAL',
      'invalid-amount': 'Transaksioni u  refuzua. Shuma nuk është e vlefshme.',
      'invalid-card-number':
        'Transaksioni u refuzua. Numri i kartës nuk është i vlefshëm.',
      'maximum-credit': 'Pagimi maksimal',
      'maximum-debit': 'Pagesa maksimale',
      'minimum-credit': 'Pagimi minimal',
      'minimum-debit': 'Pagesa minimale',
      pay: 'Paguaj',
      'payment-default-error':
        'Transaksioni u refuzua. Ju lutemi ql të tentoni përsëri.',
      'payment-destination': 'Destinacioni i pagesës',
      'payment-success': 'Pagesa u krye me sukses.',
      'physical-payment': 'Pagesë fizike',
      'poker-withdrawal-success': 'Pagesa u krye me sukses.',
      'potential-payout': 'Pagim i mundshëm:',
      'proceed-with-payment': 'Vazhdoni me pagesën duke klikuar në buton',
      skrill: 'Skrill',
      tabaccopress: {
        'deposit-step-1':
          'Kërkoni në ndonjërën nga pikat e shitjes Tobacco S Press pagimin e depozitës në llogarinë tuaj SBbet me anë të terminaleve të pagesës.',
        'deposit-step-2':
          'Është e nevojshme që shitësit ti deklaroni numrin ID të llogarisë Tuaj SBbet si dhe shumën që doni të paguani.',
        'deposit-step-3':
          'Shuma që paguat do të jetë në dispozicion në llogarinë Tuaj SBbet zakonisht brenda disa minutave nga momenti i pagesës.',
        'deposit-steps':
          'Si të vini para në depozitë me anë të terminaleve të pagesës?',
        'instruction-1':
          'Për pagesën me anë të terminaleve të pagesës është i nevojshëm vetëm numri i përdoruesit i llogarisë Tuaj Sbbet (numri ID).',
        'instruction-2':
          'Mundësinë e pagesës me anë të terminaleve të pagesës mund ta realizoni në një nga 300+ pikave të shitjes Tabacco S Press',
        'instruction-3':
          'Shuma minimale e pagesës me anë të terminaleve të pagesës është 1.00 EUR, kurse shuma maksimale për një transaksion është 500.00 EUR.',
        'instruction-4':
          'Në rast gabimi për stornimin e transaksionit në vetë pikën e shitjes është 10 minuta ( në rast se paret nga llogaria nuk janë harxhuar).',
        'instruction-5':
          'Objektet e TOBACCO S PRESS në të cilat mund të paguani depozitë mund ti shihni në linkun që vijon',
      },
      terminals: {
        note: 'SHËNIM: NË rast se për ndonjë arsye, mjetet e paguara nuk janë kaluar në llogarinë Tuaj Sbbet, Ju lutemi që të kontaktoni shërbimin J&A në numrin 067/788-588 në periudhën nga ora 9 deri në orën 17, dhe mbas orës 17 deri në orën 21, si edhe gjatë uikendit, në numrin 068/488-588.',
        'step-1': 'Nëterminalin J&A, zgjidhni logon e Sbbet',
        'step-2':
          'Fusni ID e llogarisë Tuaj të përdoruesit dhe  klikoni në butonin "PAGUAJ"',
        'step-3':
          'Futni në terminal shumën e pareve që dëshironi, dhe aparati do të ngarkojë dhe paraqesë se sa pare keni futur.',
        'step-4':
          'Me klikimin në butonin "KONFIRMO" do të kryeni me sukses pagesën dhe do të merrni vërtetimin e printuar për transaksionin tuaj.',
      },
      'transaction-date': 'Data e transaksionit',
      'transaction-deposit': 'Pagesa',
      'transaction-id': 'ID e transaksionit',
      'transaction-type': 'Tipi i transaksionit',
      'transaction-web-bet': 'Web bet',
      'transaction-web-bet-rollback': 'Web bet rollback]',
      'transaction-web-casino-credit': 'Casino credit',
      'transaction-web-casino-debit': 'Casino debit',
      'transaction-web-casino-rollback': 'Casino rollback',
      'transaction-web-game-bet': 'Web game bet',
      'transaction-web-game-rollback': 'Web game rollback',
      'transaction-web-game-win': 'Web game win',
      'transaction-web-win': 'Web win',
      'transaction-withdraw': 'Pagimi',
      transactions: 'Transaksionet',
      withdraw: 'PAGUAJ',
      'withdraw-on-the-bank': 'PAGIM ME BANKË',
      'withdraw-on-the-betting-place': 'PAGIM NË FILIAL',
      'withdrawal-pending': 'Me miratim',
      'withdrawal-ready': 'Të gatshme për tu tërhequr',
      'withdrawal-reserved': 'Pagim i rezervuar',
      'withdrawal-success': 'Ju krijuat me sukses kërkesën për pagim',
      'your-transaction-has-been-confirmed': 'Transaksioni juaj u konfirmua',
    },
    promoBanner: {
      aviator: 'Aviator',
      betting: 'Vënie basti',
      blackjack: 'Bllekxhek',
      cashout: 'Cashout',
      'casino-tournament': 'Turne kazinoje',
      'defeat-goalkeeper-for-bonuses': 'Munde portierin për BONUSE',
      'double-first-payin': 'Dyfishojmë çdo pagesë të parë',
      'download-app': 'Shkarko aplikacionin',
      'fly-to-win': 'Fluturo për të fituar',
      'happy-monday-bonus': 'Happy Monday Bonus',
      'lucky-6': 'Lucky 6',
      'netent-casino-games': 'NetEnt Lojëra kazinoje',
      new: 'E re',
      'pay-the-ticket-at-any-time': 'Paguaj tiket në çdo moment',
      popular: 'Popullore',
      promotion: 'Promocion ',
      roulette: 'Rulet',
      'win-5000-jackpot': 'Fito Xhekpotin prej 5.000 EUR',
      'win-apartment': 'Fito një apartament në Podgoricë',
      'win-iphone-13': 'Fito një Iphone 13',
      'win-jackpot-up-to-5000': 'Fito xhekpot deri në 5.000 EUR',
    },
    slips: {
      'accept-all-changes': 'I pranoj të gjitha ndryshimet',
      'accept-cashout': 'PRANO CASHOUT',
      'accept-changes': 'Prano ndryshimet ',
      'accept-changes-after-place-bet':
        'Prano ndryshimet pasi ke kryer pagesën',
      active: 'Aktiv ',
      'actual-winning-amount': 'Pagimi',
      'all-tickets': 'TË GJITHA TIKETAT',
      approving: 'Pritje për aprovim',
      bet: 'Basti',
      'bet-type': 'Tipi i bastit',
      bonus: 'Bonus:',
      'cancel-cashout': 'Anulo cashout',
      cashout: 'CASHOUT',
      'cashout-accepted': 'Cashout u pranua',
      'cashout-denied': 'Cashout u refuzua me sukses',
      'cashout-denied-timer':
        'Cashout u refuzua për shkak të skadimit të kohës për të pranuar ndryshimet',
      changed: 'U ndryshua',
      'confirm-ticket': 'KONFIRMONI TIKETËN',
      'delete-all': 'Fshiji të gjitha',
      'delete-ticket': 'Fshini tiketën',
      'delete-ticket-info': 'A dëshironi që ta fshini këtë biletë',
      denied: 'Refuzohet',
      'deny-cashout': 'REFUZO CASHOUT',
      'erase-multiple-odds':
        'Me këtë veprim do të fshihen integralet, kështu që mbetet vetëm shenja e parë në çdo ndeshje',
      failed: 'Humbje',
      fix: 'FIKS',
      'group-number': 'Grupi nr.',
      id: 'Kodi',
      'max-pot-payout': 'Pagesa Max. potenciale',
      'max-pot-win': 'Fitimi Max. potencial',
      'max-potential-bonus': 'Bonusi Max. potencial',
      'min-max-bonus': 'Bonusi Min/Max',
      'min-max-odd': 'Kuota Min/Max',
      'min-max-pot-win': 'Fitimi potencial Min/Max',
      'min-pot-win': 'Fitimi Min. potencial',
      'min-potential-bonus': 'Bonusi Min. potencial',
      'min-winning-total': 'Fitimi Min. potencial',
      multiplier: 'Kuota',
      'my-tickets': 'TIKETAT E MIJA',
      'no-slip-events': 'Nuk ka ngjaje në slip',
      'no-tickets': 'Nuk ka tiketa.',
      'number-of-combinations': 'Nr. i komb.',
      'number-of-matches': 'Numri i ngjarjeve:',
      'number-of-pairs': 'Numri i çifteve:',
      'number-of-tickets': 'Numri i tiketës:',
      odd: 'Kuota:',
      paid: 'U pagua',
      passed: 'Fitove',
      payin: 'Pagesa:',
      'payin-time': 'Koha:',
      'payment-time': 'Koha e pagesës:',
      payout: 'Pagimi:',
      'place-bet': 'Paguaj tiketën',
      'possible-winning': 'Fitimi i mundur',
      'pot-winning-amount': 'Pagimi potencial',
      'pot-winning-amount-short': 'Pagimi pot.',
      'potential-bonus': 'Bonusi potencial',
      'potential-gain': 'Fitimi potencial',
      'quick-ticket-code': 'KODI PËR TIKETËN E SHPEJTË',
      'quick-ticket-live':
        'Nuk është e mundur që të luani me tiketin e shpejtë në ndeshjet direkt',
      'quick-ticket-pay':
        'Tiketë të shpejtë mund të paguani në çdo vend pagesash Sbbet. Kodi është:',
      'quick-ticket-terms':
        'Verifikimi i kushteve dhe kufizimeve të mundëshme kryhet në pikat tona të pagesës, e kjo me rastin e pagimit të tiketës',
      'quick-tickets': 'TIKETAT E SHPEJTA',
      regular: 'TË ZAKONSHME',
      'regular-ticket': 'TIKET I ZAKONSHËM',
      'remove-match': 'Hiqe ndeshjen',
      'remove-odd': 'Hiqe kuotën',
      round: 'Raundi',
      'save-games': 'Ruaj ndeshjet',
      'share-ticket-success': 'Linku i biletës u kopjua me sukses',
      'sign-in-status': 'Identifikohuni që të shikoni statusin e tiketës',
      'slip-not-eligible-for-cashout':
        'Tiketa nuk është e pranueshme për cashout',
      'slip-payout': 'Pagimi i biletës',
      stake: 'Investimi',
      'stake-per-combination': 'Pagesë për komb.:',
      status: 'Statusi',
      'status-capitalize': 'Statusi:',
      'switch-ticket': 'A dëshironi që ta kaloni tiketin në të zakonshëm?',
      system: 'SISTEMATIK',
      'system-bonus-calculation':
        'Përllogaritja e bonusit në tiketin sistematik do të kryhet mbas realizimit të të gjitha ngjarjeve.',
      ticket: 'TIKETA',
      'ticket-date': 'Data e tiketës',
      'ticket-details': 'DETAJET E TIKETËS',
      'ticket-id': 'Kodi i tiketës',
      'ticket-id-search': 'ID i tiketës:',
      'ticket-overview': 'Pamja e tiketës',
      'ticket-status': 'Statusi i tiketës',
      'ticket-type': 'Tipi i tiketës',
      tickets: 'TIKETAT',
      'top-tickets': 'TOP TIKETAT',
      'total-odd': 'Kuota gjithsej:',
      unconfirmed: 'E pa konfirmuar',
      win: 'Fitimi:',
      'winning-amount-caps': 'SHUMA E FITIMIT',
    },
    sports: {
      'americki fudbal': 'Futbolli Amerikan',
      atletika: 'Atletika',
      badminton: 'Badminton',
      bejzbol: 'Bejsboll',
      biciklizam: 'Çiklizëm',
      boks: 'Boks',
      efudbal: 'eFutboll',
      esport: 'eSport',
      evrovizija: 'Eurovizion',
      fudbal: 'Futboll ',
      futsal: 'Futsall',
      golf: 'Golf',
      hokej: 'Hokej',
      košarka: 'Basketboll',
      mma: 'MMA',
      odbojka: 'Volejboll',
      olimpijada: 'Olimpiadë',
      oskar: 'Oskar',
      pikado: 'Pikado',
      ragbi: 'Ragbi',
      'ragbi liga': 'Liga e ragbisë',
      rukomet: 'Hendboll',
      snuker: 'Snuker',
      'stoni tenis': 'Pingpong',
      tenis: 'Tenis',
      trke: 'Gara',
      'trke konja': 'Gara kuajsh',
      vaterpolo: 'Vaterpolo',
      'virtuelni fudbal': 'Futboll virtual',
      'zimski sportovi': 'Sportet e dimrit',
    },
    sportsPage: {
      '1d': '1d',
      '1h': '1h',
      '3d': '3d',
      '3h': '3h',
      all: 'TË GJITHA',
      antepost: 'ANTEPOST',
      competition: 'Liga',
      'configure-offer': 'Konfiguro ofertën',
      event: 'Ngjarja',
      limit: 'Kufiri',
      live: 'DIREKT',
      'live-match-tracker': 'Ndjekje direkt e ndeshjeve',
      'no-events': 'Nuk ka ngjarje',
      'no-odds': 'Momentalisht nuk ka oferta',
      odds: 'KUOTAT',
      player: 'LOJTARI',
      'sort-by-competition': 'Sipas ligave',
      'sort-by-time': 'Sipas kohës',
      special: 'SPECIALET',
      upcoming: 'PASARDHËSET',
    },
    userData: {
      address: 'Adresa',
      adress: 'Adresa',
      'bank-account-number': 'Numri i llogarisë bankare',
      'bank-name': 'Emri i bankës',
      'betting-win-loss': 'Betting win/loss (Baste fito/humb)',
      'casino-and-virtuals-win-loss':
        'Kazino dhe virtuale win/loss (fito/humb)',
      'change-password': 'Ndryshimi i fjalëkalimit',
      'changes-active-in-24-hrs':
        'Ndryshimet tuaja do të jenë aktive për 24 orë',
      city: 'Qyteti',
      code: 'Kodi',
      'confirm-password': 'Konfirmo fjalëkalimin',
      country: 'Shteti',
      'current-password': 'Fjalëkalimi momental',
      'current-spending': 'Konsumi actual',
      'daily-limits': 'Limitet ditore',
      'date-of-birth': 'Data e lindjes',
      deposit: 'Depozita',
      'document-id': 'ID e pasaportës',
      documents: 'Dokumentet',
      'driver-license': 'Patenta e shoferit',
      'email-address': 'Adresa e email-it',
      'email-adress': 'Adresa e email-it',
      female: 'Femër',
      'first-name': 'Emri ',
      gender: 'Gjinia',
      'i-have-jmbg':
        'Unë kam numër personal (vetëm për qytetarët e Malit të Zi)',
      jmbg: 'Numri personal i identifikimit',
      'last-name': 'Mbiemri',
      male: 'Mashkull',
      'monthly-limits': 'Limitet mujore',
      'new-limit-value-is': 'Vlera e re e limitit është',
      'new-limits-take-effect-in': 'Kufizimet e reja hyjnë në fuqi për',
      'new-password': 'Fjalëkalimi i ri',
      passport: 'Pasaporta',
      password: 'Fjalëkalimi',
      'pause-gambling-confirmation':
        'Kjo do të çaktivizojë bastet sportive, kazinonë dhe virtualet deri në datën e zgjedhur. Jeni të sigurt që dëshironi të vazhdoni?',
      'pause-gambling-until': 'Ndërprerja e basteve deri më',
      'personal-data': 'Të dhënat personale',
      'personal-id': 'Letërnjoftimi',
      phone: 'Telefoni',
      place: 'Qyteti',
      'responsible-gambling': 'Baste me përgjegjësi',
      save: 'Ruaje',
      'street-and-number': 'Rruga dhe numri',
      telephone: 'Telefoni',
      'user-details': 'Të dhënat personale',
      username: 'Emri i përdoruesit',
      'weekly-limits': 'Limitet javore',
    },
    userMarket: {
      cancel: 'Anulo',
      code: 'Kodi',
      doctor: 'Doktor',
      expert: 'Ekspert',
      items: 'Artikujt',
      order: 'Porosia',
      'order-success': 'Porosia e suksesshme',
      place: 'Qyteti',
      professor: 'Profesor',
      'sbbet-points': 'Pikët SBbet',
      'successfuly-canceled-order': 'E anuluat me sukses porosinë',
    },
    validations: {
      'amount-greater-than-available':
        'Shuma e futur është me e madhe se ajo në dispozicion',
      'cannot-mix-group-events':
        'Nuk është e mundur që të luani në të njëjtën tiketë ngjarje në grup dhe ngjarje nga grupi',
      'event-disabled': 'Vënie basti për ngjarjen: {{a}} nuk është e mundur.',
      'event-starts-in-less-than':
        'Ngjarja {{a}} fillon për më pak se {{b}} sekonda',
      'free-bet-error':
        'Numri minimal i ngjarjeve në tiketën free bet është {{minEvents}}. Kuota minimale gjithsej është {{minTotalOdd}}.',
      'free-bet-unvalidated':
        'Ju lutemi të verifikoni llogarinë Tuaj në mënyrë që të shfrytëzoni mjetet promo',
      'live-odds-change': 'Ju lutemi, pranoni të gjitha ndryshimet.',
      'max-bet-amount': 'Shuma Max bet është e kufizuar deri në {{a}}',
      'max-credit': 'Shuma maksimale e pagimit është {{max}} €',
      'max-debit': 'Shuma maksimale e pagesës është {{max}}',
      'max-number-of-combinations':
        'Numri maksimal i kombinacioneve është {{a}}',
      'max-number-of-events': 'Numri maksimal i ngjarjeve është {{a}}',
      'max-number-of-same-events': 'Numri maksimal i integraleve është {{a}}',
      'max-same-slips': 'Ju keni lozur {{a}} me të njëjtat tiketa.',
      'min-amount-per-combination':
        'Pagesa minimale për kombinacion është {{a}}',
      'min-credit': 'Shuma e pagimit minimal është {{min}} €',
      'min-debit': 'Shuma minimale e pagesës është {{min}}',
      'min-payment-for-number-of-matches':
        'Pagesa minimale për numrin e ngjarjeve më të vogël se {{b}} është {{a}}.',
      'no-space': 'Nuk lejohet futja e hapësirës',
      'odd-disabled': 'Nuk lejohet vënie e bastit për kuotën {{a}}.',
      'odd-higher-than-one': 'Çdo kuotë duhet të jetë më e madhe se 1.',
      'only-letters-and-spaces-allowed': 'Lejohen vetëm shkronja dhe hapësira',
      'only-numbers-allowed': 'Lejohen vetëm numra',
      'repeat-password': 'Përsëritni fjalëkalimin',
      'validation-antepost-system':
        'Nuk është e mundur të luani ngjarje antepost në tiketën sistematike.',
      'validation-balance': 'Nuk keni mjete të mjaftueshme në llogari.',
      'validation-max-payment':
        'Pagesa e kryer është më e madhe se shuma e përcaktuar.',
      'validation-max-systems': 'Numri maksimal i sistemeve është {{a}}.',
      'validation-max-win': 'Numri i fitimeve maksimale potenciale është {{a}}',
      'validation-min-payment': 'Pagesa minimale është {{minAmount}} EUR.',
      'validation-mix-long-short-term':
        'Nuk është e mundur të luani ngjarjen antepost {{a}} dhe ngjarjen {{b}} në të njëjtën tiketë.',
      'validation-mixed-event-types':
        'Ngjarjet antepost nuk mund të luhen në kombinim me tipe të tjera ngjarjesh.',
      'validation-mixed-markets':
        'Nuk lejohet të luani në market {{a}} dhe market {{b}} në të njëjtën tiketë.',
      'validation-not-validated':
        'Nuk lejohet që ti kryhet pagim një përdoruesi i paidentifikuar.',
      'validation-only-singles':
        'Ngjarja {{a}} nuk lejohet që të kombinohet me ngjarje të tjera.',
      'validation-single-choice':
        'Ngjarja {{a}} nuk mund të përmbajë më shumë se një tip kuote.',
    },
    verification: {
      'accept-terms': 'Pranoj kushtet e përdorimit',
      'account-activated': 'Llogaria u aktivizua me sukses',
      'account-success': 'Urime! Ju e aktivizuat me sukses llogarinë Tuaj!',
      'agree-email-notif': 'Dëshiroj të marr njoftime me anë të e-mail-it',
      'agree-sms-notif': 'Dëshiroj të marr njoftime me anë të SMS-ve',
      confirm: 'KONFIRMO',
      'connection-online': 'Lidhja me Internetin vendos!',
      'data-saved': 'Të dhënat janë ruajtur me sukses',
      'deny-changes': 'Ju anuluar me sukses ndryshimet në tiketë.',
      'deny-changes-timer':
        'Ndryshimet u refuzuan sepse skadoi koha për pranimin e ndryshimeve.',
      'email-verified-success':
        'E verifikuat me sukses email-in e llogarisë Tuaj',
      'enter-recovery-email':
        'Fut adresën e email-it me të cilën do të bëni llogari SBbet',
      instruction:
        'Dërgo një SMS në numrin 1818 në formatin: SbbnumriIDShumaDepozita',
      'login-activation': 'Identifikohu në mënyrë që të përdorni aplikacionin',
      'promo-code-activated':
        'Ju e aktivizuar me sukses kodin promo. Pranuat {{amount}} {{currency}} në llogarinë Tuaj promo.',
      'promo-code-activated-multiple-wallets':
        'Keni aktivizuar me sukses kodin promovues. Keni marrë {{shuma}} {{valuta}} në llogarinë tuaj Free bet dhe {{shuma_fc}} {{valuta}} në llogarinë tuaj Free spin.',
      'promo-code-already-activated': 'Kodi promo është aktifizuar tashmë',
      'promo-code-not-for-existing-users':
        'Kodi promo nuk është i destinuar për përdoruesit e vjetër',
      'promo-code-not-for-new-users':
        'Kodi promocional nuk është parashikuar për përdoruesit e rinj',
      'promo-code-used-up': 'Kodi promo është shfrytëzuar tashmë',
      'recovery-email-sent':
        'Ju kemi dërguar një email në adresën tuaj të email-it',
      'successfully-changed-password': 'Ju e ndryshuat me sukses fjalëkalimin',
      'ticket-approved': 'Tiketa juaj u aprovua. Kliko që të shohësh tiketën.',
      'ticket-paid': 'Tiketa u pagua me sukses',
      'ticket-waiting-approval': 'Tiketa juaj është në pritje të aprovimit.',
      'upload-document': {
        'choose-document': 'Zgjidhni një dokument',
        'document-type': 'Tipi i verifikimit të dokumentit:',
        'first-page': 'Faqja e parë e dokumentit:',
        'second-page': 'Faqja e pasme e dokumentit:',
        submit: 'Dërgoje',
        title: 'Ngarkimi i dokumentit',
        'upload-first-page': 'Ngarko faqen e parë të dokumentit',
        'upload-second-page': 'Ngarko faqen e pasme të dokumentit',
        'user-update-success': 'Të dhënat u ruajtën me sukses',
      },
      'validation-18-year': 'Ju nuk i keni mbushur akoma 18 vjeç',
      'verification-complete': 'Llogaria u verifikua me sukses',
      'verification-in-progress': 'Verifikimi është duke u kryer',
      'verification-modal': {
        activate: 'Verifikimi i llogarisë',
        desc: 'Ju mund ta kryeni verifikimin e llogarisë në çdo moment në faqen e Llogarisë Tuaj tek seksioni Llogaria ime>LLOGARIA>Dokumentet',
        'doc-uploaded': 'Verifikimi është duke u kryer ',
        Name: 'Emri',
        proceed: 'Vazhdo pa verifikim',
        validate: 'Verifikimi i përdoruesit',
      },
    },
    wallet: {
      'activate-bonus-info': 'Aktivizoni një bonus promo prej {{bonus}} EUR',
      'casino-bonus-info':
        'Ju duhet të shpenzoni edhe një shumë tjetër prej {{amountToUnlock}} EUR për të çkyçur bonusin e kazinosë',
      'casino-to-unlock': 'Bonusi i kazinosë për tu çkyçur',
      'free-bet': 'Free bet',
      'free-bet-bonus': 'Free bet bonus',
      'free-bet-bonus-info':
        'Ju duhet të shpenzoni edhe një shumë tjetër prej {{amountToUnlock}} EUR për të çkyçur free bet',
      'free-bet-bonus-to-unlock': 'Free bet bonus në zhbllokim',
      'free-bet-bonus-unlock-message':
        'Duhet të shpenzoni edhe {{shumaEZhbllokimit}} EUR për të zhbllokuar bonusin Free bet',
      'free-spin': 'Free spin',
      'free-spin-to-unlock': 'Free  spin për çkyçje',
      'free-spin-unlock-message':
        'Është e nevojshme që të shpenzoni akoma edhe {{amountToUnlock}} EUR për të çkyçur free spin',
      'games-ticket-payout-info': 'A dëshironi që të paguani biletën e radhës',
      'payout-success': 'Pagesa u krye me sukses',
      'poker-balance': 'Bilanci i pokerit',
      'promo-bill': 'Promo kuleta',
      'promo-bonus': 'Promo Bonus',
      'promo-to-unlock': 'Promo në çkyçje',
      'reserved-funds': 'Mjetet e rezervuara janë të mbyllura',
      'standard-account': 'Kuleta standarde',
      'standard-bill': 'Saldo',
      'submit-for-print-success': 'U arrit që të dërgohet për printim',
      'ticket-successfully-paid': 'Bileta u pagua me sukses',
      'valid-to': 'E vlefshme deri në {{validTo}}',
      'voucher-payout-info': 'A doni të paguani KUPONIN tjetër',
      'voucher-print': 'PRINTO KUPONIN',
      'voucher-print-confirm': 'A dëshironi të vazhdoni?',
      'voucher-print-info':
        'Të gjitha mjetet që gjenden në Bilanc do të zbriten dhe do të printohet KUPON me të njëjtën vlerë.',
      wallet: 'Kuleta',
      'wallet-locked': 'Kuleta është e kyçur',
      'wallets-refresh': 'Rifresko të dhënat e kuletës',
    },
  },
};

export default al;
