/* React modules */

/* Our modules */
import { Notification } from 'components';
import './UserNotifications.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

const UserNotifications = () => {
  const notifications = [
    {
      id: 1,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling',
      created: '2021-01-29T15:07:39.919757',
      read: false,
    },
    {
      id: 2,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2021-01-28T15:07:39.919757',
      read: false,
    },
    {
      id: 33,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2021-01-27T15:07:39.919757',
      read: true,
    },
    {
      id: 3,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2021-01-26T15:07:39.919757',
      read: true,
    },
    {
      id: 44,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2021-01-25T15:07:39.919757',
      read: true,
    },
    {
      id: 4,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2021-01-24T15:07:39.919757',
      read: true,
    },
    {
      id: 5,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2020-12-24T15:07:39.919757',
      read: true,
    },
    {
      id: 6,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2020-11-24T15:07:39.919757',
      read: true,
    },
    {
      id: 7,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2020-10-24T15:07:39.919757',
      read: true,
    },
    {
      id: 8,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2020-09-24T15:07:39.919757',
      read: false,
    },
    {
      id: 9,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2020-08-24T15:07:39.919757',
      read: false,
    },
    {
      id: 10,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2020-07-24T15:07:39.919757',
      read: true,
    },
    {
      id: 11,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2019-07-24T15:07:39.919757',
      read: true,
    },
    {
      id: 12,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2018-07-24T15:07:39.919757',
      read: true,
    },
    {
      id: 13,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2017-07-24T15:07:39.919757',
      read: true,
    },
    {
      id: 14,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2016-07-24T15:07:39.919757',
      read: true,
    },
    {
      id: 15,
      title: 'PROMO: PRAZNICNA SPINOMANIJA',
      text: 'Nova godina donosi i novo takmicenje sa novim fondom. SBBet gambling...',
      created: '2015-07-24T15:07:39.919757',
      read: true,
    },
  ];

  const { t } = useTranslation();

  return (
    <div className="user-notifications">
      <div className="user-notifications__header">
        <span className="text-regular">{t('globals.notifications')}</span>
        <span className="text-small text-underline pointer">
          {t('globals.mark-all-as-read')}
        </span>
      </div>

      <div className="user-notifications__content">
        <ul>
          {notifications.map((notification: any) => (
            <Notification
              key={`UserNotifications-Notification-${notification.id}`}
              {...notification}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserNotifications;
