/* React modules  */

/* Our modules */
import { http } from 'app/axios-config';
import {
  ResponsibleGamblingResponse,
  ResponsibleGamblingRequest,
  ResponsibleGamblingPauseResponse,
} from 'modules/user/user.models';
import { BonusType } from 'modules/wallet/wallet.types';

/* 3rd Party modules */

class UserApi {
  getUser() {
    return http.get('/user/platform');
  }

  getTransactions(params: any) {
    return http.get('/user/platform/transaction-history', { params });
  }

  updateUser(data: any) {
    return http.put('user/platform', data);
  }

  upoloadDocuments(payload: any) {
    return http.post('/user/platform/documents', payload);
  }

  selectBonus(type: BonusType) {
    return http.post(`/user/activate-bonus/${type}`);
  }

  getPokerBalance() {
    return http.get<{ available: number }>('/games/poker/get-player-balance');
  }

  setPokerDeposit(amount: number) {
    return http.post<{ available: number }>('/games/poker/credit', { amount });
  }

  setPokerWithdrawal(amount: number) {
    return http.post<{ available: number }>('/games/poker/withdrawal', {
      amount,
    });
  }

  getResponsibleGamblingLimits() {
    return http.get<ResponsibleGamblingResponse>('/user/get-gambling-limits');
  }

  setResponsibleGamblingLimits(data: ResponsibleGamblingRequest) {
    return http.post<{
      delay: boolean;
    }>('/user/set-gambling-limits', data);
  }

  setResponsibleGamblingPause(dateAndTime: string) {
    return http.post<ResponsibleGamblingPauseResponse>(
      `/user/platform/responsible-gambling-timeout`,
      {
        block_until: dateAndTime,
      }
    );
  }

  getCountriesAlpha3Codes(countryAlpha2Code: string) {
    return http.get<{ name: string; code: string }[]>('user/get-countries', {
      params: { country: countryAlpha2Code },
    });
  }
}

export default UserApi;
