// source: proto/odds/odds.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
goog.exportSymbol('proto.odds.AddCompetitionRequest', null, global);
goog.exportSymbol('proto.odds.AddCompetitionResponse', null, global);
goog.exportSymbol('proto.odds.AddCompetitorRequest', null, global);
goog.exportSymbol('proto.odds.AddCompetitorResponse', null, global);
goog.exportSymbol('proto.odds.AddNoteRequest', null, global);
goog.exportSymbol('proto.odds.AddPlayerRequest', null, global);
goog.exportSymbol('proto.odds.AddPlayerResponse', null, global);
goog.exportSymbol('proto.odds.AllEvents', null, global);
goog.exportSymbol('proto.odds.AssignLandBaseCodeRequest', null, global);
goog.exportSymbol('proto.odds.AssignLandBaseCodeResponse', null, global);
goog.exportSymbol('proto.odds.AutoAddCompetitionEventsRequest', null, global);
goog.exportSymbol('proto.odds.BasicOffer', null, global);
goog.exportSymbol('proto.odds.BasicOfferMarket', null, global);
goog.exportSymbol('proto.odds.BlockEventReasons', null, global);
goog.exportSymbol('proto.odds.BlockEventRequest', null, global);
goog.exportSymbol('proto.odds.CancelAllUnplayedRequest', null, global);
goog.exportSymbol('proto.odds.CancelEventOddsRequest', null, global);
goog.exportSymbol('proto.odds.CancelledEventsRequest', null, global);
goog.exportSymbol('proto.odds.CancelledEventsResponse', null, global);
goog.exportSymbol('proto.odds.ChangeMarketTemplateRequest', null, global);
goog.exportSymbol('proto.odds.Competition', null, global);
goog.exportSymbol('proto.odds.CompetitionForLinking', null, global);
goog.exportSymbol('proto.odds.CompetitionFwwRequest', null, global);
goog.exportSymbol('proto.odds.CompetitionLimit', null, global);
goog.exportSymbol('proto.odds.CompetitionsForLinkingRequest', null, global);
goog.exportSymbol('proto.odds.CompetitionsForLinkingResponse', null, global);
goog.exportSymbol('proto.odds.Competitor', null, global);
goog.exportSymbol('proto.odds.ConfigureEventStartTimeRequest', null, global);
goog.exportSymbol('proto.odds.CreateEventRequest', null, global);
goog.exportSymbol('proto.odds.CreateLandBaseOfferRequest', null, global);
goog.exportSymbol('proto.odds.CreateLandBaseOfferResponse', null, global);
goog.exportSymbol('proto.odds.CreateOfferEvent', null, global);
goog.exportSymbol('proto.odds.CreateOfferRequest', null, global);
goog.exportSymbol('proto.odds.DailyEvent', null, global);
goog.exportSymbol('proto.odds.DailyMarkets', null, global);
goog.exportSymbol('proto.odds.DailyOutcomes', null, global);
goog.exportSymbol('proto.odds.DailySport', null, global);
goog.exportSymbol('proto.odds.DisableEventRequest', null, global);
goog.exportSymbol('proto.odds.DisableLive', null, global);
goog.exportSymbol('proto.odds.DuplicatesRequest', null, global);
goog.exportSymbol('proto.odds.DuplicatesResponse', null, global);
goog.exportSymbol('proto.odds.EditCompetitionRequest', null, global);
goog.exportSymbol('proto.odds.EditCompetitorRequest', null, global);
goog.exportSymbol('proto.odds.EditEventRequest', null, global);
goog.exportSymbol('proto.odds.EditLandBaseOfferRequest', null, global);
goog.exportSymbol('proto.odds.EditLandBaseOfferResponse', null, global);
goog.exportSymbol('proto.odds.EditManualResultRequest', null, global);
goog.exportSymbol('proto.odds.EditPlayer', null, global);
goog.exportSymbol('proto.odds.Event', null, global);
goog.exportSymbol('proto.odds.EventFwwRequest', null, global);
goog.exportSymbol('proto.odds.EventList', null, global);
goog.exportSymbol('proto.odds.EventOdd', null, global);
goog.exportSymbol('proto.odds.EventOutcome', null, global);
goog.exportSymbol('proto.odds.FeaturedEvent', null, global);
goog.exportSymbol('proto.odds.FeaturedEvents', null, global);
goog.exportSymbol('proto.odds.Filter', null, global);
goog.exportSymbol('proto.odds.GenerateListPDFRequest', null, global);
goog.exportSymbol('proto.odds.GenerateListPDFResponse', null, global);
goog.exportSymbol('proto.odds.GetActiveLBCodesRequest', null, global);
goog.exportSymbol('proto.odds.GetActiveLBCodesResponse', null, global);
goog.exportSymbol('proto.odds.GetAllLandBaseOffersResponse', null, global);
goog.exportSymbol('proto.odds.GetAllMarketGroupsBoRequest', null, global);
goog.exportSymbol('proto.odds.GetAllMarketGroupsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetAllMarketsBoRequest', null, global);
goog.exportSymbol('proto.odds.GetAllMarketsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetCompetitionBetLimitRequest', null, global);
goog.exportSymbol('proto.odds.GetCompetitionBetLimitResponse', null, global);
goog.exportSymbol('proto.odds.GetCompetitionsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetCompetitorsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetCompetitorsRequest', null, global);
goog.exportSymbol('proto.odds.GetDailyListRequest', null, global);
goog.exportSymbol('proto.odds.GetDailyListResponse', null, global);
goog.exportSymbol('proto.odds.GetEventOddsRequest', null, global);
goog.exportSymbol('proto.odds.GetEventOddsResponse', null, global);
goog.exportSymbol('proto.odds.GetEventTypesResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsByTypeRequest', null, global);
goog.exportSymbol('proto.odds.GetEventsByTypeResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsCreateOfferResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsForLinkingRequest', null, global);
goog.exportSymbol('proto.odds.GetEventsForManualResultsResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsWithPlayersRequest', null, global);
goog.exportSymbol('proto.odds.GetEventsWithPlayersResponse', null, global);
goog.exportSymbol('proto.odds.GetFastOddsRequest', null, global);
goog.exportSymbol('proto.odds.GetForProviderRequest', null, global);
goog.exportSymbol('proto.odds.GetInitialDataForBoResponse', null, global);
goog.exportSymbol('proto.odds.GetInitialDataRequest', null, global);
goog.exportSymbol('proto.odds.GetInitialDataResponse', null, global);
goog.exportSymbol('proto.odds.GetLocationsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetMarketTemplatesRequest', null, global);
goog.exportSymbol('proto.odds.GetMarketsResponse', null, global);
goog.exportSymbol('proto.odds.GetMinLimitRequest', null, global);
goog.exportSymbol('proto.odds.GetMinLimitResponse', null, global);
goog.exportSymbol('proto.odds.GetOddStatusHistoryResponse', null, global);
goog.exportSymbol('proto.odds.GetOddsRequest', null, global);
goog.exportSymbol('proto.odds.GetOddsResponse', null, global);
goog.exportSymbol('proto.odds.GetOutcomesRequest', null, global);
goog.exportSymbol('proto.odds.GetOutcomesResponse', null, global);
goog.exportSymbol('proto.odds.GetPaginatedCompetitionsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetPaginatedCompetitorsBoResponse', null, global);
goog.exportSymbol('proto.odds.GetPaginatedEventsRequest', null, global);
goog.exportSymbol('proto.odds.GetPaginatedRequest', null, global);
goog.exportSymbol('proto.odds.GetPlayersResponse', null, global);
goog.exportSymbol('proto.odds.GetProviderCompetitionsRequest', null, global);
goog.exportSymbol('proto.odds.GetProviderCompetitionsResponse', null, global);
goog.exportSymbol('proto.odds.GetProviderCompetitorsRequest', null, global);
goog.exportSymbol('proto.odds.GetProviderCompetitorsResponse', null, global);
goog.exportSymbol('proto.odds.GetProviderOutcomesResponse', null, global);
goog.exportSymbol('proto.odds.GetProviderPlayersRequest', null, global);
goog.exportSymbol('proto.odds.GetProviderPlayersResponse', null, global);
goog.exportSymbol('proto.odds.GetProvidersResponse', null, global);
goog.exportSymbol('proto.odds.GetSideBarRequest', null, global);
goog.exportSymbol('proto.odds.GetSideBarResponse', null, global);
goog.exportSymbol('proto.odds.GetSportsBoReponse', null, global);
goog.exportSymbol('proto.odds.GetSportsWithCompetitionsResponse', null, global);
goog.exportSymbol('proto.odds.GetStatusesForEventsAndOddsRequest', null, global);
goog.exportSymbol('proto.odds.GetStatusesForEventsAndOddsResponse', null, global);
goog.exportSymbol('proto.odds.GetUnresolvedEventsResponse', null, global);
goog.exportSymbol('proto.odds.IsonisOutArg', null, global);
goog.exportSymbol('proto.odds.IsonisOutcomeArgs', null, global);
goog.exportSymbol('proto.odds.LandBaseCode', null, global);
goog.exportSymbol('proto.odds.LandBaseCodeEvent', null, global);
goog.exportSymbol('proto.odds.LandBaseCounter', null, global);
goog.exportSymbol('proto.odds.LandBaseCounters', null, global);
goog.exportSymbol('proto.odds.LandBaseId', null, global);
goog.exportSymbol('proto.odds.LandBaseResultEvent', null, global);
goog.exportSymbol('proto.odds.LandBaseResultsRequest', null, global);
goog.exportSymbol('proto.odds.LandBaseResultsResponse', null, global);
goog.exportSymbol('proto.odds.Link', null, global);
goog.exportSymbol('proto.odds.LinkCompetitionRequest', null, global);
goog.exportSymbol('proto.odds.LinkCompetitorRequest', null, global);
goog.exportSymbol('proto.odds.LinkEventsRequest', null, global);
goog.exportSymbol('proto.odds.LinkPlayerRequest', null, global);
goog.exportSymbol('proto.odds.LiveDisableStatus', null, global);
goog.exportSymbol('proto.odds.LiveEvents', null, global);
goog.exportSymbol('proto.odds.LiveEventsBO', null, global);
goog.exportSymbol('proto.odds.LiveMarket', null, global);
goog.exportSymbol('proto.odds.LiveOrderSport', null, global);
goog.exportSymbol('proto.odds.LiveOutcomeOrderResponse', null, global);
goog.exportSymbol('proto.odds.Location', null, global);
goog.exportSymbol('proto.odds.LocationOrderRequest', null, global);
goog.exportSymbol('proto.odds.Market', null, global);
goog.exportSymbol('proto.odds.MarketConfig', null, global);
goog.exportSymbol('proto.odds.MarketGroup', null, global);
goog.exportSymbol('proto.odds.MarketGroupConfig', null, global);
goog.exportSymbol('proto.odds.MarketTemplate', null, global);
goog.exportSymbol('proto.odds.MarketTemplateResponse', null, global);
goog.exportSymbol('proto.odds.MatchProfile', null, global);
goog.exportSymbol('proto.odds.MatchProfiles', null, global);
goog.exportSymbol('proto.odds.Odd', null, global);
goog.exportSymbol('proto.odds.OddFwwRequest', null, global);
goog.exportSymbol('proto.odds.OddResponse', null, global);
goog.exportSymbol('proto.odds.OddStatus', null, global);
goog.exportSymbol('proto.odds.OddStatusHistory', null, global);
goog.exportSymbol('proto.odds.Outcome', null, global);
goog.exportSymbol('proto.odds.PASport', null, global);
goog.exportSymbol('proto.odds.PaginatedEvents', null, global);
goog.exportSymbol('proto.odds.Player', null, global);
goog.exportSymbol('proto.odds.Provider', null, global);
goog.exportSymbol('proto.odds.ProviderCompetition', null, global);
goog.exportSymbol('proto.odds.ProviderCompetitor', null, global);
goog.exportSymbol('proto.odds.ProviderOutcome', null, global);
goog.exportSymbol('proto.odds.ProviderPlayer', null, global);
goog.exportSymbol('proto.odds.PublishEventEvent', null, global);
goog.exportSymbol('proto.odds.PublishEventsBoRequest', null, global);
goog.exportSymbol('proto.odds.PublishEventsBoResponse', null, global);
goog.exportSymbol('proto.odds.ResetLandbaseCodesRequest', null, global);
goog.exportSymbol('proto.odds.ResolveOddRequest', null, global);
goog.exportSymbol('proto.odds.SearchCompetitorRequest', null, global);
goog.exportSymbol('proto.odds.SearchEventsRequest', null, global);
goog.exportSymbol('proto.odds.SearchOutcomesRequest', null, global);
goog.exportSymbol('proto.odds.SearchOutcomesResponse', null, global);
goog.exportSymbol('proto.odds.SingleApproval', null, global);
goog.exportSymbol('proto.odds.SingleLandBaseOfferResponse', null, global);
goog.exportSymbol('proto.odds.SlipGroupOddResponse', null, global);
goog.exportSymbol('proto.odds.SlipSubGroupOddResponse', null, global);
goog.exportSymbol('proto.odds.SpecialMarketIds', null, global);
goog.exportSymbol('proto.odds.Sport', null, global);
goog.exportSymbol('proto.odds.SportLocationRequest', null, global);
goog.exportSymbol('proto.odds.StreamEvent', null, global);
goog.exportSymbol('proto.odds.StreamEventOdd', null, global);
goog.exportSymbol('proto.odds.SuspiciousEvent', null, global);
goog.exportSymbol('proto.odds.SuspiciousLevel', null, global);
goog.exportSymbol('proto.odds.SuspiciousLevels', null, global);
goog.exportSymbol('proto.odds.SyncLandBaseRequest', null, global);
goog.exportSymbol('proto.odds.SyncLandBaseResponse', null, global);
goog.exportSymbol('proto.odds.TimeCancelRequest', null, global);
goog.exportSymbol('proto.odds.TimeRange', null, global);
goog.exportSymbol('proto.odds.ToggleFavoriteRequest', null, global);
goog.exportSymbol('proto.odds.UpcomingEvents', null, global);
goog.exportSymbol('proto.odds.UpdateChangedLimitOddsRequest', null, global);
goog.exportSymbol('proto.odds.UpdateChangedLimitOddsResponse', null, global);
goog.exportSymbol('proto.odds.UpdateCompetitionLimit', null, global);
goog.exportSymbol('proto.odds.UpdateEventResultRequest', null, global);
goog.exportSymbol('proto.odds.UpdateLandBaseCodes', null, global);
goog.exportSymbol('proto.odds.UpdateMarketGroupsBoRequest', null, global);
goog.exportSymbol('proto.odds.UpdateMissingSlipResultsRequest', null, global);
goog.exportSymbol('proto.odds.UpdateOddsRequest', null, global);
goog.exportSymbol('proto.odds.ValidationPriorityObjects', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetActiveLBCodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetActiveLBCodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetActiveLBCodesRequest.displayName = 'proto.odds.GetActiveLBCodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetActiveLBCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetActiveLBCodesResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetActiveLBCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetActiveLBCodesResponse.displayName = 'proto.odds.GetActiveLBCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AssignLandBaseCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.AssignLandBaseCodeRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.AssignLandBaseCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AssignLandBaseCodeRequest.displayName = 'proto.odds.AssignLandBaseCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AssignLandBaseCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.AssignLandBaseCodeResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.AssignLandBaseCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AssignLandBaseCodeResponse.displayName = 'proto.odds.AssignLandBaseCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseCounters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LandBaseCounters.repeatedFields_, null);
};
goog.inherits(proto.odds.LandBaseCounters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseCounters.displayName = 'proto.odds.LandBaseCounters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseCounter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LandBaseCounter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseCounter.displayName = 'proto.odds.LandBaseCounter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DuplicatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.DuplicatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DuplicatesRequest.displayName = 'proto.odds.DuplicatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DuplicatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.DuplicatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DuplicatesResponse.displayName = 'proto.odds.DuplicatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EventList.repeatedFields_, null);
};
goog.inherits(proto.odds.EventList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventList.displayName = 'proto.odds.EventList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsCreateOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventsCreateOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsCreateOfferResponse.displayName = 'proto.odds.GetEventsCreateOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetMinLimitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetMinLimitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetMinLimitRequest.displayName = 'proto.odds.GetMinLimitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventOutcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.EventOutcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventOutcome.displayName = 'proto.odds.EventOutcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetMinLimitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetMinLimitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetMinLimitResponse.displayName = 'proto.odds.GetMinLimitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateEventResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.UpdateEventResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateEventResultRequest.displayName = 'proto.odds.UpdateEventResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.TimeCancelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.TimeCancelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.TimeCancelRequest.displayName = 'proto.odds.TimeCancelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CompetitionsForLinkingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CompetitionsForLinkingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CompetitionsForLinkingRequest.displayName = 'proto.odds.CompetitionsForLinkingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CompetitionsForLinkingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CompetitionsForLinkingResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.CompetitionsForLinkingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CompetitionsForLinkingResponse.displayName = 'proto.odds.CompetitionsForLinkingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CompetitionForLinking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CompetitionForLinking.repeatedFields_, null);
};
goog.inherits(proto.odds.CompetitionForLinking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CompetitionForLinking.displayName = 'proto.odds.CompetitionForLinking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ProviderCompetition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.ProviderCompetition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ProviderCompetition.displayName = 'proto.odds.ProviderCompetition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PASport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.PASport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PASport.displayName = 'proto.odds.PASport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.BlockEventReasons = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.BlockEventReasons.repeatedFields_, null);
};
goog.inherits(proto.odds.BlockEventReasons, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.BlockEventReasons.displayName = 'proto.odds.BlockEventReasons';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.BlockEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.BlockEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.BlockEventRequest.displayName = 'proto.odds.BlockEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsForLinkingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventsForLinkingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsForLinkingRequest.displayName = 'proto.odds.GetEventsForLinkingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateLandBaseCodes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.UpdateLandBaseCodes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateLandBaseCodes.displayName = 'proto.odds.UpdateLandBaseCodes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.IsonisOutcomeArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.IsonisOutcomeArgs.repeatedFields_, null);
};
goog.inherits(proto.odds.IsonisOutcomeArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.IsonisOutcomeArgs.displayName = 'proto.odds.IsonisOutcomeArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.IsonisOutArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.IsonisOutArg.repeatedFields_, null);
};
goog.inherits(proto.odds.IsonisOutArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.IsonisOutArg.displayName = 'proto.odds.IsonisOutArg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LandBaseCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseCode.displayName = 'proto.odds.LandBaseCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CancelledEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CancelledEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CancelledEventsRequest.displayName = 'proto.odds.CancelledEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CancelledEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CancelledEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.CancelledEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CancelledEventsResponse.displayName = 'proto.odds.CancelledEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetDailyListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetDailyListRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.GetDailyListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetDailyListRequest.displayName = 'proto.odds.GetDailyListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetDailyListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetDailyListResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetDailyListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetDailyListResponse.displayName = 'proto.odds.GetDailyListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DailySport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.DailySport.repeatedFields_, null);
};
goog.inherits(proto.odds.DailySport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DailySport.displayName = 'proto.odds.DailySport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DailyEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.DailyEvent.repeatedFields_, null);
};
goog.inherits(proto.odds.DailyEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DailyEvent.displayName = 'proto.odds.DailyEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DailyMarkets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.DailyMarkets.repeatedFields_, null);
};
goog.inherits(proto.odds.DailyMarkets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DailyMarkets.displayName = 'proto.odds.DailyMarkets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DailyOutcomes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.DailyOutcomes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DailyOutcomes.displayName = 'proto.odds.DailyOutcomes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LiveOutcomeOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LiveOutcomeOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.LiveOutcomeOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LiveOutcomeOrderResponse.displayName = 'proto.odds.LiveOutcomeOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LiveOrderSport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LiveOrderSport.repeatedFields_, null);
};
goog.inherits(proto.odds.LiveOrderSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LiveOrderSport.displayName = 'proto.odds.LiveOrderSport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LiveMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LiveMarket.repeatedFields_, null);
};
goog.inherits(proto.odds.LiveMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LiveMarket.displayName = 'proto.odds.LiveMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SyncLandBaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SyncLandBaseRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.SyncLandBaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SyncLandBaseRequest.displayName = 'proto.odds.SyncLandBaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SyncLandBaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SyncLandBaseResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.SyncLandBaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SyncLandBaseResponse.displayName = 'proto.odds.SyncLandBaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetMarketTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetMarketTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetMarketTemplatesRequest.displayName = 'proto.odds.GetMarketTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LiveDisableStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LiveDisableStatus.repeatedFields_, null);
};
goog.inherits(proto.odds.LiveDisableStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LiveDisableStatus.displayName = 'proto.odds.LiveDisableStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateMissingSlipResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.UpdateMissingSlipResultsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.UpdateMissingSlipResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateMissingSlipResultsRequest.displayName = 'proto.odds.UpdateMissingSlipResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetCompetitorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetCompetitorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetCompetitorsRequest.displayName = 'proto.odds.GetCompetitorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetLocationsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetLocationsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetLocationsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetLocationsBoResponse.displayName = 'proto.odds.GetLocationsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetCompetitionsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetCompetitionsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetCompetitionsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetCompetitionsBoResponse.displayName = 'proto.odds.GetCompetitionsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LocationOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LocationOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.LocationOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LocationOrderRequest.displayName = 'proto.odds.LocationOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SportLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SportLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SportLocationRequest.displayName = 'proto.odds.SportLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateMarketGroupsBoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.UpdateMarketGroupsBoRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.UpdateMarketGroupsBoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateMarketGroupsBoRequest.displayName = 'proto.odds.UpdateMarketGroupsBoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetCompetitorsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetCompetitorsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetCompetitorsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetCompetitorsBoResponse.displayName = 'proto.odds.GetCompetitorsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSportsBoReponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetSportsBoReponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetSportsBoReponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSportsBoReponse.displayName = 'proto.odds.GetSportsBoReponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAllMarketGroupsBoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetAllMarketGroupsBoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAllMarketGroupsBoRequest.displayName = 'proto.odds.GetAllMarketGroupsBoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAllMarketGroupsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetAllMarketGroupsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetAllMarketGroupsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAllMarketGroupsBoResponse.displayName = 'proto.odds.GetAllMarketGroupsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketGroupConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MarketGroupConfig.repeatedFields_, null);
};
goog.inherits(proto.odds.MarketGroupConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketGroupConfig.displayName = 'proto.odds.MarketGroupConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAllMarketsBoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetAllMarketsBoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAllMarketsBoRequest.displayName = 'proto.odds.GetAllMarketsBoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAllMarketsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetAllMarketsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetAllMarketsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAllMarketsBoResponse.displayName = 'proto.odds.GetAllMarketsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.MarketConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketConfig.displayName = 'proto.odds.MarketConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Competitor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Competitor.repeatedFields_, null);
};
goog.inherits(proto.odds.Competitor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Competitor.displayName = 'proto.odds.Competitor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Link.displayName = 'proto.odds.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetForProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetForProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetForProviderRequest.displayName = 'proto.odds.GetForProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetInitialDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetInitialDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetInitialDataRequest.displayName = 'proto.odds.GetInitialDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetOddsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.GetOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetOddsRequest.displayName = 'proto.odds.GetOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetFastOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetFastOddsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.GetFastOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetFastOddsRequest.displayName = 'proto.odds.GetFastOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ToggleFavoriteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.ToggleFavoriteRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.ToggleFavoriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ToggleFavoriteRequest.displayName = 'proto.odds.ToggleFavoriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PublishEventEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.PublishEventEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PublishEventEvent.displayName = 'proto.odds.PublishEventEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PublishEventsBoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.PublishEventsBoRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.PublishEventsBoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PublishEventsBoRequest.displayName = 'proto.odds.PublishEventsBoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PublishEventsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.PublishEventsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.PublishEventsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PublishEventsBoResponse.displayName = 'proto.odds.PublishEventsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Event.repeatedFields_, null);
};
goog.inherits(proto.odds.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Event.displayName = 'proto.odds.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseCodeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LandBaseCodeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseCodeEvent.displayName = 'proto.odds.LandBaseCodeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.StreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.StreamEvent.repeatedFields_, null);
};
goog.inherits(proto.odds.StreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.StreamEvent.displayName = 'proto.odds.StreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.StreamEventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.StreamEventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.StreamEventOdd.displayName = 'proto.odds.StreamEventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CreateEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CreateEventRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.CreateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CreateEventRequest.displayName = 'proto.odds.CreateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventFwwRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.EventFwwRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventFwwRequest.displayName = 'proto.odds.EventFwwRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddFwwRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddFwwRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddFwwRequest.displayName = 'proto.odds.OddFwwRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CompetitionFwwRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CompetitionFwwRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CompetitionFwwRequest.displayName = 'proto.odds.CompetitionFwwRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.EventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventOdd.displayName = 'proto.odds.EventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MarketGroup.repeatedFields_, null);
};
goog.inherits(proto.odds.MarketGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketGroup.displayName = 'proto.odds.MarketGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Market = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Market.repeatedFields_, null);
};
goog.inherits(proto.odds.Market, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Market.displayName = 'proto.odds.Market';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Outcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Outcome.repeatedFields_, null);
};
goog.inherits(proto.odds.Outcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Outcome.displayName = 'proto.odds.Outcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Odd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.Odd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Odd.displayName = 'proto.odds.Odd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Sport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Sport.repeatedFields_, null);
};
goog.inherits(proto.odds.Sport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Sport.displayName = 'proto.odds.Sport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.BasicOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.BasicOffer.repeatedFields_, null);
};
goog.inherits(proto.odds.BasicOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.BasicOffer.displayName = 'proto.odds.BasicOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.BasicOfferMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.BasicOfferMarket.repeatedFields_, null);
};
goog.inherits(proto.odds.BasicOfferMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.BasicOfferMarket.displayName = 'proto.odds.BasicOfferMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Location.repeatedFields_, null);
};
goog.inherits(proto.odds.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Location.displayName = 'proto.odds.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Competition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Competition.repeatedFields_, null);
};
goog.inherits(proto.odds.Competition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Competition.displayName = 'proto.odds.Competition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddResponse.displayName = 'proto.odds.OddResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetInitialDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetInitialDataResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetInitialDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetInitialDataResponse.displayName = 'proto.odds.GetInitialDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetInitialDataForBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetInitialDataForBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetInitialDataForBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetInitialDataForBoResponse.displayName = 'proto.odds.GetInitialDataForBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProvidersResponse.displayName = 'proto.odds.GetProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsResponse.displayName = 'proto.odds.GetEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LiveEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LiveEvents.repeatedFields_, null);
};
goog.inherits(proto.odds.LiveEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LiveEvents.displayName = 'proto.odds.LiveEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LiveEventsBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LiveEventsBO.repeatedFields_, null);
};
goog.inherits(proto.odds.LiveEventsBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LiveEventsBO.displayName = 'proto.odds.LiveEventsBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpcomingEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.UpcomingEvents.repeatedFields_, null);
};
goog.inherits(proto.odds.UpcomingEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpcomingEvents.displayName = 'proto.odds.UpcomingEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AllEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.AllEvents.repeatedFields_, null);
};
goog.inherits(proto.odds.AllEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AllEvents.displayName = 'proto.odds.AllEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SlipSubGroupOddResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SlipSubGroupOddResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.SlipSubGroupOddResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SlipSubGroupOddResponse.displayName = 'proto.odds.SlipSubGroupOddResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SlipGroupOddResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SlipGroupOddResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.SlipGroupOddResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SlipGroupOddResponse.displayName = 'proto.odds.SlipGroupOddResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetOddsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetOddsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetOddsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetOddsResponse.displayName = 'proto.odds.GetOddsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Provider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Provider.repeatedFields_, null);
};
goog.inherits(proto.odds.Provider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Provider.displayName = 'proto.odds.Provider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CreateOfferEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CreateOfferEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CreateOfferEvent.displayName = 'proto.odds.CreateOfferEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CreateOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CreateOfferRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.CreateOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CreateOfferRequest.displayName = 'proto.odds.CreateOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAllLandBaseOffersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetAllLandBaseOffersResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetAllLandBaseOffersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAllLandBaseOffersResponse.displayName = 'proto.odds.GetAllLandBaseOffersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SingleLandBaseOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SingleLandBaseOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SingleLandBaseOfferResponse.displayName = 'proto.odds.SingleLandBaseOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CreateLandBaseOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CreateLandBaseOfferRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.CreateLandBaseOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CreateLandBaseOfferRequest.displayName = 'proto.odds.CreateLandBaseOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CreateLandBaseOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CreateLandBaseOfferResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.CreateLandBaseOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CreateLandBaseOfferResponse.displayName = 'proto.odds.CreateLandBaseOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.TimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.TimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.TimeRange.displayName = 'proto.odds.TimeRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LandBaseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseId.displayName = 'proto.odds.LandBaseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditLandBaseOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EditLandBaseOfferRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.EditLandBaseOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditLandBaseOfferRequest.displayName = 'proto.odds.EditLandBaseOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditLandBaseOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EditLandBaseOfferResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.EditLandBaseOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditLandBaseOfferResponse.displayName = 'proto.odds.EditLandBaseOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SearchEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SearchEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SearchEventsRequest.displayName = 'proto.odds.SearchEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SearchOutcomesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SearchOutcomesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SearchOutcomesRequest.displayName = 'proto.odds.SearchOutcomesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SearchOutcomesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SearchOutcomesResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.SearchOutcomesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SearchOutcomesResponse.displayName = 'proto.odds.SearchOutcomesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SearchCompetitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SearchCompetitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SearchCompetitorRequest.displayName = 'proto.odds.SearchCompetitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LinkCompetitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LinkCompetitorRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.LinkCompetitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LinkCompetitorRequest.displayName = 'proto.odds.LinkCompetitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LinkCompetitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LinkCompetitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LinkCompetitionRequest.displayName = 'proto.odds.LinkCompetitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetStatusesForEventsAndOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetStatusesForEventsAndOddsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.GetStatusesForEventsAndOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetStatusesForEventsAndOddsRequest.displayName = 'proto.odds.GetStatusesForEventsAndOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddStatus.displayName = 'proto.odds.OddStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetStatusesForEventsAndOddsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetStatusesForEventsAndOddsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetStatusesForEventsAndOddsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetStatusesForEventsAndOddsResponse.displayName = 'proto.odds.GetStatusesForEventsAndOddsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderCompetitorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetProviderCompetitorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderCompetitorsRequest.displayName = 'proto.odds.GetProviderCompetitorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderCompetitorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetProviderCompetitorsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetProviderCompetitorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderCompetitorsResponse.displayName = 'proto.odds.GetProviderCompetitorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderCompetitionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetProviderCompetitionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderCompetitionsRequest.displayName = 'proto.odds.GetProviderCompetitionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderCompetitionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetProviderCompetitionsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetProviderCompetitionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderCompetitionsResponse.displayName = 'proto.odds.GetProviderCompetitionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ProviderCompetitor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.ProviderCompetitor.repeatedFields_, null);
};
goog.inherits(proto.odds.ProviderCompetitor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ProviderCompetitor.displayName = 'proto.odds.ProviderCompetitor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ProviderPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.ProviderPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ProviderPlayer.displayName = 'proto.odds.ProviderPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ProviderOutcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.ProviderOutcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ProviderOutcome.displayName = 'proto.odds.ProviderOutcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPlayersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPlayersResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPlayersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPlayersResponse.displayName = 'proto.odds.GetPlayersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Player = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Player.repeatedFields_, null);
};
goog.inherits(proto.odds.Player, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Player.displayName = 'proto.odds.Player';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddPlayerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AddPlayerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddPlayerRequest.displayName = 'proto.odds.AddPlayerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddPlayerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AddPlayerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddPlayerResponse.displayName = 'proto.odds.AddPlayerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.EditPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditPlayer.displayName = 'proto.odds.EditPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LinkPlayerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LinkPlayerRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.LinkPlayerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LinkPlayerRequest.displayName = 'proto.odds.LinkPlayerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderPlayersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetProviderPlayersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderPlayersRequest.displayName = 'proto.odds.GetProviderPlayersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderPlayersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetProviderPlayersResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetProviderPlayersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderPlayersResponse.displayName = 'proto.odds.GetProviderPlayersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddCompetitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.AddCompetitorRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.AddCompetitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddCompetitorRequest.displayName = 'proto.odds.AddCompetitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddCompetitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AddCompetitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddCompetitionRequest.displayName = 'proto.odds.AddCompetitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddCompetitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AddCompetitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddCompetitionResponse.displayName = 'proto.odds.AddCompetitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddCompetitorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.AddCompetitorResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.AddCompetitorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddCompetitorResponse.displayName = 'proto.odds.AddCompetitorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditCompetitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EditCompetitorRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.EditCompetitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditCompetitorRequest.displayName = 'proto.odds.EditCompetitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditCompetitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.EditCompetitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditCompetitionRequest.displayName = 'proto.odds.EditCompetitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AutoAddCompetitionEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AutoAddCompetitionEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AutoAddCompetitionEventsRequest.displayName = 'proto.odds.AutoAddCompetitionEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetOutcomesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetOutcomesResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetOutcomesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetOutcomesResponse.displayName = 'proto.odds.GetOutcomesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetOutcomesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetOutcomesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetOutcomesRequest.displayName = 'proto.odds.GetOutcomesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetProviderOutcomesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetProviderOutcomesResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetProviderOutcomesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetProviderOutcomesResponse.displayName = 'proto.odds.GetProviderOutcomesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventTypesResponse.displayName = 'proto.odds.GetEventTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ConfigureEventStartTimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.ConfigureEventStartTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ConfigureEventStartTimeRequest.displayName = 'proto.odds.ConfigureEventStartTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsByTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventsByTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsByTypeRequest.displayName = 'proto.odds.GetEventsByTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsByTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetEventsByTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetEventsByTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsByTypeResponse.displayName = 'proto.odds.GetEventsByTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EditEventRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.EditEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditEventRequest.displayName = 'proto.odds.EditEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ResolveOddRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.ResolveOddRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.ResolveOddRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ResolveOddRequest.displayName = 'proto.odds.ResolveOddRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventOddsRequest.displayName = 'proto.odds.GetEventOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventOddsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetEventOddsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetEventOddsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventOddsResponse.displayName = 'proto.odds.GetEventOddsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DisableEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.DisableEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DisableEventRequest.displayName = 'proto.odds.DisableEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsWithPlayersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetEventsWithPlayersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsWithPlayersRequest.displayName = 'proto.odds.GetEventsWithPlayersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsWithPlayersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetEventsWithPlayersResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetEventsWithPlayersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsWithPlayersResponse.displayName = 'proto.odds.GetEventsWithPlayersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetMarketsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetMarketsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetMarketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetMarketsResponse.displayName = 'proto.odds.GetMarketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetUnresolvedEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetUnresolvedEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetUnresolvedEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetUnresolvedEventsResponse.displayName = 'proto.odds.GetUnresolvedEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSportsWithCompetitionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetSportsWithCompetitionsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetSportsWithCompetitionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSportsWithCompetitionsResponse.displayName = 'proto.odds.GetSportsWithCompetitionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsForManualResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetEventsForManualResultsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetEventsForManualResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsForManualResultsResponse.displayName = 'proto.odds.GetEventsForManualResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EditManualResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.EditManualResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EditManualResultRequest.displayName = 'proto.odds.EditManualResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LinkEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LinkEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LinkEventsRequest.displayName = 'proto.odds.LinkEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GenerateListPDFRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GenerateListPDFRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GenerateListPDFRequest.displayName = 'proto.odds.GenerateListPDFRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GenerateListPDFResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GenerateListPDFResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GenerateListPDFResponse.displayName = 'proto.odds.GenerateListPDFResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSideBarRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetSideBarRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSideBarRequest.displayName = 'proto.odds.GetSideBarRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSideBarResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetSideBarResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetSideBarResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSideBarResponse.displayName = 'proto.odds.GetSideBarResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Filter.repeatedFields_, null);
};
goog.inherits(proto.odds.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Filter.displayName = 'proto.odds.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPaginatedEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPaginatedEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPaginatedEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPaginatedEventsRequest.displayName = 'proto.odds.GetPaginatedEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PaginatedEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.PaginatedEvents.repeatedFields_, null);
};
goog.inherits(proto.odds.PaginatedEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PaginatedEvents.displayName = 'proto.odds.PaginatedEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CancelAllUnplayedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CancelAllUnplayedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CancelAllUnplayedRequest.displayName = 'proto.odds.CancelAllUnplayedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ValidationPriorityObjects = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.ValidationPriorityObjects, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ValidationPriorityObjects.displayName = 'proto.odds.ValidationPriorityObjects';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AddNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AddNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AddNoteRequest.displayName = 'proto.odds.AddNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SuspiciousEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SuspiciousEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SuspiciousEvent.displayName = 'proto.odds.SuspiciousEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.UpdateOddsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.UpdateOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateOddsRequest.displayName = 'proto.odds.UpdateOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SuspiciousLevels = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SuspiciousLevels.repeatedFields_, null);
};
goog.inherits(proto.odds.SuspiciousLevels, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SuspiciousLevels.displayName = 'proto.odds.SuspiciousLevels';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SuspiciousLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SuspiciousLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SuspiciousLevel.displayName = 'proto.odds.SuspiciousLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MatchProfiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MatchProfiles.repeatedFields_, null);
};
goog.inherits(proto.odds.MatchProfiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MatchProfiles.displayName = 'proto.odds.MatchProfiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MatchProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.MatchProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MatchProfile.displayName = 'proto.odds.MatchProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SingleApproval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.SingleApproval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SingleApproval.displayName = 'proto.odds.SingleApproval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateChangedLimitOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.UpdateChangedLimitOddsRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.UpdateChangedLimitOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateChangedLimitOddsRequest.displayName = 'proto.odds.UpdateChangedLimitOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateChangedLimitOddsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.UpdateChangedLimitOddsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateChangedLimitOddsResponse.displayName = 'proto.odds.UpdateChangedLimitOddsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ResetLandbaseCodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.ResetLandbaseCodesRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.ResetLandbaseCodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ResetLandbaseCodesRequest.displayName = 'proto.odds.ResetLandbaseCodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPaginatedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.GetPaginatedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPaginatedRequest.displayName = 'proto.odds.GetPaginatedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPaginatedCompetitorsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPaginatedCompetitorsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPaginatedCompetitorsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPaginatedCompetitorsBoResponse.displayName = 'proto.odds.GetPaginatedCompetitorsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPaginatedCompetitionsBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPaginatedCompetitionsBoResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPaginatedCompetitionsBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPaginatedCompetitionsBoResponse.displayName = 'proto.odds.GetPaginatedCompetitionsBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.DisableLive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.DisableLive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.DisableLive.displayName = 'proto.odds.DisableLive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetCompetitionBetLimitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetCompetitionBetLimitRequest.repeatedFields_, null);
};
goog.inherits(proto.odds.GetCompetitionBetLimitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetCompetitionBetLimitRequest.displayName = 'proto.odds.GetCompetitionBetLimitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CompetitionLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.CompetitionLimit.repeatedFields_, null);
};
goog.inherits(proto.odds.CompetitionLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CompetitionLimit.displayName = 'proto.odds.CompetitionLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetCompetitionBetLimitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetCompetitionBetLimitResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetCompetitionBetLimitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetCompetitionBetLimitResponse.displayName = 'proto.odds.GetCompetitionBetLimitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.UpdateCompetitionLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.UpdateCompetitionLimit.repeatedFields_, null);
};
goog.inherits(proto.odds.UpdateCompetitionLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.UpdateCompetitionLimit.displayName = 'proto.odds.UpdateCompetitionLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MarketTemplate.repeatedFields_, null);
};
goog.inherits(proto.odds.MarketTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketTemplate.displayName = 'proto.odds.MarketTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MarketTemplateResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.MarketTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketTemplateResponse.displayName = 'proto.odds.MarketTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.ChangeMarketTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.ChangeMarketTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.ChangeMarketTemplateRequest.displayName = 'proto.odds.ChangeMarketTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LandBaseResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseResultsRequest.displayName = 'proto.odds.LandBaseResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseResultEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LandBaseResultEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseResultEvent.displayName = 'proto.odds.LandBaseResultEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LandBaseResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.LandBaseResultsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.LandBaseResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LandBaseResultsResponse.displayName = 'proto.odds.LandBaseResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SpecialMarketIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SpecialMarketIds.repeatedFields_, null);
};
goog.inherits(proto.odds.SpecialMarketIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SpecialMarketIds.displayName = 'proto.odds.SpecialMarketIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CancelEventOddsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CancelEventOddsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CancelEventOddsRequest.displayName = 'proto.odds.CancelEventOddsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddStatusHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddStatusHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddStatusHistory.displayName = 'proto.odds.OddStatusHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetOddStatusHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetOddStatusHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetOddStatusHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetOddStatusHistoryResponse.displayName = 'proto.odds.GetOddStatusHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.FeaturedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.FeaturedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.FeaturedEvent.displayName = 'proto.odds.FeaturedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.FeaturedEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.FeaturedEvents.repeatedFields_, null);
};
goog.inherits(proto.odds.FeaturedEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.FeaturedEvents.displayName = 'proto.odds.FeaturedEvents';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetActiveLBCodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetActiveLBCodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetActiveLBCodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetActiveLBCodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetActiveLBCodesRequest}
 */
proto.odds.GetActiveLBCodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetActiveLBCodesRequest;
  return proto.odds.GetActiveLBCodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetActiveLBCodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetActiveLBCodesRequest}
 */
proto.odds.GetActiveLBCodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetActiveLBCodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetActiveLBCodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetActiveLBCodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetActiveLBCodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 sport_id = 1;
 * @return {number}
 */
proto.odds.GetActiveLBCodesRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetActiveLBCodesRequest} returns this
 */
proto.odds.GetActiveLBCodesRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetActiveLBCodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetActiveLBCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetActiveLBCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetActiveLBCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetActiveLBCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lbcodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetActiveLBCodesResponse}
 */
proto.odds.GetActiveLBCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetActiveLBCodesResponse;
  return proto.odds.GetActiveLBCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetActiveLBCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetActiveLBCodesResponse}
 */
proto.odds.GetActiveLBCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLbcodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetActiveLBCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetActiveLBCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetActiveLBCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetActiveLBCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLbcodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string lbcodes = 1;
 * @return {!Array<string>}
 */
proto.odds.GetActiveLBCodesResponse.prototype.getLbcodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.GetActiveLBCodesResponse} returns this
 */
proto.odds.GetActiveLBCodesResponse.prototype.setLbcodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.GetActiveLBCodesResponse} returns this
 */
proto.odds.GetActiveLBCodesResponse.prototype.addLbcodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetActiveLBCodesResponse} returns this
 */
proto.odds.GetActiveLBCodesResponse.prototype.clearLbcodesList = function() {
  return this.setLbcodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.AssignLandBaseCodeRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AssignLandBaseCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AssignLandBaseCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AssignLandBaseCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    competitionIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    filter: (f = msg.getFilter()) && proto.odds.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AssignLandBaseCodeRequest}
 */
proto.odds.AssignLandBaseCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AssignLandBaseCodeRequest;
  return proto.odds.AssignLandBaseCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AssignLandBaseCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AssignLandBaseCodeRequest}
 */
proto.odds.AssignLandBaseCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompetitionIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 5:
      var value = new proto.odds.Filter;
      reader.readMessage(value,proto.odds.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AssignLandBaseCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AssignLandBaseCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AssignLandBaseCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompetitionIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.odds.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 provider_id = 1;
 * @return {number}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 2;
 * @return {number}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int32 competition_ids = 6;
 * @return {!Array<number>}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.getCompetitionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.setCompetitionIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.addCompetitionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.clearCompetitionIdsList = function() {
  return this.setCompetitionIdsList([]);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string event_id = 4;
 * @return {string}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Filter filter = 5;
 * @return {?proto.odds.Filter}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.getFilter = function() {
  return /** @type{?proto.odds.Filter} */ (
    jspb.Message.getWrapperField(this, proto.odds.Filter, 5));
};


/**
 * @param {?proto.odds.Filter|undefined} value
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
*/
proto.odds.AssignLandBaseCodeRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.AssignLandBaseCodeRequest} returns this
 */
proto.odds.AssignLandBaseCodeRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.AssignLandBaseCodeRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.AssignLandBaseCodeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AssignLandBaseCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AssignLandBaseCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AssignLandBaseCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AssignLandBaseCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AssignLandBaseCodeResponse}
 */
proto.odds.AssignLandBaseCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AssignLandBaseCodeResponse;
  return proto.odds.AssignLandBaseCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AssignLandBaseCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AssignLandBaseCodeResponse}
 */
proto.odds.AssignLandBaseCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AssignLandBaseCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AssignLandBaseCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AssignLandBaseCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AssignLandBaseCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.AssignLandBaseCodeResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 1));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.AssignLandBaseCodeResponse} returns this
*/
proto.odds.AssignLandBaseCodeResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.AssignLandBaseCodeResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.AssignLandBaseCodeResponse} returns this
 */
proto.odds.AssignLandBaseCodeResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional common.Error error = 2;
 * @return {?proto.common.Error}
 */
proto.odds.AssignLandBaseCodeResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 2));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.AssignLandBaseCodeResponse} returns this
*/
proto.odds.AssignLandBaseCodeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.AssignLandBaseCodeResponse} returns this
 */
proto.odds.AssignLandBaseCodeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.AssignLandBaseCodeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LandBaseCounters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseCounters.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseCounters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseCounters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCounters.toObject = function(includeInstance, msg) {
  var f, obj = {
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    proto.odds.LandBaseCounter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseCounters}
 */
proto.odds.LandBaseCounters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseCounters;
  return proto.odds.LandBaseCounters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseCounters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseCounters}
 */
proto.odds.LandBaseCounters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.LandBaseCounter;
      reader.readMessage(value,proto.odds.LandBaseCounter.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseCounters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseCounters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseCounters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCounters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.LandBaseCounter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LandBaseCounter counters = 1;
 * @return {!Array<!proto.odds.LandBaseCounter>}
 */
proto.odds.LandBaseCounters.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.odds.LandBaseCounter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.LandBaseCounter, 1));
};


/**
 * @param {!Array<!proto.odds.LandBaseCounter>} value
 * @return {!proto.odds.LandBaseCounters} returns this
*/
proto.odds.LandBaseCounters.prototype.setCountersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.LandBaseCounter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.LandBaseCounter}
 */
proto.odds.LandBaseCounters.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.LandBaseCounter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LandBaseCounters} returns this
 */
proto.odds.LandBaseCounters.prototype.clearCountersList = function() {
  return this.setCountersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseCounter.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseCounter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseCounter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCounter.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    nextValue: jspb.Message.getFieldWithDefault(msg, 4, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseCounter}
 */
proto.odds.LandBaseCounter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseCounter;
  return proto.odds.LandBaseCounter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseCounter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseCounter}
 */
proto.odds.LandBaseCounter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextValue(value);
      break;
    case 5:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseCounter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseCounter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseCounter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCounter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxValue();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNextValue();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 sport_id = 1;
 * @return {number}
 */
proto.odds.LandBaseCounter.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseCounter} returns this
 */
proto.odds.LandBaseCounter.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 min_value = 2;
 * @return {number}
 */
proto.odds.LandBaseCounter.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseCounter} returns this
 */
proto.odds.LandBaseCounter.prototype.setMinValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 max_value = 3;
 * @return {number}
 */
proto.odds.LandBaseCounter.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseCounter} returns this
 */
proto.odds.LandBaseCounter.prototype.setMaxValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 next_value = 4;
 * @return {number}
 */
proto.odds.LandBaseCounter.prototype.getNextValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseCounter} returns this
 */
proto.odds.LandBaseCounter.prototype.setNextValue = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional common.Error error = 5;
 * @return {?proto.common.Error}
 */
proto.odds.LandBaseCounter.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 5));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.LandBaseCounter} returns this
*/
proto.odds.LandBaseCounter.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseCounter} returns this
 */
proto.odds.LandBaseCounter.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseCounter.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DuplicatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DuplicatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DuplicatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DuplicatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DuplicatesRequest}
 */
proto.odds.DuplicatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DuplicatesRequest;
  return proto.odds.DuplicatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DuplicatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DuplicatesRequest}
 */
proto.odds.DuplicatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DuplicatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DuplicatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DuplicatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DuplicatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 provider_id = 1;
 * @return {number}
 */
proto.odds.DuplicatesRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.DuplicatesRequest} returns this
 */
proto.odds.DuplicatesRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DuplicatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DuplicatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DuplicatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DuplicatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    landbaseCodeMapMap: (f = msg.getLandbaseCodeMapMap()) ? f.toObject(includeInstance, proto.odds.EventList.toObject) : [],
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DuplicatesResponse}
 */
proto.odds.DuplicatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DuplicatesResponse;
  return proto.odds.DuplicatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DuplicatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DuplicatesResponse}
 */
proto.odds.DuplicatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLandbaseCodeMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds.EventList.deserializeBinaryFromReader, "", new proto.odds.EventList());
         });
      break;
    case 5:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DuplicatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DuplicatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DuplicatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DuplicatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLandbaseCodeMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds.EventList.serializeBinaryToWriter);
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, EventList> landbase_code_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds.EventList>}
 */
proto.odds.DuplicatesResponse.prototype.getLandbaseCodeMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds.EventList>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.odds.EventList));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.DuplicatesResponse} returns this
 */
proto.odds.DuplicatesResponse.prototype.clearLandbaseCodeMapMap = function() {
  this.getLandbaseCodeMapMap().clear();
  return this;};


/**
 * optional common.Error error = 5;
 * @return {?proto.common.Error}
 */
proto.odds.DuplicatesResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 5));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.DuplicatesResponse} returns this
*/
proto.odds.DuplicatesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.DuplicatesResponse} returns this
 */
proto.odds.DuplicatesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.DuplicatesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EventList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventList.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventList.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventList}
 */
proto.odds.EventList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventList;
  return proto.odds.EventList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventList}
 */
proto.odds.EventList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.EventList.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 1));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.EventList} returns this
*/
proto.odds.EventList.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.EventList.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EventList} returns this
 */
proto.odds.EventList.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsCreateOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsCreateOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsCreateOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsCreateOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 2, 0),
    competitionsMap: (f = msg.getCompetitionsMap()) ? f.toObject(includeInstance, proto.odds.Competition.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsCreateOfferResponse}
 */
proto.odds.GetEventsCreateOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsCreateOfferResponse;
  return proto.odds.GetEventsCreateOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsCreateOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsCreateOfferResponse}
 */
proto.odds.GetEventsCreateOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 3:
      var value = msg.getCompetitionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.odds.Competition.deserializeBinaryFromReader, 0, new proto.odds.Competition());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsCreateOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsCreateOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsCreateOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsCreateOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompetitionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.odds.Competition.serializeBinaryToWriter);
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.odds.GetEventsCreateOfferResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetEventsCreateOfferResponse} returns this
 */
proto.odds.GetEventsCreateOfferResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_pages = 2;
 * @return {number}
 */
proto.odds.GetEventsCreateOfferResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetEventsCreateOfferResponse} returns this
 */
proto.odds.GetEventsCreateOfferResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * map<int32, Competition> competitions = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.odds.Competition>}
 */
proto.odds.GetEventsCreateOfferResponse.prototype.getCompetitionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.odds.Competition>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.odds.Competition));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.GetEventsCreateOfferResponse} returns this
 */
proto.odds.GetEventsCreateOfferResponse.prototype.clearCompetitionsMap = function() {
  this.getCompetitionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetMinLimitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetMinLimitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetMinLimitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMinLimitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddsMapMap: (f = msg.getOddsMapMap()) ? f.toObject(includeInstance, proto.odds.EventOutcome.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetMinLimitRequest}
 */
proto.odds.GetMinLimitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetMinLimitRequest;
  return proto.odds.GetMinLimitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetMinLimitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetMinLimitRequest}
 */
proto.odds.GetMinLimitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getOddsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds.EventOutcome.deserializeBinaryFromReader, "", new proto.odds.EventOutcome());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetMinLimitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetMinLimitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetMinLimitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMinLimitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds.EventOutcome.serializeBinaryToWriter);
  }
};


/**
 * map<string, EventOutcome> odds_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds.EventOutcome>}
 */
proto.odds.GetMinLimitRequest.prototype.getOddsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds.EventOutcome>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.odds.EventOutcome));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.GetMinLimitRequest} returns this
 */
proto.odds.GetMinLimitRequest.prototype.clearOddsMapMap = function() {
  this.getOddsMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventOutcome.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventOutcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventOutcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventOutcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventOutcome}
 */
proto.odds.EventOutcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventOutcome;
  return proto.odds.EventOutcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventOutcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventOutcome}
 */
proto.odds.EventOutcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventOutcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventOutcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventOutcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventOutcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.odds.EventOutcome.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOutcome} returns this
 */
proto.odds.EventOutcome.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 outcome_id = 2;
 * @return {number}
 */
proto.odds.EventOutcome.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOutcome} returns this
 */
proto.odds.EventOutcome.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetMinLimitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetMinLimitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetMinLimitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMinLimitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    limitsMapMap: (f = msg.getLimitsMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetMinLimitResponse}
 */
proto.odds.GetMinLimitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetMinLimitResponse;
  return proto.odds.GetMinLimitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetMinLimitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetMinLimitResponse}
 */
proto.odds.GetMinLimitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLimitsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetMinLimitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetMinLimitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetMinLimitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMinLimitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * map<string, double> limits_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.odds.GetMinLimitResponse.prototype.getLimitsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.GetMinLimitResponse} returns this
 */
proto.odds.GetMinLimitResponse.prototype.clearLimitsMapMap = function() {
  this.getLimitsMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateEventResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateEventResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateEventResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateEventResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    result: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resolve: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateEventResultRequest}
 */
proto.odds.UpdateEventResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateEventResultRequest;
  return proto.odds.UpdateEventResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateEventResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateEventResultRequest}
 */
proto.odds.UpdateEventResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResolve(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateEventResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateEventResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateEventResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateEventResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResolve();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.odds.UpdateEventResultRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.UpdateEventResultRequest} returns this
 */
proto.odds.UpdateEventResultRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string result = 2;
 * @return {string}
 */
proto.odds.UpdateEventResultRequest.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.UpdateEventResultRequest} returns this
 */
proto.odds.UpdateEventResultRequest.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool resolve = 3;
 * @return {boolean}
 */
proto.odds.UpdateEventResultRequest.prototype.getResolve = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.UpdateEventResultRequest} returns this
 */
proto.odds.UpdateEventResultRequest.prototype.setResolve = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.TimeCancelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.TimeCancelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.TimeCancelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.TimeCancelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.TimeCancelRequest}
 */
proto.odds.TimeCancelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.TimeCancelRequest;
  return proto.odds.TimeCancelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.TimeCancelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.TimeCancelRequest}
 */
proto.odds.TimeCancelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.TimeCancelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.TimeCancelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.TimeCancelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.TimeCancelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.odds.TimeCancelRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.TimeCancelRequest} returns this
 */
proto.odds.TimeCancelRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 timestamp = 2;
 * @return {number}
 */
proto.odds.TimeCancelRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.TimeCancelRequest} returns this
 */
proto.odds.TimeCancelRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CompetitionsForLinkingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CompetitionsForLinkingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionsForLinkingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    searchString: jspb.Message.getFieldWithDefault(msg, 3, ""),
    linkFilter: jspb.Message.getFieldWithDefault(msg, 4, ""),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CompetitionsForLinkingRequest}
 */
proto.odds.CompetitionsForLinkingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CompetitionsForLinkingRequest;
  return proto.odds.CompetitionsForLinkingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CompetitionsForLinkingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CompetitionsForLinkingRequest}
 */
proto.odds.CompetitionsForLinkingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkFilter(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CompetitionsForLinkingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CompetitionsForLinkingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionsForLinkingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLinkFilter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 1;
 * @return {number}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionsForLinkingRequest} returns this
 */
proto.odds.CompetitionsForLinkingRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 location_id = 2;
 * @return {number}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionsForLinkingRequest} returns this
 */
proto.odds.CompetitionsForLinkingRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string search_string = 3;
 * @return {string}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionsForLinkingRequest} returns this
 */
proto.odds.CompetitionsForLinkingRequest.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string link_filter = 4;
 * @return {string}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.getLinkFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionsForLinkingRequest} returns this
 */
proto.odds.CompetitionsForLinkingRequest.prototype.setLinkFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.odds.CompetitionsForLinkingRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionsForLinkingRequest} returns this
 */
proto.odds.CompetitionsForLinkingRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CompetitionsForLinkingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CompetitionsForLinkingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CompetitionsForLinkingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CompetitionsForLinkingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionsForLinkingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.CompetitionForLinking.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CompetitionsForLinkingResponse}
 */
proto.odds.CompetitionsForLinkingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CompetitionsForLinkingResponse;
  return proto.odds.CompetitionsForLinkingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CompetitionsForLinkingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CompetitionsForLinkingResponse}
 */
proto.odds.CompetitionsForLinkingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.CompetitionForLinking;
      reader.readMessage(value,proto.odds.CompetitionForLinking.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CompetitionsForLinkingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CompetitionsForLinkingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CompetitionsForLinkingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionsForLinkingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.CompetitionForLinking.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated CompetitionForLinking competitions = 1;
 * @return {!Array<!proto.odds.CompetitionForLinking>}
 */
proto.odds.CompetitionsForLinkingResponse.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.CompetitionForLinking>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.CompetitionForLinking, 1));
};


/**
 * @param {!Array<!proto.odds.CompetitionForLinking>} value
 * @return {!proto.odds.CompetitionsForLinkingResponse} returns this
*/
proto.odds.CompetitionsForLinkingResponse.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.CompetitionForLinking=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.CompetitionForLinking}
 */
proto.odds.CompetitionsForLinkingResponse.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.CompetitionForLinking, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CompetitionsForLinkingResponse} returns this
 */
proto.odds.CompetitionsForLinkingResponse.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.odds.CompetitionsForLinkingResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionsForLinkingResponse} returns this
 */
proto.odds.CompetitionsForLinkingResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_count = 3;
 * @return {number}
 */
proto.odds.CompetitionsForLinkingResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionsForLinkingResponse} returns this
 */
proto.odds.CompetitionsForLinkingResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CompetitionForLinking.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CompetitionForLinking.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CompetitionForLinking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CompetitionForLinking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionForLinking.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, ""),
    liveCompetitionList: jspb.Message.toObjectList(msg.getLiveCompetitionList(),
    proto.odds.ProviderCompetition.toObject, includeInstance),
    prematchCompetitionList: jspb.Message.toObjectList(msg.getPrematchCompetitionList(),
    proto.odds.ProviderCompetition.toObject, includeInstance),
    shortName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    standingsUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CompetitionForLinking}
 */
proto.odds.CompetitionForLinking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CompetitionForLinking;
  return proto.odds.CompetitionForLinking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CompetitionForLinking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CompetitionForLinking}
 */
proto.odds.CompetitionForLinking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 4:
      var value = new proto.odds.ProviderCompetition;
      reader.readMessage(value,proto.odds.ProviderCompetition.deserializeBinaryFromReader);
      msg.addLiveCompetition(value);
      break;
    case 5:
      var value = new proto.odds.ProviderCompetition;
      reader.readMessage(value,proto.odds.ProviderCompetition.deserializeBinaryFromReader);
      msg.addPrematchCompetition(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandingsUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CompetitionForLinking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CompetitionForLinking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CompetitionForLinking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionForLinking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLiveCompetitionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.odds.ProviderCompetition.serializeBinaryToWriter
    );
  }
  f = message.getPrematchCompetitionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.odds.ProviderCompetition.serializeBinaryToWriter
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStandingsUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.CompetitionForLinking.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.CompetitionForLinking.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 3;
 * @return {string}
 */
proto.odds.CompetitionForLinking.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProviderCompetition live_competition = 4;
 * @return {!Array<!proto.odds.ProviderCompetition>}
 */
proto.odds.CompetitionForLinking.prototype.getLiveCompetitionList = function() {
  return /** @type{!Array<!proto.odds.ProviderCompetition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.ProviderCompetition, 4));
};


/**
 * @param {!Array<!proto.odds.ProviderCompetition>} value
 * @return {!proto.odds.CompetitionForLinking} returns this
*/
proto.odds.CompetitionForLinking.prototype.setLiveCompetitionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.odds.ProviderCompetition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderCompetition}
 */
proto.odds.CompetitionForLinking.prototype.addLiveCompetition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.odds.ProviderCompetition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.clearLiveCompetitionList = function() {
  return this.setLiveCompetitionList([]);
};


/**
 * repeated ProviderCompetition prematch_competition = 5;
 * @return {!Array<!proto.odds.ProviderCompetition>}
 */
proto.odds.CompetitionForLinking.prototype.getPrematchCompetitionList = function() {
  return /** @type{!Array<!proto.odds.ProviderCompetition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.ProviderCompetition, 5));
};


/**
 * @param {!Array<!proto.odds.ProviderCompetition>} value
 * @return {!proto.odds.CompetitionForLinking} returns this
*/
proto.odds.CompetitionForLinking.prototype.setPrematchCompetitionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.odds.ProviderCompetition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderCompetition}
 */
proto.odds.CompetitionForLinking.prototype.addPrematchCompetition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.odds.ProviderCompetition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.clearPrematchCompetitionList = function() {
  return this.setPrematchCompetitionList([]);
};


/**
 * optional string short_name = 6;
 * @return {string}
 */
proto.odds.CompetitionForLinking.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 location_id = 7;
 * @return {number}
 */
proto.odds.CompetitionForLinking.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string standings_url = 8;
 * @return {string}
 */
proto.odds.CompetitionForLinking.prototype.getStandingsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionForLinking} returns this
 */
proto.odds.CompetitionForLinking.prototype.setStandingsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ProviderCompetition.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ProviderCompetition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ProviderCompetition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderCompetition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 46, ""),
    factoryCompetitionId: jspb.Message.getFieldWithDefault(msg, 129, 0),
    factoryCompetitionName: jspb.Message.getFieldWithDefault(msg, 130, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ProviderCompetition}
 */
proto.odds.ProviderCompetition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ProviderCompetition;
  return proto.odds.ProviderCompetition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ProviderCompetition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ProviderCompetition}
 */
proto.odds.ProviderCompetition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 129:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitionId(value);
      break;
    case 130:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryCompetitionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ProviderCompetition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ProviderCompetition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ProviderCompetition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderCompetition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getFactoryCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      129,
      f
    );
  }
  f = message.getFactoryCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      130,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.ProviderCompetition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderCompetition} returns this
 */
proto.odds.ProviderCompetition.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.ProviderCompetition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderCompetition} returns this
 */
proto.odds.ProviderCompetition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 46;
 * @return {string}
 */
proto.odds.ProviderCompetition.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderCompetition} returns this
 */
proto.odds.ProviderCompetition.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional int32 factory_competition_id = 129;
 * @return {number}
 */
proto.odds.ProviderCompetition.prototype.getFactoryCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 129, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderCompetition} returns this
 */
proto.odds.ProviderCompetition.prototype.setFactoryCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 129, value);
};


/**
 * optional string factory_competition_name = 130;
 * @return {string}
 */
proto.odds.ProviderCompetition.prototype.getFactoryCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 130, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderCompetition} returns this
 */
proto.odds.ProviderCompetition.prototype.setFactoryCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 130, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PASport.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PASport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PASport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PASport.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PASport}
 */
proto.odds.PASport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PASport;
  return proto.odds.PASport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PASport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PASport}
 */
proto.odds.PASport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PASport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PASport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PASport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PASport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds.PASport.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PASport} returns this
 */
proto.odds.PASport.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 2;
 * @return {number}
 */
proto.odds.PASport.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PASport} returns this
 */
proto.odds.PASport.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.odds.PASport.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PASport} returns this
 */
proto.odds.PASport.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.BlockEventReasons.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.BlockEventReasons.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.BlockEventReasons.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.BlockEventReasons} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BlockEventReasons.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.BlockEventReasons}
 */
proto.odds.BlockEventReasons.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.BlockEventReasons;
  return proto.odds.BlockEventReasons.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.BlockEventReasons} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.BlockEventReasons}
 */
proto.odds.BlockEventReasons.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.BlockEventReasons.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.BlockEventReasons.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.BlockEventReasons} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BlockEventReasons.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string reasons = 1;
 * @return {!Array<string>}
 */
proto.odds.BlockEventReasons.prototype.getReasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.BlockEventReasons} returns this
 */
proto.odds.BlockEventReasons.prototype.setReasonsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.BlockEventReasons} returns this
 */
proto.odds.BlockEventReasons.prototype.addReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.BlockEventReasons} returns this
 */
proto.odds.BlockEventReasons.prototype.clearReasonsList = function() {
  return this.setReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.BlockEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.BlockEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.BlockEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BlockEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.BlockEventRequest}
 */
proto.odds.BlockEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.BlockEventRequest;
  return proto.odds.BlockEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.BlockEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.BlockEventRequest}
 */
proto.odds.BlockEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.BlockEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.BlockEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.BlockEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BlockEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.BlockEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.BlockEventRequest} returns this
 */
proto.odds.BlockEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.odds.BlockEventRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.BlockEventRequest} returns this
 */
proto.odds.BlockEventRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsForLinkingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsForLinkingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsForLinkingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsForLinkingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchString: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsForLinkingRequest}
 */
proto.odds.GetEventsForLinkingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsForLinkingRequest;
  return proto.odds.GetEventsForLinkingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsForLinkingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsForLinkingRequest}
 */
proto.odds.GetEventsForLinkingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsForLinkingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsForLinkingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsForLinkingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsForLinkingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string search_string = 1;
 * @return {string}
 */
proto.odds.GetEventsForLinkingRequest.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetEventsForLinkingRequest} returns this
 */
proto.odds.GetEventsForLinkingRequest.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 sport_id = 2;
 * @return {number}
 */
proto.odds.GetEventsForLinkingRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetEventsForLinkingRequest} returns this
 */
proto.odds.GetEventsForLinkingRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateLandBaseCodes.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateLandBaseCodes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateLandBaseCodes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateLandBaseCodes.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateLandBaseCodes}
 */
proto.odds.UpdateLandBaseCodes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateLandBaseCodes;
  return proto.odds.UpdateLandBaseCodes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateLandBaseCodes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateLandBaseCodes}
 */
proto.odds.UpdateLandBaseCodes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateLandBaseCodes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateLandBaseCodes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateLandBaseCodes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateLandBaseCodes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.UpdateLandBaseCodes.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.UpdateLandBaseCodes} returns this
 */
proto.odds.UpdateLandBaseCodes.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.UpdateLandBaseCodes.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.UpdateLandBaseCodes} returns this
 */
proto.odds.UpdateLandBaseCodes.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.IsonisOutcomeArgs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.IsonisOutcomeArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.IsonisOutcomeArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.IsonisOutcomeArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.IsonisOutcomeArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
    outcomeArgsList: jspb.Message.toObjectList(msg.getOutcomeArgsList(),
    proto.odds.IsonisOutArg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.IsonisOutcomeArgs}
 */
proto.odds.IsonisOutcomeArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.IsonisOutcomeArgs;
  return proto.odds.IsonisOutcomeArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.IsonisOutcomeArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.IsonisOutcomeArgs}
 */
proto.odds.IsonisOutcomeArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.IsonisOutArg;
      reader.readMessage(value,proto.odds.IsonisOutArg.deserializeBinaryFromReader);
      msg.addOutcomeArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.IsonisOutcomeArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.IsonisOutcomeArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.IsonisOutcomeArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.IsonisOutcomeArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutcomeArgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.IsonisOutArg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IsonisOutArg outcome_args = 1;
 * @return {!Array<!proto.odds.IsonisOutArg>}
 */
proto.odds.IsonisOutcomeArgs.prototype.getOutcomeArgsList = function() {
  return /** @type{!Array<!proto.odds.IsonisOutArg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.IsonisOutArg, 1));
};


/**
 * @param {!Array<!proto.odds.IsonisOutArg>} value
 * @return {!proto.odds.IsonisOutcomeArgs} returns this
*/
proto.odds.IsonisOutcomeArgs.prototype.setOutcomeArgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.IsonisOutArg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.IsonisOutArg}
 */
proto.odds.IsonisOutcomeArgs.prototype.addOutcomeArgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.IsonisOutArg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.IsonisOutcomeArgs} returns this
 */
proto.odds.IsonisOutcomeArgs.prototype.clearOutcomeArgsList = function() {
  return this.setOutcomeArgsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.IsonisOutArg.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.IsonisOutArg.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.IsonisOutArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.IsonisOutArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.IsonisOutArg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isonisResultsPrematchArgsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    isonisResultsLiveArgsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    isonisName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.IsonisOutArg}
 */
proto.odds.IsonisOutArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.IsonisOutArg;
  return proto.odds.IsonisOutArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.IsonisOutArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.IsonisOutArg}
 */
proto.odds.IsonisOutArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addIsonisResultsPrematchArgs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIsonisResultsLiveArgs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsonisName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.IsonisOutArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.IsonisOutArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.IsonisOutArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.IsonisOutArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsonisResultsPrematchArgsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getIsonisResultsLiveArgsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getIsonisName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.IsonisOutArg.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string isonis_results_prematch_args = 2;
 * @return {!Array<string>}
 */
proto.odds.IsonisOutArg.prototype.getIsonisResultsPrematchArgsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.setIsonisResultsPrematchArgsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.addIsonisResultsPrematchArgs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.clearIsonisResultsPrematchArgsList = function() {
  return this.setIsonisResultsPrematchArgsList([]);
};


/**
 * repeated string isonis_results_live_args = 3;
 * @return {!Array<string>}
 */
proto.odds.IsonisOutArg.prototype.getIsonisResultsLiveArgsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.setIsonisResultsLiveArgsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.addIsonisResultsLiveArgs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.clearIsonisResultsLiveArgsList = function() {
  return this.setIsonisResultsLiveArgsList([]);
};


/**
 * optional string isonis_name = 4;
 * @return {string}
 */
proto.odds.IsonisOutArg.prototype.getIsonisName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.IsonisOutArg} returns this
 */
proto.odds.IsonisOutArg.prototype.setIsonisName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseCode.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 80, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseCode}
 */
proto.odds.LandBaseCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseCode;
  return proto.odds.LandBaseCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseCode}
 */
proto.odds.LandBaseCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
};


/**
 * optional string landbase_code = 80;
 * @return {string}
 */
proto.odds.LandBaseCode.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LandBaseCode} returns this
 */
proto.odds.LandBaseCode.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CancelledEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CancelledEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CancelledEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelledEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    searchString: jspb.Message.getFieldWithDefault(msg, 157, ""),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateTo: (f = msg.getDateTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CancelledEventsRequest}
 */
proto.odds.CancelledEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CancelledEventsRequest;
  return proto.odds.CancelledEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CancelledEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CancelledEventsRequest}
 */
proto.odds.CancelledEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 157:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 159:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 160:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CancelledEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CancelledEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CancelledEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelledEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      157,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      159,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateTo();
  if (f != null) {
    writer.writeMessage(
      160,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.CancelledEventsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CancelledEventsRequest} returns this
 */
proto.odds.CancelledEventsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional string search_string = 157;
 * @return {string}
 */
proto.odds.CancelledEventsRequest.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 157, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CancelledEventsRequest} returns this
 */
proto.odds.CancelledEventsRequest.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 157, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 159;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CancelledEventsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 159));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CancelledEventsRequest} returns this
*/
proto.odds.CancelledEventsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 159, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CancelledEventsRequest} returns this
 */
proto.odds.CancelledEventsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CancelledEventsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 159) != null;
};


/**
 * optional google.protobuf.Timestamp date_to = 160;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CancelledEventsRequest.prototype.getDateTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 160));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CancelledEventsRequest} returns this
*/
proto.odds.CancelledEventsRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 160, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CancelledEventsRequest} returns this
 */
proto.odds.CancelledEventsRequest.prototype.clearDateTo = function() {
  return this.setDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CancelledEventsRequest.prototype.hasDateTo = function() {
  return jspb.Message.getField(this, 160) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CancelledEventsResponse.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CancelledEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CancelledEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CancelledEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelledEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 162, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CancelledEventsResponse}
 */
proto.odds.CancelledEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CancelledEventsResponse;
  return proto.odds.CancelledEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CancelledEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CancelledEventsResponse}
 */
proto.odds.CancelledEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 162:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CancelledEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CancelledEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CancelledEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelledEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      162,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.CancelledEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.CancelledEventsResponse} returns this
*/
proto.odds.CancelledEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.CancelledEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CancelledEventsResponse} returns this
 */
proto.odds.CancelledEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.CancelledEventsResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CancelledEventsResponse} returns this
 */
proto.odds.CancelledEventsResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 total_pages = 162;
 * @return {number}
 */
proto.odds.CancelledEventsResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CancelledEventsResponse} returns this
 */
proto.odds.CancelledEventsResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 162, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.CancelledEventsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.CancelledEventsResponse} returns this
*/
proto.odds.CancelledEventsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CancelledEventsResponse} returns this
 */
proto.odds.CancelledEventsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CancelledEventsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetDailyListRequest.repeatedFields_ = [182];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetDailyListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetDailyListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetDailyListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetDailyListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportIdsList: (f = jspb.Message.getRepeatedField(msg, 182)) == null ? undefined : f,
    fullOffer: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetDailyListRequest}
 */
proto.odds.GetDailyListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetDailyListRequest;
  return proto.odds.GetDailyListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetDailyListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetDailyListRequest}
 */
proto.odds.GetDailyListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 182:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSportIds(values[i]);
      }
      break;
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetDailyListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetDailyListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetDailyListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetDailyListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      182,
      f
    );
  }
  f = message.getFullOffer();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * repeated int32 sport_ids = 182;
 * @return {!Array<number>}
 */
proto.odds.GetDailyListRequest.prototype.getSportIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 182));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.GetDailyListRequest} returns this
 */
proto.odds.GetDailyListRequest.prototype.setSportIdsList = function(value) {
  return jspb.Message.setField(this, 182, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.GetDailyListRequest} returns this
 */
proto.odds.GetDailyListRequest.prototype.addSportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 182, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetDailyListRequest} returns this
 */
proto.odds.GetDailyListRequest.prototype.clearSportIdsList = function() {
  return this.setSportIdsList([]);
};


/**
 * optional bool full_offer = 1;
 * @return {boolean}
 */
proto.odds.GetDailyListRequest.prototype.getFullOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.GetDailyListRequest} returns this
 */
proto.odds.GetDailyListRequest.prototype.setFullOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetDailyListResponse.repeatedFields_ = [269];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetDailyListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetDailyListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetDailyListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetDailyListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.DailySport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetDailyListResponse}
 */
proto.odds.GetDailyListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetDailyListResponse;
  return proto.odds.GetDailyListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetDailyListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetDailyListResponse}
 */
proto.odds.GetDailyListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 269:
      var value = new proto.odds.DailySport;
      reader.readMessage(value,proto.odds.DailySport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetDailyListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetDailyListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetDailyListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetDailyListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      269,
      f,
      proto.odds.DailySport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DailySport sports = 269;
 * @return {!Array<!proto.odds.DailySport>}
 */
proto.odds.GetDailyListResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.DailySport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.DailySport, 269));
};


/**
 * @param {!Array<!proto.odds.DailySport>} value
 * @return {!proto.odds.GetDailyListResponse} returns this
*/
proto.odds.GetDailyListResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 269, value);
};


/**
 * @param {!proto.odds.DailySport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.DailySport}
 */
proto.odds.GetDailyListResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 269, opt_value, proto.odds.DailySport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetDailyListResponse} returns this
 */
proto.odds.GetDailyListResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.DailySport.repeatedFields_ = [270];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DailySport.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DailySport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DailySport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailySport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.DailyEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DailySport}
 */
proto.odds.DailySport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DailySport;
  return proto.odds.DailySport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DailySport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DailySport}
 */
proto.odds.DailySport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 270:
      var value = new proto.odds.DailyEvent;
      reader.readMessage(value,proto.odds.DailyEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DailySport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DailySport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DailySport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailySport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      270,
      f,
      proto.odds.DailyEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 5;
 * @return {number}
 */
proto.odds.DailySport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.DailySport} returns this
 */
proto.odds.DailySport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated DailyEvent events = 270;
 * @return {!Array<!proto.odds.DailyEvent>}
 */
proto.odds.DailySport.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.DailyEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.DailyEvent, 270));
};


/**
 * @param {!Array<!proto.odds.DailyEvent>} value
 * @return {!proto.odds.DailySport} returns this
*/
proto.odds.DailySport.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 270, value);
};


/**
 * @param {!proto.odds.DailyEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.DailyEvent}
 */
proto.odds.DailySport.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 270, opt_value, proto.odds.DailyEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.DailySport} returns this
 */
proto.odds.DailySport.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.DailyEvent.repeatedFields_ = [271];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DailyEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DailyEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DailyEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailyEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 127, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 80, ""),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.DailyMarkets.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DailyEvent}
 */
proto.odds.DailyEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DailyEvent;
  return proto.odds.DailyEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DailyEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DailyEvent}
 */
proto.odds.DailyEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 127:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 271:
      var value = new proto.odds.DailyMarkets;
      reader.readMessage(value,proto.odds.DailyMarkets.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DailyEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DailyEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DailyEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailyEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      127,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      271,
      f,
      proto.odds.DailyMarkets.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.DailyEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DailyEvent} returns this
 */
proto.odds.DailyEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string competition_name = 127;
 * @return {string}
 */
proto.odds.DailyEvent.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 127, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DailyEvent} returns this
 */
proto.odds.DailyEvent.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 127, value);
};


/**
 * optional google.protobuf.Timestamp start = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.DailyEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.DailyEvent} returns this
*/
proto.odds.DailyEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.DailyEvent} returns this
 */
proto.odds.DailyEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.DailyEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string landbase_code = 80;
 * @return {string}
 */
proto.odds.DailyEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DailyEvent} returns this
 */
proto.odds.DailyEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * repeated DailyMarkets markets = 271;
 * @return {!Array<!proto.odds.DailyMarkets>}
 */
proto.odds.DailyEvent.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.DailyMarkets>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.DailyMarkets, 271));
};


/**
 * @param {!Array<!proto.odds.DailyMarkets>} value
 * @return {!proto.odds.DailyEvent} returns this
*/
proto.odds.DailyEvent.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 271, value);
};


/**
 * @param {!proto.odds.DailyMarkets=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.DailyMarkets}
 */
proto.odds.DailyEvent.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 271, opt_value, proto.odds.DailyMarkets, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.DailyEvent} returns this
 */
proto.odds.DailyEvent.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.DailyMarkets.repeatedFields_ = [272];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DailyMarkets.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DailyMarkets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DailyMarkets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailyMarkets.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.DailyOutcomes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DailyMarkets}
 */
proto.odds.DailyMarkets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DailyMarkets;
  return proto.odds.DailyMarkets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DailyMarkets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DailyMarkets}
 */
proto.odds.DailyMarkets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 272:
      var value = new proto.odds.DailyOutcomes;
      reader.readMessage(value,proto.odds.DailyOutcomes.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DailyMarkets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DailyMarkets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DailyMarkets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailyMarkets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      272,
      f,
      proto.odds.DailyOutcomes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.DailyMarkets.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DailyMarkets} returns this
 */
proto.odds.DailyMarkets.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DailyOutcomes outcomes = 272;
 * @return {!Array<!proto.odds.DailyOutcomes>}
 */
proto.odds.DailyMarkets.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.DailyOutcomes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.DailyOutcomes, 272));
};


/**
 * @param {!Array<!proto.odds.DailyOutcomes>} value
 * @return {!proto.odds.DailyMarkets} returns this
*/
proto.odds.DailyMarkets.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 272, value);
};


/**
 * @param {!proto.odds.DailyOutcomes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.DailyOutcomes}
 */
proto.odds.DailyMarkets.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 272, opt_value, proto.odds.DailyOutcomes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.DailyMarkets} returns this
 */
proto.odds.DailyMarkets.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DailyOutcomes.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DailyOutcomes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DailyOutcomes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailyOutcomes.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DailyOutcomes}
 */
proto.odds.DailyOutcomes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DailyOutcomes;
  return proto.odds.DailyOutcomes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DailyOutcomes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DailyOutcomes}
 */
proto.odds.DailyOutcomes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DailyOutcomes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DailyOutcomes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DailyOutcomes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DailyOutcomes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.DailyOutcomes.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DailyOutcomes} returns this
 */
proto.odds.DailyOutcomes.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double value = 8;
 * @return {number}
 */
proto.odds.DailyOutcomes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.DailyOutcomes} returns this
 */
proto.odds.DailyOutcomes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LiveOutcomeOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LiveOutcomeOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LiveOutcomeOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LiveOutcomeOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveOutcomeOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.LiveOrderSport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LiveOutcomeOrderResponse}
 */
proto.odds.LiveOutcomeOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LiveOutcomeOrderResponse;
  return proto.odds.LiveOutcomeOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LiveOutcomeOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LiveOutcomeOrderResponse}
 */
proto.odds.LiveOutcomeOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.LiveOrderSport;
      reader.readMessage(value,proto.odds.LiveOrderSport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LiveOutcomeOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LiveOutcomeOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LiveOutcomeOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveOutcomeOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.LiveOrderSport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LiveOrderSport sports = 1;
 * @return {!Array<!proto.odds.LiveOrderSport>}
 */
proto.odds.LiveOutcomeOrderResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.LiveOrderSport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.LiveOrderSport, 1));
};


/**
 * @param {!Array<!proto.odds.LiveOrderSport>} value
 * @return {!proto.odds.LiveOutcomeOrderResponse} returns this
*/
proto.odds.LiveOutcomeOrderResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.LiveOrderSport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.LiveOrderSport}
 */
proto.odds.LiveOutcomeOrderResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.LiveOrderSport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveOutcomeOrderResponse} returns this
 */
proto.odds.LiveOutcomeOrderResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LiveOrderSport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LiveOrderSport.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LiveOrderSport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LiveOrderSport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveOrderSport.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    liveMarketsList: jspb.Message.toObjectList(msg.getLiveMarketsList(),
    proto.odds.LiveMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LiveOrderSport}
 */
proto.odds.LiveOrderSport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LiveOrderSport;
  return proto.odds.LiveOrderSport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LiveOrderSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LiveOrderSport}
 */
proto.odds.LiveOrderSport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = new proto.odds.LiveMarket;
      reader.readMessage(value,proto.odds.LiveMarket.deserializeBinaryFromReader);
      msg.addLiveMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LiveOrderSport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LiveOrderSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LiveOrderSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveOrderSport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLiveMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds.LiveMarket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.LiveOrderSport.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LiveOrderSport} returns this
 */
proto.odds.LiveOrderSport.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated LiveMarket live_markets = 2;
 * @return {!Array<!proto.odds.LiveMarket>}
 */
proto.odds.LiveOrderSport.prototype.getLiveMarketsList = function() {
  return /** @type{!Array<!proto.odds.LiveMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.LiveMarket, 2));
};


/**
 * @param {!Array<!proto.odds.LiveMarket>} value
 * @return {!proto.odds.LiveOrderSport} returns this
*/
proto.odds.LiveOrderSport.prototype.setLiveMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds.LiveMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.LiveMarket}
 */
proto.odds.LiveOrderSport.prototype.addLiveMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds.LiveMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveOrderSport} returns this
 */
proto.odds.LiveOrderSport.prototype.clearLiveMarketsList = function() {
  return this.setLiveMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LiveMarket.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LiveMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LiveMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LiveMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomeIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LiveMarket}
 */
proto.odds.LiveMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LiveMarket;
  return proto.odds.LiveMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LiveMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LiveMarket}
 */
proto.odds.LiveMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutcomeIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LiveMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LiveMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LiveMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 5;
 * @return {number}
 */
proto.odds.LiveMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LiveMarket} returns this
 */
proto.odds.LiveMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.LiveMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LiveMarket} returns this
 */
proto.odds.LiveMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 outcome_ids = 4;
 * @return {!Array<number>}
 */
proto.odds.LiveMarket.prototype.getOutcomeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.LiveMarket} returns this
 */
proto.odds.LiveMarket.prototype.setOutcomeIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.LiveMarket} returns this
 */
proto.odds.LiveMarket.prototype.addOutcomeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveMarket} returns this
 */
proto.odds.LiveMarket.prototype.clearOutcomeIdsList = function() {
  return this.setOutcomeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SyncLandBaseRequest.repeatedFields_ = [241,242,250,251,252];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SyncLandBaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SyncLandBaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SyncLandBaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SyncLandBaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveIdsList: (f = jspb.Message.getRepeatedField(msg, 241)) == null ? undefined : f,
    prematchIdsList: (f = jspb.Message.getRepeatedField(msg, 242)) == null ? undefined : f,
    outrightIdsList: (f = jspb.Message.getRepeatedField(msg, 250)) == null ? undefined : f,
    playerIdsList: (f = jspb.Message.getRepeatedField(msg, 251)) == null ? undefined : f,
    groupIdsList: (f = jspb.Message.getRepeatedField(msg, 252)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SyncLandBaseRequest}
 */
proto.odds.SyncLandBaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SyncLandBaseRequest;
  return proto.odds.SyncLandBaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SyncLandBaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SyncLandBaseRequest}
 */
proto.odds.SyncLandBaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 241:
      var value = /** @type {string} */ (reader.readString());
      msg.addLiveIds(value);
      break;
    case 242:
      var value = /** @type {string} */ (reader.readString());
      msg.addPrematchIds(value);
      break;
    case 250:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutrightIds(value);
      break;
    case 251:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlayerIds(value);
      break;
    case 252:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SyncLandBaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SyncLandBaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SyncLandBaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SyncLandBaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      241,
      f
    );
  }
  f = message.getPrematchIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      242,
      f
    );
  }
  f = message.getOutrightIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      250,
      f
    );
  }
  f = message.getPlayerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      251,
      f
    );
  }
  f = message.getGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      252,
      f
    );
  }
};


/**
 * repeated string live_ids = 241;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseRequest.prototype.getLiveIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 241));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.setLiveIdsList = function(value) {
  return jspb.Message.setField(this, 241, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.addLiveIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 241, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.clearLiveIdsList = function() {
  return this.setLiveIdsList([]);
};


/**
 * repeated string prematch_ids = 242;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseRequest.prototype.getPrematchIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 242));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.setPrematchIdsList = function(value) {
  return jspb.Message.setField(this, 242, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.addPrematchIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 242, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.clearPrematchIdsList = function() {
  return this.setPrematchIdsList([]);
};


/**
 * repeated string outright_ids = 250;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseRequest.prototype.getOutrightIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 250));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.setOutrightIdsList = function(value) {
  return jspb.Message.setField(this, 250, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.addOutrightIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 250, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.clearOutrightIdsList = function() {
  return this.setOutrightIdsList([]);
};


/**
 * repeated string player_ids = 251;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseRequest.prototype.getPlayerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 251));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.setPlayerIdsList = function(value) {
  return jspb.Message.setField(this, 251, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.addPlayerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 251, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.clearPlayerIdsList = function() {
  return this.setPlayerIdsList([]);
};


/**
 * repeated string group_ids = 252;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseRequest.prototype.getGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 252));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.setGroupIdsList = function(value) {
  return jspb.Message.setField(this, 252, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.addGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 252, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseRequest} returns this
 */
proto.odds.SyncLandBaseRequest.prototype.clearGroupIdsList = function() {
  return this.setGroupIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SyncLandBaseResponse.repeatedFields_ = [243,244,245,246,253,254,255,256,257,258];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SyncLandBaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SyncLandBaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SyncLandBaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SyncLandBaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletedPrematchList: (f = jspb.Message.getRepeatedField(msg, 243)) == null ? undefined : f,
    createdPrematchList: jspb.Message.toObjectList(msg.getCreatedPrematchList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    deletedLiveList: (f = jspb.Message.getRepeatedField(msg, 245)) == null ? undefined : f,
    createdLiveList: jspb.Message.toObjectList(msg.getCreatedLiveList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    deletedOutrightList: (f = jspb.Message.getRepeatedField(msg, 253)) == null ? undefined : f,
    createdOutrightList: jspb.Message.toObjectList(msg.getCreatedOutrightList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    deletedPlayerList: (f = jspb.Message.getRepeatedField(msg, 255)) == null ? undefined : f,
    createdPlayerList: jspb.Message.toObjectList(msg.getCreatedPlayerList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    deletedGroupList: (f = jspb.Message.getRepeatedField(msg, 257)) == null ? undefined : f,
    createdGroupList: jspb.Message.toObjectList(msg.getCreatedGroupList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SyncLandBaseResponse}
 */
proto.odds.SyncLandBaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SyncLandBaseResponse;
  return proto.odds.SyncLandBaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SyncLandBaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SyncLandBaseResponse}
 */
proto.odds.SyncLandBaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 243:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeletedPrematch(value);
      break;
    case 244:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addCreatedPrematch(value);
      break;
    case 245:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeletedLive(value);
      break;
    case 246:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addCreatedLive(value);
      break;
    case 253:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeletedOutright(value);
      break;
    case 254:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addCreatedOutright(value);
      break;
    case 255:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeletedPlayer(value);
      break;
    case 256:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addCreatedPlayer(value);
      break;
    case 257:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeletedGroup(value);
      break;
    case 258:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addCreatedGroup(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SyncLandBaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SyncLandBaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SyncLandBaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SyncLandBaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeletedPrematchList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      243,
      f
    );
  }
  f = message.getCreatedPrematchList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      244,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getDeletedLiveList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      245,
      f
    );
  }
  f = message.getCreatedLiveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      246,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getDeletedOutrightList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      253,
      f
    );
  }
  f = message.getCreatedOutrightList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      254,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getDeletedPlayerList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      255,
      f
    );
  }
  f = message.getCreatedPlayerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      256,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getDeletedGroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      257,
      f
    );
  }
  f = message.getCreatedGroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      258,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string deleted_prematch = 243;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseResponse.prototype.getDeletedPrematchList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 243));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.setDeletedPrematchList = function(value) {
  return jspb.Message.setField(this, 243, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.addDeletedPrematch = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 243, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearDeletedPrematchList = function() {
  return this.setDeletedPrematchList([]);
};


/**
 * repeated StreamEvent created_prematch = 244;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.SyncLandBaseResponse.prototype.getCreatedPrematchList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 244));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
*/
proto.odds.SyncLandBaseResponse.prototype.setCreatedPrematchList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 244, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.SyncLandBaseResponse.prototype.addCreatedPrematch = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 244, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearCreatedPrematchList = function() {
  return this.setCreatedPrematchList([]);
};


/**
 * repeated string deleted_live = 245;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseResponse.prototype.getDeletedLiveList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 245));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.setDeletedLiveList = function(value) {
  return jspb.Message.setField(this, 245, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.addDeletedLive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 245, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearDeletedLiveList = function() {
  return this.setDeletedLiveList([]);
};


/**
 * repeated StreamEvent created_live = 246;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.SyncLandBaseResponse.prototype.getCreatedLiveList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 246));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
*/
proto.odds.SyncLandBaseResponse.prototype.setCreatedLiveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 246, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.SyncLandBaseResponse.prototype.addCreatedLive = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 246, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearCreatedLiveList = function() {
  return this.setCreatedLiveList([]);
};


/**
 * repeated string deleted_outright = 253;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseResponse.prototype.getDeletedOutrightList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 253));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.setDeletedOutrightList = function(value) {
  return jspb.Message.setField(this, 253, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.addDeletedOutright = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 253, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearDeletedOutrightList = function() {
  return this.setDeletedOutrightList([]);
};


/**
 * repeated StreamEvent created_outright = 254;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.SyncLandBaseResponse.prototype.getCreatedOutrightList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 254));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
*/
proto.odds.SyncLandBaseResponse.prototype.setCreatedOutrightList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 254, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.SyncLandBaseResponse.prototype.addCreatedOutright = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 254, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearCreatedOutrightList = function() {
  return this.setCreatedOutrightList([]);
};


/**
 * repeated string deleted_player = 255;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseResponse.prototype.getDeletedPlayerList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 255));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.setDeletedPlayerList = function(value) {
  return jspb.Message.setField(this, 255, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.addDeletedPlayer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 255, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearDeletedPlayerList = function() {
  return this.setDeletedPlayerList([]);
};


/**
 * repeated StreamEvent created_player = 256;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.SyncLandBaseResponse.prototype.getCreatedPlayerList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 256));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
*/
proto.odds.SyncLandBaseResponse.prototype.setCreatedPlayerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 256, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.SyncLandBaseResponse.prototype.addCreatedPlayer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 256, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearCreatedPlayerList = function() {
  return this.setCreatedPlayerList([]);
};


/**
 * repeated string deleted_group = 257;
 * @return {!Array<string>}
 */
proto.odds.SyncLandBaseResponse.prototype.getDeletedGroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 257));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.setDeletedGroupList = function(value) {
  return jspb.Message.setField(this, 257, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.addDeletedGroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 257, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearDeletedGroupList = function() {
  return this.setDeletedGroupList([]);
};


/**
 * repeated StreamEvent created_group = 258;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.SyncLandBaseResponse.prototype.getCreatedGroupList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 258));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
*/
proto.odds.SyncLandBaseResponse.prototype.setCreatedGroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 258, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.SyncLandBaseResponse.prototype.addCreatedGroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 258, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearCreatedGroupList = function() {
  return this.setCreatedGroupList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.SyncLandBaseResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.SyncLandBaseResponse} returns this
*/
proto.odds.SyncLandBaseResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.SyncLandBaseResponse} returns this
 */
proto.odds.SyncLandBaseResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.SyncLandBaseResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetMarketTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetMarketTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetMarketTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMarketTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetMarketTemplatesRequest}
 */
proto.odds.GetMarketTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetMarketTemplatesRequest;
  return proto.odds.GetMarketTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetMarketTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetMarketTemplatesRequest}
 */
proto.odds.GetMarketTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetMarketTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetMarketTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetMarketTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMarketTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetMarketTemplatesRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetMarketTemplatesRequest} returns this
 */
proto.odds.GetMarketTemplatesRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LiveDisableStatus.repeatedFields_ = [194];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LiveDisableStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LiveDisableStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LiveDisableStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveDisableStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    disableAll: jspb.Message.getBooleanFieldWithDefault(msg, 193, false),
    disabledSportsList: (f = jspb.Message.getRepeatedField(msg, 194)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LiveDisableStatus}
 */
proto.odds.LiveDisableStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LiveDisableStatus;
  return proto.odds.LiveDisableStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LiveDisableStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LiveDisableStatus}
 */
proto.odds.LiveDisableStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 193:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableAll(value);
      break;
    case 194:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisabledSports(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LiveDisableStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LiveDisableStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LiveDisableStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveDisableStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisableAll();
  if (f) {
    writer.writeBool(
      193,
      f
    );
  }
  f = message.getDisabledSportsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      194,
      f
    );
  }
};


/**
 * optional bool disable_all = 193;
 * @return {boolean}
 */
proto.odds.LiveDisableStatus.prototype.getDisableAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 193, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.LiveDisableStatus} returns this
 */
proto.odds.LiveDisableStatus.prototype.setDisableAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 193, value);
};


/**
 * repeated int32 disabled_sports = 194;
 * @return {!Array<number>}
 */
proto.odds.LiveDisableStatus.prototype.getDisabledSportsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 194));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.LiveDisableStatus} returns this
 */
proto.odds.LiveDisableStatus.prototype.setDisabledSportsList = function(value) {
  return jspb.Message.setField(this, 194, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.LiveDisableStatus} returns this
 */
proto.odds.LiveDisableStatus.prototype.addDisabledSports = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 194, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveDisableStatus} returns this
 */
proto.odds.LiveDisableStatus.prototype.clearDisabledSportsList = function() {
  return this.setDisabledSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.UpdateMissingSlipResultsRequest.repeatedFields_ = [62];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateMissingSlipResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateMissingSlipResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateMissingSlipResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateMissingSlipResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 62)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateMissingSlipResultsRequest}
 */
proto.odds.UpdateMissingSlipResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateMissingSlipResultsRequest;
  return proto.odds.UpdateMissingSlipResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateMissingSlipResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateMissingSlipResultsRequest}
 */
proto.odds.UpdateMissingSlipResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateMissingSlipResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateMissingSlipResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateMissingSlipResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateMissingSlipResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      62,
      f
    );
  }
};


/**
 * repeated string event_ids = 62;
 * @return {!Array<string>}
 */
proto.odds.UpdateMissingSlipResultsRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 62));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.UpdateMissingSlipResultsRequest} returns this
 */
proto.odds.UpdateMissingSlipResultsRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 62, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.UpdateMissingSlipResultsRequest} returns this
 */
proto.odds.UpdateMissingSlipResultsRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 62, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpdateMissingSlipResultsRequest} returns this
 */
proto.odds.UpdateMissingSlipResultsRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetCompetitorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetCompetitorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetCompetitorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetCompetitorsRequest}
 */
proto.odds.GetCompetitorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetCompetitorsRequest;
  return proto.odds.GetCompetitorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetCompetitorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetCompetitorsRequest}
 */
proto.odds.GetCompetitorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetCompetitorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetCompetitorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetCompetitorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetCompetitorsRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitorsRequest} returns this
 */
proto.odds.GetCompetitorsRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.GetCompetitorsRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitorsRequest} returns this
 */
proto.odds.GetCompetitorsRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetLocationsBoResponse.repeatedFields_ = [23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetLocationsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetLocationsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetLocationsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetLocationsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.odds.Location.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetLocationsBoResponse}
 */
proto.odds.GetLocationsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetLocationsBoResponse;
  return proto.odds.GetLocationsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetLocationsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetLocationsBoResponse}
 */
proto.odds.GetLocationsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 23:
      var value = new proto.odds.Location;
      reader.readMessage(value,proto.odds.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetLocationsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetLocationsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetLocationsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetLocationsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.odds.Location.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Location locations = 23;
 * @return {!Array<!proto.odds.Location>}
 */
proto.odds.GetLocationsBoResponse.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.odds.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Location, 23));
};


/**
 * @param {!Array<!proto.odds.Location>} value
 * @return {!proto.odds.GetLocationsBoResponse} returns this
*/
proto.odds.GetLocationsBoResponse.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.odds.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Location}
 */
proto.odds.GetLocationsBoResponse.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.odds.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetLocationsBoResponse} returns this
 */
proto.odds.GetLocationsBoResponse.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetCompetitionsBoResponse.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetCompetitionsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetCompetitionsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetCompetitionsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetCompetitionsBoResponse}
 */
proto.odds.GetCompetitionsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetCompetitionsBoResponse;
  return proto.odds.GetCompetitionsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetCompetitionsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetCompetitionsBoResponse}
 */
proto.odds.GetCompetitionsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 25:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetCompetitionsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetCompetitionsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetCompetitionsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Competition competitions = 25;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.GetCompetitionsBoResponse.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 25));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.GetCompetitionsBoResponse} returns this
*/
proto.odds.GetCompetitionsBoResponse.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.GetCompetitionsBoResponse.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetCompetitionsBoResponse} returns this
 */
proto.odds.GetCompetitionsBoResponse.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetCompetitionsBoResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetCompetitionsBoResponse} returns this
*/
proto.odds.GetCompetitionsBoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetCompetitionsBoResponse} returns this
 */
proto.odds.GetCompetitionsBoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetCompetitionsBoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LocationOrderRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LocationOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LocationOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LocationOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LocationOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LocationOrderRequest}
 */
proto.odds.LocationOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LocationOrderRequest;
  return proto.odds.LocationOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LocationOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LocationOrderRequest}
 */
proto.odds.LocationOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LocationOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LocationOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LocationOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LocationOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 ids = 1;
 * @return {!Array<number>}
 */
proto.odds.LocationOrderRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.LocationOrderRequest} returns this
 */
proto.odds.LocationOrderRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.LocationOrderRequest} returns this
 */
proto.odds.LocationOrderRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LocationOrderRequest} returns this
 */
proto.odds.LocationOrderRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SportLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SportLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SportLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SportLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sport: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SportLocationRequest}
 */
proto.odds.SportLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SportLocationRequest;
  return proto.odds.SportLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SportLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SportLocationRequest}
 */
proto.odds.SportLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SportLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SportLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SportLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SportLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSport();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 location = 1;
 * @return {number}
 */
proto.odds.SportLocationRequest.prototype.getLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.SportLocationRequest} returns this
 */
proto.odds.SportLocationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport = 2;
 * @return {number}
 */
proto.odds.SportLocationRequest.prototype.getSport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.SportLocationRequest} returns this
 */
proto.odds.SportLocationRequest.prototype.setSport = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.UpdateMarketGroupsBoRequest.repeatedFields_ = [51];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateMarketGroupsBoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateMarketGroupsBoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateMarketGroupsBoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    marketGroupConfigsList: jspb.Message.toObjectList(msg.getMarketGroupConfigsList(),
    proto.odds.MarketGroupConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateMarketGroupsBoRequest}
 */
proto.odds.UpdateMarketGroupsBoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateMarketGroupsBoRequest;
  return proto.odds.UpdateMarketGroupsBoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateMarketGroupsBoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateMarketGroupsBoRequest}
 */
proto.odds.UpdateMarketGroupsBoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 51:
      var value = new proto.odds.MarketGroupConfig;
      reader.readMessage(value,proto.odds.MarketGroupConfig.deserializeBinaryFromReader);
      msg.addMarketGroupConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateMarketGroupsBoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateMarketGroupsBoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateMarketGroupsBoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMarketGroupConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      51,
      f,
      proto.odds.MarketGroupConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.UpdateMarketGroupsBoRequest} returns this
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated MarketGroupConfig market_group_configs = 51;
 * @return {!Array<!proto.odds.MarketGroupConfig>}
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.getMarketGroupConfigsList = function() {
  return /** @type{!Array<!proto.odds.MarketGroupConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketGroupConfig, 51));
};


/**
 * @param {!Array<!proto.odds.MarketGroupConfig>} value
 * @return {!proto.odds.UpdateMarketGroupsBoRequest} returns this
*/
proto.odds.UpdateMarketGroupsBoRequest.prototype.setMarketGroupConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 51, value);
};


/**
 * @param {!proto.odds.MarketGroupConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketGroupConfig}
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.addMarketGroupConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 51, opt_value, proto.odds.MarketGroupConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpdateMarketGroupsBoRequest} returns this
 */
proto.odds.UpdateMarketGroupsBoRequest.prototype.clearMarketGroupConfigsList = function() {
  return this.setMarketGroupConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetCompetitorsBoResponse.repeatedFields_ = [44];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetCompetitorsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetCompetitorsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetCompetitorsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitorsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitorsList: jspb.Message.toObjectList(msg.getCompetitorsList(),
    proto.odds.Competitor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetCompetitorsBoResponse}
 */
proto.odds.GetCompetitorsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetCompetitorsBoResponse;
  return proto.odds.GetCompetitorsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetCompetitorsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetCompetitorsBoResponse}
 */
proto.odds.GetCompetitorsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 44:
      var value = new proto.odds.Competitor;
      reader.readMessage(value,proto.odds.Competitor.deserializeBinaryFromReader);
      msg.addCompetitors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetCompetitorsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetCompetitorsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetCompetitorsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitorsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.odds.Competitor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Competitor competitors = 44;
 * @return {!Array<!proto.odds.Competitor>}
 */
proto.odds.GetCompetitorsBoResponse.prototype.getCompetitorsList = function() {
  return /** @type{!Array<!proto.odds.Competitor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competitor, 44));
};


/**
 * @param {!Array<!proto.odds.Competitor>} value
 * @return {!proto.odds.GetCompetitorsBoResponse} returns this
*/
proto.odds.GetCompetitorsBoResponse.prototype.setCompetitorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.odds.Competitor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competitor}
 */
proto.odds.GetCompetitorsBoResponse.prototype.addCompetitors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.odds.Competitor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetCompetitorsBoResponse} returns this
 */
proto.odds.GetCompetitorsBoResponse.prototype.clearCompetitorsList = function() {
  return this.setCompetitorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetSportsBoReponse.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSportsBoReponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSportsBoReponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSportsBoReponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsBoReponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.Sport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSportsBoReponse}
 */
proto.odds.GetSportsBoReponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSportsBoReponse;
  return proto.odds.GetSportsBoReponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSportsBoReponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSportsBoReponse}
 */
proto.odds.GetSportsBoReponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSportsBoReponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSportsBoReponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSportsBoReponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsBoReponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sport sports = 13;
 * @return {!Array<!proto.odds.Sport>}
 */
proto.odds.GetSportsBoReponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.Sport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Sport, 13));
};


/**
 * @param {!Array<!proto.odds.Sport>} value
 * @return {!proto.odds.GetSportsBoReponse} returns this
*/
proto.odds.GetSportsBoReponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.odds.Sport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport}
 */
proto.odds.GetSportsBoReponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.odds.Sport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSportsBoReponse} returns this
 */
proto.odds.GetSportsBoReponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAllMarketGroupsBoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAllMarketGroupsBoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAllMarketGroupsBoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketGroupsBoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAllMarketGroupsBoRequest}
 */
proto.odds.GetAllMarketGroupsBoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAllMarketGroupsBoRequest;
  return proto.odds.GetAllMarketGroupsBoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAllMarketGroupsBoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAllMarketGroupsBoRequest}
 */
proto.odds.GetAllMarketGroupsBoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAllMarketGroupsBoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAllMarketGroupsBoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAllMarketGroupsBoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketGroupsBoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetAllMarketGroupsBoRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetAllMarketGroupsBoRequest} returns this
 */
proto.odds.GetAllMarketGroupsBoRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetAllMarketGroupsBoResponse.repeatedFields_ = [51];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAllMarketGroupsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAllMarketGroupsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketGroupsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupConfigsList: jspb.Message.toObjectList(msg.getMarketGroupConfigsList(),
    proto.odds.MarketGroupConfig.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAllMarketGroupsBoResponse}
 */
proto.odds.GetAllMarketGroupsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAllMarketGroupsBoResponse;
  return proto.odds.GetAllMarketGroupsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAllMarketGroupsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAllMarketGroupsBoResponse}
 */
proto.odds.GetAllMarketGroupsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 51:
      var value = new proto.odds.MarketGroupConfig;
      reader.readMessage(value,proto.odds.MarketGroupConfig.deserializeBinaryFromReader);
      msg.addMarketGroupConfigs(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAllMarketGroupsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAllMarketGroupsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketGroupsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      51,
      f,
      proto.odds.MarketGroupConfig.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketGroupConfig market_group_configs = 51;
 * @return {!Array<!proto.odds.MarketGroupConfig>}
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.getMarketGroupConfigsList = function() {
  return /** @type{!Array<!proto.odds.MarketGroupConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketGroupConfig, 51));
};


/**
 * @param {!Array<!proto.odds.MarketGroupConfig>} value
 * @return {!proto.odds.GetAllMarketGroupsBoResponse} returns this
*/
proto.odds.GetAllMarketGroupsBoResponse.prototype.setMarketGroupConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 51, value);
};


/**
 * @param {!proto.odds.MarketGroupConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketGroupConfig}
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.addMarketGroupConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 51, opt_value, proto.odds.MarketGroupConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetAllMarketGroupsBoResponse} returns this
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.clearMarketGroupConfigsList = function() {
  return this.setMarketGroupConfigsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetAllMarketGroupsBoResponse} returns this
*/
proto.odds.GetAllMarketGroupsBoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetAllMarketGroupsBoResponse} returns this
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetAllMarketGroupsBoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MarketGroupConfig.repeatedFields_ = [52];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketGroupConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketGroupConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketGroupConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroupConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketIdsList: (f = jspb.Message.getRepeatedField(msg, 52)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketGroupConfig}
 */
proto.odds.MarketGroupConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketGroupConfig;
  return proto.odds.MarketGroupConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketGroupConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketGroupConfig}
 */
proto.odds.MarketGroupConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 52:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMarketIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketGroupConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketGroupConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketGroupConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroupConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      52,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketGroupConfig.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroupConfig} returns this
 */
proto.odds.MarketGroupConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketGroupConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketGroupConfig} returns this
 */
proto.odds.MarketGroupConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 market_ids = 52;
 * @return {!Array<number>}
 */
proto.odds.MarketGroupConfig.prototype.getMarketIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 52));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.MarketGroupConfig} returns this
 */
proto.odds.MarketGroupConfig.prototype.setMarketIdsList = function(value) {
  return jspb.Message.setField(this, 52, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketGroupConfig} returns this
 */
proto.odds.MarketGroupConfig.prototype.addMarketIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 52, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MarketGroupConfig} returns this
 */
proto.odds.MarketGroupConfig.prototype.clearMarketIdsList = function() {
  return this.setMarketIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAllMarketsBoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAllMarketsBoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAllMarketsBoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketsBoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAllMarketsBoRequest}
 */
proto.odds.GetAllMarketsBoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAllMarketsBoRequest;
  return proto.odds.GetAllMarketsBoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAllMarketsBoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAllMarketsBoRequest}
 */
proto.odds.GetAllMarketsBoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAllMarketsBoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAllMarketsBoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAllMarketsBoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketsBoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetAllMarketsBoRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetAllMarketsBoRequest} returns this
 */
proto.odds.GetAllMarketsBoRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetAllMarketsBoResponse.repeatedFields_ = [53];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAllMarketsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAllMarketsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAllMarketsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketConfigsList: jspb.Message.toObjectList(msg.getMarketConfigsList(),
    proto.odds.MarketConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAllMarketsBoResponse}
 */
proto.odds.GetAllMarketsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAllMarketsBoResponse;
  return proto.odds.GetAllMarketsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAllMarketsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAllMarketsBoResponse}
 */
proto.odds.GetAllMarketsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 53:
      var value = new proto.odds.MarketConfig;
      reader.readMessage(value,proto.odds.MarketConfig.deserializeBinaryFromReader);
      msg.addMarketConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAllMarketsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAllMarketsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAllMarketsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllMarketsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      53,
      f,
      proto.odds.MarketConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketConfig market_configs = 53;
 * @return {!Array<!proto.odds.MarketConfig>}
 */
proto.odds.GetAllMarketsBoResponse.prototype.getMarketConfigsList = function() {
  return /** @type{!Array<!proto.odds.MarketConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketConfig, 53));
};


/**
 * @param {!Array<!proto.odds.MarketConfig>} value
 * @return {!proto.odds.GetAllMarketsBoResponse} returns this
*/
proto.odds.GetAllMarketsBoResponse.prototype.setMarketConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 53, value);
};


/**
 * @param {!proto.odds.MarketConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketConfig}
 */
proto.odds.GetAllMarketsBoResponse.prototype.addMarketConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 53, opt_value, proto.odds.MarketConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetAllMarketsBoResponse} returns this
 */
proto.odds.GetAllMarketsBoResponse.prototype.clearMarketConfigsList = function() {
  return this.setMarketConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketConfig}
 */
proto.odds.MarketConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketConfig;
  return proto.odds.MarketConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketConfig}
 */
proto.odds.MarketConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketConfig.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketConfig} returns this
 */
proto.odds.MarketConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketConfig} returns this
 */
proto.odds.MarketConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Competitor.repeatedFields_ = [25,98,138];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Competitor.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Competitor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Competitor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Competitor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance),
    locationName: jspb.Message.getFieldWithDefault(msg, 108, ""),
    sportName: jspb.Message.getFieldWithDefault(msg, 107, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.odds.Link.toObject, includeInstance),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.odds.Player.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Competitor}
 */
proto.odds.Competitor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Competitor;
  return proto.odds.Competitor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Competitor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Competitor}
 */
proto.odds.Competitor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 25:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 107:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 50:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    case 98:
      var value = new proto.odds.Link;
      reader.readMessage(value,proto.odds.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 138:
      var value = new proto.odds.Player;
      reader.readMessage(value,proto.odds.Player.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Competitor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Competitor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Competitor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Competitor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getSportName();
  if (f.length > 0) {
    writer.writeString(
      107,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      98,
      f,
      proto.odds.Link.serializeBinaryToWriter
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      138,
      f,
      proto.odds.Player.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Competitor.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Competition competitions = 25;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.Competitor.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 25));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.Competitor} returns this
*/
proto.odds.Competitor.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.Competitor.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional string location_name = 108;
 * @return {string}
 */
proto.odds.Competitor.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * optional string sport_name = 107;
 * @return {string}
 */
proto.odds.Competitor.prototype.getSportName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 107, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.setSportName = function(value) {
  return jspb.Message.setProto3StringField(this, 107, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.Competitor.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Competitor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_updated = 50;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Competitor.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 50));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Competitor} returns this
*/
proto.odds.Competitor.prototype.setLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.clearLastUpdated = function() {
  return this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Competitor.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * repeated Link links = 98;
 * @return {!Array<!proto.odds.Link>}
 */
proto.odds.Competitor.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.odds.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Link, 98));
};


/**
 * @param {!Array<!proto.odds.Link>} value
 * @return {!proto.odds.Competitor} returns this
*/
proto.odds.Competitor.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 98, value);
};


/**
 * @param {!proto.odds.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Link}
 */
proto.odds.Competitor.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 98, opt_value, proto.odds.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * repeated Player players = 138;
 * @return {!Array<!proto.odds.Player>}
 */
proto.odds.Competitor.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.odds.Player>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Player, 138));
};


/**
 * @param {!Array<!proto.odds.Player>} value
 * @return {!proto.odds.Competitor} returns this
*/
proto.odds.Competitor.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 138, value);
};


/**
 * @param {!proto.odds.Player=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Player}
 */
proto.odds.Competitor.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 138, opt_value, proto.odds.Player, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Competitor} returns this
 */
proto.odds.Competitor.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 33, 0),
    providerName: jspb.Message.getFieldWithDefault(msg, 99, ""),
    providerCompetitorId: jspb.Message.getFieldWithDefault(msg, 82, 0),
    providerCompetitorName: jspb.Message.getFieldWithDefault(msg, 100, ""),
    providerPlayerId: jspb.Message.getFieldWithDefault(msg, 101, 0),
    providerPlayerName: jspb.Message.getFieldWithDefault(msg, 105, ""),
    providerOutcomeId: jspb.Message.getFieldWithDefault(msg, 120, 0),
    providerOutcomeName: jspb.Message.getFieldWithDefault(msg, 121, ""),
    providerCompetitionId: jspb.Message.getFieldWithDefault(msg, 126, 0),
    providerCompetitionName: jspb.Message.getFieldWithDefault(msg, 127, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Link}
 */
proto.odds.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Link;
  return proto.odds.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Link}
 */
proto.odds.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    case 99:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderName(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderCompetitorId(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderCompetitorName(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderPlayerId(value);
      break;
    case 105:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderPlayerName(value);
      break;
    case 120:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderOutcomeId(value);
      break;
    case 121:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderOutcomeName(value);
      break;
    case 126:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderCompetitionId(value);
      break;
    case 127:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderCompetitionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
  f = message.getProviderName();
  if (f.length > 0) {
    writer.writeString(
      99,
      f
    );
  }
  f = message.getProviderCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      82,
      f
    );
  }
  f = message.getProviderCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getProviderPlayerId();
  if (f !== 0) {
    writer.writeInt32(
      101,
      f
    );
  }
  f = message.getProviderPlayerName();
  if (f.length > 0) {
    writer.writeString(
      105,
      f
    );
  }
  f = message.getProviderOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      120,
      f
    );
  }
  f = message.getProviderOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      121,
      f
    );
  }
  f = message.getProviderCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      126,
      f
    );
  }
  f = message.getProviderCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      127,
      f
    );
  }
};


/**
 * optional int32 provider_id = 33;
 * @return {number}
 */
proto.odds.Link.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional string provider_name = 99;
 * @return {string}
 */
proto.odds.Link.prototype.getProviderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 99, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderName = function(value) {
  return jspb.Message.setProto3StringField(this, 99, value);
};


/**
 * optional int32 provider_competitor_id = 82;
 * @return {number}
 */
proto.odds.Link.prototype.getProviderCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 82, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 82, value);
};


/**
 * optional string provider_competitor_name = 100;
 * @return {string}
 */
proto.odds.Link.prototype.getProviderCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional int32 provider_player_id = 101;
 * @return {number}
 */
proto.odds.Link.prototype.getProviderPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};


/**
 * optional string provider_player_name = 105;
 * @return {string}
 */
proto.odds.Link.prototype.getProviderPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 105, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 105, value);
};


/**
 * optional int32 provider_outcome_id = 120;
 * @return {number}
 */
proto.odds.Link.prototype.getProviderOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 120, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 120, value);
};


/**
 * optional string provider_outcome_name = 121;
 * @return {string}
 */
proto.odds.Link.prototype.getProviderOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 121, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 121, value);
};


/**
 * optional int32 provider_competition_id = 126;
 * @return {number}
 */
proto.odds.Link.prototype.getProviderCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 126, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 126, value);
};


/**
 * optional string provider_competition_name = 127;
 * @return {string}
 */
proto.odds.Link.prototype.getProviderCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 127, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Link} returns this
 */
proto.odds.Link.prototype.setProviderCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 127, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetForProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetForProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetForProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetForProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 33, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetForProviderRequest}
 */
proto.odds.GetForProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetForProviderRequest;
  return proto.odds.GetForProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetForProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetForProviderRequest}
 */
proto.odds.GetForProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetForProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetForProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetForProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetForProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
};


/**
 * optional int32 provider_id = 33;
 * @return {number}
 */
proto.odds.GetForProviderRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetForProviderRequest} returns this
 */
proto.odds.GetForProviderRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetInitialDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetInitialDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetInitialDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetInitialDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    onlyPublished: jspb.Message.getBooleanFieldWithDefault(msg, 42, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetInitialDataRequest}
 */
proto.odds.GetInitialDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetInitialDataRequest;
  return proto.odds.GetInitialDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetInitialDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetInitialDataRequest}
 */
proto.odds.GetInitialDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyPublished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetInitialDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetInitialDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetInitialDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetInitialDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnlyPublished();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
};


/**
 * optional bool only_published = 42;
 * @return {boolean}
 */
proto.odds.GetInitialDataRequest.prototype.getOnlyPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.GetInitialDataRequest} returns this
 */
proto.odds.GetInitialDataRequest.prototype.setOnlyPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetOddsRequest.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipGroupsList: jspb.Message.toObjectList(msg.getSlipGroupsList(),
    proto_common_common_pb.SlipGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetOddsRequest}
 */
proto.odds.GetOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetOddsRequest;
  return proto.odds.GetOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetOddsRequest}
 */
proto.odds.GetOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 30:
      var value = new proto_common_common_pb.SlipGroup;
      reader.readMessage(value,proto_common_common_pb.SlipGroup.deserializeBinaryFromReader);
      msg.addSlipGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto_common_common_pb.SlipGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated common.SlipGroup slip_groups = 30;
 * @return {!Array<!proto.common.SlipGroup>}
 */
proto.odds.GetOddsRequest.prototype.getSlipGroupsList = function() {
  return /** @type{!Array<!proto.common.SlipGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_common_pb.SlipGroup, 30));
};


/**
 * @param {!Array<!proto.common.SlipGroup>} value
 * @return {!proto.odds.GetOddsRequest} returns this
*/
proto.odds.GetOddsRequest.prototype.setSlipGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.common.SlipGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SlipGroup}
 */
proto.odds.GetOddsRequest.prototype.addSlipGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.common.SlipGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetOddsRequest} returns this
 */
proto.odds.GetOddsRequest.prototype.clearSlipGroupsList = function() {
  return this.setSlipGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetFastOddsRequest.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetFastOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetFastOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetFastOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetFastOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipGroupsList: jspb.Message.toObjectList(msg.getSlipGroupsList(),
    proto_common_common_pb.FastSlipGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetFastOddsRequest}
 */
proto.odds.GetFastOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetFastOddsRequest;
  return proto.odds.GetFastOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetFastOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetFastOddsRequest}
 */
proto.odds.GetFastOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 30:
      var value = new proto_common_common_pb.FastSlipGroup;
      reader.readMessage(value,proto_common_common_pb.FastSlipGroup.deserializeBinaryFromReader);
      msg.addSlipGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetFastOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetFastOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetFastOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetFastOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto_common_common_pb.FastSlipGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated common.FastSlipGroup slip_groups = 30;
 * @return {!Array<!proto.common.FastSlipGroup>}
 */
proto.odds.GetFastOddsRequest.prototype.getSlipGroupsList = function() {
  return /** @type{!Array<!proto.common.FastSlipGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_common_pb.FastSlipGroup, 30));
};


/**
 * @param {!Array<!proto.common.FastSlipGroup>} value
 * @return {!proto.odds.GetFastOddsRequest} returns this
*/
proto.odds.GetFastOddsRequest.prototype.setSlipGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.common.FastSlipGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FastSlipGroup}
 */
proto.odds.GetFastOddsRequest.prototype.addSlipGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.common.FastSlipGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetFastOddsRequest} returns this
 */
proto.odds.GetFastOddsRequest.prototype.clearSlipGroupsList = function() {
  return this.setSlipGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.ToggleFavoriteRequest.repeatedFields_ = [41];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ToggleFavoriteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ToggleFavoriteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ToggleFavoriteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ToggleFavoriteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionIdsList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ToggleFavoriteRequest}
 */
proto.odds.ToggleFavoriteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ToggleFavoriteRequest;
  return proto.odds.ToggleFavoriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ToggleFavoriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ToggleFavoriteRequest}
 */
proto.odds.ToggleFavoriteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 41:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompetitionIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ToggleFavoriteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ToggleFavoriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ToggleFavoriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ToggleFavoriteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      41,
      f
    );
  }
};


/**
 * repeated int32 competition_ids = 41;
 * @return {!Array<number>}
 */
proto.odds.ToggleFavoriteRequest.prototype.getCompetitionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.ToggleFavoriteRequest} returns this
 */
proto.odds.ToggleFavoriteRequest.prototype.setCompetitionIdsList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.ToggleFavoriteRequest} returns this
 */
proto.odds.ToggleFavoriteRequest.prototype.addCompetitionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ToggleFavoriteRequest} returns this
 */
proto.odds.ToggleFavoriteRequest.prototype.clearCompetitionIdsList = function() {
  return this.setCompetitionIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PublishEventEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PublishEventEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PublishEventEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PublishEventEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    liveProviderId: jspb.Message.getFieldWithDefault(msg, 111, 0),
    prematchProviderId: jspb.Message.getFieldWithDefault(msg, 112, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PublishEventEvent}
 */
proto.odds.PublishEventEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PublishEventEvent;
  return proto.odds.PublishEventEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PublishEventEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PublishEventEvent}
 */
proto.odds.PublishEventEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 111:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveProviderId(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PublishEventEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PublishEventEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PublishEventEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PublishEventEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLiveProviderId();
  if (f !== 0) {
    writer.writeInt32(
      111,
      f
    );
  }
  f = message.getPrematchProviderId();
  if (f !== 0) {
    writer.writeInt32(
      112,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.PublishEventEvent.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PublishEventEvent} returns this
 */
proto.odds.PublishEventEvent.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 live_provider_id = 111;
 * @return {number}
 */
proto.odds.PublishEventEvent.prototype.getLiveProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 111, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PublishEventEvent} returns this
 */
proto.odds.PublishEventEvent.prototype.setLiveProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 111, value);
};


/**
 * optional int32 prematch_provider_id = 112;
 * @return {number}
 */
proto.odds.PublishEventEvent.prototype.getPrematchProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 112, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PublishEventEvent} returns this
 */
proto.odds.PublishEventEvent.prototype.setPrematchProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 112, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.PublishEventsBoRequest.repeatedFields_ = [39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PublishEventsBoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PublishEventsBoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PublishEventsBoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PublishEventsBoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.PublishEventEvent.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 93, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PublishEventsBoRequest}
 */
proto.odds.PublishEventsBoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PublishEventsBoRequest;
  return proto.odds.PublishEventsBoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PublishEventsBoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PublishEventsBoRequest}
 */
proto.odds.PublishEventsBoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 39:
      var value = new proto.odds.PublishEventEvent;
      reader.readMessage(value,proto.odds.PublishEventEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PublishEventsBoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PublishEventsBoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PublishEventsBoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PublishEventsBoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      39,
      f,
      proto.odds.PublishEventEvent.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
};


/**
 * repeated PublishEventEvent events = 39;
 * @return {!Array<!proto.odds.PublishEventEvent>}
 */
proto.odds.PublishEventsBoRequest.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.PublishEventEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PublishEventEvent, 39));
};


/**
 * @param {!Array<!proto.odds.PublishEventEvent>} value
 * @return {!proto.odds.PublishEventsBoRequest} returns this
*/
proto.odds.PublishEventsBoRequest.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 39, value);
};


/**
 * @param {!proto.odds.PublishEventEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PublishEventEvent}
 */
proto.odds.PublishEventsBoRequest.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 39, opt_value, proto.odds.PublishEventEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.PublishEventsBoRequest} returns this
 */
proto.odds.PublishEventsBoRequest.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.PublishEventsBoRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PublishEventsBoRequest} returns this
 */
proto.odds.PublishEventsBoRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.PublishEventsBoResponse.repeatedFields_ = [39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PublishEventsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PublishEventsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PublishEventsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PublishEventsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.PublishEventEvent.toObject, includeInstance),
    numberOfPublished: jspb.Message.getFieldWithDefault(msg, 220, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PublishEventsBoResponse}
 */
proto.odds.PublishEventsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PublishEventsBoResponse;
  return proto.odds.PublishEventsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PublishEventsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PublishEventsBoResponse}
 */
proto.odds.PublishEventsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 39:
      var value = new proto.odds.PublishEventEvent;
      reader.readMessage(value,proto.odds.PublishEventEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 220:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfPublished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PublishEventsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PublishEventsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PublishEventsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PublishEventsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      39,
      f,
      proto.odds.PublishEventEvent.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfPublished();
  if (f !== 0) {
    writer.writeInt32(
      220,
      f
    );
  }
};


/**
 * repeated PublishEventEvent events = 39;
 * @return {!Array<!proto.odds.PublishEventEvent>}
 */
proto.odds.PublishEventsBoResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.PublishEventEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PublishEventEvent, 39));
};


/**
 * @param {!Array<!proto.odds.PublishEventEvent>} value
 * @return {!proto.odds.PublishEventsBoResponse} returns this
*/
proto.odds.PublishEventsBoResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 39, value);
};


/**
 * @param {!proto.odds.PublishEventEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PublishEventEvent}
 */
proto.odds.PublishEventsBoResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 39, opt_value, proto.odds.PublishEventEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.PublishEventsBoResponse} returns this
 */
proto.odds.PublishEventsBoResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int32 number_of_published = 220;
 * @return {number}
 */
proto.odds.PublishEventsBoResponse.prototype.getNumberOfPublished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 220, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PublishEventsBoResponse} returns this
 */
proto.odds.PublishEventsBoResponse.prototype.setNumberOfPublished = function(value) {
  return jspb.Message.setProto3IntField(this, 220, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Event.repeatedFields_ = [181,237,38,44];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 154, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 108, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.EventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endBettingTime: (f = msg.getEndBettingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isPublished: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    manualDisable: jspb.Message.getBooleanFieldWithDefault(msg, 192, false),
    manualDisableReason: jspb.Message.getFieldWithDefault(msg, 330, ""),
    omName: jspb.Message.getFieldWithDefault(msg, 331, ""),
    exeName: jspb.Message.getFieldWithDefault(msg, 332, ""),
    isSuspicious: jspb.Message.getBooleanFieldWithDefault(msg, 176, false),
    isSingleApproval: jspb.Message.getBooleanFieldWithDefault(msg, 178, false),
    suspiciousLevel: (f = msg.getSuspiciousLevel()) && proto.odds.SuspiciousLevel.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 80, ""),
    landbaseCodesList: (f = jspb.Message.getRepeatedField(msg, 237)) == null ? undefined : f,
    newLandbaseCode: jspb.Message.getFieldWithDefault(msg, 226, ""),
    type: jspb.Message.getFieldWithDefault(msg, 93, ""),
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.odds.Provider.toObject, includeInstance),
    liveProviderId: jspb.Message.getFieldWithDefault(msg, 111, 0),
    prematchProviderId: jspb.Message.getFieldWithDefault(msg, 112, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f),
    outrightType: jspb.Message.getFieldWithDefault(msg, 132, ""),
    competitorsList: jspb.Message.toObjectList(msg.getCompetitorsList(),
    proto.odds.Competitor.toObject, includeInstance),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f),
    hasResult: jspb.Message.getBooleanFieldWithDefault(msg, 146, false),
    canBeLinked: jspb.Message.getBooleanFieldWithDefault(msg, 147, false),
    numOfSlips: jspb.Message.getFieldWithDefault(msg, 151, 0),
    numUnresolvedOdds: jspb.Message.getFieldWithDefault(msg, 166, 0),
    numOfOdds: jspb.Message.getFieldWithDefault(msg, 169, 0),
    finalResult: jspb.Message.getFieldWithDefault(msg, 167, ""),
    note: jspb.Message.getFieldWithDefault(msg, 173, ""),
    factoryCompetitorName: jspb.Message.getFieldWithDefault(msg, 96, ""),
    factoryPlayerName: jspb.Message.getFieldWithDefault(msg, 104, ""),
    minutes: jspb.Message.getFieldWithDefault(msg, 187, 0),
    seconds: jspb.Message.getFieldWithDefault(msg, 188, 0),
    marketTemplateId: jspb.Message.getFieldWithDefault(msg, 224, 0),
    canFinish: jspb.Message.getBooleanFieldWithDefault(msg, 275, false),
    liveEventName: jspb.Message.getFieldWithDefault(msg, 300, ""),
    liveEventStart: (f = msg.getLiveEventStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isBlocked: jspb.Message.getBooleanFieldWithDefault(msg, 302, false),
    blockReason: jspb.Message.getFieldWithDefault(msg, 303, ""),
    autoLink: jspb.Message.getBooleanFieldWithDefault(msg, 304, false),
    timeCancelTimestamp: (f = msg.getTimeCancelTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    exefeedPrematch: jspb.Message.getBooleanFieldWithDefault(msg, 334, false),
    resultFormat: jspb.Message.getFieldWithDefault(msg, 335, ""),
    providerEventId: jspb.Message.getFieldWithDefault(msg, 336, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Event}
 */
proto.odds.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Event;
  return proto.odds.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Event}
 */
proto.odds.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 154:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 181:
      var value = new proto.odds.EventOdd;
      reader.readMessage(value,proto.odds.EventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 140:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndBettingTime(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublished(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 192:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualDisable(value);
      break;
    case 330:
      var value = /** @type {string} */ (reader.readString());
      msg.setManualDisableReason(value);
      break;
    case 331:
      var value = /** @type {string} */ (reader.readString());
      msg.setOmName(value);
      break;
    case 332:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeName(value);
      break;
    case 176:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspicious(value);
      break;
    case 178:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleApproval(value);
      break;
    case 174:
      var value = new proto.odds.SuspiciousLevel;
      reader.readMessage(value,proto.odds.SuspiciousLevel.deserializeBinaryFromReader);
      msg.setSuspiciousLevel(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 237:
      var value = /** @type {string} */ (reader.readString());
      msg.addLandbaseCodes(value);
      break;
    case 226:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewLandbaseCode(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 38:
      var value = new proto.odds.Provider;
      reader.readMessage(value,proto.odds.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 111:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveProviderId(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchProviderId(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 132:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 44:
      var value = new proto.odds.Competitor;
      reader.readMessage(value,proto.odds.Competitor.deserializeBinaryFromReader);
      msg.addCompetitors(value);
      break;
    case 145:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 146:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasResult(value);
      break;
    case 147:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeLinked(value);
      break;
    case 151:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfSlips(value);
      break;
    case 166:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumUnresolvedOdds(value);
      break;
    case 169:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfOdds(value);
      break;
    case 167:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalResult(value);
      break;
    case 173:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryCompetitorName(value);
      break;
    case 104:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryPlayerName(value);
      break;
    case 187:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutes(value);
      break;
    case 188:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeconds(value);
      break;
    case 224:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketTemplateId(value);
      break;
    case 275:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanFinish(value);
      break;
    case 300:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveEventName(value);
      break;
    case 301:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLiveEventStart(value);
      break;
    case 302:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlocked(value);
      break;
    case 303:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockReason(value);
      break;
    case 304:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoLink(value);
      break;
    case 333:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeCancelTimestamp(value);
      break;
    case 334:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExefeedPrematch(value);
      break;
    case 335:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultFormat(value);
      break;
    case 336:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      154,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      181,
      f,
      proto.odds.EventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndBettingTime();
  if (f != null) {
    writer.writeMessage(
      140,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsPublished();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getManualDisable();
  if (f) {
    writer.writeBool(
      192,
      f
    );
  }
  f = message.getManualDisableReason();
  if (f.length > 0) {
    writer.writeString(
      330,
      f
    );
  }
  f = message.getOmName();
  if (f.length > 0) {
    writer.writeString(
      331,
      f
    );
  }
  f = message.getExeName();
  if (f.length > 0) {
    writer.writeString(
      332,
      f
    );
  }
  f = message.getIsSuspicious();
  if (f) {
    writer.writeBool(
      176,
      f
    );
  }
  f = message.getIsSingleApproval();
  if (f) {
    writer.writeBool(
      178,
      f
    );
  }
  f = message.getSuspiciousLevel();
  if (f != null) {
    writer.writeMessage(
      174,
      f,
      proto.odds.SuspiciousLevel.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getLandbaseCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      237,
      f
    );
  }
  f = message.getNewLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      226,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.odds.Provider.serializeBinaryToWriter
    );
  }
  f = message.getLiveProviderId();
  if (f !== 0) {
    writer.writeInt32(
      111,
      f
    );
  }
  f = message.getPrematchProviderId();
  if (f !== 0) {
    writer.writeInt32(
      112,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      132,
      f
    );
  }
  f = message.getCompetitorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.odds.Competitor.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      145,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getHasResult();
  if (f) {
    writer.writeBool(
      146,
      f
    );
  }
  f = message.getCanBeLinked();
  if (f) {
    writer.writeBool(
      147,
      f
    );
  }
  f = message.getNumOfSlips();
  if (f !== 0) {
    writer.writeInt32(
      151,
      f
    );
  }
  f = message.getNumUnresolvedOdds();
  if (f !== 0) {
    writer.writeInt32(
      166,
      f
    );
  }
  f = message.getNumOfOdds();
  if (f !== 0) {
    writer.writeInt32(
      169,
      f
    );
  }
  f = message.getFinalResult();
  if (f.length > 0) {
    writer.writeString(
      167,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      173,
      f
    );
  }
  f = message.getFactoryCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getFactoryPlayerName();
  if (f.length > 0) {
    writer.writeString(
      104,
      f
    );
  }
  f = message.getMinutes();
  if (f !== 0) {
    writer.writeInt32(
      187,
      f
    );
  }
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt32(
      188,
      f
    );
  }
  f = message.getMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      224,
      f
    );
  }
  f = message.getCanFinish();
  if (f) {
    writer.writeBool(
      275,
      f
    );
  }
  f = message.getLiveEventName();
  if (f.length > 0) {
    writer.writeString(
      300,
      f
    );
  }
  f = message.getLiveEventStart();
  if (f != null) {
    writer.writeMessage(
      301,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsBlocked();
  if (f) {
    writer.writeBool(
      302,
      f
    );
  }
  f = message.getBlockReason();
  if (f.length > 0) {
    writer.writeString(
      303,
      f
    );
  }
  f = message.getAutoLink();
  if (f) {
    writer.writeBool(
      304,
      f
    );
  }
  f = message.getTimeCancelTimestamp();
  if (f != null) {
    writer.writeMessage(
      333,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExefeedPrematch();
  if (f) {
    writer.writeBool(
      334,
      f
    );
  }
  f = message.getResultFormat();
  if (f.length > 0) {
    writer.writeString(
      335,
      f
    );
  }
  f = message.getProviderEventId();
  if (f !== 0) {
    writer.writeInt32(
      336,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.Event.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Event.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alias = 3;
 * @return {string}
 */
proto.odds.Event.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.Event.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string competition_name = 154;
 * @return {string}
 */
proto.odds.Event.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 154, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 154, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.Event.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * optional string location_name = 108;
 * @return {string}
 */
proto.odds.Event.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.Event.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated EventOdd odds = 181;
 * @return {!Array<!proto.odds.EventOdd>}
 */
proto.odds.Event.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.EventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.EventOdd, 181));
};


/**
 * @param {!Array<!proto.odds.EventOdd>} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 181, value);
};


/**
 * @param {!proto.odds.EventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.EventOdd}
 */
proto.odds.Event.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 181, opt_value, proto.odds.EventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Event.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasStart = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp end_betting_time = 140;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Event.prototype.getEndBettingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 140));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setEndBettingTime = function(value) {
  return jspb.Message.setWrapperField(this, 140, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearEndBettingTime = function() {
  return this.setEndBettingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasEndBettingTime = function() {
  return jspb.Message.getField(this, 140) != null;
};


/**
 * optional bool is_published = 43;
 * @return {boolean}
 */
proto.odds.Event.prototype.getIsPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIsPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool is_disabled = 136;
 * @return {boolean}
 */
proto.odds.Event.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional bool manual_disable = 192;
 * @return {boolean}
 */
proto.odds.Event.prototype.getManualDisable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 192, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setManualDisable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 192, value);
};


/**
 * optional string manual_disable_reason = 330;
 * @return {string}
 */
proto.odds.Event.prototype.getManualDisableReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 330, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setManualDisableReason = function(value) {
  return jspb.Message.setProto3StringField(this, 330, value);
};


/**
 * optional string om_name = 331;
 * @return {string}
 */
proto.odds.Event.prototype.getOmName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 331, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setOmName = function(value) {
  return jspb.Message.setProto3StringField(this, 331, value);
};


/**
 * optional string exe_name = 332;
 * @return {string}
 */
proto.odds.Event.prototype.getExeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 332, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setExeName = function(value) {
  return jspb.Message.setProto3StringField(this, 332, value);
};


/**
 * optional bool is_suspicious = 176;
 * @return {boolean}
 */
proto.odds.Event.prototype.getIsSuspicious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 176, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIsSuspicious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 176, value);
};


/**
 * optional bool is_single_approval = 178;
 * @return {boolean}
 */
proto.odds.Event.prototype.getIsSingleApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 178, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIsSingleApproval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 178, value);
};


/**
 * optional SuspiciousLevel suspicious_level = 174;
 * @return {?proto.odds.SuspiciousLevel}
 */
proto.odds.Event.prototype.getSuspiciousLevel = function() {
  return /** @type{?proto.odds.SuspiciousLevel} */ (
    jspb.Message.getWrapperField(this, proto.odds.SuspiciousLevel, 174));
};


/**
 * @param {?proto.odds.SuspiciousLevel|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setSuspiciousLevel = function(value) {
  return jspb.Message.setWrapperField(this, 174, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearSuspiciousLevel = function() {
  return this.setSuspiciousLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasSuspiciousLevel = function() {
  return jspb.Message.getField(this, 174) != null;
};


/**
 * optional string landbase_code = 80;
 * @return {string}
 */
proto.odds.Event.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * repeated string landbase_codes = 237;
 * @return {!Array<string>}
 */
proto.odds.Event.prototype.getLandbaseCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 237));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLandbaseCodesList = function(value) {
  return jspb.Message.setField(this, 237, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.addLandbaseCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 237, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearLandbaseCodesList = function() {
  return this.setLandbaseCodesList([]);
};


/**
 * optional string new_landbase_code = 226;
 * @return {string}
 */
proto.odds.Event.prototype.getNewLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 226, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setNewLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 226, value);
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.Event.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * repeated Provider providers = 38;
 * @return {!Array<!proto.odds.Provider>}
 */
proto.odds.Event.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.odds.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Provider, 38));
};


/**
 * @param {!Array<!proto.odds.Provider>} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.odds.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Provider}
 */
proto.odds.Event.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.odds.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional int32 live_provider_id = 111;
 * @return {number}
 */
proto.odds.Event.prototype.getLiveProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 111, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLiveProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 111, value);
};


/**
 * optional int32 prematch_provider_id = 112;
 * @return {number}
 */
proto.odds.Event.prototype.getPrematchProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 112, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setPrematchProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 112, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.Event.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional string outright_type = 132;
 * @return {string}
 */
proto.odds.Event.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 132, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 132, value);
};


/**
 * repeated Competitor competitors = 44;
 * @return {!Array<!proto.odds.Competitor>}
 */
proto.odds.Event.prototype.getCompetitorsList = function() {
  return /** @type{!Array<!proto.odds.Competitor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competitor, 44));
};


/**
 * @param {!Array<!proto.odds.Competitor>} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setCompetitorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.odds.Competitor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competitor}
 */
proto.odds.Event.prototype.addCompetitors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.odds.Competitor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearCompetitorsList = function() {
  return this.setCompetitorsList([]);
};


/**
 * optional common.EventResult result = 145;
 * @return {?proto.common.EventResult}
 */
proto.odds.Event.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 145));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 145, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasResult = function() {
  return jspb.Message.getField(this, 145) != null;
};


/**
 * optional bool has_result = 146;
 * @return {boolean}
 */
proto.odds.Event.prototype.getHasResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 146, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setHasResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 146, value);
};


/**
 * optional bool can_be_linked = 147;
 * @return {boolean}
 */
proto.odds.Event.prototype.getCanBeLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 147, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setCanBeLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 147, value);
};


/**
 * optional int32 num_of_slips = 151;
 * @return {number}
 */
proto.odds.Event.prototype.getNumOfSlips = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 151, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setNumOfSlips = function(value) {
  return jspb.Message.setProto3IntField(this, 151, value);
};


/**
 * optional int32 num_unresolved_odds = 166;
 * @return {number}
 */
proto.odds.Event.prototype.getNumUnresolvedOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 166, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setNumUnresolvedOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 166, value);
};


/**
 * optional int32 num_of_odds = 169;
 * @return {number}
 */
proto.odds.Event.prototype.getNumOfOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 169, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setNumOfOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 169, value);
};


/**
 * optional string final_result = 167;
 * @return {string}
 */
proto.odds.Event.prototype.getFinalResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 167, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setFinalResult = function(value) {
  return jspb.Message.setProto3StringField(this, 167, value);
};


/**
 * optional string note = 173;
 * @return {string}
 */
proto.odds.Event.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 173, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 173, value);
};


/**
 * optional string factory_competitor_name = 96;
 * @return {string}
 */
proto.odds.Event.prototype.getFactoryCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setFactoryCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional string factory_player_name = 104;
 * @return {string}
 */
proto.odds.Event.prototype.getFactoryPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 104, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setFactoryPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 104, value);
};


/**
 * optional int32 minutes = 187;
 * @return {number}
 */
proto.odds.Event.prototype.getMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 187, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 187, value);
};


/**
 * optional int32 seconds = 188;
 * @return {number}
 */
proto.odds.Event.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 188, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 188, value);
};


/**
 * optional int32 market_template_id = 224;
 * @return {number}
 */
proto.odds.Event.prototype.getMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 224, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 224, value);
};


/**
 * optional bool can_finish = 275;
 * @return {boolean}
 */
proto.odds.Event.prototype.getCanFinish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 275, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setCanFinish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 275, value);
};


/**
 * optional string live_event_name = 300;
 * @return {string}
 */
proto.odds.Event.prototype.getLiveEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 300, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLiveEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 300, value);
};


/**
 * optional google.protobuf.Timestamp live_event_start = 301;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Event.prototype.getLiveEventStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 301));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setLiveEventStart = function(value) {
  return jspb.Message.setWrapperField(this, 301, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearLiveEventStart = function() {
  return this.setLiveEventStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasLiveEventStart = function() {
  return jspb.Message.getField(this, 301) != null;
};


/**
 * optional bool is_blocked = 302;
 * @return {boolean}
 */
proto.odds.Event.prototype.getIsBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 302, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIsBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 302, value);
};


/**
 * optional string block_reason = 303;
 * @return {string}
 */
proto.odds.Event.prototype.getBlockReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 303, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setBlockReason = function(value) {
  return jspb.Message.setProto3StringField(this, 303, value);
};


/**
 * optional bool auto_link = 304;
 * @return {boolean}
 */
proto.odds.Event.prototype.getAutoLink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 304, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setAutoLink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 304, value);
};


/**
 * optional google.protobuf.Timestamp time_cancel_timestamp = 333;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Event.prototype.getTimeCancelTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 333));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setTimeCancelTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 333, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearTimeCancelTimestamp = function() {
  return this.setTimeCancelTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasTimeCancelTimestamp = function() {
  return jspb.Message.getField(this, 333) != null;
};


/**
 * optional bool exefeed_prematch = 334;
 * @return {boolean}
 */
proto.odds.Event.prototype.getExefeedPrematch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 334, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setExefeedPrematch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 334, value);
};


/**
 * optional string result_format = 335;
 * @return {string}
 */
proto.odds.Event.prototype.getResultFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 335, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setResultFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 335, value);
};


/**
 * optional int32 provider_event_id = 336;
 * @return {number}
 */
proto.odds.Event.prototype.getProviderEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 336, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setProviderEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 336, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseCodeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseCodeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseCodeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCodeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto.odds.StreamEvent.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseCodeEvent}
 */
proto.odds.LandBaseCodeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseCodeEvent;
  return proto.odds.LandBaseCodeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseCodeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseCodeEvent}
 */
proto.odds.LandBaseCodeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseCodeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseCodeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseCodeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseCodeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional StreamEvent event = 1;
 * @return {?proto.odds.StreamEvent}
 */
proto.odds.LandBaseCodeEvent.prototype.getEvent = function() {
  return /** @type{?proto.odds.StreamEvent} */ (
    jspb.Message.getWrapperField(this, proto.odds.StreamEvent, 1));
};


/**
 * @param {?proto.odds.StreamEvent|undefined} value
 * @return {!proto.odds.LandBaseCodeEvent} returns this
*/
proto.odds.LandBaseCodeEvent.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseCodeEvent} returns this
 */
proto.odds.LandBaseCodeEvent.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseCodeEvent.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.Error error = 2;
 * @return {?proto.common.Error}
 */
proto.odds.LandBaseCodeEvent.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 2));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.LandBaseCodeEvent} returns this
*/
proto.odds.LandBaseCodeEvent.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseCodeEvent} returns this
 */
proto.odds.LandBaseCodeEvent.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseCodeEvent.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.StreamEvent.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.StreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.StreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.StreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.StreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.StreamEventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isSuspicious: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isSingleApproval: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    profile: jspb.Message.getFieldWithDefault(msg, 12, ""),
    currentPhase: (f = msg.getCurrentPhase()) && proto_common_common_pb.EventCurrentPhase.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    type: jspb.Message.getFieldWithDefault(msg, 15, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 17, 0),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.StreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.StreamEvent;
  return proto.odds.StreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.StreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.StreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 7:
      var value = new proto.odds.StreamEventOdd;
      reader.readMessage(value,proto.odds.StreamEventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspicious(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleApproval(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 13:
      var value = new proto_common_common_pb.EventCurrentPhase;
      reader.readMessage(value,proto_common_common_pb.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 18:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.StreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.StreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.StreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.StreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.odds.StreamEventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsSuspicious();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsSingleApproval();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_common_common_pb.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.StreamEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 5;
 * @return {number}
 */
proto.odds.StreamEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sport_id = 6;
 * @return {number}
 */
proto.odds.StreamEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated StreamEventOdd odds = 7;
 * @return {!Array<!proto.odds.StreamEventOdd>}
 */
proto.odds.StreamEvent.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.StreamEventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEventOdd, 7));
};


/**
 * @param {!Array<!proto.odds.StreamEventOdd>} value
 * @return {!proto.odds.StreamEvent} returns this
*/
proto.odds.StreamEvent.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.odds.StreamEventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEventOdd}
 */
proto.odds.StreamEvent.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.odds.StreamEventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.StreamEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.StreamEvent} returns this
*/
proto.odds.StreamEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.StreamEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_disabled = 9;
 * @return {boolean}
 */
proto.odds.StreamEvent.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_suspicious = 10;
 * @return {boolean}
 */
proto.odds.StreamEvent.prototype.getIsSuspicious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setIsSuspicious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_single_approval = 11;
 * @return {boolean}
 */
proto.odds.StreamEvent.prototype.getIsSingleApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setIsSingleApproval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string profile = 12;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional common.EventCurrentPhase current_phase = 13;
 * @return {?proto.common.EventCurrentPhase}
 */
proto.odds.StreamEvent.prototype.getCurrentPhase = function() {
  return /** @type{?proto.common.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventCurrentPhase, 13));
};


/**
 * @param {?proto.common.EventCurrentPhase|undefined} value
 * @return {!proto.odds.StreamEvent} returns this
*/
proto.odds.StreamEvent.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.StreamEvent.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string landbase_code = 14;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string type = 15;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string outright_type = 16;
 * @return {string}
 */
proto.odds.StreamEvent.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 mon = 17;
 * @return {number}
 */
proto.odds.StreamEvent.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional common.EventResult result = 18;
 * @return {?proto.common.EventResult}
 */
proto.odds.StreamEvent.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 18));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.odds.StreamEvent} returns this
*/
proto.odds.StreamEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.StreamEvent} returns this
 */
proto.odds.StreamEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.StreamEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.StreamEventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.StreamEventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.StreamEventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.StreamEventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 122, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 238, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 239, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.StreamEventOdd}
 */
proto.odds.StreamEventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.StreamEventOdd;
  return proto.odds.StreamEventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.StreamEventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.StreamEventOdd}
 */
proto.odds.StreamEventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 122:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 238:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 239:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.StreamEventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.StreamEventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.StreamEventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.StreamEventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      122,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      238,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      239,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.StreamEventOdd.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 8;
 * @return {number}
 */
proto.odds.StreamEventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 outcome_id = 11;
 * @return {number}
 */
proto.odds.StreamEventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double limit = 122;
 * @return {number}
 */
proto.odds.StreamEventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 122, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 122, value);
};


/**
 * optional int32 frame_number = 238;
 * @return {number}
 */
proto.odds.StreamEventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 238, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 238, value);
};


/**
 * optional string current_result = 239;
 * @return {string}
 */
proto.odds.StreamEventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 239, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 239, value);
};


/**
 * optional bool is_disabled = 136;
 * @return {boolean}
 */
proto.odds.StreamEventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.odds.StreamEventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.StreamEventOdd} returns this
 */
proto.odds.StreamEventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CreateEventRequest.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CreateEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CreateEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CreateEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 93, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.Outcome.toObject, includeInstance),
    outrightType: jspb.Message.getFieldWithDefault(msg, 132, ""),
    endBettingTime: (f = msg.getEndBettingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CreateEventRequest}
 */
proto.odds.CreateEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CreateEventRequest;
  return proto.odds.CreateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CreateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CreateEventRequest}
 */
proto.odds.CreateEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 27:
      var value = new proto.odds.Outcome;
      reader.readMessage(value,proto.odds.Outcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    case 132:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 140:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndBettingTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CreateEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CreateEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CreateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.odds.Outcome.serializeBinaryToWriter
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      132,
      f
    );
  }
  f = message.getEndBettingTime();
  if (f != null) {
    writer.writeMessage(
      140,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.CreateEventRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.CreateEventRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.CreateEventRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.CreateEventRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Outcome outcomes = 27;
 * @return {!Array<!proto.odds.Outcome>}
 */
proto.odds.CreateEventRequest.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.Outcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Outcome, 27));
};


/**
 * @param {!Array<!proto.odds.Outcome>} value
 * @return {!proto.odds.CreateEventRequest} returns this
*/
proto.odds.CreateEventRequest.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.odds.Outcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Outcome}
 */
proto.odds.CreateEventRequest.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.odds.Outcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};


/**
 * optional string outright_type = 132;
 * @return {string}
 */
proto.odds.CreateEventRequest.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 132, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 132, value);
};


/**
 * optional google.protobuf.Timestamp end_betting_time = 140;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CreateEventRequest.prototype.getEndBettingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 140));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CreateEventRequest} returns this
*/
proto.odds.CreateEventRequest.prototype.setEndBettingTime = function(value) {
  return jspb.Message.setWrapperField(this, 140, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CreateEventRequest} returns this
 */
proto.odds.CreateEventRequest.prototype.clearEndBettingTime = function() {
  return this.setEndBettingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CreateEventRequest.prototype.hasEndBettingTime = function() {
  return jspb.Message.getField(this, 140) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventFwwRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventFwwRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventFwwRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventFwwRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    start: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventFwwRequest}
 */
proto.odds.EventFwwRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventFwwRequest;
  return proto.odds.EventFwwRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventFwwRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventFwwRequest}
 */
proto.odds.EventFwwRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventFwwRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventFwwRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventFwwRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventFwwRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.odds.EventFwwRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventFwwRequest} returns this
 */
proto.odds.EventFwwRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.EventFwwRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventFwwRequest} returns this
 */
proto.odds.EventFwwRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_id = 3;
 * @return {string}
 */
proto.odds.EventFwwRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventFwwRequest} returns this
 */
proto.odds.EventFwwRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.EventFwwRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventFwwRequest} returns this
 */
proto.odds.EventFwwRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 start = 5;
 * @return {number}
 */
proto.odds.EventFwwRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventFwwRequest} returns this
 */
proto.odds.EventFwwRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddFwwRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddFwwRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddFwwRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddFwwRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    prematchOdd: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    eventId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddFwwRequest}
 */
proto.odds.OddFwwRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddFwwRequest;
  return proto.odds.OddFwwRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddFwwRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddFwwRequest}
 */
proto.odds.OddFwwRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOddId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrematchOdd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddFwwRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddFwwRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddFwwRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddFwwRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPrematchOdd();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string odd_id = 11;
 * @return {string}
 */
proto.odds.OddFwwRequest.prototype.getOddId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddFwwRequest} returns this
 */
proto.odds.OddFwwRequest.prototype.setOddId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double limit = 1;
 * @return {number}
 */
proto.odds.OddFwwRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddFwwRequest} returns this
 */
proto.odds.OddFwwRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double prematch_odd = 2;
 * @return {number}
 */
proto.odds.OddFwwRequest.prototype.getPrematchOdd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddFwwRequest} returns this
 */
proto.odds.OddFwwRequest.prototype.setPrematchOdd = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string event_id = 3;
 * @return {string}
 */
proto.odds.OddFwwRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddFwwRequest} returns this
 */
proto.odds.OddFwwRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 outcome_id = 4;
 * @return {number}
 */
proto.odds.OddFwwRequest.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddFwwRequest} returns this
 */
proto.odds.OddFwwRequest.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CompetitionFwwRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CompetitionFwwRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CompetitionFwwRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionFwwRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    paSportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CompetitionFwwRequest}
 */
proto.odds.CompetitionFwwRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CompetitionFwwRequest;
  return proto.odds.CompetitionFwwRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CompetitionFwwRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CompetitionFwwRequest}
 */
proto.odds.CompetitionFwwRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CompetitionFwwRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CompetitionFwwRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CompetitionFwwRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionFwwRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPaSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPaType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.odds.CompetitionFwwRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionFwwRequest} returns this
 */
proto.odds.CompetitionFwwRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 location_id = 2;
 * @return {number}
 */
proto.odds.CompetitionFwwRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionFwwRequest} returns this
 */
proto.odds.CompetitionFwwRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.odds.CompetitionFwwRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionFwwRequest} returns this
 */
proto.odds.CompetitionFwwRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 pa_sport_id = 4;
 * @return {number}
 */
proto.odds.CompetitionFwwRequest.prototype.getPaSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionFwwRequest} returns this
 */
proto.odds.CompetitionFwwRequest.prototype.setPaSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string pa_type = 5;
 * @return {string}
 */
proto.odds.CompetitionFwwRequest.prototype.getPaType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionFwwRequest} returns this
 */
proto.odds.CompetitionFwwRequest.prototype.setPaType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    marketId: jspb.Message.getFieldWithDefault(msg, 94, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 122, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 238, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 239, ""),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 85, ""),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 191, ""),
    marketName: jspb.Message.getFieldWithDefault(msg, 84, ""),
    factoryCompetitorId: jspb.Message.getFieldWithDefault(msg, 83, 0),
    factoryCompetitorName: jspb.Message.getFieldWithDefault(msg, 96, ""),
    status: jspb.Message.getFieldWithDefault(msg, 91, ""),
    numOfSlips: jspb.Message.getFieldWithDefault(msg, 151, 0),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 80, ""),
    outcomeCode: jspb.Message.getFieldWithDefault(msg, 81, ""),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventOdd}
 */
proto.odds.EventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventOdd;
  return proto.odds.EventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventOdd}
 */
proto.odds.EventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 94:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 122:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 238:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 239:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    case 191:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 84:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitorId(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryCompetitorName(value);
      break;
    case 91:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 151:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfSlips(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      94,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      122,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      238,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      239,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      191,
      f
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      84,
      f
    );
  }
  f = message.getFactoryCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      83,
      f
    );
  }
  f = message.getFactoryCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      91,
      f
    );
  }
  f = message.getNumOfSlips();
  if (f !== 0) {
    writer.writeInt32(
      151,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getOutcomeCode();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 8;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 outcome_id = 11;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 market_id = 94;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 94, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 94, value);
};


/**
 * optional double limit = 122;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 122, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 122, value);
};


/**
 * optional int32 frame_number = 238;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 238, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 238, value);
};


/**
 * optional string current_result = 239;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 239, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 239, value);
};


/**
 * optional string outcome_name = 85;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional string outcome_group = 191;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 191, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 191, value);
};


/**
 * optional string market_name = 84;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 84, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 84, value);
};


/**
 * optional int32 factory_competitor_id = 83;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getFactoryCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setFactoryCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};


/**
 * optional string factory_competitor_name = 96;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getFactoryCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setFactoryCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional string status = 91;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 91, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 91, value);
};


/**
 * optional int32 num_of_slips = 151;
 * @return {number}
 */
proto.odds.EventOdd.prototype.getNumOfSlips = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 151, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setNumOfSlips = function(value) {
  return jspb.Message.setProto3IntField(this, 151, value);
};


/**
 * optional bool is_disabled = 136;
 * @return {boolean}
 */
proto.odds.EventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional string landbase_code = 80;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string outcome_code = 81;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getOutcomeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setOutcomeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.odds.EventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventOdd} returns this
 */
proto.odds.EventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MarketGroup.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 55, 0),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.Market.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketGroup}
 */
proto.odds.MarketGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketGroup;
  return proto.odds.MarketGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketGroup}
 */
proto.odds.MarketGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = new proto.odds.Market;
      reader.readMessage(value,proto.odds.Market.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      55,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds.Market.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 55;
 * @return {number}
 */
proto.odds.MarketGroup.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 55, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 55, value);
};


/**
 * repeated Market markets = 6;
 * @return {!Array<!proto.odds.Market>}
 */
proto.odds.MarketGroup.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.Market>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Market, 6));
};


/**
 * @param {!Array<!proto.odds.Market>} value
 * @return {!proto.odds.MarketGroup} returns this
*/
proto.odds.MarketGroup.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds.Market=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Market}
 */
proto.odds.MarketGroup.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds.Market, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Market.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Market.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Market.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Market} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Market.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.Outcome.toObject, includeInstance),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 185, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Market}
 */
proto.odds.Market.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Market;
  return proto.odds.Market.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Market} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Market}
 */
proto.odds.Market.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 27:
      var value = new proto.odds.Outcome;
      reader.readMessage(value,proto.odds.Outcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    case 185:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Market.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Market.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Market} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Market.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.odds.Outcome.serializeBinaryToWriter
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      185,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Market.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Market.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.Market.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Outcome outcomes = 27;
 * @return {!Array<!proto.odds.Outcome>}
 */
proto.odds.Market.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.Outcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Outcome, 27));
};


/**
 * @param {!Array<!proto.odds.Outcome>} value
 * @return {!proto.odds.Market} returns this
*/
proto.odds.Market.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.odds.Outcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Outcome}
 */
proto.odds.Market.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.odds.Outcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};


/**
 * optional bool has_limit = 185;
 * @return {boolean}
 */
proto.odds.Market.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 185, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 185, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Outcome.repeatedFields_ = [98];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Outcome.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Outcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Outcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Outcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sport: (f = msg.getSport()) && proto.odds.Sport.toObject(includeInstance, f),
    market: (f = msg.getMarket()) && proto.odds.Market.toObject(includeInstance, f),
    outcomeCode: jspb.Message.getFieldWithDefault(msg, 81, ""),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 191, ""),
    outcomeTic: jspb.Message.getFieldWithDefault(msg, 274, ""),
    exeLimit: jspb.Message.getFieldWithDefault(msg, 240, ""),
    odd: (f = msg.getOdd()) && proto.odds.Odd.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 118, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 119, ""),
    factoryCompetitorId: jspb.Message.getFieldWithDefault(msg, 83, 0),
    factoryPlayerId: jspb.Message.getFieldWithDefault(msg, 102, 0),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.odds.Link.toObject, includeInstance),
    liveMarketId: jspb.Message.getFieldWithDefault(msg, 261, 0),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 279, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Outcome}
 */
proto.odds.Outcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Outcome;
  return proto.odds.Outcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Outcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Outcome}
 */
proto.odds.Outcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 120:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.setSport(value);
      break;
    case 121:
      var value = new proto.odds.Market;
      reader.readMessage(value,proto.odds.Market.deserializeBinaryFromReader);
      msg.setMarket(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeCode(value);
      break;
    case 191:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 274:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeTic(value);
      break;
    case 240:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeLimit(value);
      break;
    case 19:
      var value = new proto.odds.Odd;
      reader.readMessage(value,proto.odds.Odd.deserializeBinaryFromReader);
      msg.setOdd(value);
      break;
    case 118:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 119:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitorId(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryPlayerId(value);
      break;
    case 98:
      var value = new proto.odds.Link;
      reader.readMessage(value,proto.odds.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 261:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMarketId(value);
      break;
    case 279:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Outcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Outcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Outcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Outcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSport();
  if (f != null) {
    writer.writeMessage(
      120,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
  f = message.getMarket();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      proto.odds.Market.serializeBinaryToWriter
    );
  }
  f = message.getOutcomeCode();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      191,
      f
    );
  }
  f = message.getOutcomeTic();
  if (f.length > 0) {
    writer.writeString(
      274,
      f
    );
  }
  f = message.getExeLimit();
  if (f.length > 0) {
    writer.writeString(
      240,
      f
    );
  }
  f = message.getOdd();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.odds.Odd.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      118,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      119,
      f
    );
  }
  f = message.getFactoryCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      83,
      f
    );
  }
  f = message.getFactoryPlayerId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      98,
      f,
      proto.odds.Link.serializeBinaryToWriter
    );
  }
  f = message.getLiveMarketId();
  if (f !== 0) {
    writer.writeInt32(
      261,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      279,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Outcome.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Outcome.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Sport sport = 120;
 * @return {?proto.odds.Sport}
 */
proto.odds.Outcome.prototype.getSport = function() {
  return /** @type{?proto.odds.Sport} */ (
    jspb.Message.getWrapperField(this, proto.odds.Sport, 120));
};


/**
 * @param {?proto.odds.Sport|undefined} value
 * @return {!proto.odds.Outcome} returns this
*/
proto.odds.Outcome.prototype.setSport = function(value) {
  return jspb.Message.setWrapperField(this, 120, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.clearSport = function() {
  return this.setSport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Outcome.prototype.hasSport = function() {
  return jspb.Message.getField(this, 120) != null;
};


/**
 * optional Market market = 121;
 * @return {?proto.odds.Market}
 */
proto.odds.Outcome.prototype.getMarket = function() {
  return /** @type{?proto.odds.Market} */ (
    jspb.Message.getWrapperField(this, proto.odds.Market, 121));
};


/**
 * @param {?proto.odds.Market|undefined} value
 * @return {!proto.odds.Outcome} returns this
*/
proto.odds.Outcome.prototype.setMarket = function(value) {
  return jspb.Message.setWrapperField(this, 121, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.clearMarket = function() {
  return this.setMarket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Outcome.prototype.hasMarket = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional string outcome_code = 81;
 * @return {string}
 */
proto.odds.Outcome.prototype.getOutcomeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setOutcomeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};


/**
 * optional string outcome_group = 191;
 * @return {string}
 */
proto.odds.Outcome.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 191, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 191, value);
};


/**
 * optional string outcome_tic = 274;
 * @return {string}
 */
proto.odds.Outcome.prototype.getOutcomeTic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 274, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setOutcomeTic = function(value) {
  return jspb.Message.setProto3StringField(this, 274, value);
};


/**
 * optional string exe_limit = 240;
 * @return {string}
 */
proto.odds.Outcome.prototype.getExeLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 240, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setExeLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 240, value);
};


/**
 * optional Odd odd = 19;
 * @return {?proto.odds.Odd}
 */
proto.odds.Outcome.prototype.getOdd = function() {
  return /** @type{?proto.odds.Odd} */ (
    jspb.Message.getWrapperField(this, proto.odds.Odd, 19));
};


/**
 * @param {?proto.odds.Odd|undefined} value
 * @return {!proto.odds.Outcome} returns this
*/
proto.odds.Outcome.prototype.setOdd = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.clearOdd = function() {
  return this.setOdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Outcome.prototype.hasOdd = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string description = 118;
 * @return {string}
 */
proto.odds.Outcome.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 118, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 118, value);
};


/**
 * optional string short_description = 119;
 * @return {string}
 */
proto.odds.Outcome.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 119, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 119, value);
};


/**
 * optional int32 factory_competitor_id = 83;
 * @return {number}
 */
proto.odds.Outcome.prototype.getFactoryCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setFactoryCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};


/**
 * optional int32 factory_player_id = 102;
 * @return {number}
 */
proto.odds.Outcome.prototype.getFactoryPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setFactoryPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};


/**
 * repeated Link links = 98;
 * @return {!Array<!proto.odds.Link>}
 */
proto.odds.Outcome.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.odds.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Link, 98));
};


/**
 * @param {!Array<!proto.odds.Link>} value
 * @return {!proto.odds.Outcome} returns this
*/
proto.odds.Outcome.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 98, value);
};


/**
 * @param {!proto.odds.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Link}
 */
proto.odds.Outcome.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 98, opt_value, proto.odds.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * optional int32 live_market_id = 261;
 * @return {number}
 */
proto.odds.Outcome.prototype.getLiveMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 261, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setLiveMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 261, value);
};


/**
 * optional bool has_limit = 279;
 * @return {boolean}
 */
proto.odds.Outcome.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 279, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 279, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Odd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Odd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Odd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Odd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 8, ""),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 122, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 91, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    isFixed: jspb.Message.getBooleanFieldWithDefault(msg, 183, false),
    prematchOdd: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    eventId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Odd}
 */
proto.odds.Odd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Odd;
  return proto.odds.Odd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Odd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Odd}
 */
proto.odds.Odd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 122:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 91:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 183:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFixed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrematchOdd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 77:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Odd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Odd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Odd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Odd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      122,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      91,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getIsFixed();
  if (f) {
    writer.writeBool(
      183,
      f
    );
  }
  f = message.getPrematchOdd();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.Odd.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 8;
 * @return {string}
 */
proto.odds.Odd.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double limit = 122;
 * @return {number}
 */
proto.odds.Odd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 122, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 122, value);
};


/**
 * optional string status = 91;
 * @return {string}
 */
proto.odds.Odd.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 91, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 91, value);
};


/**
 * optional bool is_disabled = 136;
 * @return {boolean}
 */
proto.odds.Odd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional bool is_fixed = 183;
 * @return {boolean}
 */
proto.odds.Odd.prototype.getIsFixed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 183, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setIsFixed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 183, value);
};


/**
 * optional double prematch_odd = 2;
 * @return {number}
 */
proto.odds.Odd.prototype.getPrematchOdd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setPrematchOdd = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string event_id = 3;
 * @return {string}
 */
proto.odds.Odd.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 outcome_id = 4;
 * @return {number}
 */
proto.odds.Odd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional common.Error error = 77;
 * @return {?proto.common.Error}
 */
proto.odds.Odd.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 77));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.Odd} returns this
*/
proto.odds.Odd.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 77, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Odd.prototype.hasError = function() {
  return jspb.Message.getField(this, 77) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Sport.repeatedFields_ = [23,52];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Sport.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Sport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Sport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Sport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    filteredEventCount: jspb.Message.getFieldWithDefault(msg, 171, 0),
    hasPlayers: jspb.Message.getBooleanFieldWithDefault(msg, 137, false),
    hasGroups: jspb.Message.getBooleanFieldWithDefault(msg, 141, false),
    manualResults: jspb.Message.getBooleanFieldWithDefault(msg, 144, false),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.odds.Location.toObject, includeInstance),
    marketIdsList: (f = jspb.Message.getRepeatedField(msg, 52)) == null ? undefined : f,
    basicOfferLive: (f = msg.getBasicOfferLive()) && proto.odds.BasicOffer.toObject(includeInstance, f),
    basicOfferPrematch: (f = msg.getBasicOfferPrematch()) && proto.odds.BasicOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Sport}
 */
proto.odds.Sport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Sport;
  return proto.odds.Sport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Sport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Sport}
 */
proto.odds.Sport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCount(value);
      break;
    case 171:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilteredEventCount(value);
      break;
    case 137:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPlayers(value);
      break;
    case 141:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGroups(value);
      break;
    case 144:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualResults(value);
      break;
    case 23:
      var value = new proto.odds.Location;
      reader.readMessage(value,proto.odds.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 52:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMarketIds(values[i]);
      }
      break;
    case 150:
      var value = new proto.odds.BasicOffer;
      reader.readMessage(value,proto.odds.BasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferLive(value);
      break;
    case 151:
      var value = new proto.odds.BasicOffer;
      reader.readMessage(value,proto.odds.BasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferPrematch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Sport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Sport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Sport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Sport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventCount();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getFilteredEventCount();
  if (f !== 0) {
    writer.writeInt32(
      171,
      f
    );
  }
  f = message.getHasPlayers();
  if (f) {
    writer.writeBool(
      137,
      f
    );
  }
  f = message.getHasGroups();
  if (f) {
    writer.writeBool(
      141,
      f
    );
  }
  f = message.getManualResults();
  if (f) {
    writer.writeBool(
      144,
      f
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.odds.Location.serializeBinaryToWriter
    );
  }
  f = message.getMarketIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      52,
      f
    );
  }
  f = message.getBasicOfferLive();
  if (f != null) {
    writer.writeMessage(
      150,
      f,
      proto.odds.BasicOffer.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferPrematch();
  if (f != null) {
    writer.writeMessage(
      151,
      f,
      proto.odds.BasicOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Sport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Sport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 event_count = 24;
 * @return {number}
 */
proto.odds.Sport.prototype.getEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 filtered_event_count = 171;
 * @return {number}
 */
proto.odds.Sport.prototype.getFilteredEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 171, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setFilteredEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 171, value);
};


/**
 * optional bool has_players = 137;
 * @return {boolean}
 */
proto.odds.Sport.prototype.getHasPlayers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 137, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setHasPlayers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 137, value);
};


/**
 * optional bool has_groups = 141;
 * @return {boolean}
 */
proto.odds.Sport.prototype.getHasGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 141, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setHasGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 141, value);
};


/**
 * optional bool manual_results = 144;
 * @return {boolean}
 */
proto.odds.Sport.prototype.getManualResults = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 144, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setManualResults = function(value) {
  return jspb.Message.setProto3BooleanField(this, 144, value);
};


/**
 * repeated Location locations = 23;
 * @return {!Array<!proto.odds.Location>}
 */
proto.odds.Sport.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.odds.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Location, 23));
};


/**
 * @param {!Array<!proto.odds.Location>} value
 * @return {!proto.odds.Sport} returns this
*/
proto.odds.Sport.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.odds.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Location}
 */
proto.odds.Sport.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.odds.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * repeated int32 market_ids = 52;
 * @return {!Array<number>}
 */
proto.odds.Sport.prototype.getMarketIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 52));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setMarketIdsList = function(value) {
  return jspb.Message.setField(this, 52, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.addMarketIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 52, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearMarketIdsList = function() {
  return this.setMarketIdsList([]);
};


/**
 * optional BasicOffer basic_offer_live = 150;
 * @return {?proto.odds.BasicOffer}
 */
proto.odds.Sport.prototype.getBasicOfferLive = function() {
  return /** @type{?proto.odds.BasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.odds.BasicOffer, 150));
};


/**
 * @param {?proto.odds.BasicOffer|undefined} value
 * @return {!proto.odds.Sport} returns this
*/
proto.odds.Sport.prototype.setBasicOfferLive = function(value) {
  return jspb.Message.setWrapperField(this, 150, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearBasicOfferLive = function() {
  return this.setBasicOfferLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Sport.prototype.hasBasicOfferLive = function() {
  return jspb.Message.getField(this, 150) != null;
};


/**
 * optional BasicOffer basic_offer_prematch = 151;
 * @return {?proto.odds.BasicOffer}
 */
proto.odds.Sport.prototype.getBasicOfferPrematch = function() {
  return /** @type{?proto.odds.BasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.odds.BasicOffer, 151));
};


/**
 * @param {?proto.odds.BasicOffer|undefined} value
 * @return {!proto.odds.Sport} returns this
*/
proto.odds.Sport.prototype.setBasicOfferPrematch = function(value) {
  return jspb.Message.setWrapperField(this, 151, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearBasicOfferPrematch = function() {
  return this.setBasicOfferPrematch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Sport.prototype.hasBasicOfferPrematch = function() {
  return jspb.Message.getField(this, 151) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.BasicOffer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.BasicOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.BasicOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.BasicOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BasicOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.BasicOfferMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.BasicOffer}
 */
proto.odds.BasicOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.BasicOffer;
  return proto.odds.BasicOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.BasicOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.BasicOffer}
 */
proto.odds.BasicOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.BasicOfferMarket;
      reader.readMessage(value,proto.odds.BasicOfferMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.BasicOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.BasicOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.BasicOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BasicOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.BasicOfferMarket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BasicOfferMarket markets = 1;
 * @return {!Array<!proto.odds.BasicOfferMarket>}
 */
proto.odds.BasicOffer.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.BasicOfferMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.BasicOfferMarket, 1));
};


/**
 * @param {!Array<!proto.odds.BasicOfferMarket>} value
 * @return {!proto.odds.BasicOffer} returns this
*/
proto.odds.BasicOffer.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.BasicOfferMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.BasicOfferMarket}
 */
proto.odds.BasicOffer.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.BasicOfferMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.BasicOffer} returns this
 */
proto.odds.BasicOffer.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.BasicOfferMarket.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.BasicOfferMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.BasicOfferMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.BasicOfferMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BasicOfferMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcomeIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.BasicOfferMarket}
 */
proto.odds.BasicOfferMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.BasicOfferMarket;
  return proto.odds.BasicOfferMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.BasicOfferMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.BasicOfferMarket}
 */
proto.odds.BasicOfferMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutcomeIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.BasicOfferMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.BasicOfferMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.BasicOfferMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BasicOfferMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutcomeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 market_id = 1;
 * @return {number}
 */
proto.odds.BasicOfferMarket.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.BasicOfferMarket} returns this
 */
proto.odds.BasicOfferMarket.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 outcome_ids = 2;
 * @return {!Array<number>}
 */
proto.odds.BasicOfferMarket.prototype.getOutcomeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.BasicOfferMarket} returns this
 */
proto.odds.BasicOfferMarket.prototype.setOutcomeIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.BasicOfferMarket} returns this
 */
proto.odds.BasicOfferMarket.prototype.addOutcomeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.BasicOfferMarket} returns this
 */
proto.odds.BasicOfferMarket.prototype.clearOutcomeIdsList = function() {
  return this.setOutcomeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Location.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    filteredEventCount: jspb.Message.getFieldWithDefault(msg, 171, 0),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Location}
 */
proto.odds.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Location;
  return proto.odds.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Location}
 */
proto.odds.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCount(value);
      break;
    case 171:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilteredEventCount(value);
      break;
    case 25:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventCount();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getFilteredEventCount();
  if (f !== 0) {
    writer.writeInt32(
      171,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Location.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Location.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 event_count = 24;
 * @return {number}
 */
proto.odds.Location.prototype.getEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 filtered_event_count = 171;
 * @return {number}
 */
proto.odds.Location.prototype.getFilteredEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 171, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setFilteredEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 171, value);
};


/**
 * repeated Competition competitions = 25;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.Location.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 25));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.Location} returns this
*/
proto.odds.Location.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.Location.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Competition.repeatedFields_ = [44,98,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Competition.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Competition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Competition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Competition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    eventCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    filteredEventCount: jspb.Message.getFieldWithDefault(msg, 171, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportName: jspb.Message.getFieldWithDefault(msg, 107, ""),
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    isAutomatic: jspb.Message.getBooleanFieldWithDefault(msg, 110, false),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 108, ""),
    competitorsList: jspb.Message.toObjectList(msg.getCompetitorsList(),
    proto.odds.Competitor.toObject, includeInstance),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.odds.Link.toObject, includeInstance),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance),
    marketTemplateId: jspb.Message.getFieldWithDefault(msg, 224, 0),
    paSportId: jspb.Message.getFieldWithDefault(msg, 305, 0),
    paSportName: jspb.Message.getFieldWithDefault(msg, 306, 0),
    paType: jspb.Message.getFieldWithDefault(msg, 307, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Competition}
 */
proto.odds.Competition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Competition;
  return proto.odds.Competition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Competition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Competition}
 */
proto.odds.Competition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCount(value);
      break;
    case 171:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilteredEventCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 107:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportName(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    case 110:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutomatic(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 44:
      var value = new proto.odds.Competitor;
      reader.readMessage(value,proto.odds.Competitor.deserializeBinaryFromReader);
      msg.addCompetitors(value);
      break;
    case 98:
      var value = new proto.odds.Link;
      reader.readMessage(value,proto.odds.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 224:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketTemplateId(value);
      break;
    case 305:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaSportId(value);
      break;
    case 306:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaSportName(value);
      break;
    case 307:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Competition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Competition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Competition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Competition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getEventCount();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getFilteredEventCount();
  if (f !== 0) {
    writer.writeInt32(
      171,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportName();
  if (f.length > 0) {
    writer.writeString(
      107,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getIsAutomatic();
  if (f) {
    writer.writeBool(
      110,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getCompetitorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.odds.Competitor.serializeBinaryToWriter
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      98,
      f,
      proto.odds.Link.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
  f = message.getMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      224,
      f
    );
  }
  f = message.getPaSportId();
  if (f !== 0) {
    writer.writeInt32(
      305,
      f
    );
  }
  f = message.getPaSportName();
  if (f !== 0) {
    writer.writeInt32(
      306,
      f
    );
  }
  f = message.getPaType();
  if (f.length > 0) {
    writer.writeString(
      307,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Competition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Competition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.Competition.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 event_count = 24;
 * @return {number}
 */
proto.odds.Competition.prototype.getEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 filtered_event_count = 171;
 * @return {number}
 */
proto.odds.Competition.prototype.getFilteredEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 171, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setFilteredEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 171, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.Competition.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sport_name = 107;
 * @return {string}
 */
proto.odds.Competition.prototype.getSportName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 107, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setSportName = function(value) {
  return jspb.Message.setProto3StringField(this, 107, value);
};


/**
 * optional bool is_favorite = 40;
 * @return {boolean}
 */
proto.odds.Competition.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool is_automatic = 110;
 * @return {boolean}
 */
proto.odds.Competition.prototype.getIsAutomatic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 110, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setIsAutomatic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 110, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.Competition.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * optional string location_name = 108;
 * @return {string}
 */
proto.odds.Competition.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * repeated Competitor competitors = 44;
 * @return {!Array<!proto.odds.Competitor>}
 */
proto.odds.Competition.prototype.getCompetitorsList = function() {
  return /** @type{!Array<!proto.odds.Competitor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competitor, 44));
};


/**
 * @param {!Array<!proto.odds.Competitor>} value
 * @return {!proto.odds.Competition} returns this
*/
proto.odds.Competition.prototype.setCompetitorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.odds.Competitor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competitor}
 */
proto.odds.Competition.prototype.addCompetitors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.odds.Competitor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.clearCompetitorsList = function() {
  return this.setCompetitorsList([]);
};


/**
 * repeated Link links = 98;
 * @return {!Array<!proto.odds.Link>}
 */
proto.odds.Competition.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.odds.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Link, 98));
};


/**
 * @param {!Array<!proto.odds.Link>} value
 * @return {!proto.odds.Competition} returns this
*/
proto.odds.Competition.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 98, value);
};


/**
 * @param {!proto.odds.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Link}
 */
proto.odds.Competition.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 98, opt_value, proto.odds.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.Competition.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.Competition} returns this
*/
proto.odds.Competition.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.Competition.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int32 market_template_id = 224;
 * @return {number}
 */
proto.odds.Competition.prototype.getMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 224, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 224, value);
};


/**
 * optional int32 pa_sport_id = 305;
 * @return {number}
 */
proto.odds.Competition.prototype.getPaSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 305, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setPaSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 305, value);
};


/**
 * optional int32 pa_sport_name = 306;
 * @return {number}
 */
proto.odds.Competition.prototype.getPaSportName = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 306, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setPaSportName = function(value) {
  return jspb.Message.setProto3IntField(this, 306, value);
};


/**
 * optional string pa_type = 307;
 * @return {string}
 */
proto.odds.Competition.prototype.getPaType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 307, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setPaType = function(value) {
  return jspb.Message.setProto3StringField(this, 307, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    intId: jspb.Message.getFieldWithDefault(msg, 45, 0),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 46, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 47, ""),
    providerOddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    eventType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    eventProfile: jspb.Message.getFieldWithDefault(msg, 7, ""),
    eventDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    eventFinished: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    eventPublished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    eventSuspicious: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    eventSingleApproval: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    eventLandbaseCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    suspiciousLevel: jspb.Message.getFieldWithDefault(msg, 14, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    competitionShortName: jspb.Message.getFieldWithDefault(msg, 43, ""),
    flagCode: jspb.Message.getFieldWithDefault(msg, 44, ""),
    competitionAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    competitionMaxSingle: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    competitionMaxMultiple: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    competitionSingleBettingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    competitionMultipleBettingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    locationId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    marketId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endBettingTime: (f = msg.getEndBettingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    marketName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    outcomeTic: jspb.Message.getFieldWithDefault(msg, 29, ""),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 30, 0),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 32, ""),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    eventName: jspb.Message.getFieldWithDefault(msg, 34, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 35, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 37, ""),
    eventNote: jspb.Message.getFieldWithDefault(msg, 38, ""),
    outcomeLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    paSport: jspb.Message.getFieldWithDefault(msg, 40, 0),
    paType: jspb.Message.getFieldWithDefault(msg, 41, ""),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 42, 0),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 48, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddResponse}
 */
proto.odds.OddResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddResponse;
  return proto.odds.OddResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddResponse}
 */
proto.odds.OddResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOddId(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProviderOddValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventProfile(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventDisabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventFinished(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventPublished(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventSuspicious(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventSingleApproval(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventLandbaseCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuspiciousLevel(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionShortName(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagCode(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompetitionAmount(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompetitionMaxSingle(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompetitionMaxMultiple(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompetitionSingleBettingAmount(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompetitionMultipleBettingAmount(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 26:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndBettingTime(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeTic(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 31:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventNote(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOutcomeLimit(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaSport(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaType(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntId();
  if (f !== 0) {
    writer.writeInt32(
      45,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      46,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getProviderOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEventProfile();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEventDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEventFinished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getEventPublished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getEventSuspicious();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getEventSingleApproval();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getEventLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSuspiciousLevel();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCompetitionShortName();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getFlagCode();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getCompetitionAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getCompetitionMaxSingle();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getCompetitionMaxMultiple();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getCompetitionSingleBettingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getCompetitionMultipleBettingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndBettingTime();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getOutcomeTic();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getEventNote();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getOutcomeLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      39,
      f
    );
  }
  f = message.getPaSport();
  if (f !== 0) {
    writer.writeInt32(
      40,
      f
    );
  }
  f = message.getPaType();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      42,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
};


/**
 * optional string odd_id = 1;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getOddId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOddId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 int_id = 45;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getIntId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setIntId = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional double odd_value = 2;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 frame_number = 46;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional string current_result = 47;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional double provider_odd_value = 3;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getProviderOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setProviderOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string event_id = 5;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string event_type = 6;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getEventType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string event_profile = 7;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getEventProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool event_disabled = 8;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getEventDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool event_finished = 9;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getEventFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool event_published = 10;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getEventPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool event_suspicious = 11;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getEventSuspicious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventSuspicious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool event_single_approval = 12;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getEventSingleApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventSingleApproval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string event_landbase_code = 13;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getEventLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string suspicious_level = 14;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getSuspiciousLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setSuspiciousLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 competition_id = 15;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string competition_name = 16;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string competition_short_name = 43;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getCompetitionShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string flag_code = 44;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getFlagCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setFlagCode = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional double competition_amount = 17;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getCompetitionAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double competition_max_single = 18;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getCompetitionMaxSingle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionMaxSingle = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double competition_max_multiple = 19;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getCompetitionMaxMultiple = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionMaxMultiple = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double competition_single_betting_amount = 20;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getCompetitionSingleBettingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionSingleBettingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double competition_multiple_betting_amount = 21;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getCompetitionMultipleBettingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setCompetitionMultipleBettingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional int32 location_id = 22;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 market_id = 23;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional bool is_live = 24;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional google.protobuf.Timestamp start = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.OddResponse.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.OddResponse} returns this
*/
proto.odds.OddResponse.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.hasStart = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.Timestamp end_betting_time = 26;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.OddResponse.prototype.getEndBettingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 26));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.OddResponse} returns this
*/
proto.odds.OddResponse.prototype.setEndBettingTime = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.clearEndBettingTime = function() {
  return this.setEndBettingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.hasEndBettingTime = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string market_name = 27;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string outcome_name = 28;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string outcome_tic = 29;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getOutcomeTic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOutcomeTic = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional int32 outcome_id = 30;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional common.EventResult result = 31;
 * @return {?proto.common.EventResult}
 */
proto.odds.OddResponse.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 31));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.odds.OddResponse} returns this
*/
proto.odds.OddResponse.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string outcome_group = 32;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional double limit = 33;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional string event_name = 34;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string outright_type = 35;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional bool is_disabled = 36;
 * @return {boolean}
 */
proto.odds.OddResponse.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional string landbase_code = 37;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string event_note = 38;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getEventNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setEventNote = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional double outcome_limit = 39;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getOutcomeLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setOutcomeLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional int32 pa_sport = 40;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getPaSport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setPaSport = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional string pa_type = 41;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getPaType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setPaType = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional int32 exefeed_id = 42;
 * @return {number}
 */
proto.odds.OddResponse.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional string live_player = 48;
 * @return {string}
 */
proto.odds.OddResponse.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddResponse} returns this
 */
proto.odds.OddResponse.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetInitialDataResponse.repeatedFields_ = [29,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetInitialDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetInitialDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetInitialDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetInitialDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupsList: jspb.Message.toObjectList(msg.getMarketGroupsList(),
    proto.odds.MarketGroup.toObject, includeInstance),
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.Sport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetInitialDataResponse}
 */
proto.odds.GetInitialDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetInitialDataResponse;
  return proto.odds.GetInitialDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetInitialDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetInitialDataResponse}
 */
proto.odds.GetInitialDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 29:
      var value = new proto.odds.MarketGroup;
      reader.readMessage(value,proto.odds.MarketGroup.deserializeBinaryFromReader);
      msg.addMarketGroups(value);
      break;
    case 13:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetInitialDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetInitialDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetInitialDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetInitialDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      proto.odds.MarketGroup.serializeBinaryToWriter
    );
  }
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketGroup market_groups = 29;
 * @return {!Array<!proto.odds.MarketGroup>}
 */
proto.odds.GetInitialDataResponse.prototype.getMarketGroupsList = function() {
  return /** @type{!Array<!proto.odds.MarketGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketGroup, 29));
};


/**
 * @param {!Array<!proto.odds.MarketGroup>} value
 * @return {!proto.odds.GetInitialDataResponse} returns this
*/
proto.odds.GetInitialDataResponse.prototype.setMarketGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.odds.MarketGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketGroup}
 */
proto.odds.GetInitialDataResponse.prototype.addMarketGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.odds.MarketGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetInitialDataResponse} returns this
 */
proto.odds.GetInitialDataResponse.prototype.clearMarketGroupsList = function() {
  return this.setMarketGroupsList([]);
};


/**
 * repeated Sport sports = 13;
 * @return {!Array<!proto.odds.Sport>}
 */
proto.odds.GetInitialDataResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.Sport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Sport, 13));
};


/**
 * @param {!Array<!proto.odds.Sport>} value
 * @return {!proto.odds.GetInitialDataResponse} returns this
*/
proto.odds.GetInitialDataResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.odds.Sport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport}
 */
proto.odds.GetInitialDataResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.odds.Sport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetInitialDataResponse} returns this
 */
proto.odds.GetInitialDataResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetInitialDataForBoResponse.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetInitialDataForBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetInitialDataForBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetInitialDataForBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetInitialDataForBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.Sport.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetInitialDataForBoResponse}
 */
proto.odds.GetInitialDataForBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetInitialDataForBoResponse;
  return proto.odds.GetInitialDataForBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetInitialDataForBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetInitialDataForBoResponse}
 */
proto.odds.GetInitialDataForBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetInitialDataForBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetInitialDataForBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetInitialDataForBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetInitialDataForBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sport sports = 13;
 * @return {!Array<!proto.odds.Sport>}
 */
proto.odds.GetInitialDataForBoResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.Sport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Sport, 13));
};


/**
 * @param {!Array<!proto.odds.Sport>} value
 * @return {!proto.odds.GetInitialDataForBoResponse} returns this
*/
proto.odds.GetInitialDataForBoResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.odds.Sport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport}
 */
proto.odds.GetInitialDataForBoResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.odds.Sport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetInitialDataForBoResponse} returns this
 */
proto.odds.GetInitialDataForBoResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetInitialDataForBoResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetInitialDataForBoResponse} returns this
*/
proto.odds.GetInitialDataForBoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetInitialDataForBoResponse} returns this
 */
proto.odds.GetInitialDataForBoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetInitialDataForBoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetProvidersResponse.repeatedFields_ = [38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProvidersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProvidersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProvidersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProvidersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.odds.Provider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProvidersResponse}
 */
proto.odds.GetProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProvidersResponse;
  return proto.odds.GetProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProvidersResponse}
 */
proto.odds.GetProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 38:
      var value = new proto.odds.Provider;
      reader.readMessage(value,proto.odds.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.odds.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Provider providers = 38;
 * @return {!Array<!proto.odds.Provider>}
 */
proto.odds.GetProvidersResponse.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.odds.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Provider, 38));
};


/**
 * @param {!Array<!proto.odds.Provider>} value
 * @return {!proto.odds.GetProvidersResponse} returns this
*/
proto.odds.GetProvidersResponse.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.odds.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Provider}
 */
proto.odds.GetProvidersResponse.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.odds.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetProvidersResponse} returns this
 */
proto.odds.GetProvidersResponse.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEvents: (f = msg.getLiveEvents()) && proto.odds.LiveEvents.toObject(includeInstance, f),
    upcomingEvents: (f = msg.getUpcomingEvents()) && proto.odds.UpcomingEvents.toObject(includeInstance, f),
    outrightEvents: (f = msg.getOutrightEvents()) && proto.odds.UpcomingEvents.toObject(includeInstance, f),
    playerEvents: (f = msg.getPlayerEvents()) && proto.odds.UpcomingEvents.toObject(includeInstance, f),
    groupEvents: (f = msg.getGroupEvents()) && proto.odds.UpcomingEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsResponse}
 */
proto.odds.GetEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsResponse;
  return proto.odds.GetEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsResponse}
 */
proto.odds.GetEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 36:
      var value = new proto.odds.LiveEvents;
      reader.readMessage(value,proto.odds.LiveEvents.deserializeBinaryFromReader);
      msg.setLiveEvents(value);
      break;
    case 37:
      var value = new proto.odds.UpcomingEvents;
      reader.readMessage(value,proto.odds.UpcomingEvents.deserializeBinaryFromReader);
      msg.setUpcomingEvents(value);
      break;
    case 247:
      var value = new proto.odds.UpcomingEvents;
      reader.readMessage(value,proto.odds.UpcomingEvents.deserializeBinaryFromReader);
      msg.setOutrightEvents(value);
      break;
    case 248:
      var value = new proto.odds.UpcomingEvents;
      reader.readMessage(value,proto.odds.UpcomingEvents.deserializeBinaryFromReader);
      msg.setPlayerEvents(value);
      break;
    case 249:
      var value = new proto.odds.UpcomingEvents;
      reader.readMessage(value,proto.odds.UpcomingEvents.deserializeBinaryFromReader);
      msg.setGroupEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEvents();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.odds.LiveEvents.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingEvents();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.odds.UpcomingEvents.serializeBinaryToWriter
    );
  }
  f = message.getOutrightEvents();
  if (f != null) {
    writer.writeMessage(
      247,
      f,
      proto.odds.UpcomingEvents.serializeBinaryToWriter
    );
  }
  f = message.getPlayerEvents();
  if (f != null) {
    writer.writeMessage(
      248,
      f,
      proto.odds.UpcomingEvents.serializeBinaryToWriter
    );
  }
  f = message.getGroupEvents();
  if (f != null) {
    writer.writeMessage(
      249,
      f,
      proto.odds.UpcomingEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional LiveEvents live_events = 36;
 * @return {?proto.odds.LiveEvents}
 */
proto.odds.GetEventsResponse.prototype.getLiveEvents = function() {
  return /** @type{?proto.odds.LiveEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds.LiveEvents, 36));
};


/**
 * @param {?proto.odds.LiveEvents|undefined} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setLiveEvents = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearLiveEvents = function() {
  return this.setLiveEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventsResponse.prototype.hasLiveEvents = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional UpcomingEvents upcoming_events = 37;
 * @return {?proto.odds.UpcomingEvents}
 */
proto.odds.GetEventsResponse.prototype.getUpcomingEvents = function() {
  return /** @type{?proto.odds.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds.UpcomingEvents, 37));
};


/**
 * @param {?proto.odds.UpcomingEvents|undefined} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setUpcomingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearUpcomingEvents = function() {
  return this.setUpcomingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventsResponse.prototype.hasUpcomingEvents = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional UpcomingEvents outright_events = 247;
 * @return {?proto.odds.UpcomingEvents}
 */
proto.odds.GetEventsResponse.prototype.getOutrightEvents = function() {
  return /** @type{?proto.odds.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds.UpcomingEvents, 247));
};


/**
 * @param {?proto.odds.UpcomingEvents|undefined} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setOutrightEvents = function(value) {
  return jspb.Message.setWrapperField(this, 247, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearOutrightEvents = function() {
  return this.setOutrightEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventsResponse.prototype.hasOutrightEvents = function() {
  return jspb.Message.getField(this, 247) != null;
};


/**
 * optional UpcomingEvents player_events = 248;
 * @return {?proto.odds.UpcomingEvents}
 */
proto.odds.GetEventsResponse.prototype.getPlayerEvents = function() {
  return /** @type{?proto.odds.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds.UpcomingEvents, 248));
};


/**
 * @param {?proto.odds.UpcomingEvents|undefined} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setPlayerEvents = function(value) {
  return jspb.Message.setWrapperField(this, 248, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearPlayerEvents = function() {
  return this.setPlayerEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventsResponse.prototype.hasPlayerEvents = function() {
  return jspb.Message.getField(this, 248) != null;
};


/**
 * optional UpcomingEvents group_events = 249;
 * @return {?proto.odds.UpcomingEvents}
 */
proto.odds.GetEventsResponse.prototype.getGroupEvents = function() {
  return /** @type{?proto.odds.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds.UpcomingEvents, 249));
};


/**
 * @param {?proto.odds.UpcomingEvents|undefined} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setGroupEvents = function(value) {
  return jspb.Message.setWrapperField(this, 249, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearGroupEvents = function() {
  return this.setGroupEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventsResponse.prototype.hasGroupEvents = function() {
  return jspb.Message.getField(this, 249) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LiveEvents.repeatedFields_ = [17,194];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LiveEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LiveEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LiveEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    disableAll: jspb.Message.getBooleanFieldWithDefault(msg, 193, false),
    disabledSportsList: (f = jspb.Message.getRepeatedField(msg, 194)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LiveEvents}
 */
proto.odds.LiveEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LiveEvents;
  return proto.odds.LiveEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LiveEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LiveEvents}
 */
proto.odds.LiveEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 193:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableAll(value);
      break;
    case 194:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisabledSports(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LiveEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LiveEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LiveEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getDisableAll();
  if (f) {
    writer.writeBool(
      193,
      f
    );
  }
  f = message.getDisabledSportsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      194,
      f
    );
  }
};


/**
 * repeated StreamEvent events = 17;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.LiveEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 17));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.LiveEvents} returns this
*/
proto.odds.LiveEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.LiveEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveEvents} returns this
 */
proto.odds.LiveEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional bool disable_all = 193;
 * @return {boolean}
 */
proto.odds.LiveEvents.prototype.getDisableAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 193, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.LiveEvents} returns this
 */
proto.odds.LiveEvents.prototype.setDisableAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 193, value);
};


/**
 * repeated int32 disabled_sports = 194;
 * @return {!Array<number>}
 */
proto.odds.LiveEvents.prototype.getDisabledSportsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 194));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.LiveEvents} returns this
 */
proto.odds.LiveEvents.prototype.setDisabledSportsList = function(value) {
  return jspb.Message.setField(this, 194, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.LiveEvents} returns this
 */
proto.odds.LiveEvents.prototype.addDisabledSports = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 194, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveEvents} returns this
 */
proto.odds.LiveEvents.prototype.clearDisabledSportsList = function() {
  return this.setDisabledSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LiveEventsBO.repeatedFields_ = [17,194];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LiveEventsBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LiveEventsBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LiveEventsBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveEventsBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance),
    disableAll: jspb.Message.getBooleanFieldWithDefault(msg, 193, false),
    disabledSportsList: (f = jspb.Message.getRepeatedField(msg, 194)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LiveEventsBO}
 */
proto.odds.LiveEventsBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LiveEventsBO;
  return proto.odds.LiveEventsBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LiveEventsBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LiveEventsBO}
 */
proto.odds.LiveEventsBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 193:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableAll(value);
      break;
    case 194:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisabledSports(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LiveEventsBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LiveEventsBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LiveEventsBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LiveEventsBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
  f = message.getDisableAll();
  if (f) {
    writer.writeBool(
      193,
      f
    );
  }
  f = message.getDisabledSportsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      194,
      f
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.LiveEventsBO.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.LiveEventsBO} returns this
*/
proto.odds.LiveEventsBO.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.LiveEventsBO.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveEventsBO} returns this
 */
proto.odds.LiveEventsBO.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional bool disable_all = 193;
 * @return {boolean}
 */
proto.odds.LiveEventsBO.prototype.getDisableAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 193, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.LiveEventsBO} returns this
 */
proto.odds.LiveEventsBO.prototype.setDisableAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 193, value);
};


/**
 * repeated int32 disabled_sports = 194;
 * @return {!Array<number>}
 */
proto.odds.LiveEventsBO.prototype.getDisabledSportsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 194));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.LiveEventsBO} returns this
 */
proto.odds.LiveEventsBO.prototype.setDisabledSportsList = function(value) {
  return jspb.Message.setField(this, 194, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.LiveEventsBO} returns this
 */
proto.odds.LiveEventsBO.prototype.addDisabledSports = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 194, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LiveEventsBO} returns this
 */
proto.odds.LiveEventsBO.prototype.clearDisabledSportsList = function() {
  return this.setDisabledSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.UpcomingEvents.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpcomingEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpcomingEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpcomingEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpcomingEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.StreamEvent.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpcomingEvents}
 */
proto.odds.UpcomingEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpcomingEvents;
  return proto.odds.UpcomingEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpcomingEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpcomingEvents}
 */
proto.odds.UpcomingEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.StreamEvent;
      reader.readMessage(value,proto.odds.StreamEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpcomingEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpcomingEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpcomingEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpcomingEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StreamEvent events = 17;
 * @return {!Array<!proto.odds.StreamEvent>}
 */
proto.odds.UpcomingEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.StreamEvent, 17));
};


/**
 * @param {!Array<!proto.odds.StreamEvent>} value
 * @return {!proto.odds.UpcomingEvents} returns this
*/
proto.odds.UpcomingEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.StreamEvent}
 */
proto.odds.UpcomingEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpcomingEvents} returns this
 */
proto.odds.UpcomingEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.UpcomingEvents.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.UpcomingEvents} returns this
*/
proto.odds.UpcomingEvents.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.UpcomingEvents} returns this
 */
proto.odds.UpcomingEvents.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.UpcomingEvents.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.AllEvents.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AllEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AllEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AllEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AllEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AllEvents}
 */
proto.odds.AllEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AllEvents;
  return proto.odds.AllEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AllEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AllEvents}
 */
proto.odds.AllEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AllEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AllEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AllEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AllEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.AllEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.AllEvents} returns this
*/
proto.odds.AllEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.AllEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.AllEvents} returns this
 */
proto.odds.AllEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SlipSubGroupOddResponse.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SlipSubGroupOddResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SlipSubGroupOddResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SlipSubGroupOddResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SlipSubGroupOddResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.OddResponse.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SlipSubGroupOddResponse}
 */
proto.odds.SlipSubGroupOddResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SlipSubGroupOddResponse;
  return proto.odds.SlipSubGroupOddResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SlipSubGroupOddResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SlipSubGroupOddResponse}
 */
proto.odds.SlipSubGroupOddResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 18:
      var value = new proto.odds.OddResponse;
      reader.readMessage(value,proto.odds.OddResponse.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SlipSubGroupOddResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SlipSubGroupOddResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SlipSubGroupOddResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SlipSubGroupOddResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.odds.OddResponse.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * repeated OddResponse odds = 18;
 * @return {!Array<!proto.odds.OddResponse>}
 */
proto.odds.SlipSubGroupOddResponse.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.OddResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OddResponse, 18));
};


/**
 * @param {!Array<!proto.odds.OddResponse>} value
 * @return {!proto.odds.SlipSubGroupOddResponse} returns this
*/
proto.odds.SlipSubGroupOddResponse.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.odds.OddResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OddResponse}
 */
proto.odds.SlipSubGroupOddResponse.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.odds.OddResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SlipSubGroupOddResponse} returns this
 */
proto.odds.SlipSubGroupOddResponse.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional string type = 31;
 * @return {string}
 */
proto.odds.SlipSubGroupOddResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SlipSubGroupOddResponse} returns this
 */
proto.odds.SlipSubGroupOddResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SlipGroupOddResponse.repeatedFields_ = [77];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SlipGroupOddResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SlipGroupOddResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SlipGroupOddResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SlipGroupOddResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subGroupsList: jspb.Message.toObjectList(msg.getSubGroupsList(),
    proto.odds.SlipSubGroupOddResponse.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SlipGroupOddResponse}
 */
proto.odds.SlipGroupOddResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SlipGroupOddResponse;
  return proto.odds.SlipGroupOddResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SlipGroupOddResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SlipGroupOddResponse}
 */
proto.odds.SlipGroupOddResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 77:
      var value = new proto.odds.SlipSubGroupOddResponse;
      reader.readMessage(value,proto.odds.SlipSubGroupOddResponse.deserializeBinaryFromReader);
      msg.addSubGroups(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SlipGroupOddResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SlipGroupOddResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SlipGroupOddResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SlipGroupOddResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      77,
      f,
      proto.odds.SlipSubGroupOddResponse.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * repeated SlipSubGroupOddResponse sub_groups = 77;
 * @return {!Array<!proto.odds.SlipSubGroupOddResponse>}
 */
proto.odds.SlipGroupOddResponse.prototype.getSubGroupsList = function() {
  return /** @type{!Array<!proto.odds.SlipSubGroupOddResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.SlipSubGroupOddResponse, 77));
};


/**
 * @param {!Array<!proto.odds.SlipSubGroupOddResponse>} value
 * @return {!proto.odds.SlipGroupOddResponse} returns this
*/
proto.odds.SlipGroupOddResponse.prototype.setSubGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 77, value);
};


/**
 * @param {!proto.odds.SlipSubGroupOddResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.SlipSubGroupOddResponse}
 */
proto.odds.SlipGroupOddResponse.prototype.addSubGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 77, opt_value, proto.odds.SlipSubGroupOddResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SlipGroupOddResponse} returns this
 */
proto.odds.SlipGroupOddResponse.prototype.clearSubGroupsList = function() {
  return this.setSubGroupsList([]);
};


/**
 * optional string type = 31;
 * @return {string}
 */
proto.odds.SlipGroupOddResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SlipGroupOddResponse} returns this
 */
proto.odds.SlipGroupOddResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetOddsResponse.repeatedFields_ = [32];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetOddsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetOddsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetOddsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOddsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipGroupsList: jspb.Message.toObjectList(msg.getSlipGroupsList(),
    proto.odds.SlipGroupOddResponse.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetOddsResponse}
 */
proto.odds.GetOddsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetOddsResponse;
  return proto.odds.GetOddsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetOddsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetOddsResponse}
 */
proto.odds.GetOddsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 32:
      var value = new proto.odds.SlipGroupOddResponse;
      reader.readMessage(value,proto.odds.SlipGroupOddResponse.deserializeBinaryFromReader);
      msg.addSlipGroups(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetOddsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetOddsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetOddsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOddsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.odds.SlipGroupOddResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlipGroupOddResponse slip_groups = 32;
 * @return {!Array<!proto.odds.SlipGroupOddResponse>}
 */
proto.odds.GetOddsResponse.prototype.getSlipGroupsList = function() {
  return /** @type{!Array<!proto.odds.SlipGroupOddResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.SlipGroupOddResponse, 32));
};


/**
 * @param {!Array<!proto.odds.SlipGroupOddResponse>} value
 * @return {!proto.odds.GetOddsResponse} returns this
*/
proto.odds.GetOddsResponse.prototype.setSlipGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.odds.SlipGroupOddResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.SlipGroupOddResponse}
 */
proto.odds.GetOddsResponse.prototype.addSlipGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.odds.SlipGroupOddResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetOddsResponse} returns this
 */
proto.odds.GetOddsResponse.prototype.clearSlipGroupsList = function() {
  return this.setSlipGroupsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetOddsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetOddsResponse} returns this
*/
proto.odds.GetOddsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetOddsResponse} returns this
 */
proto.odds.GetOddsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetOddsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Provider.repeatedFields_ = [95,103,117,128];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Provider.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Provider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Provider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Provider.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    liveProvider: jspb.Message.getBooleanFieldWithDefault(msg, 105, false),
    prematchProvider: jspb.Message.getBooleanFieldWithDefault(msg, 106, false),
    providerCompetitorsList: jspb.Message.toObjectList(msg.getProviderCompetitorsList(),
    proto.odds.ProviderCompetitor.toObject, includeInstance),
    providerPlayersList: jspb.Message.toObjectList(msg.getProviderPlayersList(),
    proto.odds.ProviderPlayer.toObject, includeInstance),
    providerOutcomesList: jspb.Message.toObjectList(msg.getProviderOutcomesList(),
    proto.odds.ProviderOutcome.toObject, includeInstance),
    providerCompetitionsList: jspb.Message.toObjectList(msg.getProviderCompetitionsList(),
    proto.odds.ProviderCompetition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Provider}
 */
proto.odds.Provider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Provider;
  return proto.odds.Provider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Provider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Provider}
 */
proto.odds.Provider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 105:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveProvider(value);
      break;
    case 106:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchProvider(value);
      break;
    case 95:
      var value = new proto.odds.ProviderCompetitor;
      reader.readMessage(value,proto.odds.ProviderCompetitor.deserializeBinaryFromReader);
      msg.addProviderCompetitors(value);
      break;
    case 103:
      var value = new proto.odds.ProviderPlayer;
      reader.readMessage(value,proto.odds.ProviderPlayer.deserializeBinaryFromReader);
      msg.addProviderPlayers(value);
      break;
    case 117:
      var value = new proto.odds.ProviderOutcome;
      reader.readMessage(value,proto.odds.ProviderOutcome.deserializeBinaryFromReader);
      msg.addProviderOutcomes(value);
      break;
    case 128:
      var value = new proto.odds.ProviderCompetition;
      reader.readMessage(value,proto.odds.ProviderCompetition.deserializeBinaryFromReader);
      msg.addProviderCompetitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Provider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Provider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Provider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Provider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLiveProvider();
  if (f) {
    writer.writeBool(
      105,
      f
    );
  }
  f = message.getPrematchProvider();
  if (f) {
    writer.writeBool(
      106,
      f
    );
  }
  f = message.getProviderCompetitorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      95,
      f,
      proto.odds.ProviderCompetitor.serializeBinaryToWriter
    );
  }
  f = message.getProviderPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      103,
      f,
      proto.odds.ProviderPlayer.serializeBinaryToWriter
    );
  }
  f = message.getProviderOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      117,
      f,
      proto.odds.ProviderOutcome.serializeBinaryToWriter
    );
  }
  f = message.getProviderCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      128,
      f,
      proto.odds.ProviderCompetition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Provider.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Provider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alias = 3;
 * @return {string}
 */
proto.odds.Provider.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool live_provider = 105;
 * @return {boolean}
 */
proto.odds.Provider.prototype.getLiveProvider = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 105, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3BooleanField(this, 105, value);
};


/**
 * optional bool prematch_provider = 106;
 * @return {boolean}
 */
proto.odds.Provider.prototype.getPrematchProvider = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 106, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.setPrematchProvider = function(value) {
  return jspb.Message.setProto3BooleanField(this, 106, value);
};


/**
 * repeated ProviderCompetitor provider_competitors = 95;
 * @return {!Array<!proto.odds.ProviderCompetitor>}
 */
proto.odds.Provider.prototype.getProviderCompetitorsList = function() {
  return /** @type{!Array<!proto.odds.ProviderCompetitor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.ProviderCompetitor, 95));
};


/**
 * @param {!Array<!proto.odds.ProviderCompetitor>} value
 * @return {!proto.odds.Provider} returns this
*/
proto.odds.Provider.prototype.setProviderCompetitorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 95, value);
};


/**
 * @param {!proto.odds.ProviderCompetitor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderCompetitor}
 */
proto.odds.Provider.prototype.addProviderCompetitors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 95, opt_value, proto.odds.ProviderCompetitor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.clearProviderCompetitorsList = function() {
  return this.setProviderCompetitorsList([]);
};


/**
 * repeated ProviderPlayer provider_players = 103;
 * @return {!Array<!proto.odds.ProviderPlayer>}
 */
proto.odds.Provider.prototype.getProviderPlayersList = function() {
  return /** @type{!Array<!proto.odds.ProviderPlayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.ProviderPlayer, 103));
};


/**
 * @param {!Array<!proto.odds.ProviderPlayer>} value
 * @return {!proto.odds.Provider} returns this
*/
proto.odds.Provider.prototype.setProviderPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 103, value);
};


/**
 * @param {!proto.odds.ProviderPlayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderPlayer}
 */
proto.odds.Provider.prototype.addProviderPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 103, opt_value, proto.odds.ProviderPlayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.clearProviderPlayersList = function() {
  return this.setProviderPlayersList([]);
};


/**
 * repeated ProviderOutcome provider_outcomes = 117;
 * @return {!Array<!proto.odds.ProviderOutcome>}
 */
proto.odds.Provider.prototype.getProviderOutcomesList = function() {
  return /** @type{!Array<!proto.odds.ProviderOutcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.ProviderOutcome, 117));
};


/**
 * @param {!Array<!proto.odds.ProviderOutcome>} value
 * @return {!proto.odds.Provider} returns this
*/
proto.odds.Provider.prototype.setProviderOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 117, value);
};


/**
 * @param {!proto.odds.ProviderOutcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderOutcome}
 */
proto.odds.Provider.prototype.addProviderOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 117, opt_value, proto.odds.ProviderOutcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.clearProviderOutcomesList = function() {
  return this.setProviderOutcomesList([]);
};


/**
 * repeated ProviderCompetition provider_competitions = 128;
 * @return {!Array<!proto.odds.ProviderCompetition>}
 */
proto.odds.Provider.prototype.getProviderCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.ProviderCompetition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.ProviderCompetition, 128));
};


/**
 * @param {!Array<!proto.odds.ProviderCompetition>} value
 * @return {!proto.odds.Provider} returns this
*/
proto.odds.Provider.prototype.setProviderCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 128, value);
};


/**
 * @param {!proto.odds.ProviderCompetition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderCompetition}
 */
proto.odds.Provider.prototype.addProviderCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 128, opt_value, proto.odds.ProviderCompetition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Provider} returns this
 */
proto.odds.Provider.prototype.clearProviderCompetitionsList = function() {
  return this.setProviderCompetitionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CreateOfferEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CreateOfferEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CreateOfferEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateOfferEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveProviderId: jspb.Message.getFieldWithDefault(msg, 111, 0),
    prematchProviderId: jspb.Message.getFieldWithDefault(msg, 112, 0),
    liveEventId: jspb.Message.getFieldWithDefault(msg, 113, ""),
    prematchEventId: jspb.Message.getFieldWithDefault(msg, 114, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CreateOfferEvent}
 */
proto.odds.CreateOfferEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CreateOfferEvent;
  return proto.odds.CreateOfferEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CreateOfferEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CreateOfferEvent}
 */
proto.odds.CreateOfferEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 111:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveProviderId(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchProviderId(value);
      break;
    case 113:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveEventId(value);
      break;
    case 114:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CreateOfferEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CreateOfferEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CreateOfferEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateOfferEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveProviderId();
  if (f !== 0) {
    writer.writeInt32(
      111,
      f
    );
  }
  f = message.getPrematchProviderId();
  if (f !== 0) {
    writer.writeInt32(
      112,
      f
    );
  }
  f = message.getLiveEventId();
  if (f.length > 0) {
    writer.writeString(
      113,
      f
    );
  }
  f = message.getPrematchEventId();
  if (f.length > 0) {
    writer.writeString(
      114,
      f
    );
  }
};


/**
 * optional int32 live_provider_id = 111;
 * @return {number}
 */
proto.odds.CreateOfferEvent.prototype.getLiveProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 111, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CreateOfferEvent} returns this
 */
proto.odds.CreateOfferEvent.prototype.setLiveProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 111, value);
};


/**
 * optional int32 prematch_provider_id = 112;
 * @return {number}
 */
proto.odds.CreateOfferEvent.prototype.getPrematchProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 112, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CreateOfferEvent} returns this
 */
proto.odds.CreateOfferEvent.prototype.setPrematchProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 112, value);
};


/**
 * optional string live_event_id = 113;
 * @return {string}
 */
proto.odds.CreateOfferEvent.prototype.getLiveEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 113, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CreateOfferEvent} returns this
 */
proto.odds.CreateOfferEvent.prototype.setLiveEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 113, value);
};


/**
 * optional string prematch_event_id = 114;
 * @return {string}
 */
proto.odds.CreateOfferEvent.prototype.getPrematchEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 114, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CreateOfferEvent} returns this
 */
proto.odds.CreateOfferEvent.prototype.setPrematchEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 114, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CreateOfferRequest.repeatedFields_ = [115];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CreateOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CreateOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CreateOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.CreateOfferEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CreateOfferRequest}
 */
proto.odds.CreateOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CreateOfferRequest;
  return proto.odds.CreateOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CreateOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CreateOfferRequest}
 */
proto.odds.CreateOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 115:
      var value = new proto.odds.CreateOfferEvent;
      reader.readMessage(value,proto.odds.CreateOfferEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CreateOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CreateOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CreateOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      115,
      f,
      proto.odds.CreateOfferEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateOfferEvent events = 115;
 * @return {!Array<!proto.odds.CreateOfferEvent>}
 */
proto.odds.CreateOfferRequest.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.CreateOfferEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.CreateOfferEvent, 115));
};


/**
 * @param {!Array<!proto.odds.CreateOfferEvent>} value
 * @return {!proto.odds.CreateOfferRequest} returns this
*/
proto.odds.CreateOfferRequest.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 115, value);
};


/**
 * @param {!proto.odds.CreateOfferEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.CreateOfferEvent}
 */
proto.odds.CreateOfferRequest.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 115, opt_value, proto.odds.CreateOfferEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CreateOfferRequest} returns this
 */
proto.odds.CreateOfferRequest.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetAllLandBaseOffersResponse.repeatedFields_ = [59];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAllLandBaseOffersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAllLandBaseOffersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAllLandBaseOffersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllLandBaseOffersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    proto.odds.SingleLandBaseOfferResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAllLandBaseOffersResponse}
 */
proto.odds.GetAllLandBaseOffersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAllLandBaseOffersResponse;
  return proto.odds.GetAllLandBaseOffersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAllLandBaseOffersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAllLandBaseOffersResponse}
 */
proto.odds.GetAllLandBaseOffersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 59:
      var value = new proto.odds.SingleLandBaseOfferResponse;
      reader.readMessage(value,proto.odds.SingleLandBaseOfferResponse.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAllLandBaseOffersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAllLandBaseOffersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAllLandBaseOffersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAllLandBaseOffersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      59,
      f,
      proto.odds.SingleLandBaseOfferResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SingleLandBaseOfferResponse offers = 59;
 * @return {!Array<!proto.odds.SingleLandBaseOfferResponse>}
 */
proto.odds.GetAllLandBaseOffersResponse.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.odds.SingleLandBaseOfferResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.SingleLandBaseOfferResponse, 59));
};


/**
 * @param {!Array<!proto.odds.SingleLandBaseOfferResponse>} value
 * @return {!proto.odds.GetAllLandBaseOffersResponse} returns this
*/
proto.odds.GetAllLandBaseOffersResponse.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 59, value);
};


/**
 * @param {!proto.odds.SingleLandBaseOfferResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.SingleLandBaseOfferResponse}
 */
proto.odds.GetAllLandBaseOffersResponse.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 59, opt_value, proto.odds.SingleLandBaseOfferResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetAllLandBaseOffersResponse} returns this
 */
proto.odds.GetAllLandBaseOffersResponse.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SingleLandBaseOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SingleLandBaseOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SingleLandBaseOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 58, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SingleLandBaseOfferResponse}
 */
proto.odds.SingleLandBaseOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SingleLandBaseOfferResponse;
  return proto.odds.SingleLandBaseOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SingleLandBaseOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SingleLandBaseOfferResponse}
 */
proto.odds.SingleLandBaseOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 56:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 57:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SingleLandBaseOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SingleLandBaseOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SingleLandBaseOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      58,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 56;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 56));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.SingleLandBaseOfferResponse} returns this
*/
proto.odds.SingleLandBaseOfferResponse.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.SingleLandBaseOfferResponse} returns this
 */
proto.odds.SingleLandBaseOfferResponse.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.hasStart = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional google.protobuf.Timestamp end = 57;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 57));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.SingleLandBaseOfferResponse} returns this
*/
proto.odds.SingleLandBaseOfferResponse.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.SingleLandBaseOfferResponse} returns this
 */
proto.odds.SingleLandBaseOfferResponse.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional int32 id = 58;
 * @return {number}
 */
proto.odds.SingleLandBaseOfferResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.SingleLandBaseOfferResponse} returns this
 */
proto.odds.SingleLandBaseOfferResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CreateLandBaseOfferRequest.repeatedFields_ = [62];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CreateLandBaseOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CreateLandBaseOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateLandBaseOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 62)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CreateLandBaseOfferRequest}
 */
proto.odds.CreateLandBaseOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CreateLandBaseOfferRequest;
  return proto.odds.CreateLandBaseOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CreateLandBaseOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CreateLandBaseOfferRequest}
 */
proto.odds.CreateLandBaseOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 60:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 61:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CreateLandBaseOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CreateLandBaseOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateLandBaseOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      62,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 60;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 60));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
*/
proto.odds.CreateLandBaseOfferRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
 */
proto.odds.CreateLandBaseOfferRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional google.protobuf.Timestamp end = 61;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 61));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
*/
proto.odds.CreateLandBaseOfferRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
 */
proto.odds.CreateLandBaseOfferRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * repeated string event_ids = 62;
 * @return {!Array<string>}
 */
proto.odds.CreateLandBaseOfferRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 62));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
 */
proto.odds.CreateLandBaseOfferRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 62, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
 */
proto.odds.CreateLandBaseOfferRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 62, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CreateLandBaseOfferRequest} returns this
 */
proto.odds.CreateLandBaseOfferRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CreateLandBaseOfferResponse.repeatedFields_ = [62];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CreateLandBaseOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CreateLandBaseOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateLandBaseOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 62)) == null ? undefined : f,
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 71, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CreateLandBaseOfferResponse}
 */
proto.odds.CreateLandBaseOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CreateLandBaseOfferResponse;
  return proto.odds.CreateLandBaseOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CreateLandBaseOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CreateLandBaseOfferResponse}
 */
proto.odds.CreateLandBaseOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 60:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 61:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CreateLandBaseOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CreateLandBaseOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CreateLandBaseOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      62,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      71,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 60;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 60));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
*/
proto.odds.CreateLandBaseOfferResponse.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.hasStart = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional google.protobuf.Timestamp end = 61;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 61));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
*/
proto.odds.CreateLandBaseOfferResponse.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * repeated string event_ids = 62;
 * @return {!Array<string>}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 62));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 62, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 62, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
*/
proto.odds.CreateLandBaseOfferResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional int32 id = 71;
 * @return {number}
 */
proto.odds.CreateLandBaseOfferResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CreateLandBaseOfferResponse} returns this
 */
proto.odds.CreateLandBaseOfferResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 71, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.TimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.TimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.TimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.TimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.TimeRange}
 */
proto.odds.TimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.TimeRange;
  return proto.odds.TimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.TimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.TimeRange}
 */
proto.odds.TimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 64:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 65:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.TimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.TimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.TimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.TimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 64;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.TimeRange.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 64));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.TimeRange} returns this
*/
proto.odds.TimeRange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 64, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.TimeRange} returns this
 */
proto.odds.TimeRange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.TimeRange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional google.protobuf.Timestamp end = 65;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.TimeRange.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 65));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.TimeRange} returns this
*/
proto.odds.TimeRange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.TimeRange} returns this
 */
proto.odds.TimeRange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.TimeRange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 65) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseId.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 66, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseId}
 */
proto.odds.LandBaseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseId;
  return proto.odds.LandBaseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseId}
 */
proto.odds.LandBaseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 66:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      66,
      f
    );
  }
};


/**
 * optional int32 id = 66;
 * @return {number}
 */
proto.odds.LandBaseId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseId} returns this
 */
proto.odds.LandBaseId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 66, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EditLandBaseOfferRequest.repeatedFields_ = [75];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditLandBaseOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditLandBaseOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditLandBaseOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditLandBaseOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 75)) == null ? undefined : f,
    id: jspb.Message.getFieldWithDefault(msg, 76, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditLandBaseOfferRequest}
 */
proto.odds.EditLandBaseOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditLandBaseOfferRequest;
  return proto.odds.EditLandBaseOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditLandBaseOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditLandBaseOfferRequest}
 */
proto.odds.EditLandBaseOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 73:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 74:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    case 76:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditLandBaseOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditLandBaseOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditLandBaseOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditLandBaseOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      75,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      76,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 73;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.EditLandBaseOfferRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 73));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
*/
proto.odds.EditLandBaseOfferRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 73, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
 */
proto.odds.EditLandBaseOfferRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditLandBaseOfferRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional google.protobuf.Timestamp end = 74;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.EditLandBaseOfferRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 74));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
*/
proto.odds.EditLandBaseOfferRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 74, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
 */
proto.odds.EditLandBaseOfferRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditLandBaseOfferRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * repeated string event_ids = 75;
 * @return {!Array<string>}
 */
proto.odds.EditLandBaseOfferRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 75));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
 */
proto.odds.EditLandBaseOfferRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 75, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
 */
proto.odds.EditLandBaseOfferRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 75, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
 */
proto.odds.EditLandBaseOfferRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional int32 id = 76;
 * @return {number}
 */
proto.odds.EditLandBaseOfferRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditLandBaseOfferRequest} returns this
 */
proto.odds.EditLandBaseOfferRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 76, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EditLandBaseOfferResponse.repeatedFields_ = [69];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditLandBaseOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditLandBaseOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditLandBaseOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditLandBaseOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 69)) == null ? undefined : f,
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 72, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditLandBaseOfferResponse}
 */
proto.odds.EditLandBaseOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditLandBaseOfferResponse;
  return proto.odds.EditLandBaseOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditLandBaseOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditLandBaseOfferResponse}
 */
proto.odds.EditLandBaseOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 67:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 68:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditLandBaseOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditLandBaseOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditLandBaseOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditLandBaseOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      69,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      72,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 67;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.EditLandBaseOfferResponse.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 67));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
*/
proto.odds.EditLandBaseOfferResponse.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditLandBaseOfferResponse.prototype.hasStart = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional google.protobuf.Timestamp end = 68;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.EditLandBaseOfferResponse.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 68));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
*/
proto.odds.EditLandBaseOfferResponse.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 68, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditLandBaseOfferResponse.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * repeated string event_ids = 69;
 * @return {!Array<string>}
 */
proto.odds.EditLandBaseOfferResponse.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 69));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 69, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 69, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.EditLandBaseOfferResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
*/
proto.odds.EditLandBaseOfferResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditLandBaseOfferResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional int32 id = 72;
 * @return {number}
 */
proto.odds.EditLandBaseOfferResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 72, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditLandBaseOfferResponse} returns this
 */
proto.odds.EditLandBaseOfferResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 72, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SearchEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SearchEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SearchEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteria: jspb.Message.getFieldWithDefault(msg, 79, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SearchEventsRequest}
 */
proto.odds.SearchEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SearchEventsRequest;
  return proto.odds.SearchEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SearchEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SearchEventsRequest}
 */
proto.odds.SearchEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SearchEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SearchEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SearchEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteria();
  if (f.length > 0) {
    writer.writeString(
      79,
      f
    );
  }
};


/**
 * optional string criteria = 79;
 * @return {string}
 */
proto.odds.SearchEventsRequest.prototype.getCriteria = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SearchEventsRequest} returns this
 */
proto.odds.SearchEventsRequest.prototype.setCriteria = function(value) {
  return jspb.Message.setProto3StringField(this, 79, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SearchOutcomesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SearchOutcomesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SearchOutcomesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchOutcomesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    outcomeCode: jspb.Message.getFieldWithDefault(msg, 81, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SearchOutcomesRequest}
 */
proto.odds.SearchOutcomesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SearchOutcomesRequest;
  return proto.odds.SearchOutcomesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SearchOutcomesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SearchOutcomesRequest}
 */
proto.odds.SearchOutcomesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SearchOutcomesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SearchOutcomesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SearchOutcomesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchOutcomesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOutcomeCode();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.SearchOutcomesRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SearchOutcomesRequest} returns this
 */
proto.odds.SearchOutcomesRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string outcome_code = 81;
 * @return {string}
 */
proto.odds.SearchOutcomesRequest.prototype.getOutcomeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SearchOutcomesRequest} returns this
 */
proto.odds.SearchOutcomesRequest.prototype.setOutcomeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SearchOutcomesResponse.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SearchOutcomesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SearchOutcomesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SearchOutcomesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchOutcomesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.Outcome.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SearchOutcomesResponse}
 */
proto.odds.SearchOutcomesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SearchOutcomesResponse;
  return proto.odds.SearchOutcomesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SearchOutcomesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SearchOutcomesResponse}
 */
proto.odds.SearchOutcomesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 27:
      var value = new proto.odds.Outcome;
      reader.readMessage(value,proto.odds.Outcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SearchOutcomesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SearchOutcomesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SearchOutcomesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchOutcomesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.odds.Outcome.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Outcome outcomes = 27;
 * @return {!Array<!proto.odds.Outcome>}
 */
proto.odds.SearchOutcomesResponse.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.Outcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Outcome, 27));
};


/**
 * @param {!Array<!proto.odds.Outcome>} value
 * @return {!proto.odds.SearchOutcomesResponse} returns this
*/
proto.odds.SearchOutcomesResponse.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.odds.Outcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Outcome}
 */
proto.odds.SearchOutcomesResponse.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.odds.Outcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SearchOutcomesResponse} returns this
 */
proto.odds.SearchOutcomesResponse.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.SearchOutcomesResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.SearchOutcomesResponse} returns this
*/
proto.odds.SearchOutcomesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.SearchOutcomesResponse} returns this
 */
proto.odds.SearchOutcomesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.SearchOutcomesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SearchCompetitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SearchCompetitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SearchCompetitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchCompetitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SearchCompetitorRequest}
 */
proto.odds.SearchCompetitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SearchCompetitorRequest;
  return proto.odds.SearchCompetitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SearchCompetitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SearchCompetitorRequest}
 */
proto.odds.SearchCompetitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SearchCompetitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SearchCompetitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SearchCompetitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SearchCompetitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.SearchCompetitorRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.SearchCompetitorRequest} returns this
 */
proto.odds.SearchCompetitorRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.SearchCompetitorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SearchCompetitorRequest} returns this
 */
proto.odds.SearchCompetitorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LinkCompetitorRequest.repeatedFields_ = [98];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LinkCompetitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LinkCompetitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LinkCompetitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkCompetitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    factoryCompetitorId: jspb.Message.getFieldWithDefault(msg, 83, 0),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.odds.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LinkCompetitorRequest}
 */
proto.odds.LinkCompetitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LinkCompetitorRequest;
  return proto.odds.LinkCompetitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LinkCompetitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LinkCompetitorRequest}
 */
proto.odds.LinkCompetitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 83:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitorId(value);
      break;
    case 98:
      var value = new proto.odds.Link;
      reader.readMessage(value,proto.odds.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LinkCompetitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LinkCompetitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LinkCompetitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkCompetitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFactoryCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      83,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      98,
      f,
      proto.odds.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 factory_competitor_id = 83;
 * @return {number}
 */
proto.odds.LinkCompetitorRequest.prototype.getFactoryCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LinkCompetitorRequest} returns this
 */
proto.odds.LinkCompetitorRequest.prototype.setFactoryCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};


/**
 * repeated Link links = 98;
 * @return {!Array<!proto.odds.Link>}
 */
proto.odds.LinkCompetitorRequest.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.odds.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Link, 98));
};


/**
 * @param {!Array<!proto.odds.Link>} value
 * @return {!proto.odds.LinkCompetitorRequest} returns this
*/
proto.odds.LinkCompetitorRequest.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 98, value);
};


/**
 * @param {!proto.odds.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Link}
 */
proto.odds.LinkCompetitorRequest.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 98, opt_value, proto.odds.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LinkCompetitorRequest} returns this
 */
proto.odds.LinkCompetitorRequest.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LinkCompetitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LinkCompetitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LinkCompetitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkCompetitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    liveCompetitionId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    prematchCompetitionId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LinkCompetitionRequest}
 */
proto.odds.LinkCompetitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LinkCompetitionRequest;
  return proto.odds.LinkCompetitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LinkCompetitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LinkCompetitionRequest}
 */
proto.odds.LinkCompetitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveCompetitionId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchCompetitionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LinkCompetitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LinkCompetitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LinkCompetitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkCompetitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLiveCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPrematchCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.LinkCompetitionRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LinkCompetitionRequest} returns this
 */
proto.odds.LinkCompetitionRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 live_competition_id = 5;
 * @return {number}
 */
proto.odds.LinkCompetitionRequest.prototype.getLiveCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LinkCompetitionRequest} returns this
 */
proto.odds.LinkCompetitionRequest.prototype.setLiveCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 prematch_competition_id = 6;
 * @return {number}
 */
proto.odds.LinkCompetitionRequest.prototype.getPrematchCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LinkCompetitionRequest} returns this
 */
proto.odds.LinkCompetitionRequest.prototype.setPrematchCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetStatusesForEventsAndOddsRequest.repeatedFields_ = [86,87];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetStatusesForEventsAndOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetStatusesForEventsAndOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetStatusesForEventsAndOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 86)) == null ? undefined : f,
    oddIdsList: (f = jspb.Message.getRepeatedField(msg, 87)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest}
 */
proto.odds.GetStatusesForEventsAndOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetStatusesForEventsAndOddsRequest;
  return proto.odds.GetStatusesForEventsAndOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetStatusesForEventsAndOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest}
 */
proto.odds.GetStatusesForEventsAndOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 86:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    case 87:
      var value = /** @type {string} */ (reader.readString());
      msg.addOddIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetStatusesForEventsAndOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetStatusesForEventsAndOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetStatusesForEventsAndOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      86,
      f
    );
  }
  f = message.getOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      87,
      f
    );
  }
};


/**
 * repeated string event_ids = 86;
 * @return {!Array<string>}
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 86));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest} returns this
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 86, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest} returns this
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 86, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest} returns this
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * repeated string odd_ids = 87;
 * @return {!Array<string>}
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.getOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 87));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest} returns this
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.setOddIdsList = function(value) {
  return jspb.Message.setField(this, 87, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest} returns this
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.addOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 87, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetStatusesForEventsAndOddsRequest} returns this
 */
proto.odds.GetStatusesForEventsAndOddsRequest.prototype.clearOddIdsList = function() {
  return this.setOddIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    result: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddStatus}
 */
proto.odds.OddStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddStatus;
  return proto.odds.OddStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddStatus}
 */
proto.odds.OddStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.OddStatus.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.odds.OddStatus.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.odds.OddStatus.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool is_live = 4;
 * @return {boolean}
 */
proto.odds.OddStatus.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_disabled = 5;
 * @return {boolean}
 */
proto.odds.OddStatus.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp start = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.OddStatus.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.OddStatus} returns this
*/
proto.odds.OddStatus.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.OddStatus.prototype.hasStart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.odds.OddStatus.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string result = 8;
 * @return {string}
 */
proto.odds.OddStatus.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatus} returns this
 */
proto.odds.OddStatus.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetStatusesForEventsAndOddsResponse.repeatedFields_ = [89];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetStatusesForEventsAndOddsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetStatusesForEventsAndOddsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetStatusesForEventsAndOddsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetStatusesForEventsAndOddsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.OddStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetStatusesForEventsAndOddsResponse}
 */
proto.odds.GetStatusesForEventsAndOddsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetStatusesForEventsAndOddsResponse;
  return proto.odds.GetStatusesForEventsAndOddsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetStatusesForEventsAndOddsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetStatusesForEventsAndOddsResponse}
 */
proto.odds.GetStatusesForEventsAndOddsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 89:
      var value = new proto.odds.OddStatus;
      reader.readMessage(value,proto.odds.OddStatus.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetStatusesForEventsAndOddsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetStatusesForEventsAndOddsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetStatusesForEventsAndOddsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetStatusesForEventsAndOddsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      89,
      f,
      proto.odds.OddStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OddStatus odds = 89;
 * @return {!Array<!proto.odds.OddStatus>}
 */
proto.odds.GetStatusesForEventsAndOddsResponse.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.OddStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OddStatus, 89));
};


/**
 * @param {!Array<!proto.odds.OddStatus>} value
 * @return {!proto.odds.GetStatusesForEventsAndOddsResponse} returns this
*/
proto.odds.GetStatusesForEventsAndOddsResponse.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 89, value);
};


/**
 * @param {!proto.odds.OddStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OddStatus}
 */
proto.odds.GetStatusesForEventsAndOddsResponse.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 89, opt_value, proto.odds.OddStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetStatusesForEventsAndOddsResponse} returns this
 */
proto.odds.GetStatusesForEventsAndOddsResponse.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderCompetitorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderCompetitorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderCompetitorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderCompetitorsRequest}
 */
proto.odds.GetProviderCompetitorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderCompetitorsRequest;
  return proto.odds.GetProviderCompetitorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderCompetitorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderCompetitorsRequest}
 */
proto.odds.GetProviderCompetitorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderCompetitorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderCompetitorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderCompetitorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetProviderCompetitorsRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetProviderCompetitorsRequest} returns this
 */
proto.odds.GetProviderCompetitorsRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetProviderCompetitorsResponse.repeatedFields_ = [38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderCompetitorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderCompetitorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderCompetitorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.odds.Provider.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderCompetitorsResponse}
 */
proto.odds.GetProviderCompetitorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderCompetitorsResponse;
  return proto.odds.GetProviderCompetitorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderCompetitorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderCompetitorsResponse}
 */
proto.odds.GetProviderCompetitorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 38:
      var value = new proto.odds.Provider;
      reader.readMessage(value,proto.odds.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderCompetitorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderCompetitorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderCompetitorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.odds.Provider.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Provider providers = 38;
 * @return {!Array<!proto.odds.Provider>}
 */
proto.odds.GetProviderCompetitorsResponse.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.odds.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Provider, 38));
};


/**
 * @param {!Array<!proto.odds.Provider>} value
 * @return {!proto.odds.GetProviderCompetitorsResponse} returns this
*/
proto.odds.GetProviderCompetitorsResponse.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.odds.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Provider}
 */
proto.odds.GetProviderCompetitorsResponse.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.odds.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetProviderCompetitorsResponse} returns this
 */
proto.odds.GetProviderCompetitorsResponse.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetProviderCompetitorsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetProviderCompetitorsResponse} returns this
*/
proto.odds.GetProviderCompetitorsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetProviderCompetitorsResponse} returns this
 */
proto.odds.GetProviderCompetitorsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetProviderCompetitorsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderCompetitionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderCompetitionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderCompetitionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderCompetitionsRequest}
 */
proto.odds.GetProviderCompetitionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderCompetitionsRequest;
  return proto.odds.GetProviderCompetitionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderCompetitionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderCompetitionsRequest}
 */
proto.odds.GetProviderCompetitionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderCompetitionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderCompetitionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderCompetitionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetProviderCompetitionsRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetProviderCompetitionsRequest} returns this
 */
proto.odds.GetProviderCompetitionsRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetProviderCompetitionsResponse.repeatedFields_ = [38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderCompetitionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderCompetitionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderCompetitionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.odds.Provider.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderCompetitionsResponse}
 */
proto.odds.GetProviderCompetitionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderCompetitionsResponse;
  return proto.odds.GetProviderCompetitionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderCompetitionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderCompetitionsResponse}
 */
proto.odds.GetProviderCompetitionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 38:
      var value = new proto.odds.Provider;
      reader.readMessage(value,proto.odds.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderCompetitionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderCompetitionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderCompetitionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderCompetitionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.odds.Provider.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Provider providers = 38;
 * @return {!Array<!proto.odds.Provider>}
 */
proto.odds.GetProviderCompetitionsResponse.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.odds.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Provider, 38));
};


/**
 * @param {!Array<!proto.odds.Provider>} value
 * @return {!proto.odds.GetProviderCompetitionsResponse} returns this
*/
proto.odds.GetProviderCompetitionsResponse.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.odds.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Provider}
 */
proto.odds.GetProviderCompetitionsResponse.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.odds.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetProviderCompetitionsResponse} returns this
 */
proto.odds.GetProviderCompetitionsResponse.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetProviderCompetitionsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetProviderCompetitionsResponse} returns this
*/
proto.odds.GetProviderCompetitionsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetProviderCompetitionsResponse} returns this
 */
proto.odds.GetProviderCompetitionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetProviderCompetitionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.ProviderCompetitor.repeatedFields_ = [45,46];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ProviderCompetitor.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ProviderCompetitor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ProviderCompetitor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderCompetitor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionNamesList: (f = jspb.Message.getRepeatedField(msg, 45)) == null ? undefined : f,
    locationList: (f = jspb.Message.getRepeatedField(msg, 46)) == null ? undefined : f,
    factoryCompetitorId: jspb.Message.getFieldWithDefault(msg, 83, 0),
    factoryCompetitorName: jspb.Message.getFieldWithDefault(msg, 96, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ProviderCompetitor}
 */
proto.odds.ProviderCompetitor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ProviderCompetitor;
  return proto.odds.ProviderCompetitor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ProviderCompetitor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ProviderCompetitor}
 */
proto.odds.ProviderCompetitor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompetitionNames(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocation(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitorId(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryCompetitorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ProviderCompetitor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ProviderCompetitor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ProviderCompetitor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderCompetitor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      45,
      f
    );
  }
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      46,
      f
    );
  }
  f = message.getFactoryCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      83,
      f
    );
  }
  f = message.getFactoryCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.ProviderCompetitor.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.ProviderCompetitor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string competition_names = 45;
 * @return {!Array<string>}
 */
proto.odds.ProviderCompetitor.prototype.getCompetitionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 45));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.setCompetitionNamesList = function(value) {
  return jspb.Message.setField(this, 45, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.addCompetitionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 45, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.clearCompetitionNamesList = function() {
  return this.setCompetitionNamesList([]);
};


/**
 * repeated string location = 46;
 * @return {!Array<string>}
 */
proto.odds.ProviderCompetitor.prototype.getLocationList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 46));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.setLocationList = function(value) {
  return jspb.Message.setField(this, 46, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.addLocation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 46, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.clearLocationList = function() {
  return this.setLocationList([]);
};


/**
 * optional int32 factory_competitor_id = 83;
 * @return {number}
 */
proto.odds.ProviderCompetitor.prototype.getFactoryCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.setFactoryCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};


/**
 * optional string factory_competitor_name = 96;
 * @return {string}
 */
proto.odds.ProviderCompetitor.prototype.getFactoryCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderCompetitor} returns this
 */
proto.odds.ProviderCompetitor.prototype.setFactoryCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ProviderPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ProviderPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ProviderPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    providerCompetitorId: jspb.Message.getFieldWithDefault(msg, 82, 0),
    providerCompetitorName: jspb.Message.getFieldWithDefault(msg, 100, ""),
    factoryPlayerId: jspb.Message.getFieldWithDefault(msg, 102, 0),
    factoryPlayerName: jspb.Message.getFieldWithDefault(msg, 104, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ProviderPlayer}
 */
proto.odds.ProviderPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ProviderPlayer;
  return proto.odds.ProviderPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ProviderPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ProviderPlayer}
 */
proto.odds.ProviderPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderCompetitorId(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderCompetitorName(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryPlayerId(value);
      break;
    case 104:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryPlayerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ProviderPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ProviderPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ProviderPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProviderCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      82,
      f
    );
  }
  f = message.getProviderCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getFactoryPlayerId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
  f = message.getFactoryPlayerName();
  if (f.length > 0) {
    writer.writeString(
      104,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.ProviderPlayer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderPlayer} returns this
 */
proto.odds.ProviderPlayer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.ProviderPlayer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderPlayer} returns this
 */
proto.odds.ProviderPlayer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 provider_competitor_id = 82;
 * @return {number}
 */
proto.odds.ProviderPlayer.prototype.getProviderCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 82, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderPlayer} returns this
 */
proto.odds.ProviderPlayer.prototype.setProviderCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 82, value);
};


/**
 * optional string provider_competitor_name = 100;
 * @return {string}
 */
proto.odds.ProviderPlayer.prototype.getProviderCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderPlayer} returns this
 */
proto.odds.ProviderPlayer.prototype.setProviderCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional int32 factory_player_id = 102;
 * @return {number}
 */
proto.odds.ProviderPlayer.prototype.getFactoryPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderPlayer} returns this
 */
proto.odds.ProviderPlayer.prototype.setFactoryPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};


/**
 * optional string factory_player_name = 104;
 * @return {string}
 */
proto.odds.ProviderPlayer.prototype.getFactoryPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 104, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderPlayer} returns this
 */
proto.odds.ProviderPlayer.prototype.setFactoryPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 104, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ProviderOutcome.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ProviderOutcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ProviderOutcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderOutcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    factorySportId: jspb.Message.getFieldWithDefault(msg, 34, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ProviderOutcome}
 */
proto.odds.ProviderOutcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ProviderOutcome;
  return proto.odds.ProviderOutcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ProviderOutcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ProviderOutcome}
 */
proto.odds.ProviderOutcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactorySportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ProviderOutcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ProviderOutcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ProviderOutcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ProviderOutcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFactorySportId();
  if (f !== 0) {
    writer.writeInt32(
      34,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.ProviderOutcome.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderOutcome} returns this
 */
proto.odds.ProviderOutcome.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.ProviderOutcome.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ProviderOutcome} returns this
 */
proto.odds.ProviderOutcome.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 factory_sport_id = 34;
 * @return {number}
 */
proto.odds.ProviderOutcome.prototype.getFactorySportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ProviderOutcome} returns this
 */
proto.odds.ProviderOutcome.prototype.setFactorySportId = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPlayersResponse.repeatedFields_ = [138];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPlayersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPlayersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPlayersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.odds.Player.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPlayersResponse}
 */
proto.odds.GetPlayersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPlayersResponse;
  return proto.odds.GetPlayersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPlayersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPlayersResponse}
 */
proto.odds.GetPlayersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 138:
      var value = new proto.odds.Player;
      reader.readMessage(value,proto.odds.Player.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPlayersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPlayersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPlayersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      138,
      f,
      proto.odds.Player.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Player players = 138;
 * @return {!Array<!proto.odds.Player>}
 */
proto.odds.GetPlayersResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.odds.Player>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Player, 138));
};


/**
 * @param {!Array<!proto.odds.Player>} value
 * @return {!proto.odds.GetPlayersResponse} returns this
*/
proto.odds.GetPlayersResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 138, value);
};


/**
 * @param {!proto.odds.Player=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Player}
 */
proto.odds.GetPlayersResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 138, opt_value, proto.odds.Player, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayersResponse} returns this
 */
proto.odds.GetPlayersResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Player.repeatedFields_ = [45,98];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Player.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Player.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Player} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Player.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    factoryCompetitorName: jspb.Message.getFieldWithDefault(msg, 96, ""),
    competitionNamesList: (f = jspb.Message.getRepeatedField(msg, 45)) == null ? undefined : f,
    locationName: jspb.Message.getFieldWithDefault(msg, 108, ""),
    sport: jspb.Message.getFieldWithDefault(msg, 47, ""),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.odds.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Player}
 */
proto.odds.Player.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Player;
  return proto.odds.Player.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Player} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Player}
 */
proto.odds.Player.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryCompetitorName(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompetitionNames(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setSport(value);
      break;
    case 50:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    case 98:
      var value = new proto.odds.Link;
      reader.readMessage(value,proto.odds.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Player.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Player.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Player} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Player.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getFactoryCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getCompetitionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      45,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      98,
      f,
      proto.odds.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Player.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Player.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.Player.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string factory_competitor_name = 96;
 * @return {string}
 */
proto.odds.Player.prototype.getFactoryCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setFactoryCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * repeated string competition_names = 45;
 * @return {!Array<string>}
 */
proto.odds.Player.prototype.getCompetitionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 45));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setCompetitionNamesList = function(value) {
  return jspb.Message.setField(this, 45, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.addCompetitionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 45, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.clearCompetitionNamesList = function() {
  return this.setCompetitionNamesList([]);
};


/**
 * optional string location_name = 108;
 * @return {string}
 */
proto.odds.Player.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * optional string sport = 47;
 * @return {string}
 */
proto.odds.Player.prototype.getSport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.setSport = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional google.protobuf.Timestamp last_updated = 50;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Player.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 50));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Player} returns this
*/
proto.odds.Player.prototype.setLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.clearLastUpdated = function() {
  return this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Player.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * repeated Link links = 98;
 * @return {!Array<!proto.odds.Link>}
 */
proto.odds.Player.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.odds.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Link, 98));
};


/**
 * @param {!Array<!proto.odds.Link>} value
 * @return {!proto.odds.Player} returns this
*/
proto.odds.Player.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 98, value);
};


/**
 * @param {!proto.odds.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Link}
 */
proto.odds.Player.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 98, opt_value, proto.odds.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Player} returns this
 */
proto.odds.Player.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddPlayerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddPlayerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddPlayerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddPlayerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    factoryCompetitorId: jspb.Message.getFieldWithDefault(msg, 83, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddPlayerRequest}
 */
proto.odds.AddPlayerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddPlayerRequest;
  return proto.odds.AddPlayerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddPlayerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddPlayerRequest}
 */
proto.odds.AddPlayerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddPlayerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddPlayerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddPlayerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddPlayerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getFactoryCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      83,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.AddPlayerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddPlayerRequest} returns this
 */
proto.odds.AddPlayerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.AddPlayerRequest.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddPlayerRequest} returns this
 */
proto.odds.AddPlayerRequest.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 factory_competitor_id = 83;
 * @return {number}
 */
proto.odds.AddPlayerRequest.prototype.getFactoryCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddPlayerRequest} returns this
 */
proto.odds.AddPlayerRequest.prototype.setFactoryCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddPlayerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddPlayerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddPlayerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddPlayerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    factoryCompetitorId: jspb.Message.getFieldWithDefault(msg, 83, 0),
    factoryCompetitorName: jspb.Message.getFieldWithDefault(msg, 96, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddPlayerResponse}
 */
proto.odds.AddPlayerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddPlayerResponse;
  return proto.odds.AddPlayerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddPlayerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddPlayerResponse}
 */
proto.odds.AddPlayerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryCompetitorId(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryCompetitorName(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddPlayerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddPlayerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddPlayerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddPlayerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getFactoryCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      83,
      f
    );
  }
  f = message.getFactoryCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.AddPlayerResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddPlayerResponse} returns this
 */
proto.odds.AddPlayerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.AddPlayerResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddPlayerResponse} returns this
 */
proto.odds.AddPlayerResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.AddPlayerResponse.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddPlayerResponse} returns this
 */
proto.odds.AddPlayerResponse.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 factory_competitor_id = 83;
 * @return {number}
 */
proto.odds.AddPlayerResponse.prototype.getFactoryCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddPlayerResponse} returns this
 */
proto.odds.AddPlayerResponse.prototype.setFactoryCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 83, value);
};


/**
 * optional string factory_competitor_name = 96;
 * @return {string}
 */
proto.odds.AddPlayerResponse.prototype.getFactoryCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddPlayerResponse} returns this
 */
proto.odds.AddPlayerResponse.prototype.setFactoryCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.AddPlayerResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.AddPlayerResponse} returns this
*/
proto.odds.AddPlayerResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.AddPlayerResponse} returns this
 */
proto.odds.AddPlayerResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.AddPlayerResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditPlayer}
 */
proto.odds.EditPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditPlayer;
  return proto.odds.EditPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditPlayer}
 */
proto.odds.EditPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.EditPlayer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditPlayer} returns this
 */
proto.odds.EditPlayer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.EditPlayer.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditPlayer} returns this
 */
proto.odds.EditPlayer.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.EditPlayer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditPlayer} returns this
 */
proto.odds.EditPlayer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.EditPlayer.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.EditPlayer} returns this
*/
proto.odds.EditPlayer.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditPlayer} returns this
 */
proto.odds.EditPlayer.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditPlayer.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LinkPlayerRequest.repeatedFields_ = [98];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LinkPlayerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LinkPlayerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LinkPlayerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkPlayerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    factoryPlayerId: jspb.Message.getFieldWithDefault(msg, 102, 0),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.odds.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LinkPlayerRequest}
 */
proto.odds.LinkPlayerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LinkPlayerRequest;
  return proto.odds.LinkPlayerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LinkPlayerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LinkPlayerRequest}
 */
proto.odds.LinkPlayerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFactoryPlayerId(value);
      break;
    case 98:
      var value = new proto.odds.Link;
      reader.readMessage(value,proto.odds.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LinkPlayerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LinkPlayerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LinkPlayerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkPlayerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFactoryPlayerId();
  if (f !== 0) {
    writer.writeInt32(
      102,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      98,
      f,
      proto.odds.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 factory_player_id = 102;
 * @return {number}
 */
proto.odds.LinkPlayerRequest.prototype.getFactoryPlayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LinkPlayerRequest} returns this
 */
proto.odds.LinkPlayerRequest.prototype.setFactoryPlayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};


/**
 * repeated Link links = 98;
 * @return {!Array<!proto.odds.Link>}
 */
proto.odds.LinkPlayerRequest.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.odds.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Link, 98));
};


/**
 * @param {!Array<!proto.odds.Link>} value
 * @return {!proto.odds.LinkPlayerRequest} returns this
*/
proto.odds.LinkPlayerRequest.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 98, value);
};


/**
 * @param {!proto.odds.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Link}
 */
proto.odds.LinkPlayerRequest.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 98, opt_value, proto.odds.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LinkPlayerRequest} returns this
 */
proto.odds.LinkPlayerRequest.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderPlayersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderPlayersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderPlayersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderPlayersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderPlayersRequest}
 */
proto.odds.GetProviderPlayersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderPlayersRequest;
  return proto.odds.GetProviderPlayersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderPlayersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderPlayersRequest}
 */
proto.odds.GetProviderPlayersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderPlayersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderPlayersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderPlayersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderPlayersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetProviderPlayersRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetProviderPlayersRequest} returns this
 */
proto.odds.GetProviderPlayersRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetProviderPlayersResponse.repeatedFields_ = [38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderPlayersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderPlayersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderPlayersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderPlayersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.odds.Provider.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderPlayersResponse}
 */
proto.odds.GetProviderPlayersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderPlayersResponse;
  return proto.odds.GetProviderPlayersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderPlayersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderPlayersResponse}
 */
proto.odds.GetProviderPlayersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 38:
      var value = new proto.odds.Provider;
      reader.readMessage(value,proto.odds.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderPlayersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderPlayersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderPlayersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderPlayersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.odds.Provider.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Provider providers = 38;
 * @return {!Array<!proto.odds.Provider>}
 */
proto.odds.GetProviderPlayersResponse.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.odds.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Provider, 38));
};


/**
 * @param {!Array<!proto.odds.Provider>} value
 * @return {!proto.odds.GetProviderPlayersResponse} returns this
*/
proto.odds.GetProviderPlayersResponse.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.odds.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Provider}
 */
proto.odds.GetProviderPlayersResponse.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.odds.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetProviderPlayersResponse} returns this
 */
proto.odds.GetProviderPlayersResponse.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetProviderPlayersResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetProviderPlayersResponse} returns this
*/
proto.odds.GetProviderPlayersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetProviderPlayersResponse} returns this
 */
proto.odds.GetProviderPlayersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetProviderPlayersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.AddCompetitorRequest.repeatedFields_ = [41];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddCompetitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddCompetitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddCompetitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0),
    competitionIdsList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddCompetitorRequest}
 */
proto.odds.AddCompetitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddCompetitorRequest;
  return proto.odds.AddCompetitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddCompetitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddCompetitorRequest}
 */
proto.odds.AddCompetitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 41:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompetitionIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddCompetitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddCompetitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddCompetitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
  f = message.getCompetitionIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      41,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.AddCompetitorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.AddCompetitorRequest.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.AddCompetitorRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.AddCompetitorRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * repeated int32 competition_ids = 41;
 * @return {!Array<number>}
 */
proto.odds.AddCompetitorRequest.prototype.getCompetitionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.setCompetitionIdsList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.addCompetitionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.AddCompetitorRequest} returns this
 */
proto.odds.AddCompetitorRequest.prototype.clearCompetitionIdsList = function() {
  return this.setCompetitionIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddCompetitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddCompetitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddCompetitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddCompetitionRequest}
 */
proto.odds.AddCompetitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddCompetitionRequest;
  return proto.odds.AddCompetitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddCompetitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddCompetitionRequest}
 */
proto.odds.AddCompetitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddCompetitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddCompetitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddCompetitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.AddCompetitionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitionRequest} returns this
 */
proto.odds.AddCompetitionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.AddCompetitionRequest.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitionRequest} returns this
 */
proto.odds.AddCompetitionRequest.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.AddCompetitionRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitionRequest} returns this
 */
proto.odds.AddCompetitionRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.AddCompetitionRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitionRequest} returns this
 */
proto.odds.AddCompetitionRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddCompetitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddCompetitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddCompetitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddCompetitionResponse}
 */
proto.odds.AddCompetitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddCompetitionResponse;
  return proto.odds.AddCompetitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddCompetitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddCompetitionResponse}
 */
proto.odds.AddCompetitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddCompetitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddCompetitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddCompetitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.AddCompetitionResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitionResponse} returns this
 */
proto.odds.AddCompetitionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.AddCompetitionResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitionResponse} returns this
 */
proto.odds.AddCompetitionResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.AddCompetitionResponse.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitionResponse} returns this
 */
proto.odds.AddCompetitionResponse.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.AddCompetitionResponse.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitionResponse} returns this
 */
proto.odds.AddCompetitionResponse.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.AddCompetitionResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.AddCompetitionResponse} returns this
*/
proto.odds.AddCompetitionResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.AddCompetitionResponse} returns this
 */
proto.odds.AddCompetitionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.AddCompetitionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.AddCompetitorResponse.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddCompetitorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddCompetitorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddCompetitorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddCompetitorResponse}
 */
proto.odds.AddCompetitorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddCompetitorResponse;
  return proto.odds.AddCompetitorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddCompetitorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddCompetitorResponse}
 */
proto.odds.AddCompetitorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 25:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddCompetitorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddCompetitorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddCompetitorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddCompetitorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.AddCompetitorResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitorResponse} returns this
 */
proto.odds.AddCompetitorResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.AddCompetitorResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitorResponse} returns this
 */
proto.odds.AddCompetitorResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.AddCompetitorResponse.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddCompetitorResponse} returns this
 */
proto.odds.AddCompetitorResponse.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.AddCompetitorResponse.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AddCompetitorResponse} returns this
 */
proto.odds.AddCompetitorResponse.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Competition competitions = 25;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.AddCompetitorResponse.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 25));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.AddCompetitorResponse} returns this
*/
proto.odds.AddCompetitorResponse.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.AddCompetitorResponse.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.AddCompetitorResponse} returns this
 */
proto.odds.AddCompetitorResponse.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.AddCompetitorResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.AddCompetitorResponse} returns this
*/
proto.odds.AddCompetitorResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.AddCompetitorResponse} returns this
 */
proto.odds.AddCompetitorResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.AddCompetitorResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EditCompetitorRequest.repeatedFields_ = [41];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditCompetitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditCompetitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditCompetitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditCompetitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0),
    competitionIdsList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditCompetitorRequest}
 */
proto.odds.EditCompetitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditCompetitorRequest;
  return proto.odds.EditCompetitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditCompetitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditCompetitorRequest}
 */
proto.odds.EditCompetitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 41:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompetitionIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditCompetitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditCompetitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditCompetitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditCompetitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
  f = message.getCompetitionIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      41,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.EditCompetitorRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.EditCompetitorRequest.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.EditCompetitorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.EditCompetitorRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.EditCompetitorRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * repeated int32 competition_ids = 41;
 * @return {!Array<number>}
 */
proto.odds.EditCompetitorRequest.prototype.getCompetitionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.setCompetitionIdsList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.addCompetitionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EditCompetitorRequest} returns this
 */
proto.odds.EditCompetitorRequest.prototype.clearCompetitionIdsList = function() {
  return this.setCompetitionIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditCompetitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditCompetitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditCompetitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditCompetitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shortName: jspb.Message.getFieldWithDefault(msg, 48, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0),
    standingsUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditCompetitionRequest}
 */
proto.odds.EditCompetitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditCompetitionRequest;
  return proto.odds.EditCompetitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditCompetitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditCompetitionRequest}
 */
proto.odds.EditCompetitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandingsUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditCompetitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditCompetitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditCompetitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditCompetitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
  f = message.getStandingsUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.EditCompetitionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditCompetitionRequest} returns this
 */
proto.odds.EditCompetitionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string short_name = 48;
 * @return {string}
 */
proto.odds.EditCompetitionRequest.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditCompetitionRequest} returns this
 */
proto.odds.EditCompetitionRequest.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.EditCompetitionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditCompetitionRequest} returns this
 */
proto.odds.EditCompetitionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.EditCompetitionRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditCompetitionRequest} returns this
 */
proto.odds.EditCompetitionRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.EditCompetitionRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditCompetitionRequest} returns this
 */
proto.odds.EditCompetitionRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * optional string standings_url = 6;
 * @return {string}
 */
proto.odds.EditCompetitionRequest.prototype.getStandingsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditCompetitionRequest} returns this
 */
proto.odds.EditCompetitionRequest.prototype.setStandingsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AutoAddCompetitionEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AutoAddCompetitionEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AutoAddCompetitionEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AutoAddCompetitionEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AutoAddCompetitionEventsRequest}
 */
proto.odds.AutoAddCompetitionEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AutoAddCompetitionEventsRequest;
  return proto.odds.AutoAddCompetitionEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AutoAddCompetitionEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AutoAddCompetitionEventsRequest}
 */
proto.odds.AutoAddCompetitionEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AutoAddCompetitionEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AutoAddCompetitionEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AutoAddCompetitionEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AutoAddCompetitionEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.AutoAddCompetitionEventsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AutoAddCompetitionEventsRequest} returns this
 */
proto.odds.AutoAddCompetitionEventsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetOutcomesResponse.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetOutcomesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetOutcomesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetOutcomesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOutcomesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.Outcome.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetOutcomesResponse}
 */
proto.odds.GetOutcomesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetOutcomesResponse;
  return proto.odds.GetOutcomesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetOutcomesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetOutcomesResponse}
 */
proto.odds.GetOutcomesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 27:
      var value = new proto.odds.Outcome;
      reader.readMessage(value,proto.odds.Outcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetOutcomesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetOutcomesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetOutcomesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOutcomesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.odds.Outcome.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Outcome outcomes = 27;
 * @return {!Array<!proto.odds.Outcome>}
 */
proto.odds.GetOutcomesResponse.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.Outcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Outcome, 27));
};


/**
 * @param {!Array<!proto.odds.Outcome>} value
 * @return {!proto.odds.GetOutcomesResponse} returns this
*/
proto.odds.GetOutcomesResponse.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.odds.Outcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Outcome}
 */
proto.odds.GetOutcomesResponse.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.odds.Outcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetOutcomesResponse} returns this
 */
proto.odds.GetOutcomesResponse.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetOutcomesResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetOutcomesResponse} returns this
*/
proto.odds.GetOutcomesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetOutcomesResponse} returns this
 */
proto.odds.GetOutcomesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetOutcomesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetOutcomesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetOutcomesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetOutcomesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOutcomesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetOutcomesRequest}
 */
proto.odds.GetOutcomesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetOutcomesRequest;
  return proto.odds.GetOutcomesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetOutcomesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetOutcomesRequest}
 */
proto.odds.GetOutcomesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetOutcomesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetOutcomesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetOutcomesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOutcomesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 sport_id = 1;
 * @return {number}
 */
proto.odds.GetOutcomesRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetOutcomesRequest} returns this
 */
proto.odds.GetOutcomesRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string outcome_name = 2;
 * @return {string}
 */
proto.odds.GetOutcomesRequest.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetOutcomesRequest} returns this
 */
proto.odds.GetOutcomesRequest.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetProviderOutcomesResponse.repeatedFields_ = [38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetProviderOutcomesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetProviderOutcomesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetProviderOutcomesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderOutcomesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.odds.Provider.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetProviderOutcomesResponse}
 */
proto.odds.GetProviderOutcomesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetProviderOutcomesResponse;
  return proto.odds.GetProviderOutcomesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetProviderOutcomesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetProviderOutcomesResponse}
 */
proto.odds.GetProviderOutcomesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 38:
      var value = new proto.odds.Provider;
      reader.readMessage(value,proto.odds.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetProviderOutcomesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetProviderOutcomesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetProviderOutcomesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetProviderOutcomesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.odds.Provider.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Provider providers = 38;
 * @return {!Array<!proto.odds.Provider>}
 */
proto.odds.GetProviderOutcomesResponse.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.odds.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Provider, 38));
};


/**
 * @param {!Array<!proto.odds.Provider>} value
 * @return {!proto.odds.GetProviderOutcomesResponse} returns this
*/
proto.odds.GetProviderOutcomesResponse.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.odds.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Provider}
 */
proto.odds.GetProviderOutcomesResponse.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.odds.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetProviderOutcomesResponse} returns this
 */
proto.odds.GetProviderOutcomesResponse.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetProviderOutcomesResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetProviderOutcomesResponse} returns this
*/
proto.odds.GetProviderOutcomesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetProviderOutcomesResponse} returns this
 */
proto.odds.GetProviderOutcomesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetProviderOutcomesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    typesMap: (f = msg.getTypesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventTypesResponse}
 */
proto.odds.GetEventTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventTypesResponse;
  return proto.odds.GetEventTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventTypesResponse}
 */
proto.odds.GetEventTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 124:
      var value = msg.getTypesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(124, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> types = 124;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.odds.GetEventTypesResponse.prototype.getTypesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 124, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.GetEventTypesResponse} returns this
 */
proto.odds.GetEventTypesResponse.prototype.clearTypesMap = function() {
  this.getTypesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ConfigureEventStartTimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ConfigureEventStartTimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ConfigureEventStartTimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ConfigureEventStartTimeRequest}
 */
proto.odds.ConfigureEventStartTimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ConfigureEventStartTimeRequest;
  return proto.odds.ConfigureEventStartTimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ConfigureEventStartTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ConfigureEventStartTimeRequest}
 */
proto.odds.ConfigureEventStartTimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ConfigureEventStartTimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ConfigureEventStartTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ConfigureEventStartTimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ConfigureEventStartTimeRequest} returns this
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp start = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.ConfigureEventStartTimeRequest} returns this
*/
proto.odds.ConfigureEventStartTimeRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.ConfigureEventStartTimeRequest} returns this
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.ConfigureEventStartTimeRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsByTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsByTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsByTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsByTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 93, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsByTypeRequest}
 */
proto.odds.GetEventsByTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsByTypeRequest;
  return proto.odds.GetEventsByTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsByTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsByTypeRequest}
 */
proto.odds.GetEventsByTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsByTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsByTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsByTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsByTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.GetEventsByTypeRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetEventsByTypeRequest} returns this
 */
proto.odds.GetEventsByTypeRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetEventsByTypeResponse.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsByTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsByTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsByTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsByTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsByTypeResponse}
 */
proto.odds.GetEventsByTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsByTypeResponse;
  return proto.odds.GetEventsByTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsByTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsByTypeResponse}
 */
proto.odds.GetEventsByTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsByTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsByTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsByTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsByTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.GetEventsByTypeResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.GetEventsByTypeResponse} returns this
*/
proto.odds.GetEventsByTypeResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.GetEventsByTypeResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsByTypeResponse} returns this
 */
proto.odds.GetEventsByTypeResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetEventsByTypeResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetEventsByTypeResponse} returns this
*/
proto.odds.GetEventsByTypeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventsByTypeResponse} returns this
 */
proto.odds.GetEventsByTypeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventsByTypeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EditEventRequest.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 93, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    outrightType: jspb.Message.getFieldWithDefault(msg, 132, ""),
    endBettingTime: (f = msg.getEndBettingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.Outcome.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditEventRequest}
 */
proto.odds.EditEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditEventRequest;
  return proto.odds.EditEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditEventRequest}
 */
proto.odds.EditEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 132:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 140:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndBettingTime(value);
      break;
    case 27:
      var value = new proto.odds.Outcome;
      reader.readMessage(value,proto.odds.Outcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      132,
      f
    );
  }
  f = message.getEndBettingTime();
  if (f != null) {
    writer.writeMessage(
      140,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.odds.Outcome.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.EditEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.EditEventRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.EditEventRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.EditEventRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string outright_type = 132;
 * @return {string}
 */
proto.odds.EditEventRequest.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 132, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 132, value);
};


/**
 * optional google.protobuf.Timestamp end_betting_time = 140;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.EditEventRequest.prototype.getEndBettingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 140));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.EditEventRequest} returns this
*/
proto.odds.EditEventRequest.prototype.setEndBettingTime = function(value) {
  return jspb.Message.setWrapperField(this, 140, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.clearEndBettingTime = function() {
  return this.setEndBettingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditEventRequest.prototype.hasEndBettingTime = function() {
  return jspb.Message.getField(this, 140) != null;
};


/**
 * repeated Outcome outcomes = 27;
 * @return {!Array<!proto.odds.Outcome>}
 */
proto.odds.EditEventRequest.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.Outcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Outcome, 27));
};


/**
 * @param {!Array<!proto.odds.Outcome>} value
 * @return {!proto.odds.EditEventRequest} returns this
*/
proto.odds.EditEventRequest.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.odds.Outcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Outcome}
 */
proto.odds.EditEventRequest.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.odds.Outcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EditEventRequest} returns this
 */
proto.odds.EditEventRequest.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.ResolveOddRequest.repeatedFields_ = [126,127,128,131];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ResolveOddRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ResolveOddRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ResolveOddRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ResolveOddRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    passedOddIdsList: (f = jspb.Message.getRepeatedField(msg, 126)) == null ? undefined : f,
    failedOddIdsList: (f = jspb.Message.getRepeatedField(msg, 127)) == null ? undefined : f,
    cancelledOddIdsList: (f = jspb.Message.getRepeatedField(msg, 128)) == null ? undefined : f,
    notResolvedOddIdsList: (f = jspb.Message.getRepeatedField(msg, 131)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ResolveOddRequest}
 */
proto.odds.ResolveOddRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ResolveOddRequest;
  return proto.odds.ResolveOddRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ResolveOddRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ResolveOddRequest}
 */
proto.odds.ResolveOddRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 126:
      var value = /** @type {string} */ (reader.readString());
      msg.addPassedOddIds(value);
      break;
    case 127:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedOddIds(value);
      break;
    case 128:
      var value = /** @type {string} */ (reader.readString());
      msg.addCancelledOddIds(value);
      break;
    case 131:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotResolvedOddIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ResolveOddRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ResolveOddRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ResolveOddRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ResolveOddRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPassedOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      126,
      f
    );
  }
  f = message.getFailedOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      127,
      f
    );
  }
  f = message.getCancelledOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      128,
      f
    );
  }
  f = message.getNotResolvedOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      131,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.ResolveOddRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated string passed_odd_ids = 126;
 * @return {!Array<string>}
 */
proto.odds.ResolveOddRequest.prototype.getPassedOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 126));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.setPassedOddIdsList = function(value) {
  return jspb.Message.setField(this, 126, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.addPassedOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 126, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.clearPassedOddIdsList = function() {
  return this.setPassedOddIdsList([]);
};


/**
 * repeated string failed_odd_ids = 127;
 * @return {!Array<string>}
 */
proto.odds.ResolveOddRequest.prototype.getFailedOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 127));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.setFailedOddIdsList = function(value) {
  return jspb.Message.setField(this, 127, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.addFailedOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 127, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.clearFailedOddIdsList = function() {
  return this.setFailedOddIdsList([]);
};


/**
 * repeated string cancelled_odd_ids = 128;
 * @return {!Array<string>}
 */
proto.odds.ResolveOddRequest.prototype.getCancelledOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 128));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.setCancelledOddIdsList = function(value) {
  return jspb.Message.setField(this, 128, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.addCancelledOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 128, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.clearCancelledOddIdsList = function() {
  return this.setCancelledOddIdsList([]);
};


/**
 * repeated string not_resolved_odd_ids = 131;
 * @return {!Array<string>}
 */
proto.odds.ResolveOddRequest.prototype.getNotResolvedOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 131));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.setNotResolvedOddIdsList = function(value) {
  return jspb.Message.setField(this, 131, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.addNotResolvedOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 131, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ResolveOddRequest} returns this
 */
proto.odds.ResolveOddRequest.prototype.clearNotResolvedOddIdsList = function() {
  return this.setNotResolvedOddIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventOddsRequest}
 */
proto.odds.GetEventOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventOddsRequest;
  return proto.odds.GetEventOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventOddsRequest}
 */
proto.odds.GetEventOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.GetEventOddsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetEventOddsRequest} returns this
 */
proto.odds.GetEventOddsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetEventOddsResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventOddsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventOddsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventOddsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventOddsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.Market.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventOddsResponse}
 */
proto.odds.GetEventOddsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventOddsResponse;
  return proto.odds.GetEventOddsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventOddsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventOddsResponse}
 */
proto.odds.GetEventOddsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = new proto.odds.Market;
      reader.readMessage(value,proto.odds.Market.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventOddsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventOddsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventOddsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventOddsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds.Market.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Market markets = 6;
 * @return {!Array<!proto.odds.Market>}
 */
proto.odds.GetEventOddsResponse.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.Market>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Market, 6));
};


/**
 * @param {!Array<!proto.odds.Market>} value
 * @return {!proto.odds.GetEventOddsResponse} returns this
*/
proto.odds.GetEventOddsResponse.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds.Market=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Market}
 */
proto.odds.GetEventOddsResponse.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds.Market, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventOddsResponse} returns this
 */
proto.odds.GetEventOddsResponse.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetEventOddsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetEventOddsResponse} returns this
*/
proto.odds.GetEventOddsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetEventOddsResponse} returns this
 */
proto.odds.GetEventOddsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetEventOddsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DisableEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DisableEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DisableEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DisableEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DisableEventRequest}
 */
proto.odds.DisableEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DisableEventRequest;
  return proto.odds.DisableEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DisableEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DisableEventRequest}
 */
proto.odds.DisableEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DisableEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DisableEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DisableEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DisableEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.DisableEventRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DisableEventRequest} returns this
 */
proto.odds.DisableEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsWithPlayersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsWithPlayersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsWithPlayersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsWithPlayersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsWithPlayersRequest}
 */
proto.odds.GetEventsWithPlayersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsWithPlayersRequest;
  return proto.odds.GetEventsWithPlayersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsWithPlayersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsWithPlayersRequest}
 */
proto.odds.GetEventsWithPlayersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsWithPlayersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsWithPlayersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsWithPlayersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsWithPlayersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetEventsWithPlayersRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetEventsWithPlayersRequest} returns this
 */
proto.odds.GetEventsWithPlayersRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetEventsWithPlayersResponse.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsWithPlayersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsWithPlayersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsWithPlayersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsWithPlayersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsWithPlayersResponse}
 */
proto.odds.GetEventsWithPlayersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsWithPlayersResponse;
  return proto.odds.GetEventsWithPlayersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsWithPlayersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsWithPlayersResponse}
 */
proto.odds.GetEventsWithPlayersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsWithPlayersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsWithPlayersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsWithPlayersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsWithPlayersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.GetEventsWithPlayersResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.GetEventsWithPlayersResponse} returns this
*/
proto.odds.GetEventsWithPlayersResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.GetEventsWithPlayersResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsWithPlayersResponse} returns this
 */
proto.odds.GetEventsWithPlayersResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetMarketsResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetMarketsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetMarketsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetMarketsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMarketsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.Market.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetMarketsResponse}
 */
proto.odds.GetMarketsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetMarketsResponse;
  return proto.odds.GetMarketsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetMarketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetMarketsResponse}
 */
proto.odds.GetMarketsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = new proto.odds.Market;
      reader.readMessage(value,proto.odds.Market.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetMarketsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetMarketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetMarketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMarketsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds.Market.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Market markets = 6;
 * @return {!Array<!proto.odds.Market>}
 */
proto.odds.GetMarketsResponse.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.Market>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Market, 6));
};


/**
 * @param {!Array<!proto.odds.Market>} value
 * @return {!proto.odds.GetMarketsResponse} returns this
*/
proto.odds.GetMarketsResponse.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds.Market=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Market}
 */
proto.odds.GetMarketsResponse.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds.Market, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetMarketsResponse} returns this
 */
proto.odds.GetMarketsResponse.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetUnresolvedEventsResponse.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetUnresolvedEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetUnresolvedEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetUnresolvedEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetUnresolvedEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetUnresolvedEventsResponse}
 */
proto.odds.GetUnresolvedEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetUnresolvedEventsResponse;
  return proto.odds.GetUnresolvedEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetUnresolvedEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetUnresolvedEventsResponse}
 */
proto.odds.GetUnresolvedEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetUnresolvedEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetUnresolvedEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetUnresolvedEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetUnresolvedEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.GetUnresolvedEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.GetUnresolvedEventsResponse} returns this
*/
proto.odds.GetUnresolvedEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.GetUnresolvedEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetUnresolvedEventsResponse} returns this
 */
proto.odds.GetUnresolvedEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetSportsWithCompetitionsResponse.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSportsWithCompetitionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSportsWithCompetitionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSportsWithCompetitionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsWithCompetitionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.Sport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSportsWithCompetitionsResponse}
 */
proto.odds.GetSportsWithCompetitionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSportsWithCompetitionsResponse;
  return proto.odds.GetSportsWithCompetitionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSportsWithCompetitionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSportsWithCompetitionsResponse}
 */
proto.odds.GetSportsWithCompetitionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSportsWithCompetitionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSportsWithCompetitionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSportsWithCompetitionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsWithCompetitionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sport sports = 13;
 * @return {!Array<!proto.odds.Sport>}
 */
proto.odds.GetSportsWithCompetitionsResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.Sport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Sport, 13));
};


/**
 * @param {!Array<!proto.odds.Sport>} value
 * @return {!proto.odds.GetSportsWithCompetitionsResponse} returns this
*/
proto.odds.GetSportsWithCompetitionsResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.odds.Sport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport}
 */
proto.odds.GetSportsWithCompetitionsResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.odds.Sport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSportsWithCompetitionsResponse} returns this
 */
proto.odds.GetSportsWithCompetitionsResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetEventsForManualResultsResponse.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsForManualResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsForManualResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsForManualResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsForManualResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsForManualResultsResponse}
 */
proto.odds.GetEventsForManualResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsForManualResultsResponse;
  return proto.odds.GetEventsForManualResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsForManualResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsForManualResultsResponse}
 */
proto.odds.GetEventsForManualResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsForManualResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsForManualResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsForManualResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsForManualResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 17;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.GetEventsForManualResultsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 17));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.GetEventsForManualResultsResponse} returns this
*/
proto.odds.GetEventsForManualResultsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.GetEventsForManualResultsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsForManualResultsResponse} returns this
 */
proto.odds.GetEventsForManualResultsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EditManualResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EditManualResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EditManualResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditManualResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EditManualResultRequest}
 */
proto.odds.EditManualResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EditManualResultRequest;
  return proto.odds.EditManualResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EditManualResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EditManualResultRequest}
 */
proto.odds.EditManualResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 145:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EditManualResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EditManualResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EditManualResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EditManualResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      145,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.EditManualResultRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EditManualResultRequest} returns this
 */
proto.odds.EditManualResultRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.EventResult result = 145;
 * @return {?proto.common.EventResult}
 */
proto.odds.EditManualResultRequest.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 145));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.odds.EditManualResultRequest} returns this
*/
proto.odds.EditManualResultRequest.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 145, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EditManualResultRequest} returns this
 */
proto.odds.EditManualResultRequest.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EditManualResultRequest.prototype.hasResult = function() {
  return jspb.Message.getField(this, 145) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LinkEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LinkEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LinkEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liveEventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkCompetitors: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LinkEventsRequest}
 */
proto.odds.LinkEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LinkEventsRequest;
  return proto.odds.LinkEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LinkEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LinkEventsRequest}
 */
proto.odds.LinkEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveEventId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLinkCompetitors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LinkEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LinkEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LinkEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LinkEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiveEventId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkCompetitors();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string prematch_event_id = 1;
 * @return {string}
 */
proto.odds.LinkEventsRequest.prototype.getPrematchEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LinkEventsRequest} returns this
 */
proto.odds.LinkEventsRequest.prototype.setPrematchEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string live_event_id = 2;
 * @return {string}
 */
proto.odds.LinkEventsRequest.prototype.getLiveEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LinkEventsRequest} returns this
 */
proto.odds.LinkEventsRequest.prototype.setLiveEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool link_competitors = 3;
 * @return {boolean}
 */
proto.odds.LinkEventsRequest.prototype.getLinkCompetitors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.LinkEventsRequest} returns this
 */
proto.odds.LinkEventsRequest.prototype.setLinkCompetitors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GenerateListPDFRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GenerateListPDFRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GenerateListPDFRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GenerateListPDFRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GenerateListPDFRequest}
 */
proto.odds.GenerateListPDFRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GenerateListPDFRequest;
  return proto.odds.GenerateListPDFRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GenerateListPDFRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GenerateListPDFRequest}
 */
proto.odds.GenerateListPDFRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GenerateListPDFRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GenerateListPDFRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GenerateListPDFRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GenerateListPDFRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GenerateListPDFResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GenerateListPDFResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GenerateListPDFResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GenerateListPDFResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GenerateListPDFResponse}
 */
proto.odds.GenerateListPDFResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GenerateListPDFResponse;
  return proto.odds.GenerateListPDFResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GenerateListPDFResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GenerateListPDFResponse}
 */
proto.odds.GenerateListPDFResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 150:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GenerateListPDFResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GenerateListPDFResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GenerateListPDFResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GenerateListPDFResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      150,
      f
    );
  }
};


/**
 * optional bytes data = 150;
 * @return {string}
 */
proto.odds.GenerateListPDFResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 150, ""));
};


/**
 * optional bytes data = 150;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.odds.GenerateListPDFResponse.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 150;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.odds.GenerateListPDFResponse.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.odds.GenerateListPDFResponse} returns this
 */
proto.odds.GenerateListPDFResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 150, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSideBarRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSideBarRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSideBarRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSideBarRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 93, ""),
    frontendPageName: jspb.Message.getFieldWithDefault(msg, 155, ""),
    filter: (f = msg.getFilter()) && proto.odds.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSideBarRequest}
 */
proto.odds.GetSideBarRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSideBarRequest;
  return proto.odds.GetSideBarRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSideBarRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSideBarRequest}
 */
proto.odds.GetSideBarRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 155:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrontendPageName(value);
      break;
    case 158:
      var value = new proto.odds.Filter;
      reader.readMessage(value,proto.odds.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSideBarRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSideBarRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSideBarRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSideBarRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getFrontendPageName();
  if (f.length > 0) {
    writer.writeString(
      155,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      158,
      f,
      proto.odds.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 provider_id = 5;
 * @return {number}
 */
proto.odds.GetSideBarRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetSideBarRequest} returns this
 */
proto.odds.GetSideBarRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.GetSideBarRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetSideBarRequest} returns this
 */
proto.odds.GetSideBarRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string frontend_page_name = 155;
 * @return {string}
 */
proto.odds.GetSideBarRequest.prototype.getFrontendPageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 155, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetSideBarRequest} returns this
 */
proto.odds.GetSideBarRequest.prototype.setFrontendPageName = function(value) {
  return jspb.Message.setProto3StringField(this, 155, value);
};


/**
 * optional Filter filter = 158;
 * @return {?proto.odds.Filter}
 */
proto.odds.GetSideBarRequest.prototype.getFilter = function() {
  return /** @type{?proto.odds.Filter} */ (
    jspb.Message.getWrapperField(this, proto.odds.Filter, 158));
};


/**
 * @param {?proto.odds.Filter|undefined} value
 * @return {!proto.odds.GetSideBarRequest} returns this
*/
proto.odds.GetSideBarRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 158, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetSideBarRequest} returns this
 */
proto.odds.GetSideBarRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetSideBarRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 158) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetSideBarResponse.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSideBarResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSideBarResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSideBarResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSideBarResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.Sport.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSideBarResponse}
 */
proto.odds.GetSideBarResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSideBarResponse;
  return proto.odds.GetSideBarResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSideBarResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSideBarResponse}
 */
proto.odds.GetSideBarResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSideBarResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSideBarResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSideBarResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSideBarResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sport sports = 13;
 * @return {!Array<!proto.odds.Sport>}
 */
proto.odds.GetSideBarResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.Sport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Sport, 13));
};


/**
 * @param {!Array<!proto.odds.Sport>} value
 * @return {!proto.odds.GetSideBarResponse} returns this
*/
proto.odds.GetSideBarResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.odds.Sport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport}
 */
proto.odds.GetSideBarResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.odds.Sport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSideBarResponse} returns this
 */
proto.odds.GetSideBarResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetSideBarResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetSideBarResponse} returns this
*/
proto.odds.GetSideBarResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetSideBarResponse} returns this
 */
proto.odds.GetSideBarResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetSideBarResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Filter.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchString: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateTo: (f = msg.getDateTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    numOddsFrom: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numOddsTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isPublished: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isAuto: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isLinked: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hasResult: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    providerIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    resolved: jspb.Message.getFieldWithDefault(msg, 12, ""),
    byStart: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hasLbCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hasNewLbCode: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Filter}
 */
proto.odds.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Filter;
  return proto.odds.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Filter}
 */
proto.odds.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOddsFrom(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOddsTo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsPublished(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsAuto(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsLinked(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasResult(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderIds(values[i]);
      }
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolved(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setByStart(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasLbCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasNewLbCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNumOddsFrom();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumOddsTo();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIsPublished();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsAuto();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsLinked();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHasResult();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getProviderIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      11,
      f
    );
  }
  f = message.getResolved();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getByStart();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHasLbCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHasNewLbCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string search_string = 1;
 * @return {string}
 */
proto.odds.Filter.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Filter.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Filter} returns this
*/
proto.odds.Filter.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Filter.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp date_to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.Filter.prototype.getDateTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.Filter} returns this
*/
proto.odds.Filter.prototype.setDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.clearDateTo = function() {
  return this.setDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Filter.prototype.hasDateTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 num_odds_from = 4;
 * @return {number}
 */
proto.odds.Filter.prototype.getNumOddsFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setNumOddsFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 num_odds_to = 5;
 * @return {number}
 */
proto.odds.Filter.prototype.getNumOddsTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setNumOddsTo = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string is_published = 6;
 * @return {string}
 */
proto.odds.Filter.prototype.getIsPublished = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setIsPublished = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string is_auto = 7;
 * @return {string}
 */
proto.odds.Filter.prototype.getIsAuto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setIsAuto = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string is_linked = 8;
 * @return {string}
 */
proto.odds.Filter.prototype.getIsLinked = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setIsLinked = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string has_result = 9;
 * @return {string}
 */
proto.odds.Filter.prototype.getHasResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setHasResult = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 sport_id = 10;
 * @return {number}
 */
proto.odds.Filter.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated int32 provider_ids = 11;
 * @return {!Array<number>}
 */
proto.odds.Filter.prototype.getProviderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setProviderIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.addProviderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.clearProviderIdsList = function() {
  return this.setProviderIdsList([]);
};


/**
 * optional string resolved = 12;
 * @return {string}
 */
proto.odds.Filter.prototype.getResolved = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setResolved = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool by_start = 13;
 * @return {boolean}
 */
proto.odds.Filter.prototype.getByStart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setByStart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string has_lb_code = 14;
 * @return {string}
 */
proto.odds.Filter.prototype.getHasLbCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setHasLbCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string has_new_lb_code = 15;
 * @return {string}
 */
proto.odds.Filter.prototype.getHasNewLbCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Filter} returns this
 */
proto.odds.Filter.prototype.setHasNewLbCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPaginatedEventsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPaginatedEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPaginatedEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPaginatedEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    filter: (f = msg.getFilter()) && proto.odds.Filter.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    providerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPaginatedEventsRequest}
 */
proto.odds.GetPaginatedEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPaginatedEventsRequest;
  return proto.odds.GetPaginatedEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPaginatedEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPaginatedEventsRequest}
 */
proto.odds.GetPaginatedEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompetitionIds(values[i]);
      }
      break;
    case 2:
      var value = new proto.odds.Filter;
      reader.readMessage(value,proto.odds.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPaginatedEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPaginatedEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPaginatedEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.odds.Filter.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated int32 competition_ids = 1;
 * @return {!Array<number>}
 */
proto.odds.GetPaginatedEventsRequest.prototype.getCompetitionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.setCompetitionIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.addCompetitionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.clearCompetitionIdsList = function() {
  return this.setCompetitionIdsList([]);
};


/**
 * optional Filter filter = 2;
 * @return {?proto.odds.Filter}
 */
proto.odds.GetPaginatedEventsRequest.prototype.getFilter = function() {
  return /** @type{?proto.odds.Filter} */ (
    jspb.Message.getWrapperField(this, proto.odds.Filter, 2));
};


/**
 * @param {?proto.odds.Filter|undefined} value
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
*/
proto.odds.GetPaginatedEventsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetPaginatedEventsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.odds.GetPaginatedEventsRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.odds.GetPaginatedEventsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 provider_id = 5;
 * @return {number}
 */
proto.odds.GetPaginatedEventsRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string event_id = 6;
 * @return {string}
 */
proto.odds.GetPaginatedEventsRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetPaginatedEventsRequest} returns this
 */
proto.odds.GetPaginatedEventsRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.PaginatedEvents.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PaginatedEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PaginatedEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PaginatedEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PaginatedEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 162, 0),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PaginatedEvents}
 */
proto.odds.PaginatedEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PaginatedEvents;
  return proto.odds.PaginatedEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PaginatedEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PaginatedEvents}
 */
proto.odds.PaginatedEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 162:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 25:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PaginatedEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PaginatedEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PaginatedEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PaginatedEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      162,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.PaginatedEvents.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PaginatedEvents} returns this
 */
proto.odds.PaginatedEvents.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 total_pages = 162;
 * @return {number}
 */
proto.odds.PaginatedEvents.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PaginatedEvents} returns this
 */
proto.odds.PaginatedEvents.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 162, value);
};


/**
 * repeated Competition competitions = 25;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.PaginatedEvents.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 25));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.PaginatedEvents} returns this
*/
proto.odds.PaginatedEvents.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.PaginatedEvents.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.PaginatedEvents} returns this
 */
proto.odds.PaginatedEvents.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.PaginatedEvents.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.PaginatedEvents} returns this
*/
proto.odds.PaginatedEvents.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.PaginatedEvents} returns this
 */
proto.odds.PaginatedEvents.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.PaginatedEvents.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CancelAllUnplayedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CancelAllUnplayedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CancelAllUnplayedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelAllUnplayedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 93, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CancelAllUnplayedRequest}
 */
proto.odds.CancelAllUnplayedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CancelAllUnplayedRequest;
  return proto.odds.CancelAllUnplayedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CancelAllUnplayedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CancelAllUnplayedRequest}
 */
proto.odds.CancelAllUnplayedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CancelAllUnplayedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CancelAllUnplayedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CancelAllUnplayedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelAllUnplayedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
};


/**
 * optional string type = 93;
 * @return {string}
 */
proto.odds.CancelAllUnplayedRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CancelAllUnplayedRequest} returns this
 */
proto.odds.CancelAllUnplayedRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ValidationPriorityObjects.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ValidationPriorityObjects.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ValidationPriorityObjects} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ValidationPriorityObjects.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionsMapMap: (f = msg.getCompetitionsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    locationsMapMap: (f = msg.getLocationsMapMap()) ? f.toObject(includeInstance, undefined) : [],
    sportsMapMap: (f = msg.getSportsMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ValidationPriorityObjects}
 */
proto.odds.ValidationPriorityObjects.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ValidationPriorityObjects;
  return proto.odds.ValidationPriorityObjects.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ValidationPriorityObjects} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ValidationPriorityObjects}
 */
proto.odds.ValidationPriorityObjects.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 168:
      var value = msg.getCompetitionsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 169:
      var value = msg.getLocationsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 170:
      var value = msg.getSportsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ValidationPriorityObjects.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ValidationPriorityObjects.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ValidationPriorityObjects} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ValidationPriorityObjects.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(168, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLocationsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(169, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSportsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(170, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<int32, string> competitions_map = 168;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.odds.ValidationPriorityObjects.prototype.getCompetitionsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 168, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.ValidationPriorityObjects} returns this
 */
proto.odds.ValidationPriorityObjects.prototype.clearCompetitionsMapMap = function() {
  this.getCompetitionsMapMap().clear();
  return this;};


/**
 * map<int32, string> locations_map = 169;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.odds.ValidationPriorityObjects.prototype.getLocationsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 169, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.ValidationPriorityObjects} returns this
 */
proto.odds.ValidationPriorityObjects.prototype.clearLocationsMapMap = function() {
  this.getLocationsMapMap().clear();
  return this;};


/**
 * map<int32, string> sports_map = 170;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.odds.ValidationPriorityObjects.prototype.getSportsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 170, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.ValidationPriorityObjects} returns this
 */
proto.odds.ValidationPriorityObjects.prototype.clearSportsMapMap = function() {
  this.getSportsMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AddNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AddNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AddNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    note: jspb.Message.getFieldWithDefault(msg, 172, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AddNoteRequest}
 */
proto.odds.AddNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AddNoteRequest;
  return proto.odds.AddNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AddNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AddNoteRequest}
 */
proto.odds.AddNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 172:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AddNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AddNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AddNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AddNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      172,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.AddNoteRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddNoteRequest} returns this
 */
proto.odds.AddNoteRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string note = 172;
 * @return {string}
 */
proto.odds.AddNoteRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 172, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AddNoteRequest} returns this
 */
proto.odds.AddNoteRequest.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 172, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SuspiciousEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SuspiciousEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SuspiciousEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SuspiciousEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    suspiciousLevel: jspb.Message.getFieldWithDefault(msg, 174, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 80, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SuspiciousEvent}
 */
proto.odds.SuspiciousEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SuspiciousEvent;
  return proto.odds.SuspiciousEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SuspiciousEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SuspiciousEvent}
 */
proto.odds.SuspiciousEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 174:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuspiciousLevel(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SuspiciousEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SuspiciousEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SuspiciousEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SuspiciousEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSuspiciousLevel();
  if (f.length > 0) {
    writer.writeString(
      174,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.SuspiciousEvent.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SuspiciousEvent} returns this
 */
proto.odds.SuspiciousEvent.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string suspicious_level = 174;
 * @return {string}
 */
proto.odds.SuspiciousEvent.prototype.getSuspiciousLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 174, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SuspiciousEvent} returns this
 */
proto.odds.SuspiciousEvent.prototype.setSuspiciousLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 174, value);
};


/**
 * optional string landbase_code = 80;
 * @return {string}
 */
proto.odds.SuspiciousEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SuspiciousEvent} returns this
 */
proto.odds.SuspiciousEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.UpdateOddsRequest.repeatedFields_ = [87,184];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletedOddIdsList: (f = jspb.Message.getRepeatedField(msg, 87)) == null ? undefined : f,
    fixedOddIdsList: (f = jspb.Message.getRepeatedField(msg, 184)) == null ? undefined : f,
    eventId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateOddsRequest}
 */
proto.odds.UpdateOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateOddsRequest;
  return proto.odds.UpdateOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateOddsRequest}
 */
proto.odds.UpdateOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 87:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeletedOddIds(value);
      break;
    case 184:
      var value = /** @type {string} */ (reader.readString());
      msg.addFixedOddIds(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeletedOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      87,
      f
    );
  }
  f = message.getFixedOddIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      184,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * repeated string deleted_odd_ids = 87;
 * @return {!Array<string>}
 */
proto.odds.UpdateOddsRequest.prototype.getDeletedOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 87));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.setDeletedOddIdsList = function(value) {
  return jspb.Message.setField(this, 87, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.addDeletedOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 87, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.clearDeletedOddIdsList = function() {
  return this.setDeletedOddIdsList([]);
};


/**
 * repeated string fixed_odd_ids = 184;
 * @return {!Array<string>}
 */
proto.odds.UpdateOddsRequest.prototype.getFixedOddIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 184));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.setFixedOddIdsList = function(value) {
  return jspb.Message.setField(this, 184, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.addFixedOddIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 184, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.clearFixedOddIdsList = function() {
  return this.setFixedOddIdsList([]);
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.UpdateOddsRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.UpdateOddsRequest} returns this
 */
proto.odds.UpdateOddsRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SuspiciousLevels.repeatedFields_ = [177];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SuspiciousLevels.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SuspiciousLevels.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SuspiciousLevels} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SuspiciousLevels.toObject = function(includeInstance, msg) {
  var f, obj = {
    suspiciousLevelsList: jspb.Message.toObjectList(msg.getSuspiciousLevelsList(),
    proto.odds.SuspiciousLevel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SuspiciousLevels}
 */
proto.odds.SuspiciousLevels.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SuspiciousLevels;
  return proto.odds.SuspiciousLevels.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SuspiciousLevels} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SuspiciousLevels}
 */
proto.odds.SuspiciousLevels.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 177:
      var value = new proto.odds.SuspiciousLevel;
      reader.readMessage(value,proto.odds.SuspiciousLevel.deserializeBinaryFromReader);
      msg.addSuspiciousLevels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SuspiciousLevels.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SuspiciousLevels.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SuspiciousLevels} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SuspiciousLevels.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuspiciousLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      177,
      f,
      proto.odds.SuspiciousLevel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SuspiciousLevel suspicious_levels = 177;
 * @return {!Array<!proto.odds.SuspiciousLevel>}
 */
proto.odds.SuspiciousLevels.prototype.getSuspiciousLevelsList = function() {
  return /** @type{!Array<!proto.odds.SuspiciousLevel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.SuspiciousLevel, 177));
};


/**
 * @param {!Array<!proto.odds.SuspiciousLevel>} value
 * @return {!proto.odds.SuspiciousLevels} returns this
*/
proto.odds.SuspiciousLevels.prototype.setSuspiciousLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 177, value);
};


/**
 * @param {!proto.odds.SuspiciousLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.SuspiciousLevel}
 */
proto.odds.SuspiciousLevels.prototype.addSuspiciousLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 177, opt_value, proto.odds.SuspiciousLevel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SuspiciousLevels} returns this
 */
proto.odds.SuspiciousLevels.prototype.clearSuspiciousLevelsList = function() {
  return this.setSuspiciousLevelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SuspiciousLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SuspiciousLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SuspiciousLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SuspiciousLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SuspiciousLevel}
 */
proto.odds.SuspiciousLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SuspiciousLevel;
  return proto.odds.SuspiciousLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SuspiciousLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SuspiciousLevel}
 */
proto.odds.SuspiciousLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SuspiciousLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SuspiciousLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SuspiciousLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SuspiciousLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.SuspiciousLevel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SuspiciousLevel} returns this
 */
proto.odds.SuspiciousLevel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 8;
 * @return {string}
 */
proto.odds.SuspiciousLevel.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SuspiciousLevel} returns this
 */
proto.odds.SuspiciousLevel.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MatchProfiles.repeatedFields_ = [190];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MatchProfiles.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MatchProfiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MatchProfiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MatchProfiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchProfilesList: jspb.Message.toObjectList(msg.getMatchProfilesList(),
    proto.odds.MatchProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MatchProfiles}
 */
proto.odds.MatchProfiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MatchProfiles;
  return proto.odds.MatchProfiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MatchProfiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MatchProfiles}
 */
proto.odds.MatchProfiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 190:
      var value = new proto.odds.MatchProfile;
      reader.readMessage(value,proto.odds.MatchProfile.deserializeBinaryFromReader);
      msg.addMatchProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MatchProfiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MatchProfiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MatchProfiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MatchProfiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      190,
      f,
      proto.odds.MatchProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MatchProfile match_profiles = 190;
 * @return {!Array<!proto.odds.MatchProfile>}
 */
proto.odds.MatchProfiles.prototype.getMatchProfilesList = function() {
  return /** @type{!Array<!proto.odds.MatchProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MatchProfile, 190));
};


/**
 * @param {!Array<!proto.odds.MatchProfile>} value
 * @return {!proto.odds.MatchProfiles} returns this
*/
proto.odds.MatchProfiles.prototype.setMatchProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 190, value);
};


/**
 * @param {!proto.odds.MatchProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MatchProfile}
 */
proto.odds.MatchProfiles.prototype.addMatchProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 190, opt_value, proto.odds.MatchProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MatchProfiles} returns this
 */
proto.odds.MatchProfiles.prototype.clearMatchProfilesList = function() {
  return this.setMatchProfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MatchProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MatchProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MatchProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MatchProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MatchProfile}
 */
proto.odds.MatchProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MatchProfile;
  return proto.odds.MatchProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MatchProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MatchProfile}
 */
proto.odds.MatchProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MatchProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MatchProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MatchProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MatchProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MatchProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MatchProfile} returns this
 */
proto.odds.MatchProfile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 8;
 * @return {string}
 */
proto.odds.MatchProfile.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MatchProfile} returns this
 */
proto.odds.MatchProfile.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SingleApproval.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SingleApproval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SingleApproval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SingleApproval.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SingleApproval}
 */
proto.odds.SingleApproval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SingleApproval;
  return proto.odds.SingleApproval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SingleApproval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SingleApproval}
 */
proto.odds.SingleApproval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SingleApproval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SingleApproval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SingleApproval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SingleApproval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.SingleApproval.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SingleApproval} returns this
 */
proto.odds.SingleApproval.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.UpdateChangedLimitOddsRequest.repeatedFields_ = [181];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateChangedLimitOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateChangedLimitOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateChangedLimitOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateChangedLimitOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.EventOdd.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateChangedLimitOddsRequest}
 */
proto.odds.UpdateChangedLimitOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateChangedLimitOddsRequest;
  return proto.odds.UpdateChangedLimitOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateChangedLimitOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateChangedLimitOddsRequest}
 */
proto.odds.UpdateChangedLimitOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 181:
      var value = new proto.odds.EventOdd;
      reader.readMessage(value,proto.odds.EventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateChangedLimitOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateChangedLimitOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateChangedLimitOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateChangedLimitOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      181,
      f,
      proto.odds.EventOdd.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventOdd odds = 181;
 * @return {!Array<!proto.odds.EventOdd>}
 */
proto.odds.UpdateChangedLimitOddsRequest.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.EventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.EventOdd, 181));
};


/**
 * @param {!Array<!proto.odds.EventOdd>} value
 * @return {!proto.odds.UpdateChangedLimitOddsRequest} returns this
*/
proto.odds.UpdateChangedLimitOddsRequest.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 181, value);
};


/**
 * @param {!proto.odds.EventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.EventOdd}
 */
proto.odds.UpdateChangedLimitOddsRequest.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 181, opt_value, proto.odds.EventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpdateChangedLimitOddsRequest} returns this
 */
proto.odds.UpdateChangedLimitOddsRequest.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateChangedLimitOddsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateChangedLimitOddsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateChangedLimitOddsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateChangedLimitOddsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddIdsMapMap: (f = msg.getOddIdsMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateChangedLimitOddsResponse}
 */
proto.odds.UpdateChangedLimitOddsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateChangedLimitOddsResponse;
  return proto.odds.UpdateChangedLimitOddsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateChangedLimitOddsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateChangedLimitOddsResponse}
 */
proto.odds.UpdateChangedLimitOddsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 180:
      var value = msg.getOddIdsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateChangedLimitOddsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateChangedLimitOddsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateChangedLimitOddsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateChangedLimitOddsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddIdsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(180, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> odd_ids_map = 180;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.odds.UpdateChangedLimitOddsResponse.prototype.getOddIdsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 180, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.UpdateChangedLimitOddsResponse} returns this
 */
proto.odds.UpdateChangedLimitOddsResponse.prototype.clearOddIdsMapMap = function() {
  this.getOddIdsMapMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.ResetLandbaseCodesRequest.repeatedFields_ = [182];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ResetLandbaseCodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ResetLandbaseCodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ResetLandbaseCodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ResetLandbaseCodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportIdsList: (f = jspb.Message.getRepeatedField(msg, 182)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ResetLandbaseCodesRequest}
 */
proto.odds.ResetLandbaseCodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ResetLandbaseCodesRequest;
  return proto.odds.ResetLandbaseCodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ResetLandbaseCodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ResetLandbaseCodesRequest}
 */
proto.odds.ResetLandbaseCodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 182:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSportIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ResetLandbaseCodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ResetLandbaseCodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ResetLandbaseCodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ResetLandbaseCodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      182,
      f
    );
  }
};


/**
 * repeated int32 sport_ids = 182;
 * @return {!Array<number>}
 */
proto.odds.ResetLandbaseCodesRequest.prototype.getSportIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 182));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.ResetLandbaseCodesRequest} returns this
 */
proto.odds.ResetLandbaseCodesRequest.prototype.setSportIdsList = function(value) {
  return jspb.Message.setField(this, 182, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.ResetLandbaseCodesRequest} returns this
 */
proto.odds.ResetLandbaseCodesRequest.prototype.addSportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 182, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.ResetLandbaseCodesRequest} returns this
 */
proto.odds.ResetLandbaseCodesRequest.prototype.clearSportIdsList = function() {
  return this.setSportIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPaginatedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPaginatedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPaginatedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    filter: (f = msg.getFilter()) && proto.odds.Filter.toObject(includeInstance, f),
    includeProviders: jspb.Message.getBooleanFieldWithDefault(msg, 276, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPaginatedRequest}
 */
proto.odds.GetPaginatedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPaginatedRequest;
  return proto.odds.GetPaginatedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPaginatedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPaginatedRequest}
 */
proto.odds.GetPaginatedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 158:
      var value = new proto.odds.Filter;
      reader.readMessage(value,proto.odds.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 276:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeProviders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPaginatedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPaginatedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPaginatedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      158,
      f,
      proto.odds.Filter.serializeBinaryToWriter
    );
  }
  f = message.getIncludeProviders();
  if (f) {
    writer.writeBool(
      276,
      f
    );
  }
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.GetPaginatedRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedRequest} returns this
 */
proto.odds.GetPaginatedRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional Filter filter = 158;
 * @return {?proto.odds.Filter}
 */
proto.odds.GetPaginatedRequest.prototype.getFilter = function() {
  return /** @type{?proto.odds.Filter} */ (
    jspb.Message.getWrapperField(this, proto.odds.Filter, 158));
};


/**
 * @param {?proto.odds.Filter|undefined} value
 * @return {!proto.odds.GetPaginatedRequest} returns this
*/
proto.odds.GetPaginatedRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 158, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetPaginatedRequest} returns this
 */
proto.odds.GetPaginatedRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetPaginatedRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 158) != null;
};


/**
 * optional bool include_providers = 276;
 * @return {boolean}
 */
proto.odds.GetPaginatedRequest.prototype.getIncludeProviders = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 276, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.GetPaginatedRequest} returns this
 */
proto.odds.GetPaginatedRequest.prototype.setIncludeProviders = function(value) {
  return jspb.Message.setProto3BooleanField(this, 276, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPaginatedCompetitorsBoResponse.repeatedFields_ = [44];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPaginatedCompetitorsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPaginatedCompetitorsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedCompetitorsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitorsList: jspb.Message.toObjectList(msg.getCompetitorsList(),
    proto.odds.Competitor.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 162, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPaginatedCompetitorsBoResponse;
  return proto.odds.GetPaginatedCompetitorsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPaginatedCompetitorsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 44:
      var value = new proto.odds.Competitor;
      reader.readMessage(value,proto.odds.Competitor.deserializeBinaryFromReader);
      msg.addCompetitors(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 162:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPaginatedCompetitorsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPaginatedCompetitorsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedCompetitorsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.odds.Competitor.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      162,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Competitor competitors = 44;
 * @return {!Array<!proto.odds.Competitor>}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.getCompetitorsList = function() {
  return /** @type{!Array<!proto.odds.Competitor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competitor, 44));
};


/**
 * @param {!Array<!proto.odds.Competitor>} value
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse} returns this
*/
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.setCompetitorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.odds.Competitor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competitor}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.addCompetitors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.odds.Competitor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.clearCompetitorsList = function() {
  return this.setCompetitorsList([]);
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 total_pages = 162;
 * @return {number}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 162, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse} returns this
*/
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetPaginatedCompetitorsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetPaginatedCompetitorsBoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPaginatedCompetitionsBoResponse.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPaginatedCompetitionsBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPaginatedCompetitionsBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedCompetitionsBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 162, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPaginatedCompetitionsBoResponse;
  return proto.odds.GetPaginatedCompetitionsBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPaginatedCompetitionsBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 25:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 162:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPaginatedCompetitionsBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPaginatedCompetitionsBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPaginatedCompetitionsBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      162,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Competition competitions = 25;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 25));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse} returns this
*/
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 total_pages = 162;
 * @return {number}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 162, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse} returns this
*/
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetPaginatedCompetitionsBoResponse} returns this
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetPaginatedCompetitionsBoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.DisableLive.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.DisableLive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.DisableLive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DisableLive.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.DisableLive}
 */
proto.odds.DisableLive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.DisableLive;
  return proto.odds.DisableLive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.DisableLive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.DisableLive}
 */
proto.odds.DisableLive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.DisableLive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.DisableLive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.DisableLive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.DisableLive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.DisableLive.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.DisableLive} returns this
 */
proto.odds.DisableLive.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.odds.DisableLive.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.DisableLive} returns this
 */
proto.odds.DisableLive.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetCompetitionBetLimitRequest.repeatedFields_ = [260];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetCompetitionBetLimitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetCompetitionBetLimitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionBetLimitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 109, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 96, ""),
    prematch: jspb.Message.getBooleanFieldWithDefault(msg, 273, false),
    sortByList: jspb.Message.toObjectList(msg.getSortByList(),
    proto_common_common_pb.SortBy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetCompetitionBetLimitRequest}
 */
proto.odds.GetCompetitionBetLimitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetCompetitionBetLimitRequest;
  return proto.odds.GetCompetitionBetLimitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetCompetitionBetLimitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetCompetitionBetLimitRequest}
 */
proto.odds.GetCompetitionBetLimitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 109:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 273:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematch(value);
      break;
    case 260:
      var value = new proto_common_common_pb.SortBy;
      reader.readMessage(value,proto_common_common_pb.SortBy.deserializeBinaryFromReader);
      msg.addSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetCompetitionBetLimitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetCompetitionBetLimitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionBetLimitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      109,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getPrematch();
  if (f) {
    writer.writeBool(
      273,
      f
    );
  }
  f = message.getSortByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      260,
      f,
      proto_common_common_pb.SortBy.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 location_id = 109;
 * @return {number}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 109, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 109, value);
};


/**
 * optional string competition_name = 96;
 * @return {string}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional bool prematch = 273;
 * @return {boolean}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.getPrematch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 273, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.setPrematch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 273, value);
};


/**
 * repeated common.SortBy sort_by = 260;
 * @return {!Array<!proto.common.SortBy>}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.getSortByList = function() {
  return /** @type{!Array<!proto.common.SortBy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_common_pb.SortBy, 260));
};


/**
 * @param {!Array<!proto.common.SortBy>} value
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
*/
proto.odds.GetCompetitionBetLimitRequest.prototype.setSortByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 260, value);
};


/**
 * @param {!proto.common.SortBy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SortBy}
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.addSortBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 260, opt_value, proto.common.SortBy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetCompetitionBetLimitRequest} returns this
 */
proto.odds.GetCompetitionBetLimitRequest.prototype.clearSortByList = function() {
  return this.setSortByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.CompetitionLimit.repeatedFields_ = [195,196];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CompetitionLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CompetitionLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CompetitionLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 96, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 108, ""),
    sportName: jspb.Message.getFieldWithDefault(msg, 107, ""),
    daysOfMonthList: (f = jspb.Message.getRepeatedField(msg, 195)) == null ? undefined : f,
    daysOfWeekList: (f = jspb.Message.getRepeatedField(msg, 196)) == null ? undefined : f,
    maxAmountSingle: jspb.Message.getFloatingPointFieldWithDefault(msg, 198, 0.0),
    maxAmountMultiple: jspb.Message.getFloatingPointFieldWithDefault(msg, 228, 0.0),
    singleBettingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 264, 0.0),
    multipleBettingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 265, 0.0),
    amountSingle: jspb.Message.getFloatingPointFieldWithDefault(msg, 220, 0.0),
    amountMultiple: jspb.Message.getFloatingPointFieldWithDefault(msg, 229, 0.0),
    amountTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 230, 0.0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CompetitionLimit}
 */
proto.odds.CompetitionLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CompetitionLimit;
  return proto.odds.CompetitionLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CompetitionLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CompetitionLimit}
 */
proto.odds.CompetitionLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 107:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportName(value);
      break;
    case 195:
      var value = /** @type {string} */ (reader.readString());
      msg.addDaysOfMonth(value);
      break;
    case 196:
      var value = /** @type {string} */ (reader.readString());
      msg.addDaysOfWeek(value);
      break;
    case 198:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxAmountSingle(value);
      break;
    case 228:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxAmountMultiple(value);
      break;
    case 264:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSingleBettingAmount(value);
      break;
    case 265:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMultipleBettingAmount(value);
      break;
    case 220:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmountSingle(value);
      break;
    case 229:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmountMultiple(value);
      break;
    case 230:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmountTotal(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CompetitionLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CompetitionLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CompetitionLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getSportName();
  if (f.length > 0) {
    writer.writeString(
      107,
      f
    );
  }
  f = message.getDaysOfMonthList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      195,
      f
    );
  }
  f = message.getDaysOfWeekList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      196,
      f
    );
  }
  f = message.getMaxAmountSingle();
  if (f !== 0.0) {
    writer.writeDouble(
      198,
      f
    );
  }
  f = message.getMaxAmountMultiple();
  if (f !== 0.0) {
    writer.writeDouble(
      228,
      f
    );
  }
  f = message.getSingleBettingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      264,
      f
    );
  }
  f = message.getMultipleBettingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      265,
      f
    );
  }
  f = message.getAmountSingle();
  if (f !== 0.0) {
    writer.writeDouble(
      220,
      f
    );
  }
  f = message.getAmountMultiple();
  if (f !== 0.0) {
    writer.writeDouble(
      229,
      f
    );
  }
  f = message.getAmountTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      230,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string competition_name = 96;
 * @return {string}
 */
proto.odds.CompetitionLimit.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional string location_name = 108;
 * @return {string}
 */
proto.odds.CompetitionLimit.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * optional string sport_name = 107;
 * @return {string}
 */
proto.odds.CompetitionLimit.prototype.getSportName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 107, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setSportName = function(value) {
  return jspb.Message.setProto3StringField(this, 107, value);
};


/**
 * repeated string days_of_month = 195;
 * @return {!Array<string>}
 */
proto.odds.CompetitionLimit.prototype.getDaysOfMonthList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 195));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setDaysOfMonthList = function(value) {
  return jspb.Message.setField(this, 195, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.addDaysOfMonth = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 195, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.clearDaysOfMonthList = function() {
  return this.setDaysOfMonthList([]);
};


/**
 * repeated string days_of_week = 196;
 * @return {!Array<string>}
 */
proto.odds.CompetitionLimit.prototype.getDaysOfWeekList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 196));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setDaysOfWeekList = function(value) {
  return jspb.Message.setField(this, 196, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.addDaysOfWeek = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 196, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.clearDaysOfWeekList = function() {
  return this.setDaysOfWeekList([]);
};


/**
 * optional double max_amount_single = 198;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getMaxAmountSingle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 198, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setMaxAmountSingle = function(value) {
  return jspb.Message.setProto3FloatField(this, 198, value);
};


/**
 * optional double max_amount_multiple = 228;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getMaxAmountMultiple = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 228, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setMaxAmountMultiple = function(value) {
  return jspb.Message.setProto3FloatField(this, 228, value);
};


/**
 * optional double single_betting_amount = 264;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getSingleBettingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 264, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setSingleBettingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 264, value);
};


/**
 * optional double multiple_betting_amount = 265;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getMultipleBettingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 265, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setMultipleBettingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 265, value);
};


/**
 * optional double amount_single = 220;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getAmountSingle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 220, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setAmountSingle = function(value) {
  return jspb.Message.setProto3FloatField(this, 220, value);
};


/**
 * optional double amount_multiple = 229;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getAmountMultiple = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 229, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setAmountMultiple = function(value) {
  return jspb.Message.setProto3FloatField(this, 229, value);
};


/**
 * optional double amount_total = 230;
 * @return {number}
 */
proto.odds.CompetitionLimit.prototype.getAmountTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 230, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.setAmountTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 230, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.CompetitionLimit.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.CompetitionLimit} returns this
*/
proto.odds.CompetitionLimit.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CompetitionLimit} returns this
 */
proto.odds.CompetitionLimit.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CompetitionLimit.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetCompetitionBetLimitResponse.repeatedFields_ = [221];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetCompetitionBetLimitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetCompetitionBetLimitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionBetLimitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalLimit: (f = msg.getGlobalLimit()) && proto.odds.CompetitionLimit.toObject(includeInstance, f),
    competitionLimitsList: jspb.Message.toObjectList(msg.getCompetitionLimitsList(),
    proto.odds.CompetitionLimit.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 162, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetCompetitionBetLimitResponse}
 */
proto.odds.GetCompetitionBetLimitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetCompetitionBetLimitResponse;
  return proto.odds.GetCompetitionBetLimitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetCompetitionBetLimitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetCompetitionBetLimitResponse}
 */
proto.odds.GetCompetitionBetLimitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 199:
      var value = new proto.odds.CompetitionLimit;
      reader.readMessage(value,proto.odds.CompetitionLimit.deserializeBinaryFromReader);
      msg.setGlobalLimit(value);
      break;
    case 221:
      var value = new proto.odds.CompetitionLimit;
      reader.readMessage(value,proto.odds.CompetitionLimit.deserializeBinaryFromReader);
      msg.addCompetitionLimits(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 162:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetCompetitionBetLimitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetCompetitionBetLimitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionBetLimitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalLimit();
  if (f != null) {
    writer.writeMessage(
      199,
      f,
      proto.odds.CompetitionLimit.serializeBinaryToWriter
    );
  }
  f = message.getCompetitionLimitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      221,
      f,
      proto.odds.CompetitionLimit.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      162,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional CompetitionLimit global_limit = 199;
 * @return {?proto.odds.CompetitionLimit}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.getGlobalLimit = function() {
  return /** @type{?proto.odds.CompetitionLimit} */ (
    jspb.Message.getWrapperField(this, proto.odds.CompetitionLimit, 199));
};


/**
 * @param {?proto.odds.CompetitionLimit|undefined} value
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
*/
proto.odds.GetCompetitionBetLimitResponse.prototype.setGlobalLimit = function(value) {
  return jspb.Message.setWrapperField(this, 199, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.clearGlobalLimit = function() {
  return this.setGlobalLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.hasGlobalLimit = function() {
  return jspb.Message.getField(this, 199) != null;
};


/**
 * repeated CompetitionLimit competition_limits = 221;
 * @return {!Array<!proto.odds.CompetitionLimit>}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.getCompetitionLimitsList = function() {
  return /** @type{!Array<!proto.odds.CompetitionLimit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.CompetitionLimit, 221));
};


/**
 * @param {!Array<!proto.odds.CompetitionLimit>} value
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
*/
proto.odds.GetCompetitionBetLimitResponse.prototype.setCompetitionLimitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 221, value);
};


/**
 * @param {!proto.odds.CompetitionLimit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.CompetitionLimit}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.addCompetitionLimits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 221, opt_value, proto.odds.CompetitionLimit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.clearCompetitionLimitsList = function() {
  return this.setCompetitionLimitsList([]);
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 total_pages = 162;
 * @return {number}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 162, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
*/
proto.odds.GetCompetitionBetLimitResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.GetCompetitionBetLimitResponse} returns this
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.GetCompetitionBetLimitResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.UpdateCompetitionLimit.repeatedFields_ = [41];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.UpdateCompetitionLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.UpdateCompetitionLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.UpdateCompetitionLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateCompetitionLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionIdsList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f,
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 220, 0.0),
    isSingle: jspb.Message.getBooleanFieldWithDefault(msg, 227, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.UpdateCompetitionLimit}
 */
proto.odds.UpdateCompetitionLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.UpdateCompetitionLimit;
  return proto.odds.UpdateCompetitionLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.UpdateCompetitionLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.UpdateCompetitionLimit}
 */
proto.odds.UpdateCompetitionLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 41:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompetitionIds(values[i]);
      }
      break;
    case 220:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 227:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.UpdateCompetitionLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.UpdateCompetitionLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.UpdateCompetitionLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.UpdateCompetitionLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      41,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      220,
      f
    );
  }
  f = message.getIsSingle();
  if (f) {
    writer.writeBool(
      227,
      f
    );
  }
};


/**
 * repeated int32 competition_ids = 41;
 * @return {!Array<number>}
 */
proto.odds.UpdateCompetitionLimit.prototype.getCompetitionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.UpdateCompetitionLimit} returns this
 */
proto.odds.UpdateCompetitionLimit.prototype.setCompetitionIdsList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.UpdateCompetitionLimit} returns this
 */
proto.odds.UpdateCompetitionLimit.prototype.addCompetitionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.UpdateCompetitionLimit} returns this
 */
proto.odds.UpdateCompetitionLimit.prototype.clearCompetitionIdsList = function() {
  return this.setCompetitionIdsList([]);
};


/**
 * optional double amount = 220;
 * @return {number}
 */
proto.odds.UpdateCompetitionLimit.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 220, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.UpdateCompetitionLimit} returns this
 */
proto.odds.UpdateCompetitionLimit.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 220, value);
};


/**
 * optional bool is_single = 227;
 * @return {boolean}
 */
proto.odds.UpdateCompetitionLimit.prototype.getIsSingle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 227, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.UpdateCompetitionLimit} returns this
 */
proto.odds.UpdateCompetitionLimit.prototype.setIsSingle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 227, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MarketTemplate.repeatedFields_ = [125];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outcomeIdsList: (f = jspb.Message.getRepeatedField(msg, 125)) == null ? undefined : f,
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 126, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketTemplate}
 */
proto.odds.MarketTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketTemplate;
  return proto.odds.MarketTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketTemplate}
 */
proto.odds.MarketTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 125:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutcomeIds(values[i]);
      }
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutcomeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      125,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketTemplate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.MarketTemplate.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 outcome_ids = 125;
 * @return {!Array<number>}
 */
proto.odds.MarketTemplate.prototype.getOutcomeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 125));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.setOutcomeIdsList = function(value) {
  return jspb.Message.setField(this, 125, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.addOutcomeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 125, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.clearOutcomeIdsList = function() {
  return this.setOutcomeIdsList([]);
};


/**
 * optional bool is_active = 126;
 * @return {boolean}
 */
proto.odds.MarketTemplate.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.MarketTemplate} returns this
 */
proto.odds.MarketTemplate.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MarketTemplateResponse.repeatedFields_ = [225];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.odds.MarketTemplate.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketTemplateResponse}
 */
proto.odds.MarketTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketTemplateResponse;
  return proto.odds.MarketTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketTemplateResponse}
 */
proto.odds.MarketTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 225:
      var value = new proto.odds.MarketTemplate;
      reader.readMessage(value,proto.odds.MarketTemplate.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      225,
      f,
      proto.odds.MarketTemplate.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketTemplate templates = 225;
 * @return {!Array<!proto.odds.MarketTemplate>}
 */
proto.odds.MarketTemplateResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.odds.MarketTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketTemplate, 225));
};


/**
 * @param {!Array<!proto.odds.MarketTemplate>} value
 * @return {!proto.odds.MarketTemplateResponse} returns this
*/
proto.odds.MarketTemplateResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 225, value);
};


/**
 * @param {!proto.odds.MarketTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketTemplate}
 */
proto.odds.MarketTemplateResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 225, opt_value, proto.odds.MarketTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MarketTemplateResponse} returns this
 */
proto.odds.MarketTemplateResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.MarketTemplateResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.MarketTemplateResponse} returns this
*/
proto.odds.MarketTemplateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.MarketTemplateResponse} returns this
 */
proto.odds.MarketTemplateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.MarketTemplateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.ChangeMarketTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.ChangeMarketTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.ChangeMarketTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ChangeMarketTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    marketTemplateId: jspb.Message.getFieldWithDefault(msg, 224, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.ChangeMarketTemplateRequest}
 */
proto.odds.ChangeMarketTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.ChangeMarketTemplateRequest;
  return proto.odds.ChangeMarketTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.ChangeMarketTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.ChangeMarketTemplateRequest}
 */
proto.odds.ChangeMarketTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 224:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.ChangeMarketTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.ChangeMarketTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.ChangeMarketTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.ChangeMarketTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      224,
      f
    );
  }
};


/**
 * optional string event_id = 12;
 * @return {string}
 */
proto.odds.ChangeMarketTemplateRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.ChangeMarketTemplateRequest} returns this
 */
proto.odds.ChangeMarketTemplateRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds.ChangeMarketTemplateRequest.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ChangeMarketTemplateRequest} returns this
 */
proto.odds.ChangeMarketTemplateRequest.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 market_template_id = 224;
 * @return {number}
 */
proto.odds.ChangeMarketTemplateRequest.prototype.getMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 224, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.ChangeMarketTemplateRequest} returns this
 */
proto.odds.ChangeMarketTemplateRequest.prototype.setMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 224, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    searchString: jspb.Message.getFieldWithDefault(msg, 157, ""),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateTo: (f = msg.getDateTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    esport: jspb.Message.getBooleanFieldWithDefault(msg, 266, false),
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 77, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseResultsRequest}
 */
proto.odds.LandBaseResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseResultsRequest;
  return proto.odds.LandBaseResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseResultsRequest}
 */
proto.odds.LandBaseResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 157:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 159:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 160:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTo(value);
      break;
    case 266:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEsport(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 77:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      157,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      159,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateTo();
  if (f != null) {
    writer.writeMessage(
      160,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEsport();
  if (f) {
    writer.writeBool(
      266,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      77,
      f
    );
  }
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.LandBaseResultsRequest.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string search_string = 157;
 * @return {string}
 */
proto.odds.LandBaseResultsRequest.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 157, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 157, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 159;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.LandBaseResultsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 159));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
*/
proto.odds.LandBaseResultsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 159, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseResultsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 159) != null;
};


/**
 * optional google.protobuf.Timestamp date_to = 160;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.LandBaseResultsRequest.prototype.getDateTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 160));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
*/
proto.odds.LandBaseResultsRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 160, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.clearDateTo = function() {
  return this.setDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseResultsRequest.prototype.hasDateTo = function() {
  return jspb.Message.getField(this, 160) != null;
};


/**
 * optional bool esport = 266;
 * @return {boolean}
 */
proto.odds.LandBaseResultsRequest.prototype.getEsport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 266, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.setEsport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 266, value);
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.LandBaseResultsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 per_page = 77;
 * @return {number}
 */
proto.odds.LandBaseResultsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 77, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseResultsRequest} returns this
 */
proto.odds.LandBaseResultsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 77, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseResultEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseResultEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseResultEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseResultEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 80, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseResultEvent}
 */
proto.odds.LandBaseResultEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseResultEvent;
  return proto.odds.LandBaseResultEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseResultEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseResultEvent}
 */
proto.odds.LandBaseResultEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 145:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseResultEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseResultEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseResultEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseResultEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      145,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.LandBaseResultEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LandBaseResultEvent} returns this
 */
proto.odds.LandBaseResultEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.LandBaseResultEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LandBaseResultEvent} returns this
 */
proto.odds.LandBaseResultEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.LandBaseResultEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseResultEvent} returns this
 */
proto.odds.LandBaseResultEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string landbase_code = 80;
 * @return {string}
 */
proto.odds.LandBaseResultEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LandBaseResultEvent} returns this
 */
proto.odds.LandBaseResultEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional google.protobuf.Timestamp start = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.LandBaseResultEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.LandBaseResultEvent} returns this
*/
proto.odds.LandBaseResultEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseResultEvent} returns this
 */
proto.odds.LandBaseResultEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseResultEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional common.EventResult result = 145;
 * @return {?proto.common.EventResult}
 */
proto.odds.LandBaseResultEvent.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 145));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.odds.LandBaseResultEvent} returns this
*/
proto.odds.LandBaseResultEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 145, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseResultEvent} returns this
 */
proto.odds.LandBaseResultEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseResultEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 145) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.LandBaseResultsResponse.repeatedFields_ = [44];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LandBaseResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LandBaseResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LandBaseResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.LandBaseResultEvent.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 161, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 162, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LandBaseResultsResponse}
 */
proto.odds.LandBaseResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LandBaseResultsResponse;
  return proto.odds.LandBaseResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LandBaseResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LandBaseResultsResponse}
 */
proto.odds.LandBaseResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 44:
      var value = new proto.odds.LandBaseResultEvent;
      reader.readMessage(value,proto.odds.LandBaseResultEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 161:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 162:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 78:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LandBaseResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LandBaseResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LandBaseResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LandBaseResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.odds.LandBaseResultEvent.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      161,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      162,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LandBaseResultEvent events = 44;
 * @return {!Array<!proto.odds.LandBaseResultEvent>}
 */
proto.odds.LandBaseResultsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.LandBaseResultEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.LandBaseResultEvent, 44));
};


/**
 * @param {!Array<!proto.odds.LandBaseResultEvent>} value
 * @return {!proto.odds.LandBaseResultsResponse} returns this
*/
proto.odds.LandBaseResultsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.odds.LandBaseResultEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.LandBaseResultEvent}
 */
proto.odds.LandBaseResultsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.odds.LandBaseResultEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.LandBaseResultsResponse} returns this
 */
proto.odds.LandBaseResultsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int32 page = 161;
 * @return {number}
 */
proto.odds.LandBaseResultsResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 161, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseResultsResponse} returns this
 */
proto.odds.LandBaseResultsResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 161, value);
};


/**
 * optional int32 total_pages = 162;
 * @return {number}
 */
proto.odds.LandBaseResultsResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 162, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LandBaseResultsResponse} returns this
 */
proto.odds.LandBaseResultsResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 162, value);
};


/**
 * optional common.Error error = 78;
 * @return {?proto.common.Error}
 */
proto.odds.LandBaseResultsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.LandBaseResultsResponse} returns this
*/
proto.odds.LandBaseResultsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.LandBaseResultsResponse} returns this
 */
proto.odds.LandBaseResultsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.LandBaseResultsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SpecialMarketIds.repeatedFields_ = [52];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SpecialMarketIds.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SpecialMarketIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SpecialMarketIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SpecialMarketIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketIdsList: (f = jspb.Message.getRepeatedField(msg, 52)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SpecialMarketIds}
 */
proto.odds.SpecialMarketIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SpecialMarketIds;
  return proto.odds.SpecialMarketIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SpecialMarketIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SpecialMarketIds}
 */
proto.odds.SpecialMarketIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 52:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMarketIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SpecialMarketIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SpecialMarketIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SpecialMarketIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SpecialMarketIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      52,
      f
    );
  }
};


/**
 * repeated int32 market_ids = 52;
 * @return {!Array<number>}
 */
proto.odds.SpecialMarketIds.prototype.getMarketIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 52));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.SpecialMarketIds} returns this
 */
proto.odds.SpecialMarketIds.prototype.setMarketIdsList = function(value) {
  return jspb.Message.setField(this, 52, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.SpecialMarketIds} returns this
 */
proto.odds.SpecialMarketIds.prototype.addMarketIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 52, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SpecialMarketIds} returns this
 */
proto.odds.SpecialMarketIds.prototype.clearMarketIdsList = function() {
  return this.setMarketIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CancelEventOddsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CancelEventOddsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CancelEventOddsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelEventOddsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CancelEventOddsRequest}
 */
proto.odds.CancelEventOddsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CancelEventOddsRequest;
  return proto.odds.CancelEventOddsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CancelEventOddsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CancelEventOddsRequest}
 */
proto.odds.CancelEventOddsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CancelEventOddsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CancelEventOddsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CancelEventOddsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CancelEventOddsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.odds.CancelEventOddsRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CancelEventOddsRequest} returns this
 */
proto.odds.CancelEventOddsRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Error error = 2;
 * @return {?proto.common.Error}
 */
proto.odds.CancelEventOddsRequest.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 2));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.odds.CancelEventOddsRequest} returns this
*/
proto.odds.CancelEventOddsRequest.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.CancelEventOddsRequest} returns this
 */
proto.odds.CancelEventOddsRequest.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.CancelEventOddsRequest.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddStatusHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddStatusHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddStatusHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddStatusHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    previousStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    field: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resolvedBy: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddStatusHistory}
 */
proto.odds.OddStatusHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddStatusHistory;
  return proto.odds.OddStatusHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddStatusHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddStatusHistory}
 */
proto.odds.OddStatusHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOddId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResolvedBy(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddStatusHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddStatusHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddStatusHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddStatusHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreviousStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResolvedBy();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string odd_id = 1;
 * @return {string}
 */
proto.odds.OddStatusHistory.prototype.getOddId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatusHistory} returns this
 */
proto.odds.OddStatusHistory.prototype.setOddId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.odds.OddStatusHistory.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatusHistory} returns this
 */
proto.odds.OddStatusHistory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string previous_status = 3;
 * @return {string}
 */
proto.odds.OddStatusHistory.prototype.getPreviousStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatusHistory} returns this
 */
proto.odds.OddStatusHistory.prototype.setPreviousStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string field = 4;
 * @return {string}
 */
proto.odds.OddStatusHistory.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddStatusHistory} returns this
 */
proto.odds.OddStatusHistory.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 resolved_by = 5;
 * @return {number}
 */
proto.odds.OddStatusHistory.prototype.getResolvedBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddStatusHistory} returns this
 */
proto.odds.OddStatusHistory.prototype.setResolvedBy = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds.OddStatusHistory.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds.OddStatusHistory} returns this
*/
proto.odds.OddStatusHistory.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.OddStatusHistory} returns this
 */
proto.odds.OddStatusHistory.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.OddStatusHistory.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetOddStatusHistoryResponse.repeatedFields_ = [89];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetOddStatusHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetOddStatusHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetOddStatusHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOddStatusHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddStatusesList: jspb.Message.toObjectList(msg.getOddStatusesList(),
    proto.odds.OddStatusHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetOddStatusHistoryResponse}
 */
proto.odds.GetOddStatusHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetOddStatusHistoryResponse;
  return proto.odds.GetOddStatusHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetOddStatusHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetOddStatusHistoryResponse}
 */
proto.odds.GetOddStatusHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 89:
      var value = new proto.odds.OddStatusHistory;
      reader.readMessage(value,proto.odds.OddStatusHistory.deserializeBinaryFromReader);
      msg.addOddStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetOddStatusHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetOddStatusHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetOddStatusHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOddStatusHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      89,
      f,
      proto.odds.OddStatusHistory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OddStatusHistory odd_statuses = 89;
 * @return {!Array<!proto.odds.OddStatusHistory>}
 */
proto.odds.GetOddStatusHistoryResponse.prototype.getOddStatusesList = function() {
  return /** @type{!Array<!proto.odds.OddStatusHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OddStatusHistory, 89));
};


/**
 * @param {!Array<!proto.odds.OddStatusHistory>} value
 * @return {!proto.odds.GetOddStatusHistoryResponse} returns this
*/
proto.odds.GetOddStatusHistoryResponse.prototype.setOddStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 89, value);
};


/**
 * @param {!proto.odds.OddStatusHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OddStatusHistory}
 */
proto.odds.GetOddStatusHistoryResponse.prototype.addOddStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 89, opt_value, proto.odds.OddStatusHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetOddStatusHistoryResponse} returns this
 */
proto.odds.GetOddStatusHistoryResponse.prototype.clearOddStatusesList = function() {
  return this.setOddStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.FeaturedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.FeaturedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.FeaturedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.FeaturedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.FeaturedEvent}
 */
proto.odds.FeaturedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.FeaturedEvent;
  return proto.odds.FeaturedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.FeaturedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.FeaturedEvent}
 */
proto.odds.FeaturedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.FeaturedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.FeaturedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.FeaturedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.FeaturedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds.FeaturedEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.FeaturedEvent} returns this
 */
proto.odds.FeaturedEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.FeaturedEvents.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.FeaturedEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.FeaturedEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.FeaturedEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.FeaturedEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.FeaturedEvents}
 */
proto.odds.FeaturedEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.FeaturedEvents;
  return proto.odds.FeaturedEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.FeaturedEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.FeaturedEvents}
 */
proto.odds.FeaturedEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.FeaturedEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.FeaturedEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.FeaturedEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.FeaturedEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.FeaturedEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 1));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.FeaturedEvents} returns this
*/
proto.odds.FeaturedEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.FeaturedEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.FeaturedEvents} returns this
 */
proto.odds.FeaturedEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


goog.object.extend(exports, proto.odds);
