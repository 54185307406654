// source: proto/cache_service/cache.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.web_odds.CounterLocationsMap', null, global);
goog.exportSymbol('proto.web_odds.CounterMap', null, global);
goog.exportSymbol('proto.web_odds.CounterRequest', null, global);
goog.exportSymbol('proto.web_odds.CounterSportMap', null, global);
goog.exportSymbol('proto.web_odds.CounterTimeMap', null, global);
goog.exportSymbol('proto.web_odds.CounterTypeMap', null, global);
goog.exportSymbol('proto.web_odds.Error', null, global);
goog.exportSymbol('proto.web_odds.EventCurrentPhase', null, global);
goog.exportSymbol('proto.web_odds.EventCurrentResult', null, global);
goog.exportSymbol('proto.web_odds.EventLiveResultHistoryElement', null, global);
goog.exportSymbol('proto.web_odds.EventRequest', null, global);
goog.exportSymbol('proto.web_odds.EventResult', null, global);
goog.exportSymbol('proto.web_odds.EventTime', null, global);
goog.exportSymbol('proto.web_odds.InitialBasicOffer', null, global);
goog.exportSymbol('proto.web_odds.InitialBasicOfferMarket', null, global);
goog.exportSymbol('proto.web_odds.InitialCompetition', null, global);
goog.exportSymbol('proto.web_odds.InitialDataMarketsMap', null, global);
goog.exportSymbol('proto.web_odds.InitialDataRequest', null, global);
goog.exportSymbol('proto.web_odds.InitialDataResponse', null, global);
goog.exportSymbol('proto.web_odds.InitialDataSportsMap', null, global);
goog.exportSymbol('proto.web_odds.InitialLocation', null, global);
goog.exportSymbol('proto.web_odds.InitialLocationMap', null, global);
goog.exportSymbol('proto.web_odds.InitialMarket', null, global);
goog.exportSymbol('proto.web_odds.InitialMarketGroup', null, global);
goog.exportSymbol('proto.web_odds.InitialMarketGroupMap', null, global);
goog.exportSymbol('proto.web_odds.InitialMarketMap', null, global);
goog.exportSymbol('proto.web_odds.InitialOutcome', null, global);
goog.exportSymbol('proto.web_odds.InitialSport', null, global);
goog.exportSymbol('proto.web_odds.InitialSportMap', null, global);
goog.exportSymbol('proto.web_odds.LiveDisabled', null, global);
goog.exportSymbol('proto.web_odds.PlayerOutrightMarkets', null, global);
goog.exportSymbol('proto.web_odds.StreamEvent', null, global);
goog.exportSymbol('proto.web_odds.StreamEventOdd', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEvent', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEventOdd', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CounterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CounterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CounterRequest.displayName = 'proto.web_odds.CounterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CounterMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CounterMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CounterMap.displayName = 'proto.web_odds.CounterMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CounterSportMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CounterSportMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CounterSportMap.displayName = 'proto.web_odds.CounterSportMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CounterLocationsMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CounterLocationsMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CounterLocationsMap.displayName = 'proto.web_odds.CounterLocationsMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CounterTypeMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CounterTypeMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CounterTypeMap.displayName = 'proto.web_odds.CounterTypeMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CounterTimeMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CounterTimeMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CounterTimeMap.displayName = 'proto.web_odds.CounterTimeMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialDataRequest.displayName = 'proto.web_odds.InitialDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialDataResponse.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialDataResponse.displayName = 'proto.web_odds.InitialDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialSport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialSport.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialSport.displayName = 'proto.web_odds.InitialSport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialLocation.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialLocation.displayName = 'proto.web_odds.InitialLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialCompetition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialCompetition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialCompetition.displayName = 'proto.web_odds.InitialCompetition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialDataSportsMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialDataSportsMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialDataSportsMap.displayName = 'proto.web_odds.InitialDataSportsMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialSportMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialSportMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialSportMap.displayName = 'proto.web_odds.InitialSportMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialLocationMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialLocationMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialLocationMap.displayName = 'proto.web_odds.InitialLocationMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialBasicOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialBasicOffer.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialBasicOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialBasicOffer.displayName = 'proto.web_odds.InitialBasicOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialBasicOfferMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialBasicOfferMarket.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialBasicOfferMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialBasicOfferMarket.displayName = 'proto.web_odds.InitialBasicOfferMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialMarketGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.web_odds.InitialMarketGroup.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialMarketGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialMarketGroup.displayName = 'proto.web_odds.InitialMarketGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.web_odds.InitialMarket.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialMarket.displayName = 'proto.web_odds.InitialMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialOutcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialOutcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialOutcome.displayName = 'proto.web_odds.InitialOutcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialDataMarketsMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialDataMarketsMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialDataMarketsMap.displayName = 'proto.web_odds.InitialDataMarketsMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialMarketGroupMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.web_odds.InitialMarketGroupMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialMarketGroupMap.displayName = 'proto.web_odds.InitialMarketGroupMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialMarketMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.web_odds.InitialMarketMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialMarketMap.displayName = 'proto.web_odds.InitialMarketMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventTime.displayName = 'proto.web_odds.EventTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventCurrentPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventCurrentPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventCurrentPhase.displayName = 'proto.web_odds.EventCurrentPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventCurrentResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventCurrentResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventCurrentResult.displayName = 'proto.web_odds.EventCurrentResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventLiveResultHistoryElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventLiveResultHistoryElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventLiveResultHistoryElement.displayName = 'proto.web_odds.EventLiveResultHistoryElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.Error.repeatedFields_, null);
};
goog.inherits(proto.web_odds.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.Error.displayName = 'proto.web_odds.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.EventResult.repeatedFields_, null);
};
goog.inherits(proto.web_odds.EventResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventResult.displayName = 'proto.web_odds.EventResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.WebStreamEvent.repeatedFields_, null);
};
goog.inherits(proto.web_odds.WebStreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEvent.displayName = 'proto.web_odds.WebStreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.WebStreamEventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEventOdd.displayName = 'proto.web_odds.WebStreamEventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventRequest.displayName = 'proto.web_odds.EventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.StreamEventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.StreamEventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.StreamEventOdd.displayName = 'proto.web_odds.StreamEventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.StreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.StreamEvent.repeatedFields_, null);
};
goog.inherits(proto.web_odds.StreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.StreamEvent.displayName = 'proto.web_odds.StreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.LiveDisabled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.LiveDisabled.repeatedFields_, null);
};
goog.inherits(proto.web_odds.LiveDisabled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.LiveDisabled.displayName = 'proto.web_odds.LiveDisabled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.PlayerOutrightMarkets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.PlayerOutrightMarkets.repeatedFields_, null);
};
goog.inherits(proto.web_odds.PlayerOutrightMarkets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.PlayerOutrightMarkets.displayName = 'proto.web_odds.PlayerOutrightMarkets';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CounterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CounterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CounterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CounterRequest}
 */
proto.web_odds.CounterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CounterRequest;
  return proto.web_odds.CounterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CounterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CounterRequest}
 */
proto.web_odds.CounterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CounterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CounterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CounterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string offset = 1;
 * @return {string}
 */
proto.web_odds.CounterRequest.prototype.getOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.CounterRequest} returns this
 */
proto.web_odds.CounterRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CounterMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CounterMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CounterMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsMap: (f = msg.getSportsMap()) ? f.toObject(includeInstance, proto.web_odds.CounterSportMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CounterMap}
 */
proto.web_odds.CounterMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CounterMap;
  return proto.web_odds.CounterMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CounterMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CounterMap}
 */
proto.web_odds.CounterMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSportsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.CounterSportMap.deserializeBinaryFromReader, "", new proto.web_odds.CounterSportMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CounterMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CounterMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CounterMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.CounterSportMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, CounterSportMap> sports = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.CounterSportMap>}
 */
proto.web_odds.CounterMap.prototype.getSportsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.CounterSportMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.web_odds.CounterSportMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.CounterMap} returns this
 */
proto.web_odds.CounterMap.prototype.clearSportsMap = function() {
  this.getSportsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CounterSportMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CounterSportMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CounterSportMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterSportMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsMap: (f = msg.getLocationsMap()) ? f.toObject(includeInstance, proto.web_odds.CounterLocationsMap.toObject) : [],
    dateMap: (f = msg.getDateMap()) ? f.toObject(includeInstance, proto.web_odds.CounterTypeMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CounterSportMap}
 */
proto.web_odds.CounterSportMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CounterSportMap;
  return proto.web_odds.CounterSportMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CounterSportMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CounterSportMap}
 */
proto.web_odds.CounterSportMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLocationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.CounterLocationsMap.deserializeBinaryFromReader, "", new proto.web_odds.CounterLocationsMap());
         });
      break;
    case 2:
      var value = msg.getDateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.CounterTypeMap.deserializeBinaryFromReader, "", new proto.web_odds.CounterTypeMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CounterSportMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CounterSportMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CounterSportMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterSportMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.CounterLocationsMap.serializeBinaryToWriter);
  }
  f = message.getDateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.CounterTypeMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, CounterLocationsMap> locations = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.CounterLocationsMap>}
 */
proto.web_odds.CounterSportMap.prototype.getLocationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.CounterLocationsMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.web_odds.CounterLocationsMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.CounterSportMap} returns this
 */
proto.web_odds.CounterSportMap.prototype.clearLocationsMap = function() {
  this.getLocationsMap().clear();
  return this;};


/**
 * map<string, CounterTypeMap> date = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.CounterTypeMap>}
 */
proto.web_odds.CounterSportMap.prototype.getDateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.CounterTypeMap>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.web_odds.CounterTypeMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.CounterSportMap} returns this
 */
proto.web_odds.CounterSportMap.prototype.clearDateMap = function() {
  this.getDateMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CounterLocationsMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CounterLocationsMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CounterLocationsMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterLocationsMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionsMap: (f = msg.getCompetitionsMap()) ? f.toObject(includeInstance, proto.web_odds.CounterTypeMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CounterLocationsMap}
 */
proto.web_odds.CounterLocationsMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CounterLocationsMap;
  return proto.web_odds.CounterLocationsMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CounterLocationsMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CounterLocationsMap}
 */
proto.web_odds.CounterLocationsMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCompetitionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.CounterTypeMap.deserializeBinaryFromReader, "", new proto.web_odds.CounterTypeMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CounterLocationsMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CounterLocationsMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CounterLocationsMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterLocationsMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.CounterTypeMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, CounterTypeMap> competitions = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.CounterTypeMap>}
 */
proto.web_odds.CounterLocationsMap.prototype.getCompetitionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.CounterTypeMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.web_odds.CounterTypeMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.CounterLocationsMap} returns this
 */
proto.web_odds.CounterLocationsMap.prototype.clearCompetitionsMap = function() {
  this.getCompetitionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CounterTypeMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CounterTypeMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CounterTypeMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterTypeMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    live: (f = msg.getLive()) && proto.web_odds.CounterTimeMap.toObject(includeInstance, f),
    prematch: (f = msg.getPrematch()) && proto.web_odds.CounterTimeMap.toObject(includeInstance, f),
    o: (f = msg.getO()) && proto.web_odds.CounterTimeMap.toObject(includeInstance, f),
    p: (f = msg.getP()) && proto.web_odds.CounterTimeMap.toObject(includeInstance, f),
    g: (f = msg.getG()) && proto.web_odds.CounterTimeMap.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CounterTypeMap}
 */
proto.web_odds.CounterTypeMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CounterTypeMap;
  return proto.web_odds.CounterTypeMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CounterTypeMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CounterTypeMap}
 */
proto.web_odds.CounterTypeMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.CounterTimeMap;
      reader.readMessage(value,proto.web_odds.CounterTimeMap.deserializeBinaryFromReader);
      msg.setLive(value);
      break;
    case 2:
      var value = new proto.web_odds.CounterTimeMap;
      reader.readMessage(value,proto.web_odds.CounterTimeMap.deserializeBinaryFromReader);
      msg.setPrematch(value);
      break;
    case 3:
      var value = new proto.web_odds.CounterTimeMap;
      reader.readMessage(value,proto.web_odds.CounterTimeMap.deserializeBinaryFromReader);
      msg.setO(value);
      break;
    case 4:
      var value = new proto.web_odds.CounterTimeMap;
      reader.readMessage(value,proto.web_odds.CounterTimeMap.deserializeBinaryFromReader);
      msg.setP(value);
      break;
    case 5:
      var value = new proto.web_odds.CounterTimeMap;
      reader.readMessage(value,proto.web_odds.CounterTimeMap.deserializeBinaryFromReader);
      msg.setG(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CounterTypeMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CounterTypeMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CounterTypeMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterTypeMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLive();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.web_odds.CounterTimeMap.serializeBinaryToWriter
    );
  }
  f = message.getPrematch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.web_odds.CounterTimeMap.serializeBinaryToWriter
    );
  }
  f = message.getO();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.web_odds.CounterTimeMap.serializeBinaryToWriter
    );
  }
  f = message.getP();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.web_odds.CounterTimeMap.serializeBinaryToWriter
    );
  }
  f = message.getG();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.web_odds.CounterTimeMap.serializeBinaryToWriter
    );
  }
};


/**
 * optional CounterTimeMap live = 1;
 * @return {?proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTypeMap.prototype.getLive = function() {
  return /** @type{?proto.web_odds.CounterTimeMap} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.CounterTimeMap, 1));
};


/**
 * @param {?proto.web_odds.CounterTimeMap|undefined} value
 * @return {!proto.web_odds.CounterTypeMap} returns this
*/
proto.web_odds.CounterTypeMap.prototype.setLive = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.CounterTypeMap} returns this
 */
proto.web_odds.CounterTypeMap.prototype.clearLive = function() {
  return this.setLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.CounterTypeMap.prototype.hasLive = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CounterTimeMap prematch = 2;
 * @return {?proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTypeMap.prototype.getPrematch = function() {
  return /** @type{?proto.web_odds.CounterTimeMap} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.CounterTimeMap, 2));
};


/**
 * @param {?proto.web_odds.CounterTimeMap|undefined} value
 * @return {!proto.web_odds.CounterTypeMap} returns this
*/
proto.web_odds.CounterTypeMap.prototype.setPrematch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.CounterTypeMap} returns this
 */
proto.web_odds.CounterTypeMap.prototype.clearPrematch = function() {
  return this.setPrematch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.CounterTypeMap.prototype.hasPrematch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CounterTimeMap O = 3;
 * @return {?proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTypeMap.prototype.getO = function() {
  return /** @type{?proto.web_odds.CounterTimeMap} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.CounterTimeMap, 3));
};


/**
 * @param {?proto.web_odds.CounterTimeMap|undefined} value
 * @return {!proto.web_odds.CounterTypeMap} returns this
*/
proto.web_odds.CounterTypeMap.prototype.setO = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.CounterTypeMap} returns this
 */
proto.web_odds.CounterTypeMap.prototype.clearO = function() {
  return this.setO(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.CounterTypeMap.prototype.hasO = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CounterTimeMap P = 4;
 * @return {?proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTypeMap.prototype.getP = function() {
  return /** @type{?proto.web_odds.CounterTimeMap} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.CounterTimeMap, 4));
};


/**
 * @param {?proto.web_odds.CounterTimeMap|undefined} value
 * @return {!proto.web_odds.CounterTypeMap} returns this
*/
proto.web_odds.CounterTypeMap.prototype.setP = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.CounterTypeMap} returns this
 */
proto.web_odds.CounterTypeMap.prototype.clearP = function() {
  return this.setP(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.CounterTypeMap.prototype.hasP = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CounterTimeMap G = 5;
 * @return {?proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTypeMap.prototype.getG = function() {
  return /** @type{?proto.web_odds.CounterTimeMap} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.CounterTimeMap, 5));
};


/**
 * @param {?proto.web_odds.CounterTimeMap|undefined} value
 * @return {!proto.web_odds.CounterTypeMap} returns this
*/
proto.web_odds.CounterTypeMap.prototype.setG = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.CounterTypeMap} returns this
 */
proto.web_odds.CounterTypeMap.prototype.clearG = function() {
  return this.setG(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.CounterTypeMap.prototype.hasG = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CounterTimeMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CounterTimeMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CounterTimeMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterTimeMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getFieldWithDefault(msg, 1, 0),
    h1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    h3: jspb.Message.getFieldWithDefault(msg, 3, 0),
    d1: jspb.Message.getFieldWithDefault(msg, 4, 0),
    d3: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTimeMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CounterTimeMap;
  return proto.web_odds.CounterTimeMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CounterTimeMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CounterTimeMap}
 */
proto.web_odds.CounterTimeMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAll(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setH1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setH3(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setD1(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setD3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CounterTimeMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CounterTimeMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CounterTimeMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CounterTimeMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getH1();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getH3();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getD1();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getD3();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 all = 1;
 * @return {number}
 */
proto.web_odds.CounterTimeMap.prototype.getAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.CounterTimeMap} returns this
 */
proto.web_odds.CounterTimeMap.prototype.setAll = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 h1 = 2;
 * @return {number}
 */
proto.web_odds.CounterTimeMap.prototype.getH1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.CounterTimeMap} returns this
 */
proto.web_odds.CounterTimeMap.prototype.setH1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 h3 = 3;
 * @return {number}
 */
proto.web_odds.CounterTimeMap.prototype.getH3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.CounterTimeMap} returns this
 */
proto.web_odds.CounterTimeMap.prototype.setH3 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 d1 = 4;
 * @return {number}
 */
proto.web_odds.CounterTimeMap.prototype.getD1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.CounterTimeMap} returns this
 */
proto.web_odds.CounterTimeMap.prototype.setD1 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 d3 = 5;
 * @return {number}
 */
proto.web_odds.CounterTimeMap.prototype.getD3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.CounterTimeMap} returns this
 */
proto.web_odds.CounterTimeMap.prototype.setD3 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    onlyMarkets: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialDataRequest}
 */
proto.web_odds.InitialDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialDataRequest;
  return proto.web_odds.InitialDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialDataRequest}
 */
proto.web_odds.InitialDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnlyMarkets();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool only_markets = 1;
 * @return {boolean}
 */
proto.web_odds.InitialDataRequest.prototype.getOnlyMarkets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.InitialDataRequest} returns this
 */
proto.web_odds.InitialDataRequest.prototype.setOnlyMarkets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialDataResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupsList: jspb.Message.toObjectList(msg.getMarketGroupsList(),
    proto.web_odds.InitialMarketGroup.toObject, includeInstance),
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.web_odds.InitialSport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialDataResponse}
 */
proto.web_odds.InitialDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialDataResponse;
  return proto.web_odds.InitialDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialDataResponse}
 */
proto.web_odds.InitialDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.InitialMarketGroup;
      reader.readMessage(value,proto.web_odds.InitialMarketGroup.deserializeBinaryFromReader);
      msg.addMarketGroups(value);
      break;
    case 2:
      var value = new proto.web_odds.InitialSport;
      reader.readMessage(value,proto.web_odds.InitialSport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_odds.InitialMarketGroup.serializeBinaryToWriter
    );
  }
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.web_odds.InitialSport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InitialMarketGroup market_groups = 1;
 * @return {!Array<!proto.web_odds.InitialMarketGroup>}
 */
proto.web_odds.InitialDataResponse.prototype.getMarketGroupsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarketGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarketGroup, 1));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarketGroup>} value
 * @return {!proto.web_odds.InitialDataResponse} returns this
*/
proto.web_odds.InitialDataResponse.prototype.setMarketGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_odds.InitialMarketGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarketGroup}
 */
proto.web_odds.InitialDataResponse.prototype.addMarketGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_odds.InitialMarketGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialDataResponse} returns this
 */
proto.web_odds.InitialDataResponse.prototype.clearMarketGroupsList = function() {
  return this.setMarketGroupsList([]);
};


/**
 * repeated InitialSport sports = 2;
 * @return {!Array<!proto.web_odds.InitialSport>}
 */
proto.web_odds.InitialDataResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialSport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialSport, 2));
};


/**
 * @param {!Array<!proto.web_odds.InitialSport>} value
 * @return {!proto.web_odds.InitialDataResponse} returns this
*/
proto.web_odds.InitialDataResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web_odds.InitialSport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialSport}
 */
proto.web_odds.InitialDataResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web_odds.InitialSport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialDataResponse} returns this
 */
proto.web_odds.InitialDataResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialSport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialSport.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialSport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialSport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialSport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.web_odds.InitialLocation.toObject, includeInstance),
    basicOfferLive: (f = msg.getBasicOfferLive()) && proto.web_odds.InitialBasicOffer.toObject(includeInstance, f),
    basicOfferPrematch: (f = msg.getBasicOfferPrematch()) && proto.web_odds.InitialBasicOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialSport}
 */
proto.web_odds.InitialSport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialSport;
  return proto.web_odds.InitialSport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialSport}
 */
proto.web_odds.InitialSport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialLocation;
      reader.readMessage(value,proto.web_odds.InitialLocation.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 4:
      var value = new proto.web_odds.InitialBasicOffer;
      reader.readMessage(value,proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferLive(value);
      break;
    case 5:
      var value = new proto.web_odds.InitialBasicOffer;
      reader.readMessage(value,proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferPrematch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialSport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialSport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialLocation.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferLive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.web_odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferPrematch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.web_odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialSport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialSport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InitialLocation locations = 3;
 * @return {!Array<!proto.web_odds.InitialLocation>}
 */
proto.web_odds.InitialSport.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialLocation, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialLocation>} value
 * @return {!proto.web_odds.InitialSport} returns this
*/
proto.web_odds.InitialSport.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialLocation}
 */
proto.web_odds.InitialSport.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * optional InitialBasicOffer basic_offer_live = 4;
 * @return {?proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialSport.prototype.getBasicOfferLive = function() {
  return /** @type{?proto.web_odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialBasicOffer, 4));
};


/**
 * @param {?proto.web_odds.InitialBasicOffer|undefined} value
 * @return {!proto.web_odds.InitialSport} returns this
*/
proto.web_odds.InitialSport.prototype.setBasicOfferLive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.clearBasicOfferLive = function() {
  return this.setBasicOfferLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.InitialSport.prototype.hasBasicOfferLive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional InitialBasicOffer basic_offer_prematch = 5;
 * @return {?proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialSport.prototype.getBasicOfferPrematch = function() {
  return /** @type{?proto.web_odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialBasicOffer, 5));
};


/**
 * @param {?proto.web_odds.InitialBasicOffer|undefined} value
 * @return {!proto.web_odds.InitialSport} returns this
*/
proto.web_odds.InitialSport.prototype.setBasicOfferPrematch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.clearBasicOfferPrematch = function() {
  return this.setBasicOfferPrematch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.InitialSport.prototype.hasBasicOfferPrematch = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialLocation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.web_odds.InitialCompetition.toObject, includeInstance),
    flagCode: jspb.Message.getFieldWithDefault(msg, 444, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialLocation}
 */
proto.web_odds.InitialLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialLocation;
  return proto.web_odds.InitialLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialLocation}
 */
proto.web_odds.InitialLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialCompetition;
      reader.readMessage(value,proto.web_odds.InitialCompetition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 444:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialCompetition.serializeBinaryToWriter
    );
  }
  f = message.getFlagCode();
  if (f.length > 0) {
    writer.writeString(
      444,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialLocation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialLocation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InitialCompetition competitions = 3;
 * @return {!Array<!proto.web_odds.InitialCompetition>}
 */
proto.web_odds.InitialLocation.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialCompetition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialCompetition, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialCompetition>} value
 * @return {!proto.web_odds.InitialLocation} returns this
*/
proto.web_odds.InitialLocation.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialCompetition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialCompetition}
 */
proto.web_odds.InitialLocation.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialCompetition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional string flag_code = 444;
 * @return {string}
 */
proto.web_odds.InitialLocation.prototype.getFlagCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 444, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.setFlagCode = function(value) {
  return jspb.Message.setProto3StringField(this, 444, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialCompetition.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialCompetition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialCompetition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialCompetition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionOrder: jspb.Message.getFieldWithDefault(msg, 333, 0),
    locationOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 334, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialCompetition}
 */
proto.web_odds.InitialCompetition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialCompetition;
  return proto.web_odds.InitialCompetition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialCompetition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialCompetition}
 */
proto.web_odds.InitialCompetition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 333:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationOrder(value);
      break;
    case 334:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialCompetition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialCompetition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialCompetition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialCompetition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionOrder();
  if (f !== 0) {
    writer.writeInt32(
      333,
      f
    );
  }
  f = message.getLocationOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      334,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialCompetition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialCompetition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.web_odds.InitialCompetition.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_order = 333;
 * @return {number}
 */
proto.web_odds.InitialCompetition.prototype.getCompetitionOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 333, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setCompetitionOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 333, value);
};


/**
 * optional int32 location_order = 4;
 * @return {number}
 */
proto.web_odds.InitialCompetition.prototype.getLocationOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setLocationOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_favorite = 334;
 * @return {boolean}
 */
proto.web_odds.InitialCompetition.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 334, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 334, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialDataSportsMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialDataSportsMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialDataSportsMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataSportsMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsMap: (f = msg.getSportsMap()) ? f.toObject(includeInstance, proto.web_odds.InitialSportMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialDataSportsMap}
 */
proto.web_odds.InitialDataSportsMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialDataSportsMap;
  return proto.web_odds.InitialDataSportsMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialDataSportsMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialDataSportsMap}
 */
proto.web_odds.InitialDataSportsMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSportsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.InitialSportMap.deserializeBinaryFromReader, "", new proto.web_odds.InitialSportMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialDataSportsMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialDataSportsMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialDataSportsMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataSportsMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.InitialSportMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, InitialSportMap> sports = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.InitialSportMap>}
 */
proto.web_odds.InitialDataSportsMap.prototype.getSportsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.InitialSportMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.web_odds.InitialSportMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.InitialDataSportsMap} returns this
 */
proto.web_odds.InitialDataSportsMap.prototype.clearSportsMap = function() {
  this.getSportsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialSportMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialSportMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialSportMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialSportMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    locationsMap: (f = msg.getLocationsMap()) ? f.toObject(includeInstance, proto.web_odds.InitialLocationMap.toObject) : [],
    basicOfferLive: (f = msg.getBasicOfferLive()) && proto.web_odds.InitialBasicOffer.toObject(includeInstance, f),
    basicOfferPrematch: (f = msg.getBasicOfferPrematch()) && proto.web_odds.InitialBasicOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialSportMap}
 */
proto.web_odds.InitialSportMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialSportMap;
  return proto.web_odds.InitialSportMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialSportMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialSportMap}
 */
proto.web_odds.InitialSportMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = msg.getLocationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.InitialLocationMap.deserializeBinaryFromReader, "", new proto.web_odds.InitialLocationMap());
         });
      break;
    case 4:
      var value = new proto.web_odds.InitialBasicOffer;
      reader.readMessage(value,proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferLive(value);
      break;
    case 5:
      var value = new proto.web_odds.InitialBasicOffer;
      reader.readMessage(value,proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferPrematch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialSportMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialSportMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialSportMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialSportMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLocationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.InitialLocationMap.serializeBinaryToWriter);
  }
  f = message.getBasicOfferLive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.web_odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferPrematch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.web_odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialSportMap.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialSportMap} returns this
 */
proto.web_odds.InitialSportMap.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialSportMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialSportMap} returns this
 */
proto.web_odds.InitialSportMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.web_odds.InitialSportMap.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialSportMap} returns this
 */
proto.web_odds.InitialSportMap.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * map<string, InitialLocationMap> locations = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.InitialLocationMap>}
 */
proto.web_odds.InitialSportMap.prototype.getLocationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.InitialLocationMap>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.web_odds.InitialLocationMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.InitialSportMap} returns this
 */
proto.web_odds.InitialSportMap.prototype.clearLocationsMap = function() {
  this.getLocationsMap().clear();
  return this;};


/**
 * optional InitialBasicOffer basic_offer_live = 4;
 * @return {?proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialSportMap.prototype.getBasicOfferLive = function() {
  return /** @type{?proto.web_odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialBasicOffer, 4));
};


/**
 * @param {?proto.web_odds.InitialBasicOffer|undefined} value
 * @return {!proto.web_odds.InitialSportMap} returns this
*/
proto.web_odds.InitialSportMap.prototype.setBasicOfferLive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.InitialSportMap} returns this
 */
proto.web_odds.InitialSportMap.prototype.clearBasicOfferLive = function() {
  return this.setBasicOfferLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.InitialSportMap.prototype.hasBasicOfferLive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional InitialBasicOffer basic_offer_prematch = 5;
 * @return {?proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialSportMap.prototype.getBasicOfferPrematch = function() {
  return /** @type{?proto.web_odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialBasicOffer, 5));
};


/**
 * @param {?proto.web_odds.InitialBasicOffer|undefined} value
 * @return {!proto.web_odds.InitialSportMap} returns this
*/
proto.web_odds.InitialSportMap.prototype.setBasicOfferPrematch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.InitialSportMap} returns this
 */
proto.web_odds.InitialSportMap.prototype.clearBasicOfferPrematch = function() {
  return this.setBasicOfferPrematch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.InitialSportMap.prototype.hasBasicOfferPrematch = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialLocationMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialLocationMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialLocationMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialLocationMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionsMap: (f = msg.getCompetitionsMap()) ? f.toObject(includeInstance, proto.web_odds.InitialCompetition.toObject) : [],
    flagCode: jspb.Message.getFieldWithDefault(msg, 444, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialLocationMap}
 */
proto.web_odds.InitialLocationMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialLocationMap;
  return proto.web_odds.InitialLocationMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialLocationMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialLocationMap}
 */
proto.web_odds.InitialLocationMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = msg.getCompetitionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.InitialCompetition.deserializeBinaryFromReader, "", new proto.web_odds.InitialCompetition());
         });
      break;
    case 444:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialLocationMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialLocationMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialLocationMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialLocationMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.InitialCompetition.serializeBinaryToWriter);
  }
  f = message.getFlagCode();
  if (f.length > 0) {
    writer.writeString(
      444,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialLocationMap.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialLocationMap} returns this
 */
proto.web_odds.InitialLocationMap.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialLocationMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialLocationMap} returns this
 */
proto.web_odds.InitialLocationMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, InitialCompetition> competitions = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.InitialCompetition>}
 */
proto.web_odds.InitialLocationMap.prototype.getCompetitionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.InitialCompetition>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.web_odds.InitialCompetition));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.InitialLocationMap} returns this
 */
proto.web_odds.InitialLocationMap.prototype.clearCompetitionsMap = function() {
  this.getCompetitionsMap().clear();
  return this;};


/**
 * optional string flag_code = 444;
 * @return {string}
 */
proto.web_odds.InitialLocationMap.prototype.getFlagCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 444, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialLocationMap} returns this
 */
proto.web_odds.InitialLocationMap.prototype.setFlagCode = function(value) {
  return jspb.Message.setProto3StringField(this, 444, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialBasicOffer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialBasicOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialBasicOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialBasicOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.web_odds.InitialBasicOfferMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialBasicOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialBasicOffer;
  return proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialBasicOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.InitialBasicOfferMarket;
      reader.readMessage(value,proto.web_odds.InitialBasicOfferMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialBasicOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialBasicOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialBasicOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_odds.InitialBasicOfferMarket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InitialBasicOfferMarket markets = 1;
 * @return {!Array<!proto.web_odds.InitialBasicOfferMarket>}
 */
proto.web_odds.InitialBasicOffer.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialBasicOfferMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialBasicOfferMarket, 1));
};


/**
 * @param {!Array<!proto.web_odds.InitialBasicOfferMarket>} value
 * @return {!proto.web_odds.InitialBasicOffer} returns this
*/
proto.web_odds.InitialBasicOffer.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_odds.InitialBasicOfferMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialBasicOfferMarket}
 */
proto.web_odds.InitialBasicOffer.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_odds.InitialBasicOfferMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialBasicOffer} returns this
 */
proto.web_odds.InitialBasicOffer.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialBasicOfferMarket.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialBasicOfferMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialBasicOfferMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOfferMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcomeIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialBasicOfferMarket}
 */
proto.web_odds.InitialBasicOfferMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialBasicOfferMarket;
  return proto.web_odds.InitialBasicOfferMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialBasicOfferMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialBasicOfferMarket}
 */
proto.web_odds.InitialBasicOfferMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutcomeIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialBasicOfferMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialBasicOfferMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOfferMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutcomeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 market_id = 1;
 * @return {number}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 outcome_ids = 2;
 * @return {!Array<number>}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.getOutcomeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.setOutcomeIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.addOutcomeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.clearOutcomeIdsList = function() {
  return this.setOutcomeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialMarketGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialMarketGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialMarketGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialMarketGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 555, ""),
    albanianName: jspb.Message.getFieldWithDefault(msg, 556, ""),
    turkishName: jspb.Message.getFieldWithDefault(msg, 557, ""),
    russianName: jspb.Message.getFieldWithDefault(msg, 558, ""),
    ukrainianName: jspb.Message.getFieldWithDefault(msg, 559, ""),
    italianName: jspb.Message.getFieldWithDefault(msg, 560, ""),
    germanName: jspb.Message.getFieldWithDefault(msg, 561, ""),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.web_odds.InitialMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialMarketGroup}
 */
proto.web_odds.InitialMarketGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialMarketGroup;
  return proto.web_odds.InitialMarketGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialMarketGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialMarketGroup}
 */
proto.web_odds.InitialMarketGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 555:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 556:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbanianName(value);
      break;
    case 557:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurkishName(value);
      break;
    case 558:
      var value = /** @type {string} */ (reader.readString());
      msg.setRussianName(value);
      break;
    case 559:
      var value = /** @type {string} */ (reader.readString());
      msg.setUkrainianName(value);
      break;
    case 560:
      var value = /** @type {string} */ (reader.readString());
      msg.setItalianName(value);
      break;
    case 561:
      var value = /** @type {string} */ (reader.readString());
      msg.setGermanName(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialMarket;
      reader.readMessage(value,proto.web_odds.InitialMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialMarketGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialMarketGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialMarketGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      555,
      f
    );
  }
  f = message.getAlbanianName();
  if (f.length > 0) {
    writer.writeString(
      556,
      f
    );
  }
  f = message.getTurkishName();
  if (f.length > 0) {
    writer.writeString(
      557,
      f
    );
  }
  f = message.getRussianName();
  if (f.length > 0) {
    writer.writeString(
      558,
      f
    );
  }
  f = message.getUkrainianName();
  if (f.length > 0) {
    writer.writeString(
      559,
      f
    );
  }
  f = message.getItalianName();
  if (f.length > 0) {
    writer.writeString(
      560,
      f
    );
  }
  f = message.getGermanName();
  if (f.length > 0) {
    writer.writeString(
      561,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialMarket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialMarketGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string english_name = 555;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 555, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 555, value);
};


/**
 * optional string albanian_name = 556;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getAlbanianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 556, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setAlbanianName = function(value) {
  return jspb.Message.setProto3StringField(this, 556, value);
};


/**
 * optional string turkish_name = 557;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getTurkishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 557, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setTurkishName = function(value) {
  return jspb.Message.setProto3StringField(this, 557, value);
};


/**
 * optional string russian_name = 558;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getRussianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 558, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setRussianName = function(value) {
  return jspb.Message.setProto3StringField(this, 558, value);
};


/**
 * optional string ukrainian_name = 559;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getUkrainianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 559, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setUkrainianName = function(value) {
  return jspb.Message.setProto3StringField(this, 559, value);
};


/**
 * optional string italian_name = 560;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getItalianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 560, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setItalianName = function(value) {
  return jspb.Message.setProto3StringField(this, 560, value);
};


/**
 * optional string german_name = 561;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getGermanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 561, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setGermanName = function(value) {
  return jspb.Message.setProto3StringField(this, 561, value);
};


/**
 * repeated InitialMarket markets = 3;
 * @return {!Array<!proto.web_odds.InitialMarket>}
 */
proto.web_odds.InitialMarketGroup.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarket, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarket>} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
*/
proto.web_odds.InitialMarketGroup.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.InitialMarketGroup.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialMarket.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 555, ""),
    albanianName: jspb.Message.getFieldWithDefault(msg, 556, ""),
    turkishName: jspb.Message.getFieldWithDefault(msg, 557, ""),
    russianName: jspb.Message.getFieldWithDefault(msg, 558, ""),
    ukrainianName: jspb.Message.getFieldWithDefault(msg, 559, ""),
    italianName: jspb.Message.getFieldWithDefault(msg, 560, ""),
    germanName: jspb.Message.getFieldWithDefault(msg, 561, ""),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.web_odds.InitialOutcome.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.InitialMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialMarket;
  return proto.web_odds.InitialMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.InitialMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 555:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 556:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbanianName(value);
      break;
    case 557:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurkishName(value);
      break;
    case 558:
      var value = /** @type {string} */ (reader.readString());
      msg.setRussianName(value);
      break;
    case 559:
      var value = /** @type {string} */ (reader.readString());
      msg.setUkrainianName(value);
      break;
    case 560:
      var value = /** @type {string} */ (reader.readString());
      msg.setItalianName(value);
      break;
    case 561:
      var value = /** @type {string} */ (reader.readString());
      msg.setGermanName(value);
      break;
    case 4:
      var value = new proto.web_odds.InitialOutcome;
      reader.readMessage(value,proto.web_odds.InitialOutcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      555,
      f
    );
  }
  f = message.getAlbanianName();
  if (f.length > 0) {
    writer.writeString(
      556,
      f
    );
  }
  f = message.getTurkishName();
  if (f.length > 0) {
    writer.writeString(
      557,
      f
    );
  }
  f = message.getRussianName();
  if (f.length > 0) {
    writer.writeString(
      558,
      f
    );
  }
  f = message.getUkrainianName();
  if (f.length > 0) {
    writer.writeString(
      559,
      f
    );
  }
  f = message.getItalianName();
  if (f.length > 0) {
    writer.writeString(
      560,
      f
    );
  }
  f = message.getGermanName();
  if (f.length > 0) {
    writer.writeString(
      561,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.web_odds.InitialOutcome.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.web_odds.InitialMarket.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string english_name = 555;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 555, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 555, value);
};


/**
 * optional string albanian_name = 556;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getAlbanianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 556, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setAlbanianName = function(value) {
  return jspb.Message.setProto3StringField(this, 556, value);
};


/**
 * optional string turkish_name = 557;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getTurkishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 557, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setTurkishName = function(value) {
  return jspb.Message.setProto3StringField(this, 557, value);
};


/**
 * optional string russian_name = 558;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getRussianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 558, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setRussianName = function(value) {
  return jspb.Message.setProto3StringField(this, 558, value);
};


/**
 * optional string ukrainian_name = 559;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getUkrainianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 559, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setUkrainianName = function(value) {
  return jspb.Message.setProto3StringField(this, 559, value);
};


/**
 * optional string italian_name = 560;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getItalianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 560, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setItalianName = function(value) {
  return jspb.Message.setProto3StringField(this, 560, value);
};


/**
 * optional string german_name = 561;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getGermanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 561, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setGermanName = function(value) {
  return jspb.Message.setProto3StringField(this, 561, value);
};


/**
 * repeated InitialOutcome outcomes = 4;
 * @return {!Array<!proto.web_odds.InitialOutcome>}
 */
proto.web_odds.InitialMarket.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.web_odds.InitialOutcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialOutcome, 4));
};


/**
 * @param {!Array<!proto.web_odds.InitialOutcome>} value
 * @return {!proto.web_odds.InitialMarket} returns this
*/
proto.web_odds.InitialMarket.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.web_odds.InitialOutcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialOutcome}
 */
proto.web_odds.InitialMarket.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.web_odds.InitialOutcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialOutcome.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialOutcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialOutcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialOutcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomeTic: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exeLimit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    order: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialOutcome}
 */
proto.web_odds.InitialOutcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialOutcome;
  return proto.web_odds.InitialOutcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialOutcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialOutcome}
 */
proto.web_odds.InitialOutcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeTic(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialOutcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialOutcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialOutcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialOutcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomeTic();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExeLimit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialOutcome.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outcome_group = 3;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outcome_tic = 4;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getOutcomeTic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setOutcomeTic = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exe_limit = 5;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getExeLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setExeLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string short_description = 6;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_limit = 7;
 * @return {boolean}
 */
proto.web_odds.InitialOutcome.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 order = 8;
 * @return {number}
 */
proto.web_odds.InitialOutcome.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialDataMarketsMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialDataMarketsMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialDataMarketsMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataMarketsMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupsMap: (f = msg.getMarketGroupsMap()) ? f.toObject(includeInstance, proto.web_odds.InitialMarketGroupMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialDataMarketsMap}
 */
proto.web_odds.InitialDataMarketsMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialDataMarketsMap;
  return proto.web_odds.InitialDataMarketsMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialDataMarketsMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialDataMarketsMap}
 */
proto.web_odds.InitialDataMarketsMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getMarketGroupsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.InitialMarketGroupMap.deserializeBinaryFromReader, "", new proto.web_odds.InitialMarketGroupMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialDataMarketsMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialDataMarketsMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialDataMarketsMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataMarketsMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.InitialMarketGroupMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, InitialMarketGroupMap> market_groups = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.InitialMarketGroupMap>}
 */
proto.web_odds.InitialDataMarketsMap.prototype.getMarketGroupsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.InitialMarketGroupMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.web_odds.InitialMarketGroupMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.InitialDataMarketsMap} returns this
 */
proto.web_odds.InitialDataMarketsMap.prototype.clearMarketGroupsMap = function() {
  this.getMarketGroupsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialMarketGroupMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialMarketGroupMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialMarketGroupMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketGroupMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    albanianName: jspb.Message.getFieldWithDefault(msg, 556, ""),
    turkishName: jspb.Message.getFieldWithDefault(msg, 557, ""),
    russianName: jspb.Message.getFieldWithDefault(msg, 558, ""),
    ukrainianName: jspb.Message.getFieldWithDefault(msg, 559, ""),
    italianName: jspb.Message.getFieldWithDefault(msg, 560, ""),
    germanName: jspb.Message.getFieldWithDefault(msg, 561, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, proto.web_odds.InitialMarketMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialMarketGroupMap}
 */
proto.web_odds.InitialMarketGroupMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialMarketGroupMap;
  return proto.web_odds.InitialMarketGroupMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialMarketGroupMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialMarketGroupMap}
 */
proto.web_odds.InitialMarketGroupMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 556:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbanianName(value);
      break;
    case 557:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurkishName(value);
      break;
    case 558:
      var value = /** @type {string} */ (reader.readString());
      msg.setRussianName(value);
      break;
    case 559:
      var value = /** @type {string} */ (reader.readString());
      msg.setUkrainianName(value);
      break;
    case 560:
      var value = /** @type {string} */ (reader.readString());
      msg.setItalianName(value);
      break;
    case 561:
      var value = /** @type {string} */ (reader.readString());
      msg.setGermanName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.InitialMarketMap.deserializeBinaryFromReader, "", new proto.web_odds.InitialMarketMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialMarketGroupMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialMarketGroupMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialMarketGroupMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketGroupMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAlbanianName();
  if (f.length > 0) {
    writer.writeString(
      556,
      f
    );
  }
  f = message.getTurkishName();
  if (f.length > 0) {
    writer.writeString(
      557,
      f
    );
  }
  f = message.getRussianName();
  if (f.length > 0) {
    writer.writeString(
      558,
      f
    );
  }
  f = message.getUkrainianName();
  if (f.length > 0) {
    writer.writeString(
      559,
      f
    );
  }
  f = message.getItalianName();
  if (f.length > 0) {
    writer.writeString(
      560,
      f
    );
  }
  f = message.getGermanName();
  if (f.length > 0) {
    writer.writeString(
      561,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.InitialMarketMap.serializeBinaryToWriter);
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string english_name = 4;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string albanian_name = 556;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getAlbanianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 556, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setAlbanianName = function(value) {
  return jspb.Message.setProto3StringField(this, 556, value);
};


/**
 * optional string turkish_name = 557;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getTurkishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 557, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setTurkishName = function(value) {
  return jspb.Message.setProto3StringField(this, 557, value);
};


/**
 * optional string russian_name = 558;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getRussianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 558, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setRussianName = function(value) {
  return jspb.Message.setProto3StringField(this, 558, value);
};


/**
 * optional string ukrainian_name = 559;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getUkrainianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 559, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setUkrainianName = function(value) {
  return jspb.Message.setProto3StringField(this, 559, value);
};


/**
 * optional string italian_name = 560;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getItalianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 560, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setItalianName = function(value) {
  return jspb.Message.setProto3StringField(this, 560, value);
};


/**
 * optional string german_name = 561;
 * @return {string}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getGermanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 561, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setGermanName = function(value) {
  return jspb.Message.setProto3StringField(this, 561, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<string, InitialMarketMap> markets = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.InitialMarketMap>}
 */
proto.web_odds.InitialMarketGroupMap.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.InitialMarketMap>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.web_odds.InitialMarketMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.InitialMarketGroupMap} returns this
 */
proto.web_odds.InitialMarketGroupMap.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialMarketMap.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialMarketMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialMarketMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    albanianName: jspb.Message.getFieldWithDefault(msg, 556, ""),
    turkishName: jspb.Message.getFieldWithDefault(msg, 557, ""),
    russianName: jspb.Message.getFieldWithDefault(msg, 558, ""),
    ukrainianName: jspb.Message.getFieldWithDefault(msg, 559, ""),
    italianName: jspb.Message.getFieldWithDefault(msg, 560, ""),
    germanName: jspb.Message.getFieldWithDefault(msg, 561, ""),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    outcomesMap: (f = msg.getOutcomesMap()) ? f.toObject(includeInstance, proto.web_odds.InitialOutcome.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialMarketMap}
 */
proto.web_odds.InitialMarketMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialMarketMap;
  return proto.web_odds.InitialMarketMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialMarketMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialMarketMap}
 */
proto.web_odds.InitialMarketMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 556:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbanianName(value);
      break;
    case 557:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurkishName(value);
      break;
    case 558:
      var value = /** @type {string} */ (reader.readString());
      msg.setRussianName(value);
      break;
    case 559:
      var value = /** @type {string} */ (reader.readString());
      msg.setUkrainianName(value);
      break;
    case 560:
      var value = /** @type {string} */ (reader.readString());
      msg.setItalianName(value);
      break;
    case 561:
      var value = /** @type {string} */ (reader.readString());
      msg.setGermanName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = msg.getOutcomesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.web_odds.InitialOutcome.deserializeBinaryFromReader, "", new proto.web_odds.InitialOutcome());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialMarketMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialMarketMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialMarketMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAlbanianName();
  if (f.length > 0) {
    writer.writeString(
      556,
      f
    );
  }
  f = message.getTurkishName();
  if (f.length > 0) {
    writer.writeString(
      557,
      f
    );
  }
  f = message.getRussianName();
  if (f.length > 0) {
    writer.writeString(
      558,
      f
    );
  }
  f = message.getUkrainianName();
  if (f.length > 0) {
    writer.writeString(
      559,
      f
    );
  }
  f = message.getItalianName();
  if (f.length > 0) {
    writer.writeString(
      560,
      f
    );
  }
  f = message.getGermanName();
  if (f.length > 0) {
    writer.writeString(
      561,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOutcomesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.web_odds.InitialOutcome.serializeBinaryToWriter);
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialMarketMap.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.web_odds.InitialMarketMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string english_name = 5;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string albanian_name = 556;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getAlbanianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 556, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setAlbanianName = function(value) {
  return jspb.Message.setProto3StringField(this, 556, value);
};


/**
 * optional string turkish_name = 557;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getTurkishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 557, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setTurkishName = function(value) {
  return jspb.Message.setProto3StringField(this, 557, value);
};


/**
 * optional string russian_name = 558;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getRussianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 558, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setRussianName = function(value) {
  return jspb.Message.setProto3StringField(this, 558, value);
};


/**
 * optional string ukrainian_name = 559;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getUkrainianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 559, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setUkrainianName = function(value) {
  return jspb.Message.setProto3StringField(this, 559, value);
};


/**
 * optional string italian_name = 560;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getItalianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 560, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setItalianName = function(value) {
  return jspb.Message.setProto3StringField(this, 560, value);
};


/**
 * optional string german_name = 561;
 * @return {string}
 */
proto.web_odds.InitialMarketMap.prototype.getGermanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 561, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setGermanName = function(value) {
  return jspb.Message.setProto3StringField(this, 561, value);
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.web_odds.InitialMarketMap.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * map<string, InitialOutcome> outcomes = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.web_odds.InitialOutcome>}
 */
proto.web_odds.InitialMarketMap.prototype.getOutcomesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.web_odds.InitialOutcome>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.web_odds.InitialOutcome));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.web_odds.InitialMarketMap} returns this
 */
proto.web_odds.InitialMarketMap.prototype.clearOutcomesMap = function() {
  this.getOutcomesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventTime.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    m: jspb.Message.getFieldWithDefault(msg, 202, ""),
    s: jspb.Message.getFieldWithDefault(msg, 203, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventTime}
 */
proto.web_odds.EventTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventTime;
  return proto.web_odds.EventTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventTime}
 */
proto.web_odds.EventTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 202:
      var value = /** @type {string} */ (reader.readString());
      msg.setM(value);
      break;
    case 203:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getM();
  if (f.length > 0) {
    writer.writeString(
      202,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      203,
      f
    );
  }
};


/**
 * optional string M = 202;
 * @return {string}
 */
proto.web_odds.EventTime.prototype.getM = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 202, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventTime} returns this
 */
proto.web_odds.EventTime.prototype.setM = function(value) {
  return jspb.Message.setProto3StringField(this, 202, value);
};


/**
 * optional string S = 203;
 * @return {string}
 */
proto.web_odds.EventTime.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 203, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventTime} returns this
 */
proto.web_odds.EventTime.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 203, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventCurrentPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventCurrentPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventCurrentPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventCurrentPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    n: jspb.Message.getFieldWithDefault(msg, 200, ""),
    s: jspb.Message.getFieldWithDefault(msg, 201, ""),
    t: (f = msg.getT()) && proto.web_odds.EventTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventCurrentPhase}
 */
proto.web_odds.EventCurrentPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventCurrentPhase;
  return proto.web_odds.EventCurrentPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventCurrentPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventCurrentPhase}
 */
proto.web_odds.EventCurrentPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 200:
      var value = /** @type {string} */ (reader.readString());
      msg.setN(value);
      break;
    case 201:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    case 204:
      var value = new proto.web_odds.EventTime;
      reader.readMessage(value,proto.web_odds.EventTime.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventCurrentPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventCurrentPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventCurrentPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventCurrentPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getN();
  if (f.length > 0) {
    writer.writeString(
      200,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      201,
      f
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      204,
      f,
      proto.web_odds.EventTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string N = 200;
 * @return {string}
 */
proto.web_odds.EventCurrentPhase.prototype.getN = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 200, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentPhase} returns this
 */
proto.web_odds.EventCurrentPhase.prototype.setN = function(value) {
  return jspb.Message.setProto3StringField(this, 200, value);
};


/**
 * optional string S = 201;
 * @return {string}
 */
proto.web_odds.EventCurrentPhase.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 201, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentPhase} returns this
 */
proto.web_odds.EventCurrentPhase.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 201, value);
};


/**
 * optional EventTime T = 204;
 * @return {?proto.web_odds.EventTime}
 */
proto.web_odds.EventCurrentPhase.prototype.getT = function() {
  return /** @type{?proto.web_odds.EventTime} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventTime, 204));
};


/**
 * @param {?proto.web_odds.EventTime|undefined} value
 * @return {!proto.web_odds.EventCurrentPhase} returns this
*/
proto.web_odds.EventCurrentPhase.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 204, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventCurrentPhase} returns this
 */
proto.web_odds.EventCurrentPhase.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventCurrentPhase.prototype.hasT = function() {
  return jspb.Message.getField(this, 204) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventCurrentResult.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventCurrentResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventCurrentResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventCurrentResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    g: jspb.Message.getFieldWithDefault(msg, 205, ""),
    c: jspb.Message.getFieldWithDefault(msg, 206, ""),
    rc: jspb.Message.getFieldWithDefault(msg, 207, ""),
    yc: jspb.Message.getFieldWithDefault(msg, 208, ""),
    p: jspb.Message.getFieldWithDefault(msg, 209, ""),
    s: jspb.Message.getFieldWithDefault(msg, 210, ""),
    l: jspb.Message.getFieldWithDefault(msg, 267, ""),
    s180: jspb.Message.getFieldWithDefault(msg, 268, ""),
    fg: jspb.Message.getFieldWithDefault(msg, 269, ""),
    pn: jspb.Message.getFieldWithDefault(msg, 270, ""),
    td: jspb.Message.getFieldWithDefault(msg, 271, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventCurrentResult}
 */
proto.web_odds.EventCurrentResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventCurrentResult;
  return proto.web_odds.EventCurrentResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventCurrentResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventCurrentResult}
 */
proto.web_odds.EventCurrentResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 205:
      var value = /** @type {string} */ (reader.readString());
      msg.setG(value);
      break;
    case 206:
      var value = /** @type {string} */ (reader.readString());
      msg.setC(value);
      break;
    case 207:
      var value = /** @type {string} */ (reader.readString());
      msg.setRc(value);
      break;
    case 208:
      var value = /** @type {string} */ (reader.readString());
      msg.setYc(value);
      break;
    case 209:
      var value = /** @type {string} */ (reader.readString());
      msg.setP(value);
      break;
    case 210:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    case 267:
      var value = /** @type {string} */ (reader.readString());
      msg.setL(value);
      break;
    case 268:
      var value = /** @type {string} */ (reader.readString());
      msg.setS180(value);
      break;
    case 269:
      var value = /** @type {string} */ (reader.readString());
      msg.setFg(value);
      break;
    case 270:
      var value = /** @type {string} */ (reader.readString());
      msg.setPn(value);
      break;
    case 271:
      var value = /** @type {string} */ (reader.readString());
      msg.setTd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventCurrentResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventCurrentResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventCurrentResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventCurrentResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getG();
  if (f.length > 0) {
    writer.writeString(
      205,
      f
    );
  }
  f = message.getC();
  if (f.length > 0) {
    writer.writeString(
      206,
      f
    );
  }
  f = message.getRc();
  if (f.length > 0) {
    writer.writeString(
      207,
      f
    );
  }
  f = message.getYc();
  if (f.length > 0) {
    writer.writeString(
      208,
      f
    );
  }
  f = message.getP();
  if (f.length > 0) {
    writer.writeString(
      209,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      210,
      f
    );
  }
  f = message.getL();
  if (f.length > 0) {
    writer.writeString(
      267,
      f
    );
  }
  f = message.getS180();
  if (f.length > 0) {
    writer.writeString(
      268,
      f
    );
  }
  f = message.getFg();
  if (f.length > 0) {
    writer.writeString(
      269,
      f
    );
  }
  f = message.getPn();
  if (f.length > 0) {
    writer.writeString(
      270,
      f
    );
  }
  f = message.getTd();
  if (f.length > 0) {
    writer.writeString(
      271,
      f
    );
  }
};


/**
 * optional string G = 205;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getG = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 205, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setG = function(value) {
  return jspb.Message.setProto3StringField(this, 205, value);
};


/**
 * optional string C = 206;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getC = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 206, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setC = function(value) {
  return jspb.Message.setProto3StringField(this, 206, value);
};


/**
 * optional string RC = 207;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getRc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 207, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setRc = function(value) {
  return jspb.Message.setProto3StringField(this, 207, value);
};


/**
 * optional string YC = 208;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getYc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 208, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setYc = function(value) {
  return jspb.Message.setProto3StringField(this, 208, value);
};


/**
 * optional string P = 209;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getP = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 209, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setP = function(value) {
  return jspb.Message.setProto3StringField(this, 209, value);
};


/**
 * optional string S = 210;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 210, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 210, value);
};


/**
 * optional string L = 267;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getL = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 267, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setL = function(value) {
  return jspb.Message.setProto3StringField(this, 267, value);
};


/**
 * optional string S180 = 268;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getS180 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 268, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setS180 = function(value) {
  return jspb.Message.setProto3StringField(this, 268, value);
};


/**
 * optional string FG = 269;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getFg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 269, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setFg = function(value) {
  return jspb.Message.setProto3StringField(this, 269, value);
};


/**
 * optional string PN = 270;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getPn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 270, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setPn = function(value) {
  return jspb.Message.setProto3StringField(this, 270, value);
};


/**
 * optional string TD = 271;
 * @return {string}
 */
proto.web_odds.EventCurrentResult.prototype.getTd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 271, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventCurrentResult} returns this
 */
proto.web_odds.EventCurrentResult.prototype.setTd = function(value) {
  return jspb.Message.setProto3StringField(this, 271, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventLiveResultHistoryElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventLiveResultHistoryElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventLiveResultHistoryElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    p: (f = msg.getP()) && proto.web_odds.EventCurrentPhase.toObject(includeInstance, f),
    r: (f = msg.getR()) && proto.web_odds.EventCurrentResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventLiveResultHistoryElement}
 */
proto.web_odds.EventLiveResultHistoryElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventLiveResultHistoryElement;
  return proto.web_odds.EventLiveResultHistoryElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventLiveResultHistoryElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventLiveResultHistoryElement}
 */
proto.web_odds.EventLiveResultHistoryElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 211:
      var value = new proto.web_odds.EventCurrentPhase;
      reader.readMessage(value,proto.web_odds.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setP(value);
      break;
    case 212:
      var value = new proto.web_odds.EventCurrentResult;
      reader.readMessage(value,proto.web_odds.EventCurrentResult.deserializeBinaryFromReader);
      msg.setR(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventLiveResultHistoryElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventLiveResultHistoryElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventLiveResultHistoryElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getP();
  if (f != null) {
    writer.writeMessage(
      211,
      f,
      proto.web_odds.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getR();
  if (f != null) {
    writer.writeMessage(
      212,
      f,
      proto.web_odds.EventCurrentResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCurrentPhase P = 211;
 * @return {?proto.web_odds.EventCurrentPhase}
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.getP = function() {
  return /** @type{?proto.web_odds.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventCurrentPhase, 211));
};


/**
 * @param {?proto.web_odds.EventCurrentPhase|undefined} value
 * @return {!proto.web_odds.EventLiveResultHistoryElement} returns this
*/
proto.web_odds.EventLiveResultHistoryElement.prototype.setP = function(value) {
  return jspb.Message.setWrapperField(this, 211, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventLiveResultHistoryElement} returns this
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.clearP = function() {
  return this.setP(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.hasP = function() {
  return jspb.Message.getField(this, 211) != null;
};


/**
 * optional EventCurrentResult R = 212;
 * @return {?proto.web_odds.EventCurrentResult}
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.getR = function() {
  return /** @type{?proto.web_odds.EventCurrentResult} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventCurrentResult, 212));
};


/**
 * @param {?proto.web_odds.EventCurrentResult|undefined} value
 * @return {!proto.web_odds.EventLiveResultHistoryElement} returns this
*/
proto.web_odds.EventLiveResultHistoryElement.prototype.setR = function(value) {
  return jspb.Message.setWrapperField(this, 212, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventLiveResultHistoryElement} returns this
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.clearR = function() {
  return this.setR(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventLiveResultHistoryElement.prototype.hasR = function() {
  return jspb.Message.getField(this, 212) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.Error.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    grpcCode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    httpCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    messageList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.Error}
 */
proto.web_odds.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.Error;
  return proto.web_odds.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.Error}
 */
proto.web_odds.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGrpcCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHttpCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGrpcCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHttpCode();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMessageList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional int32 grpc_code = 5;
 * @return {number}
 */
proto.web_odds.Error.prototype.getGrpcCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.Error} returns this
 */
proto.web_odds.Error.prototype.setGrpcCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 http_code = 6;
 * @return {number}
 */
proto.web_odds.Error.prototype.getHttpCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.Error} returns this
 */
proto.web_odds.Error.prototype.setHttpCode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string message = 7;
 * @return {!Array<string>}
 */
proto.web_odds.Error.prototype.getMessageList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.web_odds.Error} returns this
 */
proto.web_odds.Error.prototype.setMessageList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.web_odds.Error} returns this
 */
proto.web_odds.Error.prototype.addMessage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.Error} returns this
 */
proto.web_odds.Error.prototype.clearMessageList = function() {
  return this.setMessageList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.EventResult.repeatedFields_ = [215];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventResult.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPhase: (f = msg.getCurrentPhase()) && proto.web_odds.EventCurrentPhase.toObject(includeInstance, f),
    currentResult: (f = msg.getCurrentResult()) && proto.web_odds.EventCurrentResult.toObject(includeInstance, f),
    liveResultHistoryList: jspb.Message.toObjectList(msg.getLiveResultHistoryList(),
    proto.web_odds.EventLiveResultHistoryElement.toObject, includeInstance),
    liveResultStats: jspb.Message.getFieldWithDefault(msg, 216, ""),
    prematchResult: jspb.Message.getFieldWithDefault(msg, 217, ""),
    liveResult: jspb.Message.getFieldWithDefault(msg, 218, ""),
    confirmed: jspb.Message.getBooleanFieldWithDefault(msg, 219, false),
    error: (f = msg.getError()) && proto.web_odds.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventResult}
 */
proto.web_odds.EventResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventResult;
  return proto.web_odds.EventResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventResult}
 */
proto.web_odds.EventResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 213:
      var value = new proto.web_odds.EventCurrentPhase;
      reader.readMessage(value,proto.web_odds.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 214:
      var value = new proto.web_odds.EventCurrentResult;
      reader.readMessage(value,proto.web_odds.EventCurrentResult.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 215:
      var value = new proto.web_odds.EventLiveResultHistoryElement;
      reader.readMessage(value,proto.web_odds.EventLiveResultHistoryElement.deserializeBinaryFromReader);
      msg.addLiveResultHistory(value);
      break;
    case 216:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveResultStats(value);
      break;
    case 217:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchResult(value);
      break;
    case 218:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveResult(value);
      break;
    case 219:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    case 78:
      var value = new proto.web_odds.Error;
      reader.readMessage(value,proto.web_odds.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      213,
      f,
      proto.web_odds.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      214,
      f,
      proto.web_odds.EventCurrentResult.serializeBinaryToWriter
    );
  }
  f = message.getLiveResultHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      215,
      f,
      proto.web_odds.EventLiveResultHistoryElement.serializeBinaryToWriter
    );
  }
  f = message.getLiveResultStats();
  if (f.length > 0) {
    writer.writeString(
      216,
      f
    );
  }
  f = message.getPrematchResult();
  if (f.length > 0) {
    writer.writeString(
      217,
      f
    );
  }
  f = message.getLiveResult();
  if (f.length > 0) {
    writer.writeString(
      218,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      219,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto.web_odds.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCurrentPhase current_phase = 213;
 * @return {?proto.web_odds.EventCurrentPhase}
 */
proto.web_odds.EventResult.prototype.getCurrentPhase = function() {
  return /** @type{?proto.web_odds.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventCurrentPhase, 213));
};


/**
 * @param {?proto.web_odds.EventCurrentPhase|undefined} value
 * @return {!proto.web_odds.EventResult} returns this
*/
proto.web_odds.EventResult.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 213, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventResult.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 213) != null;
};


/**
 * optional EventCurrentResult current_result = 214;
 * @return {?proto.web_odds.EventCurrentResult}
 */
proto.web_odds.EventResult.prototype.getCurrentResult = function() {
  return /** @type{?proto.web_odds.EventCurrentResult} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventCurrentResult, 214));
};


/**
 * @param {?proto.web_odds.EventCurrentResult|undefined} value
 * @return {!proto.web_odds.EventResult} returns this
*/
proto.web_odds.EventResult.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 214, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventResult.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 214) != null;
};


/**
 * repeated EventLiveResultHistoryElement live_result_history = 215;
 * @return {!Array<!proto.web_odds.EventLiveResultHistoryElement>}
 */
proto.web_odds.EventResult.prototype.getLiveResultHistoryList = function() {
  return /** @type{!Array<!proto.web_odds.EventLiveResultHistoryElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.EventLiveResultHistoryElement, 215));
};


/**
 * @param {!Array<!proto.web_odds.EventLiveResultHistoryElement>} value
 * @return {!proto.web_odds.EventResult} returns this
*/
proto.web_odds.EventResult.prototype.setLiveResultHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 215, value);
};


/**
 * @param {!proto.web_odds.EventLiveResultHistoryElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.EventLiveResultHistoryElement}
 */
proto.web_odds.EventResult.prototype.addLiveResultHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 215, opt_value, proto.web_odds.EventLiveResultHistoryElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.clearLiveResultHistoryList = function() {
  return this.setLiveResultHistoryList([]);
};


/**
 * optional string live_result_stats = 216;
 * @return {string}
 */
proto.web_odds.EventResult.prototype.getLiveResultStats = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 216, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.setLiveResultStats = function(value) {
  return jspb.Message.setProto3StringField(this, 216, value);
};


/**
 * optional string prematch_result = 217;
 * @return {string}
 */
proto.web_odds.EventResult.prototype.getPrematchResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 217, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.setPrematchResult = function(value) {
  return jspb.Message.setProto3StringField(this, 217, value);
};


/**
 * optional string live_result = 218;
 * @return {string}
 */
proto.web_odds.EventResult.prototype.getLiveResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 218, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.setLiveResult = function(value) {
  return jspb.Message.setProto3StringField(this, 218, value);
};


/**
 * optional bool confirmed = 219;
 * @return {boolean}
 */
proto.web_odds.EventResult.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 219, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.setConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 219, value);
};


/**
 * optional Error error = 78;
 * @return {?proto.web_odds.Error}
 */
proto.web_odds.EventResult.prototype.getError = function() {
  return /** @type{?proto.web_odds.Error} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.Error, 78));
};


/**
 * @param {?proto.web_odds.Error|undefined} value
 * @return {!proto.web_odds.EventResult} returns this
*/
proto.web_odds.EventResult.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventResult} returns this
 */
proto.web_odds.EventResult.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventResult.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.WebStreamEvent.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.web_odds.WebStreamEventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    result: (f = msg.getResult()) && proto.web_odds.EventResult.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 13, 0),
    numberOfOdds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    profile: jspb.Message.getFieldWithDefault(msg, 16, ""),
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    betradarStreamId: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.WebStreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEvent;
  return proto.web_odds.WebStreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.WebStreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = new proto.web_odds.WebStreamEventOdd;
      reader.readMessage(value,proto.web_odds.WebStreamEventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = new proto.web_odds.EventResult;
      reader.readMessage(value,proto.web_odds.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOdds(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.web_odds.WebStreamEventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.web_odds.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getNumberOfOdds();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBetradarStreamId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 location_id = 4;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated WebStreamEventOdd odds = 6;
 * @return {!Array<!proto.web_odds.WebStreamEventOdd>}
 */
proto.web_odds.WebStreamEvent.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEventOdd, 6));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEventOdd>} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
*/
proto.web_odds.WebStreamEvent.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.web_odds.WebStreamEventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEventOdd}
 */
proto.web_odds.WebStreamEvent.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.web_odds.WebStreamEventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web_odds.WebStreamEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
*/
proto.web_odds.WebStreamEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.WebStreamEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_disabled = 8;
 * @return {boolean}
 */
proto.web_odds.WebStreamEvent.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional EventResult result = 9;
 * @return {?proto.web_odds.EventResult}
 */
proto.web_odds.WebStreamEvent.prototype.getResult = function() {
  return /** @type{?proto.web_odds.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventResult, 9));
};


/**
 * @param {?proto.web_odds.EventResult|undefined} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
*/
proto.web_odds.WebStreamEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.WebStreamEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string landbase_code = 10;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outright_type = 12;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 mon = 13;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 number_of_odds = 14;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getNumberOfOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setNumberOfOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 exefeed_id = 15;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string profile = 16;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string betradar_stream_id = 18;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getBetradarStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setBetradarStreamId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEventOdd}
 */
proto.web_odds.WebStreamEventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEventOdd;
  return proto.web_odds.WebStreamEventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEventOdd}
 */
proto.web_odds.WebStreamEventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double limit = 4;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 frame_number = 5;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string current_result = 6;
 * @return {string}
 */
proto.web_odds.WebStreamEventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_disabled = 7;
 * @return {boolean}
 */
proto.web_odds.WebStreamEventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.web_odds.WebStreamEventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventRequest}
 */
proto.web_odds.EventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventRequest;
  return proto.web_odds.EventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventRequest}
 */
proto.web_odds.EventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.web_odds.EventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventRequest} returns this
 */
proto.web_odds.EventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.StreamEventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.StreamEventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.StreamEventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.StreamEventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 122, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 238, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 239, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.StreamEventOdd}
 */
proto.web_odds.StreamEventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.StreamEventOdd;
  return proto.web_odds.StreamEventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.StreamEventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.StreamEventOdd}
 */
proto.web_odds.StreamEventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 122:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 238:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 239:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.StreamEventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.StreamEventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.StreamEventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.StreamEventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      122,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      238,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      239,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.StreamEventOdd.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 8;
 * @return {number}
 */
proto.web_odds.StreamEventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 outcome_id = 11;
 * @return {number}
 */
proto.web_odds.StreamEventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double limit = 122;
 * @return {number}
 */
proto.web_odds.StreamEventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 122, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 122, value);
};


/**
 * optional int32 frame_number = 238;
 * @return {number}
 */
proto.web_odds.StreamEventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 238, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 238, value);
};


/**
 * optional string current_result = 239;
 * @return {string}
 */
proto.web_odds.StreamEventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 239, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 239, value);
};


/**
 * optional bool is_disabled = 136;
 * @return {boolean}
 */
proto.web_odds.StreamEventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.web_odds.StreamEventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEventOdd} returns this
 */
proto.web_odds.StreamEventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.StreamEvent.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.StreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.StreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.StreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.StreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.web_odds.StreamEventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isSuspicious: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isSingleApproval: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    profile: jspb.Message.getFieldWithDefault(msg, 12, ""),
    currentPhase: (f = msg.getCurrentPhase()) && proto.web_odds.EventCurrentPhase.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    type: jspb.Message.getFieldWithDefault(msg, 15, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 17, 0),
    result: (f = msg.getResult()) && proto.web_odds.EventResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.StreamEvent}
 */
proto.web_odds.StreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.StreamEvent;
  return proto.web_odds.StreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.StreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.StreamEvent}
 */
proto.web_odds.StreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 7:
      var value = new proto.web_odds.StreamEventOdd;
      reader.readMessage(value,proto.web_odds.StreamEventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspicious(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleApproval(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 13:
      var value = new proto.web_odds.EventCurrentPhase;
      reader.readMessage(value,proto.web_odds.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 18:
      var value = new proto.web_odds.EventResult;
      reader.readMessage(value,proto.web_odds.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.StreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.StreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.StreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.StreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.web_odds.StreamEventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsSuspicious();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsSingleApproval();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.web_odds.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.web_odds.EventResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.web_odds.StreamEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 5;
 * @return {number}
 */
proto.web_odds.StreamEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sport_id = 6;
 * @return {number}
 */
proto.web_odds.StreamEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated StreamEventOdd odds = 7;
 * @return {!Array<!proto.web_odds.StreamEventOdd>}
 */
proto.web_odds.StreamEvent.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.web_odds.StreamEventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.StreamEventOdd, 7));
};


/**
 * @param {!Array<!proto.web_odds.StreamEventOdd>} value
 * @return {!proto.web_odds.StreamEvent} returns this
*/
proto.web_odds.StreamEvent.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.web_odds.StreamEventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.StreamEventOdd}
 */
proto.web_odds.StreamEvent.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.web_odds.StreamEventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web_odds.StreamEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web_odds.StreamEvent} returns this
*/
proto.web_odds.StreamEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.StreamEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_disabled = 9;
 * @return {boolean}
 */
proto.web_odds.StreamEvent.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_suspicious = 10;
 * @return {boolean}
 */
proto.web_odds.StreamEvent.prototype.getIsSuspicious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setIsSuspicious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_single_approval = 11;
 * @return {boolean}
 */
proto.web_odds.StreamEvent.prototype.getIsSingleApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setIsSingleApproval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string profile = 12;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional EventCurrentPhase current_phase = 13;
 * @return {?proto.web_odds.EventCurrentPhase}
 */
proto.web_odds.StreamEvent.prototype.getCurrentPhase = function() {
  return /** @type{?proto.web_odds.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventCurrentPhase, 13));
};


/**
 * @param {?proto.web_odds.EventCurrentPhase|undefined} value
 * @return {!proto.web_odds.StreamEvent} returns this
*/
proto.web_odds.StreamEvent.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.StreamEvent.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string landbase_code = 14;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string type = 15;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string outright_type = 16;
 * @return {string}
 */
proto.web_odds.StreamEvent.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 mon = 17;
 * @return {number}
 */
proto.web_odds.StreamEvent.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional EventResult result = 18;
 * @return {?proto.web_odds.EventResult}
 */
proto.web_odds.StreamEvent.prototype.getResult = function() {
  return /** @type{?proto.web_odds.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.EventResult, 18));
};


/**
 * @param {?proto.web_odds.EventResult|undefined} value
 * @return {!proto.web_odds.StreamEvent} returns this
*/
proto.web_odds.StreamEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.StreamEvent} returns this
 */
proto.web_odds.StreamEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.StreamEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 18) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.LiveDisabled.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.LiveDisabled.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.LiveDisabled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.LiveDisabled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.LiveDisabled.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    sportIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.LiveDisabled}
 */
proto.web_odds.LiveDisabled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.LiveDisabled;
  return proto.web_odds.LiveDisabled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.LiveDisabled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.LiveDisabled}
 */
proto.web_odds.LiveDisabled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAll(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSportIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.LiveDisabled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.LiveDisabled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.LiveDisabled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.LiveDisabled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSportIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional bool all = 1;
 * @return {boolean}
 */
proto.web_odds.LiveDisabled.prototype.getAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.setAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated int32 sport_ids = 2;
 * @return {!Array<number>}
 */
proto.web_odds.LiveDisabled.prototype.getSportIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.setSportIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.addSportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.clearSportIdsList = function() {
  return this.setSportIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.PlayerOutrightMarkets.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.PlayerOutrightMarkets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.PlayerOutrightMarkets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.PlayerOutrightMarkets.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outrightMarketsList: jspb.Message.toObjectList(msg.getOutrightMarketsList(),
    proto.web_odds.InitialMarket.toObject, includeInstance),
    playerMarketsList: jspb.Message.toObjectList(msg.getPlayerMarketsList(),
    proto.web_odds.InitialMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.PlayerOutrightMarkets}
 */
proto.web_odds.PlayerOutrightMarkets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.PlayerOutrightMarkets;
  return proto.web_odds.PlayerOutrightMarkets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.PlayerOutrightMarkets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.PlayerOutrightMarkets}
 */
proto.web_odds.PlayerOutrightMarkets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = new proto.web_odds.InitialMarket;
      reader.readMessage(value,proto.web_odds.InitialMarket.deserializeBinaryFromReader);
      msg.addOutrightMarkets(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialMarket;
      reader.readMessage(value,proto.web_odds.InitialMarket.deserializeBinaryFromReader);
      msg.addPlayerMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.PlayerOutrightMarkets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.PlayerOutrightMarkets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.PlayerOutrightMarkets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutrightMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.web_odds.InitialMarket.serializeBinaryToWriter
    );
  }
  f = message.getPlayerMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialMarket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 sport_id = 1;
 * @return {number}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
 */
proto.web_odds.PlayerOutrightMarkets.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated InitialMarket outright_markets = 2;
 * @return {!Array<!proto.web_odds.InitialMarket>}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.getOutrightMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarket, 2));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarket>} value
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
*/
proto.web_odds.PlayerOutrightMarkets.prototype.setOutrightMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web_odds.InitialMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.addOutrightMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web_odds.InitialMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
 */
proto.web_odds.PlayerOutrightMarkets.prototype.clearOutrightMarketsList = function() {
  return this.setOutrightMarketsList([]);
};


/**
 * repeated InitialMarket player_markets = 3;
 * @return {!Array<!proto.web_odds.InitialMarket>}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.getPlayerMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarket, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarket>} value
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
*/
proto.web_odds.PlayerOutrightMarkets.prototype.setPlayerMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.addPlayerMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
 */
proto.web_odds.PlayerOutrightMarkets.prototype.clearPlayerMarketsList = function() {
  return this.setPlayerMarketsList([]);
};


goog.object.extend(exports, proto.web_odds);
