/* React modules */

/* Our modules */
import { MarketGroup, Market } from 'modules/sports/sports.types';
import SportMarketBase from 'modules/sports/market-base-model';

/* 3rd Party modules */

export class SportMarketGroup extends SportMarketBase {
  public marketsList: Market[];

  constructor(data: MarketGroup) {
    super(data);

    this.marketsList = data.marketsList;
  }
}

export class SportMarket extends SportMarketBase {
  public outcomes: any[];

  constructor(data: Market) {
    super(data);

    this.outcomes = data.outcomesList.map((outcome: any) => ({
      ...outcome,
      displayName: outcome.exeLimit
        ? outcome.name.includes('+')
          ? '>'
          : '<'
        : outcome.name,
    }));
  }
}

class SportMarkets {
  private sportId: number;
  private marketGroups: SportMarketGroup[];

  public groups: SportMarketGroup[] = [];
  public marketsList: SportMarket[] = [];
  public initialMarkets: any;

  constructor(
    sport: any,
    marketGroups: MarketGroup[],
    outrightPlayersMarkets: any
  ) {
    const { outrightMarketsList, playerMarketsList } = outrightPlayersMarkets;

    this.sportId = sport.id;
    this.marketGroups = marketGroups.map(
      (group: any) => new SportMarketGroup(group)
    );
    this.groups = this.getSportGroups();

    this.marketsList = [
      ...this.getSportMarkets(),
      ...outrightMarketsList.map((m: any) => new SportMarket(m)),
      ...playerMarketsList.map((m: any) => new SportMarket(m)),
    ];

    this.initialMarkets = {
      live: this.getInitialMarkets(sport.basicOfferLive),
      upcoming: this.getInitialMarkets(sport.basicOfferPrematch),
      antepost: this.formatMarkets(outrightMarketsList),
      player: this.formatMarkets(playerMarketsList),
      special: this.formatMarkets(playerMarketsList),
    };
  }

  formatMarkets(markets: any): any[] {
    return markets.map((m: any) => new SportMarket(m));
  }

  getInitialMarkets(configuration: any): any[] {
    const { marketsList } = configuration;

    return marketsList
      ?.map(({ marketId, outcomeIdsList }: any) => {
        const market = this.marketsList.find((m: any) => m.id === marketId);

        if (market) {
          return Object.assign(
            Object.create(Object.getPrototypeOf(market)),
            market,
            {
              outcomes: outcomeIdsList.map((outcomeId: any) => {
                return market.outcomes.find(
                  (outcome) => outcome.id === outcomeId
                );
              }),
            }
          );
        }

        return null;
      })
      .filter((m: any) => !!m);
  }

  getSportGroups(): SportMarketGroup[] {
    return this.marketGroups.filter((group: any) => {
      return group.marketsList.some((m: any) => m.sportId === this.sportId);
    });
  }

  getSportMarkets(): SportMarket[] {
    return this.groups.reduce(
      (markets: SportMarket[], group: SportMarketGroup) => {
        return [
          ...markets,
          ...group.marketsList.map((m: Market) => new SportMarket(m)),
        ];
      },
      []
    );
  }
}

export default SportMarkets;
