/* React modules */
import { useState, useEffect, useCallback } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import SingleGame from 'modules/gambling/ui/SingleGame/SingleGame';
import GamesSections from 'modules/gambling/ui/GamblingHomepage/GamesSections';
import SingleBanner from 'modules/gambling/ui/SingleBanner';
import GamesListHeader from 'modules/gambling/ui/GamesListHeader';
import Footer from 'components/Footer';
import Leaderboard from 'modules/gambling/ui/GamblingHomepage/Leaderboard/Leaderboard';
import { httpBanner } from 'app/axios-config';
import useWindowSize from 'libs/viewport';
import pokerGameImg from 'images/poker-game.png';
import './GamesGrid.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';

interface GamesGridSectionProps {
  gamesList: Array<any>;
  screenWidth: number;
  hasMore: boolean;
}

const singleGameSize = (screenWidth: number) => {
  switch (true) {
    case screenWidth < 439:
      return 'extra-small';
    case screenWidth < 668:
      return 'small';
    case screenWidth < 1200:
      return 'medium';
    default:
      return 'large';
  }
};

const GamesGridSection = ({
  gamesList,
  screenWidth,
  hasMore,
}: GamesGridSectionProps) => {
  return (
    <>
      <div className="games-grid__grid-container">
        {gamesList.map((game, index) => (
          <SingleGame
            {...game}
            size={singleGameSize(screenWidth)}
            key={`GamesGridSection-SingleGame-${index}`}
          />
        ))}
      </div>

      {!hasMore && <Footer />}
    </>
  );
};

const GamesGrid = observer(
  ({
    isGamesOnly,
    isLive,
    isMobile,
  }: {
    isGamesOnly?: boolean;
    isLive?: boolean;
    isMobile?: boolean;
  }) => {
    const viewport = useWindowSize();
    const { gamblingStore, sportsStore } = useStores();
    const { games, loadMore, getTitle, filters, hasMore, getSectionId } =
      gamblingStore;
    const [gameThumbnails, setGameThumbnails] = useState<Array<any>>([]);
    const [gridHeight, setGridHeight] = useState<number>(viewport.height);
    const { leaderBoards } = gamblingStore;

    const tag = leaderBoards?.find(
      (tag: any) =>
        tag?.web_tag === getSectionId || tag?.mobile_tag === getSectionId
    );

    const [casinoBanner, setCasinoBanner] = useState<any>(null);
    const [casinoLiveBanner, setCasinoLiveBanner] = useState<any>(null);

    const scrollableGamesGridRef = useInfiniteScroll<HTMLDivElement>({
      next: loadMore,
      hasMore: { down: true },
    });

    const calcGridHeight = useCallback(
      (screenWidth: number) => {
        switch (true) {
          case screenWidth > 1200:
            setGridHeight(viewport.height - 120);
            break;
          case screenWidth > 992:
            setGridHeight(viewport.height - 55);
            break;
          case screenWidth >= 768:
            setGridHeight(viewport.height - 55);
            break;
          case screenWidth < 768:
            setGridHeight(
              !isMobile ? viewport.height - 110 : viewport.height - 70
            );
            break;
        }
      },
      [setGridHeight, viewport.height]
    );

    const fetchBanners = async () => {
      const { data } = await httpBanner.get(`web/data.json`);
      if (data) {
        setCasinoBanner(data.casino_main[0]);
        setCasinoLiveBanner(data.casino_live_main[0]);
      }
    };

    useEffect(() => {
      fetchBanners();
    }, []);

    useEffect(() => {
      calcGridHeight(viewport.width);
    }, [viewport.width, calcGridHeight]);

    useEffect(() => {
      setGameThumbnails(
        isLive && sportsStore.isPokerEnabled
          ? [
              [
                {
                  game_name: 'Poker',
                  image: pokerGameImg,
                  image_filled: pokerGameImg,
                },
                ...games,
              ],
            ]
          : [games]
      );
    }, [games, isLive, sportsStore.isPokerEnabled]);

    const resetFilters = () => {
      filters.clearFilters();
      gamblingStore.getGames();
      gamblingStore.setHasMore(true);
    };

    return (
      <div className="games-grid" style={{ height: gridHeight + 'px' }}>
        <div className="games-grid__content">
          <div ref={scrollableGamesGridRef} className="games-grid__scrollable">
            {isGamesOnly ? (
              <SingleBanner
                banner={isLive ? casinoLiveBanner : casinoBanner}
                className="games-grid__banner"
              />
            ) : (
              <GamesSections />
            )}

            <GamesListHeader
              hasNavigation={false}
              title={getTitle}
              isMobile={false}
              back={filters.hasActiveFilters ? resetFilters : null}
            />

            {tag && (
              <div className="px-3">
                <Leaderboard tag={tag} />
              </div>
            )}

            {gameThumbnails.map((list: any, index: number) => (
              <GamesGridSection
                key={`GamesGrid-GamesGridSection-${index}`}
                hasMore={hasMore}
                screenWidth={viewport.width}
                gamesList={list}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export { GamesGrid };
