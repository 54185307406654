/* React modules */
import { useState, ChangeEvent } from 'react';

/* Our modules */
import { PromoCodeActivationError } from 'modules/payment/payment.types';
import useStores from 'common/hooks/useStores';
import { paymentStore } from 'modules/payment/payment.store';
import { Input, Button } from 'components';
import SectionHeader from 'modules/user/ui/SectionHeader';
import { logger } from 'libs/common-helpers';
import './UserPromoCode.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const UserPromoCode = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const [code, setCode] = useState<string | undefined>();

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const activate = async () => {
    try {
      const { amount, amount_type, amount_fc } =
        await paymentStore.activatePromoCode(code || '');

      const currency = userStore.promoWallet?.currency;

      const walletType =
        amount_type === 'free_bet'
          ? t('wallet.free-bet')
          : amount_type === 'free_casino'
          ? t('wallet.free-spin')
          : '';

      if (amount_type === 'both') {
        toast.success(
          t('verification.promo-code-activated-multiple-wallets', {
            amount,
            amount_fc,
            currency,
          })
        );
      } else {
        toast.success(
          t('verification.promo-code-activated', {
            amount,
            currency,
            walletType,
          })
        );
      }

      userStore.getUserData();
    } catch (exception: any) {
      if (exception.detail === PromoCodeActivationError.INVALID_CODE) {
        toast.error(t('errors.promo-code-invalid'));
      } else if (
        exception.detail === PromoCodeActivationError.ALREADY_ACTIVATED
      ) {
        toast.error(t('verification.promo-code-already-activated'));
      } else if (exception.detail === PromoCodeActivationError.CODE_USED_UP) {
        toast.error(t('verification.promo-code-used-up'));
      } else if (
        exception.detail === PromoCodeActivationError.CODE_NOT_FOR_NEW_USERS
      ) {
        toast.error(t('verification.promo-code-not-for-new-users'));
      } else if (
        exception.detail ===
        PromoCodeActivationError.CODE_NOT_FOR_EXISTING_USERS
      ) {
        toast.error(t('verification.promo-code-not-for-existing-users'));
      } else {
        toast.error(t('errors.system-error'));
      }

      logger('UserPromoCode -> activate -> exception', exception);
    }
  };

  return (
    <>
      <SectionHeader title="PROMO CODE" />
      <div className="promo-code">
        <div className="promo-code__body">
          <div>
            <div className="mb-1">
              <label>{t('globals.sbbet-promo-code')}</label>
            </div>
            <Input
              bg="light"
              className="mb-4"
              name="amount"
              value={code}
              onChange={onCodeChange}
            />
          </div>
          <div className="d-flex justify-center">
            <Button
              className="w-20"
              bg="success"
              size="large"
              onClick={activate}
              disabled={!code}
            >
              {t('globals.send-1')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});

export default UserPromoCode;
