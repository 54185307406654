enum TicketStatus {
  MANUAL_CHANGED = '__MANUAL_APPROVAL_CHANGED__',
  APPROVING = '__APPROVING__',
  NOT_RESOLVED = '__NOT_RESOLVED__',
  DENIED = '__DENIED__',
  PASSED = '__PASSED__',
  FAILED = '__FAILED__',
  PAYED_OUT = '__PAYED_OUT__',
  UNCONFIRMED = 'unconfirmed',
  USER_BLOCKED = 'ERR_BETTING_BLOCKED_FOR_USER',
}

type TicketStatuses =
  | TicketStatus.MANUAL_CHANGED
  | TicketStatus.APPROVING
  | TicketStatus.NOT_RESOLVED
  | TicketStatus.PASSED
  | TicketStatus.FAILED
  | TicketStatus.DENIED
  | TicketStatus.PAYED_OUT
  | TicketStatus.UNCONFIRMED
  | TicketStatus.USER_BLOCKED;

enum TicketError {
  INVALID_SYSTEM_SLIP = 'ERR_INVALID_SYSTEM_SLIP',
}

type TicketErrors = TicketError.INVALID_SYSTEM_SLIP;

type TicketStatusGroup =
  | TicketStatusGroups.ALL
  | TicketStatusGroups.ACTIVE
  | TicketStatusGroups.APPROVING
  | TicketStatusGroups.PASSED
  | TicketStatusGroups.FAILED;

enum TicketStatusGroups {
  ALL = 'all',
  ACTIVE = 'active',
  APPROVING = 'approving',
  PASSED = 'passed',
  FAILED = 'failed',
}

enum TicketEventStatus {
  PASSED = '__PASSED__',
  FAILED = '__FAILED__',
}

interface TicketData {
  id: string;
  shortUuid: string;
  date: string;
  created_at: string;
  time: string;
  accountType: string;
  bettingAmount: number;
  bettingTime: any;
  amount: number;
  status: TicketStatuses;
  account: string;
  maxWinningAmount: number;
  code?: string;
}

interface TicketEvent {
  competitors: string;
  odd_value: number;
  outcome: string;
  start_time: string;
  code: number;
  result: string;
}

interface TicketsStatus {
  win: TicketData[];
  loss: TicketData[];
  active: TicketData[];
}

interface TicketsResponse {
  amount: number;
  created_at: string;
  id: string;
}

interface SlipOdd {
  automated_status: any;
  manual_status: string | null;
  market_name: string | null;
  odd_id: string;
  odd_value: number;
  outcome_name: string | null;
  outcome_print_code: string | null;
}

interface SlipEvent {
  automated_status: any;
  competition_id: any;
  competition_name: string;
  event_code: any;
  event_id: string;
  event_name: string | null;
  event_note: string | null;
  event_type: string | null;
  location_id: any;
  manual_status: string | null;
  sport_id: any;
  sport_name: string;
  odds: SlipOdd[];
  event_start_time: string;
}

interface SlipGroup {
  automated_status: any;
  manual_status: any;
  num_of_combinations: number | null;
  system: string;
  events: SlipEvent[];
}

interface SingleTicket {
  id: string;
  actual_odds_value: number | null;
  actualWinningAmount: number | null;
  bettingAmount: number;
  approval_reason: string | null;
  approved_by: any;
  automated_status: any;
  betting_place_id: string | null;
  manual_status: string | null;
  maxOddsValue: number | null;
  maxWinningAmount: number | null;
  maxWinningTotal: number | null;
  minWinningAmount: number | null;
  minWinningTotal: number | null;
  maxBonusAmount: number | null;
  minBonusAmount: number | null;
  actualWinningNoBonus: number | null;
  minOddsValue: number | null;
  min_winning_amount: number | null;
  num_of_combinations: number | null;
  numberOfMatches: number;
  shortUuid: any;
  transaction_id: string;
  user: number;
  bettingTime: any;
  isSystem: boolean;
  slipGroupsList: SlipGroup[];
  status: TicketStatuses;
  oldBettingAmount: number;
  cashout: boolean;
  hasCashout: boolean;
  bonusPercent: number | null;
  slipType: SlipType;
}

interface SlipPayloadOdd {
  id: number;
  odd_value: number;
}

interface SlipPayloadEvent {
  event_id: string;
  odds: SlipPayloadOdd[];
}

interface SlipPayloadGroupEvent {
  event_id: string;
  odds: SlipPayloadOdd[];
}

interface SlipPayloadGroup {
  system: string;
  events: SlipPayloadGroupEvent[];
}

interface PlaceBetPayload {
  user: number | null;
  amount: number;
  approval_reason: string | null;
  slip_groups: SlipPayloadGroup[];
  wallet?: string;
  wallet_prefix?: string;
  shouldAcceptChanges?: boolean;
}

enum ApprovalStatuses {
  APPROVED = 'slip_approved',
  DENIED = 'slip_denied',
  CHANGED = 'slip_changed',
  CASHOUT_APPROVED = 'slip_cashout_approved',
  CASHOUT_DENIED = 'slip_cashout_denied',
}

type SlipType = 'PREMATCH' | 'LIVE' | 'MIX';

interface PreviewData {
  slipId?: string;
  status: TicketStatuses;
  isFix: boolean;
  systemGroups: any[];
  amount: number;
  oldAmount: number;
  created_at: string | null;
  bonus: number;
  minBonus: number | null;
  potentialWinningAmount: number | null;
  minWinningTotal: number | null;
  winningAmount: number | null;
  actualWinningNoBonus: number | null;
  actualWinningAmount: number | null;
  minWinningAmount: number | null;
  totalOdd: number | null;
  minOdd: number | null;
  eventsCount: number;
  shortUuid?: string;
  cashout: boolean;
  hasCashout: boolean;
  bonusPercent: number | null;
  slipType: SlipType;
  maxPotBonus?: number;
  maxOdd?: number;
}

interface TicketOptions {
  fromApi: boolean;
}

interface Error {
  key: string;
  message: any;
}

export {
  TicketStatus,
  TicketError,
  TicketStatusGroups,
  TicketEventStatus,
  ApprovalStatuses,
};

export type {
  TicketStatuses,
  TicketErrors,
  TicketStatusGroup,
  TicketData,
  TicketEvent,
  TicketsStatus,
  TicketsResponse,
  SlipOdd,
  SlipEvent,
  SlipGroup,
  SingleTicket,
  SlipPayloadOdd,
  SlipPayloadEvent,
  SlipPayloadGroupEvent,
  SlipPayloadGroup,
  PlaceBetPayload,
  PreviewData,
  TicketOptions,
  Error,
};
