import React from 'react';
import { Button } from 'components';

interface CashoutButtonProps {
  className?: string;
  cashoutAmount: number;
}

const CashoutButton = ({ className, cashoutAmount }: CashoutButtonProps) => (
  <Button
    className={`mr-6 cashout-btn ${className}`}
    size="small"
    bg="success"
    hasBorder
  >
    {
      <div className="cashout-btn__text w-90">
        <span>CASHOUT</span>
        <span>{`${cashoutAmount} EUR`}</span>
      </div>
    }
  </Button>
);

export { CashoutButton };
