/* React modules */
import { useEffect, useRef, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { MobileFilter, MobileFilterLabel } from 'components';
import {
  GameTypeFilterSelect,
  SystemFilterSelect,
} from 'modules/gambling/ui/GamblingSidebar/GamblingSidebar';
import { GamblingFilter } from 'modules/gambling/gambling.types';
import useOnScreen from 'libs/useOnScreen';
import './GamesFilterMobile.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface DropdownRecordsProps {
  filters: Array<GamblingFilter>;
}

interface GamesFilterHeaderProps {
  close: () => void;
}

const GamesFilterHeader = ({ close }: GamesFilterHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className="games-filter-mobile__close" onClick={close}>
      {t('globals.close')}
    </div>
  );
};

const GameTypeDropdownRecords = ({ filters }: DropdownRecordsProps) => {
  return (
    <div className="sports-filter-mobile__records">
      {filters.map((filter) => (
        <GameTypeFilterSelect
          key={`GameTypeDropdownRecords-GameTypeFilterSelect-${filter.value}`}
          filter={filter}
        />
      ))}
    </div>
  );
};

const SystemDropdownRecords = ({ filters }: DropdownRecordsProps) => {
  return (
    <div className="sports-filter-mobile__records">
      {filters.map((filter) => (
        <SystemFilterSelect
          key={`SystemDropdownRecords-SystemFilterSelect-${filter.value}`}
          filter={filter}
        />
      ))}
    </div>
  );
};

const GamesFilterMobile = observer(({ mobile }: { mobile: boolean }) => {
  const { t } = useTranslation();
  const [providersDrawer, setProvidersDrawer] = useState<boolean>(false);
  const [filtersDrawer, setFiltersDrawer] = useState<boolean>(false);

  const {
    gamblingStore: { providers, gameTypes, filters, getSectionGames },
  } = useStores();

  const mobileFiltersRef = useRef(null);
  const isVisible = useOnScreen(mobileFiltersRef);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    filters.setMobileFilter(isMobile);
    getSectionGames();
  }, []);

  useEffect(() => {
    filters.setMobileFilter(isMobile);
    getSectionGames();
  }, [isVisible]);

  const toggleProvidersDrawer = () => {
    setProvidersDrawer(!providersDrawer);
  };

  const toggleCategoriesDrawer = () => {
    setFiltersDrawer(!filtersDrawer);
  };

  return (
    // <div className="games-filter-mobile" ref={mobileFiltersRef}>
    <div
      className={!mobile ? 'games-filter-mobile' : 'games-filter-mobile-app'}
      ref={mobileFiltersRef}
    >
      <MobileFilter
        background="transparent"
        isDrawerOpen={filtersDrawer}
        prefix="category"
        buttonColor="green"
        drawerPlacement="bottom"
        onClick={toggleCategoriesDrawer}
        onClose={toggleCategoriesDrawer}
        dropdownContent={
          <div className="games-filter-mobile__wrapper">
            <GamesFilterHeader close={toggleCategoriesDrawer} />
            <GameTypeDropdownRecords filters={gameTypes} />
          </div>
        }
      >
        <MobileFilterLabel label={t('casino.category')} />
      </MobileFilter>
      <MobileFilter
        background="transparent"
        isDrawerOpen={providersDrawer}
        prefix="provider"
        buttonColor="green"
        drawerPlacement="bottom"
        onClick={toggleProvidersDrawer}
        onClose={toggleProvidersDrawer}
        dropdownContent={
          <div className="games-filter-mobile__wrapper">
            <GamesFilterHeader close={toggleProvidersDrawer} />
            <SystemDropdownRecords filters={providers} />
          </div>
        }
      >
        <MobileFilterLabel label={t('casino.provider')} />
      </MobileFilter>
    </div>
  );
});

export { GamesFilterMobile };
