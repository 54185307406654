/* React modules */
import { useState, useEffect, ChangeEvent, FormEvent } from 'react';

/* Our modules */
import {
  ResponsibleGamblingRequest,
  ResponsibleGamblingResponse,
  ResponsibleGamblingRequestKeys,
} from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import { Input, Button, DatePicker } from 'components';
import SectionHeader from 'modules/user/ui/SectionHeader';
import GamblingPause from 'modules/user/ui/UserSettings/ResponsibleGambling/GamblingPause/GamblingPause';
import { formatDate } from 'libs/datetime';
import { isNumber } from 'libs/common-helpers';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ResponsibleGambling = observer(() => {
  const { t } = useTranslation();

  const [pauseDate, setPauseDate] = useState<string | null>('');

  const [hasPauseExpired, setHasPauseExpired] = useState<boolean>(true);

  const [pauseGamblingUntil, setPauseGamblingUntil] = useState<string | null>(
    null
  );

  const [limitsActiveAfter, setLimitsActiveAfter] = useState<number>(0);

  const [spendings, setSpendings] = useState<
    Omit<
      ResponsibleGamblingResponse,
      'current_limits' | 'new_limits' | 'valid' | 'timeout_until'
    >
  >({
    daily_spent: {
      daily_deposit: 0,
      daily_net_bet: 0,
      daily_net_games: 0,
    },
    weekly_spent: {
      weekly_deposit: 0,
      weekly_net_bet: 0,
      weekly_net_games: 0,
    },
    monthly_spent: {
      monthly_deposit: 0,
      monthly_net_bet: 0,
      monthly_net_games: 0,
    },
  });

  const [currentLimits, setCurrentLimits] =
    useState<ResponsibleGamblingRequest>({
      daily_deposit: 0,
      daily_net_bet: 0,
      daily_net_games: 0,
      weekly_deposit: 0,
      weekly_net_bet: 0,
      weekly_net_games: 0,
      monthly_deposit: 0,
      monthly_net_bet: 0,
      monthly_net_games: 0,
    });

  const [newLimits, setNewLimits] = useState<ResponsibleGamblingRequest>({
    daily_deposit: 0,
    daily_net_bet: 0,
    daily_net_games: 0,
    weekly_deposit: 0,
    weekly_net_bet: 0,
    weekly_net_games: 0,
    monthly_deposit: 0,
    monthly_net_bet: 0,
    monthly_net_games: 0,
  });

  const {
    userStore: {
      user,
      getResponsibleGamblingLimits,
      setResponsibleGamblingLimits,
      setResponsibleGamblingPause,
    },
    overlayStore: { openModal },
  } = useStores();

  const isActiveNewLimit = (inputName: ResponsibleGamblingRequestKeys) => {
    return (
      currentLimits &&
      newLimits &&
      isNumber(currentLimits[inputName]) &&
      isNumber(newLimits[inputName]) &&
      currentLimits[inputName] !== newLimits[inputName]
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target) {
      const { name, value } = event.target;

      if (name && isNumber(+value)) {
        setCurrentLimits({
          ...currentLimits,
          [name]: +value >= 0 ? +value : 0,
        });
      }
    }
  };

  const handleInit = async () => {
    const response = await getResponsibleGamblingLimits();

    const {
      current_limits,
      new_limits,
      daily_spent,
      weekly_spent,
      monthly_spent,
      valid,
      timeout_until,
    } = response || {};

    if (timeout_until) {
      setPauseDate(
        formatDate(
          new Date(new Date(`${timeout_until.split(' ')} UTC`).toString()),
          true
        )
      );
    }

    if (current_limits) {
      setCurrentLimits(current_limits);
    }

    if (new_limits) {
      setNewLimits(new_limits);
    }

    setLimitsActiveAfter(
      valid && isNumber(valid) && valid > 0 ? valid * 1000 : 0
    );

    if (daily_spent && weekly_spent && monthly_spent) {
      setSpendings({ daily_spent, weekly_spent, monthly_spent });
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (currentLimits) {
      const isWithDelay = await setResponsibleGamblingLimits(currentLimits);

      toast.success(
        isWithDelay
          ? t('userData.changes-active-in-24-hrs')
          : t('verification.data-saved')
      );

      handleInit();
    }
  };

  const onGamblingPauseChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target) {
      const { value } = event.target;

      if (value && value !== pauseGamblingUntil) {
        const onConfirmGamblingPause = async () => {
          setPauseGamblingUntil(value);

          const selectedDate = value.includes('.')
            ? new Date(
                `${value.split('.')[2]}-${value.split('.')[1]}-${
                  value.split('.')[0]
                }`
              )
                .toISOString()
                .split('T')[0]
            : '';

          if (selectedDate && user?.id) {
            const result = await setResponsibleGamblingPause(
              `${selectedDate} 22:00`
            );

            if (result) {
              const { timeout, betting } = result;

              if (timeout && betting) {
                setPauseDate(
                  formatDate(
                    new Date(new Date(`${betting.split(' ')} UTC`).toString()),
                    true
                  )
                );
              }
            }
          }
        };

        openModal(<GamblingPause onConfirm={onConfirmGamblingPause} />, {
          width: 'small',
        });
      }
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    const [day, month, year] = (pauseDate || '').split('.');

    setHasPauseExpired(
      pauseDate
        ? !!(
            day &&
            month &&
            year &&
            new Date() > new Date(`${year}-${month}-${day}`)
          )
        : true
    );
  }, [pauseDate]);

  return (
    <form onSubmit={handleSubmit}>
      <SectionHeader title={t('userData.responsible-gambling')} />

      <div className="text-white">
        {limitsActiveAfter ? (
          <p className="py-2 text-center text-yellow uppercase">
            {t('userData.new-limits-take-effect-in')}{' '}
            {new Date(limitsActiveAfter).toISOString().substring(11, 19)}
          </p>
        ) : null}

        <p className="py-2 pl-8 bg-grey-800 uppercase">
          {t('userData.daily-limits')}
        </p>

        <div className="d-flex align-items mt-5">
          <p className="w-60 text-center">{t('userData.deposit')}</p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="daily_deposit"
              value={currentLimits.daily_deposit}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('daily_deposit')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['daily_deposit']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['daily_spent'].daily_deposit.toFixed(2)} EUR
            </p>
          </div>
        </div>

        <div className="d-flex align-items mt-5">
          <p className="w-60 text-center">{t('userData.betting-win-loss')}</p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="daily_net_bet"
              value={currentLimits.daily_net_bet}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('daily_net_bet')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['daily_net_bet']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['daily_spent'].daily_net_bet.toFixed(2)} EUR
            </p>
          </div>
        </div>

        <div className="d-flex align-items my-5">
          <p className="w-60 text-center">
            {t('userData.casino-and-virtuals-win-loss')}
          </p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="daily_net_games"
              value={currentLimits.daily_net_games}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('daily_net_games')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['daily_net_games']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['daily_spent'].daily_net_games.toFixed(2)} EUR
            </p>
          </div>
        </div>
      </div>

      <div className="mt-2 text-white">
        <p className="py-2 pl-8 bg-grey-800 uppercase">
          {t('userData.weekly-limits')}
        </p>

        <div className="d-flex align-items mt-5">
          <p className="w-60 text-center">{t('userData.deposit')}</p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="weekly_deposit"
              value={currentLimits.weekly_deposit}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('weekly_deposit')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['weekly_deposit']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['weekly_spent'].weekly_deposit.toFixed(2)} EUR
            </p>
          </div>
        </div>

        <div className="d-flex align-items mt-5">
          <p className="w-60 text-center">{t('userData.betting-win-loss')}</p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="weekly_net_bet"
              value={currentLimits.weekly_net_bet}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('weekly_net_bet')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['weekly_net_bet']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['weekly_spent'].weekly_net_bet.toFixed(2)} EUR
            </p>
          </div>
        </div>

        <div className="d-flex align-items my-5">
          <p className="w-60 text-center">
            {t('userData.casino-and-virtuals-win-loss')}
          </p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="weekly_net_games"
              value={currentLimits.weekly_net_games}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('weekly_net_games')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['weekly_net_games']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['weekly_spent'].weekly_net_games.toFixed(2)} EUR
            </p>
          </div>
        </div>
      </div>

      <div className="mt-2 text-white">
        <p className="py-2 pl-8 bg-grey-800 uppercase">
          {t('userData.monthly-limits')}
        </p>

        <div className="d-flex align-items mt-5">
          <p className="w-60 text-center">{t('userData.deposit')}</p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="monthly_deposit"
              value={currentLimits.monthly_deposit}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('monthly_deposit')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['monthly_deposit']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['monthly_spent'].monthly_deposit.toFixed(2)} EUR
            </p>
          </div>
        </div>

        <div className="d-flex align-items mt-5">
          <p className="w-60 text-center">{t('userData.betting-win-loss')}</p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="monthly_net_bet"
              value={currentLimits.monthly_net_bet}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('monthly_net_bet')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['monthly_net_bet']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['monthly_spent'].monthly_net_bet.toFixed(2)} EUR
            </p>
          </div>
        </div>

        <div className="d-flex align-items my-5">
          <p className="w-60 text-center">
            {t('userData.casino-and-virtuals-win-loss')}
          </p>

          <div className="w-100">
            <Input
              bg="light"
              type="number"
              name="monthly_net_games"
              value={currentLimits.monthly_net_games}
              onChange={handleInputChange}
            />

            <p className="text-center text-yellow">
              {isActiveNewLimit('monthly_net_games')
                ? `${t('userData.new-limit-value-is')} ${
                    newLimits['monthly_net_games']
                  }`
                : ''}
            </p>
          </div>

          <div className="w-60 text-center">
            <p>{t('userData.current-spending')}</p>

            <p className="text-green-200">
              {spendings['monthly_spent'].monthly_net_games.toFixed(2)} EUR
            </p>
          </div>
        </div>
      </div>

      <div className="text-center">
        <Button className="w-33 uppercase" bg="success" size="large">
          {t('globals.save')}
        </Button>
      </div>

      <div className="mt-5 text-white">
        <p
          className={classnames('py-2 pl-8 bg-grey-800 uppercase', {
            'text-yellow': !hasPauseExpired,
          })}
        >
          {t('userData.pause-gambling-until')}&nbsp;
          {hasPauseExpired ? '' : pauseDate}
        </p>

        {hasPauseExpired && (
          <div className="d-flex justify-center mt-5 mb-10">
            <DatePicker
              minimumDate={{
                day: new Date().getDate(),
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
              }}
              value={pauseGamblingUntil}
              onChange={onGamblingPauseChange}
            />
          </div>
        )}
      </div>
    </form>
  );
});

export default ResponsibleGambling;
