/* React modules */

/* Our modules */
import { Location as LocationType } from 'modules/sports/sports.types';
import SportNode, {
  NODE_TYPE,
} from 'modules/sports/services/sport-node.service';

/* 3rd Party modules */
import { makeObservable, observable } from 'mobx';

class Location extends SportNode {
  constructor(node: LocationType) {
    super(node, NODE_TYPE.LOCATION);

    makeObservable(this, {
      children: observable,
    });
  }
}

export default Location;
