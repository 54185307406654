// source: proto/odds_stream/odds_stream.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.odds_stream.Error', null, global);
goog.exportSymbol('proto.odds_stream.EventCurrentPhase', null, global);
goog.exportSymbol('proto.odds_stream.EventCurrentResult', null, global);
goog.exportSymbol('proto.odds_stream.EventLiveResultHistoryElement', null, global);
goog.exportSymbol('proto.odds_stream.EventResult', null, global);
goog.exportSymbol('proto.odds_stream.EventStream', null, global);
goog.exportSymbol('proto.odds_stream.EventStreamInt', null, global);
goog.exportSymbol('proto.odds_stream.EventTime', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsResponse', null, global);
goog.exportSymbol('proto.odds_stream.LiveEvents', null, global);
goog.exportSymbol('proto.odds_stream.StreamEvent', null, global);
goog.exportSymbol('proto.odds_stream.StreamEventOdd', null, global);
goog.exportSymbol('proto.odds_stream.UpcomingEvents', null, global);
goog.exportSymbol('proto.odds_stream.WebStreamEvent', null, global);
goog.exportSymbol('proto.odds_stream.WebStreamEventInt', null, global);
goog.exportSymbol('proto.odds_stream.WebStreamEventOdd', null, global);
goog.exportSymbol('proto.odds_stream.WebStreamEventOddInt', null, global);
goog.exportSymbol('proto.odds_stream.WebStreamEvents', null, global);
goog.exportSymbol('proto.odds_stream.WebStreamEventsInt', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventTime.displayName = 'proto.odds_stream.EventTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventCurrentPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventCurrentPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventCurrentPhase.displayName = 'proto.odds_stream.EventCurrentPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventCurrentResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventCurrentResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventCurrentResult.displayName = 'proto.odds_stream.EventCurrentResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventLiveResultHistoryElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventLiveResultHistoryElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventLiveResultHistoryElement.displayName = 'proto.odds_stream.EventLiveResultHistoryElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.Error.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.Error.displayName = 'proto.odds_stream.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.EventResult.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.EventResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventResult.displayName = 'proto.odds_stream.EventResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventStream.displayName = 'proto.odds_stream.EventStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebStreamEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.WebStreamEvents.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.WebStreamEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebStreamEvents.displayName = 'proto.odds_stream.WebStreamEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebStreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.WebStreamEvent.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.WebStreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebStreamEvent.displayName = 'proto.odds_stream.WebStreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebStreamEventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.WebStreamEventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebStreamEventOdd.displayName = 'proto.odds_stream.WebStreamEventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventStreamInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventStreamInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventStreamInt.displayName = 'proto.odds_stream.EventStreamInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebStreamEventsInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.WebStreamEventsInt.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.WebStreamEventsInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebStreamEventsInt.displayName = 'proto.odds_stream.WebStreamEventsInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebStreamEventInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.WebStreamEventInt.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.WebStreamEventInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebStreamEventInt.displayName = 'proto.odds_stream.WebStreamEventInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebStreamEventOddInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.WebStreamEventOddInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebStreamEventOddInt.displayName = 'proto.odds_stream.WebStreamEventOddInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.StreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.StreamEvent.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.StreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.StreamEvent.displayName = 'proto.odds_stream.StreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.StreamEventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.StreamEventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.StreamEventOdd.displayName = 'proto.odds_stream.StreamEventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.LiveEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.LiveEvents.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.LiveEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.LiveEvents.displayName = 'proto.odds_stream.LiveEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.UpcomingEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.UpcomingEvents.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.UpcomingEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.UpcomingEvents.displayName = 'proto.odds_stream.UpcomingEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.GetEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsResponse.displayName = 'proto.odds_stream.GetEventsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventTime.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    m: jspb.Message.getFieldWithDefault(msg, 202, ""),
    s: jspb.Message.getFieldWithDefault(msg, 203, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventTime}
 */
proto.odds_stream.EventTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventTime;
  return proto.odds_stream.EventTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventTime}
 */
proto.odds_stream.EventTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 202:
      var value = /** @type {string} */ (reader.readString());
      msg.setM(value);
      break;
    case 203:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getM();
  if (f.length > 0) {
    writer.writeString(
      202,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      203,
      f
    );
  }
};


/**
 * optional string M = 202;
 * @return {string}
 */
proto.odds_stream.EventTime.prototype.getM = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 202, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventTime} returns this
 */
proto.odds_stream.EventTime.prototype.setM = function(value) {
  return jspb.Message.setProto3StringField(this, 202, value);
};


/**
 * optional string S = 203;
 * @return {string}
 */
proto.odds_stream.EventTime.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 203, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventTime} returns this
 */
proto.odds_stream.EventTime.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 203, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventCurrentPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventCurrentPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventCurrentPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCurrentPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    n: jspb.Message.getFieldWithDefault(msg, 200, ""),
    s: jspb.Message.getFieldWithDefault(msg, 201, ""),
    t: (f = msg.getT()) && proto.odds_stream.EventTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventCurrentPhase}
 */
proto.odds_stream.EventCurrentPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventCurrentPhase;
  return proto.odds_stream.EventCurrentPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventCurrentPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventCurrentPhase}
 */
proto.odds_stream.EventCurrentPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 200:
      var value = /** @type {string} */ (reader.readString());
      msg.setN(value);
      break;
    case 201:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    case 204:
      var value = new proto.odds_stream.EventTime;
      reader.readMessage(value,proto.odds_stream.EventTime.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventCurrentPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventCurrentPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventCurrentPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCurrentPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getN();
  if (f.length > 0) {
    writer.writeString(
      200,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      201,
      f
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      204,
      f,
      proto.odds_stream.EventTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string N = 200;
 * @return {string}
 */
proto.odds_stream.EventCurrentPhase.prototype.getN = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 200, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentPhase} returns this
 */
proto.odds_stream.EventCurrentPhase.prototype.setN = function(value) {
  return jspb.Message.setProto3StringField(this, 200, value);
};


/**
 * optional string S = 201;
 * @return {string}
 */
proto.odds_stream.EventCurrentPhase.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 201, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentPhase} returns this
 */
proto.odds_stream.EventCurrentPhase.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 201, value);
};


/**
 * optional EventTime T = 204;
 * @return {?proto.odds_stream.EventTime}
 */
proto.odds_stream.EventCurrentPhase.prototype.getT = function() {
  return /** @type{?proto.odds_stream.EventTime} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventTime, 204));
};


/**
 * @param {?proto.odds_stream.EventTime|undefined} value
 * @return {!proto.odds_stream.EventCurrentPhase} returns this
*/
proto.odds_stream.EventCurrentPhase.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 204, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventCurrentPhase} returns this
 */
proto.odds_stream.EventCurrentPhase.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventCurrentPhase.prototype.hasT = function() {
  return jspb.Message.getField(this, 204) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventCurrentResult.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventCurrentResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventCurrentResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCurrentResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    g: jspb.Message.getFieldWithDefault(msg, 205, ""),
    c: jspb.Message.getFieldWithDefault(msg, 206, ""),
    rc: jspb.Message.getFieldWithDefault(msg, 207, ""),
    yc: jspb.Message.getFieldWithDefault(msg, 208, ""),
    p: jspb.Message.getFieldWithDefault(msg, 209, ""),
    s: jspb.Message.getFieldWithDefault(msg, 210, ""),
    l: jspb.Message.getFieldWithDefault(msg, 267, ""),
    s180: jspb.Message.getFieldWithDefault(msg, 268, ""),
    fg: jspb.Message.getFieldWithDefault(msg, 269, ""),
    pn: jspb.Message.getFieldWithDefault(msg, 270, ""),
    td: jspb.Message.getFieldWithDefault(msg, 271, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventCurrentResult}
 */
proto.odds_stream.EventCurrentResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventCurrentResult;
  return proto.odds_stream.EventCurrentResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventCurrentResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventCurrentResult}
 */
proto.odds_stream.EventCurrentResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 205:
      var value = /** @type {string} */ (reader.readString());
      msg.setG(value);
      break;
    case 206:
      var value = /** @type {string} */ (reader.readString());
      msg.setC(value);
      break;
    case 207:
      var value = /** @type {string} */ (reader.readString());
      msg.setRc(value);
      break;
    case 208:
      var value = /** @type {string} */ (reader.readString());
      msg.setYc(value);
      break;
    case 209:
      var value = /** @type {string} */ (reader.readString());
      msg.setP(value);
      break;
    case 210:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    case 267:
      var value = /** @type {string} */ (reader.readString());
      msg.setL(value);
      break;
    case 268:
      var value = /** @type {string} */ (reader.readString());
      msg.setS180(value);
      break;
    case 269:
      var value = /** @type {string} */ (reader.readString());
      msg.setFg(value);
      break;
    case 270:
      var value = /** @type {string} */ (reader.readString());
      msg.setPn(value);
      break;
    case 271:
      var value = /** @type {string} */ (reader.readString());
      msg.setTd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventCurrentResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventCurrentResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventCurrentResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCurrentResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getG();
  if (f.length > 0) {
    writer.writeString(
      205,
      f
    );
  }
  f = message.getC();
  if (f.length > 0) {
    writer.writeString(
      206,
      f
    );
  }
  f = message.getRc();
  if (f.length > 0) {
    writer.writeString(
      207,
      f
    );
  }
  f = message.getYc();
  if (f.length > 0) {
    writer.writeString(
      208,
      f
    );
  }
  f = message.getP();
  if (f.length > 0) {
    writer.writeString(
      209,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      210,
      f
    );
  }
  f = message.getL();
  if (f.length > 0) {
    writer.writeString(
      267,
      f
    );
  }
  f = message.getS180();
  if (f.length > 0) {
    writer.writeString(
      268,
      f
    );
  }
  f = message.getFg();
  if (f.length > 0) {
    writer.writeString(
      269,
      f
    );
  }
  f = message.getPn();
  if (f.length > 0) {
    writer.writeString(
      270,
      f
    );
  }
  f = message.getTd();
  if (f.length > 0) {
    writer.writeString(
      271,
      f
    );
  }
};


/**
 * optional string G = 205;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getG = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 205, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setG = function(value) {
  return jspb.Message.setProto3StringField(this, 205, value);
};


/**
 * optional string C = 206;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getC = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 206, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setC = function(value) {
  return jspb.Message.setProto3StringField(this, 206, value);
};


/**
 * optional string RC = 207;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getRc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 207, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setRc = function(value) {
  return jspb.Message.setProto3StringField(this, 207, value);
};


/**
 * optional string YC = 208;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getYc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 208, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setYc = function(value) {
  return jspb.Message.setProto3StringField(this, 208, value);
};


/**
 * optional string P = 209;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getP = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 209, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setP = function(value) {
  return jspb.Message.setProto3StringField(this, 209, value);
};


/**
 * optional string S = 210;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 210, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 210, value);
};


/**
 * optional string L = 267;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getL = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 267, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setL = function(value) {
  return jspb.Message.setProto3StringField(this, 267, value);
};


/**
 * optional string S180 = 268;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getS180 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 268, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setS180 = function(value) {
  return jspb.Message.setProto3StringField(this, 268, value);
};


/**
 * optional string FG = 269;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getFg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 269, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setFg = function(value) {
  return jspb.Message.setProto3StringField(this, 269, value);
};


/**
 * optional string PN = 270;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getPn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 270, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setPn = function(value) {
  return jspb.Message.setProto3StringField(this, 270, value);
};


/**
 * optional string TD = 271;
 * @return {string}
 */
proto.odds_stream.EventCurrentResult.prototype.getTd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 271, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCurrentResult} returns this
 */
proto.odds_stream.EventCurrentResult.prototype.setTd = function(value) {
  return jspb.Message.setProto3StringField(this, 271, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventLiveResultHistoryElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventLiveResultHistoryElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventLiveResultHistoryElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    p: (f = msg.getP()) && proto.odds_stream.EventCurrentPhase.toObject(includeInstance, f),
    r: (f = msg.getR()) && proto.odds_stream.EventCurrentResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventLiveResultHistoryElement}
 */
proto.odds_stream.EventLiveResultHistoryElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventLiveResultHistoryElement;
  return proto.odds_stream.EventLiveResultHistoryElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventLiveResultHistoryElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventLiveResultHistoryElement}
 */
proto.odds_stream.EventLiveResultHistoryElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 211:
      var value = new proto.odds_stream.EventCurrentPhase;
      reader.readMessage(value,proto.odds_stream.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setP(value);
      break;
    case 212:
      var value = new proto.odds_stream.EventCurrentResult;
      reader.readMessage(value,proto.odds_stream.EventCurrentResult.deserializeBinaryFromReader);
      msg.setR(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventLiveResultHistoryElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventLiveResultHistoryElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventLiveResultHistoryElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getP();
  if (f != null) {
    writer.writeMessage(
      211,
      f,
      proto.odds_stream.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getR();
  if (f != null) {
    writer.writeMessage(
      212,
      f,
      proto.odds_stream.EventCurrentResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCurrentPhase P = 211;
 * @return {?proto.odds_stream.EventCurrentPhase}
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.getP = function() {
  return /** @type{?proto.odds_stream.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventCurrentPhase, 211));
};


/**
 * @param {?proto.odds_stream.EventCurrentPhase|undefined} value
 * @return {!proto.odds_stream.EventLiveResultHistoryElement} returns this
*/
proto.odds_stream.EventLiveResultHistoryElement.prototype.setP = function(value) {
  return jspb.Message.setWrapperField(this, 211, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveResultHistoryElement} returns this
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.clearP = function() {
  return this.setP(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.hasP = function() {
  return jspb.Message.getField(this, 211) != null;
};


/**
 * optional EventCurrentResult R = 212;
 * @return {?proto.odds_stream.EventCurrentResult}
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.getR = function() {
  return /** @type{?proto.odds_stream.EventCurrentResult} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventCurrentResult, 212));
};


/**
 * @param {?proto.odds_stream.EventCurrentResult|undefined} value
 * @return {!proto.odds_stream.EventLiveResultHistoryElement} returns this
*/
proto.odds_stream.EventLiveResultHistoryElement.prototype.setR = function(value) {
  return jspb.Message.setWrapperField(this, 212, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveResultHistoryElement} returns this
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.clearR = function() {
  return this.setR(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveResultHistoryElement.prototype.hasR = function() {
  return jspb.Message.getField(this, 212) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.Error.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    grpcCode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    httpCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    messageList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.Error}
 */
proto.odds_stream.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.Error;
  return proto.odds_stream.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.Error}
 */
proto.odds_stream.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGrpcCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHttpCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGrpcCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHttpCode();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMessageList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional int32 grpc_code = 5;
 * @return {number}
 */
proto.odds_stream.Error.prototype.getGrpcCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Error} returns this
 */
proto.odds_stream.Error.prototype.setGrpcCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 http_code = 6;
 * @return {number}
 */
proto.odds_stream.Error.prototype.getHttpCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Error} returns this
 */
proto.odds_stream.Error.prototype.setHttpCode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string message = 7;
 * @return {!Array<string>}
 */
proto.odds_stream.Error.prototype.getMessageList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds_stream.Error} returns this
 */
proto.odds_stream.Error.prototype.setMessageList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.Error} returns this
 */
proto.odds_stream.Error.prototype.addMessage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.Error} returns this
 */
proto.odds_stream.Error.prototype.clearMessageList = function() {
  return this.setMessageList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.EventResult.repeatedFields_ = [215];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventResult.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPhase: (f = msg.getCurrentPhase()) && proto.odds_stream.EventCurrentPhase.toObject(includeInstance, f),
    currentResult: (f = msg.getCurrentResult()) && proto.odds_stream.EventCurrentResult.toObject(includeInstance, f),
    liveResultHistoryList: jspb.Message.toObjectList(msg.getLiveResultHistoryList(),
    proto.odds_stream.EventLiveResultHistoryElement.toObject, includeInstance),
    liveResultStats: jspb.Message.getFieldWithDefault(msg, 216, ""),
    prematchResult: jspb.Message.getFieldWithDefault(msg, 217, ""),
    liveResult: jspb.Message.getFieldWithDefault(msg, 218, ""),
    confirmed: jspb.Message.getBooleanFieldWithDefault(msg, 219, false),
    error: (f = msg.getError()) && proto.odds_stream.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventResult}
 */
proto.odds_stream.EventResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventResult;
  return proto.odds_stream.EventResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventResult}
 */
proto.odds_stream.EventResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 213:
      var value = new proto.odds_stream.EventCurrentPhase;
      reader.readMessage(value,proto.odds_stream.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 214:
      var value = new proto.odds_stream.EventCurrentResult;
      reader.readMessage(value,proto.odds_stream.EventCurrentResult.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 215:
      var value = new proto.odds_stream.EventLiveResultHistoryElement;
      reader.readMessage(value,proto.odds_stream.EventLiveResultHistoryElement.deserializeBinaryFromReader);
      msg.addLiveResultHistory(value);
      break;
    case 216:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveResultStats(value);
      break;
    case 217:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchResult(value);
      break;
    case 218:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveResult(value);
      break;
    case 219:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    case 78:
      var value = new proto.odds_stream.Error;
      reader.readMessage(value,proto.odds_stream.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      213,
      f,
      proto.odds_stream.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      214,
      f,
      proto.odds_stream.EventCurrentResult.serializeBinaryToWriter
    );
  }
  f = message.getLiveResultHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      215,
      f,
      proto.odds_stream.EventLiveResultHistoryElement.serializeBinaryToWriter
    );
  }
  f = message.getLiveResultStats();
  if (f.length > 0) {
    writer.writeString(
      216,
      f
    );
  }
  f = message.getPrematchResult();
  if (f.length > 0) {
    writer.writeString(
      217,
      f
    );
  }
  f = message.getLiveResult();
  if (f.length > 0) {
    writer.writeString(
      218,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      219,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto.odds_stream.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCurrentPhase current_phase = 213;
 * @return {?proto.odds_stream.EventCurrentPhase}
 */
proto.odds_stream.EventResult.prototype.getCurrentPhase = function() {
  return /** @type{?proto.odds_stream.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventCurrentPhase, 213));
};


/**
 * @param {?proto.odds_stream.EventCurrentPhase|undefined} value
 * @return {!proto.odds_stream.EventResult} returns this
*/
proto.odds_stream.EventResult.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 213, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventResult.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 213) != null;
};


/**
 * optional EventCurrentResult current_result = 214;
 * @return {?proto.odds_stream.EventCurrentResult}
 */
proto.odds_stream.EventResult.prototype.getCurrentResult = function() {
  return /** @type{?proto.odds_stream.EventCurrentResult} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventCurrentResult, 214));
};


/**
 * @param {?proto.odds_stream.EventCurrentResult|undefined} value
 * @return {!proto.odds_stream.EventResult} returns this
*/
proto.odds_stream.EventResult.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 214, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventResult.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 214) != null;
};


/**
 * repeated EventLiveResultHistoryElement live_result_history = 215;
 * @return {!Array<!proto.odds_stream.EventLiveResultHistoryElement>}
 */
proto.odds_stream.EventResult.prototype.getLiveResultHistoryList = function() {
  return /** @type{!Array<!proto.odds_stream.EventLiveResultHistoryElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventLiveResultHistoryElement, 215));
};


/**
 * @param {!Array<!proto.odds_stream.EventLiveResultHistoryElement>} value
 * @return {!proto.odds_stream.EventResult} returns this
*/
proto.odds_stream.EventResult.prototype.setLiveResultHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 215, value);
};


/**
 * @param {!proto.odds_stream.EventLiveResultHistoryElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventLiveResultHistoryElement}
 */
proto.odds_stream.EventResult.prototype.addLiveResultHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 215, opt_value, proto.odds_stream.EventLiveResultHistoryElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.clearLiveResultHistoryList = function() {
  return this.setLiveResultHistoryList([]);
};


/**
 * optional string live_result_stats = 216;
 * @return {string}
 */
proto.odds_stream.EventResult.prototype.getLiveResultStats = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 216, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.setLiveResultStats = function(value) {
  return jspb.Message.setProto3StringField(this, 216, value);
};


/**
 * optional string prematch_result = 217;
 * @return {string}
 */
proto.odds_stream.EventResult.prototype.getPrematchResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 217, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.setPrematchResult = function(value) {
  return jspb.Message.setProto3StringField(this, 217, value);
};


/**
 * optional string live_result = 218;
 * @return {string}
 */
proto.odds_stream.EventResult.prototype.getLiveResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 218, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.setLiveResult = function(value) {
  return jspb.Message.setProto3StringField(this, 218, value);
};


/**
 * optional bool confirmed = 219;
 * @return {boolean}
 */
proto.odds_stream.EventResult.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 219, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.setConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 219, value);
};


/**
 * optional Error error = 78;
 * @return {?proto.odds_stream.Error}
 */
proto.odds_stream.EventResult.prototype.getError = function() {
  return /** @type{?proto.odds_stream.Error} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.Error, 78));
};


/**
 * @param {?proto.odds_stream.Error|undefined} value
 * @return {!proto.odds_stream.EventResult} returns this
*/
proto.odds_stream.EventResult.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventResult} returns this
 */
proto.odds_stream.EventResult.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventResult.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventStream.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEvents: (f = msg.getLiveEvents()) && proto.odds_stream.WebStreamEvents.toObject(includeInstance, f),
    upcomingEvents: (f = msg.getUpcomingEvents()) && proto.odds_stream.WebStreamEvents.toObject(includeInstance, f),
    outrightEvents: (f = msg.getOutrightEvents()) && proto.odds_stream.WebStreamEvents.toObject(includeInstance, f),
    playerEvents: (f = msg.getPlayerEvents()) && proto.odds_stream.WebStreamEvents.toObject(includeInstance, f),
    groupEvents: (f = msg.getGroupEvents()) && proto.odds_stream.WebStreamEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventStream}
 */
proto.odds_stream.EventStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventStream;
  return proto.odds_stream.EventStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventStream}
 */
proto.odds_stream.EventStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.WebStreamEvents;
      reader.readMessage(value,proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader);
      msg.setLiveEvents(value);
      break;
    case 2:
      var value = new proto.odds_stream.WebStreamEvents;
      reader.readMessage(value,proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader);
      msg.setUpcomingEvents(value);
      break;
    case 3:
      var value = new proto.odds_stream.WebStreamEvents;
      reader.readMessage(value,proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader);
      msg.setOutrightEvents(value);
      break;
    case 4:
      var value = new proto.odds_stream.WebStreamEvents;
      reader.readMessage(value,proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader);
      msg.setPlayerEvents(value);
      break;
    case 5:
      var value = new proto.odds_stream.WebStreamEvents;
      reader.readMessage(value,proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader);
      msg.setGroupEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEvents();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.odds_stream.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingEvents();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.odds_stream.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getOutrightEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.odds_stream.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getPlayerEvents();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.odds_stream.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getGroupEvents();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.odds_stream.WebStreamEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional WebStreamEvents live_events = 1;
 * @return {?proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.EventStream.prototype.getLiveEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEvents, 1));
};


/**
 * @param {?proto.odds_stream.WebStreamEvents|undefined} value
 * @return {!proto.odds_stream.EventStream} returns this
*/
proto.odds_stream.EventStream.prototype.setLiveEvents = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStream} returns this
 */
proto.odds_stream.EventStream.prototype.clearLiveEvents = function() {
  return this.setLiveEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStream.prototype.hasLiveEvents = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WebStreamEvents upcoming_events = 2;
 * @return {?proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.EventStream.prototype.getUpcomingEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEvents, 2));
};


/**
 * @param {?proto.odds_stream.WebStreamEvents|undefined} value
 * @return {!proto.odds_stream.EventStream} returns this
*/
proto.odds_stream.EventStream.prototype.setUpcomingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStream} returns this
 */
proto.odds_stream.EventStream.prototype.clearUpcomingEvents = function() {
  return this.setUpcomingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStream.prototype.hasUpcomingEvents = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WebStreamEvents outright_events = 3;
 * @return {?proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.EventStream.prototype.getOutrightEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEvents, 3));
};


/**
 * @param {?proto.odds_stream.WebStreamEvents|undefined} value
 * @return {!proto.odds_stream.EventStream} returns this
*/
proto.odds_stream.EventStream.prototype.setOutrightEvents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStream} returns this
 */
proto.odds_stream.EventStream.prototype.clearOutrightEvents = function() {
  return this.setOutrightEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStream.prototype.hasOutrightEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WebStreamEvents player_events = 4;
 * @return {?proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.EventStream.prototype.getPlayerEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEvents, 4));
};


/**
 * @param {?proto.odds_stream.WebStreamEvents|undefined} value
 * @return {!proto.odds_stream.EventStream} returns this
*/
proto.odds_stream.EventStream.prototype.setPlayerEvents = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStream} returns this
 */
proto.odds_stream.EventStream.prototype.clearPlayerEvents = function() {
  return this.setPlayerEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStream.prototype.hasPlayerEvents = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WebStreamEvents group_events = 5;
 * @return {?proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.EventStream.prototype.getGroupEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEvents, 5));
};


/**
 * @param {?proto.odds_stream.WebStreamEvents|undefined} value
 * @return {!proto.odds_stream.EventStream} returns this
*/
proto.odds_stream.EventStream.prototype.setGroupEvents = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStream} returns this
 */
proto.odds_stream.EventStream.prototype.clearGroupEvents = function() {
  return this.setGroupEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStream.prototype.hasGroupEvents = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.WebStreamEvents.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebStreamEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebStreamEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebStreamEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.WebStreamEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.WebStreamEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebStreamEvents;
  return proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebStreamEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebStreamEvents}
 */
proto.odds_stream.WebStreamEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds_stream.WebStreamEvent;
      reader.readMessage(value,proto.odds_stream.WebStreamEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebStreamEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebStreamEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebStreamEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds_stream.WebStreamEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebStreamEvent events = 17;
 * @return {!Array<!proto.odds_stream.WebStreamEvent>}
 */
proto.odds_stream.WebStreamEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.WebStreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.WebStreamEvent, 17));
};


/**
 * @param {!Array<!proto.odds_stream.WebStreamEvent>} value
 * @return {!proto.odds_stream.WebStreamEvents} returns this
*/
proto.odds_stream.WebStreamEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds_stream.WebStreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.WebStreamEvent}
 */
proto.odds_stream.WebStreamEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds_stream.WebStreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.WebStreamEvents} returns this
 */
proto.odds_stream.WebStreamEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.WebStreamEvent.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebStreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebStreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebStreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds_stream.WebStreamEventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    result: (f = msg.getResult()) && proto.odds_stream.EventResult.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 13, 0),
    numberOfOdds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    profile: jspb.Message.getFieldWithDefault(msg, 16, ""),
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    betradarStreamId: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebStreamEvent}
 */
proto.odds_stream.WebStreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebStreamEvent;
  return proto.odds_stream.WebStreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebStreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebStreamEvent}
 */
proto.odds_stream.WebStreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = new proto.odds_stream.WebStreamEventOdd;
      reader.readMessage(value,proto.odds_stream.WebStreamEventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = new proto.odds_stream.EventResult;
      reader.readMessage(value,proto.odds_stream.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOdds(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebStreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebStreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebStreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds_stream.WebStreamEventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.odds_stream.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getNumberOfOdds();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBetradarStreamId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds_stream.WebStreamEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 location_id = 4;
 * @return {number}
 */
proto.odds_stream.WebStreamEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds_stream.WebStreamEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated WebStreamEventOdd odds = 6;
 * @return {!Array<!proto.odds_stream.WebStreamEventOdd>}
 */
proto.odds_stream.WebStreamEvent.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds_stream.WebStreamEventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.WebStreamEventOdd, 6));
};


/**
 * @param {!Array<!proto.odds_stream.WebStreamEventOdd>} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
*/
proto.odds_stream.WebStreamEvent.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds_stream.WebStreamEventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.WebStreamEventOdd}
 */
proto.odds_stream.WebStreamEvent.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds_stream.WebStreamEventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds_stream.WebStreamEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
*/
proto.odds_stream.WebStreamEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebStreamEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_disabled = 8;
 * @return {boolean}
 */
proto.odds_stream.WebStreamEvent.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional EventResult result = 9;
 * @return {?proto.odds_stream.EventResult}
 */
proto.odds_stream.WebStreamEvent.prototype.getResult = function() {
  return /** @type{?proto.odds_stream.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventResult, 9));
};


/**
 * @param {?proto.odds_stream.EventResult|undefined} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
*/
proto.odds_stream.WebStreamEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebStreamEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string landbase_code = 10;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outright_type = 12;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 mon = 13;
 * @return {number}
 */
proto.odds_stream.WebStreamEvent.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 number_of_odds = 14;
 * @return {number}
 */
proto.odds_stream.WebStreamEvent.prototype.getNumberOfOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setNumberOfOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 exefeed_id = 15;
 * @return {number}
 */
proto.odds_stream.WebStreamEvent.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string profile = 16;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string betradar_stream_id = 18;
 * @return {string}
 */
proto.odds_stream.WebStreamEvent.prototype.getBetradarStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEvent} returns this
 */
proto.odds_stream.WebStreamEvent.prototype.setBetradarStreamId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebStreamEventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebStreamEventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebStreamEventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebStreamEventOdd}
 */
proto.odds_stream.WebStreamEventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebStreamEventOdd;
  return proto.odds_stream.WebStreamEventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebStreamEventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebStreamEventOdd}
 */
proto.odds_stream.WebStreamEventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebStreamEventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebStreamEventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebStreamEventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double limit = 4;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 frame_number = 5;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string current_result = 6;
 * @return {string}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_disabled = 7;
 * @return {boolean}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.odds_stream.WebStreamEventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventOdd} returns this
 */
proto.odds_stream.WebStreamEventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventStreamInt.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventStreamInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventStreamInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventStreamInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEvents: (f = msg.getLiveEvents()) && proto.odds_stream.WebStreamEventsInt.toObject(includeInstance, f),
    upcomingEvents: (f = msg.getUpcomingEvents()) && proto.odds_stream.WebStreamEventsInt.toObject(includeInstance, f),
    outrightEvents: (f = msg.getOutrightEvents()) && proto.odds_stream.WebStreamEventsInt.toObject(includeInstance, f),
    playerEvents: (f = msg.getPlayerEvents()) && proto.odds_stream.WebStreamEventsInt.toObject(includeInstance, f),
    groupEvents: (f = msg.getGroupEvents()) && proto.odds_stream.WebStreamEventsInt.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventStreamInt}
 */
proto.odds_stream.EventStreamInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventStreamInt;
  return proto.odds_stream.EventStreamInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventStreamInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventStreamInt}
 */
proto.odds_stream.EventStreamInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.WebStreamEventsInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader);
      msg.setLiveEvents(value);
      break;
    case 2:
      var value = new proto.odds_stream.WebStreamEventsInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader);
      msg.setUpcomingEvents(value);
      break;
    case 3:
      var value = new proto.odds_stream.WebStreamEventsInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader);
      msg.setOutrightEvents(value);
      break;
    case 4:
      var value = new proto.odds_stream.WebStreamEventsInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader);
      msg.setPlayerEvents(value);
      break;
    case 5:
      var value = new proto.odds_stream.WebStreamEventsInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader);
      msg.setGroupEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventStreamInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventStreamInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventStreamInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventStreamInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEvents();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingEvents();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter
    );
  }
  f = message.getOutrightEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter
    );
  }
  f = message.getPlayerEvents();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter
    );
  }
  f = message.getGroupEvents();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter
    );
  }
};


/**
 * optional WebStreamEventsInt live_events = 1;
 * @return {?proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.EventStreamInt.prototype.getLiveEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEventsInt} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEventsInt, 1));
};


/**
 * @param {?proto.odds_stream.WebStreamEventsInt|undefined} value
 * @return {!proto.odds_stream.EventStreamInt} returns this
*/
proto.odds_stream.EventStreamInt.prototype.setLiveEvents = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStreamInt} returns this
 */
proto.odds_stream.EventStreamInt.prototype.clearLiveEvents = function() {
  return this.setLiveEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStreamInt.prototype.hasLiveEvents = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WebStreamEventsInt upcoming_events = 2;
 * @return {?proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.EventStreamInt.prototype.getUpcomingEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEventsInt} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEventsInt, 2));
};


/**
 * @param {?proto.odds_stream.WebStreamEventsInt|undefined} value
 * @return {!proto.odds_stream.EventStreamInt} returns this
*/
proto.odds_stream.EventStreamInt.prototype.setUpcomingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStreamInt} returns this
 */
proto.odds_stream.EventStreamInt.prototype.clearUpcomingEvents = function() {
  return this.setUpcomingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStreamInt.prototype.hasUpcomingEvents = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WebStreamEventsInt outright_events = 3;
 * @return {?proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.EventStreamInt.prototype.getOutrightEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEventsInt} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEventsInt, 3));
};


/**
 * @param {?proto.odds_stream.WebStreamEventsInt|undefined} value
 * @return {!proto.odds_stream.EventStreamInt} returns this
*/
proto.odds_stream.EventStreamInt.prototype.setOutrightEvents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStreamInt} returns this
 */
proto.odds_stream.EventStreamInt.prototype.clearOutrightEvents = function() {
  return this.setOutrightEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStreamInt.prototype.hasOutrightEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WebStreamEventsInt player_events = 4;
 * @return {?proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.EventStreamInt.prototype.getPlayerEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEventsInt} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEventsInt, 4));
};


/**
 * @param {?proto.odds_stream.WebStreamEventsInt|undefined} value
 * @return {!proto.odds_stream.EventStreamInt} returns this
*/
proto.odds_stream.EventStreamInt.prototype.setPlayerEvents = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStreamInt} returns this
 */
proto.odds_stream.EventStreamInt.prototype.clearPlayerEvents = function() {
  return this.setPlayerEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStreamInt.prototype.hasPlayerEvents = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WebStreamEventsInt group_events = 5;
 * @return {?proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.EventStreamInt.prototype.getGroupEvents = function() {
  return /** @type{?proto.odds_stream.WebStreamEventsInt} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.WebStreamEventsInt, 5));
};


/**
 * @param {?proto.odds_stream.WebStreamEventsInt|undefined} value
 * @return {!proto.odds_stream.EventStreamInt} returns this
*/
proto.odds_stream.EventStreamInt.prototype.setGroupEvents = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventStreamInt} returns this
 */
proto.odds_stream.EventStreamInt.prototype.clearGroupEvents = function() {
  return this.setGroupEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventStreamInt.prototype.hasGroupEvents = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.WebStreamEventsInt.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebStreamEventsInt.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebStreamEventsInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebStreamEventsInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventsInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.WebStreamEventInt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.WebStreamEventsInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebStreamEventsInt;
  return proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebStreamEventsInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebStreamEventsInt}
 */
proto.odds_stream.WebStreamEventsInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds_stream.WebStreamEventInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventInt.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebStreamEventsInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebStreamEventsInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventsInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds_stream.WebStreamEventInt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebStreamEventInt events = 17;
 * @return {!Array<!proto.odds_stream.WebStreamEventInt>}
 */
proto.odds_stream.WebStreamEventsInt.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.WebStreamEventInt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.WebStreamEventInt, 17));
};


/**
 * @param {!Array<!proto.odds_stream.WebStreamEventInt>} value
 * @return {!proto.odds_stream.WebStreamEventsInt} returns this
*/
proto.odds_stream.WebStreamEventsInt.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds_stream.WebStreamEventInt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.WebStreamEventInt}
 */
proto.odds_stream.WebStreamEventsInt.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds_stream.WebStreamEventInt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.WebStreamEventsInt} returns this
 */
proto.odds_stream.WebStreamEventsInt.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.WebStreamEventInt.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebStreamEventInt.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebStreamEventInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebStreamEventInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds_stream.WebStreamEventOddInt.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    result: (f = msg.getResult()) && proto.odds_stream.EventResult.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 13, 0),
    numberOfOdds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    profile: jspb.Message.getFieldWithDefault(msg, 16, ""),
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    betradarStreamId: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebStreamEventInt}
 */
proto.odds_stream.WebStreamEventInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebStreamEventInt;
  return proto.odds_stream.WebStreamEventInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebStreamEventInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebStreamEventInt}
 */
proto.odds_stream.WebStreamEventInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = new proto.odds_stream.WebStreamEventOddInt;
      reader.readMessage(value,proto.odds_stream.WebStreamEventOddInt.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = new proto.odds_stream.EventResult;
      reader.readMessage(value,proto.odds_stream.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOdds(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebStreamEventInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebStreamEventInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebStreamEventInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds_stream.WebStreamEventOddInt.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.odds_stream.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getNumberOfOdds();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBetradarStreamId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds_stream.WebStreamEventInt.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 location_id = 4;
 * @return {number}
 */
proto.odds_stream.WebStreamEventInt.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds_stream.WebStreamEventInt.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated WebStreamEventOddInt odds = 6;
 * @return {!Array<!proto.odds_stream.WebStreamEventOddInt>}
 */
proto.odds_stream.WebStreamEventInt.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds_stream.WebStreamEventOddInt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.WebStreamEventOddInt, 6));
};


/**
 * @param {!Array<!proto.odds_stream.WebStreamEventOddInt>} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
*/
proto.odds_stream.WebStreamEventInt.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds_stream.WebStreamEventOddInt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.WebStreamEventOddInt}
 */
proto.odds_stream.WebStreamEventInt.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds_stream.WebStreamEventOddInt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds_stream.WebStreamEventInt.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
*/
proto.odds_stream.WebStreamEventInt.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebStreamEventInt.prototype.hasStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_disabled = 8;
 * @return {boolean}
 */
proto.odds_stream.WebStreamEventInt.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional EventResult result = 9;
 * @return {?proto.odds_stream.EventResult}
 */
proto.odds_stream.WebStreamEventInt.prototype.getResult = function() {
  return /** @type{?proto.odds_stream.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventResult, 9));
};


/**
 * @param {?proto.odds_stream.EventResult|undefined} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
*/
proto.odds_stream.WebStreamEventInt.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebStreamEventInt.prototype.hasResult = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string landbase_code = 10;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outright_type = 12;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 mon = 13;
 * @return {number}
 */
proto.odds_stream.WebStreamEventInt.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 number_of_odds = 14;
 * @return {number}
 */
proto.odds_stream.WebStreamEventInt.prototype.getNumberOfOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setNumberOfOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 exefeed_id = 15;
 * @return {number}
 */
proto.odds_stream.WebStreamEventInt.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string profile = 16;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string betradar_stream_id = 18;
 * @return {string}
 */
proto.odds_stream.WebStreamEventInt.prototype.getBetradarStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventInt} returns this
 */
proto.odds_stream.WebStreamEventInt.prototype.setBetradarStreamId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebStreamEventOddInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebStreamEventOddInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventOddInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebStreamEventOddInt}
 */
proto.odds_stream.WebStreamEventOddInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebStreamEventOddInt;
  return proto.odds_stream.WebStreamEventOddInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebStreamEventOddInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebStreamEventOddInt}
 */
proto.odds_stream.WebStreamEventOddInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebStreamEventOddInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebStreamEventOddInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebStreamEventOddInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 frame_number = 5;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string current_result = 6;
 * @return {string}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_disabled = 7;
 * @return {boolean}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.odds_stream.WebStreamEventOddInt.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebStreamEventOddInt} returns this
 */
proto.odds_stream.WebStreamEventOddInt.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.StreamEvent.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.StreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.StreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.StreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.StreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds_stream.StreamEventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isSuspicious: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isSingleApproval: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    profile: jspb.Message.getFieldWithDefault(msg, 12, ""),
    currentPhase: (f = msg.getCurrentPhase()) && proto.odds_stream.EventCurrentPhase.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    type: jspb.Message.getFieldWithDefault(msg, 15, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 17, 0),
    result: (f = msg.getResult()) && proto.odds_stream.EventResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.StreamEvent}
 */
proto.odds_stream.StreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.StreamEvent;
  return proto.odds_stream.StreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.StreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.StreamEvent}
 */
proto.odds_stream.StreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 7:
      var value = new proto.odds_stream.StreamEventOdd;
      reader.readMessage(value,proto.odds_stream.StreamEventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspicious(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleApproval(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 13:
      var value = new proto.odds_stream.EventCurrentPhase;
      reader.readMessage(value,proto.odds_stream.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 18:
      var value = new proto.odds_stream.EventResult;
      reader.readMessage(value,proto.odds_stream.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.StreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.StreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.StreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.StreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.odds_stream.StreamEventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsSuspicious();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsSingleApproval();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.odds_stream.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.odds_stream.EventResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds_stream.StreamEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 5;
 * @return {number}
 */
proto.odds_stream.StreamEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sport_id = 6;
 * @return {number}
 */
proto.odds_stream.StreamEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated StreamEventOdd odds = 7;
 * @return {!Array<!proto.odds_stream.StreamEventOdd>}
 */
proto.odds_stream.StreamEvent.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds_stream.StreamEventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.StreamEventOdd, 7));
};


/**
 * @param {!Array<!proto.odds_stream.StreamEventOdd>} value
 * @return {!proto.odds_stream.StreamEvent} returns this
*/
proto.odds_stream.StreamEvent.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.odds_stream.StreamEventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.StreamEventOdd}
 */
proto.odds_stream.StreamEvent.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.odds_stream.StreamEventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.odds_stream.StreamEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.odds_stream.StreamEvent} returns this
*/
proto.odds_stream.StreamEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.StreamEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_disabled = 9;
 * @return {boolean}
 */
proto.odds_stream.StreamEvent.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_suspicious = 10;
 * @return {boolean}
 */
proto.odds_stream.StreamEvent.prototype.getIsSuspicious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setIsSuspicious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_single_approval = 11;
 * @return {boolean}
 */
proto.odds_stream.StreamEvent.prototype.getIsSingleApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setIsSingleApproval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string profile = 12;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional EventCurrentPhase current_phase = 13;
 * @return {?proto.odds_stream.EventCurrentPhase}
 */
proto.odds_stream.StreamEvent.prototype.getCurrentPhase = function() {
  return /** @type{?proto.odds_stream.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventCurrentPhase, 13));
};


/**
 * @param {?proto.odds_stream.EventCurrentPhase|undefined} value
 * @return {!proto.odds_stream.StreamEvent} returns this
*/
proto.odds_stream.StreamEvent.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.StreamEvent.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string landbase_code = 14;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string type = 15;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string outright_type = 16;
 * @return {string}
 */
proto.odds_stream.StreamEvent.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 mon = 17;
 * @return {number}
 */
proto.odds_stream.StreamEvent.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional EventResult result = 18;
 * @return {?proto.odds_stream.EventResult}
 */
proto.odds_stream.StreamEvent.prototype.getResult = function() {
  return /** @type{?proto.odds_stream.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.EventResult, 18));
};


/**
 * @param {?proto.odds_stream.EventResult|undefined} value
 * @return {!proto.odds_stream.StreamEvent} returns this
*/
proto.odds_stream.StreamEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.StreamEvent} returns this
 */
proto.odds_stream.StreamEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.StreamEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.StreamEventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.StreamEventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.StreamEventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.StreamEventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 122, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 238, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 239, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.StreamEventOdd}
 */
proto.odds_stream.StreamEventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.StreamEventOdd;
  return proto.odds_stream.StreamEventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.StreamEventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.StreamEventOdd}
 */
proto.odds_stream.StreamEventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 122:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 238:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 239:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.StreamEventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.StreamEventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.StreamEventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.StreamEventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      122,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      238,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      239,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds_stream.StreamEventOdd.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 8;
 * @return {number}
 */
proto.odds_stream.StreamEventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 outcome_id = 11;
 * @return {number}
 */
proto.odds_stream.StreamEventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double limit = 122;
 * @return {number}
 */
proto.odds_stream.StreamEventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 122, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 122, value);
};


/**
 * optional int32 frame_number = 238;
 * @return {number}
 */
proto.odds_stream.StreamEventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 238, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 238, value);
};


/**
 * optional string current_result = 239;
 * @return {string}
 */
proto.odds_stream.StreamEventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 239, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 239, value);
};


/**
 * optional bool is_disabled = 136;
 * @return {boolean}
 */
proto.odds_stream.StreamEventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.odds_stream.StreamEventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.StreamEventOdd} returns this
 */
proto.odds_stream.StreamEventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.LiveEvents.repeatedFields_ = [17,194];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.LiveEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.LiveEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.LiveEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.LiveEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.StreamEvent.toObject, includeInstance),
    disableAll: jspb.Message.getBooleanFieldWithDefault(msg, 193, false),
    disabledSportsList: (f = jspb.Message.getRepeatedField(msg, 194)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.LiveEvents}
 */
proto.odds_stream.LiveEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.LiveEvents;
  return proto.odds_stream.LiveEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.LiveEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.LiveEvents}
 */
proto.odds_stream.LiveEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds_stream.StreamEvent;
      reader.readMessage(value,proto.odds_stream.StreamEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 193:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableAll(value);
      break;
    case 194:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisabledSports(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.LiveEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.LiveEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.LiveEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.LiveEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds_stream.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getDisableAll();
  if (f) {
    writer.writeBool(
      193,
      f
    );
  }
  f = message.getDisabledSportsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      194,
      f
    );
  }
};


/**
 * repeated StreamEvent events = 17;
 * @return {!Array<!proto.odds_stream.StreamEvent>}
 */
proto.odds_stream.LiveEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.StreamEvent, 17));
};


/**
 * @param {!Array<!proto.odds_stream.StreamEvent>} value
 * @return {!proto.odds_stream.LiveEvents} returns this
*/
proto.odds_stream.LiveEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds_stream.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.StreamEvent}
 */
proto.odds_stream.LiveEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds_stream.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.LiveEvents} returns this
 */
proto.odds_stream.LiveEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional bool disable_all = 193;
 * @return {boolean}
 */
proto.odds_stream.LiveEvents.prototype.getDisableAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 193, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.LiveEvents} returns this
 */
proto.odds_stream.LiveEvents.prototype.setDisableAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 193, value);
};


/**
 * repeated int32 disabled_sports = 194;
 * @return {!Array<number>}
 */
proto.odds_stream.LiveEvents.prototype.getDisabledSportsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 194));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds_stream.LiveEvents} returns this
 */
proto.odds_stream.LiveEvents.prototype.setDisabledSportsList = function(value) {
  return jspb.Message.setField(this, 194, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.LiveEvents} returns this
 */
proto.odds_stream.LiveEvents.prototype.addDisabledSports = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 194, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.LiveEvents} returns this
 */
proto.odds_stream.LiveEvents.prototype.clearDisabledSportsList = function() {
  return this.setDisabledSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.UpcomingEvents.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.UpcomingEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.UpcomingEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.UpcomingEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.UpcomingEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.StreamEvent.toObject, includeInstance),
    error: (f = msg.getError()) && proto.odds_stream.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.UpcomingEvents}
 */
proto.odds_stream.UpcomingEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.UpcomingEvents;
  return proto.odds_stream.UpcomingEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.UpcomingEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.UpcomingEvents}
 */
proto.odds_stream.UpcomingEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.odds_stream.StreamEvent;
      reader.readMessage(value,proto.odds_stream.StreamEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 78:
      var value = new proto.odds_stream.Error;
      reader.readMessage(value,proto.odds_stream.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.UpcomingEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.UpcomingEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.UpcomingEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.UpcomingEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.odds_stream.StreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto.odds_stream.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StreamEvent events = 17;
 * @return {!Array<!proto.odds_stream.StreamEvent>}
 */
proto.odds_stream.UpcomingEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.StreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.StreamEvent, 17));
};


/**
 * @param {!Array<!proto.odds_stream.StreamEvent>} value
 * @return {!proto.odds_stream.UpcomingEvents} returns this
*/
proto.odds_stream.UpcomingEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.odds_stream.StreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.StreamEvent}
 */
proto.odds_stream.UpcomingEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.odds_stream.StreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.UpcomingEvents} returns this
 */
proto.odds_stream.UpcomingEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional Error error = 78;
 * @return {?proto.odds_stream.Error}
 */
proto.odds_stream.UpcomingEvents.prototype.getError = function() {
  return /** @type{?proto.odds_stream.Error} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.Error, 78));
};


/**
 * @param {?proto.odds_stream.Error|undefined} value
 * @return {!proto.odds_stream.UpcomingEvents} returns this
*/
proto.odds_stream.UpcomingEvents.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.UpcomingEvents} returns this
 */
proto.odds_stream.UpcomingEvents.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.UpcomingEvents.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEvents: (f = msg.getLiveEvents()) && proto.odds_stream.LiveEvents.toObject(includeInstance, f),
    upcomingEvents: (f = msg.getUpcomingEvents()) && proto.odds_stream.UpcomingEvents.toObject(includeInstance, f),
    outrightEvents: (f = msg.getOutrightEvents()) && proto.odds_stream.UpcomingEvents.toObject(includeInstance, f),
    playerEvents: (f = msg.getPlayerEvents()) && proto.odds_stream.UpcomingEvents.toObject(includeInstance, f),
    groupEvents: (f = msg.getGroupEvents()) && proto.odds_stream.UpcomingEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsResponse}
 */
proto.odds_stream.GetEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsResponse;
  return proto.odds_stream.GetEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsResponse}
 */
proto.odds_stream.GetEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 36:
      var value = new proto.odds_stream.LiveEvents;
      reader.readMessage(value,proto.odds_stream.LiveEvents.deserializeBinaryFromReader);
      msg.setLiveEvents(value);
      break;
    case 37:
      var value = new proto.odds_stream.UpcomingEvents;
      reader.readMessage(value,proto.odds_stream.UpcomingEvents.deserializeBinaryFromReader);
      msg.setUpcomingEvents(value);
      break;
    case 247:
      var value = new proto.odds_stream.UpcomingEvents;
      reader.readMessage(value,proto.odds_stream.UpcomingEvents.deserializeBinaryFromReader);
      msg.setOutrightEvents(value);
      break;
    case 248:
      var value = new proto.odds_stream.UpcomingEvents;
      reader.readMessage(value,proto.odds_stream.UpcomingEvents.deserializeBinaryFromReader);
      msg.setPlayerEvents(value);
      break;
    case 249:
      var value = new proto.odds_stream.UpcomingEvents;
      reader.readMessage(value,proto.odds_stream.UpcomingEvents.deserializeBinaryFromReader);
      msg.setGroupEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEvents();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.odds_stream.LiveEvents.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingEvents();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.odds_stream.UpcomingEvents.serializeBinaryToWriter
    );
  }
  f = message.getOutrightEvents();
  if (f != null) {
    writer.writeMessage(
      247,
      f,
      proto.odds_stream.UpcomingEvents.serializeBinaryToWriter
    );
  }
  f = message.getPlayerEvents();
  if (f != null) {
    writer.writeMessage(
      248,
      f,
      proto.odds_stream.UpcomingEvents.serializeBinaryToWriter
    );
  }
  f = message.getGroupEvents();
  if (f != null) {
    writer.writeMessage(
      249,
      f,
      proto.odds_stream.UpcomingEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional LiveEvents live_events = 36;
 * @return {?proto.odds_stream.LiveEvents}
 */
proto.odds_stream.GetEventsResponse.prototype.getLiveEvents = function() {
  return /** @type{?proto.odds_stream.LiveEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.LiveEvents, 36));
};


/**
 * @param {?proto.odds_stream.LiveEvents|undefined} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setLiveEvents = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearLiveEvents = function() {
  return this.setLiveEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.GetEventsResponse.prototype.hasLiveEvents = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional UpcomingEvents upcoming_events = 37;
 * @return {?proto.odds_stream.UpcomingEvents}
 */
proto.odds_stream.GetEventsResponse.prototype.getUpcomingEvents = function() {
  return /** @type{?proto.odds_stream.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.UpcomingEvents, 37));
};


/**
 * @param {?proto.odds_stream.UpcomingEvents|undefined} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setUpcomingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearUpcomingEvents = function() {
  return this.setUpcomingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.GetEventsResponse.prototype.hasUpcomingEvents = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional UpcomingEvents outright_events = 247;
 * @return {?proto.odds_stream.UpcomingEvents}
 */
proto.odds_stream.GetEventsResponse.prototype.getOutrightEvents = function() {
  return /** @type{?proto.odds_stream.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.UpcomingEvents, 247));
};


/**
 * @param {?proto.odds_stream.UpcomingEvents|undefined} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setOutrightEvents = function(value) {
  return jspb.Message.setWrapperField(this, 247, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearOutrightEvents = function() {
  return this.setOutrightEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.GetEventsResponse.prototype.hasOutrightEvents = function() {
  return jspb.Message.getField(this, 247) != null;
};


/**
 * optional UpcomingEvents player_events = 248;
 * @return {?proto.odds_stream.UpcomingEvents}
 */
proto.odds_stream.GetEventsResponse.prototype.getPlayerEvents = function() {
  return /** @type{?proto.odds_stream.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.UpcomingEvents, 248));
};


/**
 * @param {?proto.odds_stream.UpcomingEvents|undefined} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setPlayerEvents = function(value) {
  return jspb.Message.setWrapperField(this, 248, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearPlayerEvents = function() {
  return this.setPlayerEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.GetEventsResponse.prototype.hasPlayerEvents = function() {
  return jspb.Message.getField(this, 248) != null;
};


/**
 * optional UpcomingEvents group_events = 249;
 * @return {?proto.odds_stream.UpcomingEvents}
 */
proto.odds_stream.GetEventsResponse.prototype.getGroupEvents = function() {
  return /** @type{?proto.odds_stream.UpcomingEvents} */ (
    jspb.Message.getWrapperField(this, proto.odds_stream.UpcomingEvents, 249));
};


/**
 * @param {?proto.odds_stream.UpcomingEvents|undefined} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setGroupEvents = function(value) {
  return jspb.Message.setWrapperField(this, 249, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearGroupEvents = function() {
  return this.setGroupEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.GetEventsResponse.prototype.hasGroupEvents = function() {
  return jspb.Message.getField(this, 249) != null;
};


goog.object.extend(exports, proto.odds_stream);
