/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import {
  TicketStatusInfo,
  TicketStatusActions,
  TicketStatusHeader,
} from 'modules/ticket/ui/TicketStatus';
import TicketStatusBody from 'modules/ticket/ui/TicketStatus/TicketStatusBody';
import './TicketStatus.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const TicketStatusComponent = ({
  hideHeaderAndFooter,
  hideFooter,
  isSharedTicket,
  quickTicket,
  allTicketsButton,
  hideTime,
}: any) => {
  const { t } = useTranslation();

  const {
    ticketsStore,
    overlayStore,
    ticketBuilder,
    ticketValidator,
    loaderStore,
  } = useStores();

  const {
    ticket,
    placeBet,
    placeBetLive,
    getQuickCode,
    cashoutTimerActive,
    quickCode,
    resetCashoutAmount,
  } = ticketsStore;

  const { hasLiveEvents } = ticketBuilder;

  const {
    status,
    isFix,
    systemGroups,
    amount,
    created_at,
    bonus,
    minBonus,
    maxPotBonus,
    winningAmount,
    potentialWinningAmount,
    actualWinningAmount,
    actualWinningNoBonus,
    totalOdd,
    minOdd,
    maxOdd,
    eventsCount,
    oldAmount,
    minWinningAmount,
    minWinningTotal,
  } = ticket || {};

  const bonusInPercent = ticketBuilder.bonusInPercent;

  const headerProps = {
    status: status || '',
    type: isFix ? t('slips.fix') : t('slips.system'),
    created_at,
    quickTicket,
    hideTime,
    hideFooter,
  };

  const infoProps = {
    bonus: bonus || null,
    max_bonus: maxPotBonus || null,
    min_bonus: minBonus || null,
    odd_sum: totalOdd || null,
    min_odd: minOdd || null,
    max_odd: maxOdd || null,
    bet_amount: amount || null,
    old_bet_amount: oldAmount,
    event_length: eventsCount || null,
    potential_winning: potentialWinningAmount || null,
    min_winning_total: minWinningTotal || null,
    min_winning_amount: minWinningAmount || null,
    actual_winning_amount: actualWinningAmount || null,
    actual_winning_no_bonus: actualWinningNoBonus || null,
    potential_cashout: winningAmount || null,
    cashout_amount: null,
    quickTicket,
    bonusInPercent,
    allTicketsButton,
  };

  const confirmPlaceBet = () => {
    if (overlayStore.modalProps?.data?.isFastSlip) {
      getQuickCode({ amount: amount || -1, systemGroups }).then(
        (success: boolean) => {
          if (success) {
            ticketValidator.clearServerErrors();
            overlayStore.modalProps?.data?.onSuccess();
          }
        }
      );
    } else {
      hasLiveEvents
        ? placeBetLive({ amount: amount || -1, systemGroups })
        : placeBet({ amount: amount || -1, systemGroups });
    }
  };

  const QuickTicketCode = ({ quickCode }: any) => {
    return (
      <div className="ticket-status__code">
        {!loaderStore.isActive && (
          <>
            <div className="ticket-status__code--spacer">
              <span>{t('slips.quick-ticket-pay')}</span>
            </div>
            <div className="ticket-status__code--row">{quickCode}</div>
            <div className="ticket-status__code--spacer">
              <span>{t('slips.quick-ticket-terms')}</span>
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      overlayStore.modalProps.name === 'ticket-preview' &&
      ticketsStore.timerActive
    ) {
      overlayStore.modalProps.className += ' disable-close';
    }

    if (
      overlayStore.modalProps.name === 'ticket-preview' &&
      cashoutTimerActive
    ) {
      overlayStore.modalProps.className += ' disable-close';
    }

    return () => {
      overlayStore.modalProps.className = '';
    };
  });

  useEffect(() => {
    return () => {
      ticketsStore.resetCopyCount();
    };
  }, []);

  useEffect(() => {
    return () => {
      resetCashoutAmount();
    };
  }, []);

  return (
    <div className="ticket-status ticket-preview-modal-wrap">
      <div
        className={classNames('ticket-status__inner', {
          'ticket-status__inner--system': !isFix,
        })}
      >
        {!hideHeaderAndFooter && <TicketStatusHeader {...headerProps} />}

        <div
          className={classNames('ticket-status__body-wrap', {
            'quick-ticket-wrap': quickTicket,
          })}
          style={{ height: '100%' }}
        >
          <TicketStatusBody
            status={status}
            isFix={isFix}
            systemGroups={systemGroups}
          />

          {!hideHeaderAndFooter && (
            <TicketStatusInfo
              isFix={isFix}
              {...infoProps}
              status={status as any}
            />
          )}
        </div>
      </div>

      {!hideHeaderAndFooter && !quickTicket ? (
        <TicketStatusActions
          placeBet={confirmPlaceBet}
          status={status as any}
          cashout_amount={null}
          allTicketsButton={allTicketsButton}
          hideFooter={hideFooter}
          isSharedTicket={isSharedTicket}
        />
      ) : (
        <QuickTicketCode quickCode={quickCode} />
      )}
    </div>
  );
};

TicketStatusComponent.defaultProps = {
  hideHeaderAndFooter: false,
  allTicketsButton: true,
  hideTime: false,
  hideFooter: false,
  isSharedTicket: false,
};

const TicketStatus = observer(TicketStatusComponent);

export { TicketStatus };
