import React from 'react';

import { Button, Icon } from 'components';
import overlayStore from 'libs/overlay-store';

import './ActivationSent.scss';
import { useTranslation } from 'react-i18next';

const ActivationSent = () => {
  const { t } = useTranslation();

  return (
    <div className="activation-sent">
      <div className="activation-sent__header">
        <h3>{t('globals.register')}</h3>
      </div>
      <div className="activation-sent__content">
        <div className="d-flex justify-center mb-6">
          <Icon size="extra-large" name="activation" />
        </div>
        <div className="activation-sent__content--row">
          <span>{t('errors.activation-link-exp')}</span>
        </div>
        <div className="d-flex justify-center align-items mt-5 mb-5">
          <Button
            bg="green"
            size="large"
            onClick={() => overlayStore.closeModal()}
          >
            <span className="text-regular text-bold">OK</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActivationSent;
