enum QuickGamesErrors {
  USER_BLOCKED = 'ERR_USER_BLOCKED',
  USER_NOT_VALIDATED = 'ERR_USER_NOT_VALIDATED',
}

type QuickGamesError =
  | QuickGamesErrors.USER_BLOCKED
  | QuickGamesErrors.USER_NOT_VALIDATED;

enum QuickGamesOptions {
  LUCKY_SIX = 'luckysix',
  LUCKY_X = 'luckyx',
  GREYHOUND = 'greyhound',
  HORSE_RACES = 'vhorse',
  NEXT_SIX = 'nextsix',
  LIGHTNING = 'NVNVLightningLuckySix',
  AVIATOR = 'aviator',
  GOAL = 'goal',
  DICE = 'dice',
  MINI_ROULETTE = 'mini-roulette',
  PLINKO = 'plinko',
  KENO = 'keno',
  HILO = 'hi-lo',
  MINES = 'mines',
}

type QuickGame =
  | QuickGamesOptions.LUCKY_SIX
  | QuickGamesOptions.LUCKY_X
  | QuickGamesOptions.GREYHOUND
  | QuickGamesOptions.HORSE_RACES
  | QuickGamesOptions.NEXT_SIX
  | QuickGamesOptions.LIGHTNING
  | QuickGamesOptions.AVIATOR
  | QuickGamesOptions.GOAL
  | QuickGamesOptions.DICE
  | QuickGamesOptions.MINI_ROULETTE
  | QuickGamesOptions.PLINKO
  | QuickGamesOptions.KENO
  | QuickGamesOptions.HILO
  | QuickGamesOptions.MINES;

export { QuickGamesErrors, QuickGamesOptions };

export type { QuickGamesError, QuickGame };
