/* React modules */
import { useEffect, useCallback } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import NotificationsService from 'modules/notifications/notifications.service';
import SingleDrop from 'modules/user/ui/CashDrop/SingleDrop/SingleDrop';
import { hasProperty } from 'libs/common-helpers';
import './CashDrop.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const CashDrop = () => {
  const { t } = useTranslation();
  const { cashDropStore, authStore } = useStores();
  const { dropsList, setNewCashDrop, resetCashDrop } = cashDropStore;
  let notificationsService: any = null;

  const initSocket = useCallback(() => {
    if (authStore.token) {
      const handlers = {
        cashdrop: setNewCashDrop,
      };

      notificationsService = new NotificationsService(
        handlers,
        authStore.token
      );
    } else {
      console.error(
        'Could not connect to notifications socket because token is not valid.'
      );
    }
  }, []);

  useEffect(() => {
    initSocket();

    return () => {
      notificationsService.disconnect();
      resetCashDrop();
    };
  }, []);

  const parseDropsPerDate = useCallback((dropsList: any) => {
    let dropsListPerDate: any = {};

    dropsList.forEach((drop: any) => {
      if (!hasProperty(dropsListPerDate, drop.date)) {
        dropsListPerDate[drop.date] = [];
      }
    });

    dropsList.forEach((drop: any) => {
      Object.keys(dropsListPerDate).forEach((key: any) => {
        if (drop.date === key) {
          dropsListPerDate[key].push(drop);
        }
      });
    });

    return dropsListPerDate;
  }, []);

  const formatDate = (date: any) => {
    return date.replace(/\//g, '.');
  };

  const sortList = useCallback((dropsList: any) => {
    Object.keys(dropsList).forEach((date) => {
      dropsList[date].sort((a: any, b: any) => b.start - a.start);
    });

    return dropsList;
  }, []);

  const parsedList = parseDropsPerDate(dropsList);
  const sortedList = sortList(parsedList);

  return (
    <div className="cash-drop">
      {dropsList.length > 0 &&
        Object.keys(sortedList).map((date: any, index: number) => (
          <ul key={`CashDrop-ul-${index}`} className="py-3">
            <p className="sb-text-small">{formatDate(date)}</p>

            {sortedList[date].map((drop: any) => (
              <div
                key={`CashDrop-SingleDrop-${drop.id + date}`}
                className="py-1"
              >
                <SingleDrop
                  id={drop.id}
                  active={drop.active}
                  awardAmount={drop.awardAmount}
                  awardType={drop.awardType}
                  collected={drop.collected}
                  collectedByUser={drop.collectedByUser}
                  total={drop.total}
                  date={drop.date}
                  time={drop.time}
                />
              </div>
            ))}
          </ul>
        ))}
      {dropsList.length === 0 && (
        <div className="d-flex justify-center align-items mt-20 sb-text-small">
          {t('errors.system-error')}
        </div>
      )}
    </div>
  );
};

export default observer(CashDrop);
