/**
 * @fileoverview gRPC-Web generated client stub for notifications
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_common_common_pb from '../../proto/common/common_pb';
import * as proto_notifications_notifications_pb from '../../proto/notifications/notifications_pb';


export class NotificationsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoNotifyUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.UserNotification) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  notifyUser(
    request: proto_notifications_notifications_pb.UserNotification,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  notifyUser(
    request: proto_notifications_notifications_pb.UserNotification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  notifyUser(
    request: proto_notifications_notifications_pb.UserNotification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/NotifyUser',
        request,
        metadata || {},
        this.methodInfoNotifyUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/NotifyUser',
    request,
    metadata || {},
    this.methodInfoNotifyUser);
  }

  methodInfoConfirmNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.UserConfirmation) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  confirmNotification(
    request: proto_notifications_notifications_pb.UserConfirmation,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  confirmNotification(
    request: proto_notifications_notifications_pb.UserConfirmation,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  confirmNotification(
    request: proto_notifications_notifications_pb.UserConfirmation,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/ConfirmNotification',
        request,
        metadata || {},
        this.methodInfoConfirmNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/ConfirmNotification',
    request,
    metadata || {},
    this.methodInfoConfirmNotification);
  }

  methodInfoNotifyBettingPlaces = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.BettingPlacesNotification) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  notifyBettingPlaces(
    request: proto_notifications_notifications_pb.BettingPlacesNotification,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  notifyBettingPlaces(
    request: proto_notifications_notifications_pb.BettingPlacesNotification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  notifyBettingPlaces(
    request: proto_notifications_notifications_pb.BettingPlacesNotification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/NotifyBettingPlaces',
        request,
        metadata || {},
        this.methodInfoNotifyBettingPlaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/NotifyBettingPlaces',
    request,
    metadata || {},
    this.methodInfoNotifyBettingPlaces);
  }

  methodInfoSystemNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.SystemNotificationRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  systemNotification(
    request: proto_notifications_notifications_pb.SystemNotificationRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  systemNotification(
    request: proto_notifications_notifications_pb.SystemNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  systemNotification(
    request: proto_notifications_notifications_pb.SystemNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/SystemNotification',
        request,
        metadata || {},
        this.methodInfoSystemNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/SystemNotification',
    request,
    metadata || {},
    this.methodInfoSystemNotification);
  }

  methodInfoGetPushNotifications = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.GetPushNotificationsResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.GetPushNotificationsResponse.deserializeBinary
  );

  getPushNotifications(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.GetPushNotificationsResponse>;

  getPushNotifications(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.GetPushNotificationsResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.GetPushNotificationsResponse>;

  getPushNotifications(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.GetPushNotificationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/GetPushNotifications',
        request,
        metadata || {},
        this.methodInfoGetPushNotifications,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/GetPushNotifications',
    request,
    metadata || {},
    this.methodInfoGetPushNotifications);
  }

  methodInfoBroadcastDoublePrint = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.BroadcastDoublePrintRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  broadcastDoublePrint(
    request: proto_notifications_notifications_pb.BroadcastDoublePrintRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  broadcastDoublePrint(
    request: proto_notifications_notifications_pb.BroadcastDoublePrintRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  broadcastDoublePrint(
    request: proto_notifications_notifications_pb.BroadcastDoublePrintRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/BroadcastDoublePrint',
        request,
        metadata || {},
        this.methodInfoBroadcastDoublePrint,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/BroadcastDoublePrint',
    request,
    metadata || {},
    this.methodInfoBroadcastDoublePrint);
  }

  methodInfoBroadcastBlankSlip = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.BroadcastBlankSlipRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  broadcastBlankSlip(
    request: proto_notifications_notifications_pb.BroadcastBlankSlipRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  broadcastBlankSlip(
    request: proto_notifications_notifications_pb.BroadcastBlankSlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  broadcastBlankSlip(
    request: proto_notifications_notifications_pb.BroadcastBlankSlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/BroadcastBlankSlip',
        request,
        metadata || {},
        this.methodInfoBroadcastBlankSlip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/BroadcastBlankSlip',
    request,
    metadata || {},
    this.methodInfoBroadcastBlankSlip);
  }

  methodInfoValidationRulesUpdate = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  validationRulesUpdate(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  validationRulesUpdate(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  validationRulesUpdate(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/ValidationRulesUpdate',
        request,
        metadata || {},
        this.methodInfoValidationRulesUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/ValidationRulesUpdate',
    request,
    metadata || {},
    this.methodInfoValidationRulesUpdate);
  }

  methodInfoWebUsersNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.WebNotification) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  webUsersNotification(
    request: proto_notifications_notifications_pb.WebNotification,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  webUsersNotification(
    request: proto_notifications_notifications_pb.WebNotification,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  webUsersNotification(
    request: proto_notifications_notifications_pb.WebNotification,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/WebUsersNotification',
        request,
        metadata || {},
        this.methodInfoWebUsersNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/WebUsersNotification',
    request,
    metadata || {},
    this.methodInfoWebUsersNotification);
  }

  methodInfoOutcomeUpdateNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.OutcomeUpdateRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  outcomeUpdateNotification(
    request: proto_notifications_notifications_pb.OutcomeUpdateRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  outcomeUpdateNotification(
    request: proto_notifications_notifications_pb.OutcomeUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  outcomeUpdateNotification(
    request: proto_notifications_notifications_pb.OutcomeUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/OutcomeUpdateNotification',
        request,
        metadata || {},
        this.methodInfoOutcomeUpdateNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/OutcomeUpdateNotification',
    request,
    metadata || {},
    this.methodInfoOutcomeUpdateNotification);
  }

  methodInfoConfirmBmMessage = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_notifications_notifications_pb.ConfirmBmMessageRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  confirmBmMessage(
    request: proto_notifications_notifications_pb.ConfirmBmMessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  confirmBmMessage(
    request: proto_notifications_notifications_pb.ConfirmBmMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  confirmBmMessage(
    request: proto_notifications_notifications_pb.ConfirmBmMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.NotificationsService/ConfirmBmMessage',
        request,
        metadata || {},
        this.methodInfoConfirmBmMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.NotificationsService/ConfirmBmMessage',
    request,
    metadata || {},
    this.methodInfoConfirmBmMessage);
  }

}

export class ApproveNotificationsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoSendForManualApproval = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.ManualApprovalRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  sendForManualApproval(
    request: proto_notifications_notifications_pb.ManualApprovalRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  sendForManualApproval(
    request: proto_notifications_notifications_pb.ManualApprovalRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  sendForManualApproval(
    request: proto_notifications_notifications_pb.ManualApprovalRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.ApproveNotificationsService/SendForManualApproval',
        request,
        metadata || {},
        this.methodInfoSendForManualApproval,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.ApproveNotificationsService/SendForManualApproval',
    request,
    metadata || {},
    this.methodInfoSendForManualApproval);
  }

  methodInfoRemoveFromManualApproval = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.ManualApprovalRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  removeFromManualApproval(
    request: proto_notifications_notifications_pb.ManualApprovalRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  removeFromManualApproval(
    request: proto_notifications_notifications_pb.ManualApprovalRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  removeFromManualApproval(
    request: proto_notifications_notifications_pb.ManualApprovalRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.ApproveNotificationsService/RemoveFromManualApproval',
        request,
        metadata || {},
        this.methodInfoRemoveFromManualApproval,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.ApproveNotificationsService/RemoveFromManualApproval',
    request,
    metadata || {},
    this.methodInfoRemoveFromManualApproval);
  }

  methodInfoSendForManualCashOut = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.ManualCashOutRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  sendForManualCashOut(
    request: proto_notifications_notifications_pb.ManualCashOutRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  sendForManualCashOut(
    request: proto_notifications_notifications_pb.ManualCashOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  sendForManualCashOut(
    request: proto_notifications_notifications_pb.ManualCashOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.ApproveNotificationsService/SendForManualCashOut',
        request,
        metadata || {},
        this.methodInfoSendForManualCashOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.ApproveNotificationsService/SendForManualCashOut',
    request,
    metadata || {},
    this.methodInfoSendForManualCashOut);
  }

  methodInfoRemoveFromManualCashOut = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.ManualCashOutRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  removeFromManualCashOut(
    request: proto_notifications_notifications_pb.ManualCashOutRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  removeFromManualCashOut(
    request: proto_notifications_notifications_pb.ManualCashOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  removeFromManualCashOut(
    request: proto_notifications_notifications_pb.ManualCashOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.ApproveNotificationsService/RemoveFromManualCashOut',
        request,
        metadata || {},
        this.methodInfoRemoveFromManualCashOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.ApproveNotificationsService/RemoveFromManualCashOut',
    request,
    metadata || {},
    this.methodInfoRemoveFromManualCashOut);
  }

  methodInfoSendMinimumLimits = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.LimitsRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  sendMinimumLimits(
    request: proto_notifications_notifications_pb.LimitsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  sendMinimumLimits(
    request: proto_notifications_notifications_pb.LimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  sendMinimumLimits(
    request: proto_notifications_notifications_pb.LimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.ApproveNotificationsService/SendMinimumLimits',
        request,
        metadata || {},
        this.methodInfoSendMinimumLimits,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.ApproveNotificationsService/SendMinimumLimits',
    request,
    metadata || {},
    this.methodInfoSendMinimumLimits);
  }

  methodInfoRequestNewLimits = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_notifications_notifications_pb.NotificationResponse,
    (request: proto_notifications_notifications_pb.LimitsRequest) => {
      return request.serializeBinary();
    },
    proto_notifications_notifications_pb.NotificationResponse.deserializeBinary
  );

  requestNewLimits(
    request: proto_notifications_notifications_pb.LimitsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_notifications_notifications_pb.NotificationResponse>;

  requestNewLimits(
    request: proto_notifications_notifications_pb.LimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void): grpcWeb.ClientReadableStream<proto_notifications_notifications_pb.NotificationResponse>;

  requestNewLimits(
    request: proto_notifications_notifications_pb.LimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_notifications_notifications_pb.NotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.ApproveNotificationsService/RequestNewLimits',
        request,
        metadata || {},
        this.methodInfoRequestNewLimits,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.ApproveNotificationsService/RequestNewLimits',
    request,
    metadata || {},
    this.methodInfoRequestNewLimits);
  }

}

