import { makeAutoObservable } from 'mobx';

class LoaderStore {
  isActive: boolean = false;
  single: Set<string> = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  setActive = (isLoading: boolean) => {
    this.isActive = isLoading;
  };

  addLoader = (name: string) => {
    this.single.add(name);
  };

  removeLoader = (name: string) => {
    this.single.delete(name);
  };

  isActiveLoader = (name: string) => {
    return this.single.has(name);
  };
}

export default new LoaderStore();
