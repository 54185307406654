export enum CashDropType {
  FREE_BET = 'FB',
  FREE_CASINO = 'FC',
}

export type CashDropTypes = CashDropType.FREE_BET | CashDropType.FREE_CASINO;

export interface DropItem {
  id: number;
  start: number;
  date?: string;
  time?: string;
  total: number;
  active: boolean;
  collected: number;
  awardType: string;
  awardAmount: number;
  collectedByUser?: boolean;
}

export enum CashDropError {
  DROP_NOT_ACTIVE = 'DROP_NOT_ACTIVE',
  USER_MIN_BET = 'USER_MIN_BET',
  DROP_NOT_FOUND = 'DROP_NOT_FOUND',
  USER_MIN_BALANCE = 'USER_MIN_BALANCE',
  DROP_ALREADY_COLLECTED_BY_USER = 'DROP_ALREADY_COLLECTED_BY_USER',
  GROUP_ALREADY_COLLECTED_BY_USER = 'GROUP_ALREADY_COLLECTED_BY_USER',
  DROP_COLLECTED = 'DROP_COLLECTED',
}

export type CashDropErrors =
  | CashDropError.DROP_NOT_ACTIVE
  | CashDropError.USER_MIN_BET
  | CashDropError.DROP_NOT_FOUND
  | CashDropError.USER_MIN_BALANCE
  | CashDropError.DROP_ALREADY_COLLECTED_BY_USER
  | CashDropError.DROP_COLLECTED
  | CashDropError.GROUP_ALREADY_COLLECTED_BY_USER;

export enum CashDropStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type CashDropStatuses = CashDropStatus.ENABLED | CashDropStatus.DISABLED;
