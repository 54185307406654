/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { EventsFilters } from 'modules/offer/store/offer.filters';
import { OfferWrapperHeader } from 'modules/offer/ui/OfferMobile/OfferWrapper/OfferWrapper';
import EventMobile from 'modules/offer/ui/OfferMobile/EventMobile';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso } from 'react-virtuoso';

const OfferListView = ({ selectedSport, mobileOffer }: any) => {
  const { t } = useTranslation();

  const [groupsCount, setGroupsCount] = useState(
    mobileOffer.map((t: any) => t.events.length)
  );

  useEffect(() => {
    setGroupsCount(mobileOffer.map((t: any) => t.events.length));
  }, [selectedSport, mobileOffer]);

  const renderHeader = (index: number) => (
    <OfferWrapperHeader
      icon={index === 0 ? 'animatedLiveGreen' : 'calendarMobile'}
      label={index === 0 ? t(EventsFilters.LIVE) : t(EventsFilters.UPCOMING)}
    />
  );

  const renderEvents = (
    index: number,
    groupIndex: number,
    mobileOffer: any
  ) => {
    const prevGroupsEventsCount =
      groupIndex >= 1 ? mobileOffer[groupIndex - 1]?.events.length : 0;

    const events = mobileOffer[groupIndex]?.events || [];
    if (!events[index - prevGroupsEventsCount]) {
      return <div style={{ height: '1px' }} />;
    }
    return (
      <EventMobile
        event={events[index - prevGroupsEventsCount]}
        events={events}
        rowIndex={index - prevGroupsEventsCount}
      />
    );
  };

  return (
    <GroupedVirtuoso
      style={{ height: '100%', width: '100%' }}
      groupCounts={groupsCount}
      groupContent={renderHeader}
      itemContent={(index: any, groupIndex: any) =>
        renderEvents(index, groupIndex, mobileOffer)
      }
    />
  );
};

export default observer(OfferListView);
