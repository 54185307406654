/* React modules */
import { useCallback, useContext } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import { OverlayContext } from 'pages/sport/Sport';
import CountryIcon, { COUNTRY_ICONS } from 'components/CountryIcon';
import overlayStore from 'libs/overlay-store';
import DefaultLocationIcon from '../../../../images/defaultLocationIcon.svg';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

const SearchEventCell = observer(({ value }: any) => {
  const openAllOdds = useContext(OverlayContext);

  const {
    competitionName,
    locationName,
    type,
    eventTime,
    name,
    competitionShortDisplay,
  } = value.event;

  const canOpenAllOdds =
    type === EVENT_TYPE.LIVE || type === EVENT_TYPE.UPCOMING;

  const openOverlay = useCallback(
    () =>
      canOpenAllOdds &&
      openAllOdds({
        ...value,
        location: locationName,
        competition: competitionName,
      }),
    [value, canOpenAllOdds, openAllOdds, locationName, competitionName]
  );

  return (
    <div
      onClick={() => {
        openOverlay();
        overlayStore.closeModal();
      }}
      className="w-100 d-flex align-items"
    >
      <div>
        {COUNTRY_ICONS[locationName] !== undefined ? (
          <CountryIcon
            original
            size="large"
            country={COUNTRY_ICONS[locationName]}
          />
        ) : (
          <img
            alt="Location"
            style={{ height: '20px', width: '20px' }}
            src={DefaultLocationIcon}
          />
        )}
        <p className="text-sb-extra-small text-center">
          {competitionShortDisplay.substr(0, 3)}
        </p>
      </div>
      <div>
        <p className="ml-3 text-yellow-500">{eventTime}</p>
        <p className="ml-3 mt-1">{name}</p>
      </div>
    </div>
  );
});

export default SearchEventCell;
