/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { WalletTypes } from 'modules/wallet/wallet.types';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import UserNotifications from 'modules/user/ui/UserNotifications';
import UserOverlay from 'modules/user/ui/UserOverlay';
import Wallet from 'modules/wallet/ui/Wallet';
import { Drawer } from 'components';
import './HeaderControls.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

const HeaderControls = observer(() => {
  const { t } = useTranslation();
  const [walletsVisible, toggleWalletsVisiblilty] = useState(false);
  const {
    userStore: { wallets, user, getUserData, pokerBalance },
    authStore: { isLoggedIn },
    marketStore: { getLoyalty, isDisabledLoyalty, pointsStatus },
  } = useStores();

  const [isOpenOverlay, toggleOverlay] = useState<boolean>(false);

  const overlayDrawerHandler = () => {
    toggleOverlay(!isOpenOverlay);
  };

  useEffect(() => {
    if (walletsVisible) {
      setTimeout(() => toggleWalletsVisiblilty(false), 10000);
    }
  }, [walletsVisible]);

  const toggleWallet = () => {
    if (!walletsVisible) {
      getUserData();
    }

    toggleWalletsVisiblilty(!walletsVisible);
  };

  const parsePoints = (points: any) => {
    let parsedPoints = String(points);
    if (parsedPoints?.includes('.')) {
      return (
        parsedPoints.split('.')[0] +
        '.' +
        parsedPoints.split('.')[1].substr(0, 2)
      );
    }

    return parsedPoints;
  };

  return (
    <div onClick={overlayDrawerHandler} className="header-controls">
      <div
        className={classnames(
          'visible-desktop-up',
          'mr-6',
          'd-flex',
          'align-items',
          {
            'header-controls__wallet-visible': walletsVisible,
            'header-controls__wallet-hidden': !walletsVisible,
          }
        )}
      >
        {wallets
          .filter(
            (wallet: any) =>
              wallet.type !== WalletTypes.RESERVED_FUNDS &&
              wallet.type !== WalletTypes.PROMO_BET_BONUS_TO_UNLOCK
          )
          .map((wallet: any, index: number) => (
            <div key={`HeaderControls-div-${index}`} className="ml-4">
              <Wallet inline data={wallet} />
            </div>
          ))}

        {pointsStatus === 'enabled' && !isDisabledLoyalty && (
          <Tooltip
            placement="bottom"
            overlay={t('globals.click-for-more-info')}
            overlayClassName="tooltip"
            mouseEnterDelay={0.5}
          >
            <a
              href="https://promo.sbbet.me/loyalty/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="loyalty-balance inline">
                <div className="d-flex text-small">
                  <p className="balance-label">
                    {t('userMarket.sbbet-points')}
                  </p>
                </div>
                <div className="loyalty-balance__amount inline ml-3">
                  {parsePoints(getLoyalty?.points)}
                </div>
              </div>
            </a>
          </Tooltip>
        )}

        <div className="loyalty-balance inline">
          <div className="d-flex text-small">
            <p className="balance-label">{t('wallet.poker-balance')}</p>
          </div>

          <div className="loyalty-balance__amount inline ml-3">
            {pokerBalance}
          </div>
        </div>
      </div>

      <div className="header-controls__right">
        {!walletsVisible && !!user ? (
          <div className="text-small mr-4 visible-tablet-up">
            <span className="text-green-200 mr-1">{user.username}</span>
            <span>[{user.id}]</span>
          </div>
        ) : (
          <div />
        )}

        <div className="d-flex">
          <Tooltip
            overlay={t('globals.wallets')}
            placement="bottom"
            overlayClassName="tooltip"
          >
            <span className="visible-desktop-up">
              <Icon
                className="mr-4"
                variant={walletsVisible ? 'sbgreen-800' : 'light'}
                name="wallet"
                onClick={toggleWallet}
              />
            </span>
          </Tooltip>

          {isLoggedIn && (
            <Dropdown
              template={<UserNotifications />}
              className="notifications-dropdown"
            >
              <Tooltip
                overlay={t('globals.notifications')}
                placement="bottom"
                overlayClassName="tooltip"
              >
                <div>
                  <Icon className="mr-4 notifications-dropdown" name="bell" />
                </div>
              </Tooltip>
            </Dropdown>
          )}

          <Tooltip
            overlay={t('globals.profile')}
            placement="bottom"
            overlayClassName="tooltip"
          >
            <Link
              className={classnames(
                isLoggedIn ? 'visible-tablet-up' : 'visible-desktop-up'
              )}
              to="/profil/uplata"
            >
              <Icon name="user" />
            </Link>
          </Tooltip>
          <span
            className={
              !isLoggedIn ? 'visible-desktop-down' : 'visible-tablet-down'
            }
          >
            <Icon name="user" onClick={overlayDrawerHandler} />
          </span>
        </div>
      </div>
      <Drawer
        placement="right"
        width="200px"
        height="100%"
        className="visible-desktop-down"
        visible={isOpenOverlay}
        onClose={overlayDrawerHandler}
      >
        <UserOverlay />
      </Drawer>
    </div>
  );
});

export { HeaderControls };
