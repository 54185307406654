/* React modules */

/* Our modules */
import { SystemAPI } from 'modules/system/system.api';
import { hasProperty } from 'libs/common-helpers';

/* 3rd Party modules */

class SystemService {
  private handlers: any;

  private api: SystemAPI;

  constructor(handlers: any) {
    this.handlers = handlers;

    this.api = new SystemAPI();

    this.api.connect(this.onMessage.bind(this));
  }

  onMessage(message: any) {
    const { type } = message;

    if (hasProperty(this.handlers, type)) {
      this.handlers[type](message);
    }
  }

  disconnect() {
    this.api.disconnect();
  }
}

export { SystemService };
