/* React modules */

/* Our modules */
import NotificationsApi from 'modules/notifications/notifications.api';
import { hasProperty } from 'libs/common-helpers';

/* 3rd Party modules */

class NotificationsService {
  public handlers: any;
  private api: NotificationsApi;

  constructor(handlers: any, token: string) {
    this.handlers = handlers;
    this.api = new NotificationsApi();
    this.api.connect(this.onMessage.bind(this), token);
  }

  onMessage(message: any) {
    const { type } = message;

    if (hasProperty(this.handlers, type)) {
      this.handlers[type](message);
    }
  }

  disconnect() {
    this.api.disconnect();
  }
}

export default NotificationsService;
