/* React modules */
import { useState } from 'react';

/* Our modules */
import './CreditDebitFilter.scss';

/* 3rd Party modules */
import classNames from 'classnames';

interface DebitFilterProps {
  tabs: object[];
  onTabSelected: (arg: number) => void;
}

const CreditDebitFilter = ({ tabs, onTabSelected }: DebitFilterProps) => {
  const [active, setActive] = useState<number>(0);

  const setActiveTab = (index: number) => {
    onTabSelected(index);
    setActive(index);
  };

  return (
    <div className="debit-filter d-flex align-items uppercase">
      {tabs.map((tab: any, i: number) => (
        <div
          key={`CreditDebitFilter-div-${tab.label}`}
          onClick={() => setActiveTab(i)}
          className={classNames('debit-filter__item', 'pointer', 'uppercase', {
            'debit-filter__active': active === i,
          })}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default CreditDebitFilter;
