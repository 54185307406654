/* React modules */

/* Our modules */
import TicketApi from 'modules/ticket/ticket.api';
import { WalletType, WalletTypes } from 'modules/wallet/wallet.types';
import { Error } from 'modules/ticket/ticket.types';
import { FREE_BET_RULES } from 'modules/ticket/ticket.constants';
import * as ERROR_MESSAGES from 'modules/ticket/ticket.errors';
import { logger, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import i18next from 'i18next';

const { ValidationRule, VALIDATION_REGEX, VALIDATION_KEYS } = ERROR_MESSAGES;

class TicketValidator {
  api: TicketApi;
  rules: any;
  errors: Error[] = [];
  warnings: Error[] = [];
  serverErrors: any[] = [];

  constructor() {
    this.api = new TicketApi();
    makeAutoObservable(this, {
      rules: observable,
      errors: observable,
      warnings: observable,
      serverErrors: observable,
      displayError: computed,
      displayWarning: computed,
      clearServerErrors: action,
      onServerError: action,
      getRules: action,
      validateSystemAmount: action,
      validateFixAmount: action,
      validateAmount: action,
      validateEventsLength: action,
      validateFreeBetTicket: action,
      validateAntepostEvents: action,
      validateEvents: action,
      validateWinAmount: action,
      removeWarning: action,
      removeError: action,
    });
  }

  get displayError(): any | null {
    if (this.errors.length) {
      return this.errors[0].message;
    }

    return null;
  }

  get displayWarning(): string | null {
    if (this.warnings.length) {
      return this.warnings[0].message;
    }

    return null;
  }

  get minimumBettingAmount(): number {
    return this.getRuleValue(ValidationRule.MIN_AMOUNT) || 0;
  }

  get maximumBettingAmount(): number {
    return this.getRuleValue(ValidationRule.MAX_AMOUNT) || 0;
  }

  get maxWinAmount(): number {
    return this.getRuleValue(ValidationRule.MAX_WIN_AMOUNT) || 0;
  }

  get maxEventsSystem(): number | null {
    return this.getRuleValue(ValidationRule.MAX_EVENTS_SYSTEM);
  }

  get maxEventsFix(): number | null {
    return this.getRuleValue(ValidationRule.MAX_EVENTS);
  }

  get maxEventOdds(): number {
    return this.getRuleValue(ValidationRule.MAX_EVENT_ODDS) || 1;
  }

  clearServerErrors = () => {
    this.serverErrors = [];
  };

  onServerError = (messageList: any) => {
    this.serverErrors = messageList.map((error: string) => {
      const err = VALIDATION_REGEX.find((validation) =>
        error.match(validation.regex)
      );
      if (!err) return error;
      const match = error.match(err.regex);
      return i18next.t(
        err.messageKey,
        match ? { a: match[1], b: match[2] } : {}
      );
    });
  };

  getRules = async (onRulesFetched: () => void) => {
    try {
      const response = await this.api.getValidationRules();

      runInAction(() => {
        this.rules = this.formatRules(response.rules);
      });

      if (onRulesFetched) {
        onRulesFetched();
      }
    } catch (exception: any) {
      logger('TicketValidator -> getRules -> exception', exception);
    }
  };

  validateSystemAmount = (amount: number) => {
    this.removeError(ValidationRule.MIN_AMOUNT);
    this.removeError(ValidationRule.MAX_AMOUNT);

    const minCombinationAmount = this.getRuleValue(
      ValidationRule.MIN_COMBINATION_AMOUNT
    );

    if (+amount < minCombinationAmount) {
      this.errors.push({
        key: ValidationRule.MIN_COMBINATION_AMOUNT,
        message: {
          key: 'validations.min-amount-per-combination',
          values: { a: minCombinationAmount },
        },
      });
    } else {
      this.removeError(ValidationRule.MIN_COMBINATION_AMOUNT);
    }
  };

  validateFixAmount = (amount: number) => {
    this.removeError(ValidationRule.MIN_COMBINATION_AMOUNT);

    if (+amount < this.minimumBettingAmount) {
      this.errors.push({
        key: ValidationRule.MIN_AMOUNT,
        message: {
          key: 'validations.validation-min-payment',
          values: { minAmount: this.minimumBettingAmount },
        },
      });
    } else {
      this.removeError(ValidationRule.MIN_AMOUNT);
    }

    if (amount > this.maximumBettingAmount) {
      this.errors.push({
        key: ValidationRule.MAX_AMOUNT,
        message: {
          key: 'validations.max-bet-amount',
          values: { a: this.maximumBettingAmount },
        },
      });
    } else {
      this.removeError(ValidationRule.MAX_AMOUNT);
    }
  };

  validateAmount = (amount: number, wallet: WalletType, isSystem: boolean) => {
    const amountValue = amount;

    if (!isSystem) {
      this.validateFixAmount(amountValue);
    } else {
      this.validateSystemAmount(amountValue);
    }
  };

  validateEventsLength = (eventsLength: number, isSystem: boolean) => {
    this.removeError(ValidationRule.MAX_EVENTS);
    const maxEvents = isSystem ? this.maxEventsSystem : this.maxEventsFix;

    if (maxEvents && eventsLength > maxEvents) {
      this.errors.push({
        key: ValidationRule.MAX_EVENTS,
        message: {
          key: 'validations.max-number-of-events',
          values: { a: maxEvents },
        },
      });
    }
  };

  validateFreeBetTicket = (
    wallet: WalletType,
    eventsLength: number,
    totalOdd: number
  ) => {
    this.removeError(ValidationRule.FREE_BET_ERROR);

    if (wallet !== WalletTypes.PROMO && wallet !== WalletTypes.PROMO_BET_BONUS)
      return;

    const { minEvents, minTotalOdd } = FREE_BET_RULES;

    if (eventsLength < minEvents || totalOdd < minTotalOdd) {
      this.errors.push({
        key: ValidationRule.FREE_BET_ERROR,
        message: {
          key: 'validations.free-bet-error',
          values: { minEvents, minTotalOdd },
        },
      });
    }
  };

  validateAntepostEvents = (events: any[], isSystem: boolean) => {
    this.removeError(ValidationRule.SYSTEM_WITH_ANTEPOST);
    this.removeError(ValidationRule.MIXED_EVENT_TYPES);

    const hasAntepostEvents = events.some(
      (event: any) => event.type === 'antepost'
    );
    const hasOtherEvents =
      !!events.length && events.some((event: any) => event.type !== 'antepost');

    if (isSystem && hasAntepostEvents) {
      this.errors.push({
        key: ValidationRule.SYSTEM_WITH_ANTEPOST,
        message: { key: 'validations.validation-antepost-system' },
      });
    }

    if (hasAntepostEvents && hasOtherEvents) {
      this.errors.push({
        key: ValidationRule.MIXED_EVENT_TYPES,
        message: { key: 'validations.validation-mixed-event-types' },
      });
    }
  };

  validateEvents = (events: any, isSystem: boolean) => {
    this.validateEventsLength(events.length, isSystem);
    this.validateAntepostEvents(events, isSystem);
  };

  validateWinAmount = (winAmount: number, isSystem: boolean) => {
    this.removeWarning(ValidationRule.MAX_WIN_AMOUNT);
    if (isSystem) return;

    if (this.maxWinAmount <= winAmount) {
      this.warnings.push({
        key: ValidationRule.MAX_WIN_AMOUNT,
        message: {
          key: 'validations.validation-max-win',
          values: { a: this.maxWinAmount },
        },
      });
    } else {
      this.removeWarning(ValidationRule.MAX_WIN_AMOUNT);
    }
  };

  removeWarning = (key: string) => {
    this.warnings = this.warnings.filter((e) => e.key !== key);
  };

  removeError = (key: string) => {
    this.errors = this.errors.filter((e) => e.key !== key);
  };

  getRuleValue = (ruleName: string) => {
    if (this.rules) {
      return this.rules[ruleName];
    }
  };

  formatRules(rawRules: any) {
    const parsedData = rawRules ? parseJSONString(rawRules) : {};

    const parsedRules = parsedData ? parsedData : {};

    return Object.keys(parsedRules).reduce((rules: any, rule: any) => {
      const ruleKeys = VALIDATION_KEYS.find((v) => v.description === rule);
      if (!ruleKeys) return rules;

      const validation = parsedRules[rule];
      rules[ruleKeys.accessKey] = +validation[ruleKeys.key];
      return rules;
    }, {});
  }
}

export default new TicketValidator();
