/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Button from 'components/Button';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface MatchStatsProps {
  setFrameLoad?: () => void;
  betradarId: string;
}

const MatchStatistic = observer(
  ({ setFrameLoad, betradarId }: MatchStatsProps) => {
    const { overlayStore } = useStores();
    const { t } = useTranslation();

    const url = `https://s5.sir.sportradar.com/sportingbookmakers/me/match/${betradarId}`;

    const closeStatistic = () => {
      overlayStore.closeModal();
    };

    const [height, setHeight] = useState(0);
    useEffect(() => {
      setHeight(window.innerHeight - 60);
    }, []);

    return (
      <div>
        <div>
          <iframe
            id="sr-iframe"
            className="w-100"
            height={height}
            frameBorder="none"
            scrolling="yes"
            onLoad={() => setFrameLoad}
            src={url}
            title="event-tracker"
          />
        </div>
        <div className="w-100 text-center py-3 bg-black-500">
          <Button
            onClick={closeStatistic}
            bg="green"
            className="uppercase"
            testId="close-stats"
          >
            {t('globals.close')}
          </Button>
        </div>
      </div>
    );
  }
);

export default MatchStatistic;
