/* React modules */
import React from 'react';

/* Our modules */
import overlayStore from 'libs/overlay-store';
import { Accordion } from 'components';
import Icon from 'components/Icon';
import AppStore from '../../images/apple.svg';
import PlayStore from '../../images/google.svg';
import useStores from 'common/hooks/useStores';
import { useExternalLink } from 'libs/useExternalLink';
import './Nav.scss';

/* 3rd Party modules */
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

interface InnerRouteProps {
  label: string;
  route: string;
}

interface NavRoute {
  label: string;
  route: string;
  icon?: any;
  staticRoute?: string;
  innerRoutes?: Array<InnerRouteProps>;
  externalLink?: boolean;
}

interface NavProps {
  offerRoutes: NavRoute[];
  navigationRoutes?: NavRoute[];
}

const checkRoute = (staticRoute: undefined | string, route: string) => {
  if (staticRoute) {
    return {
      pathname: staticRoute,
      state: {
        innerPath: route,
      },
    };
  } else {
    return route;
  }
};

const ExternalLink = observer(({ label, route }: NavRoute) => {
  const { t } = useTranslation();
  const { sportsStore } = useStores();
  const { handleExternalLink } = useExternalLink();

  const onOpenExternalLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    overlayStore.closeDrawer();

    handleExternalLink(event, label, route);
  };

  return !(label && /poker/gi.test(label) && !sportsStore.isPokerEnabled) ? (
    <li data-testid="nav-route" className="nav__route">
      <a href="" onClick={onOpenExternalLink}>
        {t(label)}
      </a>
    </li>
  ) : null;
});

const RenderRoute = ({
  label,
  route,
  externalLink,
  icon,
  staticRoute,
  innerRoutes,
}: NavRoute) => {
  const { t } = useTranslation();

  if (externalLink) {
    return <ExternalLink label={label} route={route} />;
  } else if (!innerRoutes) {
    return (
      <li className="nav__route" data-testid="nav-route">
        <NavLink
          onClick={() => overlayStore.closeDrawer()}
          className={({ isActive }) => (isActive ? 'nav__route-active' : '')}
          to={checkRoute(staticRoute, route)}
        >
          <div className="d-flex space-between align-items">
            <span>{t(label)}</span>
            <Icon name={icon} size="regular" className="visible-desktop-down" />
          </div>
        </NavLink>
      </li>
    );
  } else {
    return (
      <li data-testid="nav-route accordion">
        <Accordion
          testId="staticMenu"
          headerProps={{
            label: label.toUpperCase(),
            size: 'small',
            hasPill: false,
            bg: 'primary-grey',
          }}
        >
          {innerRoutes.map((dropdown: any, index) => (
            <li
              key={`RenderRoute-li-3-${dropdown.label}-${index}`}
              className="nav__route nav__route-inner"
            >
              <NavLink
                onClick={() => overlayStore.closeDrawer()}
                className={({ isActive }) =>
                  isActive ? 'nav__route-active' : ''
                }
                to={checkRoute(staticRoute, dropdown.route)}
              >
                {dropdown.label}
              </NavLink>
            </li>
          ))}
        </Accordion>
      </li>
    );
  }
};

const Nav = ({ offerRoutes, navigationRoutes }: NavProps) => {
  const { t } = useTranslation();

  const toggleLogateChat = () => {
    overlayStore.closeDrawer();

    const currentWindow = window as any;

    if (currentWindow.LogateChat && currentWindow.LogateChat.showChat) {
      currentWindow.LogateChat.showChat();
    }
  };

  return (
    <div className="nav">
      <div>
        <div className="nav__title visible-desktop-down d-flex">
          <Icon name="offer" size="large" className="mr-2" />
          <span className="pt-3">{i18n.t('navigation.offer')}</span>
        </div>

        <ul className="nav__routes">
          {offerRoutes.map((item, index) => (
            <RenderRoute {...item} key={`Nav-RenderRoute-1-${index}`} />
          ))}
        </ul>
      </div>

      <div className="visible-desktop-down">
        <ul className="nav__routes">
          {navigationRoutes &&
            navigationRoutes.map((item, index) => (
              <RenderRoute {...item} key={`Nav-RenderRoute-2-${index}`} />
            ))}
        </ul>
      </div>

      <div className="visible-tablet-down nav__live-chat-wrapper">
        <ul className="nav__routes">
          <li
            onClick={toggleLogateChat}
            className="nav__route pl-10 uppercase nav__live-chat-wrapper__live-chat"
          >
            {t('globals.customer-support')}
          </li>
        </ul>
      </div>

      <div className="nav__download-links visible-desktop-down">
        <div className="nav__download-links__actions">
          <div className="nav__download-links__button">
            <a
              href="https://apps.apple.com/me/app/sbbet/id1589867471"
              className="welcome-popup-mobile__button"
            >
              <img
                src={AppStore}
                className="nav__download-links__button__img"
                alt=""
              />
            </a>
          </div>
          <div className="nav__download-links__button">
            <a
              href="https://sbbet-pbr.factoryww.com/static/mobile/apk/SBBet-APK.apk"
              download
              className="welcome-popup-mobile__button"
            >
              <img
                src={PlayStore}
                className="nav__download-links__button__img"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Nav };
