/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import Icon from 'components/Icon';
import TicketStatus from 'modules/ticket/ui/TicketStatus';
import TicketSidebar from 'modules/ticket/ui/TicketSidebar';
import { TicketPreviewContext } from 'pages/sport/Sport';
import './MobileFooter.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface MobileRouteProps {
  labelKey: string;
  route: string;
  icon: any;
}

interface MobileNavProps {
  routes: MobileRouteProps[];
}

const MobileFooterItem = ({ labelKey, route, icon }: MobileRouteProps) => {
  const { t } = useTranslation();

  return (
    <NavLink
      className={({ isActive }) =>
        'mobile-footer__item' + (isActive ? ' mobile-footer__active' : '')
      }
      to={route}
    >
      <Icon size="large" name={icon} />
      <span>{t(labelKey)}</span>
    </NavLink>
  );
};

const MobileFooter = ({ routes }: MobileNavProps) => {
  const { t } = useTranslation();
  const { overlayStore, ticketBuilder } = useStores();

  const openTicketPreview = () => {
    overlayStore.openModal(<TicketStatus />, {
      width: 'large',
      className: 'visibe-tablet-down',
      wrapClassName: 'ticket-preview-modal-wrap',
      name: 'ticket-preview',
    });
  };

  const openTicketDrawer = () => {
    overlayStore.openDrawer(<TicketSidebar isMobile={true} />, {
      className: 'sport__ticket-drawer',
      placement: 'bottom',
      height: '100%',
      width: '100vw',
    });
  };

  return (
    <div className="mobile-footer visible-mobile-down">
      <div className="mobile-footer__routes">
        {routes.map((route, index) => (
          <MobileFooterItem
            key={`MobileFooter-MobileFooterItem-${index}`}
            labelKey={route.labelKey}
            icon={route.icon}
            route={route.route}
          />
        ))}
        <TicketPreviewContext.Provider value={openTicketPreview}>
          <div
            className={classnames('mobile-footer__item', {
              'mobile-footer__active--ticket': ticketBuilder.eventsCount > 0,
            })}
            onClick={openTicketDrawer}
          >
            <Icon size="large" name="mobileTicket" />
            <span>{t('slips.ticket')}</span>
            <div className="mobile-footer__counter">
              {ticketBuilder.events.length > 0 && (
                <span>{ticketBuilder.eventsCount}</span>
              )}
            </div>
          </div>
        </TicketPreviewContext.Provider>
      </div>
    </div>
  );
};

export default observer(MobileFooter);
