/**
 * @fileoverview gRPC-Web generated client stub for odds_stream
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_odds_stream_odds_stream_pb from '../../proto/odds_stream/odds_stream_pb';


export class OddsStreamServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoWebEventsStream = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStream,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStream.deserializeBinary
  );

  webEventsStream(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/WebEventsStream',
      request,
      metadata || {},
      this.methodInfoWebEventsStream);
  }

  methodInfoWebEventsStreamOrdered = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStreamInt,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStreamInt.deserializeBinary
  );

  webEventsStreamOrdered(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/WebEventsStreamOrdered',
      request,
      metadata || {},
      this.methodInfoWebEventsStreamOrdered);
  }

  methodInfoWebEventsStreamTop = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStreamInt,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStreamInt.deserializeBinary
  );

  webEventsStreamTop(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/WebEventsStreamTop',
      request,
      metadata || {},
      this.methodInfoWebEventsStreamTop);
  }

  methodInfoWebEventsStreamRest = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStreamInt,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStreamInt.deserializeBinary
  );

  webEventsStreamRest(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/WebEventsStreamRest',
      request,
      metadata || {},
      this.methodInfoWebEventsStreamRest);
  }

  methodInfoAppEventsStreamTop = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStreamInt,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStreamInt.deserializeBinary
  );

  appEventsStreamTop(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/AppEventsStreamTop',
      request,
      metadata || {},
      this.methodInfoAppEventsStreamTop);
  }

  methodInfoAppEventsStreamRest = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStreamInt,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStreamInt.deserializeBinary
  );

  appEventsStreamRest(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/AppEventsStreamRest',
      request,
      metadata || {},
      this.methodInfoAppEventsStreamRest);
  }

  methodInfoUnaryStreamGetEvents = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.GetEventsResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.GetEventsResponse.deserializeBinary
  );

  unaryStreamGetEvents(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/UnaryStreamGetEvents',
      request,
      metadata || {},
      this.methodInfoUnaryStreamGetEvents);
  }

  methodInfoLiveEventsStream = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.GetEventsResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.GetEventsResponse.deserializeBinary
  );

  liveEventsStream(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/LiveEventsStream',
      request,
      metadata || {},
      this.methodInfoLiveEventsStream);
  }

  methodInfoWebEventsLiveStream = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStream,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStream.deserializeBinary
  );

  webEventsLiveStream(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/WebEventsLiveStream',
      request,
      metadata || {},
      this.methodInfoWebEventsLiveStream);
  }

  methodInfoWebEventsPrematchStream = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_stream_odds_stream_pb.EventStream,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_stream_odds_stream_pb.EventStream.deserializeBinary
  );

  webEventsPrematchStream(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/odds_stream.OddsStreamService/WebEventsPrematchStream',
      request,
      metadata || {},
      this.methodInfoWebEventsPrematchStream);
  }

}

