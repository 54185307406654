/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { currencyWithoutSymbol } from 'libs/currency-formatter';
import { isNumber } from 'libs/common-helpers';
import pik from 'images/jackpot-banners/amusnet/pik.png';
import herc from 'images/jackpot-banners/amusnet/herc.png';
import karo from 'images/jackpot-banners/amusnet/karo.png';
import tref from 'images/jackpot-banners/amusnet/tref.png';
import './AmusnetBanner.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

export const AmusnetBanner = observer(() => {
  const {
    feedStore: { subscribeAmusnetJackpot, unsubscribeAmusnetJackpot },
    gamblingStore: { amusnetJackpot, getAmusnetJackpotData },
  } = useStores();

  useEffect(() => {
    async function fetchAmusnetJackpotData() {
      await getAmusnetJackpotData();
    }

    fetchAmusnetJackpotData();

    subscribeAmusnetJackpot();

    return () => {
      unsubscribeAmusnetJackpot();
    };
  }, []);

  return (
    <div className="amusnet-banner pb-4">
      <div className="h-30 scalable-image jackpot-cards-logo" />

      <div className="h-62">
        <div className="h-50 xy-center text-yellow-500">
          <div className="h-100 xy-center">
            <img src={pik} alt="pik" className="h-65 current-level-img" />

            {isNumber(amusnetJackpot.currentLevelIV) ? (
              <span className="bg-black-0 current-level-amount">
                {currencyWithoutSymbol(amusnetJackpot.currentLevelIV / 100)}
                &nbsp;&euro;
              </span>
            ) : (
              '-'
            )}
          </div>
        </div>

        <div className="h-45 d-flex boxes-2nd-line">
          <div className="w-33 h-100 xy-center text-yellow-500">
            <div className="h-100 xy-center">
              <img src={herc} alt="herc" className="h-65 current-level-img" />

              {isNumber(amusnetJackpot.currentLevelIII) ? (
                <span className="bg-black-0 current-level-amount">
                  {currencyWithoutSymbol(amusnetJackpot.currentLevelIII / 100)}
                  &nbsp;&euro;
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>

          <div className="w-33 h-100 xy-center text-yellow-500">
            <div className="h-100 xy-center">
              <img src={karo} alt="karo" className="h-65 current-level-img" />

              {isNumber(amusnetJackpot.currentLevelII) ? (
                <span className="bg-black-0 current-level-amount">
                  {currencyWithoutSymbol(amusnetJackpot.currentLevelII / 100)}
                  &nbsp;&euro;
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>

          <div className="w-33 h-100 xy-center text-yellow-500">
            <div className="h-100 xy-center">
              <img src={tref} alt="tref" className="h-65 current-level-img" />

              {isNumber(amusnetJackpot.currentLevelI) ? (
                <span className="bg-black-0 current-level-amount">
                  {currencyWithoutSymbol(amusnetJackpot.currentLevelI / 100)}
                  &nbsp;&euro;
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="h-8 scalable-image amusnet-logo" />
    </div>
  );
});
