/* React modules */

/* Our modules */
import Icon from 'components/Icon';
import './ButtonGroup.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

export interface Option {
  label?: string;
  value: number;
  icon?: string;
  tooltip?: string;
}

type ButtonGroupSize = 'regular' | 'small';

export interface ButtonGroupProps {
  options: Option[];
  selected: string;
  testId: string;
  size?: ButtonGroupSize;
  onSelect: (arg: any) => void;
  type?: string;
}

const ButtonGroup = ({
  options,
  selected,
  onSelect,
  testId,
  size,
  type,
}: ButtonGroupProps) => (
  <div className="mr-1 btn-group-wrapper" data-testid={testId}>
    <div className={`btn-group ${size} btn-group__${type}`}>
      {options.map((option, i) =>
        option.tooltip ? (
          <Tooltip
            key={`ButtonGroup-Tooltip-${i}`}
            placement="bottom"
            overlay={option.tooltip}
            overlayClassName="tooltip"
          >
            <button
              data-testid={`${testId}-${i}`}
              className={`${selected === String(option.value) ? 'active' : ''}`}
              onClick={() => onSelect(option.value)}
            >
              {option.icon ? (
                <Icon name={option.icon as any} size={size} variant="light" />
              ) : (
                option.label
              )}
            </button>
          </Tooltip>
        ) : (
          <button
            key={`ButtonGroup-button-${i}`}
            data-testid={`${testId}-${i}`}
            className={`${selected === String(option.value) ? 'active' : ''}`}
            onClick={() => onSelect(option.value)}
          >
            {option.icon ? (
              <Icon name={option.icon as any} size={size} variant="light" />
            ) : (
              option.label
            )}
          </button>
        )
      )}
    </div>

    <div
      className={classNames(
        'btn-group__active',
        'd-flex',
        'justify-center',
        'align-items',
        {
          'btn-group__active-left': selected === String(options[0].value),
          'btn-group__active-right': selected === String(options[1].value),
        }
      )}
    ></div>
  </div>
);

ButtonGroup.defaultProps = {
  size: 'regular',
  type: 'text',
};

export default ButtonGroup;
