// source: proto/loyalty/loyalty.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
goog.exportSymbol('proto.loyalty.DisableLoyaltyMessage', null, global);
goog.exportSymbol('proto.loyalty.DisableUserLoyaltyMessage', null, global);
goog.exportSymbol('proto.loyalty.ExpireAt', null, global);
goog.exportSymbol('proto.loyalty.GetOrdersBORequest', null, global);
goog.exportSymbol('proto.loyalty.GetOrdersRequest', null, global);
goog.exportSymbol('proto.loyalty.GetUsersRequest', null, global);
goog.exportSymbol('proto.loyalty.LevelCount', null, global);
goog.exportSymbol('proto.loyalty.LevelLimits', null, global);
goog.exportSymbol('proto.loyalty.LoyaltyConfig', null, global);
goog.exportSymbol('proto.loyalty.Order', null, global);
goog.exportSymbol('proto.loyalty.OrderItem', null, global);
goog.exportSymbol('proto.loyalty.Orders', null, global);
goog.exportSymbol('proto.loyalty.StoreItem', null, global);
goog.exportSymbol('proto.loyalty.StoreItemOrdering', null, global);
goog.exportSymbol('proto.loyalty.StoreItems', null, global);
goog.exportSymbol('proto.loyalty.Transaction', null, global);
goog.exportSymbol('proto.loyalty.Transactions', null, global);
goog.exportSymbol('proto.loyalty.UserLoyalty', null, global);
goog.exportSymbol('proto.loyalty.UserTransactionsRequest', null, global);
goog.exportSymbol('proto.loyalty.Users', null, global);
goog.exportSymbol('proto.loyalty.WebLoyaltyConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.GetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.GetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.GetUsersRequest.displayName = 'proto.loyalty.GetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.UserTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.UserTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.UserTransactionsRequest.displayName = 'proto.loyalty.UserTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.Transactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loyalty.Transactions.repeatedFields_, null);
};
goog.inherits(proto.loyalty.Transactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.Transactions.displayName = 'proto.loyalty.Transactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.Transaction.displayName = 'proto.loyalty.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loyalty.Users.repeatedFields_, null);
};
goog.inherits(proto.loyalty.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.Users.displayName = 'proto.loyalty.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.LevelCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.LevelCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.LevelCount.displayName = 'proto.loyalty.LevelCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.DisableLoyaltyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.DisableLoyaltyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.DisableLoyaltyMessage.displayName = 'proto.loyalty.DisableLoyaltyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.DisableUserLoyaltyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.DisableUserLoyaltyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.DisableUserLoyaltyMessage.displayName = 'proto.loyalty.DisableUserLoyaltyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.GetOrdersBORequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.GetOrdersBORequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.GetOrdersBORequest.displayName = 'proto.loyalty.GetOrdersBORequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.StoreItemOrdering = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.StoreItemOrdering, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.StoreItemOrdering.displayName = 'proto.loyalty.StoreItemOrdering';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.GetOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.GetOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.GetOrdersRequest.displayName = 'proto.loyalty.GetOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.StoreItems = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loyalty.StoreItems.repeatedFields_, null);
};
goog.inherits(proto.loyalty.StoreItems, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.StoreItems.displayName = 'proto.loyalty.StoreItems';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.StoreItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.StoreItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.StoreItem.displayName = 'proto.loyalty.StoreItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.Orders = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loyalty.Orders.repeatedFields_, null);
};
goog.inherits(proto.loyalty.Orders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.Orders.displayName = 'proto.loyalty.Orders';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loyalty.Order.repeatedFields_, null);
};
goog.inherits(proto.loyalty.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.Order.displayName = 'proto.loyalty.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.OrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.OrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.OrderItem.displayName = 'proto.loyalty.OrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.WebLoyaltyConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.WebLoyaltyConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.WebLoyaltyConfig.displayName = 'proto.loyalty.WebLoyaltyConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.LoyaltyConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.LoyaltyConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.LoyaltyConfig.displayName = 'proto.loyalty.LoyaltyConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.LevelLimits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.LevelLimits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.LevelLimits.displayName = 'proto.loyalty.LevelLimits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.UserLoyalty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.UserLoyalty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.UserLoyalty.displayName = 'proto.loyalty.UserLoyalty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loyalty.ExpireAt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loyalty.ExpireAt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loyalty.ExpireAt.displayName = 'proto.loyalty.ExpireAt';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.GetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.GetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.GetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.GetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    loyaltyLevel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.GetUsersRequest}
 */
proto.loyalty.GetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.GetUsersRequest;
  return proto.loyalty.GetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.GetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.GetUsersRequest}
 */
proto.loyalty.GetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoyaltyLevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.GetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.GetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.GetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.GetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLoyaltyLevel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.loyalty.GetUsersRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetUsersRequest} returns this
 */
proto.loyalty.GetUsersRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string loyalty_level = 2;
 * @return {string}
 */
proto.loyalty.GetUsersRequest.prototype.getLoyaltyLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.GetUsersRequest} returns this
 */
proto.loyalty.GetUsersRequest.prototype.setLoyaltyLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 offset = 4;
 * @return {number}
 */
proto.loyalty.GetUsersRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetUsersRequest} returns this
 */
proto.loyalty.GetUsersRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.UserTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.UserTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.UserTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.UserTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transactionType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loyaltyType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    start: jspb.Message.getFieldWithDefault(msg, 4, 0),
    end: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.UserTransactionsRequest}
 */
proto.loyalty.UserTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.UserTransactionsRequest;
  return proto.loyalty.UserTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.UserTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.UserTransactionsRequest}
 */
proto.loyalty.UserTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoyaltyType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.UserTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.UserTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.UserTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.UserTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTransactionType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoyaltyType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.loyalty.UserTransactionsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.UserTransactionsRequest} returns this
 */
proto.loyalty.UserTransactionsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string transaction_type = 2;
 * @return {string}
 */
proto.loyalty.UserTransactionsRequest.prototype.getTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.UserTransactionsRequest} returns this
 */
proto.loyalty.UserTransactionsRequest.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loyalty_type = 3;
 * @return {string}
 */
proto.loyalty.UserTransactionsRequest.prototype.getLoyaltyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.UserTransactionsRequest} returns this
 */
proto.loyalty.UserTransactionsRequest.prototype.setLoyaltyType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 start = 4;
 * @return {number}
 */
proto.loyalty.UserTransactionsRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.UserTransactionsRequest} returns this
 */
proto.loyalty.UserTransactionsRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 end = 5;
 * @return {number}
 */
proto.loyalty.UserTransactionsRequest.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.UserTransactionsRequest} returns this
 */
proto.loyalty.UserTransactionsRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 offset = 6;
 * @return {number}
 */
proto.loyalty.UserTransactionsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.UserTransactionsRequest} returns this
 */
proto.loyalty.UserTransactionsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loyalty.Transactions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.Transactions.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.Transactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.Transactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Transactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.loyalty.Transaction.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.Transactions}
 */
proto.loyalty.Transactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.Transactions;
  return proto.loyalty.Transactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.Transactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.Transactions}
 */
proto.loyalty.Transactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loyalty.Transaction;
      reader.readMessage(value,proto.loyalty.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.Transactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.Transactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.Transactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Transactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loyalty.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.loyalty.Transaction>}
 */
proto.loyalty.Transactions.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.loyalty.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loyalty.Transaction, 1));
};


/**
 * @param {!Array<!proto.loyalty.Transaction>} value
 * @return {!proto.loyalty.Transactions} returns this
*/
proto.loyalty.Transactions.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loyalty.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loyalty.Transaction}
 */
proto.loyalty.Transactions.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loyalty.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loyalty.Transactions} returns this
 */
proto.loyalty.Transactions.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.loyalty.Transactions.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.Transactions} returns this
*/
proto.loyalty.Transactions.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.Transactions} returns this
 */
proto.loyalty.Transactions.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.Transactions.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    points: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    transactionType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loyaltyType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.Transaction}
 */
proto.loyalty.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.Transaction;
  return proto.loyalty.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.Transaction}
 */
proto.loyalty.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoyaltyType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTransactionType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoyaltyType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loyalty.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.Transaction} returns this
 */
proto.loyalty.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double points = 2;
 * @return {number}
 */
proto.loyalty.Transaction.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Transaction} returns this
 */
proto.loyalty.Transaction.prototype.setPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 created_at = 3;
 * @return {number}
 */
proto.loyalty.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Transaction} returns this
 */
proto.loyalty.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string transaction_type = 4;
 * @return {string}
 */
proto.loyalty.Transaction.prototype.getTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.Transaction} returns this
 */
proto.loyalty.Transaction.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string loyalty_type = 5;
 * @return {string}
 */
proto.loyalty.Transaction.prototype.getLoyaltyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.Transaction} returns this
 */
proto.loyalty.Transaction.prototype.setLoyaltyType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 expires_at = 6;
 * @return {number}
 */
proto.loyalty.Transaction.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Transaction} returns this
 */
proto.loyalty.Transaction.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loyalty.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.loyalty.UserLoyalty.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.Users}
 */
proto.loyalty.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.Users;
  return proto.loyalty.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.Users}
 */
proto.loyalty.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loyalty.UserLoyalty;
      reader.readMessage(value,proto.loyalty.UserLoyalty.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loyalty.UserLoyalty.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserLoyalty users = 1;
 * @return {!Array<!proto.loyalty.UserLoyalty>}
 */
proto.loyalty.Users.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.loyalty.UserLoyalty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loyalty.UserLoyalty, 1));
};


/**
 * @param {!Array<!proto.loyalty.UserLoyalty>} value
 * @return {!proto.loyalty.Users} returns this
*/
proto.loyalty.Users.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loyalty.UserLoyalty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loyalty.UserLoyalty}
 */
proto.loyalty.Users.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loyalty.UserLoyalty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loyalty.Users} returns this
 */
proto.loyalty.Users.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.loyalty.Users.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.Users} returns this
*/
proto.loyalty.Users.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.Users} returns this
 */
proto.loyalty.Users.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.Users.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.LevelCount.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.LevelCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.LevelCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.LevelCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelsMap: (f = msg.getLevelsMap()) ? f.toObject(includeInstance, undefined) : [],
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.LevelCount}
 */
proto.loyalty.LevelCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.LevelCount;
  return proto.loyalty.LevelCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.LevelCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.LevelCount}
 */
proto.loyalty.LevelCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLevelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "", 0);
         });
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.LevelCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.LevelCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.LevelCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.LevelCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, int32> levels = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loyalty.LevelCount.prototype.getLevelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.LevelCount} returns this
 */
proto.loyalty.LevelCount.prototype.clearLevelsMap = function() {
  this.getLevelsMap().clear();
  return this;};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.loyalty.LevelCount.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.LevelCount} returns this
*/
proto.loyalty.LevelCount.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.LevelCount} returns this
 */
proto.loyalty.LevelCount.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.LevelCount.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.DisableLoyaltyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.DisableLoyaltyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.DisableLoyaltyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.DisableLoyaltyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: jspb.Message.getFieldWithDefault(msg, 1, ""),
    disableType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.DisableLoyaltyMessage}
 */
proto.loyalty.DisableLoyaltyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.DisableLoyaltyMessage;
  return proto.loyalty.DisableLoyaltyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.DisableLoyaltyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.DisableLoyaltyMessage}
 */
proto.loyalty.DisableLoyaltyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisableType(value);
      break;
    case 3:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.DisableLoyaltyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.DisableLoyaltyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.DisableLoyaltyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.DisableLoyaltyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisabled();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisableType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string disabled = 1;
 * @return {string}
 */
proto.loyalty.DisableLoyaltyMessage.prototype.getDisabled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.DisableLoyaltyMessage} returns this
 */
proto.loyalty.DisableLoyaltyMessage.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string disable_type = 2;
 * @return {string}
 */
proto.loyalty.DisableLoyaltyMessage.prototype.getDisableType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.DisableLoyaltyMessage} returns this
 */
proto.loyalty.DisableLoyaltyMessage.prototype.setDisableType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Error error = 3;
 * @return {?proto.common.Error}
 */
proto.loyalty.DisableLoyaltyMessage.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 3));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.DisableLoyaltyMessage} returns this
*/
proto.loyalty.DisableLoyaltyMessage.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.DisableLoyaltyMessage} returns this
 */
proto.loyalty.DisableLoyaltyMessage.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.DisableLoyaltyMessage.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.DisableUserLoyaltyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.DisableUserLoyaltyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.DisableUserLoyaltyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    disabled: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.DisableUserLoyaltyMessage}
 */
proto.loyalty.DisableUserLoyaltyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.DisableUserLoyaltyMessage;
  return proto.loyalty.DisableUserLoyaltyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.DisableUserLoyaltyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.DisableUserLoyaltyMessage}
 */
proto.loyalty.DisableUserLoyaltyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabled(value);
      break;
    case 5:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.DisableUserLoyaltyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.DisableUserLoyaltyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.DisableUserLoyaltyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisabled();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.DisableUserLoyaltyMessage} returns this
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string disabled = 2;
 * @return {string}
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.getDisabled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.DisableUserLoyaltyMessage} returns this
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Error error = 5;
 * @return {?proto.common.Error}
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 5));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.DisableUserLoyaltyMessage} returns this
*/
proto.loyalty.DisableUserLoyaltyMessage.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.DisableUserLoyaltyMessage} returns this
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.DisableUserLoyaltyMessage.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.GetOrdersBORequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.GetOrdersBORequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.GetOrdersBORequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.GetOrdersBORequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bp: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.GetOrdersBORequest}
 */
proto.loyalty.GetOrdersBORequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.GetOrdersBORequest;
  return proto.loyalty.GetOrdersBORequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.GetOrdersBORequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.GetOrdersBORequest}
 */
proto.loyalty.GetOrdersBORequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.GetOrdersBORequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.GetOrdersBORequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.GetOrdersBORequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.GetOrdersBORequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.loyalty.GetOrdersBORequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetOrdersBORequest} returns this
 */
proto.loyalty.GetOrdersBORequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 betting_place_id = 2;
 * @return {number}
 */
proto.loyalty.GetOrdersBORequest.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetOrdersBORequest} returns this
 */
proto.loyalty.GetOrdersBORequest.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.loyalty.GetOrdersBORequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.GetOrdersBORequest} returns this
 */
proto.loyalty.GetOrdersBORequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 offset = 5;
 * @return {number}
 */
proto.loyalty.GetOrdersBORequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetOrdersBORequest} returns this
 */
proto.loyalty.GetOrdersBORequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string bp = 4;
 * @return {string}
 */
proto.loyalty.GetOrdersBORequest.prototype.getBp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.GetOrdersBORequest} returns this
 */
proto.loyalty.GetOrdersBORequest.prototype.setBp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.StoreItemOrdering.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.StoreItemOrdering.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.StoreItemOrdering} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.StoreItemOrdering.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.StoreItemOrdering}
 */
proto.loyalty.StoreItemOrdering.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.StoreItemOrdering;
  return proto.loyalty.StoreItemOrdering.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.StoreItemOrdering} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.StoreItemOrdering}
 */
proto.loyalty.StoreItemOrdering.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.StoreItemOrdering.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.StoreItemOrdering.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.StoreItemOrdering} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.StoreItemOrdering.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * map<string, int32> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loyalty.StoreItemOrdering.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.StoreItemOrdering} returns this
 */
proto.loyalty.StoreItemOrdering.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.GetOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.GetOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.GetOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.GetOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    end: jspb.Message.getFieldWithDefault(msg, 3, 0),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.GetOrdersRequest}
 */
proto.loyalty.GetOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.GetOrdersRequest;
  return proto.loyalty.GetOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.GetOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.GetOrdersRequest}
 */
proto.loyalty.GetOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.GetOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.GetOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.GetOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.GetOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 offset = 1;
 * @return {number}
 */
proto.loyalty.GetOrdersRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetOrdersRequest} returns this
 */
proto.loyalty.GetOrdersRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 start = 2;
 * @return {number}
 */
proto.loyalty.GetOrdersRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetOrdersRequest} returns this
 */
proto.loyalty.GetOrdersRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 end = 3;
 * @return {number}
 */
proto.loyalty.GetOrdersRequest.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.GetOrdersRequest} returns this
 */
proto.loyalty.GetOrdersRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.loyalty.GetOrdersRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.GetOrdersRequest} returns this
 */
proto.loyalty.GetOrdersRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.loyalty.GetOrdersRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.GetOrdersRequest} returns this
 */
proto.loyalty.GetOrdersRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loyalty.StoreItems.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.StoreItems.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.StoreItems.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.StoreItems} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.StoreItems.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.loyalty.StoreItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.StoreItems}
 */
proto.loyalty.StoreItems.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.StoreItems;
  return proto.loyalty.StoreItems.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.StoreItems} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.StoreItems}
 */
proto.loyalty.StoreItems.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loyalty.StoreItem;
      reader.readMessage(value,proto.loyalty.StoreItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.StoreItems.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.StoreItems.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.StoreItems} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.StoreItems.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loyalty.StoreItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StoreItem items = 1;
 * @return {!Array<!proto.loyalty.StoreItem>}
 */
proto.loyalty.StoreItems.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loyalty.StoreItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loyalty.StoreItem, 1));
};


/**
 * @param {!Array<!proto.loyalty.StoreItem>} value
 * @return {!proto.loyalty.StoreItems} returns this
*/
proto.loyalty.StoreItems.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loyalty.StoreItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loyalty.StoreItem}
 */
proto.loyalty.StoreItems.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loyalty.StoreItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loyalty.StoreItems} returns this
 */
proto.loyalty.StoreItems.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.StoreItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.StoreItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.StoreItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.StoreItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reserved: jspb.Message.getFieldWithDefault(msg, 7, 0),
    digital: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    digitalType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    digitalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    order: jspb.Message.getFieldWithDefault(msg, 12, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.StoreItem}
 */
proto.loyalty.StoreItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.StoreItem;
  return proto.loyalty.StoreItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.StoreItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.StoreItem}
 */
proto.loyalty.StoreItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReserved(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDigital(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigitalType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDigitalAmount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 13:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.StoreItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.StoreItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.StoreItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.StoreItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getReserved();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDigital();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDigitalType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDigitalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.loyalty.StoreItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loyalty.StoreItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loyalty.StoreItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.loyalty.StoreItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.loyalty.StoreItem.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 quantity = 6;
 * @return {number}
 */
proto.loyalty.StoreItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 reserved = 7;
 * @return {number}
 */
proto.loyalty.StoreItem.prototype.getReserved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setReserved = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool digital = 8;
 * @return {boolean}
 */
proto.loyalty.StoreItem.prototype.getDigital = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setDigital = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string digital_type = 9;
 * @return {string}
 */
proto.loyalty.StoreItem.prototype.getDigitalType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setDigitalType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double digital_amount = 10;
 * @return {number}
 */
proto.loyalty.StoreItem.prototype.getDigitalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setDigitalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional bool is_active = 11;
 * @return {boolean}
 */
proto.loyalty.StoreItem.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 order = 12;
 * @return {number}
 */
proto.loyalty.StoreItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional common.Error error = 13;
 * @return {?proto.common.Error}
 */
proto.loyalty.StoreItem.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 13));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.StoreItem} returns this
*/
proto.loyalty.StoreItem.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.StoreItem} returns this
 */
proto.loyalty.StoreItem.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.StoreItem.prototype.hasError = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loyalty.Orders.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.Orders.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.Orders.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.Orders} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Orders.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.loyalty.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.Orders}
 */
proto.loyalty.Orders.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.Orders;
  return proto.loyalty.Orders.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.Orders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.Orders}
 */
proto.loyalty.Orders.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loyalty.Order;
      reader.readMessage(value,proto.loyalty.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.Orders.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.Orders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.Orders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Orders.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loyalty.Order.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Order orders = 1;
 * @return {!Array<!proto.loyalty.Order>}
 */
proto.loyalty.Orders.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.loyalty.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loyalty.Order, 1));
};


/**
 * @param {!Array<!proto.loyalty.Order>} value
 * @return {!proto.loyalty.Orders} returns this
*/
proto.loyalty.Orders.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loyalty.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loyalty.Order}
 */
proto.loyalty.Orders.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loyalty.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loyalty.Orders} returns this
 */
proto.loyalty.Orders.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loyalty.Order.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    points: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.loyalty.OrderItem.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.Order}
 */
proto.loyalty.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.Order;
  return proto.loyalty.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.Order}
 */
proto.loyalty.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPoints(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.loyalty.OrderItem;
      reader.readMessage(value,proto.loyalty.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 8:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoints();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loyalty.OrderItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional double points = 1;
 * @return {number}
 */
proto.loyalty.Order.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.loyalty.Order.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.loyalty.Order.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 user_id = 9;
 * @return {number}
 */
proto.loyalty.Order.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated OrderItem items = 4;
 * @return {!Array<!proto.loyalty.OrderItem>}
 */
proto.loyalty.Order.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loyalty.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loyalty.OrderItem, 4));
};


/**
 * @param {!Array<!proto.loyalty.OrderItem>} value
 * @return {!proto.loyalty.Order} returns this
*/
proto.loyalty.Order.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loyalty.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loyalty.OrderItem}
 */
proto.loyalty.Order.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loyalty.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int32 created_at = 5;
 * @return {number}
 */
proto.loyalty.Order.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.loyalty.Order.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 betting_place_id = 7;
 * @return {number}
 */
proto.loyalty.Order.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional common.Error error = 8;
 * @return {?proto.common.Error}
 */
proto.loyalty.Order.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 8));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.Order} returns this
*/
proto.loyalty.Order.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.Order} returns this
 */
proto.loyalty.Order.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.Order.prototype.hasError = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.OrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.OrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.OrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.OrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.loyalty.StoreItem.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.OrderItem}
 */
proto.loyalty.OrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.OrderItem;
  return proto.loyalty.OrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.OrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.OrderItem}
 */
proto.loyalty.OrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loyalty.StoreItem;
      reader.readMessage(value,proto.loyalty.StoreItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.OrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.OrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.OrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.OrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loyalty.StoreItem.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional StoreItem item = 1;
 * @return {?proto.loyalty.StoreItem}
 */
proto.loyalty.OrderItem.prototype.getItem = function() {
  return /** @type{?proto.loyalty.StoreItem} */ (
    jspb.Message.getWrapperField(this, proto.loyalty.StoreItem, 1));
};


/**
 * @param {?proto.loyalty.StoreItem|undefined} value
 * @return {!proto.loyalty.OrderItem} returns this
*/
proto.loyalty.OrderItem.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.OrderItem} returns this
 */
proto.loyalty.OrderItem.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.OrderItem.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.loyalty.OrderItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.OrderItem} returns this
 */
proto.loyalty.OrderItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.WebLoyaltyConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.WebLoyaltyConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.WebLoyaltyConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.WebLoyaltyConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelUpMapMap: (f = msg.getLevelUpMapMap()) ? f.toObject(includeInstance, proto.loyalty.LevelLimits.toObject) : [],
    disabledPoints: jspb.Message.getFieldWithDefault(msg, 6, ""),
    disabledMarket: jspb.Message.getFieldWithDefault(msg, 7, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.WebLoyaltyConfig}
 */
proto.loyalty.WebLoyaltyConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.WebLoyaltyConfig;
  return proto.loyalty.WebLoyaltyConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.WebLoyaltyConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.WebLoyaltyConfig}
 */
proto.loyalty.WebLoyaltyConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = msg.getLevelUpMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loyalty.LevelLimits.deserializeBinaryFromReader, "", new proto.loyalty.LevelLimits());
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledPoints(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledMarket(value);
      break;
    case 8:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.WebLoyaltyConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.WebLoyaltyConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.WebLoyaltyConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.WebLoyaltyConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelUpMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loyalty.LevelLimits.serializeBinaryToWriter);
  }
  f = message.getDisabledPoints();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisabledMarket();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, LevelLimits> level_up_map = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loyalty.LevelLimits>}
 */
proto.loyalty.WebLoyaltyConfig.prototype.getLevelUpMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loyalty.LevelLimits>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.loyalty.LevelLimits));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.WebLoyaltyConfig} returns this
 */
proto.loyalty.WebLoyaltyConfig.prototype.clearLevelUpMapMap = function() {
  this.getLevelUpMapMap().clear();
  return this;};


/**
 * optional string disabled_points = 6;
 * @return {string}
 */
proto.loyalty.WebLoyaltyConfig.prototype.getDisabledPoints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.WebLoyaltyConfig} returns this
 */
proto.loyalty.WebLoyaltyConfig.prototype.setDisabledPoints = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string disabled_market = 7;
 * @return {string}
 */
proto.loyalty.WebLoyaltyConfig.prototype.getDisabledMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.WebLoyaltyConfig} returns this
 */
proto.loyalty.WebLoyaltyConfig.prototype.setDisabledMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional common.Error error = 8;
 * @return {?proto.common.Error}
 */
proto.loyalty.WebLoyaltyConfig.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 8));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.WebLoyaltyConfig} returns this
*/
proto.loyalty.WebLoyaltyConfig.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.WebLoyaltyConfig} returns this
 */
proto.loyalty.WebLoyaltyConfig.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.WebLoyaltyConfig.prototype.hasError = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.LoyaltyConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.LoyaltyConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.LoyaltyConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.LoyaltyConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipCoefficientMapMap: (f = msg.getSlipCoefficientMapMap()) ? f.toObject(includeInstance, undefined) : [],
    casinoCoefficient: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slipLevelMapMap: (f = msg.getSlipLevelMapMap()) ? f.toObject(includeInstance, undefined) : [],
    casinoLevelMapMap: (f = msg.getCasinoLevelMapMap()) ? f.toObject(includeInstance, undefined) : [],
    levelUpMapMap: (f = msg.getLevelUpMapMap()) ? f.toObject(includeInstance, proto.loyalty.LevelLimits.toObject) : [],
    disabledPoints: jspb.Message.getFieldWithDefault(msg, 6, ""),
    disabledMarket: jspb.Message.getFieldWithDefault(msg, 7, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.LoyaltyConfig}
 */
proto.loyalty.LoyaltyConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.LoyaltyConfig;
  return proto.loyalty.LoyaltyConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.LoyaltyConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.LoyaltyConfig}
 */
proto.loyalty.LoyaltyConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSlipCoefficientMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCasinoCoefficient(value);
      break;
    case 3:
      var value = msg.getSlipLevelMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = msg.getCasinoLevelMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = msg.getLevelUpMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loyalty.LevelLimits.deserializeBinaryFromReader, "", new proto.loyalty.LevelLimits());
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledPoints(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledMarket(value);
      break;
    case 8:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.LoyaltyConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.LoyaltyConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.LoyaltyConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.LoyaltyConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipCoefficientMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCasinoCoefficient();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlipLevelMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCasinoLevelMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLevelUpMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loyalty.LevelLimits.serializeBinaryToWriter);
  }
  f = message.getDisabledPoints();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisabledMarket();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, string> slip_coefficient_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loyalty.LoyaltyConfig.prototype.getSlipCoefficientMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.clearSlipCoefficientMapMap = function() {
  this.getSlipCoefficientMapMap().clear();
  return this;};


/**
 * optional string casino_coefficient = 2;
 * @return {string}
 */
proto.loyalty.LoyaltyConfig.prototype.getCasinoCoefficient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.setCasinoCoefficient = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> slip_level_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loyalty.LoyaltyConfig.prototype.getSlipLevelMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.clearSlipLevelMapMap = function() {
  this.getSlipLevelMapMap().clear();
  return this;};


/**
 * map<string, string> casino_level_map = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loyalty.LoyaltyConfig.prototype.getCasinoLevelMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.clearCasinoLevelMapMap = function() {
  this.getCasinoLevelMapMap().clear();
  return this;};


/**
 * map<string, LevelLimits> level_up_map = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loyalty.LevelLimits>}
 */
proto.loyalty.LoyaltyConfig.prototype.getLevelUpMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loyalty.LevelLimits>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.loyalty.LevelLimits));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.clearLevelUpMapMap = function() {
  this.getLevelUpMapMap().clear();
  return this;};


/**
 * optional string disabled_points = 6;
 * @return {string}
 */
proto.loyalty.LoyaltyConfig.prototype.getDisabledPoints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.setDisabledPoints = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string disabled_market = 7;
 * @return {string}
 */
proto.loyalty.LoyaltyConfig.prototype.getDisabledMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.setDisabledMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional common.Error error = 8;
 * @return {?proto.common.Error}
 */
proto.loyalty.LoyaltyConfig.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 8));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.LoyaltyConfig} returns this
*/
proto.loyalty.LoyaltyConfig.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.LoyaltyConfig} returns this
 */
proto.loyalty.LoyaltyConfig.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.LoyaltyConfig.prototype.hasError = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.LevelLimits.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.LevelLimits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.LevelLimits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.LevelLimits.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelStay: jspb.Message.getFieldWithDefault(msg, 1, ""),
    levelUp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.LevelLimits}
 */
proto.loyalty.LevelLimits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.LevelLimits;
  return proto.loyalty.LevelLimits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.LevelLimits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.LevelLimits}
 */
proto.loyalty.LevelLimits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevelStay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevelUp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.LevelLimits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.LevelLimits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.LevelLimits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.LevelLimits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelStay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLevelUp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string level_stay = 1;
 * @return {string}
 */
proto.loyalty.LevelLimits.prototype.getLevelStay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.LevelLimits} returns this
 */
proto.loyalty.LevelLimits.prototype.setLevelStay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string level_up = 2;
 * @return {string}
 */
proto.loyalty.LevelLimits.prototype.getLevelUp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.LevelLimits} returns this
 */
proto.loyalty.LevelLimits.prototype.setLevelUp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.UserLoyalty.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.UserLoyalty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.UserLoyalty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.UserLoyalty.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    points: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    levelPoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    loyaltyLevel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    order: (f = msg.getOrder()) && proto.loyalty.Order.toObject(includeInstance, f),
    expireNext: (f = msg.getExpireNext()) && proto.loyalty.ExpireAt.toObject(includeInstance, f),
    username: jspb.Message.getFieldWithDefault(msg, 8, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.UserLoyalty}
 */
proto.loyalty.UserLoyalty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.UserLoyalty;
  return proto.loyalty.UserLoyalty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.UserLoyalty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.UserLoyalty}
 */
proto.loyalty.UserLoyalty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLevelPoints(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoyaltyLevel(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserDisabled(value);
      break;
    case 6:
      var value = new proto.loyalty.Order;
      reader.readMessage(value,proto.loyalty.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 7:
      var value = new proto.loyalty.ExpireAt;
      reader.readMessage(value,proto.loyalty.ExpireAt.deserializeBinaryFromReader);
      msg.setExpireNext(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.UserLoyalty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.UserLoyalty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.UserLoyalty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.UserLoyalty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLevelPoints();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLoyaltyLevel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserDisabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loyalty.Order.serializeBinaryToWriter
    );
  }
  f = message.getExpireNext();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loyalty.ExpireAt.serializeBinaryToWriter
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.loyalty.UserLoyalty.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double points = 2;
 * @return {number}
 */
proto.loyalty.UserLoyalty.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.setPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double level_points = 3;
 * @return {number}
 */
proto.loyalty.UserLoyalty.prototype.getLevelPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.setLevelPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string loyalty_level = 4;
 * @return {string}
 */
proto.loyalty.UserLoyalty.prototype.getLoyaltyLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.setLoyaltyLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool user_disabled = 5;
 * @return {boolean}
 */
proto.loyalty.UserLoyalty.prototype.getUserDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.setUserDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional Order order = 6;
 * @return {?proto.loyalty.Order}
 */
proto.loyalty.UserLoyalty.prototype.getOrder = function() {
  return /** @type{?proto.loyalty.Order} */ (
    jspb.Message.getWrapperField(this, proto.loyalty.Order, 6));
};


/**
 * @param {?proto.loyalty.Order|undefined} value
 * @return {!proto.loyalty.UserLoyalty} returns this
*/
proto.loyalty.UserLoyalty.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.UserLoyalty.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExpireAt expire_next = 7;
 * @return {?proto.loyalty.ExpireAt}
 */
proto.loyalty.UserLoyalty.prototype.getExpireNext = function() {
  return /** @type{?proto.loyalty.ExpireAt} */ (
    jspb.Message.getWrapperField(this, proto.loyalty.ExpireAt, 7));
};


/**
 * @param {?proto.loyalty.ExpireAt|undefined} value
 * @return {!proto.loyalty.UserLoyalty} returns this
*/
proto.loyalty.UserLoyalty.prototype.setExpireNext = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.clearExpireNext = function() {
  return this.setExpireNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.UserLoyalty.prototype.hasExpireNext = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string username = 8;
 * @return {string}
 */
proto.loyalty.UserLoyalty.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.loyalty.UserLoyalty.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.loyalty.UserLoyalty} returns this
*/
proto.loyalty.UserLoyalty.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loyalty.UserLoyalty} returns this
 */
proto.loyalty.UserLoyalty.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loyalty.UserLoyalty.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loyalty.ExpireAt.prototype.toObject = function(opt_includeInstance) {
  return proto.loyalty.ExpireAt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loyalty.ExpireAt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.ExpireAt.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    points: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loyalty.ExpireAt}
 */
proto.loyalty.ExpireAt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loyalty.ExpireAt;
  return proto.loyalty.ExpireAt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loyalty.ExpireAt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loyalty.ExpireAt}
 */
proto.loyalty.ExpireAt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loyalty.ExpireAt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loyalty.ExpireAt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loyalty.ExpireAt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loyalty.ExpireAt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 time = 1;
 * @return {number}
 */
proto.loyalty.ExpireAt.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loyalty.ExpireAt} returns this
 */
proto.loyalty.ExpireAt.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string points = 2;
 * @return {string}
 */
proto.loyalty.ExpireAt.prototype.getPoints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loyalty.ExpireAt} returns this
 */
proto.loyalty.ExpireAt.prototype.setPoints = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.loyalty);
