const defaultGrpcOptions = {
  service: 'python',
};

export const getAxiosBaseUrl = () => {
  return process.env.REACT_APP_API_NEW_PATH;
};

export const getAxiosBannerUrl = () => {
  return process.env.REACT_APP_BANNER_URL;
};

const getPythonGrpcPath = () => {
  return `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_GRPC_PATH}`;
};

const getGoGrpcPath = () => {
  return `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_GO_GRPC_PATH}`;
};

const getGoCacheGrpcPath = () => {
  return `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_GO_CACHE_GRPC_PATH}`;
};

const getApiBaseUrl = (options?: any) => {
  if (options?.service === 'go') {
    return getGoGrpcPath();
  }

  if (options?.service === 'go_cache') {
    return getGoCacheGrpcPath();
  }

  return getPythonGrpcPath();
};

const getSocketBaseUrl = () => {
  return `${process.env.REACT_APP_SOCKET_PROTOCOL}://${process.env.REACT_APP_SOCKET_API_PATH}`;
};

export const getApiUrl = (endpoint?: string, options = defaultGrpcOptions) => {
  return `${getApiBaseUrl(options)}${endpoint ? `/${endpoint}` : ''}`;
};

export const getSocketUrl = (endpoint?: string) => {
  return `${getSocketBaseUrl()}${endpoint ? `/${endpoint}` : ''}`;
};
