/* React modules */
import { ReactNode, useState } from 'react';

/* Our modules */
import { CashDropStatus } from 'modules/user/cash-drop.types';
import useStores from 'common/hooks/useStores';
import TopBar from 'components/TopBar';
import HeaderControls from 'components/HeaderControls';
import Icon from 'components/Icon';
import CashDrop from 'modules/user/ui/CashDrop';
import overlayStore from 'libs/overlay-store';
import './Header.scss';

/* 3rd Party modules */
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SpinnerCircularSplit } from 'spinners-react';

interface HeaderProps {
  children: ReactNode;
  topBarControls: ReactNode;
  onBurgerClick: () => void;
  mobile: boolean;
}

const Header = observer(
  ({ children, onBurgerClick, topBarControls, mobile }: HeaderProps) => {
    const {
      cashDropStore: { getCashDrops, cashDropStatus },
      authStore: { isLoggedIn },
    } = useStores();

    const [cashDropLoading, setCashDropLoading] = useState(false);

    const openCahsDropModal = async () => {
      setCashDropLoading(true);

      await getCashDrops().then(() => {
        const modalContent = <CashDrop />;

        overlayStore.openModal(modalContent, {
          width: 'medium',
          closable: true,
          title: 'CASH DROP',
        });
      });

      setCashDropLoading(false);
    };

    return (
      <div>
        <div className="visible-desktop-up">
          <TopBar>{topBarControls}</TopBar>
        </div>

        <div className="header">
          {!mobile && (
            <div className="header__burger" onClick={onBurgerClick}>
              <Icon name="bars" />
            </div>
          )}

          <div className="header__logo">
            {!mobile ? (
              <Link to={!mobile ? '/sport' : ''}>
                <img
                  src={process.env.PUBLIC_URL + '/assets/logo.svg'}
                  alt="SBBet"
                />
              </Link>
            ) : (
              <div>
                <img
                  src={process.env.PUBLIC_URL + '/assets/logo.svg'}
                  alt="SBBet"
                />
              </div>
            )}
          </div>

          <div className="header__actions visible-desktop-down d-flex align-items">
            {isLoggedIn && cashDropStatus === CashDropStatus.ENABLED && (
              <div className="ml-auto d-flex align-items justify-center">
                {cashDropLoading && (
                  <div
                    className="mr-2"
                    style={{ height: '20px', width: '20px' }}
                  >
                    <SpinnerCircularSplit
                      enabled={true}
                      color="#acf439"
                      size="100%"
                    />
                  </div>
                )}

                {!cashDropLoading && (
                  <button
                    className="cash-drop-mobile-btn"
                    onClick={openCahsDropModal}
                  >
                    <div className="d-flex align-items"></div>
                  </button>
                )}
              </div>
            )}

            <HeaderControls />
          </div>

          <div className="header__content visible-desktop-up">{children}</div>
        </div>
      </div>
    );
  }
);

export { Header };
