/* React modules */
import { useCallback, useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Sidebar, Checkbox, Input, Icon } from 'components';
import { LoaderWrapper } from 'components/Loader';
import {
  GamblingFilter,
  CasinoSectionWithGames,
} from 'modules/gambling/gambling.types';
import useQuery from 'libs/useQuery';
import './GamblingSidebar.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface FilterSelectProps {
  filter: GamblingFilter;
}

interface MenuLabelProps {
  label: string;
}

interface GamblingSearchProps {
  min?: number;
  max?: number;
  search: string;
  changeMin?: any;
  onSearchClick: () => void;
  changeMax?: any;
  onSearch: (e: any) => void;
}

export const MenuLabel = ({ label }: MenuLabelProps) => (
  <div className="gambling-menu-label">{label}</div>
);

export const GameTypeFilterSelect = observer(
  ({ filter }: FilterSelectProps) => {
    const { gamblingStore } = useStores();
    const { toggleGameTypeFilter, filters, setHasMore } = gamblingStore;

    const isSelected = filters.$gameTypeFilters.has(filter.name);
    const onToggle = useCallback(() => {
      setHasMore(true);
      toggleGameTypeFilter(filter);
    }, [filter, toggleGameTypeFilter, setHasMore]);

    return (
      <div className="gambling-sidebar__filter">
        <Checkbox
          label={filter.name}
          onChange={onToggle}
          value={isSelected}
          position="left"
          variant="gambling"
        />
      </div>
    );
  }
);

export const SystemFilterSelect = observer(({ filter }: FilterSelectProps) => {
  const { gamblingStore } = useStores();
  const { toggleSystemFilter, filters, setHasMore } = gamblingStore;

  const isSelected = filters.$systemFilters.has(filter.value);
  const onToggle = useCallback(() => {
    setHasMore(true);
    toggleSystemFilter(filter);
  }, [filter, toggleSystemFilter, setHasMore]);

  return (
    <div className="gambling-sidebar__filter">
      <Checkbox
        label={filter.name}
        onChange={onToggle}
        value={isSelected}
        position="left"
        variant="gambling"
      />
    </div>
  );
});

export const SectionFilterSelect = observer(
  ({ filter }: { filter: CasinoSectionWithGames }) => {
    const { gamblingStore } = useStores();
    const { toggleSectionFilter, filters, setHasMore } = gamblingStore;

    const isSelected = filters.$sectionFilters.has(filter.id);
    const onToggle = useCallback(() => {
      setHasMore(true);
      toggleSectionFilter(filter);
    }, [filter, toggleSectionFilter, setHasMore]);

    return (
      <div className="gambling-sidebar__filter">
        <Checkbox
          label={filter.tag_name}
          onChange={onToggle}
          value={isSelected}
          position="left"
          variant="gambling"
        />
      </div>
    );
  }
);

const GamblingSearch = ({ search, onSearch }: GamblingSearchProps) => {
  const { t } = useTranslation();

  return (
    <div className="py-1">
      <div className="gambling-search">
        <Input
          placeholder={t('globals.search')}
          value={search}
          bg="gambling-dark"
          name="search-games"
          onChange={onSearch}
          addon={<Icon name="searchGreen" />}
        />
      </div>
      {/* <div className="gambling-bet-amount">
        <p>BET IZNOS</p>
      </div>
      <div className="gambling-filters">
        <div className="gambling-filters__filter">
          <span className="sb-text-small text-white text-center mb-1">MIN</span>
          <Input
            placeholder="0 &euro;"
            bg="black"
            value={min}
            height="small"
            onChange={changeMin}
            name="search-min"
            className="gambling-filters__min-max"
          />
        </div>
        <div className="gambling-filters__filter text-white mt-4 ml-1">-</div>
        <div className="gambling-filters__filter">
          <span className="sb-text-small text-white text-center mb-1">MAX</span>
          <Input
            placeholder="100 &euro;"
            bg="black"
            value={max}
            height="small"
            onChange={changeMax}
            name="search-max"
            className="gambling-filters__min-max"
          />
        </div>
        <div className="gambling-filters__btn d-flex justify-center ml-2">
          <Button
            onClick={() => onSearchClick()}
            size="small"
            className="w-100 uppercase"
            bg="dark"
          >
            {t('search')}
          </Button>
        </div>
      </div> */}
    </div>
  );
};

let searchDebounce: any;

const GamblingSidebar = ({ live }: any) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [min, setMin] = useState<number | undefined>(undefined);
  const [max, setMax] = useState<number | undefined>(undefined);
  const { gamblingStore } = useStores();
  const {
    searchGames,
    filters,
    gameTypes,
    providers,
    gamesSections,
    addSystemFilter,
  } = gamblingStore;

  let queryParams = useQuery();

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  const setFilterParam = () => {
    if (getParam('filter')) {
      const filter = {
        name: getParam('filter') || '',
        value: getParam('filter') || '',
      };

      addSystemFilter(filter);
    }
  };

  useEffect(() => {
    setFilterParam();
  }, []);

  useEffect(() => {
    setFilterParam();
  }, [getParam('filter')]);

  useEffect(() => {
    const searchString: any = getParam('searchString');
    if (searchString) {
      setSearch(searchString);
      searchGames({ game_name: searchString });
    }
  }, []);

  const onSearch = (e: any) => {
    if (typeof searchDebounce === 'number') {
      clearTimeout(searchDebounce);
    }
    searchDebounce = setTimeout(
      () => searchGames({ game_name: e.target.value }),
      400
    );

    if (!e.target.value) {
      filters.setFilters({ game_name: '' });
    }
    setSearch(e.target.value);
  };

  const onChangeMin = (e: any) => {
    setMin(e.target.value);
  };

  const onChangeMax = (e: any) => {
    setMax(e.target.value);
  };

  const onSearchClick = () => {
    searchGames({ min_bet: Number(min), max_bet: Number(max) });
  };

  useEffect(() => {
    if (!min) {
      filters.setFilters({ min_bet: 0 });
    }
  }, [min, searchGames, t, filters]);

  useEffect(() => {
    if (!max) {
      filters.setFilters({ max_bet: 0 });
    }
  }, [max, searchGames, t, filters]);

  return (
    <>
      <Sidebar>
        <GamblingSearch
          min={min}
          changeMin={onChangeMin}
          max={max}
          onSearchClick={onSearchClick}
          changeMax={onChangeMax}
          search={search}
          onSearch={onSearch}
        />
        <div className="gambling-sidebar">
          {!!gamesSections?.length && !live && (
            <>
              <MenuLabel label={t('casino.sections')} />
              <LoaderWrapper name="games-sections">
                {gamesSections.map((section: any) => (
                  <SectionFilterSelect
                    key={`GamblingSidebar-SectionFilterSelect-${section.id}`}
                    filter={section}
                  />
                ))}
              </LoaderWrapper>
            </>
          )}

          {!!gameTypes?.length && (
            <>
              <MenuLabel label={t('casino.categories')} />
              <LoaderWrapper name="games-types">
                {gameTypes.map((filter: any) => (
                  <GameTypeFilterSelect
                    key={`GamblingSidebar-GameTypeFilterSelect-${filter.value}`}
                    filter={filter}
                  />
                ))}
              </LoaderWrapper>
            </>
          )}

          {!!providers?.length && (
            <>
              <MenuLabel label={t('casino.casino-brands')} />
              <LoaderWrapper name="games-providers">
                {providers.map((filter: any) => (
                  <SystemFilterSelect
                    key={`GamblingSidebar-SystemFilterSelect-${filter.value}`}
                    filter={filter}
                  />
                ))}
              </LoaderWrapper>
            </>
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default observer(GamblingSidebar);
