import React, { ReactNode } from 'react';

import './Button.scss';

type ButtonBackground =
  | 'default'
  | 'success'
  | 'warning'
  | 'transparent'
  | 'silver'
  | 'dark'
  | 'ultra-black'
  | 'grey'
  | 'orange'
  | 'green';
type ButtonSize = 'small' | 'regular' | 'medium' | 'large';
type ClickHandler = (e: any) => void;

interface ButtonProps {
  children: ReactNode;
  className?: string;
  size: ButtonSize;
  bg: ButtonBackground;
  onClick?: ClickHandler;
  testId?: string;
  disabled?: boolean;
  hasBorder?: boolean;
  rounded?: boolean;
  style?: any;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({
  children,
  className,
  size,
  bg,
  onClick,
  testId,
  disabled,
  hasBorder,
  rounded,
  style,
  type,
}: ButtonProps) => (
  <button
    data-testid={testId}
    disabled={disabled}
    className={`btn btn-${bg} btn-${size} ${className || ''} ${
      hasBorder ? 'bordered' : ''
    } ${rounded ? 'rounded' : ''}`}
    onClick={onClick}
    style={style}
    type={type}
  >
    {children}
  </button>
);

Button.defaultProps = {
  size: 'regular',
  bg: 'default',
  hasBorder: false,
  isRound: true,
};

export default Button;
