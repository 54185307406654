/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { TicketEvent as ITicketEvent } from 'modules/ticket/store/ticket-builder';
import { getMarketNameTranslationKey } from 'modules/offer/services/offer-helpers.service';
import { Icon, Pill } from 'components';
import { SystemGroup } from 'modules/ticket/system-ticket';
import { EventTimeCell } from 'modules/ticket/ui/TicketStatus/TicketStatusBody/TicketStatusBody';
import useHighlight from 'modules/offer/ui/Offer/OddCell/use-highlight';
import { formatDate, formatTime, toDate } from 'libs/datetime';

/* 3rd Party modules */
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

enum OddState {
  UP = 'ticket-event__odd--ascending',
  DOWN = 'ticket-event__odd--descending',
  INITIAL = '',
}

const RenderOdd = observer(({ event, odd, hasMultipleOdds }: any) => {
  const { t, i18n } = useTranslation();
  const { suspended, id } = event;
  const { ticketBuilder } = useStores();
  const [oddState, setOddState] = useState<OddState>(OddState.INITIAL);

  const oddStateOptions = {
    automaticallyResetHighlight: false,
  };

  const { highlightState, highlight, isUp, isDown, isInitial } = useHighlight(
    odd,
    oddStateOptions
  );

  const removeOdd = (e: any) => {
    e.stopPropagation();
    ticketBuilder.remove(id, odd.id);
  };

  useEffect(() => {
    /** reset highlight when outcomeId has changed */
    highlight(null);
    setOddState(OddState.INITIAL);
  }, [highlight, ticketBuilder, id]);

  useEffect(() => {
    if (!!highlightState && highlightState !== 'initial') {
      ticketBuilder.onOddChange(odd.id);
    }
  }, [highlightState, ticketBuilder, odd.id]);

  useEffect(() => {
    if (ticketBuilder.changesAccepted) {
      highlight(null);
      setOddState(OddState.INITIAL);
    }
  }, [ticketBuilder.changesAccepted, highlight]);

  useEffect(() => {
    if (isDown()) {
      setOddState(OddState.DOWN);
    } else if (isUp()) {
      setOddState(OddState.UP);
    } else if (isInitial()) {
      setOddState(OddState.INITIAL);
    }
  }, [highlightState, isUp, isDown, isInitial, setOddState]);

  return (
    <div>
      <div className="d-flex mt-1 space-between">
        <div className="ticket-event__outcome">
          <span
            title={`${
              odd && odd.market && getMarketNameTranslationKey(i18n.language)
                ? odd.market[getMarketNameTranslationKey(i18n.language)]
                : ''
            } ${odd?.livePlayer ? ` - ${odd?.livePlayer}` : ''}`}
            className="ticket-event__outcome--name"
          >
            {odd && odd.market && getMarketNameTranslationKey(i18n.language)
              ? odd.market[getMarketNameTranslationKey(i18n.language)]
              : ''}{' '}
            {odd?.livePlayer ? ` - ${odd?.livePlayer}` : ''}
          </span>
          {odd.limit > 0 && (
            <span className="ml-2 ticket-event__outcome--separator">
              {odd.limit}
            </span>
          )}
          <span
            title={odd.outcomeName}
            className="ml-2 ticket-event__outcome--separator"
          >
            {odd.outcomeName}
          </span>
        </div>

        <div className="d-flex justify-end align-items">
          <Tooltip
            overlayClassName="tooltip"
            placement="bottom"
            overlay={odd.isDisabled || suspended ? '' : odd.displayValue}
          >
            <span className={classNames(oddState, 'ticket-event__odd')}>
              <span>
                {odd.isDisabled || !odd.visible || suspended ? (
                  <Icon size="small" name="lockSmall" />
                ) : (
                  odd.displayValue
                )}
              </span>
            </span>
          </Tooltip>

          {hasMultipleOdds && (
            <span onClick={removeOdd}>
              <Icon
                name="minus"
                className="ml-1"
                variant="sbgrey"
                tooltip={t('slips.remove-odd')}
                tooltipPosition="top"
                size="extra-small"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
});

interface TicketEventProps {
  event: ITicketEvent;
  isSystem: boolean | undefined;
}

const TicketEvent = ({ event, isSystem }: TicketEventProps) => {
  const { t } = useTranslation();
  const { ticketBuilder, feedStore } = useStores();
  const { id, name, selectedOdds, start } = event;
  const eventTime = formatTime(toDate(start.seconds));
  const eventDate = formatDate(toDate(start.seconds));
  const eventOdd = event.selectedOdds[0];
  const location = useLocation();

  const moveToGroup = (group: SystemGroup) => (e: any) => {
    e.stopPropagation();
    ticketBuilder.system.move(event.id, group);
  };

  const renderGroupPills = () => {
    const groups = ticketBuilder.system.getAvailableGroups(event.id);

    return groups.map((group: SystemGroup, i: number) => (
      <span
        key={`renderGroupPills-span-${group.name}`}
        className={classNames({ 'ml-1': i !== 0 })}
        onClick={moveToGroup(group)}
      >
        <Pill size="small">
          <Tooltip
            placement="left"
            overlay={`${t('slips.group-number')} ${group.name}`}
            overlayClassName="tooltip"
          >
            <span>S{group.name}</span>
          </Tooltip>
        </Pill>
      </span>
    ));
  };

  const removeEvent = (e: any) => {
    e.stopPropagation();
    ticketBuilder.removeEvent(id);
    // escape unsubscribing event if overlay with odds for that event is open
    const eventId = location.search.split('=')[1];
    if (eventId !== event.id) feedStore.unsubscribeEvent(event.id);
  };

  return (
    <>
      <div className="ticket-event d-flex space-between">
        <div className="w-90">
          <div title={name} className="w-90 ticket-event__name">
            {name}
          </div>
          <div>
            {event.type === 'live' ? (
              <div className="d-flex mt-1">
                <span className=" text-extra-strong mr-2">
                  <EventTimeCell
                    event={event}
                    start={event.start}
                    liveTime={eventOdd.event?.eventTime}
                  />
                </span>
                <Icon size="small" name="animatedLiveGreen" />
              </div>
            ) : (
              <>
                <span className="mr-3 mt-1">{eventTime}</span>
                <span>{eventDate}</span>
              </>
            )}
          </div>
        </div>
        <div className="mt-1" onClick={removeEvent}>
          <Icon
            name="removeEvent"
            size="regular"
            tooltip={t('slips.remove-match')}
            tooltipPosition="left"
          />
        </div>
      </div>

      <div className="mt-1">
        <div className="d-flex flex-column">
          {selectedOdds.map((odd) => (
            <RenderOdd
              key={`TicketEvent-RenderOdd-${odd.id}`}
              hasMultipleOdds={selectedOdds.length > 1}
              event={event}
              odd={odd}
            />
          ))}
        </div>
        {isSystem && <div className="mt-1 d-flex">{renderGroupPills()}</div>}
      </div>
    </>
  );
};

export default observer(TicketEvent);
