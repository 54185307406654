import { ReactNode } from 'react';
import { Clock } from 'components';
import { formatDate } from 'libs/datetime';
import './TopBar.scss';
import LanguageSelect from 'components/LanguageSelect';

interface TopBarProps {
  children: ReactNode;
}

const TopBar = ({ children }: TopBarProps) => {
  return (
    <div className="top-bar visible-tablet-up">
      <div className="top-bar__items-wrapper">
        <div className="text-extra-small text-yellow text-center mr-3">
          <Clock />
          <span className="ml-2">{formatDate(new Date())}</span>
        </div>
        <LanguageSelect />
      </div>
      <div className="d-flex justify-end ml-auto">{children}</div>
    </div>
  );
};

export default TopBar;
