/* React modules */

/* Our modules */
import Odd from 'modules/sports/store/odd.store';
import { formatOddsByLimit } from 'modules/offer/services/offer-helpers.service';
import {
  sortObj,
  findLowestDifference,
  sortByLowestDifference,
} from 'pages/sport/EventAllOddsOverlay/components/helpers';

/* 3rd Party modules */

export function useLimitOptions(odds: Odd[]) {
  const oddsByLimit = formatOddsByLimit(odds);

  Object.keys(oddsByLimit).forEach((key) => {
    oddsByLimit[key].sort((a: any, b: any) =>
      a.outcome.id > b.outcome.id ? 1 : -1
    );
  });

  const sortedOddsByLimits = sortObj(oddsByLimit);

  const { lowestDifferenceKey, targetKey } =
    findLowestDifference(sortedOddsByLimits);

  const sortedByLowestDifference = sortByLowestDifference(
    sortedOddsByLimits,
    lowestDifferenceKey,
    targetKey
  );

  return {
    oddsByLimit,
    sortedByLowestDifference,
    limitOptions: sortedByLowestDifference
      ? Object.keys(sortedByLowestDifference).filter((item: any) =>
          sortedByLowestDifference[item].some((o: any) => !o.isDisabled)
        )
      : [],
  };
}
