/* React modules */

/* Our modules */
import {
  EventOdd,
  Outcome,
  EVENT_TYPE,
  OddStatus,
  OddStatuses,
} from 'modules/sports/sports.types';
import sportsStore from 'modules/sports/store/sports.store';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import EventClass from 'modules/sports/store/event.store';
import oddFeedService from 'modules/sports/services/odd-feed.service';
import { SportMarket } from 'modules/sports/services/sports-markets.service';

/* 3rd Party modules */
import { runInAction, makeAutoObservable } from 'mobx';

export enum OddDirection {
  DOWN = 'down',
  UP = 'up',
  INITIAL = 'initial',
}

export type ChangeDirection =
  | OddDirection.DOWN
  | OddDirection.UP
  | OddDirection.INITIAL
  | null;

class Odd {
  eventId: string | null = null;
  id: number = 0;
  value: number | null = null;
  outcome: Outcome | null = null;
  outcomeName: string = '';
  market: SportMarket | null = null;
  changeDirection: ChangeDirection = null;
  event: EventClass;
  isDisabled = false;
  frameNumber: number | undefined = 0;
  livePlayer: string = '';
  exeLimit: string | null = null;
  limit: number | null = null;
  status: OddStatus | null = null;

  constructor(event: EventClass, data: EventOdd) {
    this.setData(event, data);
    this.event = event;

    /*
    makeObservable(this, {
      value: observable,
      status: observable,
      changeDirection: observable,
      isDisabled: observable,
      visible: computed,
      displayValue: computed,
      frameNumber: observable,
      livePlayer: observable,
      setData: action,
      onValueUpdate: action,
      updateChangeDirection: action,
      resetChangeDirection: action,
      connectToFeed: action,
      isSelected: computed,
    });
    */
    makeAutoObservable(this);
  }

  get outcomeDisplay(): string {
    if (!this.outcome) return '';
    if (this.outcome.exeLimit) return this.outcomeName;
    if (this.outcome.outcomeTic) return this.outcome.outcomeTic;
    return `${this.outcome.outcomeGroup} ${this.outcome.name}`;
  }

  get visible(): boolean {
    return (
      this.status === OddStatuses.NOT_RESOLVED &&
      this.value !== null &&
      this.value > 1
    );
  }

  get displayValue(): string {
    return this.value ? this.value.toFixed(2) : '';
  }

  get isSelected(): boolean {
    if (!this.eventId) return false;
    return ticketBuilder.hasOutcome(this.eventId, this.id);
  }

  setData(event: EventClass, data: EventOdd) {
    const { sportId, id: eventId } = event;

    this.status = data.status;
    this.eventId = eventId;
    this.value = data.value;
    this.id = data.id;
    this.isDisabled = data.isDisabled;
    this.frameNumber = data.frameNumber;
    this.livePlayer = data.livePlayer;
    this.outcome = this.findOutcome(sportId, data.outcomeId);
    this.market = this.findOddMarket(
      sportsStore.marketGroups.get(sportId)?.marketsList,
      data.outcomeId
    );

    this.outcomeName = this.outcome?.name || '';

    if (data.limit) {
      this.limit = +data.limit;
    }

    if (this.outcome?.exeLimit && event.type === EVENT_TYPE.LIVE) {
      this.limit = +this.outcome.exeLimit;
      this.setOutcomeName();
    }
  }

  setOutcomeName = () => {
    if (this.outcome) {
      const { name } = this.outcome;
      this.outcomeName = name.includes('+') ? '>' : '<';
    }
  };

  onValueUpdate = (
    value: number | null,
    disabled: boolean,
    status: OddStatus
  ) => {
    if (value && value !== this.value) {
      this.updateChangeDirection(value);
    }
    runInAction(() => {
      if (value !== this.value) {
        this.value = value;
      }

      this.isDisabled = disabled;
      this.status = status;

      if (this.isSelected) {
        if (this.isDisabled || !this.visible) {
          ticketBuilder.onOddDisabled(this.id);
        } else {
          ticketBuilder.onOddEnabled(this.id);
        }
      }
    });
  };

  updateChangeDirection = (value: number) => {
    runInAction(() => {
      if (this.event.isSuspended) return;
      if (!this.value || value > this.value) {
        this.changeDirection = OddDirection.UP;
      }

      if (this.value && value < this.value) {
        this.changeDirection = OddDirection.DOWN;
      }

      if (this.value && value === this.value) {
        this.changeDirection = OddDirection.INITIAL;
      }
    });
  };

  resetChangeDirection = () => {
    this.changeDirection = null;
  };

  findOutcome(sport: number, id: number): Outcome | null {
    const markets = sportsStore.marketGroups.get(sport)?.marketsList;
    const oddMarket = this.findOddMarket(markets, id);

    if (oddMarket) {
      return oddMarket.outcomes.find((o) => o.id === id) || null;
    }

    return null;
  }

  findOddMarket(
    markets: SportMarket[] | undefined,
    outcomeId: number
  ): SportMarket | null {
    if (!markets) return null;

    return (
      markets.find((market) =>
        market.outcomes.some((outcome) => outcome.id === outcomeId)
      ) || null
    );
  }

  connectToFeed() {
    if (!this.eventId || !this.id) return;
    const handlers = {
      onValueUpdate: this.onValueUpdate,
    };

    oddFeedService.connectOdd(this.eventId, this.id, handlers);

    const updatedOdd = oddFeedService.getFromFeed(this.eventId, this.id);

    if (updatedOdd && updatedOdd !== this.value) {
      this.value = updatedOdd;
    }
  }

  disconnectFromFeed() {
    if (!this.eventId || !this.id) return;
    oddFeedService.disconnectOdd(this.eventId, this.id);
  }
}

export default Odd;
