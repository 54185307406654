/* React modules */
import { useContext } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { renderCombinations } from 'modules/ticket/ticket.service';
import { Checkbox } from 'components';
import TicketEvent from 'modules/ticket/ui/TicketSidebar/Ticket/components/TicketEvent';
import { SystemGroup, SystemGroupEvent } from 'modules/ticket/system-ticket';
import { OverlayContext } from 'pages/sport/Sport';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface TicketRecordsdProps {
  isSystem?: boolean;
}

const TicketRecords = ({ isSystem }: TicketRecordsdProps) => {
  const { t } = useTranslation();
  const { ticketBuilder } = useStores();
  const openAllOdds = useContext(OverlayContext);

  const renderSystemCheckboxes = (group: SystemGroup) => {
    return group.events.map((event: SystemGroupEvent, index: number) => (
      <div
        key={`renderSystemCheckboxes-Checkbox-${event.id}`}
        className="checkbox-wrapper"
      >
        <Checkbox
          label={`${index + 1} / ${group.events.length}`}
          value={ticketBuilder.system.isSystemSelected(group, index + 1)}
          onChange={() => {
            ticketBuilder.system.toggleSystem(group, index + 1);
          }}
        />
      </div>
    ));
  };

  const renderEvents = (events: any[]) => {
    return events.map((event: any) => (
      <div
        key={`renderEvents-div-${event.id}`}
        onClick={() => openAllOdds(event.id)}
        className="ticket-records__single text-small text-strong"
      >
        <TicketEvent event={event} isSystem={isSystem} />
      </div>
    ));
  };

  const RenderGroup = (group: SystemGroup) => {
    const { t } = useTranslation();

    if (!group.events.length) return null;
    const events = group.events.map((e) => ticketBuilder.findEvent(e.id));

    return (
      <div key={`RenderGroup-div-${group.name}`}>
        <div className="d-flex space-between text-regular py-2 px-2">
          <span className="text-strong">{t('slips.system')}</span>
          <span className="text-strong">
            {renderCombinations(group.system, group.events, !isSystem)}
          </span>
        </div>

        <div>{renderEvents(events)}</div>

        <div className="mt-1 ml-2 ticket-records__system">
          {renderSystemCheckboxes(group)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames('ticket-records mb-2', {
        'bordered-bottom': ticketBuilder.events.length,
        'system-wrapper': isSystem,
      })}
    >
      {!isSystem && renderEvents(ticketBuilder.events)}
      {isSystem && ticketBuilder.system.groups.map(RenderGroup)}
      {ticketBuilder.events.length === 0 && (
        <div className="ticket-records__empty">{t('slips.no-slip-events')}</div>
      )}
    </div>
  );
};

TicketRecords.defaultProps = {
  isSystem: false,
};

export default observer(TicketRecords);
