/* React modules */

/* Our modules */
import me from './resources/me';
import en from './resources/en';
import al from './resources/al';
import tr from './resources/tr';
import ru from './resources/ru';
import ua from './resources/ua';
import it from './resources/it';
import de from './resources/de';

/* 3rd Party modules */

const resources = {
  me,
  en,
  al,
  tr,
  ru,
  ua,
  it,
  de,
} as const;

export default resources;
