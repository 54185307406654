import React from 'react';
import classNames from 'classnames';
import Dotdotdot from 'react-dotdotdot';

import './Notification.scss';
import timeAgo from 'libs/time-ago';

interface NotificationProps {
  title: string;
  text: string;
  created: string;
  read: boolean;
}

const Notification = ({ title, text, created, read }: NotificationProps) => (
  <div className="notification">
    <span className="text-small">{timeAgo(created)}</span>
    <div className="notification__content">
      <div className="notification__text">
        <span
          className={classNames('text-small', 'mb-1', 'text-green-200', {
            'text-strong': !read,
          })}
        >
          {title}
        </span>
        <span className="text-extra-small">
          <Dotdotdot clamp={2}>{text}</Dotdotdot>
        </span>
      </div>
    </div>
  </div>
);

export default Notification;
