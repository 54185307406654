/* React modules */
import { useCallback, useContext } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import Result from 'modules/offer/ui/Offer/Result';
import { OverlayContext } from 'pages/sport/Sport';
import MatchStatistic from 'pages/sport/EventAllOddsOverlay/components/MatchStatistic';
import { Icon } from 'components';
import CountryIcon, { COUNTRY_ICONS } from 'components/CountryIcon';
import DefaultLocationIcon from 'images/defaultLocationIcon.svg';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

const EventCell = observer(({ value, isSuspended = false }: any) => {
  const openAllOdds = useContext(OverlayContext);

  const {
    day,
    englishDay,
    home,
    away,
    competitionName,
    locationName,
    currentResult,
    periodsShort,
    disabled,
    type,
    betradarId,
    eventTime,
    name,
    competitionShortDisplay,
    liveStreamId,
  } = value.event;

  const { i18n } = useTranslation();
  const { overlayStore } = useStores();

  const canOpenAllOdds =
    type === EVENT_TYPE.LIVE ||
    type === EVENT_TYPE.UPCOMING ||
    type === EVENT_TYPE.PLAYER;

  const openOverlay = useCallback(
    () =>
      canOpenAllOdds &&
      openAllOdds({
        ...value,
        location: locationName,
        competition: competitionName,
      }),
    [value, canOpenAllOdds, openAllOdds, locationName, competitionName]
  );

  const eventTimeClass = [
    'text-strong',
    'text-center',
    { 'text-yellow': type === EVENT_TYPE.LIVE },
  ];

  const openMatchStats = () => {
    overlayStore.openModal(<MatchStatistic betradarId={betradarId} />, {
      width: 'large',
      name: 'sr-iframe',
      closable: false,
    });
  };

  const isAntepostOrSpecial =
    type === EVENT_TYPE.SPECIAL || type === EVENT_TYPE.ANTEPOST;
  const isOverlayDisabled =
    type === EVENT_TYPE.SPECIAL || type === EVENT_TYPE.ANTEPOST;

  return (
    <div
      className={classNames(
        'w-100 pointer event-cell d-flex border-box bg-grey-900',
        {
          'cursor-default': isOverlayDisabled,
        }
      )}
      style={{ height: '100%' }}
    >
      {!isAntepostOrSpecial && (
        <>
          <div
            onClick={!isSuspended ? openOverlay : () => null}
            className="d-flex justify-center align-items"
            style={{ width: '60px' }}
          >
            <div>
              <div className={classNames('pb-1', eventTimeClass)}>
                {eventTime}
              </div>

              <div>{i18n.language === 'me' ? day : englishDay}</div>
            </div>
          </div>

          <div
            onClick={!isSuspended ? openOverlay : () => null}
            className="d-flex justify-center align-items text-ellipsis"
            style={{ width: '35px' }}
          >
            <div>
              <div className="d-flex justify-center align-items">
                {COUNTRY_ICONS[locationName] !== undefined ? (
                  <CountryIcon
                    original
                    size="large"
                    country={COUNTRY_ICONS[locationName]}
                  />
                ) : (
                  <img
                    alt="Location"
                    style={{ height: '20px', width: '20px' }}
                    src={DefaultLocationIcon}
                  />
                )}
              </div>

              <Tooltip
                placement="right"
                overlay={competitionName}
                overlayClassName="tooltip"
                mouseEnterDelay={0.5}
              >
                <p
                  style={{ width: '35px' }}
                  className="text-center text-ellipsis text-sb-extra-small"
                >
                  {competitionShortDisplay}
                </p>
              </Tooltip>
            </div>
          </div>
        </>
      )}

      <div
        onClick={!isSuspended ? openOverlay : () => null}
        className="d-flex align-items text-ellipsis pl-2 pt-1"
      >
        <div>
          <Tooltip
            placement="right"
            overlay={home}
            overlayClassName="tooltip"
            mouseEnterDelay={0.5}
          >
            <div
              style={{ width: isAntepostOrSpecial ? '100%' : '105px' }}
              className={classNames('pb-1', 'text-ellipsis', {
                'text-extra-strong':
                  +currentResult[0]?.homeScore > +currentResult[0]?.awayScore,
              })}
            >
              {!isAntepostOrSpecial ? home : name}
            </div>
          </Tooltip>

          <Tooltip
            placement="right"
            overlay={away}
            overlayClassName="tooltip"
            mouseEnterDelay={0.5}
          >
            <div
              style={!isAntepostOrSpecial ? { width: '105px' } : {}}
              className={classNames('pb-1', 'text-ellipsis', {
                'text-extra-strong':
                  +currentResult[0]?.awayScore > +currentResult[0]?.homeScore,
              })}
            >
              {isAntepostOrSpecial ? competitionName : away}
            </div>
          </Tooltip>
        </div>
      </div>

      <div
        onClick={!isSuspended ? openOverlay : () => null}
        className="d-flex justify-center align-items"
        style={{ width: '20px' }}
      >
        {disabled ? (
          <Icon
            name="lockSmall"
            size="regular"
            className={classNames('suspended-lock', {
              'tennis-lock': value.event.sportName === 'tenis',
            })}
          />
        ) : liveStreamId ? (
          <Icon
            name="liveStream"
            size="regular"
            className={classNames('icon-sbgreen suspended-lock', {
              'tennis-lock': value.event.sportName === 'tenis',
            })}
          />
        ) : null}
      </div>

      {type === EVENT_TYPE.LIVE && (
        <div
          onClick={!isSuspended ? openOverlay : () => null}
          className="d-flex justify-end align-items"
          style={{ width: '70px' }}
        >
          <Result current={currentResult} periods={periodsShort} />
        </div>
      )}

      {type === EVENT_TYPE.UPCOMING && betradarId && betradarId !== '' && (
        <div
          className="d-flex justify-end align-items"
          style={{ width: '70px' }}
        >
          <Icon name="stats" className="mr-3" onClick={openMatchStats} />
        </div>
      )}
    </div>
  );
});

export default EventCell;
