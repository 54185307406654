/* React modules */
import { useState } from 'react';

/* Our modules */
import { LoyaltyLevel } from 'modules/user/market.types';
import useStores from 'common/hooks/useStores';
import userStore from 'modules/user/user.store';
import overlayStore from 'libs/overlay-store';
import { Drawer, Icon } from 'components';
import LanguageSelect from 'components/LanguageSelect';
import Wallet from 'modules/wallet/ui/Wallet';
import Register from 'modules/auth/ui/Register';
import Login from 'modules/auth/ui/Login';
import Logout from 'modules/auth/ui/Logout';
import { profileRoutes } from 'pages/user-profile/UserProfile';
import './UserOverlay.scss';

/* 3rd Party modules */
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

interface Link {
  route: string;
  label: string;
  icon: string;
  routes?: object[];
  index: number;
  disabled?: boolean;
}

const RenderLinks = observer(() => {
  const { t } = useTranslation();

  const { marketStore } = useStores();

  const { isDisabledLoyalty, marketStatus } = marketStore;

  const navLinks: Link[] = [
    {
      route: profileRoutes.DEPOSIT,
      label: t('payments.balance'),
      icon: 'userSettings',
      index: 0,
      routes: [
        {
          route: profileRoutes.DEPOSIT,
          label: t('payments.debit'),
          icon: 'creditDebit',
          index: 0,
        },
        {
          route: profileRoutes.WITHDRAW,
          label: t('payments.credit'),
          icon: 'creditDebit',
          index: 0,
        },
      ],
    },
    {
      route: profileRoutes.SETTINGS,
      label: t('globals.account'),
      icon: 'userSettings',
      index: 1,
      routes: [
        {
          route: profileRoutes.SETTINGS,
          label: t('userData.personal-data'),
          icon: 'userSettings',
          index: 1,
        },
        {
          route: profileRoutes.DOCUMENTS,
          label: t('userData.documents'),
          icon: '',
          index: 1,
        },
        {
          route: profileRoutes.PASSWORD_CHANGE,
          label: t('userData.change-password'),
          icon: 'userSettings',
          index: 1,
        },
        {
          route: profileRoutes.RESPONSIBLE_GAMBLING,
          label: t('userData.responsible-gambling'),
          icon: 'userSettings',
          index: 1,
        },
      ],
    },
    {
      route: profileRoutes.TRANSACTIONS,
      label: t('globals.transaction-history'),
      icon: 'transactions',
      index: 2,
    },
    {
      route: profileRoutes.TICKETS_OVERVIEW,
      label: t('slips.tickets'),
      icon: 'ticketOverview',
      index: 3,
      routes: [
        {
          route: profileRoutes.TICKETS_OVERVIEW,
          label: t('globals.betting-history'),
          icon: '',
          index: 3,
        },
        {
          route: profileRoutes.QUICK_TICKETS_OVERVIEW,
          label: t('slips.quick-tickets'),
          icon: '',
          index: 3,
        },
      ],
    },
    {
      route: profileRoutes.PROMO,
      label: t('globals.promo-code'),
      icon: 'promoCode',
      index: 4,
    },
    {
      disabled: isDisabledLoyalty || marketStatus === 'disabled',
      route: profileRoutes.MARKET,
      label: t('SBBet LOYALTY'),
      icon: 'ticketOverview',
      index: 5,
      routes: [
        {
          route: profileRoutes.MARKET,
          label: t('Market'),
          icon: '',
          index: 5,
        },
        {
          route: profileRoutes.MARKET_HISTORY,
          label: t('globals.order-history'),
          icon: '',
          index: 5,
        },
      ],
    },
    {
      route: profileRoutes.POKER_DEPOSIT,
      label: t('footer.poker'),
      icon: 'userSettings',
      index: 6,
      routes: [
        {
          route: profileRoutes.POKER_DEPOSIT,
          label: t('payments.debit'),
          index: 6,
        },
        {
          route: profileRoutes.POKER_WITHDRAW,
          label: t('payments.credit'),
          index: 6,
        },
      ],
    },
  ];

  const [activeLink, setActiveLink] = useState(0);

  const activeLinkHandler = (index: number) => {
    setActiveLink(index);
  };

  return (
    <div>
      {navLinks.map(
        (link: Link, i: number) =>
          !link.disabled && (
            <li key={`RenderLinks-li-${link.route}`}>
              <NavLink
                onClick={() => {
                  overlayStore.closeDrawer();
                  activeLinkHandler(link.index);
                }}
                end
                className={({ isActive }) =>
                  activeLink === i
                    ? 'active-item'
                    : '' + (isActive ? ' active-item' : '')
                }
                to={link.route}
              >
                <div className="user-overlay__nav-link border-box">
                  <span className="__underlined border-box uppercase">
                    {link.label}
                  </span>
                </div>
              </NavLink>

              {link.routes &&
                link.routes.map((item: any) => (
                  <ul key={`RenderLinks-ul-${item.route}`}>
                    <li>
                      <NavLink
                        onClick={() => {
                          overlayStore.closeDrawer();
                          activeLinkHandler(link.index);
                        }}
                        end
                        className={({ isActive }) =>
                          isActive ? 'active-item-sublink' : ''
                        }
                        to={item.route}
                      >
                        <div className="user-overlay__nav-sublink border-box">
                          <span className="mr-auto border-box ml-2">
                            {item.label}
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                ))}
            </li>
          )
      )}
    </div>
  );
});

const UserOverlay = () => {
  const {
    userStore: { user, wallets, initUser, getPokerBalance },
    authStore: { isLoggedIn },
    marketStore: { pointsStatus, isDisabledLoyalty, getLoyalty, fetchLoyalty },
    overlayStore,
  } = useStores();

  const { t } = useTranslation();

  const [isOpen, toggleIsOpen] = useState<boolean>(false);

  const languageDrawerHandler = () => {
    toggleIsOpen(!isOpen);
  };

  const init = async () => {
    initUser();
    fetchLoyalty();
    await getPokerBalance();
  };

  const openRegisterModal = () => {
    overlayStore.closeDrawer();
    overlayStore.openModal(<Register continueIdle={() => null} />, {
      className: 'register-modal',
      wrapClassName: 'register-modal-wrap',
      width: 'medium',
    });
  };

  const openLogoutModal = () => {
    overlayStore.openModal(<Logout />, {
      className: '',
      wrapClassName: '',
      width: 'small',
    });
  };

  const openLoginModal = () => {
    overlayStore.closeDrawer();
    const modalContent = (
      <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
    );

    overlayStore.openModal(modalContent, {
      width: 'small',
    });
  };

  const parsePoints = (points: any) => {
    let parsedPoints = String(points);
    if (parsedPoints?.includes('.')) {
      return (
        parsedPoints.split('.')[0] +
        '.' +
        parsedPoints.split('.')[1].substr(0, 2)
      );
    }

    return parsedPoints;
  };

  return (
    <div className="wrapper">
      {isLoggedIn ? (
        <div className="user-overlay">
          <div className="user-overlay__header d-flex align-items justify-center">
            <span className="text-sb-heading">({user?.id})&nbsp;</span>
            {user?.username}
            <Icon
              className="ml-2"
              name="reload"
              onClick={async () => {
                await init();
              }}
              tooltip={t('wallet.wallets-refresh')}
            />
          </div>

          {getLoyalty?.loyaltyLevel === LoyaltyLevel.EXPERT && (
            <div className="bg-black py-2 text-center d-flex align-items justify-center sb-heading text-white">
              <p className="mr-1 uppercase">{t('userMarket.expert')}</p>
              <Icon name="badgeExpert" style={{ width: '20px' }} />
            </div>
          )}

          {getLoyalty?.loyaltyLevel === LoyaltyLevel.PROFESSOR && (
            <div className="bg-black py-2 text-center d-flex align-items justify-center sb-heading text-white">
              <p className="mr-1 uppercase">{t('userMarket.professor')}</p>
              <Icon name="badgeProfessor" style={{ width: '20px' }} />
            </div>
          )}

          {getLoyalty?.loyaltyLevel === LoyaltyLevel.DOCTOR && (
            <div className="bg-black py-2 text-center d-flex align-items justify-center sb-heading text-white">
              <p className="mr-1 uppercase">{t('userMarket.doctor')}</p>
              <Icon name="badgeDoctor" style={{ width: '20px' }} />
            </div>
          )}

          <div className="user-overlay__content">
            {/* Loyalty progress bar */}
            {/*{pointsStatus === 'enabled' && !isDisabledLoyalty &&*/}
            {/*  <div className="px-3 py-2 pr-6">*/}
            {/*    <UserProgress />*/}
            {/*  </div>*/}
            {/*}*/}
            <div className="user-overlay__wallets">
              {wallets.map((wallet: any) => (
                <div
                  key={`UserOverlay-div-${wallet.type}`}
                  className="mb-3 px-3"
                >
                  <Wallet data={wallet} />
                </div>
              ))}

              {pointsStatus === 'enabled' && !isDisabledLoyalty && (
                <div className="mb-3">
                  <Tooltip
                    placement="bottom"
                    overlay={t('globals.click-for-more-info')}
                    overlayClassName="tooltip"
                    mouseEnterDelay={0.5}
                  >
                    <a
                      href="https://promo.sbbet.me/loyalty/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="loyalty-balance">
                        <div className="d-flex text-small">
                          <p className="balance-label">
                            {t('userMarket.sbbet-points')}
                          </p>
                        </div>

                        <div className="loyalty-balance__amount mx-3 mt-2">
                          <p>{parsePoints(getLoyalty?.points)}</p>
                        </div>
                      </div>
                    </a>
                  </Tooltip>
                </div>
              )}

              <div className="loyalty-balance">
                <div className="d-flex text-small">
                  <p className="balance-label">{t('wallet.poker-balance')}</p>
                </div>

                <div className="loyalty-balance__amount mx-3 mt-2">
                  <p>{userStore.pokerBalance}</p>
                </div>
              </div>
            </div>

            <ul className="mt-8">
              <RenderLinks />
              <li
                onClick={languageDrawerHandler}
                className="pointer user-overlay__nav-link uppercase visible-desktop-down"
              >
                {t('language.language')}
              </li>
              <li
                className="pointer user-overlay__nav-link uppercase"
                onClick={() => {
                  openLogoutModal();
                  overlayStore.closeDrawer();
                }}
              >
                <span>{t('login.logout')}</span>
                <span />
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="user-overlay uppercase">
          <ul className="user-overlay__auth">
            <li
              onClick={openLoginModal}
              className="d-flex align-items pl-5 py-3 user-overlay__auth-item pointer"
            >
              <Icon name="signIn" />
              <span className="ml-2 text-bold">{t('login.login')}</span>
            </li>

            <li
              onClick={openRegisterModal}
              className="d-flex align-items pl-5 py-3 user-overlay__auth-item pointer"
            >
              <span className="text-bold pl-6">{t('globals.register')}</span>
            </li>
            <li
              onClick={languageDrawerHandler}
              className="d-flex align-items pl-5 py-3 user-overlay__auth-item pointer"
            >
              <span className="text-bold pl-6">{t('language.language')}</span>
            </li>
          </ul>
        </div>
      )}
      <Drawer
        placement="bottom"
        width="100%"
        height="100%"
        visible={isOpen}
        onClose={languageDrawerHandler}
      >
        <div>
          <div
            className="d-flex align-items py-4 px-4 bg-black-700"
            onClick={languageDrawerHandler}
          >
            <div>
              <Icon name="caretLeft" />
            </div>
            <span className="uppercase ml-4 heading-m">
              {t('language.language')}
            </span>
          </div>
        </div>
        <div className="w-100 my-10">
          <LanguageSelect />
        </div>
      </Drawer>
    </div>
  );
};

export default observer(UserOverlay);
