/* React modules */

/* Our modules */
import { SYSTEM_SOCKET_ENDPOINT } from 'modules/system/system-constants';
import { getSocketUrl } from 'libs/urlBuilder';
import { parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import {
  Websocket,
  WebsocketBuilder,
  LinearBackoff,
  WebsocketEvent,
} from 'websocket-ts';

class SystemAPI {
  private socket: Websocket;

  constructor() {
    this.socket = new WebsocketBuilder(getSocketUrl(SYSTEM_SOCKET_ENDPOINT))
      .withBackoff(new LinearBackoff(0, 1000, 10000)) // Start with a 0-second delay and increase by 1 second for each retry, up to 10 seconds (docs: https://github.com/jjxxs/websocket-ts#linearbackoff)
      .build();
  }

  connect(onMessage: (message: any) => void) {
    if (this.socket) {
      this.socket.addEventListener(
        WebsocketEvent.message,
        (i: Websocket, event: MessageEvent) => {
          const message = parseJSONString(event.data);

          if (message) {
            onMessage(message);
          }
        }
      );
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export { SystemAPI };
