/* React modules */

/* Our modules */
import { ResultPeriod } from 'modules/sports/store/event.store';
import './Result.scss';

/* 3rd Party modules */
import classnames from 'classnames';

const PeriodResult = ({
  period,
  displayHeader,
  isMainScore,
  position,
}: any) => {
  return (
    <>
      <div
        className={classnames(
          'd-flex',
          'flex-column',
          'result__header',
          'text-center',
          {
            'text-white': !isMainScore && !position,
            'text-yellow': isMainScore,
            'text-green': position > 0,
          }
        )}
      >
        {displayHeader && (
          <div style={{ borderBottom: '1px solid white' }}>
            <span className="mx-1">{period?.name}</span>
          </div>
        )}
        <span
          className={classnames(
            'mb-1',
            'mx-1',
            'result__body',
            'result__score',
            { 'text-extra-strong': period?.homeScore > period?.awayScore }
          )}
        >
          {period?.homeScore}
        </span>
        <span
          className={classnames('mx-1', 'result__score', {
            'text-extra-strong': period?.awayScore > period?.homeScore,
          })}
        >
          {period?.awayScore}
        </span>
      </div>
    </>
  );
};

interface ResultProps {
  current: ResultPeriod[];
  periods: ResultPeriod[];
  displayHeader?: boolean;
}

const Result = ({ current, periods, displayHeader }: ResultProps) => {
  return (
    <div className="d-flex mr-1 result">
      <div className="d-flex justify-center">
        {current.map((period: any, i: number) => (
          <PeriodResult
            key={`Result-PeriodResult-1-${period?.name}`}
            period={period}
            displayHeader={displayHeader}
            isMainScore={i === 0}
            position={i}
          />
        ))}

        {periods.map((period: any) => (
          <PeriodResult
            key={`Result-PeriodResult-2-${period?.name}`}
            period={period}
            displayHeader={displayHeader}
          />
        ))}
      </div>
    </div>
  );
};

Result.defaultProps = {
  current: [],
  periods: [],
  displayHeader: false,
};

export default Result;
