/* React modules */
import { useState, useEffect } from 'react';

/* Our modules */
import { SelectOption } from 'components/Select/Select';
import useStores from 'common/hooks/useStores';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

export function useCountryOptions() {
  const {
    userStore: { getCountriesAlpha3Codes },
  } = useStores();

  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    async function fetchCountriesWithISOAlpha3Codes() {
      if (language) {
        const response = await getCountriesAlpha3Codes(language);

        setCountryOptions(
          (response || []).map((item) => ({
            value: item.code,
            label: item.name,
          }))
        );
      }
    }

    fetchCountriesWithISOAlpha3Codes();
  }, [language]);

  return countryOptions || [];
}
