import React from 'react';

import { EVENT_TYPE, EventType } from 'modules/sports/sports.types';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

interface TableTitleProps {
  tableTitle: string;
  sport: string;
  type: EventType;
}

const TableTitle = ({ tableTitle, type }: TableTitleProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="pl-3 d-flex align-items text-strong text-left heading-large text-ellipsis"
      style={{ fontSize: '20px' }}
    >
      {type !== EVENT_TYPE.LIVE &&
        type !== EVENT_TYPE.UPCOMING &&
        type !== EVENT_TYPE.ANTEPOST && (
          <div>
            <div className="text-ellipsis">{t(`sportsPage.${type}`)}</div>
            {tableTitle && (
              <Tooltip
                placement="top"
                overlay={tableTitle}
                overlayClassName="tooltip"
              >
                <div className="text-ellipsis text-yellow-500 sb-text-small">
                  {tableTitle.split('=')[1]}
                </div>
              </Tooltip>
            )}
          </div>
        )}

      {(type === EVENT_TYPE.UPCOMING ||
        type === EVENT_TYPE.LIVE ||
        type === EVENT_TYPE.ANTEPOST) && (
        <div>
          <span className="text-ellipsis">{t(`sportsPage.${type}`)}</span>
        </div>
      )}
      {type === 'live' && (
        <Icon name="animatedLiveGreen" className="ml-3 mt-1" size="large" />
      )}
    </div>
  );
};

export default TableTitle;
