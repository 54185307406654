/* React modules */

/* Our modules */
import TicketApi from 'modules/ticket/ticket.api';
import { TicketStatus } from 'modules/ticket/ticket.types';
import { EventStart } from 'modules/sports/sports.types';
import { WalletType, WalletTypes } from 'modules/wallet/wallet.types';
import { ValidationRule } from 'modules/ticket/ticket.errors';
import SystemTicket from 'modules/ticket/system-ticket';
import ticketValidator from 'modules/ticket/store/ticket-validator';
import Odd from 'modules/sports/store/odd.store';
import ticketsStore from 'modules/ticket/store/user-tickets';
import userTickets from 'modules/ticket/store/user-tickets';
import { calculateTotalOdd, getGroups } from 'modules/ticket/ticket.service';
import overlayStore from 'libs/overlay-store';
import { logger, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable, runInAction } from 'mobx';

export interface TicketEvent {
  code: number;
  sportId: number;
  sportName: string;
  competitionName: string;
  locationName: string;
  id: string;
  name: string;
  start: EventStart;
  type: string;
  selectedOdds: Odd[];
  suspended?: boolean;
}

class TicketBuilder {
  api: TicketApi;
  amount: string = '0';
  system: SystemTicket;
  bonusConfig: any = null;
  events: TicketEvent[] = [];
  isSystem = false;
  changedOdds: Set<number> = new Set();
  wallet: WalletType = WalletTypes.STANDARD;
  shouldSaveOdds: boolean = true;

  constructor() {
    this.api = new TicketApi();
    this.system = new SystemTicket();
    this.activate();

    makeAutoObservable(this);
  }

  activate() {
    this.getBonusConfig();
    ticketValidator.getRules(this.onRulesFetched);
  }

  onRulesChanged() {
    ticketValidator.getRules(() => {
      ticketValidator.validateWinAmount(this.winningAmount, this.isSystem);
      ticketValidator.validateEventsLength(this.events.length, this.isSystem);
      ticketValidator.validateAmount(
        this.isSystem ? this.amountPerCombination : +this.amount,
        this.wallet,
        this.isSystem
      );
    });
  }

  get slip() {
    return {
      amount: this.amount,
      isFix: !this.isSystem,
      systemGroups: getGroups(this.system.groups, this.events, !this.isSystem),
      status: TicketStatus.UNCONFIRMED,
      bonus: this.potentialBonus,
      maxPotBonus: this.system.maxBonusAmount,
      potentialWinningAmount: this.potentialWinningAmount,
      winningAmount: this.winningAmount,
      totalOdd: this.totalOdd,
      minOdd: this.system.minOddsValue,
      maxOdd: this.system.maxOddsValue,
      minWinningAmount: this.system.minWinningAmount,
      minWinningTotal: this.system.minWinningAmount,
      eventsCount: this.events.length,
    };
  }

  get slipEvents() {
    return this.events;
  }

  get slipEventsCount() {
    return this.events.length;
  }

  get canAddAntepostEvent() {
    return !this.events.some((event) => event.type !== 'antepost');
  }

  allOddsValid = () => {
    return this.getAllOdds().every((odd: Odd) => {
      if (odd) {
        // @ts-ignore
        return odd.value > 1 && odd.visible && !odd.isDisabled;
      }
      return false;
    });
  };

  get changesAccepted() {
    return this.allOddsValid() && this.changedOdds.size === 0;
  }

  get isQuickTicketDisabled() {
    return (
      !this.events.length ||
      this.events.some((event) => event.type === 'live') ||
      !!ticketValidator.errors.length ||
      (this.isSystem && !this.isSystemTicketValid)
    );
  }

  get hasLiveEvents() {
    return this.events.some((event) => event.type === 'live');
  }

  get isSystemTicketValid(): boolean {
    if (!this.isSystem) return true;

    const groupsWithEvents = this.system.groups.filter(
      (g: any) => !!g.events.length
    );
    return groupsWithEvents.every((g: any) => g.system.length > 0);
  }

  get isPlaceBetDisabled(): boolean {
    return (
      !this.events.length ||
      !!ticketValidator.errors.length ||
      !this.isSystemTicketValid
    );
  }

  get totalOdd() {
    return calculateTotalOdd(this.events);
  }

  get amountPerCombination(): number {
    if (this.system.numberOfCombinations > 0) {
      return +this.amount / this.system.numberOfCombinations;
    }

    return 0;
  }

  get calculatedWinningAmount(): number {
    return +this.amount * this.totalOdd;
  }

  get potentialWinningAmount(): number {
    const { rules } = ticketValidator;

    if (
      rules &&
      this.calculatedWinningAmount + this.potentialBonus >
        rules.maximum_winning_amount
    ) {
      return rules.maximum_winning_amount;
    }

    if (this.isSystem) {
      return this.system.maxWinningAmount;
    } else {
      return this.calculatedWinningAmount;
    }
  }

  get bonusEvents(): number {
    return this.events.filter((e: TicketEvent) => {
      if (!e.selectedOdds.length) return false;
      const [odd] = e.selectedOdds;
      return odd.value && odd.value > this.bonusConfig.minOdd;
    }).length;
  }

  get bonusInPercent(): number {
    if (!this.bonusConfig) return 0;

    const bonusRule = this.bonusConfig.bonuses.find((bonusRule: any) => {
      return bonusRule.eventsNumber === this.bonusEvents;
    });

    return bonusRule ? bonusRule.bonus : 0;
  }

  get potentialBonus(): number {
    return (this.calculatedWinningAmount / 100) * this.bonusInPercent;
  }

  get winningAmount(): number {
    if (ticketValidator.rules) {
      if (
        this.potentialWinningAmount + this.potentialBonus >
        ticketValidator.rules.maximum_winning_amount
      ) {
        return ticketValidator.rules.maximum_winning_amount;
      }

      return this.potentialWinningAmount + this.potentialBonus;
    }

    return 0;
  }

  get maxPotentialPayout(): number {
    return (
      this.system.maxWinningAmount +
      (this.system.maxWinningAmount / 100) * this.system.bonusPercent
    );
  }

  get eventsCount(): number {
    return this.events.length;
  }

  formatBonuses = (bonuses: any) => {
    return Object.keys(bonuses).map((eventsNumber: string) => ({
      eventsNumber: Number(eventsNumber),
      bonus: bonuses[eventsNumber],
    }));
  };

  setSystemSlip = () => {
    const { amount, systemGroups } = this.slip;

    ticketsStore
      .calculateSystemSlip({ amount, systemGroups })
      .then((systemSlip) => {
        this.system.bonusPercent = systemSlip.bonusPercent;
        this.system.maxWinningAmount = systemSlip.maxWinningAmount;
        this.system.minWinningAmount = systemSlip.minWinningAmount;
        this.system.maxOddsValue = systemSlip.maxOddsValue;
        this.system.minOddsValue = systemSlip.minOddsValue;
        this.system.maxBonusAmount = systemSlip.maxBonusAmount;
        this.system.minBonusAmount = systemSlip.minBonusAmount;
        this.system.numberOfCombinations = systemSlip.numberOfCombinations;

        if (this.isSystem) {
          ticketValidator.validateSystemAmount(this.amountPerCombination);
          ticketValidator.validateFreeBetTicket(
            this.wallet,
            this.events.length,
            systemSlip.minOddsValue
          );
        }
      });
  };

  getBonusConfig = async () => {
    try {
      const { bonusConfig } = await this.api.getBonusConfig();

      const parsedData = parseJSONString(bonusConfig);

      if (parsedData) {
        const { min_odd_value, num_of_games } = parsedData;

        runInAction(() => {
          this.bonusConfig = {
            minOdd: min_odd_value,
            bonuses: this.formatBonuses(num_of_games),
          };
        });
      }
    } catch (exception: any) {
      logger('TicketBuilder -> getBonusConfig -> exception', exception);
    }
  };

  onRulesFetched = () => {
    runInAction(() => {
      this.amount = String(ticketValidator.minimumBettingAmount);
    });
  };

  changeWallet = (wallet: WalletType) => {
    this.wallet = wallet;
    ticketValidator.validateAmount(
      this.isSystem ? this.amountPerCombination : +this.amount,
      this.wallet,
      this.isSystem
    );
    const minOdd = this.isSystem ? this.system.minOddsValue : this.totalOdd;
    ticketValidator.validateFreeBetTicket(
      this.wallet,
      this.events.length,
      minOdd
    );
  };

  setAmount = (amount: string) => {
    this.amount = amount;
    ticketValidator.validateAmount(
      this.isSystem ? this.amountPerCombination : +this.amount,
      this.wallet,
      this.isSystem
    );
    ticketValidator.validateWinAmount(this.winningAmount, this.isSystem);
    if (this.isSystem) this.setSystemSlip();
  };

  setSystemTicket = (isSystem: boolean) => {
    this.isSystem = isSystem;
    ticketValidator.validateEvents(this.events, isSystem);
    ticketValidator.validateWinAmount(this.winningAmount, isSystem);

    if (this.isSystem) {
      ticketValidator.validateSystemAmount(this.amountPerCombination);
      this.setSystemSlip();
    } else {
      ticketValidator.validateFixAmount(+this.amount);
      ticketValidator.validateFreeBetTicket(
        this.wallet,
        this.events.length,
        this.totalOdd
      );
    }
  };

  clearMultipleOdds = () => {
    this.events = this.events.map((e: any) => ({
      ...e,
      selectedOdds: e.selectedOdds.slice(0, 1),
    }));
  };

  onOddSelected = (event: any) => {
    const { id, odd } = event;
    const hasOutcome = this.hasOutcome(id, odd.id);
    const hasEvent = this.hasEvent(id);

    if (hasOutcome) {
      this.remove(id, odd.id);
    } else if (hasEvent) {
      this.update(event);
    } else {
      this.add(event);
    }

    if (this.isSystem) {
      this.setSystemSlip();
    } else {
      ticketValidator.validateFreeBetTicket(
        this.wallet,
        this.events.length,
        this.totalOdd
      );
    }
  };

  add = (event: any) => {
    const {
      sportId,
      sportName,
      competitionName,
      locationName,
      id,
      type,
      name,
      start,
      eventCode,
      odd,
    } = event;

    this.events.push({
      code: eventCode,
      sportId,
      sportName,
      competitionName,
      locationName,
      id,
      name,
      start,
      type,
      selectedOdds: [odd],
    });

    this.system.add({ id: event.id, oddsCount: 1 }, this.system.groups[0]);
    ticketValidator.validateEvents(this.events, this.isSystem);
    ticketValidator.validateWinAmount(this.winningAmount, this.isSystem);
  };

  update = (event: any) => {
    const e = this.findEvent(event.id);
    if (!e) return false;

    if (this.isSystem) {
      // Allow multiple odds in system ticket
      e.selectedOdds.push(event.odd);

      if (e.selectedOdds.length > ticketValidator.maxEventOdds) {
        e.selectedOdds.shift();
      }

      this.system.update(event.id, e.selectedOdds.length);
    } else {
      e.selectedOdds = [event.odd];
    }

    this.onEventOddsChanged();
  };

  getAllOdds = () => {
    return this.events.reduce((odds: Odd[], event: TicketEvent) => {
      return [...odds, ...event.selectedOdds];
    }, []);
  };

  onEventOddsChanged = () => {
    const allOdds = this.getAllOdds();

    this.changedOdds.forEach((odd: number) => {
      if (!allOdds.some((o) => o.id === odd)) {
        this.changedOdds.delete(odd);
      }
    });
  };

  removeEvent = (eventId: string) => {
    this.acceptEventChangedOdds(eventId);
    this.events = this.events.filter((event) => event.id !== eventId);
    ticketValidator.validateEvents(this.events, this.isSystem);
    ticketValidator.validateWinAmount(this.winningAmount, this.isSystem);

    this.system.removeEvent(eventId);

    if (this.isSystem) {
      this.setSystemSlip();
    } else {
      ticketValidator.validateFreeBetTicket(
        this.wallet,
        this.events.length,
        this.totalOdd
      );
    }
  };

  remove = (eventId: string, oddId: number) => {
    const event = this.findEvent(eventId);
    if (!event) return false;

    event.selectedOdds = event.selectedOdds.filter((o) => o.id !== oddId);

    this.removeEmptyEvents();
    this.system.remove(eventId);
    this.onEventOddsChanged();
    ticketValidator.validateEvents(this.events, this.isSystem);
    ticketValidator.validateWinAmount(this.winningAmount, this.isSystem);

    if (this.isSystem) {
      this.setSystemSlip();
    } else {
      ticketValidator.validateFreeBetTicket(
        this.wallet,
        this.events.length,
        this.totalOdd
      );
    }
  };

  removeEmptyEvents = () => {
    this.events.forEach((e) => {
      if (!e.selectedOdds.length) {
        this.removeEvent(e.id);
      }
    });
  };

  getEmptyEvent = () => {
    return this.events.find((e) => !e.selectedOdds.length);
  };

  removeAll = () => {
    this.events = [];
    this.system.removeAll();
    this.acceptAllChanges();
    ticketValidator.removeError(ValidationRule.MAX_SLIP_ODD);
    ticketValidator.validateEventsLength(this.events.length, this.isSystem);

    if (this.isSystem) {
      this.setSystemSlip();
    } else {
      ticketValidator.validateFreeBetTicket(
        this.wallet,
        this.events.length,
        this.totalOdd
      );
    }
  };

  onEventDisabled = (id: string) => {
    const event = this.findEvent(id);
    if (!event) return;

    event.suspended = true;
    event.selectedOdds.forEach((odd) => {
      this.onOddChange(odd.id);
    });
  };

  onEventEnabled = (id: string) => {
    const event = this.findEvent(id);
    if (!event) return;

    event.suspended = false;
  };

  onOddDisabled = (id: number) => {
    this.events.forEach((event) => {
      event.selectedOdds.forEach((odd) => {
        if (odd.id === id) {
          odd.isDisabled = true;
          this.onOddChange(odd.id);
        }
      });
    });
  };

  onOddEnabled = (id: number) => {
    this.events.forEach((event) => {
      event.selectedOdds.forEach((odd) => {
        if (odd.id === id) {
          odd.isDisabled = false;
        }
      });
    });
  };

  onOddChange = (odd_id: number) => {
    this.changedOdds.add(odd_id);
  };

  acceptAllChanges = (refreshPreview = false) => {
    this.changedOdds.clear();
    this.onChangeAccepted(refreshPreview);
  };

  acceptOneChange = (id: number) => {
    this.changedOdds.delete(id);
    this.onChangeAccepted();
  };

  onChangeAccepted = (refreshPreview = false) => {
    this.filterSuspendedEvents();
    this.filterEventsWithSuspendedOdds();
    this.removeEmptyEvents();

    if (!this.isSystem) {
      ticketValidator.validateFreeBetTicket(
        this.wallet,
        this.events.length,
        this.totalOdd
      );
    }

    if (refreshPreview && !this.events.length) {
      overlayStore.closeModal();
    } else if (userTickets.ticket && refreshPreview) {
      userTickets.preview(this.slip);
    }
  };

  checkDoesEventDeleted = (event: any) => {
    this.events.forEach((odd: any) => {
      if (event.id === odd.id) {
        this.changedOdds.add(odd.id);
        this.onEventDisabled(event.id);
      }
    });
  };

  filterEventsWithSuspendedOdds = () => {
    this.events = this.events.map((event) => ({
      ...event,
      selectedOdds: event.selectedOdds.filter((o) => {
        return !(o === null || !o.visible || o.isDisabled);
      }),
    }));
  };

  filterSuspendedEvents = () => {
    this.events.forEach((e) => {
      if (e.suspended) {
        this.removeEvent(e.id);
      }
    });
  };

  acceptEventChangedOdds = (eventId: string) => {
    const event = this.findEvent(eventId);
    if (!event) return;
    event.selectedOdds.forEach((odd) => this.changedOdds.delete(odd.id));
  };

  setShouldSaveOdds = (shouldSave: boolean) => {
    this.shouldSaveOdds = shouldSave;
  };

  hasOutcome = (eventId: string, oddId: number) => {
    const event = this.findEvent(eventId);
    if (!event) return false;

    return event.selectedOdds.some((o) => o.id === oddId);
  };

  hasEvent = (eventId: string) => {
    return this.events.some((e: any) => e.id === eventId);
  };

  findEvent = (id: string) => {
    return this.events.find((e: any) => e.id === id);
  };
}

export default new TicketBuilder();
