/* React modules */
import React, { ReactNode, useState, useEffect } from 'react';

/* Our modules */
import { IconName } from 'components/Icon/Icon';
import { PillSize, PillType } from 'components/Pill/Pill';
import AccordionHeader from 'components/Accordion/AccordionHeader';
import './Accordion.scss';

/* 3rd Party modules */

type HeaderBackground =
  | 'green'
  | 'dark'
  | 'grey'
  | 'sbgrey'
  | 'matgrey'
  | 'sbdark'
  | 'medium-grey'
  | 'medium-grey-active'
  | 'primary-grey';
type HeaderSize = 'regular' | 'small';

export interface AccordionProps {
  headerProps?: {
    label: string;
    count?: number;
    bg?: HeaderBackground;
    size?: HeaderSize;
    hasPill?: boolean;
    pillVariant?: PillType;
    pillSize?: PillSize;
    icon?: IconName;
    className?: string;
  };
  testId: string;
  isOpen?: boolean;
  children: ReactNode;
  customHeader?: React.FC<any>;
  customHeaderProps?: any;
  onClick?: () => void | null;
  className?: string;
  triggerClose?: boolean;
  hasCheckbox?: boolean;
  onCheck?: (e: any) => void;
  checkboxActive?: boolean;
}

const Accordion = ({
  headerProps,
  children,
  customHeader,
  customHeaderProps,
  isOpen,
  testId,
  onClick,
  triggerClose,
  hasCheckbox,
  onCheck,
  checkboxActive,
}: AccordionProps) => {
  const [active, toggleActive] = useState(false);

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      toggleActive(isOpen);
    }
  }, [isOpen]);

  const toggle = () => {
    toggleActive(!active);
  };

  const closeAccordion = () => {
    toggleActive(false);
  };

  useEffect(() => {
    if (triggerClose) {
      closeAccordion();
    }
  }, [triggerClose]);

  return (
    <div className="accordion" data-testid={testId}>
      {customHeader &&
        customHeader({
          toggleAccordion: toggle,
          isActive: active,
          testId,
          ...customHeaderProps,
        })}
      {headerProps && (
        <AccordionHeader
          {...headerProps}
          onClick={onClick}
          onCheck={onCheck}
          testId={testId}
          isActive={active}
          toggleAccordion={toggle}
          hasCheckbox={hasCheckbox}
          checkboxActive={checkboxActive}
        />
      )}
      <div
        data-testid={`${testId}-content`}
        className={`noselect accordion__content ${active ? 'active' : ''}`}
      >
        {active ? children : null}
      </div>
    </div>
  );
};

export default Accordion;
