/* React modules */
import React, { ChangeEvent, useState } from 'react';

/* Our modules */
import { LoginErrors } from 'modules/auth/auth.models';
import { UserStatuses } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import { Input, Icon, Button } from 'components';
import ResetPassword from 'modules/auth/ui/ResetPassword';
import VerificationModal from 'modules/user/ui/VerificationModal';
import { isNumber, logger } from 'libs/common-helpers';
import './Login.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ERROR_MESSAGES: any = {
  [LoginErrors.WRONG_CREDENTIALS]: 'credentials-error',
  [LoginErrors.USER_BLOCKED]: 'user-blocked-error',
  [LoginErrors.SESSION_EXPIRED]: 'session-expired',
  [LoginErrors.USER_NOT_ACTIVE]: 'user-not-active',
  [LoginErrors.UNKNOWN]: 'error-unknown',
};

export interface LoginFormProps {
  onSuccess?: () => void;
  isModal: boolean;
}

const LoginForm = ({ onSuccess, isModal }: LoginFormProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { authStore, userStore, overlayStore } = useStores();

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (username && password) {
      authStore
        .login({ username, password })
        .then(() => {
          if (onSuccess) {
            onSuccess();

            if (
              authStore.isLoggedIn &&
              UserStatuses.ACTIVATED === userStore.user?.status
            ) {
              overlayStore.openModal(<VerificationModal />, { width: 'small' });
            }
          }

          if (userStore.user && isNumber(userStore.user.id)) {
            /* More details can be found in 'Sportradar Tag Manager Tracking Funnel' document */
            // @ts-ignore
            srtmCommands.push({
              event: 'track.user.login',
              payload: {
                action: 'complete',
                userId: String(userStore.user.id),
              },
            });
          }

          if (window.innerWidth >= 1200 && pathname === '/') {
            navigate('/sport');
          }
        })
        .catch((error: any) => {
          toast.error(t(`errors.${ERROR_MESSAGES[authStore.error || '']}`));
          authStore.clearError();

          logger('LoginForm -> submit -> error', error);
        });
    }
  };

  const resetPassword = () => {
    overlayStore.openModal(<ResetPassword />, {
      className: 'reset-password-modal',
      wrapClassName: 'reset-password-modal-wrap',
      width: 'medium',
    });
  };

  return (
    <form onSubmit={submit}>
      <div className={classNames('login-form', { modal: isModal })}>
        <div className="d-flex w-90">
          <Input
            bg="grey"
            name="username"
            placeholder={`${t('userData.email-address')}/${t(
              'userData.username'
            )}`}
            height="large"
            value={username}
            rounded={false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
            addon={<Icon size="large" name="userFill" />}
          />
        </div>

        <div className="d-flex mt-5 w-90">
          <Input
            bg="grey"
            name="password"
            placeholder={t('userData.password')}
            height="large"
            value={password}
            type="password"
            rounded={false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            addon={<Icon size="large" name="lockFill" />}
          />
        </div>

        <div className="mt-3 d-flex w-90 align-items space-between">
          <span className="ml-1" />

          <span className="text-yellow ml-2 pointer" onClick={resetPassword}>
            {t('login.forgot-password')}
          </span>
        </div>

        <div className="d-flex justify-center align-items mt-4">
          <Button bg="green" size="large" type="submit">
            <div className="d-flex align-items justify-center">
              <Icon name="signIn" />
              <span className="ml-2 text-regular text-bold">
                {t('login.sign-in')}
              </span>
            </div>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default observer(LoginForm);
