/* React modules */
import { useState, useEffect } from 'react';

/* Our modules */
import { Sidebar } from 'components';
import UserOverlay from 'modules/user/ui/UserOverlay';
import Footer from 'components/Footer';
import SinglePromo from 'components/PromoBanner/SinglePromo/SinglePromo';
import { httpBanner } from 'app/axios-config';
import './UserProfile.scss';

/* 3rd Party modules */
import { Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

export const profileRoutes: { [k: string]: string } = {
  TICKETS_OVERVIEW: 'pregled-tiketa',
  QUICK_TICKETS_OVERVIEW: 'brzi-tiketi',
  PASSWORD_CHANGE: 'promena-lozinke',
  DOCUMENTS: 'dokumenta',
  WITHDRAW: 'isplata',
  DEPOSIT: 'uplata',
  TRANSACTIONS: 'transakcije',
  SETTINGS: 'podesavanja',
  PROMO: 'promo-kod',
  MARKET_INFO: 'market-info',
  MARKET: 'market',
  MARKET_HISTORY: 'istorija-kupovine',
  MARKET_CART: 'korpa',
  POKER_DEPOSIT: 'poker-uplata',
  POKER_WITHDRAW: 'poker-isplata',
  RESPONSIBLE_GAMBLING: 'odgovorno-kladjenje',
};

const UserProfile = observer(() => {
  const location = useLocation();

  const [sidebarBanners, setSidebarBanners] = useState<any>(null);

  const displayGlobalFooter = () => {
    const routesWithoutGlobalFooter = [
      profileRoutes.TRANSACTIONS,
      profileRoutes.TICKETS_OVERVIEW,
      profileRoutes.QUICK_TICKETS_OVERVIEW,
    ];

    return !routesWithoutGlobalFooter.includes(location.pathname);
  };

  const fetchBanners = async () => {
    const { data } = await httpBanner.get(`web/data.json`);

    if (data) {
      setSidebarBanners(data.user_profile_side);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div className="user-profile">
      <div className="visible-tablet-up">
        <Sidebar borderRight>
          <UserOverlay />
        </Sidebar>
      </div>

      <div className="w-100 user-profile__content">
        <div>
          <Outlet />
        </div>

        {displayGlobalFooter() && <Footer />}
      </div>

      <div className="user-profile__promo-sidebar-wrapper">
        <Sidebar borderLeft>
          <div className="user-profile__promo-sidebar">
            {sidebarBanners?.map(
              (banner: any, index: number) =>
                banner && (
                  <SinglePromo
                    key={`UserProfile-SinglePromo-${index}`}
                    promo={banner}
                    isOpen={true}
                    sidebarBanner={true}
                  />
                )
            )}
          </div>
        </Sidebar>
      </div>
    </div>
  );
});

export default UserProfile;
