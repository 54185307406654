/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import {
  SingleTicket,
  TicketStatuses,
  PlaceBetPayload,
  SlipPayloadGroup,
  ApprovalStatuses,
  TicketStatus,
  TicketStatusGroups,
  PreviewData,
  TicketOptions,
} from 'modules/ticket/ticket.types';
import { WalletTypes } from 'modules/wallet/wallet.types';
import { TICKET_FILTERS } from 'modules/ticket/ticket.constants';
import { RESULTS_MAP } from 'modules/sports/constants';
import TicketApi from 'modules/ticket/ticket.api';
import NotificationsApi from 'modules/notifications/notifications.api';
import ticketValidator from 'modules/ticket/store/ticket-validator';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import Odd from 'modules/sports/store/odd.store';
import userStore from 'modules/user/user.store';
import authStore from 'modules/auth/auth.store';
import sportsStore from 'modules/sports/store/sports.store';
import {
  parseResult,
  getEventCompetitors,
} from 'modules/sports/store/event.store';
import loaderStore from 'components/Loader/loader.store';
import { NODE_TYPE } from 'modules/sports/services/sport-node.service';
import { logger, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import { runInAction, makeAutoObservable } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';

const TICKETS_PAGE_SIZE = 35;

class UserTickets {
  ticket: PreviewData | null = null;
  api: TicketApi;
  notificationsApi: NotificationsApi;
  quickCode: string | null = null;
  approvalTickets: any[] = [];
  cashoutAmount: any = null;
  shouldAcceptChanges: boolean = true;
  approvalTimeout: number = 0;
  timerActive: boolean = false;
  copyCount: number = 0;

  cashoutTimerActive: boolean = false;
  cashoutDenied: boolean = false;
  validTime: number = 0;
  ticketsRequestInProgress = false;
  quickSlipCodes: any = [];
  topWinningTickets: any[] = [];
  tickets: any = [];

  ticketsPagination = {
    pageSize: TICKETS_PAGE_SIZE,
    page: 1,
    hasNext: true,
  };

  constructor() {
    this.api = new TicketApi();
    this.notificationsApi = new NotificationsApi();
    makeAutoObservable(this);
  }

  getApprovalHandlers() {
    return {
      [ApprovalStatuses.APPROVED]: this.onApprovalStatusChange,
      [ApprovalStatuses.DENIED]: this.onApprovalStatusChange,
      [ApprovalStatuses.CHANGED]: this.onApprovalStatusChange,
      [ApprovalStatuses.CASHOUT_APPROVED]: this.onCashoutApproved,
      [ApprovalStatuses.CASHOUT_DENIED]: this.onCashoutDenied,
    };
  }

  openApprovalSocket(token: string): void {
    this.api.listenForApprovalChanges(token, this.onApprovalStatusChange);
  }

  closeApprovalSocket(): void {
    this.api.closeSocket();
  }

  setShouldAcceptChanges = (val: boolean) => {
    this.shouldAcceptChanges = val;
  };

  get approvalTimer() {
    return new Date(this.approvalTimeout * 1000 - Date.now()).getSeconds();
  }

  get cashoutTimer() {
    return new Date(this.validTime * 1000 - Date.now()).getSeconds();
  }

  get manuallyApprovedTickets() {
    return this.approvalTickets.filter(
      (t: any) => t.type === ApprovalStatuses.APPROVED
    );
  }

  get manuallyDeniedTickets() {
    return this.approvalTickets.filter(
      (t: any) => t.type === ApprovalStatuses.DENIED
    );
  }

  get manuallyChangedTickets() {
    return this.approvalTickets.filter(
      (t: any) => t.type === ApprovalStatuses.CHANGED
    );
  }

  removeTicketFromApproval = (slipId: string) => {
    this.approvalTickets = this.approvalTickets.filter(
      (t) => t.slip_id !== slipId
    );
  };

  getTopWinningTickets = async () => {
    try {
      const data = await this.api.getTopWinningTickets();
      runInAction(() => (this.topWinningTickets = data.slipsList));
    } catch (exception: any) {
      logger('UserTickets -> getTopWinningTickets -> exception', exception);
    }
  };

  getSlipData = (groups: any): SlipPayloadGroup[] => {
    return groups.map((group: any) => ({
      system:
        group.system.replaceAll(' ', '') ||
        `${group.events.length}/${group.events.length}`,
      events: group.events.map((e: any) => ({
        event_id: e.id,
        odds: e.selectedOdds.map((odd: Odd) => ({
          id: odd.id,
          odd_value: odd.value,
        })),
      })),
    }));
  };

  getSlipOdds = (groups: any): SlipPayloadGroup[] => {
    return groups?.map((group: any) => ({
      system:
        group.system.replaceAll(' ', '') ||
        `${group.events.length}/${group.events.length}`,
      events: group.events.map((e: any) => ({
        event_id: e.id,
        odds: e.selectedOdds.map((odd: Odd) => ({
          id: odd.value?.toString(),
        })),
      })),
    }));
  };

  clearApprovalTimer() {
    this.timerActive = false;
  }

  clearCashout() {
    this.validTime = 0;
    this.cashoutTimerActive = false;
    this.cashoutAmount = null;
  }

  placeBet = async ({
    amount,
    systemGroups,
  }: {
    amount: number;
    systemGroups: any;
  }) => {
    if (!authStore.token) return;
    loaderStore.setActive(true);

    const payload: PlaceBetPayload = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipData(systemGroups),
      shouldAcceptChanges: this.shouldAcceptChanges,
    };

    if (ticketBuilder.wallet === WalletTypes.PROMO) {
      payload.wallet = 'free_bet';
    } else if (ticketBuilder.wallet === WalletTypes.PROMO_BET_BONUS) {
      payload.wallet_prefix = 'promo_bet';
    }

    const response = await this.api.placeBet(payload, authStore.token);

    loaderStore.setActive(false);

    logger('UserTickets -> placeBet -> response', response);

    if (response.status === TicketStatus.DENIED) {
      this.onPlaceBetError(response.error);
    } else if (
      !response.status &&
      response.error.messageList[0] === TicketStatus.USER_BLOCKED
    ) {
      toast.error(i18n.t('errors.user-blocked-error'));
    } else if (
      !response.status &&
      response.error.messageList[0] === 'ERR_NO_LIVE_ODDS_CHANGE'
    ) {
      this.onPlaceBetError(response.error);
    } else if (!response.status && response.error) {
      this.onPlaceBetError(response.error);
    } else {
      this.onPlaceBetSuccess(response);
    }
  };

  placeBetLive = async ({
    amount,
    systemGroups,
  }: {
    amount: number;
    systemGroups: any;
  }) => {
    if (!authStore.token) return;
    loaderStore.setActive(true);

    const payload: PlaceBetPayload = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipData(systemGroups),
      shouldAcceptChanges: this.shouldAcceptChanges,
    };

    if (ticketBuilder.wallet === WalletTypes.PROMO) {
      payload.wallet = 'free_bet';
    } else if (ticketBuilder.wallet === WalletTypes.PROMO_BET_BONUS) {
      payload.wallet_prefix = 'promo_bet';
    }

    const response = await this.api.placeBet(payload, authStore.token, true);

    loaderStore.setActive(false);

    if (response.status === TicketStatus.DENIED) {
      this.onPlaceBetError(response.error);
    } else if (
      !response.status &&
      response.error.messageList[0] === TicketStatus.USER_BLOCKED
    ) {
      toast.error(i18n.t('errors.user-blocked-error'));
    } else if (
      !response.status &&
      response.error.messageList[0] === 'ERR_NO_LIVE_ODDS_CHANGE'
    ) {
      this.onPlaceBetError(response.error);
    } else if (!response.status && response.error) {
      this.onPlaceBetError(response.error);
    } else {
      this.onPlaceBetSuccess(response);
    }
  };

  calculateSystemSlip = async ({
    amount,
    systemGroups,
  }: {
    amount: string;
    systemGroups: any;
  }) => {
    const payload: any = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipOdds(systemGroups),
    };

    const response = await this.api.calculateSlipValues(
      payload,
      authStore.token
    );
    return response;
  };

  getQuickCode = async ({
    amount,
    systemGroups,
  }: {
    amount: number;
    systemGroups: any;
  }) => {
    loaderStore.setActive(true);

    const payload: PlaceBetPayload = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipData(systemGroups),
    };

    try {
      const { code, error } = await this.api.getQuickCode(
        payload,
        authStore.token
      );

      this.quickCode = code || null;

      return error && (error.messageList || []).length ? error.messageList : [];
    } catch (exception: any) {
      this.onPlaceBetError(exception.data);

      logger('UserTickets -> getQuickCode -> exception', exception);
    } finally {
      loaderStore.setActive(false);
    }

    return false;
  };

  getUserQuickCodes = async () => {
    if (!authStore.token) return;

    loaderStore.addLoader('tickets-table');

    try {
      const data = await this.api.getUserQuickCodes(authStore.token);

      runInAction(() => {
        this.quickSlipCodes = data.fastSlipsList;
      });
    } catch (exception: any) {
      logger('UserTickets -> getUserQuickCodes -> exception', exception);
    } finally {
      loaderStore.removeLoader('tickets-table');
    }
  };

  onPlaceBetSuccess = async (slip: any) => {
    this.preview(slip, { fromApi: true });
    this.displayNotification(slip.status);
    await userStore.getUserData();
    if (!ticketBuilder.shouldSaveOdds) {
      ticketBuilder.removeAll();
    }
  };

  onPlaceBetError = (error: any) => {
    toast.error(i18next.t('errors.place-bet-error'));

    if (error && error.messageList) {
      ticketValidator.onServerError(error.messageList);
    }
  };

  resetCashoutAmount = () => {
    this.cashoutAmount = null;
  };

  acceptTicketChanges = async () => {
    if (!authStore.token) return;
    const { ticket } = this;

    if (!ticket || !ticket.slipId) return;
    this.clearApprovalTimer();

    try {
      await this.api.acceptTicketChanges(ticket.slipId, authStore.token);
      toast.success('Tiket je uspešno uplaćen');
      if (ticket.shortUuid) {
        this.getTicket(ticket.shortUuid, { disableTimer: true });
      }
    } catch (exception: any) {
      toast.error(i18n.t('errors.system-error'));

      logger('UserTickets -> acceptTicketChanges -> exception', exception);
    } finally {
      userStore.getUserData();
    }
  };

  denyTicketChanges = async (timeExceeded: boolean = false) => {
    if (!authStore.token) return;
    const { ticket } = this;
    if (!ticket || !ticket.slipId) return;
    this.clearApprovalTimer();

    try {
      await this.api.denyTicketChanges(ticket.slipId, authStore.token);
      if (timeExceeded) {
        toast.success(i18n.t('verification.deny-changes-timer'));
      } else {
        toast.success(i18n.t('verification.deny-changes'));
      }
      return true;
    } catch (exception: any) {
      toast.error(i18n.t('errors.system-error'));

      logger('UserTickets -> denyTicketChanges -> exception', exception);
    } finally {
      userStore.getUserData();
    }
  };

  get canCopyTicket() {
    return this.copyCount === 0;
  }

  resetCopyCount = () => {
    this.copyCount = 0;
  };

  copyTicket = () => {
    this.copyCount += 1;
    ticketBuilder.removeAll();
    this.ticket?.systemGroups.forEach((group: any) => {
      group.subGroupsList.forEach((event: any) => {
        const e = sportsStore.allEvents.find(
          (e: any) => event.event.id === e.id
        );
        if (e) {
          event.oddsList.forEach((odd: any) => {
            let selectedOdd = e.oddsList.find((o: any) => +o.id === +odd.id);
            if (selectedOdd) {
              selectedOdd = {
                ...odd,
                ...selectedOdd,
                id: selectedOdd.id,
                eventId: e.id,
                displayValue: String(selectedOdd.value),
                visible: true,
                market: { name: odd.marketName, getName: () => odd.marketName },
              };
              ticketBuilder.onOddSelected({ ...e, odd: { ...selectedOdd } });
            }
          });
        }
      });
    });
    if (!this.ticket?.isFix) this.groupCopiedSystemTicket();
    if (ticketBuilder.eventsCount < 1) {
      toast.error(i18n.t('errors.no-events-for-copy'));
      this.resetCopyCount();
    }
  };

  groupCopiedSystemTicket = () => {
    ticketBuilder.setSystemTicket(true);
    this.ticket?.systemGroups.forEach((group: any, index) => {
      const systemTypes = group.type
        .split('/')[0]
        .split('+')
        .map((item: string) => +item);
      ticketBuilder.system.setSystem(
        systemTypes,
        ticketBuilder.system.groups[index]
      );

      group.subGroupsList.forEach((subGroup: any, i: number) => {
        if (index > 0)
          ticketBuilder.system.move(
            subGroup.event.id,
            ticketBuilder.system.groups[index]
          );
      });
    });
  };

  resetTickets = () => {
    runInAction(() => {
      this.ticketsPagination.hasNext = true;
      this.ticketsPagination.page = 0;
      this.tickets = [];
    });
  };

  setTicketsPage = (page: number) => {
    this.ticketsPagination.page = page;
  };

  getTickets = async (
    filterGroup: TicketStatusGroups,
    dateFilter?: [Date, Date],
    id?: string
  ) => {
    this.ticketsRequestInProgress = true;
    loaderStore.addLoader('tickets-table');
    let status: TicketStatus[] = TICKET_FILTERS[filterGroup];

    try {
      const data = await this.api.getTickets(
        this.ticketsPagination,
        status,
        dateFilter,
        id,
        authStore.token
      );
      runInAction(() => {
        this.ticketsPagination.hasNext =
          data.slipsList.length === this.ticketsPagination.pageSize;
        this.tickets.push(...data.slipsList);
      });
    } catch (exception: any) {
      logger('UserTickets -> getTickets -> exception', exception);
    } finally {
      loaderStore.removeLoader('tickets-table');
      this.ticketsRequestInProgress = false;
    }
  };

  getTicket = async (
    id: string,
    options?: { isNew?: boolean; disableTimer: boolean }
  ) => {
    if (!authStore.token) return;

    try {
      const data = await this.api.getTicket(id, authStore.token);

      if (
        !options?.disableTimer &&
        data.manualChangeTime?.seconds &&
        data.status !== TicketStatus.DENIED
      ) {
        this.approvalTimeout = data.manualChangeTime.seconds;
        this.timerActive = true;
      }

      if (data.status === TicketStatus.DENIED) {
        this.clearApprovalTimer();
      }

      if (options && options.isNew) {
        this.displayNotification(data.status as TicketStatuses);
      }

      this.preview(data, { fromApi: true });

      return this.ticket;
    } catch (exception: any) {
      toast.error(i18n.t('errors.ticket-error-reading'));

      logger('UserTickets -> getTicket -> exception', exception);
    }
  };

  getSharedTicket = async (id: string) => {
    try {
      const data = await this.api.getSharedTicket(id);

      this.preview(data, { fromApi: true });

      return this.ticket;
    } catch (exception: any) {
      toast.error(i18n.t('errors.ticket-error-reading'));

      logger('UserTickets -> getSharedTicket -> exception', exception);
    }
  };

  getQuickTicket = async (id: string, options?: { isNew: boolean }) => {
    if (!authStore.token) return;

    try {
      const data = await this.api.getQuickTicket(id, authStore.token);

      if (options && options.isNew) {
        this.displayNotification(data.status);
      }

      this.preview(data, { fromApi: true });
      return this.ticket;
    } catch (exception: any) {
      toast.error(i18n.t('errors.ticket-error-reading'));

      logger('UserTickets -> getQuickTicket -> exception', exception);
    }
  };

  displayNotification = (status: TicketStatuses) => {
    if (status === TicketStatus.APPROVING) {
      toast.info(i18n.t('verification.ticket-waiting-approval'));
    } else if (status === TicketStatus.NOT_RESOLVED) {
      toast.success(i18n.t('verification.ticket-paid'));
    }
  };

  preview = (ticket: any, options?: TicketOptions) => {
    if (options && options.fromApi) {
      this.ticket = this.normalize(ticket);
    } else {
      this.ticket = ticket;
    }
  };

  onApprovalStatusChange = (approvalData: any) => {
    if (authStore.token) {
      this.notificationsApi.confirmMessageReceived(
        approvalData.uuid,
        authStore.token
      );
    }

    this.approvalTickets.push(approvalData);
    userStore.getUserData();
  };

  sendSlipForCashout = async () => {
    if (!authStore.token || !this.ticket?.slipId) return;
    loaderStore.setActive(true);
    const data = await this.api.sendSlipForCashout(
      this.ticket.slipId,
      authStore.token
    );
    if (data.error) {
      toast.error(i18n.t('slips.slip-not-eligible-for-cashout'));
      loaderStore.setActive(false);
    }
  };

  acceptCashout = () => {
    this.clearCashout();

    if (!authStore.token || !this.ticket?.slipId) return;
    loaderStore.setActive(true);
    this.api.acceptCashout(this.ticket.slipId, authStore.token).then(() => {
      loaderStore.setActive(false);
      toast.success(i18n.t('slips.cashout-accepted'));
      this.getTicket(this.ticket?.shortUuid as string, { disableTimer: true });
    });
  };

  denyCashout = (deniedManually: boolean = false) => {
    this.cashoutDenied = true;
    this.clearCashout();

    if (!authStore.token || !this.ticket?.slipId) return;
    loaderStore.setActive(true);

    this.api.denyCashout(this.ticket.slipId, authStore.token).then(() => {
      if (deniedManually) {
        toast.info(i18n.t('slips.cashout-denied'));
      } else {
        toast.info(i18n.t('slips.cashout-denied-timer'));
      }

      loaderStore.setActive(false);
    });
  };

  cancelCashout = () => {
    this.clearCashout();
    this.cashoutDenied = true;
    loaderStore.setActive(false);
    if (!authStore.token || !this.ticket?.slipId) return;

    this.api.cancelCashout(this.ticket.slipId, authStore.token);
  };

  onCashoutApproved = async (data: any) => {
    this.cashoutDenied = false;

    if (authStore.token) {
      this.notificationsApi.confirmMessageReceived(data.uuid, authStore.token);
    }

    runInAction(() => {
      this.cashoutTimerActive = true;
      this.validTime = data.valid_time;
      this.cashoutAmount = data.cashout_amount;
    });

    loaderStore.setActive(false);
  };

  onCashoutDenied = async (data: any) => {
    if (authStore.token) {
      this.notificationsApi.confirmMessageReceived(data.uuid, authStore.token);
    }

    this.clearCashout();
    loaderStore.setActive(false);
  };

  normalize = (ticket: SingleTicket): PreviewData => {
    const {
      id,
      status,
      bettingAmount,
      actualWinningAmount,
      maxWinningAmount,
      maxWinningTotal,
      minWinningAmount,
      minWinningTotal,
      maxBonusAmount,
      minBonusAmount,
      numberOfMatches,
      maxOddsValue,
      minOddsValue,
      slipGroupsList,
      bettingTime,
      actualWinningNoBonus,
      isSystem,
      shortUuid,
      oldBettingAmount,
      hasCashout,
      cashout,
      bonusPercent,
      slipType,
    } = ticket;

    return {
      slipId: id,
      shortUuid,
      bonus: maxBonusAmount || 0,
      minBonus: minBonusAmount || 0,
      winningAmount: maxWinningTotal,
      potentialWinningAmount: maxWinningAmount,
      minWinningTotal,
      minWinningAmount,
      actualWinningAmount,
      actualWinningNoBonus,
      totalOdd: maxOddsValue,
      minOdd: minOddsValue,
      amount: bettingAmount,
      cashout,
      bonusPercent,
      slipType,
      hasCashout,
      oldAmount: oldBettingAmount,
      eventsCount: numberOfMatches,
      systemGroups: slipGroupsList?.map((group: any) => ({
        ...group,
        system: group.type,
        events: group.subGroupsList.map(({ event, oddsList, status }: any) => {
          const sport = sportsStore.sportsData?.findSportById(event.sportId);
          const competition = sport?.findNode(
            NODE_TYPE.COMPETITION,
            event.competitionId
          );
          const location = sport?.findNode(
            NODE_TYPE.LOCATION,
            event.locationId
          );
          const eventFromStream = competition?.children.find((e: any) => {
            return e.id === event.id;
          });

          const [home, away] = getEventCompetitors(event);

          return {
            ...event,
            sportId: event.sportId,
            locationId: event.locationId,
            locationName: location?.name,
            competitionId: event.competitionId,
            competitionName: competition?.name,
            code: event.landbaseCode,
            sportName: sport?.name,
            name: event.name,
            status,
            odd: {
              ...oddsList[0],
              outcome: oddsList[0].outcome,
              outcomeName: oddsList[0].outcomeName,
              market: {
                id: oddsList[0].marketId,
                name: oddsList[0].marketName,
                englishName: oddsList[0].englishName,
                albanianName: oddsList[0].albanianName,
                turkishName: oddsList[0].turkishName,
                russianName: oddsList[0].russianName,
                ukrainianName: oddsList[0].ukrainianName,
                italianName: oddsList[0].italianName,
                germanName: oddsList[0].germanName,
                getName: () => oddsList[0].marketName,
              },
            },
            selectedOdds: oddsList.map((odd: any) => ({
              oddId: odd.id,
              status: odd.status,
              limit: odd.limit,
              oldLimit: odd.oldLimit,
              oldValue: odd.oldValue.toFixed(2),
              displayValue: odd.value.toFixed(2),
              outcome: { name: odd.outcomeName, id: odd.outcomeId },
              outcomeDisplay:
                odd.outcomeTic || `${odd.outcomeGroup} ${odd.outcomeName}`,
              market: { name: odd.marketName, getName: () => odd.marketName },
              livePlayer: odd.livePlayer ? odd.livePlayer : '',
              event: eventFromStream || {
                home,
                away,
                currentResult: this.formatShortResult(
                  event.result,
                  sport?.name || '',
                  status
                ),
                periodsShort: this.formatShortPeriods(
                  event.result,
                  sport?.name || ''
                ),
              },
            })),

            start: event.startTime,
          };
        }),
      })),
      isFix: !isSystem,
      status,
      created_at: bettingTime,
    };
  };

  formatShortResult(result: any, sportName: string, status: any) {
    const resultMap = RESULTS_MAP[sportName];
    if (!result || !resultMap) return [];

    const { currentResult } = result;
    const { currentScoringTypes, finalScoringTypes, replacingScoreValues } =
      resultMap;

    const scoringTypes =
      status !== '__NOT_RESOLVED__' && !!finalScoringTypes
        ? finalScoringTypes
        : currentScoringTypes;

    return (scoringTypes || []).map((scoringType: any) => {
      const { key, name } = scoringType;

      const result =
        currentResult && key
          ? currentResult[key] || currentResult[key.toLowerCase()]
          : null;

      const parsed = parseResult(result, replacingScoreValues);

      return {
        name,
        homeScore: parsed[0],
        awayScore: parsed[1],
      };
    });
  }

  formatShortPeriods(result: any, sportName: string) {
    const resultMap = RESULTS_MAP[sportName];

    if (!result || !resultMap) return [];

    const { liveResultStats } = result;

    const { replacingScoreValues, shortPeriodKeys, periodScoringKey } =
      resultMap;

    return (shortPeriodKeys || [])
      .map((key: string) => {
        const parsedData = liveResultStats
          ? parseJSONString(liveResultStats)
          : null;

        const periodResult = parsedData && key ? parsedData[key] : null;

        if (!periodResult) return null;

        const result = periodScoringKey
          ? periodResult[periodScoringKey] ||
            periodResult[periodScoringKey.toLowerCase()]
          : null;

        const parsed = parseResult(result, replacingScoreValues);

        return {
          name: key,
          homeScore: parsed[0],
          awayScore: parsed[1],
        };
      })
      .filter((r: any) => !!r);
  }
}

export default new UserTickets();
