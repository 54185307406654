/* React modules */

/* Our modules */
import { FeedMessageType } from 'modules/feed/feed.api';
import SportsApi from 'modules/sports/services/sports.api';
import offerFilters from 'modules/offer/store/offer.filters';
import OfferSort from 'modules/offer/store/offer.sort';
import feedStore from 'modules/feed/feed.store';
import OfferFactory from 'modules/offer/services/offer-factory.service';

/* 3rd Party modules */
import { makeAutoObservable, runInAction } from 'mobx';

class OfferStore {
  offerFactory: OfferFactory;
  api: SportsApi;

  offerState = {
    liveDisabled: false,
    upcomingDisabled: false,
  };

  liveDisabled = false;
  disabledSports: number[] = [];
  activeGroup: string | null = null;
  appSetupCount: number = 0;

  constructor() {
    this.api = new SportsApi();
    this.offerFactory = new OfferFactory();
    this.init();
    // makeObservable(this);
    makeAutoObservable(this);
  }

  init = () => {
    this.getLiveDisableStatus();
    this.connectToFeed();
  };

  connectToFeed = () => {
    feedStore.registerHandler(
      FeedMessageType.DISABLED_SPORT,
      this.handleSportDisable
    );
    feedStore.registerHandler(
      FeedMessageType.DISABLED_ALL,
      this.handleLiveDisable
    );
  };

  get getLiveDisabled() {
    return this.liveDisabled;
  }

  get isAppSetup() {
    return this.appSetupCount > 0;
  }

  increaseAppSetupCount = () => {
    this.appSetupCount += 1;
  };

  getLiveDisableStatus = () => {
    this.api.getLiveDisableStatus().then((response: any) => {
      const { all, sportIdsList } = response;

      runInAction(() => {
        this.liveDisabled = all;
        this.disabledSports = sportIdsList;
      });
    });
  };

  setActiveGroup = (group: string | null) => {
    this.activeGroup = group;
  };

  handleSportDisable = (data: any) => {
    const { is_disabled, sport_id } = data;

    if (is_disabled) {
      this.disabledSports.push(sport_id);
    } else {
      this.disabledSports = this.disabledSports.filter((s) => s !== sport_id);
    }
  };

  handleLiveDisable = (data: any) => {
    runInAction(() => {
      this.liveDisabled = data.is_disabled;
    });
  };

  get offer() {
    const { activeSports, activeEventTypes } = offerFilters;

    const config = {
      offerState: this.offerState,
      eventTypes: activeEventTypes,
    };

    let offer: any = this.offerFactory.build(activeSports, config);
    offer = OfferSort.adjustOfferForPlayersAndSpecials(offer);

    return offer;
  }

  get mobileOffer() {
    const { selectedSport, activeEventTypes } = offerFilters;
    if (!selectedSport) return [];

    const config = {
      offerState: this.offerState,
      eventTypes: activeEventTypes,
    };

    return this.offerFactory.build([selectedSport.id], config);
  }
}

export default new OfferStore();
