/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import './CreditDebitBalance.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const CreditDebitBalance = () => {
  const { t } = useTranslation();
  const { userStore } = useStores();

  return (
    <div className="balance mt-5">
      <div className="w-33 d-flex justify-center">
        <div className="status-wrapper">
          <span className="status-title">{t('payments.balance')}</span>
          <p className="status-amount">
            {userStore.standardWallet?.balanceDisplay}
          </p>
        </div>
      </div>
      {userStore.reservedFunds && (
        <div className="w-33 d-flex justify-center">
          <div className="status-wrapper">
            <span className="status-title">{t('wallet.reserved-funds')}</span>
            <p className="status-amount">
              {userStore.reservedFunds.balanceDisplay}
            </p>
          </div>
        </div>
      )}
      <div className="w-33 d-flex justify-center">
        <div className="status-wrapper">
          <span className="status-title">
            {t('payments.available-for-payment')}
          </span>
          <p className="status-amount">
            {userStore.standardWallet?.availableFunds}
          </p>
        </div>
      </div>
      <div className="w-33 d-flex justify-center">
        <>
          {userStore.freeBetBonus && (
            <div className="status-wrapper">
              <span className="status-title">
                {t('wallet.promo-to-unlock')}
              </span>
              <p className="status-amount">
                {userStore.freeBetBonus.balanceDisplay}
              </p>
            </div>
          )}

          {userStore.casinoBonus && (
            <div className="status-wrapper">
              <span className="status-title">
                {t('wallet.casino-to-unlock')}
              </span>
              <p className="status-amount">
                {userStore.casinoBonus.balanceDisplay}
              </p>
            </div>
          )}

          {!userStore.casinoBonus && !userStore.freeBetBonus && (
            <div className="status-wrapper">
              <span className="status-title">
                {t('payments.bonus-balance')}
              </span>
              <p className="status-amount">0.00 EUR</p>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default observer(CreditDebitBalance);
