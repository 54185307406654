/* React modules */
import { Children } from 'react';

/* Our modules */

/* 3rd Party modules */
import Slider, { Settings } from 'react-slick';

export function JackpotCarousel(props: Settings) {
  return Children.count(props.children) > 1 ? (
    <Slider
      {...{
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 6000,
        cssEase: 'linear',
      }}
      {...props}
    >
      {props.children}
    </Slider>
  ) : (
    <>{props.children}</>
  );
}
