/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { UserStatuses } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import SectionHeader from 'modules/user/ui/SectionHeader';
import CreditDebitFilter from 'modules/user/ui/UserCreditDebit/CreditDebitFilter';
import CreditCard from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Debit/CreditCard';
import VerificationModal from 'modules/user/ui/VerificationModal';
import '../CreditDebitBody.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Debit = observer(() => {
  const { t } = useTranslation();

  const { authStore, userStore, overlayStore } = useStores();

  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs = [{ label: t('payments.credit-card'), value: 0 }];

  const onSelect = (tabId: number) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (
      authStore.isLoggedIn &&
      UserStatuses.ACTIVATED === userStore.user?.status
    ) {
      overlayStore.openModal(<VerificationModal />, { width: 'small' });
    }
  }, []);

  return (
    <div className="user-credit-debit">
      <SectionHeader title={t('payments.debit')} />

      <div className="user-credit-debit__header">
        <CreditDebitFilter tabs={tabs} onTabSelected={onSelect} />
      </div>

      <div className="user-credit-debit__body w-100">
        {activeTab === 0 && <CreditCard />}
      </div>
    </div>
  );
});

export default Debit;
