/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Sidebar } from 'components';
import { LoaderWrapper } from 'components/Loader';
import PromoCarousel from 'modules/gambling/ui/PromoSidebar/PromoCarousel';
import SingleGame from 'modules/gambling/ui/SingleGame/SingleGame';
import './PromoSidebar.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const GameOfDay = ({ game, label }: any) => {
  return (
    <div className="game-of-day">
      <span className="game-of-day__header">{label}</span>
      <div className="game-of-day__game">
        <SingleGame
          {...game}
          size="large"
          displayName={true}
          displayAmount={false}
        />
      </div>
    </div>
  );
};

const PromoSidebar = () => {
  const { t } = useTranslation();
  const { gamblingStore } = useStores();
  const { gameOfDay, lastPlayed, biggestWin } = gamblingStore;

  // Fallback random games
  const fakeGame = () => gamblingStore.games[Math.floor(Math.random() * 20)];
  const fakeGames = [fakeGame(), fakeGame(), fakeGame()];

  const lastPlayedGames =
    lastPlayed?.length === 0 || !lastPlayed ? fakeGames : lastPlayed;
  const biggestWinGames =
    biggestWin?.length === 0 || !biggestWin ? fakeGames : biggestWin;

  return (
    <Sidebar borderLeft>
      <LoaderWrapper name="dynamic-sections">
        <div className="promo-sidebar">
          <PromoCarousel
            displayName={true}
            variant="biggest-win"
            title={t('casino.biggest-wins')}
            games={biggestWinGames}
            biggestWin
            displayAmount={true}
          />
          <div>
            <LoaderWrapper name="game-of-day">
              <GameOfDay
                game={gameOfDay || fakeGame()}
                label={t('casino.game-of-week')}
              />
            </LoaderWrapper>
          </div>
          <PromoCarousel
            variant="most-played"
            title={t('casino.last-played')}
            games={lastPlayedGames}
            displayName={true}
          />
        </div>
      </LoaderWrapper>
    </Sidebar>
  );
};

export default observer(PromoSidebar);
