/* React modules */

/* Our modules */
import { BettingPlacesResponse } from 'modules/betting-places/betting-places.types';
import { UserServiceClient } from 'proto/user/UserServiceClientPb';
import { GetBettingPlacesBoRequest } from 'proto/user/user_pb';
import ProtobufApi from 'libs/protobufApi';
import { getApiUrl } from 'libs/urlBuilder';

/* 3rd Party modules */

class BettingPlacesApi {
  private client: UserServiceClient;
  private api: ProtobufApi;

  constructor() {
    this.client = new UserServiceClient(getApiUrl(), {}, {});
    this.api = new ProtobufApi(this.client);
  }

  getBettingPlaces(): Promise<BettingPlacesResponse> {
    const request = new GetBettingPlacesBoRequest();

    return this.api.request<BettingPlacesResponse>('getBettingPlacesBO', [
      request,
      {},
    ]);
  }
}

export default BettingPlacesApi;
