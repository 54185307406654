/* React modules */

/* Our modules */
import { Sport } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import SportNode from 'modules/sports/services/sport-node.service';
import {
  getSportEventsCount,
  getLocationEventsCount,
  getCompetitionEventsCount,
} from 'modules/offer/services/offer-helpers.service';
import { Sidebar, Accordion, Checkbox } from 'components';
import { LoaderWrapper } from 'components/Loader';
import { SPORT_ICONS } from 'components/Icon/Icon';
import TopWinningTickets from 'modules/ticket/ui/TopWinningTickets';
import './SportsSidebar.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface SportsSidebarProps {
  sports: Sport[];
}

interface CompetitionSelectProps {
  sportId: number;
  competition: any;
  locationId: number;
  allEventsLoaded: boolean;
}

interface RenderSportLocationProps {
  sportLocation: any;
  sportId: number;
  isNodeSelected: (sportId: number, node: SportNode) => boolean;
  toggleNode: (sportId: number, node: SportNode) => void;
}

interface RenderSportProps {
  sport: any;
}

const CompetitionSelect = observer(
  ({
    sportId,
    competition,
    locationId,
    allEventsLoaded,
  }: CompetitionSelectProps) => {
    const { offerFilters, sportsStore } = useStores();

    const {
      isCompetitionSelected,
      timeFilterFunction,
      hideCompetition,
      showCompetition,
    } = offerFilters;
    const { offerCounters } = sportsStore;
    const { id, name } = competition;

    let eventsCountBeforeStreamLoaded: any = 0;
    eventsCountBeforeStreamLoaded = getCompetitionEventsCount(
      sportId,
      locationId,
      competition.id,
      offerCounters,
      offerFilters.timeFilter
    );

    const eventsCountAfterStreamLoaded = competition.getEventsCount(
      (e: any) => timeFilterFunction(e.start.seconds),
      offerFilters.activeEventTypes
    );

    if (!eventsCountBeforeStreamLoaded || eventsCountBeforeStreamLoaded < 1)
      return null;
    if (!eventsCountAfterStreamLoaded || eventsCountAfterStreamLoaded < 1)
      return null;

    const isSelected = isCompetitionSelected(sportId, [id]);

    const toggleSport = () => {
      if (isSelected) {
        hideCompetition(sportId, [id]);
      } else {
        showCompetition(sportId, [competition]);
      }
    };

    return (
      <div
        className={classNames('sports-sidebar__competition', {
          active: isSelected,
        })}
      >
        <div className="sports-sidebar__checkbox_wrapper">
          <Checkbox
            key={`CompetitionSelect-Checkbox-${competition.id}`}
            indent
            label={name}
            onChange={toggleSport}
            value={isSelected}
            tooltip={name}
          />
        </div>
        <div className="sports-sidebar__competition_count text-small">
          {allEventsLoaded
            ? eventsCountAfterStreamLoaded
            : eventsCountBeforeStreamLoaded}
        </div>
      </div>
    );
  }
);

const RenderSportLocation = observer(
  ({
    sportLocation,
    sportId,
    isNodeSelected,
    toggleNode,
  }: RenderSportLocationProps) => {
    const { sportsStore, offerFilters } = useStores();
    const { offerCounters, allEventsLoaded } = sportsStore;
    const { timeFilterFunction } = offerFilters;

    let eventsCountBeforeStreamLoaded = 0;

    eventsCountBeforeStreamLoaded =
      getLocationEventsCount(
        sportId,
        sportLocation,
        offerCounters,
        offerFilters.timeFilter
      ) || 0;

    const eventsCountAfterStreamLoaded = sportLocation.getEventsCount(
      (e: any) => timeFilterFunction(e.start.seconds),
      offerFilters.activeEventTypes
    );

    if (!eventsCountBeforeStreamLoaded || eventsCountBeforeStreamLoaded < 1)
      return null;

    if (!eventsCountAfterStreamLoaded || eventsCountAfterStreamLoaded < 1)
      return null;

    return (
      <Accordion
        key={`RenderSportLocation-Accordion-${sportLocation.id}`}
        testId="one"
        headerProps={{
          bg: 'sbdark',
          label: sportLocation.name.toUpperCase(),
          count: allEventsLoaded
            ? eventsCountAfterStreamLoaded
            : eventsCountBeforeStreamLoaded,
          hasPill: true,
          pillVariant: 'dark',
          size: 'small',
          className: isNodeSelected(sportId, sportLocation)
            ? 'active-sport'
            : '',
        }}
        hasCheckbox={true}
        checkboxActive={isNodeSelected(sportId, sportLocation)}
        onCheck={() => toggleNode(sportId, sportLocation)}
      >
        {sportLocation.getCompetitions().map((competition: any) => (
          <CompetitionSelect
            key={`RenderSportLocation-CompetitionSelect-${competition.id}`}
            sportId={sportId}
            competition={competition}
            locationId={sportLocation.id}
            allEventsLoaded={allEventsLoaded}
          />
        ))}
      </Accordion>
    );
  }
);

const RenderSport = observer(({ sport }: RenderSportProps) => {
  const { t } = useTranslation();

  const { sportsStore, offerFilters } = useStores();
  const { offerCounters, allEventsLoaded } = sportsStore;
  const {
    timeFilterFunction,
    isCompetitionSelected,
    showCompetition,
    hideCompetition,
  } = offerFilters;

  let eventsCountBeforeStreamLoaded = 0;

  eventsCountBeforeStreamLoaded =
    getSportEventsCount(sport, offerCounters, offerFilters.timeFilter) || 0;

  const eventsCountAfterStreamLoaded = sport.getEventsCount(
    (e: any) => timeFilterFunction(e.start.seconds),
    offerFilters.activeEventTypes
  );

  const isNodeSelected = (sportId: number, node: SportNode) => {
    const competitions = node.getCompetitions();

    return isCompetitionSelected(
      sportId,
      competitions.map((c: SportNode) => c.id)
    );
  };

  const toggleNode = (sportId: number, node: SportNode) => {
    const competitions = node.getCompetitions();

    if (!isNodeSelected(sportId, node)) {
      showCompetition(sportId, competitions);
    } else {
      hideCompetition(
        sportId,
        competitions.map((c: SportNode) => c.id)
      );
    }
  };

  if (!eventsCountBeforeStreamLoaded || eventsCountBeforeStreamLoaded < 1)
    return null;

  if (!eventsCountAfterStreamLoaded || eventsCountAfterStreamLoaded < 1)
    return null;

  return (
    <Accordion
      key={`RenderSport-Accordion-${sport.id}`}
      testId="main"
      headerProps={{
        bg: 'grey',
        label: String(t(`sports.${sport.name}`)).toUpperCase(),
        count: allEventsLoaded
          ? eventsCountAfterStreamLoaded
          : eventsCountBeforeStreamLoaded,
        hasPill: true,
        pillVariant: 'dark',
        icon: SPORT_ICONS[sport.name.toLowerCase()],
        size: 'small',
        className: offerFilters.activeSports.some((e: any) => e === sport.id)
          ? 'active-sport'
          : '',
      }}
      hasCheckbox={true}
      onCheck={() => toggleNode(sport.id, sport)}
      checkboxActive={offerFilters.activeSports.some(
        (e: any) => e === sport.id
      )}
      triggerClose={!offerFilters.activeSports.some((e: any) => e === sport.id)}
    >
      <div>
        {sport.getLocations().map((sportLocation: any, index: number) => (
          <RenderSportLocation
            key={`RenderSport-RenderSportLocation-${sport.id}-${sportLocation.id}-${index}`}
            sportLocation={sportLocation}
            sportId={sport.id}
            isNodeSelected={isNodeSelected}
            toggleNode={toggleNode}
          />
        ))}
      </div>
    </Accordion>
  );
});

const SportsSidebar = observer(() => {
  const {
    sportsStore: { sportsData },
  } = useStores();

  return (
    <Sidebar borderRight>
      <div className="sports-sidebar">
        <div className="sports-sidebar__sports-wrapper">
          <LoaderWrapper name="sports">
            {(sportsData?.sportsList || []).length
              ? sportsData?.sportsList.map((sport: any, index: number) => (
                  <RenderSport
                    key={`SportsSidebar-RenderSport-${sport.id}-${index}`}
                    sport={sport}
                  />
                ))
              : null}
          </LoaderWrapper>
        </div>
        <div>
          <TopWinningTickets />
        </div>
      </div>
    </Sidebar>
  );
});

export default SportsSidebar;
