import { useState, useEffect } from 'react';

import Odd, {
  ChangeDirection,
  OddDirection,
} from 'modules/sports/store/odd.store';

interface Options {
  automaticallyResetHighlight: boolean;
}

const HIGHLIGHT_TIME = 3000;

const defaultOptions = {
  automaticallyResetHighlight: true,
};

const useHighlight = (data: Odd, options: Options = defaultOptions) => {
  const { changeDirection } = data;
  const { automaticallyResetHighlight } = options;

  const [highlightState, highlight] = useState<ChangeDirection>(null);

  const isUp = () => {
    return highlightState === OddDirection.UP;
  };

  const isDown = () => {
    return highlightState === OddDirection.DOWN;
  };

  const isInitial = () => {
    return highlightState === OddDirection.INITIAL;
  };

  useEffect(() => {
    if (highlightState && automaticallyResetHighlight) {
      setTimeout(() => {
        highlight(null);
        data.resetChangeDirection();
      }, HIGHLIGHT_TIME);
    }
  }, [highlightState, highlight, automaticallyResetHighlight, data]);

  /** Handle odd updates from live feed */
  useEffect(() => {
    highlight(changeDirection);
  }, [changeDirection]);

  return {
    highlight,
    highlightState,
    isUp,
    isDown,
    isInitial,
  };
};

export default useHighlight;
