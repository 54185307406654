import React from 'react';

import './FileInput.scss';

export interface FileInputProps {
  label?: string;
  onChange: (arg: any) => void;
  value: FileList;
  name: string;
  btnLabel: string;
  className?: string | '';
  error?: string;
  onBlur?: (arg: any) => void;
  accept?: string;
}

const FileInput = ({
  label,
  onChange,
  value,
  name,
  btnLabel,
  className,
  error,
  onBlur,
  accept,
}: FileInputProps) => {
  return (
    <div className={`file-input ${className}`}>
      {label && <div className="text-small mb-1">{label}</div>}
      <div className="d-flex align-items">
        <label htmlFor={name}>{btnLabel}</label>
        <input
          onChange={(e) => onChange(e.target.files)}
          id={name}
          type="file"
          name={name}
          className="fileinput"
          onBlur={onBlur}
          {...(accept ? { accept } : {})}
        />
        {value && (
          <div className="ml-1 text-small text-white text-elipsis">
            {value[0]?.name}
          </div>
        )}
      </div>
      {error && <div className="file-input__error">{error}</div>}
    </div>
  );
};

FileInput.defaultProps = {
  className: '',
};

export default FileInput;
