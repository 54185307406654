/* React modules */

/* Our modules */
import { HomeCard } from 'pages/home/Home';
import './_page-card.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

interface PageCardProps {
  data: HomeCard;
}

const PageCard = observer(({ data }: PageCardProps) => {
  const navigate = useNavigate();

  const handleRoute = (path: string) => () => {
    if (path) {
      if (path.includes('http')) {
        window.open(path, '_blank', 'noreferrer');
      } else {
        navigate(path);
      }
    }
  };

  return (
    data && (
      <div
        className="d-flex flex-column text-center page-card pointer"
        onClick={handleRoute(data.path)}
      >
        <p className="mt-3 py-4 bg-black-0 title">{data.title}</p>

        <div className="d-flex align-items mt-5 mb-8 px-3 description">
          <p>{data.description}</p>
        </div>
      </div>
    )
  );
});

export default PageCard;
