/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Odd from 'modules/sports/store/odd.store';
import Event from 'modules/sports/store/event.store';
import { Icon } from 'components';
import useHighlight from 'modules/offer/ui/Offer/OddCell/use-highlight';
import './MobileOdd.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface MobileOddProps {
  event: Event;
  odd: Odd;
}

const MobileOdd = ({ event, odd }: MobileOddProps) => {
  const { t } = useTranslation();
  const { ticketBuilder, ticketValidator } = useStores();
  const { isUp, isDown } = useHighlight(odd);
  const isSuspended =
    odd.isDisabled ||
    (odd.market && event.suspendedMarkets.includes(odd.market.id));

  useEffect(() => {
    odd.connectToFeed();

    return () => {
      if (!odd.isSelected) {
        odd.disconnectFromFeed();
      }
    };
  }, [odd]);

  const handleSelect = () => {
    if (
      ticketValidator?.displayError?.key === 'validations.max-number-of-events'
    )
      return;
    if (!isSuspended && odd.visible) {
      ticketBuilder.onOddSelected({ ...event, odd });
    }
  };

  const classes = {
    down: isDown(),
    up: isUp(),
    active: odd.isSelected,
    suspended: isSuspended,
  };

  const outcomeGroupsToShow = [1509, 1510, 1511, 1512, 1513, 1514, 1515, 1516];

  return (
    <div
      key={`MobileOdd-div-${odd.id}`}
      className={classNames('mobile-odd', classes, { disabled: isSuspended })}
      onClick={handleSelect}
    >
      {odd.visible && (
        <>
          <div className="mb-1">
            {/* @ts-ignore */}
            {outcomeGroupsToShow.includes(odd?.outcome?.id) && (
              //  @ts-ignore
              <span>{t(`globals.${odd.outcome.outcomeGroup}`)} &nbsp;</span>
            )}
            <span>{odd.outcome?.name.toUpperCase()}</span>
          </div>
          <div className="mobile-odd__value">
            <span>{odd.displayValue}</span>

            {isSuspended && (
              <Icon
                className="ml-1"
                style={{ position: 'relative', left: '4px' }}
                name="lockSmall"
                size="regular"
              />
            )}
          </div>
        </>
      )}

      {!odd.visible && <span className="py-2">-</span>}
    </div>
  );
};

export default observer(MobileOdd);
