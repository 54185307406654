import React, { ReactNode } from 'react';

import './Pill.scss';

export type PillType = 'default' | 'success' | 'dark';
export type PillSize = 'small' | 'regular' | 'large';

interface PillProps {
  children: ReactNode;
  type: PillType;
  testId?: string;
  size?: PillSize;
  className: string;
}

const Pill = ({ children, className, type, size, testId }: PillProps) => (
  <div
    className={`pill pill-${type} ${className} ${size}`}
    data-testid={testId}
  >
    {children}
  </div>
);

Pill.defaultProps = {
  type: 'default',
  className: '',
  size: 'regular',
};

export { Pill };
