/* React modules */

/* Our modules */
import { EventType, EVENT_TYPE } from 'modules/sports/sports.types';
import sportsStore from 'modules/sports/store/sports.store';
import { filterBasketballPlayerMarkets } from 'modules/offer/services/offer-helpers.service';
import { breakpoints } from 'libs/viewport';

/* 3rd Party modules */
import { makeAutoObservable } from 'mobx';

const DESKTOP_MARKETS_NUMBER = 3;
const MOBILE_MARKETS_NUMBER = 3;

const COLUMNS_COUNT = [
  [breakpoints.XXL, 3],
  [breakpoints.XL, 2],
  [breakpoints.LG, 2],
];

export const DUPLICATE_MARKETS = [10010, 11, 210, 259];

class MarketSwitcher {
  DESKTOP_MARKETS_NUMBER = DESKTOP_MARKETS_NUMBER;
  $desktopSelection = new Map();
  $mobileMarkets: any[] = [];
  $mobileSelection: string[] = [];

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  initDesktopSelection = () => {
    sportsStore.marketGroups.forEach((value: any, sport: number) => {
      var { live, upcoming, antepost, player, special } = value.initialMarkets;

      if (sport === 11) {
        player = filterBasketballPlayerMarkets(player);
      }

      const selectedUpcoming = upcoming.slice(0, this.DESKTOP_MARKETS_NUMBER);
      const selectedLive = live.slice(0, this.DESKTOP_MARKETS_NUMBER);
      const selectedPlayer = player.slice(0, this.DESKTOP_MARKETS_NUMBER);
      const selectedSpecial = special.slice(0, this.DESKTOP_MARKETS_NUMBER);

      this.$desktopSelection.set(sport, {
        live: selectedLive,
        upcoming: selectedUpcoming,
        antepost,
        player: selectedPlayer,
        special: selectedSpecial,
      });
    });
  };

  get desktopMarketsNumber() {
    return this.DESKTOP_MARKETS_NUMBER;
  }

  switchOnDesktop = (
    sport: number,
    type: EventType,
    selected: string,
    position: number
  ) => {
    const currentSelection = this.getFromDesktopSelection(sport, type);
    const allMarkets = this.getSportMarkets(sport, type);

    if (currentSelection && allMarkets) {
      const newMarket = allMarkets?.find((m: any) => m.name === selected);

      currentSelection.splice(position, 1, newMarket);
    }
  };

  updateColumnsNumber = (width: number) => {
    const countBySize = COLUMNS_COUNT.find(([size]) => width > size);
    if (!countBySize) return;

    const [, count] = countBySize;
    this.DESKTOP_MARKETS_NUMBER = count;
    this.initDesktopSelection();
  };

  getHiddenOnDesktop = (sport: number, type: EventType) => {
    const allMarkets = this.getSportMarkets(sport, type);
    let marketIds: any = [];
    let uniqueMarkets: any = [];

    allMarkets.forEach((m: any) => {
      if (!marketIds.includes(m.id)) {
        marketIds.push(m.id);
        uniqueMarkets.push(m);
      }
    });

    if (sport === 11 && type === EVENT_TYPE.PLAYER) {
      uniqueMarkets = filterBasketballPlayerMarkets(uniqueMarkets);
    }

    return uniqueMarkets?.filter(
      (m: any) => !this.isSelectedOnDesktop(sport, type, m)
    );
  };

  isSelectedOnDesktop = (sport: number, type: EventType, market: any) => {
    const markets = this.$desktopSelection.get(sport);
    if (!markets) return false;
    const matchedMarkets = markets[type].filter((m: any) => m.id === market.id);

    if (DUPLICATE_MARKETS.includes(market.id) && type === EVENT_TYPE.LIVE) {
      return matchedMarkets.length === 2;
    }

    return matchedMarkets.length === 1;
  };

  getFromDesktopSelection = (sport: number, type: EventType) => {
    const markets = this.$desktopSelection.get(sport);
    if (markets) return markets[type];
  };

  updateMobileMarkets = (sport: number, type: EventType) => {
    this.$mobileMarkets = this.getSportMarkets(sport, type);
    this.$mobileSelection = this.$mobileMarkets
      .slice(0, MOBILE_MARKETS_NUMBER)
      .map((m) => m.name);
  };

  switchOnMobile = (marketName: string) => {
    this.$mobileSelection.shift();
    this.$mobileSelection.push(marketName);
  };

  isSelectedOnMobile = (marketName: string) => {
    return !this.$mobileSelection.includes(marketName);
  };

  getSportMarkets = (sport: number, type: EventType) => {
    const markets = sportsStore.marketGroups.get(sport)?.initialMarkets;

    if (markets) {
      return markets[type];
    } else {
      return [];
    }
  };
}

export default new MarketSwitcher();
