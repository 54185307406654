import React, { ChangeEvent } from 'react';

import { Input } from 'components';
import classnames from 'classnames';

export interface BankAccountProps {
  value: Array<string>;
  onChange: (arg: any) => void;
  onBlur?: (arg: any) => void;
  error?: string;
  className: string;
  height?: 'regular' | 'small' | 'large';
}

const BankAccount = ({
  value,
  onChange,
  error,
  onBlur,
  className = '',
  height,
}: BankAccountProps) => {
  const onAccountChange =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const temp = [...value];
      temp[index] = e.target.value;
      onChange(temp);
    };

  const handleOnBlur = (e: any) => {
    if (onBlur) {
      if (e.target.value && value.length > 2) {
        onBlur && onBlur({ target: { value: [...value, e.target.value] } });
      } else {
        onBlur({ target: { value: '' } });
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-between mb-1 align-items">
        <Input
          name="account-0"
          className={classnames('w-15', { [className]: className })}
          height={height}
          bg="light"
          onChange={onAccountChange(0)}
          value={value[0]}
          onBlur={handleOnBlur}
          maxLength={3}
        />
        <div className="mx-1">-</div>
        <Input
          name="account-1"
          bg="light"
          height={height}
          className={classnames('w-60', { [className]: className })}
          onChange={onAccountChange(1)}
          value={value[1]}
          onBlur={handleOnBlur}
        />
        <div className="mx-1">-</div>
        <Input
          className={classnames('w-12', { [className]: className })}
          name="account-2"
          height={height}
          bg="light"
          onChange={onAccountChange(2)}
          value={value[2]}
          onBlur={handleOnBlur}
          maxLength={2}
        />
      </div>
      {error && <div className="text-small text-red">{error}</div>}
    </>
  );
};

export default BankAccount;
