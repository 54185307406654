export enum LoginErrors {
  WRONG_CREDENTIALS = 1,
  USER_NOT_ACTIVE,
  USER_BLOCKED,
  SESSION_EXPIRED,
  UNKNOWN,
}

export type LoginError =
  | LoginErrors.WRONG_CREDENTIALS
  | LoginErrors.USER_NOT_ACTIVE
  | LoginErrors.UNKNOWN
  | LoginErrors.SESSION_EXPIRED
  | LoginErrors.USER_BLOCKED
  | null;

export interface Credentials {
  username: string;
  password: string;
}

export enum ChangePassErrors {
  WRONG_PASSWORD = 1,
  UNKNOWN,
}

export type ChangePassError =
  | ChangePassErrors.WRONG_PASSWORD
  | ChangePassErrors.UNKNOWN
  | null;

export enum ResetPassErrors {
  CAN_NOT_FIND_USER = 1,
  TOKEN_EXPIRED,
}

export type ResetPassError =
  | ResetPassErrors.CAN_NOT_FIND_USER
  | ResetPassErrors.TOKEN_EXPIRED
  | null;
