import ReactSwitch from 'react-switch';

export interface SwitchProps {
  onChange: (arg: boolean) => void;
  checked: boolean;
  disabled: boolean;
}

const COLORS = {
  darkgrey: '#1F1F1F',
  green: '#a9acbe',
  grey: '#00a551',
};

const Switch = ({ onChange, checked }: SwitchProps) => {
  return (
    <ReactSwitch
      height={18}
      width={40}
      handleDiameter={20}
      checked={checked}
      onChange={onChange}
      onColor={COLORS.darkgrey}
      offColor={COLORS.darkgrey}
      offHandleColor={COLORS.green}
      onHandleColor={COLORS.grey}
      uncheckedIcon={false}
      checkedIcon={false}
    />
  );
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
};

export default Switch;
