/* React modules */

/* Our modules */
import SportsApi from 'modules/sports/services/sports.api';
import { EVENT_TYPE, Sport } from 'modules/sports/sports.types';
import loaderStore from 'components/Loader/loader.store';
import SportMarkets from 'modules/sports/services/sports-markets.service';
import SportsData from 'modules/sports/services/sports-data.service';
import {
  sortSportsList,
  parseSportsMapToArray,
  parseMarketsMapToArray,
  sortCompetitions,
} from 'modules/sports/sports.service';
import { NODE_TYPE } from 'modules/sports/services/sport-node.service';
import { grpcErrorListHandler } from 'common/services/grpc-error-list-service';
import { logger, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import { autorun, runInAction, makeAutoObservable } from 'mobx';

class SportsStore {
  api: SportsApi;
  events: any[] = [];
  sportsList: any[] = [];
  public offerCounters: any = new Map();

  specialsSport: any;
  firstBatchLoaded = false;
  allEventsLoaded = false;
  sportsLoaded = false;
  sportsData: SportsData | null = null;
  marketGroups: Map<number, SportMarkets> = new Map();
  playerOutrightMarketsConfig: any = {};
  allEvents: any = [];
  deltaTimestamp: string | number = '';
  isPokerEnabled: boolean = false;
  liveStreamURL: string = '';
  isLiveStreamPlaying: boolean = false;

  constructor() {
    this.api = new SportsApi();

    /*
    makeObservable(this, {
      deltaTimestamp: observable,
      sportsData: observable,
      firstBatchLoaded: observable,
      allEventsLoaded: observable,
      sportsLoaded: observable,
      playerOutrightMarketsConfig: observable,
      isPokerEnabled: observable,
      onEnd: action,
      resetState: action,
      onPlayerOutrightMarketsLoad: action,
    });
    */
    makeAutoObservable(this);

    autorun(() => {
      if (this.firstBatchLoaded && this.sportsLoaded) {
        runInAction(() => {
          this.sportsData = new SportsData(this.sportsList, this.events);
          loaderStore.removeLoader('sports');
        });
      }

      if (this.allEventsLoaded && this.sportsLoaded) {
        runInAction(() => {
          this.sportsData = new SportsData(this.sportsList, this.events);
          loaderStore.removeLoader('sports');
        });

        this.events = [];
      }
    });
  }

  setLiveStreamURL = (url: string) => {
    this.liveStreamURL = url || '';
  };

  setIsLiveStreamPlaying = (isPlaying: boolean) => {
    this.isLiveStreamPlaying = isPlaying;
  };

  refetchEvent = async (sportId: number, competitionId: number, id: string) => {
    const sport = this.sportsData?.findSportById(sportId);
    const competition = sport?.findNode(NODE_TYPE.COMPETITION, competitionId);

    if (competition) {
      competition.removeEvent(id);

      try {
        const event = await this.api.getEventOdds(id);
        competition.addEvent(event);
      } catch (exception: any) {
        logger('SportsStore -> refetchEvent -> exception', exception);
      }
    }
  };

  resetState = () => {
    this.sportsList = [];
    this.marketGroups = new Map();
    this.sportsData = null;
    this.playerOutrightMarketsConfig = {};
    this.firstBatchLoaded = false;
    this.allEventsLoaded = false;
    this.sportsLoaded = false;
  };

  loadData = () => {
    loaderStore.addLoader('sports');

    return this.api
      .getPlayerOutrightMarkets()
      .then(this.onPlayerOutrightMarketsLoad)
      .then(this.checkInitialData)
      .then(() =>
        this.api.fetchAllStream(this.events, this.onEventsLoad, this.onEnd)
      );
  };

  loadPokerConfiguration = () => {
    this.api.getTimestamp().then((response) => {
      this.isPokerEnabled = response.data.po;
    });
  };

  onOfferCounters = async (data: any) => {
    this.offerCounters = new Map(data.sportsMap);
  };

  checkInitialData = () => {
    let sports: any = null;
    let markets: any = null;

    let request = window.indexedDB.open('initialData', 1);

    request.onupgradeneeded = (e: any) => {
      const db = e.target.result;
      const objectStore = db.createObjectStore('initialData');
    };

    request.onsuccess = (e: any) => {
      const db = e.target.result;
      const objStore = db
        .transaction('initialData', 'readwrite')
        .objectStore('initialData');

      const sportsStore = objStore.get('sports');
      const marketsStore = objStore.get('markets');

      sportsStore.onsuccess = (e: any) => {
        if (e.target.result) sports = parseJSONString(e.target.result);

        marketsStore.onsuccess = (e: any) => {
          if (e.target.result) markets = parseJSONString(e.target.result);

          this.api.getTimestamp().then((response) => {
            this.isPokerEnabled = response.data.po;

            const isNewTimestamp = this.checkIsNewDeltaTimestamp(response);

            if (isNewTimestamp || !sports || !markets) {
              this.api
                .getSports()
                .then(this.onSportsLoad)
                .then(() => this.api.getMarkets())
                .then(this.onMarketsLoad);
            } else if (!isNewTimestamp) {
              this.api.getInitialDelta().then(this.onInitialDeltaLoad);
            }
          });

          this.api.getOfferCounters('+02').then(this.onOfferCounters);
        };
      };
    };
  };

  onEnd = () => {
    this.allEventsLoaded = true;
    this.firstBatchLoaded = true;

    if (this.sportsLoaded) {
      loaderStore.removeLoader('sports');
    }
  };

  onEventsLoad = (data: any) => {
    data
      .getUpcomingEvents()
      .toObject()
      .eventsList.forEach((o: any) => {
        o.oddsList.forEach((odd: any) => {
          odd.value = odd.value / 100;
          odd.limit = odd.limit / 100;
        });
        this.events.push({ ...o, type: EVENT_TYPE.UPCOMING });
      });

    data
      .getLiveEvents()
      .toObject()
      .eventsList.forEach((o: any) => {
        o.oddsList.forEach((odd: any) => {
          odd.value = odd.value / 100;
          odd.limit = odd.limit / 100;
        });
        this.events.push({ ...o, type: EVENT_TYPE.LIVE });
      });

    data
      .getPlayerEvents()
      .toObject()
      .eventsList.forEach((o: any) => {
        o.oddsList.forEach((odd: any) => {
          odd.value = odd.value / 100;
          odd.limit = odd.limit / 100;
        });
        this.events.push({ ...o, type: EVENT_TYPE.PLAYER });
      });

    data
      .getGroupEvents()
      .toObject()
      .eventsList.forEach((o: any) => {
        o.oddsList.forEach((odd: any) => {
          odd.value = odd.value / 100;
          odd.limit = odd.limit / 100;
        });
        this.events.push({ ...o, type: EVENT_TYPE.SPECIAL });
      });

    data
      .getOutrightEvents()
      .toObject()
      .eventsList.forEach((o: any) => {
        o.oddsList.forEach((odd: any) => {
          odd.value = odd.value / 100;
          odd.limit = odd.limit / 100;
        });
        this.events.push({ ...o, type: EVENT_TYPE.ANTEPOST });
      });

    const firstBatchPiece = 80;

    if (this.events.length >= firstBatchPiece) {
      this.firstBatchLoaded = true;
    }

    this.allEvents = this.events;
  };

  onSportsLoad = (sports: any) => {
    const sportsList = parseSportsMapToArray(sports);

    let request = window.indexedDB.open('initialData', 1);

    request.onupgradeneeded = (e: any) => {
      const db = e.target.result;
      const objectStore = db.createObjectStore('initialData');
    };

    request.onsuccess = (e: any) => {
      const db = e.target.result;
      let objStore = db
        .transaction('initialData', 'readwrite')
        .objectStore('initialData');
      objStore.put(JSON.stringify(sports), 'sports');
    };

    this.specialsSport = sportsList.find(
      (s: any) => s.id === this.playerOutrightMarketsConfig?.sportId
    );
    this.sportsList = sportsList.filter(
      (s: any) => s.id !== this.specialsSport?.id
    );
    this.sportsList = sortCompetitions(this.sportsList);
    this.sportsList = sortSportsList(this.sportsList);
  };

  onMarketsLoad = (markets: any) => {
    let request = window.indexedDB.open('initialData', 1);

    request.onupgradeneeded = (e: any) => {
      const db = e.target.result;
      const objectStore = db.createObjectStore('initialData');
    };

    request.onsuccess = (e: any) => {
      const db = e.target.result;
      let objStore = db
        .transaction('initialData', 'readwrite')
        .objectStore('initialData');
      objStore.put(JSON.stringify(markets), 'markets');
    };

    const marketGroups = parseMarketsMapToArray(markets);

    marketGroups.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    marketGroups.forEach((mg: any) => {
      mg.marketsList.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    });

    this.sportsList.forEach((sport: Sport) => {
      this.marketGroups.set(
        sport.id,
        new SportMarkets(sport, marketGroups, this.playerOutrightMarketsConfig)
      );
    });

    runInAction(() => {
      this.sportsLoaded = true;
    });

    if (this.firstBatchLoaded) {
      loaderStore.removeLoader('sports');
    }
  };

  onInitialDeltaLoad = (data: any) => {
    let sportsMap = null;

    let request = window.indexedDB.open('initialData', 1);

    request.onupgradeneeded = (e: any) => {
      const db = e.target.result;
      const objectStore = db.createObjectStore('initialData');
    };

    request.onsuccess = (e: any) => {
      const db = e.target.result;
      const objStore = db
        .transaction('initialData', 'readwrite')
        .objectStore('initialData');
      const sportsStore = objStore.get('sports');

      sportsStore.onsuccess = (e: any) => {
        sportsMap = parseJSONString(e.target.result);

        const oldSportsList = parseSportsMapToArray(sportsMap);
        const delta = parseSportsMapToArray(data);

        delta.forEach((sport: any) => {
          const localSport = oldSportsList.find((x: any) => x.id === sport.id);

          if (!localSport) {
            oldSportsList.push(sport);
            return;
          }

          localSport.name = sport.name;

          sport.locationsList.forEach((location: any) => {
            const localLocation = localSport.locationsList.find(
              (x: any) => x.id === location.id
            );

            if (!localLocation) {
              localSport.locationsList.push(location);
              return;
            }

            localLocation.name = location.name;
            localLocation.flagCode = location.flagCode;

            location.competitionsList.forEach((competition: any) => {
              const localCompetition = localLocation.competitionsList.find(
                (x: any) => x.id === competition.id
              );

              if (!localCompetition) {
                localLocation.competitionsList.push(competition);
                return;
              }

              localCompetition.name = competition.name;
              localCompetition.competitionOrder = competition.competitionOrder;
              localCompetition.locationOrder = competition.locationOrder;
              localCompetition.isFavorite = competition.isFavorite;
              localCompetition.shortName = competition.shortName;
            });
          });
        });
        this.specialsSport = oldSportsList.find(
          (s: any) => s.id === this.playerOutrightMarketsConfig.sportId
        );
        this.sportsList = oldSportsList.filter(
          (s: any) => s.id !== this.specialsSport.id
        );
        this.sportsList = sortCompetitions(this.sportsList);
        this.sportsList = sortSportsList(this.sportsList);
        let markets: any = null;

        let request = window.indexedDB.open('initialData', 1);

        request.onupgradeneeded = (e: any) => {
          const db = e.target.result;
          const objectStore = db.createObjectStore('initialData');
        };

        request.onsuccess = (e: any) => {
          const db = e.target.result;
          const objStore = db
            .transaction('initialData', 'readwrite')
            .objectStore('initialData');
          const marketsStore = objStore.get('markets');

          marketsStore.onsuccess = (e: any) => {
            markets = parseJSONString(e.target.result);

            if (markets) this.onMarketsLoad(markets);
          };
        };
      };
    };
  };

  onPlayerOutrightMarketsLoad = (playerMarkets: any) => {
    this.playerOutrightMarketsConfig = playerMarkets;
  };

  checkIsNewDeltaTimestamp = (data: any) => {
    let oldTimestamp = null;

    const storedDeltaTimestamp = localStorage.getItem('deltaTimestamp');

    if (storedDeltaTimestamp) {
      oldTimestamp = parseJSONString(storedDeltaTimestamp);

      if (!oldTimestamp) {
        localStorage.removeItem('deltaTimestamp');
      }
    }

    const deltaTimestamp = localStorage.getItem('deltaTimestamp');

    if (deltaTimestamp) {
      oldTimestamp = parseJSONString(deltaTimestamp);

      if (!oldTimestamp) {
        localStorage.removeItem('deltaTimestamp');
      }
    }

    const newTimestamp = data.data.ts;

    if (newTimestamp === oldTimestamp) {
      return false;
    } else {
      localStorage.setItem('deltaTimestamp', JSON.stringify(newTimestamp));
      return true;
    }
  };

  getMissingCompetition = (id: number) => {
    return this.api.getMissingCompetition(id);
  };

  getPublicIP = async () => {
    let result = '';

    try {
      const { data } = await this.api.getPublicIP();

      if (data) {
        result = data;
      }
    } catch (exception: any) {
      logger('SportsStore -> getPublicIP -> exception', exception);
    }

    return result;
  };

  getLiveStreamURL = async (
    streamId: string,
    userId: number,
    accessToken: string
  ) => {
    try {
      const publicIP = await this.getPublicIP();

      if (publicIP) {
        const { url, error } = await this.api.getLiveStreamURL(
          streamId,
          userId,
          publicIP,
          accessToken
        );

        if (error) {
          const { messageList } = error;

          grpcErrorListHandler(messageList);
        }

        this.setLiveStreamURL(url || '');
      }
    } catch (exception: any) {
      logger('SportsStore -> getLiveStreamURL -> exception', exception);
    }
  };
}

export default new SportsStore();
