/* React modules */
import { useState, useEffect } from 'react';

/* Our modules */
import { Input, Icon } from 'components';
import { setDateFromDatePicker, formatDatePickerDate } from 'libs/datetime';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './DatePicker.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';
import DatePicker, {
  DayValue,
  DayRange,
} from 'react-modern-calendar-datepicker';

const DatePickerComponent = ({
  onChange,
  className,
  error,
  onBlur,
  customInput,
  isRange,
  value,
  ...props
}: any) => {
  const { t } = useTranslation();

  const [selectedDay, setSelecteDay] = useState<DayValue>(null);

  const [range, setRange] = useState<DayRange>({
    from: null,
    to: null,
  });

  const handleOnChange = (value: any) => {
    if (isRange) {
      if (value.from && value.to) {
        onChange(formatRangeValue(value));
      }

      setRange(value);
    } else {
      onChange({ target: { value: dayToString(value) } });

      setSelecteDay(value);
    }
  };

  const dayToString = (selectedValue: DayValue) => {
    if (selectedValue) {
      const { day, month, year } = selectedValue;

      return `${day}.${month}.${year}`;
    }

    return '';
  };

  const formatInputValue = () => {
    if (isRange) {
      const { from, to } = range;

      if (from && !to) {
        return dayToString(from);
      }

      return `${dayToString(from)} - ${dayToString(to)}`;
    }

    return dayToString(selectedDay);
  };

  const formatRangeValue = (value: DayRange) => {
    return [setDateFromDatePicker(value.from), setDateFromDatePicker(value.to)];
  };

  const clear = () => {
    if (isRange) {
      setRange({ from: null, to: null });
      onChange([]);
    } else {
      setSelecteDay(null);
      onChange(null);
    }
  };

  useEffect(() => {
    if (value) {
      if (isRange && Array.isArray(value)) {
        const [from, to] = value;

        setRange({
          from: formatDatePickerDate(from),
          to: formatDatePickerDate(to),
        });
      }

      if (!isRange) {
        setSelecteDay(formatDatePickerDate(value));
      }
    }
  }, []);

  const DefaultInput = ({ ref }: any) => {
    return (
      <Input
        ref={ref}
        bg="transparent"
        name="date-picker"
        className="datepicker__input"
        placeholder={t('globals.pick-date')}
        onChange={() => null}
        height="small"
        value={formatInputValue()}
        addon={
          <Icon
            className="ml-2"
            onClick={clear}
            size="small"
            name="cancel"
            variant="sbgrey"
          />
        }
      ></Input>
    );
  };

  return (
    <>
      <DatePicker
        className={className}
        onBlur={onBlur}
        {...props}
        value={isRange ? range : selectedDay}
        onChange={handleOnChange}
        renderInput={customInput || DefaultInput}
        colorPrimary="#acf439"
        colorPrimaryLight="#354e16"
      />

      {error ? <p className="input__error">{error}</p> : null}
    </>
  );
};

export default DatePickerComponent;
