/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { LoaderWrapper } from 'components/Loader';
import { currencyWithoutSymbol } from 'libs/currency-formatter';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

const TicketTotal = () => {
  const { t } = useTranslation();
  const { ticketBuilder } = useStores();
  const {
    potentialBonus,
    winningAmount,
    amountPerCombination,
    system,
    bonusInPercent,
    isSystem,
  } = ticketBuilder;

  return (
    <div className={classNames('ticket-total', { 'system-total': isSystem })}>
      {isSystem && (
        <LoaderWrapper name="system-ticket-total">
          <>
            <div className="mobile-wrapper">
              <div className="mobile-wrapper__item d-flex align-items space-between mb-2">
                <div>{t('slips.number-of-combinations')}</div>
                <div className="text-extra-strong">
                  {system.numberOfCombinations}
                </div>
              </div>
              <div className="mobile-wrapper__item d-flex align-items space-between mb-2">
                <div>{t('slips.stake-per-combination')}</div>
                <div className="text-extra-strong">
                  {currencyWithoutSymbol(amountPerCombination)}
                </div>
              </div>
            </div>

            <div className="mobile-wrapper">
              <div className="mobile-wrapper__item d-flex align-items space-between mb-2">
                <div>{t('slips.min-max-odd')}:</div>
                <div className="text-extra-strong">
                  {system.minOddsValue} / {system.maxOddsValue}
                </div>
              </div>
            </div>

            <div className="mobile-wrapper">
              <div className="mobile-wrapper__item d-flex align-items space-between mb-2">
                <div>Min/max pot. bonus: </div>
                <div className="text-extra-strong">
                  {system.minBonusAmount} / {system.maxBonusAmount}
                </div>
              </div>
              <div className="mobile-wrapper__item d-flex align-items space-between mb-2">
                <div>{t('slips.min-max-pot-win')}:</div>
                <div className="text-extra-strong">
                  {system.minWinningAmount} / {system.maxWinningAmount}
                </div>
              </div>
            </div>
          </>
        </LoaderWrapper>
      )}
      {!isSystem && (
        <>
          <div className="d-flex align-items space-between mb-2">
            <div className="w-50 text-ellipsis">{t('slips.bonus')}</div>
            <Tooltip
              overlayClassName="tooltip"
              placement="bottom"
              overlay={currencyWithoutSymbol(potentialBonus)}
            >
              <div className="text-extra-strong text-right w-50 text-ellipsis">
                {!isSystem && currencyWithoutSymbol(potentialBonus)} (
                {bonusInPercent})%
              </div>
            </Tooltip>
          </div>
        </>
      )}
      {!isSystem && (
        <div className="d-flex align-items space-between mb-2">
          <div className="w-50 text-ellipsis">{t('slips.payout')}</div>
          <Tooltip
            overlayClassName="tooltip"
            placement="bottom"
            overlay={currencyWithoutSymbol(winningAmount)}
          >
            <div className="text-extra-strong text-right w-50 text-ellipsis">
              {currencyWithoutSymbol(winningAmount)}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default observer(TicketTotal);
