/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import PaymentsApi from 'modules/payment/payment.api';
import { PaymentMethods, PaymentMethod } from 'modules/payment/payment.types';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

class PaymentStore {
  private api: PaymentsApi;

  isTransactionInitialized: boolean = false;

  constructor() {
    this.api = new PaymentsApi();

    makeAutoObservable(this);
  }

  setIsTransactionInitialized = (isInitialized: boolean) => {
    this.isTransactionInitialized = isInitialized;
  };

  initMonriTransaction = async (
    amount: number,
    method: PaymentMethod = PaymentMethods.MONRI
  ) => {
    let result = null;

    try {
      if (method === PaymentMethods.MONRI) {
        const { data } = await this.api.initMonriTransaction(amount);

        if (data) {
          result = data;
        }
      } else {
        logger(
          'PaymentStore -> initMonriTransaction -> Payment method not supported.'
        );
      }
    } catch (exception: any) {
      logger('PaymentStore -> initMonriTransaction -> exception', exception);

      if ((exception || {}).data) {
        const { detail } = exception.data || {};

        toast.error(i18n.t(`errors.${detail}`));
      }
    }

    return result;
  };

  getWithdrawalReservation = () => {
    return this.api.getWithdrawalReservation();
  };

  getBankWithdrawalReservation = () => {
    return this.api.getBankWithdrawalReservation();
  };

  submitWithdrawal = async (data: any) => {
    try {
      await this.api.createWithdrawal(data);

      return true;
    } catch (exception: any) {
      logger('PaymentStore -> submitWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  submitBankWithdrawal = async (data: any) => {
    try {
      await this.api.createBankWithdrawal(data);
      return true;
    } catch (exception: any) {
      logger('PaymentStore -> submitBankWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  cancelWithdrawal = async () => {
    try {
      await this.api.cancelWithdrawal();
      return true;
    } catch (exception: any) {
      logger('PaymentStore -> cancelWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  cancelBankWithdrawal = async () => {
    try {
      const data = await this.api.cancelBankWithdrawal();

      return Promise.resolve(data);
    } catch (exception: any) {
      logger('PaymentStore -> cancelBankWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  activatePromoCode = async (code: string) => {
    try {
      const { data } = await this.api.activatePromoCode(code);

      return data;
    } catch (exception: any) {
      logger('PaymentStore -> activatePromoCode -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  checkPromoCode = async (code: string) => {
    return this.api.checkPromoCode(code);
  };

  getMinMaxWithdrawal() {
    return this.api.getMinMaxWithdrawal();
  }
}

export const paymentStore = new PaymentStore();
