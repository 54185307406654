/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { TYPE_FILTER_OPTIONS } from 'modules/user/ui/UserTransactions/TransactionsFilter/TransactionsFilter';
import { DatePicker, Icon, Select } from 'components';
import { formatDatePickerDate } from 'libs/datetime';
import './TransactionsMobileFilter.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface Filters {
  label: string;
  value: number;
}

export interface TransactionsMobileFilterProps {
  onTypeChange: (filter: any) => void;
  onDateChange: (filter: [Date, Date]) => void;
  startDate: Date | null;
  endDate: Date | null;
}

const TransactionsMobileFilter = ({
  onDateChange,
  onTypeChange,
  startDate,
  endDate,
}: TransactionsMobileFilterProps) => {
  const { t } = useTranslation();

  const {
    userStore: { transactionFilters },
  } = useStores();

  const typeFilterOptions = TYPE_FILTER_OPTIONS(t);

  const { filters } = transactionFilters;

  return (
    <div className="visible-mobile-down transactions-mobile-filter pt-3 pb-5">
      <section className="sb-text-small mt-1">
        <Select
          className="mb-1 select w-100"
          options={typeFilterOptions}
          bg="sb-dark"
          size="sb-market"
          placeholder={t('slips.ticket-status')}
          value={typeFilterOptions.filter((o) =>
            filters.transaction_type
              ? o.value === filters.transaction_type
              : o.value === 'all'
          )}
          shape="squared"
          onChange={onTypeChange}
        />
      </section>
      <section className="w-100 mx-auto">
        <div className="d-flex align-items ticket-mobile-filter__datepicker-wrapper mt-6 w-90 mx-auto">
          <label
            htmlFor="filter-datepicker"
            className="d-flex align-items justify-center w-100"
          >
            <Icon name="calendar" variant="lightgrey" className="w-8" />
            <DatePicker
              placeholderText={t('globals.filter-by-date')}
              className="ticket-status-filter__datepicker ml-3"
              value={{
                from: formatDatePickerDate(startDate),
                to: formatDatePickerDate(endDate),
              }}
              onChange={onDateChange}
              isRange
              id="filter-datepicker-transactions"
            />
          </label>
        </div>
      </section>
    </div>
  );
};

export default observer(TransactionsMobileFilter);
