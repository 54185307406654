/* React modules */

/* Our modules */
import { Credentials } from 'modules/auth/auth.models';
import { User } from 'modules/user/user.models';
import { http } from 'app/axios-config';

/* 3rd Party modules */

class AuthApi {
  login(credentials: Credentials) {
    return http.post('user/login/platform', credentials);
  }

  register(data: User) {
    return http.post('user/platform', data);
  }

  changePassword(data: object) {
    return http.put('user/change-password/platform', data);
  }

  activateAccount(token: string) {
    return http.put(`user/verify-user/${token}`);
  }

  resetPassword(data: object) {
    return http.post(`user/forgot-password`, data);
  }

  submitResetPassword(data: object, token: string) {
    return http.put(`user/forgot-password/${token}`, data);
  }

  userExistsCheck(params: any) {
    return http.get('user/platform/check', { params });
  }
}

export default AuthApi;
