/* React modules */

/* Our modules */
import {
  WalletType,
  WalletCurrency,
  WalletCurrencies,
} from 'modules/wallet/wallet.types';
import { formatDate } from 'libs/datetime';
import { currencyWithoutSymbol } from 'libs/currency-formatter';

/* 3rd Party modules */

export interface WalletBalance {
  available: number;
  amountToUnlock?: number;
  cannotWithdraw?: number;
  free_bet_valid_to?: string;
  promo_bet_valid_to?: string;
}

class Wallet {
  type: WalletType;
  available: number;
  amountToUnlock: number | null = null;
  currency: WalletCurrency = WalletCurrencies.EUR;
  canWithdraw: number = 0;
  freeBetValidTo?: any;
  promoBetValidTo?: string;

  constructor(type: WalletType, data: WalletBalance) {
    this.type = type;
    this.available = data.available;

    if (data.amountToUnlock) {
      this.amountToUnlock = data.amountToUnlock;
    }

    if (data.free_bet_valid_to) {
      const rawDate = data.free_bet_valid_to.split('T')[0];

      this.freeBetValidTo = formatDate(new Date(rawDate));
    }

    if (data.promo_bet_valid_to) {
      const rawDate = data.promo_bet_valid_to.split('T')[0];

      this.promoBetValidTo = formatDate(new Date(rawDate));
    }

    if (
      typeof data.cannotWithdraw === 'number' &&
      data.cannotWithdraw < data.available
    ) {
      this.canWithdraw = data.available - data.cannotWithdraw;
    }
  }

  get locked(): boolean {
    return !!this.amountToUnlock;
  }

  get availableFunds(): string {
    const { currency, canWithdraw } = this;
    const withdrawalValue = currencyWithoutSymbol(canWithdraw, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${withdrawalValue} ${currency}`;
  }

  get balanceDisplay(): string {
    const { available, currency } = this;

    const balanceValue = currencyWithoutSymbol(available, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${balanceValue} ${currency}`;
  }
}

export default Wallet;
