enum PaymentMethods {
  MONRI = 'monri',
}

type PaymentMethod = PaymentMethods.MONRI;

enum PokerErrors {
  INVALID_USER = 'ERR_INVALID_USER',
  USER_NOT_VALIDATED = 'ERR_USER_NOT_VALIDATED',
  POKER_NOT_RESPONDING = 'ERR_POKER_NOT_RESPONDING',
  POKER_SUCCESS_FALSE = 'ERR_POKER_SUCCESS_FALSE',
  NOT_ENOUGH_FUNDS = 'ERR_NOT_ENOUGH_FUNDS',
  CANCEL_FUNDS = 'ERR_CANCEL_FUNDS',
}

type PokerError =
  | PokerErrors.INVALID_USER
  | PokerErrors.USER_NOT_VALIDATED
  | PokerErrors.POKER_NOT_RESPONDING
  | PokerErrors.POKER_SUCCESS_FALSE
  | PokerErrors.NOT_ENOUGH_FUNDS
  | PokerErrors.CANCEL_FUNDS;

enum WithdrawalErrors {
  NOT_ENOUGH_FUNDS = 'ERR_NOT_ENOUGH_FUNDS',
  USER_BLOCKED = 'ERR_USER_BLOCKED',
  USER_NOT_VALIDATED = 'ERR_USER_NOT_VALIDATED',
  NOT_FOUND = 'ERR_NOT_FOUND',
  TEST_USERS_CANNOT_WITHDRAW_MONEY = 'TEST_USERS_CANNOT_WITHDRAW_MONEY',
}

type WithdrawalError =
  | WithdrawalErrors.NOT_ENOUGH_FUNDS
  | WithdrawalErrors.USER_BLOCKED
  | WithdrawalErrors.USER_NOT_VALIDATED
  | WithdrawalErrors.NOT_FOUND;

/* enum ActiveWithdrawalsResponse {
  NO_ACTIVE_RESERVATIONS = 'ERR_NO_ACTIVE_WITHDRAWAL_RESERVATION',
} */

enum WithdrawalStatuses {
  PENDING = '__PENDING__',
  READY_FOR_WITHDRAWAL = '__READY_FOR_WITHDRAWAL__',
  APPROVED = '__APPROVED__',
}

type WithdrawalStatus =
  | WithdrawalStatuses.PENDING
  | WithdrawalStatuses.APPROVED
  | WithdrawalStatuses.READY_FOR_WITHDRAWAL;

interface ActiveWithdrawal {
  amount: number;
  betting_place_id: number;
  created_at: string;
  id: number;
  code: string;
  status: WithdrawalStatus;
}

enum PromoCodeActivationError {
  INVALID_CODE = 'ERR_INVALID_CODE',
  ALREADY_ACTIVATED = 'ERR_ALREADY_ACTIVATED',
  CODE_USED_UP = 'ERR_CODE_USED_UP',
  CODE_NOT_FOR_NEW_USERS = 'ERR_CODE_NOT_FOR_NEW_USERS',
  CODE_NOT_FOR_EXISTING_USERS = 'ERR_CODE_NOT_FOR_EXISTING_USERS',
}

type PromoCodeAmountType = 'free_bet' | 'free_casino' | 'both' | 'deposit';

interface ActivatePromoCodeResponse {
  amount: number;
  amount_fc: number;
  amount_type: PromoCodeAmountType;
}

interface MonriTransactionResponse {
  authenticity_token: string | null;
  amount: number;
  currency: string;
  language: string;
  ch_address: string;
  ch_city: string;
  ch_country: string;
  ch_email: string;
  ch_full_name: string;
  ch_phone: string;
  ch_zip: string;
  transaction_type: string;
  order_number: string;
  order_info: string;
  digest: string;
  tokenize_pan_offered: boolean;
  supported_payment_methods: string;
  client_secret: string;
}

interface MinMaxPaymentResponse {
  min_lb_payment: number;
  max_lb_payment: number;
  min_tcash_payment: number;
  max_tcash_payment: number;
  tcash_approval_limit: number;
  min_bank_payment: number;
  max_bank_payment: number;
  created_by: number;
}

// Monri Components - BEGIN
interface SavedCardPaymentMethodData {
  brand: string;
  issuer: string;
  masked: string;
  expiration_date: string;
  token: string;
}
interface SavedCardPaymentMethod {
  type: string;
  data: SavedCardPaymentMethodData;
}
interface PaymentResult {
  status: 'approved' | 'declined'; // approved or declined
  currency: string;
  amount: number; // amount in minor units, eg 10.24 USD is 1024
  order_number: string;
  pan_token: string | null; // pan token representing tokenized card
  created_at: string;
  transaction_type: string; // authorize or purchase, depending on trx type used in payment/new
  payment_method: SavedCardPaymentMethod | null; // available if card is tokenized for future payments, null if not
  errors: Array<string> | null; // errors if transaction is declined
}

interface MonriError {
  message: string;
}

interface Result {
  result: PaymentResult | null;
  error: MonriError | null;
}
// Monri Components - END

export {
  PaymentMethods,
  PokerErrors,
  WithdrawalErrors,
  WithdrawalStatuses,
  PromoCodeActivationError,
};

export type {
  PaymentMethod,
  PokerError,
  WithdrawalError,
  ActiveWithdrawal,
  ActivatePromoCodeResponse,
  MonriTransactionResponse,
  MinMaxPaymentResponse,
  PaymentResult,
  MonriError,
  Result,
};