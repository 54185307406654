/* React modules */
import { useMemo } from 'react';

/* Our modules */
import { UserStatus } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import { Button } from 'components';
import './VerificationModal.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const VerificationModal = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const HEADER_TITLE = useMemo(
    () => ({
      registered: t('verification.verification-modal.activate'),
      activated: t('verification.verification-modal.activate'),
      'documents-uploaded': t('verification.verification-modal.doc-uploaded'),
      validated: '',
    }),
    [t]
  );
  const {
    userStore: { user },
    overlayStore: { closeModal },
  } = useStores();

  const routeToVerification = () => {
    navigate(`/profil/dokumenta?verify=true`);
    closeModal();
  };

  const close = () => closeModal();

  return (
    <div className="verification-modal">
      <div className="verification-modal__header">
        {HEADER_TITLE[user?.status as UserStatus]}
      </div>
      <div className="verification-modal__body">
        <div className="d-flex space-between">
          <Button bg="success" className="mb-4" onClick={routeToVerification}>
            {t('verification.verification-modal.validate')}
          </Button>
          <Button className="mb-4" onClick={close}>
            {t('verification.verification-modal.proceed')}
          </Button>
        </div>
        <div className="text-small text-yellow">
          {t('verification.verification-modal.desc')}
        </div>
      </div>
    </div>
  );
});

export default VerificationModal;
