/* React modules */
import { useState, useEffect } from 'react';

/* Our modules */
import {
  MonriTransactionResponse,
  PaymentResult,
  MonriError,
  Result,
} from 'modules/payment/payment.types';
import { paymentStore } from 'modules/payment/payment.store';
import { Button } from 'components';
import './MonriComponentsForm.scss';
import { isNumber } from 'libs/common-helpers';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const MonriComponentsForm = observer(
  ({ amount, isFromMobile }: { amount: number; isFromMobile?: boolean }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const [transaction, setTransaction] =
      useState<MonriTransactionResponse | null>(null);

    const [paymentStatus, setPaymentStatus] = useState<
      'approved' | 'declined' | null
    >(null);

    async function fetchMonriTransactionInit(amount: number) {
      const response = await paymentStore.initMonriTransaction(amount);

      if (response) {
        setTransaction(response);
      }
    }

    useEffect(() => {
      if (isNumber(amount)) {
        fetchMonriTransactionInit(amount);
      }
    }, []);

    useEffect(() => {
      if (isNumber(amount)) {
        if (transaction) {
          const { client_secret, authenticity_token } = transaction;

          if (authenticity_token) {
            // @ts-ignore
            const monri = Monri(authenticity_token, {
              locale: language === 'me' || language === 'de' ? language : 'en',
            });

            if (client_secret) {
              const components = monri.components({
                clientSecret: client_secret,
              });

              // Custom styling can be passed to options when creating an Component.
              const style = {
                // Add your styles here.
                base: {
                  padding: '8px',
                  backgroundColor: 'white',
                },
                label: {
                  base: {
                    backgroundColor: 'white',
                  },
                },
                input: {
                  base: {
                    backgroundColor: 'white',
                    color: '#242424',
                  },
                },
                rememberCardLabel: {
                  base: {
                    backgroundColor: 'white',
                  },
                },
                selectPaymentMethod: {
                  base: {
                    backgroundColor: 'white',
                  },
                },
              };

              // Create an instance of the card Component.
              const card = components.create('card', {
                style,
                tokenizePanOffered: true, // this will enable 'save card for future payments' checkbox in form
              });

              // Add an instance of the card Component into the `card-element` <div>.
              card.mount('card-element');

              card.onChange(function (event: { error: MonriError }) {
                let displayError = document.getElementById('card-errors');

                if (event && displayError) {
                  displayError.textContent = event.error
                    ? event.error.message
                    : '';
                }
              });

              // Confirm payment or display an error when the form is submitted.
              const form = document.getElementById('payment-form');

              const handlePaymentResult = (paymentResult: PaymentResult) => {
                // Handle PaymentResult
                if (paymentResult.status === 'approved') {
                  toast.success(t('payments.payment-success'));
                } else {
                  toast.error(t('payments.payment-default-error'));
                }

                setPaymentStatus(paymentResult.status);

                if (!isFromMobile) {
                  paymentStore.setIsTransactionInitialized(false);
                }
              };

              if (form) {
                form.addEventListener('submit', function (event) {
                  event.preventDefault();

                  const {
                    ch_address,
                    ch_full_name,
                    ch_city,
                    ch_zip,
                    ch_phone,
                    ch_country,
                    ch_email,
                    order_info,
                  } = transaction;

                  const transactionParams = {
                    address: ch_address,
                    fullName: ch_full_name,
                    city: ch_city,
                    zip: ch_zip,
                    phone: ch_phone,
                    country: ch_country,
                    email: ch_email,
                    orderInfo: order_info,
                  };

                  monri
                    .confirmPayment(card, transactionParams)
                    .then(function (result: Result) {
                      if (result.error) {
                        // Inform the customer that there was an error.
                        const errorElement =
                          document.getElementById('card-errors');

                        if (errorElement) {
                          errorElement.textContent = result.error.message;
                        }
                      } else if (result.result) {
                        handlePaymentResult(result.result);
                      }
                    });
                });
              }
            }
          }
        }
      }
    }, [transaction?.client_secret]);

    return (
      <>
        {paymentStatus && isFromMobile ? (
          <p
            className={classnames('mb-5', {
              'text-green-200': paymentStatus === 'approved',
              'text-red-400': paymentStatus === 'declined',
            })}
          >
            {paymentStatus === 'approved'
              ? t('payments.payment-success')
              : t('payments.payment-default-error')}
          </p>
        ) : (
          <form id="payment-form" className="monri-components-form mb-5 px-5">
            <div className="form-row">
              <div id="card-element">
                {/* A Monri Component will be inserted here. */}
              </div>

              {/* Used to display Component errors. */}
              <div
                id="card-errors"
                role="alert"
                className="mb-5 text-red-400"
              ></div>
            </div>

            <Button bg="success" className="w-100 mb-5">
              {t('globals.submit')}
            </Button>
          </form>
        )}
      </>
    );
  }
);

export { MonriComponentsForm };
