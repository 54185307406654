/* React modules */
import { useState } from 'react';

/* Our modules */
import {
  MAX_DEBIT_BANK,
  MIN_DEBIT_BANK,
  AMOUNT_OPTIONS,
  CREDIT_CARD_FOOTER_THUMBNAILS,
} from 'modules/payment/payment.constants';
import { paymentStore } from 'modules/payment/payment.store';
import CreditDebitBalance from 'modules/user/ui/UserCreditDebit/CreditDebitBalance';
import CreditDebitInfoBar from 'modules/user/ui/UserCreditDebit/CreditDebitInfoBar';
import CreditDebitAmountPicker from 'modules/user/ui/UserCreditDebit/CreditDebitAmountPicker';
import { MonriComponentsForm } from 'modules/payment/ui/MonriComponentsForm/MonriComponentsForm';
import { isNumber } from 'libs/common-helpers';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const CreditCard = observer(() => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState<number>(0);

  const initDeposit = async (amount: number) => {
    if (isNumber(amount)) {
      setAmount(amount);
    }

    paymentStore.setIsTransactionInitialized(true);
  };

  return (
    <div className="credit-card">
      <CreditDebitBalance />

      <div className="mt-16">
        <CreditDebitInfoBar
          min={MIN_DEBIT_BANK}
          max={MAX_DEBIT_BANK}
          isDebit={true}
        />
      </div>

      <div
        className={classnames('mt-12', {
          'xy-center': paymentStore.isTransactionInitialized,
        })}
      >
        {!paymentStore.isTransactionInitialized ? (
          <CreditDebitAmountPicker
            onSubmit={initDeposit}
            buttonText={t('payments.pay')}
            min={MIN_DEBIT_BANK}
            max={MAX_DEBIT_BANK}
            amountOptions={AMOUNT_OPTIONS}
          />
        ) : (
          <MonriComponentsForm amount={amount} />
        )}
      </div>

      <div className="d-flex justify-center align-items w-50 mx-auto">
        {CREDIT_CARD_FOOTER_THUMBNAILS.map((thumbnail, index) =>
          thumbnail.link ? (
            <a
              key={`CreditCard-a-${index}`}
              href={thumbnail.link}
              target="blank"
            >
              <img
                src={thumbnail.src}
                alt={thumbnail.alt}
                className="mx-2"
                style={{ height: '30px' }}
                data-testid="footer-thumbnail"
              />
            </a>
          ) : (
            <img
              key={`CreditCard-img-${index}`}
              src={thumbnail.src}
              alt={thumbnail.alt}
              className="mx-2"
              style={{ height: '30px' }}
              data-testid="footer-thumbnail"
            />
          )
        )}
      </div>
    </div>
  );
});

export default CreditCard;
