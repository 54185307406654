/* React modules */

/* Our modules */
import { OrderStatus } from 'modules/user/market.types';
import useStores from 'common/hooks/useStores';
import Button from 'components/Button/Button';
import MobileItem from 'modules/user/ui/UserMarket/Market/MobileItem/MobileItem';
import './SingleOrderModal.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const SingleOrderModal = () => {
  const { t } = useTranslation();
  const { marketStore, bettingPlacesStore } = useStores();
  const { getSingleOrder, cancelOrder } = marketStore;
  const { bettingPlacesList } = bettingPlacesStore;

  const STATUS_DISPLAY: any = {
    [OrderStatus.PENDING]: {
      label: t('globals.pending'),
      color: 'orange-800',
    },
    [OrderStatus.SUCCESS]: {
      label: t('globals.success'),
      color: 'green',
    },
    [OrderStatus.DELIVERED]: {
      label: t('globals.delivered'),
      color: 'green',
    },
    [OrderStatus.APPROVED]: {
      label: t('globals.approved'),
      color: 'green',
    },
    [OrderStatus.CANCELED]: {
      label: t('globals.canceled'),
      color: 'red',
    },
    default: {
      label: '',
      bg: 'green',
    },
  };

  const renderStatus = (value: any) => {
    const ticketStatus = STATUS_DISPLAY[value] || STATUS_DISPLAY.default;

    return (
      <div className={`text-${ticketStatus.color}`}>{ticketStatus.label}</div>
    );
  };

  const cancelOrderHandler = () => {
    cancelOrder(getSingleOrder.id);
  };

  const renderAddress = (id: number) => {
    const orderAddress = (bettingPlacesList || []).find(
      (place: any) => place.id === id
    );
    if (orderAddress) {
      return `${orderAddress.address}, ${orderAddress.municipalityName}`;
    }

    return '/';
  };

  return (
    <div className="single-order-modal w-100 bg-grey-800 py-8">
      <div className="px-6">
        <div className="text-white text-regular" style={{ maxWidth: '350px' }}>
          <h3 className="heading-m mb-4">{t('userMarket.order')}</h3>

          <div className="d-flex mb-4">
            <span className="w-50">{t('slips.status')}:</span>
            <span className="text-yellow text-strong">
              {renderStatus(getSingleOrder?.status)}
            </span>
          </div>

          <div className="d-flex mb-4">
            <span className="w-50">{t('userData.place')}:</span>
            <span>{renderAddress(getSingleOrder?.bettingPlaceId)}</span>
          </div>

          <div className="d-flex mb-4">
            <span className="w-50">{t('userData.code')}:</span>
            <span>{getSingleOrder?.code}</span>
          </div>

          <div className="d-flex mb-4">
            <span className="w-50">{t('payments.amount')}:</span>
            <span className="text-yellow text-strong">
              {getSingleOrder?.points}
            </span>
          </div>
          {getSingleOrder?.status === OrderStatus.PENDING && (
            <Button bg="success" onClick={cancelOrderHandler}>
              {t('userMarket.cancel').toUpperCase()}
            </Button>
          )}
        </div>
      </div>

      <div className="single-order-modal__items">
        <h3 className="heading-m mb-4 mt-10 px-6">{t('userMarket.items')}</h3>

        <div className="single-order-modal__items-list">
          {getSingleOrder?.itemsList.map((item: any, index: number) => (
            <div key={`SingleOrderModal-div-${index}`} className="px-6">
              <MobileItem
                expandedItems={[]}
                item={item}
                onClick={() => null}
                hasButtons={false}
              />

              <div className="single-order-modal__item">
                <img
                  className="single-order-modal__item-image"
                  src={item.item.imageUrl}
                  alt=""
                />
                <div className="single-order-modal__item-content">
                  <div className="single-order-modal__item-content-title">
                    <p className="text-yellow text-bold">{item.item.name}</p>
                    <p className="text-white">
                      Poena:{' '}
                      <span className="text-green-800 text-extra-strong">
                        {item.item.price}
                      </span>
                    </p>
                  </div>
                  <div className="single-order-modal__item-content-description mt-1">
                    <div className="w-100 sb-text-small pr-2 text-white">
                      <p>{item.item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(SingleOrderModal);
