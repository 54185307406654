/**
 * @fileoverview gRPC-Web generated client stub for web_odds
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_cache_service_cache_pb from '../../proto/cache_service/cache_pb';


export class OddsCacheServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetInitialData = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.InitialDataResponse,
    (request: proto_cache_service_cache_pb.InitialDataRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.InitialDataResponse.deserializeBinary
  );

  getInitialData(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.InitialDataResponse>;

  getInitialData(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataResponse) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.InitialDataResponse>;

  getInitialData(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetInitialData',
        request,
        metadata || {},
        this.methodInfoGetInitialData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetInitialData',
    request,
    metadata || {},
    this.methodInfoGetInitialData);
  }

  methodInfoGetInitialDataMarkets = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.InitialDataMarketsMap,
    (request: proto_cache_service_cache_pb.InitialDataRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.InitialDataMarketsMap.deserializeBinary
  );

  getInitialDataMarkets(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.InitialDataMarketsMap>;

  getInitialDataMarkets(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataMarketsMap) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.InitialDataMarketsMap>;

  getInitialDataMarkets(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataMarketsMap) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetInitialDataMarkets',
        request,
        metadata || {},
        this.methodInfoGetInitialDataMarkets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetInitialDataMarkets',
    request,
    metadata || {},
    this.methodInfoGetInitialDataMarkets);
  }

  methodInfoGetInitialDataSportsMap = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.InitialDataSportsMap,
    (request: proto_cache_service_cache_pb.InitialDataRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.InitialDataSportsMap.deserializeBinary
  );

  getInitialDataSportsMap(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.InitialDataSportsMap>;

  getInitialDataSportsMap(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataSportsMap) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.InitialDataSportsMap>;

  getInitialDataSportsMap(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataSportsMap) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetInitialDataSportsMap',
        request,
        metadata || {},
        this.methodInfoGetInitialDataSportsMap,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetInitialDataSportsMap',
    request,
    metadata || {},
    this.methodInfoGetInitialDataSportsMap);
  }

  methodInfoGetInitialDataDeltaMap = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.InitialDataSportsMap,
    (request: proto_cache_service_cache_pb.InitialDataRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.InitialDataSportsMap.deserializeBinary
  );

  getInitialDataDeltaMap(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.InitialDataSportsMap>;

  getInitialDataDeltaMap(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataSportsMap) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.InitialDataSportsMap>;

  getInitialDataDeltaMap(
    request: proto_cache_service_cache_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.InitialDataSportsMap) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetInitialDataDeltaMap',
        request,
        metadata || {},
        this.methodInfoGetInitialDataDeltaMap,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetInitialDataDeltaMap',
    request,
    metadata || {},
    this.methodInfoGetInitialDataDeltaMap);
  }

  methodInfoGetSingleEvent = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.WebStreamEvent,
    (request: proto_cache_service_cache_pb.WebStreamEvent) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.WebStreamEvent.deserializeBinary
  );

  getSingleEvent(
    request: proto_cache_service_cache_pb.WebStreamEvent,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.WebStreamEvent>;

  getSingleEvent(
    request: proto_cache_service_cache_pb.WebStreamEvent,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.WebStreamEvent) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.WebStreamEvent>;

  getSingleEvent(
    request: proto_cache_service_cache_pb.WebStreamEvent,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.WebStreamEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetSingleEvent',
        request,
        metadata || {},
        this.methodInfoGetSingleEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetSingleEvent',
    request,
    metadata || {},
    this.methodInfoGetSingleEvent);
  }

  methodInfoGetLiveDisabled = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.LiveDisabled,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.LiveDisabled.deserializeBinary
  );

  getLiveDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.LiveDisabled>;

  getLiveDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.LiveDisabled) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.LiveDisabled>;

  getLiveDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.LiveDisabled) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetLiveDisabled',
        request,
        metadata || {},
        this.methodInfoGetLiveDisabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetLiveDisabled',
    request,
    metadata || {},
    this.methodInfoGetLiveDisabled);
  }

  methodInfoGetPlayerOutrightMarkets = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.PlayerOutrightMarkets,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.PlayerOutrightMarkets.deserializeBinary
  );

  getPlayerOutrightMarkets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.PlayerOutrightMarkets>;

  getPlayerOutrightMarkets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.PlayerOutrightMarkets) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.PlayerOutrightMarkets>;

  getPlayerOutrightMarkets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.PlayerOutrightMarkets) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetPlayerOutrightMarkets',
        request,
        metadata || {},
        this.methodInfoGetPlayerOutrightMarkets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetPlayerOutrightMarkets',
    request,
    metadata || {},
    this.methodInfoGetPlayerOutrightMarkets);
  }

  methodInfoGetMissingLiveEvent = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.StreamEvent,
    (request: proto_cache_service_cache_pb.EventRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.StreamEvent.deserializeBinary
  );

  getMissingLiveEvent(
    request: proto_cache_service_cache_pb.EventRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.StreamEvent>;

  getMissingLiveEvent(
    request: proto_cache_service_cache_pb.EventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.StreamEvent) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.StreamEvent>;

  getMissingLiveEvent(
    request: proto_cache_service_cache_pb.EventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.StreamEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetMissingLiveEvent',
        request,
        metadata || {},
        this.methodInfoGetMissingLiveEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetMissingLiveEvent',
    request,
    metadata || {},
    this.methodInfoGetMissingLiveEvent);
  }

  methodInfoGetMissingPrematchEvent = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.StreamEvent,
    (request: proto_cache_service_cache_pb.EventRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.StreamEvent.deserializeBinary
  );

  getMissingPrematchEvent(
    request: proto_cache_service_cache_pb.EventRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.StreamEvent>;

  getMissingPrematchEvent(
    request: proto_cache_service_cache_pb.EventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.StreamEvent) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.StreamEvent>;

  getMissingPrematchEvent(
    request: proto_cache_service_cache_pb.EventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.StreamEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetMissingPrematchEvent',
        request,
        metadata || {},
        this.methodInfoGetMissingPrematchEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetMissingPrematchEvent',
    request,
    metadata || {},
    this.methodInfoGetMissingPrematchEvent);
  }

  methodInfoGetOfferCounter = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_cache_service_cache_pb.CounterMap,
    (request: proto_cache_service_cache_pb.CounterRequest) => {
      return request.serializeBinary();
    },
    proto_cache_service_cache_pb.CounterMap.deserializeBinary
  );

  getOfferCounter(
    request: proto_cache_service_cache_pb.CounterRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_cache_service_cache_pb.CounterMap>;

  getOfferCounter(
    request: proto_cache_service_cache_pb.CounterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.CounterMap) => void): grpcWeb.ClientReadableStream<proto_cache_service_cache_pb.CounterMap>;

  getOfferCounter(
    request: proto_cache_service_cache_pb.CounterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_cache_service_cache_pb.CounterMap) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.OddsCacheService/GetOfferCounter',
        request,
        metadata || {},
        this.methodInfoGetOfferCounter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.OddsCacheService/GetOfferCounter',
    request,
    metadata || {},
    this.methodInfoGetOfferCounter);
  }

}

