/* React modules */

/* Our modules */
import { GetStoreItemsResponse } from 'modules/user/market.types';
import { LoyaltyWebServiceClient } from 'proto/loyalty/LoyaltyServiceClientPb';
import {
  GetOrdersRequest,
  Order,
  OrderItem,
  StoreItem,
} from 'proto/loyalty/loyalty_pb';
import ProtobufApi from 'libs/protobufApi';
import { getApiUrl } from 'libs/urlBuilder';

/* 3rd Party modules */
import Empty from 'google-protobuf/google/protobuf/empty_pb';

class MarketApi {
  private client: LoyaltyWebServiceClient;
  private api: ProtobufApi;

  constructor() {
    this.client = new LoyaltyWebServiceClient(getApiUrl(), {}, {});
    this.api = new ProtobufApi(this.client);
  }

  getStoreItems(accessToken: string): Promise<GetStoreItemsResponse> {
    const request = new Empty.Empty();
    return this.api.request('getStoreItems', [request, { accessToken }]);
  }

  placeOrder(payload: any, accessToken: any): Promise<any> {
    const request = new Order();

    const items: any = [];
    payload.items.forEach((item: any) => {
      const itemReq = new OrderItem();
      itemReq.setQuantity(item.quantity);
      const storeItem = new StoreItem();
      storeItem.setId(item.item.id);
      itemReq.setItem(storeItem);
      items.push(itemReq);
    });
    request.setItemsList(items);
    if (payload.betting_place_id)
      request.setBettingPlaceId(payload.betting_place_id);

    return this.api.request('placeOrder', [request, { accessToken }]);
  }

  getOrders(offset: number, accessToken: any): Promise<any> {
    const request = new GetOrdersRequest();
    request.setOffset(offset);
    return this.api.request('getOrders', [request, { accessToken }]);
  }

  getLoyalty(accessToken: any): Promise<any> {
    const request = new Empty.Empty();
    return this.api.request('getLoyalty', [request, { accessToken }]);
  }

  getLoyaltyConfig(accessToken: any): Promise<any> {
    const request = new Empty.Empty();
    return this.api.request('getWebLoyaltyConfig', [request, { accessToken }]);
  }

  getSingleOrder(orderId: number, accessToken: any): Promise<any> {
    const request = new Order();
    request.setId(orderId);
    return this.api.request('getSingleOrder', [request, { accessToken }]);
  }

  cancelOrder(orderId: number, accessToken: any): Promise<any> {
    const request = new Order();
    request.setId(orderId);
    return this.api.request('cancelOrder', [request, { accessToken }]);
  }
}

export default MarketApi;
