/* React modules */

/* Our modules */
import ourApps from 'images/home/our-apps.png';
import './_our-apps.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

const OurApps = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="text-center our-apps">
      <p className="fw-bold">{t('home.be-in-the-game-with-app')}</p>

      {/* <div className="d-flex justify-center mobile-app-buttons-container">
        <a
          href="https://apps.apple.com/me/app/sbbet/id1589867471"
          target="_blank"
          className="px-10 py-2 bg-green-700 text-black-400"
        >
          App Store
        </a>

        <a
          href="https://sbbet-pbr.factoryww.com/static/mobile/apk/SBBet-APK.apk"
          target="_blank"
          className="ml-12 px-10 py-2 bg-green-700 text-black-400"
        >
          Google Play
        </a>
      </div> */}

      <div className="apps-image-container">
        <img src={ourApps} alt="Our apps" className="w-100" />
      </div>
    </div>
  );
});

export default OurApps;
