// source: proto/odds/web_odds.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
goog.exportSymbol('proto.web_odds.Competition', null, global);
goog.exportSymbol('proto.web_odds.CompetitionId', null, global);
goog.exportSymbol('proto.web_odds.EventStream', null, global);
goog.exportSymbol('proto.web_odds.EventStreamString', null, global);
goog.exportSymbol('proto.web_odds.FeaturedEvent', null, global);
goog.exportSymbol('proto.web_odds.FeaturedEvents', null, global);
goog.exportSymbol('proto.web_odds.GetStreamUrlRequest', null, global);
goog.exportSymbol('proto.web_odds.InitialBasicOffer', null, global);
goog.exportSymbol('proto.web_odds.InitialBasicOfferMarket', null, global);
goog.exportSymbol('proto.web_odds.InitialCompetition', null, global);
goog.exportSymbol('proto.web_odds.InitialDataRequest', null, global);
goog.exportSymbol('proto.web_odds.InitialDataResponse', null, global);
goog.exportSymbol('proto.web_odds.InitialLocation', null, global);
goog.exportSymbol('proto.web_odds.InitialMarket', null, global);
goog.exportSymbol('proto.web_odds.InitialMarketGroup', null, global);
goog.exportSymbol('proto.web_odds.InitialOutcome', null, global);
goog.exportSymbol('proto.web_odds.InitialSport', null, global);
goog.exportSymbol('proto.web_odds.LandingPage', null, global);
goog.exportSymbol('proto.web_odds.LiveDisabled', null, global);
goog.exportSymbol('proto.web_odds.PlayerOutrightMarkets', null, global);
goog.exportSymbol('proto.web_odds.StreamUrl', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEvent', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEventInt', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEventOdd', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEventOddInt', null, global);
goog.exportSymbol('proto.web_odds.WebStreamEvents', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.CompetitionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.CompetitionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.CompetitionId.displayName = 'proto.web_odds.CompetitionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.Competition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.web_odds.Competition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.Competition.displayName = 'proto.web_odds.Competition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialDataRequest.displayName = 'proto.web_odds.InitialDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialDataResponse.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialDataResponse.displayName = 'proto.web_odds.InitialDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialSport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialSport.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialSport.displayName = 'proto.web_odds.InitialSport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialLocation.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialLocation.displayName = 'proto.web_odds.InitialLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialCompetition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialCompetition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialCompetition.displayName = 'proto.web_odds.InitialCompetition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialBasicOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialBasicOffer.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialBasicOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialBasicOffer.displayName = 'proto.web_odds.InitialBasicOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialBasicOfferMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.InitialBasicOfferMarket.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialBasicOfferMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialBasicOfferMarket.displayName = 'proto.web_odds.InitialBasicOfferMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialMarketGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.web_odds.InitialMarketGroup.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialMarketGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialMarketGroup.displayName = 'proto.web_odds.InitialMarketGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.web_odds.InitialMarket.repeatedFields_, null);
};
goog.inherits(proto.web_odds.InitialMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialMarket.displayName = 'proto.web_odds.InitialMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.InitialOutcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.InitialOutcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.InitialOutcome.displayName = 'proto.web_odds.InitialOutcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventStreamString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventStreamString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventStreamString.displayName = 'proto.web_odds.EventStreamString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.EventStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.EventStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.EventStream.displayName = 'proto.web_odds.EventStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.WebStreamEvents.repeatedFields_, null);
};
goog.inherits(proto.web_odds.WebStreamEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEvents.displayName = 'proto.web_odds.WebStreamEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.WebStreamEvent.repeatedFields_, null);
};
goog.inherits(proto.web_odds.WebStreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEvent.displayName = 'proto.web_odds.WebStreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEventOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.WebStreamEventOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEventOdd.displayName = 'proto.web_odds.WebStreamEventOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEventInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.WebStreamEventInt.repeatedFields_, null);
};
goog.inherits(proto.web_odds.WebStreamEventInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEventInt.displayName = 'proto.web_odds.WebStreamEventInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.WebStreamEventOddInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.WebStreamEventOddInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.WebStreamEventOddInt.displayName = 'proto.web_odds.WebStreamEventOddInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.LiveDisabled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.LiveDisabled.repeatedFields_, null);
};
goog.inherits(proto.web_odds.LiveDisabled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.LiveDisabled.displayName = 'proto.web_odds.LiveDisabled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.PlayerOutrightMarkets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.PlayerOutrightMarkets.repeatedFields_, null);
};
goog.inherits(proto.web_odds.PlayerOutrightMarkets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.PlayerOutrightMarkets.displayName = 'proto.web_odds.PlayerOutrightMarkets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.LandingPage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.LandingPage.repeatedFields_, null);
};
goog.inherits(proto.web_odds.LandingPage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.LandingPage.displayName = 'proto.web_odds.LandingPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.FeaturedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.FeaturedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.FeaturedEvent.displayName = 'proto.web_odds.FeaturedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.FeaturedEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_odds.FeaturedEvents.repeatedFields_, null);
};
goog.inherits(proto.web_odds.FeaturedEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.FeaturedEvents.displayName = 'proto.web_odds.FeaturedEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.GetStreamUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.GetStreamUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.GetStreamUrlRequest.displayName = 'proto.web_odds.GetStreamUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_odds.StreamUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_odds.StreamUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_odds.StreamUrl.displayName = 'proto.web_odds.StreamUrl';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.CompetitionId.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.CompetitionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.CompetitionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CompetitionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.CompetitionId}
 */
proto.web_odds.CompetitionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.CompetitionId;
  return proto.web_odds.CompetitionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.CompetitionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.CompetitionId}
 */
proto.web_odds.CompetitionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.CompetitionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.CompetitionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.CompetitionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.CompetitionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.CompetitionId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.CompetitionId} returns this
 */
proto.web_odds.CompetitionId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.Competition.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.Competition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.Competition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.Competition.toObject = function(includeInstance, msg) {
  var f, obj = {
    competition: (f = msg.getCompetition()) && proto.web_odds.InitialCompetition.toObject(includeInstance, f),
    sport: (f = msg.getSport()) && proto.web_odds.InitialSport.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.Competition}
 */
proto.web_odds.Competition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.Competition;
  return proto.web_odds.Competition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.Competition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.Competition}
 */
proto.web_odds.Competition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1001:
      var value = new proto.web_odds.InitialCompetition;
      reader.readMessage(value,proto.web_odds.InitialCompetition.deserializeBinaryFromReader);
      msg.setCompetition(value);
      break;
    case 1002:
      var value = new proto.web_odds.InitialSport;
      reader.readMessage(value,proto.web_odds.InitialSport.deserializeBinaryFromReader);
      msg.setSport(value);
      break;
    case 2002:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.Competition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.Competition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.Competition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.Competition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetition();
  if (f != null) {
    writer.writeMessage(
      1001,
      f,
      proto.web_odds.InitialCompetition.serializeBinaryToWriter
    );
  }
  f = message.getSport();
  if (f != null) {
    writer.writeMessage(
      1002,
      f,
      proto.web_odds.InitialSport.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2002,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional InitialCompetition competition = 1001;
 * @return {?proto.web_odds.InitialCompetition}
 */
proto.web_odds.Competition.prototype.getCompetition = function() {
  return /** @type{?proto.web_odds.InitialCompetition} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialCompetition, 1001));
};


/**
 * @param {?proto.web_odds.InitialCompetition|undefined} value
 * @return {!proto.web_odds.Competition} returns this
*/
proto.web_odds.Competition.prototype.setCompetition = function(value) {
  return jspb.Message.setWrapperField(this, 1001, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.Competition} returns this
 */
proto.web_odds.Competition.prototype.clearCompetition = function() {
  return this.setCompetition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.Competition.prototype.hasCompetition = function() {
  return jspb.Message.getField(this, 1001) != null;
};


/**
 * optional InitialSport sport = 1002;
 * @return {?proto.web_odds.InitialSport}
 */
proto.web_odds.Competition.prototype.getSport = function() {
  return /** @type{?proto.web_odds.InitialSport} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialSport, 1002));
};


/**
 * @param {?proto.web_odds.InitialSport|undefined} value
 * @return {!proto.web_odds.Competition} returns this
*/
proto.web_odds.Competition.prototype.setSport = function(value) {
  return jspb.Message.setWrapperField(this, 1002, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.Competition} returns this
 */
proto.web_odds.Competition.prototype.clearSport = function() {
  return this.setSport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.Competition.prototype.hasSport = function() {
  return jspb.Message.getField(this, 1002) != null;
};


/**
 * optional common.Error error = 2002;
 * @return {?proto.common.Error}
 */
proto.web_odds.Competition.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 2002));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.web_odds.Competition} returns this
*/
proto.web_odds.Competition.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2002, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.Competition} returns this
 */
proto.web_odds.Competition.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.Competition.prototype.hasError = function() {
  return jspb.Message.getField(this, 2002) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    onlyMarkets: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialDataRequest}
 */
proto.web_odds.InitialDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialDataRequest;
  return proto.web_odds.InitialDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialDataRequest}
 */
proto.web_odds.InitialDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnlyMarkets();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool only_markets = 1;
 * @return {boolean}
 */
proto.web_odds.InitialDataRequest.prototype.getOnlyMarkets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.InitialDataRequest} returns this
 */
proto.web_odds.InitialDataRequest.prototype.setOnlyMarkets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialDataResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupsList: jspb.Message.toObjectList(msg.getMarketGroupsList(),
    proto.web_odds.InitialMarketGroup.toObject, includeInstance),
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.web_odds.InitialSport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialDataResponse}
 */
proto.web_odds.InitialDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialDataResponse;
  return proto.web_odds.InitialDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialDataResponse}
 */
proto.web_odds.InitialDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.InitialMarketGroup;
      reader.readMessage(value,proto.web_odds.InitialMarketGroup.deserializeBinaryFromReader);
      msg.addMarketGroups(value);
      break;
    case 2:
      var value = new proto.web_odds.InitialSport;
      reader.readMessage(value,proto.web_odds.InitialSport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_odds.InitialMarketGroup.serializeBinaryToWriter
    );
  }
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.web_odds.InitialSport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InitialMarketGroup market_groups = 1;
 * @return {!Array<!proto.web_odds.InitialMarketGroup>}
 */
proto.web_odds.InitialDataResponse.prototype.getMarketGroupsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarketGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarketGroup, 1));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarketGroup>} value
 * @return {!proto.web_odds.InitialDataResponse} returns this
*/
proto.web_odds.InitialDataResponse.prototype.setMarketGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_odds.InitialMarketGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarketGroup}
 */
proto.web_odds.InitialDataResponse.prototype.addMarketGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_odds.InitialMarketGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialDataResponse} returns this
 */
proto.web_odds.InitialDataResponse.prototype.clearMarketGroupsList = function() {
  return this.setMarketGroupsList([]);
};


/**
 * repeated InitialSport sports = 2;
 * @return {!Array<!proto.web_odds.InitialSport>}
 */
proto.web_odds.InitialDataResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialSport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialSport, 2));
};


/**
 * @param {!Array<!proto.web_odds.InitialSport>} value
 * @return {!proto.web_odds.InitialDataResponse} returns this
*/
proto.web_odds.InitialDataResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web_odds.InitialSport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialSport}
 */
proto.web_odds.InitialDataResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web_odds.InitialSport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialDataResponse} returns this
 */
proto.web_odds.InitialDataResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialSport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialSport.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialSport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialSport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialSport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.web_odds.InitialLocation.toObject, includeInstance),
    basicOfferLive: (f = msg.getBasicOfferLive()) && proto.web_odds.InitialBasicOffer.toObject(includeInstance, f),
    basicOfferPrematch: (f = msg.getBasicOfferPrematch()) && proto.web_odds.InitialBasicOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialSport}
 */
proto.web_odds.InitialSport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialSport;
  return proto.web_odds.InitialSport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialSport}
 */
proto.web_odds.InitialSport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialLocation;
      reader.readMessage(value,proto.web_odds.InitialLocation.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 4:
      var value = new proto.web_odds.InitialBasicOffer;
      reader.readMessage(value,proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferLive(value);
      break;
    case 5:
      var value = new proto.web_odds.InitialBasicOffer;
      reader.readMessage(value,proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferPrematch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialSport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialSport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialLocation.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferLive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.web_odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferPrematch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.web_odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialSport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialSport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InitialLocation locations = 3;
 * @return {!Array<!proto.web_odds.InitialLocation>}
 */
proto.web_odds.InitialSport.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialLocation, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialLocation>} value
 * @return {!proto.web_odds.InitialSport} returns this
*/
proto.web_odds.InitialSport.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialLocation}
 */
proto.web_odds.InitialSport.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * optional InitialBasicOffer basic_offer_live = 4;
 * @return {?proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialSport.prototype.getBasicOfferLive = function() {
  return /** @type{?proto.web_odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialBasicOffer, 4));
};


/**
 * @param {?proto.web_odds.InitialBasicOffer|undefined} value
 * @return {!proto.web_odds.InitialSport} returns this
*/
proto.web_odds.InitialSport.prototype.setBasicOfferLive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.clearBasicOfferLive = function() {
  return this.setBasicOfferLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.InitialSport.prototype.hasBasicOfferLive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional InitialBasicOffer basic_offer_prematch = 5;
 * @return {?proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialSport.prototype.getBasicOfferPrematch = function() {
  return /** @type{?proto.web_odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.InitialBasicOffer, 5));
};


/**
 * @param {?proto.web_odds.InitialBasicOffer|undefined} value
 * @return {!proto.web_odds.InitialSport} returns this
*/
proto.web_odds.InitialSport.prototype.setBasicOfferPrematch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.InitialSport} returns this
 */
proto.web_odds.InitialSport.prototype.clearBasicOfferPrematch = function() {
  return this.setBasicOfferPrematch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.InitialSport.prototype.hasBasicOfferPrematch = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialLocation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.web_odds.InitialCompetition.toObject, includeInstance),
    flagCode: jspb.Message.getFieldWithDefault(msg, 444, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialLocation}
 */
proto.web_odds.InitialLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialLocation;
  return proto.web_odds.InitialLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialLocation}
 */
proto.web_odds.InitialLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialCompetition;
      reader.readMessage(value,proto.web_odds.InitialCompetition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    case 444:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialCompetition.serializeBinaryToWriter
    );
  }
  f = message.getFlagCode();
  if (f.length > 0) {
    writer.writeString(
      444,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialLocation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialLocation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InitialCompetition competitions = 3;
 * @return {!Array<!proto.web_odds.InitialCompetition>}
 */
proto.web_odds.InitialLocation.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialCompetition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialCompetition, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialCompetition>} value
 * @return {!proto.web_odds.InitialLocation} returns this
*/
proto.web_odds.InitialLocation.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialCompetition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialCompetition}
 */
proto.web_odds.InitialLocation.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialCompetition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};


/**
 * optional string flag_code = 444;
 * @return {string}
 */
proto.web_odds.InitialLocation.prototype.getFlagCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 444, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialLocation} returns this
 */
proto.web_odds.InitialLocation.prototype.setFlagCode = function(value) {
  return jspb.Message.setProto3StringField(this, 444, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialCompetition.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialCompetition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialCompetition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialCompetition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionOrder: jspb.Message.getFieldWithDefault(msg, 333, 0),
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 334, false),
    locationOrder: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialCompetition}
 */
proto.web_odds.InitialCompetition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialCompetition;
  return proto.web_odds.InitialCompetition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialCompetition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialCompetition}
 */
proto.web_odds.InitialCompetition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 333:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionOrder(value);
      break;
    case 334:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialCompetition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialCompetition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialCompetition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialCompetition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionOrder();
  if (f !== 0) {
    writer.writeInt32(
      333,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      334,
      f
    );
  }
  f = message.getLocationOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialCompetition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialCompetition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.web_odds.InitialCompetition.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_order = 333;
 * @return {number}
 */
proto.web_odds.InitialCompetition.prototype.getCompetitionOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 333, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setCompetitionOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 333, value);
};


/**
 * optional bool is_favorite = 334;
 * @return {boolean}
 */
proto.web_odds.InitialCompetition.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 334, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 334, value);
};


/**
 * optional int32 location_order = 4;
 * @return {number}
 */
proto.web_odds.InitialCompetition.prototype.getLocationOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialCompetition} returns this
 */
proto.web_odds.InitialCompetition.prototype.setLocationOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialBasicOffer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialBasicOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialBasicOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialBasicOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.web_odds.InitialBasicOfferMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialBasicOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialBasicOffer;
  return proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialBasicOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialBasicOffer}
 */
proto.web_odds.InitialBasicOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.InitialBasicOfferMarket;
      reader.readMessage(value,proto.web_odds.InitialBasicOfferMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialBasicOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialBasicOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialBasicOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_odds.InitialBasicOfferMarket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InitialBasicOfferMarket markets = 1;
 * @return {!Array<!proto.web_odds.InitialBasicOfferMarket>}
 */
proto.web_odds.InitialBasicOffer.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialBasicOfferMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialBasicOfferMarket, 1));
};


/**
 * @param {!Array<!proto.web_odds.InitialBasicOfferMarket>} value
 * @return {!proto.web_odds.InitialBasicOffer} returns this
*/
proto.web_odds.InitialBasicOffer.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_odds.InitialBasicOfferMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialBasicOfferMarket}
 */
proto.web_odds.InitialBasicOffer.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_odds.InitialBasicOfferMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialBasicOffer} returns this
 */
proto.web_odds.InitialBasicOffer.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialBasicOfferMarket.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialBasicOfferMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialBasicOfferMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOfferMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcomeIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialBasicOfferMarket}
 */
proto.web_odds.InitialBasicOfferMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialBasicOfferMarket;
  return proto.web_odds.InitialBasicOfferMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialBasicOfferMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialBasicOfferMarket}
 */
proto.web_odds.InitialBasicOfferMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOutcomeIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialBasicOfferMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialBasicOfferMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialBasicOfferMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutcomeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 market_id = 1;
 * @return {number}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 outcome_ids = 2;
 * @return {!Array<number>}
 */
proto.web_odds.InitialBasicOfferMarket.prototype.getOutcomeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.setOutcomeIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.addOutcomeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialBasicOfferMarket} returns this
 */
proto.web_odds.InitialBasicOfferMarket.prototype.clearOutcomeIdsList = function() {
  return this.setOutcomeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialMarketGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialMarketGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialMarketGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialMarketGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 555, ""),
    albanianName: jspb.Message.getFieldWithDefault(msg, 556, ""),
    turkishName: jspb.Message.getFieldWithDefault(msg, 557, ""),
    russianName: jspb.Message.getFieldWithDefault(msg, 558, ""),
    ukrainianName: jspb.Message.getFieldWithDefault(msg, 559, ""),
    italianName: jspb.Message.getFieldWithDefault(msg, 560, ""),
    germanName: jspb.Message.getFieldWithDefault(msg, 561, ""),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.web_odds.InitialMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialMarketGroup}
 */
proto.web_odds.InitialMarketGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialMarketGroup;
  return proto.web_odds.InitialMarketGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialMarketGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialMarketGroup}
 */
proto.web_odds.InitialMarketGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 555:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 556:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbanianName(value);
      break;
    case 557:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurkishName(value);
      break;
    case 558:
      var value = /** @type {string} */ (reader.readString());
      msg.setRussianName(value);
      break;
    case 559:
      var value = /** @type {string} */ (reader.readString());
      msg.setUkrainianName(value);
      break;
    case 560:
      var value = /** @type {string} */ (reader.readString());
      msg.setItalianName(value);
      break;
    case 561:
      var value = /** @type {string} */ (reader.readString());
      msg.setGermanName(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialMarket;
      reader.readMessage(value,proto.web_odds.InitialMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialMarketGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialMarketGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialMarketGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarketGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      555,
      f
    );
  }
  f = message.getAlbanianName();
  if (f.length > 0) {
    writer.writeString(
      556,
      f
    );
  }
  f = message.getTurkishName();
  if (f.length > 0) {
    writer.writeString(
      557,
      f
    );
  }
  f = message.getRussianName();
  if (f.length > 0) {
    writer.writeString(
      558,
      f
    );
  }
  f = message.getUkrainianName();
  if (f.length > 0) {
    writer.writeString(
      559,
      f
    );
  }
  f = message.getItalianName();
  if (f.length > 0) {
    writer.writeString(
      560,
      f
    );
  }
  f = message.getGermanName();
  if (f.length > 0) {
    writer.writeString(
      561,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialMarket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialMarketGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string english_name = 555;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 555, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 555, value);
};


/**
 * optional string albanian_name = 556;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getAlbanianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 556, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setAlbanianName = function(value) {
  return jspb.Message.setProto3StringField(this, 556, value);
};


/**
 * optional string turkish_name = 557;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getTurkishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 557, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setTurkishName = function(value) {
  return jspb.Message.setProto3StringField(this, 557, value);
};


/**
 * optional string russian_name = 558;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getRussianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 558, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setRussianName = function(value) {
  return jspb.Message.setProto3StringField(this, 558, value);
};


/**
 * optional string ukrainian_name = 559;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getUkrainianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 559, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setUkrainianName = function(value) {
  return jspb.Message.setProto3StringField(this, 559, value);
};


/**
 * optional string italian_name = 560;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getItalianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 560, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setItalianName = function(value) {
  return jspb.Message.setProto3StringField(this, 560, value);
};


/**
 * optional string german_name = 561;
 * @return {string}
 */
proto.web_odds.InitialMarketGroup.prototype.getGermanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 561, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.setGermanName = function(value) {
  return jspb.Message.setProto3StringField(this, 561, value);
};


/**
 * repeated InitialMarket markets = 3;
 * @return {!Array<!proto.web_odds.InitialMarket>}
 */
proto.web_odds.InitialMarketGroup.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarket, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarket>} value
 * @return {!proto.web_odds.InitialMarketGroup} returns this
*/
proto.web_odds.InitialMarketGroup.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.InitialMarketGroup.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialMarketGroup} returns this
 */
proto.web_odds.InitialMarketGroup.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.InitialMarket.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 555, ""),
    albanianName: jspb.Message.getFieldWithDefault(msg, 556, ""),
    turkishName: jspb.Message.getFieldWithDefault(msg, 557, ""),
    russianName: jspb.Message.getFieldWithDefault(msg, 558, ""),
    ukrainianName: jspb.Message.getFieldWithDefault(msg, 559, ""),
    italianName: jspb.Message.getFieldWithDefault(msg, 560, ""),
    germanName: jspb.Message.getFieldWithDefault(msg, 561, ""),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.web_odds.InitialOutcome.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.InitialMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialMarket;
  return proto.web_odds.InitialMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.InitialMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 555:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 556:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbanianName(value);
      break;
    case 557:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurkishName(value);
      break;
    case 558:
      var value = /** @type {string} */ (reader.readString());
      msg.setRussianName(value);
      break;
    case 559:
      var value = /** @type {string} */ (reader.readString());
      msg.setUkrainianName(value);
      break;
    case 560:
      var value = /** @type {string} */ (reader.readString());
      msg.setItalianName(value);
      break;
    case 561:
      var value = /** @type {string} */ (reader.readString());
      msg.setGermanName(value);
      break;
    case 4:
      var value = new proto.web_odds.InitialOutcome;
      reader.readMessage(value,proto.web_odds.InitialOutcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      555,
      f
    );
  }
  f = message.getAlbanianName();
  if (f.length > 0) {
    writer.writeString(
      556,
      f
    );
  }
  f = message.getTurkishName();
  if (f.length > 0) {
    writer.writeString(
      557,
      f
    );
  }
  f = message.getRussianName();
  if (f.length > 0) {
    writer.writeString(
      558,
      f
    );
  }
  f = message.getUkrainianName();
  if (f.length > 0) {
    writer.writeString(
      559,
      f
    );
  }
  f = message.getItalianName();
  if (f.length > 0) {
    writer.writeString(
      560,
      f
    );
  }
  f = message.getGermanName();
  if (f.length > 0) {
    writer.writeString(
      561,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.web_odds.InitialOutcome.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.web_odds.InitialMarket.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string english_name = 555;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 555, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 555, value);
};


/**
 * optional string albanian_name = 556;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getAlbanianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 556, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setAlbanianName = function(value) {
  return jspb.Message.setProto3StringField(this, 556, value);
};


/**
 * optional string turkish_name = 557;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getTurkishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 557, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setTurkishName = function(value) {
  return jspb.Message.setProto3StringField(this, 557, value);
};


/**
 * optional string russian_name = 558;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getRussianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 558, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setRussianName = function(value) {
  return jspb.Message.setProto3StringField(this, 558, value);
};


/**
 * optional string ukrainian_name = 559;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getUkrainianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 559, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setUkrainianName = function(value) {
  return jspb.Message.setProto3StringField(this, 559, value);
};


/**
 * optional string italian_name = 560;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getItalianName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 560, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setItalianName = function(value) {
  return jspb.Message.setProto3StringField(this, 560, value);
};


/**
 * optional string german_name = 561;
 * @return {string}
 */
proto.web_odds.InitialMarket.prototype.getGermanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 561, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.setGermanName = function(value) {
  return jspb.Message.setProto3StringField(this, 561, value);
};


/**
 * repeated InitialOutcome outcomes = 4;
 * @return {!Array<!proto.web_odds.InitialOutcome>}
 */
proto.web_odds.InitialMarket.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.web_odds.InitialOutcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialOutcome, 4));
};


/**
 * @param {!Array<!proto.web_odds.InitialOutcome>} value
 * @return {!proto.web_odds.InitialMarket} returns this
*/
proto.web_odds.InitialMarket.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.web_odds.InitialOutcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialOutcome}
 */
proto.web_odds.InitialMarket.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.web_odds.InitialOutcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.InitialMarket} returns this
 */
proto.web_odds.InitialMarket.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.InitialOutcome.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.InitialOutcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.InitialOutcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialOutcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outcomeTic: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exeLimit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.InitialOutcome}
 */
proto.web_odds.InitialOutcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.InitialOutcome;
  return proto.web_odds.InitialOutcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.InitialOutcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.InitialOutcome}
 */
proto.web_odds.InitialOutcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeTic(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExeLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.InitialOutcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.InitialOutcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.InitialOutcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.InitialOutcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutcomeTic();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExeLimit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.InitialOutcome.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outcome_group = 3;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outcome_tic = 4;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getOutcomeTic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setOutcomeTic = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exe_limit = 5;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getExeLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setExeLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string short_description = 6;
 * @return {string}
 */
proto.web_odds.InitialOutcome.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_limit = 7;
 * @return {boolean}
 */
proto.web_odds.InitialOutcome.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.InitialOutcome} returns this
 */
proto.web_odds.InitialOutcome.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventStreamString.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventStreamString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventStreamString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventStreamString.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEvents: jspb.Message.getFieldWithDefault(msg, 1, ""),
    upcomingEvents: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outrightEvents: jspb.Message.getFieldWithDefault(msg, 3, ""),
    playerEvents: jspb.Message.getFieldWithDefault(msg, 4, ""),
    groupEvents: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventStreamString}
 */
proto.web_odds.EventStreamString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventStreamString;
  return proto.web_odds.EventStreamString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventStreamString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventStreamString}
 */
proto.web_odds.EventStreamString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveEvents(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpcomingEvents(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightEvents(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerEvents(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventStreamString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventStreamString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventStreamString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventStreamString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEvents();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpcomingEvents();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutrightEvents();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlayerEvents();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGroupEvents();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string live_events = 1;
 * @return {string}
 */
proto.web_odds.EventStreamString.prototype.getLiveEvents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventStreamString} returns this
 */
proto.web_odds.EventStreamString.prototype.setLiveEvents = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string upcoming_events = 2;
 * @return {string}
 */
proto.web_odds.EventStreamString.prototype.getUpcomingEvents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventStreamString} returns this
 */
proto.web_odds.EventStreamString.prototype.setUpcomingEvents = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outright_events = 3;
 * @return {string}
 */
proto.web_odds.EventStreamString.prototype.getOutrightEvents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventStreamString} returns this
 */
proto.web_odds.EventStreamString.prototype.setOutrightEvents = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string player_events = 4;
 * @return {string}
 */
proto.web_odds.EventStreamString.prototype.getPlayerEvents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventStreamString} returns this
 */
proto.web_odds.EventStreamString.prototype.setPlayerEvents = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string group_events = 5;
 * @return {string}
 */
proto.web_odds.EventStreamString.prototype.getGroupEvents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.EventStreamString} returns this
 */
proto.web_odds.EventStreamString.prototype.setGroupEvents = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.EventStream.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.EventStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.EventStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEvents: (f = msg.getLiveEvents()) && proto.web_odds.WebStreamEvents.toObject(includeInstance, f),
    upcomingEvents: (f = msg.getUpcomingEvents()) && proto.web_odds.WebStreamEvents.toObject(includeInstance, f),
    outrightEvents: (f = msg.getOutrightEvents()) && proto.web_odds.WebStreamEvents.toObject(includeInstance, f),
    playerEvents: (f = msg.getPlayerEvents()) && proto.web_odds.WebStreamEvents.toObject(includeInstance, f),
    groupEvents: (f = msg.getGroupEvents()) && proto.web_odds.WebStreamEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.EventStream}
 */
proto.web_odds.EventStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.EventStream;
  return proto.web_odds.EventStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.EventStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.EventStream}
 */
proto.web_odds.EventStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.WebStreamEvents;
      reader.readMessage(value,proto.web_odds.WebStreamEvents.deserializeBinaryFromReader);
      msg.setLiveEvents(value);
      break;
    case 2:
      var value = new proto.web_odds.WebStreamEvents;
      reader.readMessage(value,proto.web_odds.WebStreamEvents.deserializeBinaryFromReader);
      msg.setUpcomingEvents(value);
      break;
    case 3:
      var value = new proto.web_odds.WebStreamEvents;
      reader.readMessage(value,proto.web_odds.WebStreamEvents.deserializeBinaryFromReader);
      msg.setOutrightEvents(value);
      break;
    case 4:
      var value = new proto.web_odds.WebStreamEvents;
      reader.readMessage(value,proto.web_odds.WebStreamEvents.deserializeBinaryFromReader);
      msg.setPlayerEvents(value);
      break;
    case 5:
      var value = new proto.web_odds.WebStreamEvents;
      reader.readMessage(value,proto.web_odds.WebStreamEvents.deserializeBinaryFromReader);
      msg.setGroupEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.EventStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.EventStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.EventStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.EventStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEvents();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.web_odds.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingEvents();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.web_odds.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getOutrightEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.web_odds.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getPlayerEvents();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.web_odds.WebStreamEvents.serializeBinaryToWriter
    );
  }
  f = message.getGroupEvents();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.web_odds.WebStreamEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional WebStreamEvents live_events = 1;
 * @return {?proto.web_odds.WebStreamEvents}
 */
proto.web_odds.EventStream.prototype.getLiveEvents = function() {
  return /** @type{?proto.web_odds.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.WebStreamEvents, 1));
};


/**
 * @param {?proto.web_odds.WebStreamEvents|undefined} value
 * @return {!proto.web_odds.EventStream} returns this
*/
proto.web_odds.EventStream.prototype.setLiveEvents = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventStream} returns this
 */
proto.web_odds.EventStream.prototype.clearLiveEvents = function() {
  return this.setLiveEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventStream.prototype.hasLiveEvents = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WebStreamEvents upcoming_events = 2;
 * @return {?proto.web_odds.WebStreamEvents}
 */
proto.web_odds.EventStream.prototype.getUpcomingEvents = function() {
  return /** @type{?proto.web_odds.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.WebStreamEvents, 2));
};


/**
 * @param {?proto.web_odds.WebStreamEvents|undefined} value
 * @return {!proto.web_odds.EventStream} returns this
*/
proto.web_odds.EventStream.prototype.setUpcomingEvents = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventStream} returns this
 */
proto.web_odds.EventStream.prototype.clearUpcomingEvents = function() {
  return this.setUpcomingEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventStream.prototype.hasUpcomingEvents = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WebStreamEvents outright_events = 3;
 * @return {?proto.web_odds.WebStreamEvents}
 */
proto.web_odds.EventStream.prototype.getOutrightEvents = function() {
  return /** @type{?proto.web_odds.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.WebStreamEvents, 3));
};


/**
 * @param {?proto.web_odds.WebStreamEvents|undefined} value
 * @return {!proto.web_odds.EventStream} returns this
*/
proto.web_odds.EventStream.prototype.setOutrightEvents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventStream} returns this
 */
proto.web_odds.EventStream.prototype.clearOutrightEvents = function() {
  return this.setOutrightEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventStream.prototype.hasOutrightEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WebStreamEvents player_events = 4;
 * @return {?proto.web_odds.WebStreamEvents}
 */
proto.web_odds.EventStream.prototype.getPlayerEvents = function() {
  return /** @type{?proto.web_odds.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.WebStreamEvents, 4));
};


/**
 * @param {?proto.web_odds.WebStreamEvents|undefined} value
 * @return {!proto.web_odds.EventStream} returns this
*/
proto.web_odds.EventStream.prototype.setPlayerEvents = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventStream} returns this
 */
proto.web_odds.EventStream.prototype.clearPlayerEvents = function() {
  return this.setPlayerEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventStream.prototype.hasPlayerEvents = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WebStreamEvents group_events = 5;
 * @return {?proto.web_odds.WebStreamEvents}
 */
proto.web_odds.EventStream.prototype.getGroupEvents = function() {
  return /** @type{?proto.web_odds.WebStreamEvents} */ (
    jspb.Message.getWrapperField(this, proto.web_odds.WebStreamEvents, 5));
};


/**
 * @param {?proto.web_odds.WebStreamEvents|undefined} value
 * @return {!proto.web_odds.EventStream} returns this
*/
proto.web_odds.EventStream.prototype.setGroupEvents = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.EventStream} returns this
 */
proto.web_odds.EventStream.prototype.clearGroupEvents = function() {
  return this.setGroupEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.EventStream.prototype.hasGroupEvents = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.WebStreamEvents.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.web_odds.WebStreamEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEvents}
 */
proto.web_odds.WebStreamEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEvents;
  return proto.web_odds.WebStreamEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEvents}
 */
proto.web_odds.WebStreamEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.web_odds.WebStreamEvent;
      reader.readMessage(value,proto.web_odds.WebStreamEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.web_odds.WebStreamEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebStreamEvent events = 17;
 * @return {!Array<!proto.web_odds.WebStreamEvent>}
 */
proto.web_odds.WebStreamEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEvent, 17));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEvent>} value
 * @return {!proto.web_odds.WebStreamEvents} returns this
*/
proto.web_odds.WebStreamEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.web_odds.WebStreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.WebStreamEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.web_odds.WebStreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.WebStreamEvents} returns this
 */
proto.web_odds.WebStreamEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.WebStreamEvent.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.web_odds.WebStreamEventOdd.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 13, 0),
    numberOfOdds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    profile: jspb.Message.getFieldWithDefault(msg, 16, ""),
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    betradarStreamId: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.WebStreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEvent;
  return proto.web_odds.WebStreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.WebStreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = new proto.web_odds.WebStreamEventOdd;
      reader.readMessage(value,proto.web_odds.WebStreamEventOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOdds(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.web_odds.WebStreamEventOdd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getNumberOfOdds();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBetradarStreamId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 location_id = 4;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated WebStreamEventOdd odds = 6;
 * @return {!Array<!proto.web_odds.WebStreamEventOdd>}
 */
proto.web_odds.WebStreamEvent.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEventOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEventOdd, 6));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEventOdd>} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
*/
proto.web_odds.WebStreamEvent.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.web_odds.WebStreamEventOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEventOdd}
 */
proto.web_odds.WebStreamEvent.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.web_odds.WebStreamEventOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web_odds.WebStreamEvent.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
*/
proto.web_odds.WebStreamEvent.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.WebStreamEvent.prototype.hasStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_disabled = 8;
 * @return {boolean}
 */
proto.web_odds.WebStreamEvent.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional common.EventResult result = 9;
 * @return {?proto.common.EventResult}
 */
proto.web_odds.WebStreamEvent.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 9));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
*/
proto.web_odds.WebStreamEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.WebStreamEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string landbase_code = 10;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outright_type = 12;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 mon = 13;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 number_of_odds = 14;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getNumberOfOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setNumberOfOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 exefeed_id = 15;
 * @return {number}
 */
proto.web_odds.WebStreamEvent.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string profile = 16;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string betradar_stream_id = 18;
 * @return {string}
 */
proto.web_odds.WebStreamEvent.prototype.getBetradarStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEvent} returns this
 */
proto.web_odds.WebStreamEvent.prototype.setBetradarStreamId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEventOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEventOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEventOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEventOdd}
 */
proto.web_odds.WebStreamEventOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEventOdd;
  return proto.web_odds.WebStreamEventOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEventOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEventOdd}
 */
proto.web_odds.WebStreamEventOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEventOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEventOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEventOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double limit = 4;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 frame_number = 5;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string current_result = 6;
 * @return {string}
 */
proto.web_odds.WebStreamEventOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_disabled = 7;
 * @return {boolean}
 */
proto.web_odds.WebStreamEventOdd.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.web_odds.WebStreamEventOdd.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.web_odds.WebStreamEventOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventOdd} returns this
 */
proto.web_odds.WebStreamEventOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.WebStreamEventInt.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEventInt.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEventInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEventInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.web_odds.WebStreamEventOddInt.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    result: (f = msg.getResult()) && proto_common_common_pb.EventResult.toObject(includeInstance, f),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outrightType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mon: jspb.Message.getFieldWithDefault(msg, 13, 0),
    numberOfOdds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    profile: jspb.Message.getFieldWithDefault(msg, 16, ""),
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    betradarStreamId: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEventInt}
 */
proto.web_odds.WebStreamEventInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEventInt;
  return proto.web_odds.WebStreamEventInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEventInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEventInt}
 */
proto.web_odds.WebStreamEventInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = new proto.web_odds.WebStreamEventOddInt;
      reader.readMessage(value,proto.web_odds.WebStreamEventOddInt.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = new proto_common_common_pb.EventResult;
      reader.readMessage(value,proto_common_common_pb.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutrightType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMon(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOdds(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEventInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEventInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEventInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.web_odds.WebStreamEventOddInt.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutrightType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMon();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getNumberOfOdds();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBetradarStreamId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.web_odds.WebStreamEventInt.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 location_id = 4;
 * @return {number}
 */
proto.web_odds.WebStreamEventInt.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.web_odds.WebStreamEventInt.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated WebStreamEventOddInt odds = 6;
 * @return {!Array<!proto.web_odds.WebStreamEventOddInt>}
 */
proto.web_odds.WebStreamEventInt.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEventOddInt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEventOddInt, 6));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEventOddInt>} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
*/
proto.web_odds.WebStreamEventInt.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.web_odds.WebStreamEventOddInt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEventOddInt}
 */
proto.web_odds.WebStreamEventInt.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.web_odds.WebStreamEventOddInt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web_odds.WebStreamEventInt.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
*/
proto.web_odds.WebStreamEventInt.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.WebStreamEventInt.prototype.hasStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_disabled = 8;
 * @return {boolean}
 */
proto.web_odds.WebStreamEventInt.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional common.EventResult result = 9;
 * @return {?proto.common.EventResult}
 */
proto.web_odds.WebStreamEventInt.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.EventResult, 9));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
*/
proto.web_odds.WebStreamEventInt.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.WebStreamEventInt.prototype.hasResult = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string landbase_code = 10;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outright_type = 12;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getOutrightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setOutrightType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 mon = 13;
 * @return {number}
 */
proto.web_odds.WebStreamEventInt.prototype.getMon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setMon = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 number_of_odds = 14;
 * @return {number}
 */
proto.web_odds.WebStreamEventInt.prototype.getNumberOfOdds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setNumberOfOdds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 exefeed_id = 15;
 * @return {number}
 */
proto.web_odds.WebStreamEventInt.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string profile = 16;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string betradar_stream_id = 18;
 * @return {string}
 */
proto.web_odds.WebStreamEventInt.prototype.getBetradarStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventInt} returns this
 */
proto.web_odds.WebStreamEventInt.prototype.setBetradarStreamId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.WebStreamEventOddInt.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.WebStreamEventOddInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.WebStreamEventOddInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventOddInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.WebStreamEventOddInt}
 */
proto.web_odds.WebStreamEventOddInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.WebStreamEventOddInt;
  return proto.web_odds.WebStreamEventOddInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.WebStreamEventOddInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.WebStreamEventOddInt}
 */
proto.web_odds.WebStreamEventOddInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.WebStreamEventOddInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.WebStreamEventOddInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.WebStreamEventOddInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.WebStreamEventOddInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 frame_number = 5;
 * @return {number}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string current_result = 6;
 * @return {string}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_disabled = 7;
 * @return {boolean}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string live_player = 9;
 * @return {string}
 */
proto.web_odds.WebStreamEventOddInt.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.WebStreamEventOddInt} returns this
 */
proto.web_odds.WebStreamEventOddInt.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.LiveDisabled.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.LiveDisabled.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.LiveDisabled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.LiveDisabled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.LiveDisabled.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    sportIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.LiveDisabled}
 */
proto.web_odds.LiveDisabled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.LiveDisabled;
  return proto.web_odds.LiveDisabled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.LiveDisabled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.LiveDisabled}
 */
proto.web_odds.LiveDisabled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAll(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSportIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.LiveDisabled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.LiveDisabled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.LiveDisabled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.LiveDisabled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSportIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional bool all = 1;
 * @return {boolean}
 */
proto.web_odds.LiveDisabled.prototype.getAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.setAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated int32 sport_ids = 2;
 * @return {!Array<number>}
 */
proto.web_odds.LiveDisabled.prototype.getSportIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.setSportIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.addSportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.LiveDisabled} returns this
 */
proto.web_odds.LiveDisabled.prototype.clearSportIdsList = function() {
  return this.setSportIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.PlayerOutrightMarkets.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.PlayerOutrightMarkets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.PlayerOutrightMarkets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.PlayerOutrightMarkets.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outrightMarketsList: jspb.Message.toObjectList(msg.getOutrightMarketsList(),
    proto.web_odds.InitialMarket.toObject, includeInstance),
    playerMarketsList: jspb.Message.toObjectList(msg.getPlayerMarketsList(),
    proto.web_odds.InitialMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.PlayerOutrightMarkets}
 */
proto.web_odds.PlayerOutrightMarkets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.PlayerOutrightMarkets;
  return proto.web_odds.PlayerOutrightMarkets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.PlayerOutrightMarkets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.PlayerOutrightMarkets}
 */
proto.web_odds.PlayerOutrightMarkets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 2:
      var value = new proto.web_odds.InitialMarket;
      reader.readMessage(value,proto.web_odds.InitialMarket.deserializeBinaryFromReader);
      msg.addOutrightMarkets(value);
      break;
    case 3:
      var value = new proto.web_odds.InitialMarket;
      reader.readMessage(value,proto.web_odds.InitialMarket.deserializeBinaryFromReader);
      msg.addPlayerMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.PlayerOutrightMarkets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.PlayerOutrightMarkets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.PlayerOutrightMarkets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutrightMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.web_odds.InitialMarket.serializeBinaryToWriter
    );
  }
  f = message.getPlayerMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.InitialMarket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 sport_id = 1;
 * @return {number}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
 */
proto.web_odds.PlayerOutrightMarkets.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated InitialMarket outright_markets = 2;
 * @return {!Array<!proto.web_odds.InitialMarket>}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.getOutrightMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarket, 2));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarket>} value
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
*/
proto.web_odds.PlayerOutrightMarkets.prototype.setOutrightMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web_odds.InitialMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.addOutrightMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web_odds.InitialMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
 */
proto.web_odds.PlayerOutrightMarkets.prototype.clearOutrightMarketsList = function() {
  return this.setOutrightMarketsList([]);
};


/**
 * repeated InitialMarket player_markets = 3;
 * @return {!Array<!proto.web_odds.InitialMarket>}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.getPlayerMarketsList = function() {
  return /** @type{!Array<!proto.web_odds.InitialMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.InitialMarket, 3));
};


/**
 * @param {!Array<!proto.web_odds.InitialMarket>} value
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
*/
proto.web_odds.PlayerOutrightMarkets.prototype.setPlayerMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.InitialMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.InitialMarket}
 */
proto.web_odds.PlayerOutrightMarkets.prototype.addPlayerMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.InitialMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.PlayerOutrightMarkets} returns this
 */
proto.web_odds.PlayerOutrightMarkets.prototype.clearPlayerMarketsList = function() {
  return this.setPlayerMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.LandingPage.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.LandingPage.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.LandingPage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.LandingPage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.LandingPage.toObject = function(includeInstance, msg) {
  var f, obj = {
    topList: jspb.Message.toObjectList(msg.getTopList(),
    proto.web_odds.WebStreamEvent.toObject, includeInstance),
    liveList: jspb.Message.toObjectList(msg.getLiveList(),
    proto.web_odds.WebStreamEvent.toObject, includeInstance),
    prematchList: jspb.Message.toObjectList(msg.getPrematchList(),
    proto.web_odds.WebStreamEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.LandingPage}
 */
proto.web_odds.LandingPage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.LandingPage;
  return proto.web_odds.LandingPage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.LandingPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.LandingPage}
 */
proto.web_odds.LandingPage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.WebStreamEvent;
      reader.readMessage(value,proto.web_odds.WebStreamEvent.deserializeBinaryFromReader);
      msg.addTop(value);
      break;
    case 2:
      var value = new proto.web_odds.WebStreamEvent;
      reader.readMessage(value,proto.web_odds.WebStreamEvent.deserializeBinaryFromReader);
      msg.addLive(value);
      break;
    case 3:
      var value = new proto.web_odds.WebStreamEvent;
      reader.readMessage(value,proto.web_odds.WebStreamEvent.deserializeBinaryFromReader);
      msg.addPrematch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.LandingPage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.LandingPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.LandingPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.LandingPage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_odds.WebStreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getLiveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.web_odds.WebStreamEvent.serializeBinaryToWriter
    );
  }
  f = message.getPrematchList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.web_odds.WebStreamEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebStreamEvent top = 1;
 * @return {!Array<!proto.web_odds.WebStreamEvent>}
 */
proto.web_odds.LandingPage.prototype.getTopList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEvent, 1));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEvent>} value
 * @return {!proto.web_odds.LandingPage} returns this
*/
proto.web_odds.LandingPage.prototype.setTopList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_odds.WebStreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.LandingPage.prototype.addTop = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_odds.WebStreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.LandingPage} returns this
 */
proto.web_odds.LandingPage.prototype.clearTopList = function() {
  return this.setTopList([]);
};


/**
 * repeated WebStreamEvent live = 2;
 * @return {!Array<!proto.web_odds.WebStreamEvent>}
 */
proto.web_odds.LandingPage.prototype.getLiveList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEvent, 2));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEvent>} value
 * @return {!proto.web_odds.LandingPage} returns this
*/
proto.web_odds.LandingPage.prototype.setLiveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web_odds.WebStreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.LandingPage.prototype.addLive = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web_odds.WebStreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.LandingPage} returns this
 */
proto.web_odds.LandingPage.prototype.clearLiveList = function() {
  return this.setLiveList([]);
};


/**
 * repeated WebStreamEvent prematch = 3;
 * @return {!Array<!proto.web_odds.WebStreamEvent>}
 */
proto.web_odds.LandingPage.prototype.getPrematchList = function() {
  return /** @type{!Array<!proto.web_odds.WebStreamEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.WebStreamEvent, 3));
};


/**
 * @param {!Array<!proto.web_odds.WebStreamEvent>} value
 * @return {!proto.web_odds.LandingPage} returns this
*/
proto.web_odds.LandingPage.prototype.setPrematchList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web_odds.WebStreamEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.WebStreamEvent}
 */
proto.web_odds.LandingPage.prototype.addPrematch = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web_odds.WebStreamEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.LandingPage} returns this
 */
proto.web_odds.LandingPage.prototype.clearPrematchList = function() {
  return this.setPrematchList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.FeaturedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.FeaturedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.FeaturedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.FeaturedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.FeaturedEvent}
 */
proto.web_odds.FeaturedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.FeaturedEvent;
  return proto.web_odds.FeaturedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.FeaturedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.FeaturedEvent}
 */
proto.web_odds.FeaturedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.FeaturedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.FeaturedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.FeaturedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.FeaturedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.web_odds.FeaturedEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.FeaturedEvent} returns this
 */
proto.web_odds.FeaturedEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_odds.FeaturedEvents.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.FeaturedEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.FeaturedEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.FeaturedEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.FeaturedEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.web_odds.FeaturedEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.FeaturedEvents}
 */
proto.web_odds.FeaturedEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.FeaturedEvents;
  return proto.web_odds.FeaturedEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.FeaturedEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.FeaturedEvents}
 */
proto.web_odds.FeaturedEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_odds.FeaturedEvent;
      reader.readMessage(value,proto.web_odds.FeaturedEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.FeaturedEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.FeaturedEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.FeaturedEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.FeaturedEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_odds.FeaturedEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FeaturedEvent events = 1;
 * @return {!Array<!proto.web_odds.FeaturedEvent>}
 */
proto.web_odds.FeaturedEvents.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.web_odds.FeaturedEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_odds.FeaturedEvent, 1));
};


/**
 * @param {!Array<!proto.web_odds.FeaturedEvent>} value
 * @return {!proto.web_odds.FeaturedEvents} returns this
*/
proto.web_odds.FeaturedEvents.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_odds.FeaturedEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_odds.FeaturedEvent}
 */
proto.web_odds.FeaturedEvents.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_odds.FeaturedEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_odds.FeaturedEvents} returns this
 */
proto.web_odds.FeaturedEvents.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.GetStreamUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.GetStreamUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.GetStreamUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.GetStreamUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    streamType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    csid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceCategory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    realIp: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.GetStreamUrlRequest}
 */
proto.web_odds.GetStreamUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.GetStreamUrlRequest;
  return proto.web_odds.GetStreamUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.GetStreamUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.GetStreamUrlRequest}
 */
proto.web_odds.GetStreamUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.GetStreamUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.GetStreamUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.GetStreamUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.GetStreamUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreamType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCsid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRealIp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string stream_id = 1;
 * @return {string}
 */
proto.web_odds.GetStreamUrlRequest.prototype.getStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.GetStreamUrlRequest} returns this
 */
proto.web_odds.GetStreamUrlRequest.prototype.setStreamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string stream_type = 2;
 * @return {string}
 */
proto.web_odds.GetStreamUrlRequest.prototype.getStreamType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.GetStreamUrlRequest} returns this
 */
proto.web_odds.GetStreamUrlRequest.prototype.setStreamType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string csid = 3;
 * @return {string}
 */
proto.web_odds.GetStreamUrlRequest.prototype.getCsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.GetStreamUrlRequest} returns this
 */
proto.web_odds.GetStreamUrlRequest.prototype.setCsid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_category = 4;
 * @return {string}
 */
proto.web_odds.GetStreamUrlRequest.prototype.getDeviceCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.GetStreamUrlRequest} returns this
 */
proto.web_odds.GetStreamUrlRequest.prototype.setDeviceCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string real_ip = 5;
 * @return {string}
 */
proto.web_odds.GetStreamUrlRequest.prototype.getRealIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.GetStreamUrlRequest} returns this
 */
proto.web_odds.GetStreamUrlRequest.prototype.setRealIp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_odds.StreamUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.web_odds.StreamUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_odds.StreamUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.StreamUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_odds.StreamUrl}
 */
proto.web_odds.StreamUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_odds.StreamUrl;
  return proto.web_odds.StreamUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_odds.StreamUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_odds.StreamUrl}
 */
proto.web_odds.StreamUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_odds.StreamUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_odds.StreamUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_odds.StreamUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_odds.StreamUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.web_odds.StreamUrl.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_odds.StreamUrl} returns this
 */
proto.web_odds.StreamUrl.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Error error = 2;
 * @return {?proto.common.Error}
 */
proto.web_odds.StreamUrl.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 2));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.web_odds.StreamUrl} returns this
*/
proto.web_odds.StreamUrl.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_odds.StreamUrl} returns this
 */
proto.web_odds.StreamUrl.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_odds.StreamUrl.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.web_odds);
