const FEED_SOCKET_ENDPOINT = 'ws/wsfeed';

const USER_PREMATCH_CHANNEL = 'web:prematch';
const USER_LIVE_CHANNEL = 'web:live';

const GUEST_PREMATCH_CHANNEL = 'guest:prematch';
const GUEST_LIVE_CHANNEL = 'guest:live';

const EVENT_CHANNEL = 'web:event';

const AMUSNET_JACKPOT_CHANNEL = 'jackpot:amusnet';

export {
  FEED_SOCKET_ENDPOINT,
  USER_PREMATCH_CHANNEL,
  USER_LIVE_CHANNEL,
  GUEST_PREMATCH_CHANNEL,
  GUEST_LIVE_CHANNEL,
  EVENT_CHANNEL,
  AMUSNET_JACKPOT_CHANNEL,
};
