/* React modules */
import { useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Input } from 'components';
import Button from 'components/Button';
import './CreditDebitAmountPicker.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface CreditDebitAmountPickerProps {
  buttonText: string;
  amountOptions?: Amount[];
  onSubmit: (arg: any) => void;
  min?: number;
  max?: number;
  isDebit?: boolean;
  isPoker?: boolean;
}

interface Amount {
  label: string;
  value: number;
}

const CreditDebitAmountPicker = ({
  buttonText,
  amountOptions,
  onSubmit,
  min,
  max,
  isDebit,
  isPoker,
}: CreditDebitAmountPickerProps) => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const [selectedAmount, setSelectedAmount] = useState<number>(5);
  const [amountError, setAmountError] = useState<string | null>(null);

  const selectAmountHandler = (amount: number) => {
    setSelectedAmount(amount);
  };

  const availableFunds = userStore?.standardWallet?.available;

  const setAmountHandler = (e: any) => {
    setSelectedAmount(e.target.value);

    if (min && e.target.value < min) {
      const messageKey = isDebit
        ? 'validations.min-debit'
        : 'validations.min-credit';
      setAmountError(t(messageKey, { min }));
    } else if (max && e.target.value > max) {
      const messageKey = isDebit
        ? 'validations.max-debit'
        : 'validations.max-credit';
      setAmountError(t(messageKey, { max }));
    } else if (
      !isDebit &&
      !isPoker &&
      e.target.value > Number(availableFunds)
    ) {
      setAmountError(t('validations.amount-greater-than-available'));
    } else {
      setAmountError('');
    }
  };

  const clickEventHandler = () => {
    onSubmit(selectedAmount);
  };

  return (
    <div>
      <div className="amount-picker">
        {amountOptions &&
          amountOptions.map((amount: Amount, i: number) => (
            <Button
              key={`CreditDebitAmountPicker-Button-${i}`}
              className="__btn"
              bg="success"
              onClick={() => selectAmountHandler(amount.value)}
            >
              {amount.label} EUR
            </Button>
          ))}
      </div>

      <div className="amount-input">
        <p className="sb-text-small">{t('payments.amount')}</p>
        <div className="custom_amount">
          <div style={{ position: 'relative' }}>
            <Input
              className="w-100 custom_amount__input mt-1 mr-1"
              name="amount-custom-input"
              bg="light"
              type="number"
              onChange={setAmountHandler}
              value={selectedAmount}
            />

            {amountError && (
              <span
                style={{ position: 'absolute', top: '40px' }}
                className="text-small text-red"
              >
                {amountError}
              </span>
            )}
          </div>

          <Button
            className="__btn mt-1 uppercase"
            style={{ height: '100%', color: 'black' }}
            bg="warning"
            disabled={!!amountError}
            onClick={clickEventHandler}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

CreditDebitAmountPicker.defaultProps = {
  isDebit: true,
};

export default observer(CreditDebitAmountPicker);
