// source: proto/common/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.common.EmptyError', null, global);
goog.exportSymbol('proto.common.Error', null, global);
goog.exportSymbol('proto.common.EventCurrentPhase', null, global);
goog.exportSymbol('proto.common.EventCurrentResult', null, global);
goog.exportSymbol('proto.common.EventLiveResultHistoryElement', null, global);
goog.exportSymbol('proto.common.EventResult', null, global);
goog.exportSymbol('proto.common.EventResultPeriod', null, global);
goog.exportSymbol('proto.common.EventTime', null, global);
goog.exportSymbol('proto.common.FastOdd', null, global);
goog.exportSymbol('proto.common.FastSlipGroup', null, global);
goog.exportSymbol('proto.common.FastSlipRequest', null, global);
goog.exportSymbol('proto.common.FastSlipSubGroup', null, global);
goog.exportSymbol('proto.common.Slip', null, global);
goog.exportSymbol('proto.common.SlipGroup', null, global);
goog.exportSymbol('proto.common.SlipRequest', null, global);
goog.exportSymbol('proto.common.SlipSubGroup', null, global);
goog.exportSymbol('proto.common.SortBy', null, global);
goog.exportSymbol('proto.common.UserSlipEvent', null, global);
goog.exportSymbol('proto.common.UserSlipGroup', null, global);
goog.exportSymbol('proto.common.UserSlipOdd', null, global);
goog.exportSymbol('proto.common.UserSlipSubGroup', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SlipGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.SlipGroup.repeatedFields_, null);
};
goog.inherits(proto.common.SlipGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SlipGroup.displayName = 'proto.common.SlipGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SlipSubGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.SlipSubGroup.repeatedFields_, null);
};
goog.inherits(proto.common.SlipSubGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SlipSubGroup.displayName = 'proto.common.SlipSubGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SlipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.SlipRequest.repeatedFields_, null);
};
goog.inherits(proto.common.SlipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SlipRequest.displayName = 'proto.common.SlipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Error.repeatedFields_, null);
};
goog.inherits(proto.common.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Error.displayName = 'proto.common.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EmptyError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EmptyError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EmptyError.displayName = 'proto.common.EmptyError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SortBy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SortBy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SortBy.displayName = 'proto.common.SortBy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.UserSlipOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.UserSlipOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.UserSlipOdd.displayName = 'proto.common.UserSlipOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.UserSlipEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.UserSlipEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.UserSlipEvent.displayName = 'proto.common.UserSlipEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.UserSlipSubGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.UserSlipSubGroup.repeatedFields_, null);
};
goog.inherits(proto.common.UserSlipSubGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.UserSlipSubGroup.displayName = 'proto.common.UserSlipSubGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.UserSlipGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.UserSlipGroup.repeatedFields_, null);
};
goog.inherits(proto.common.UserSlipGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.UserSlipGroup.displayName = 'proto.common.UserSlipGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Slip = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Slip.repeatedFields_, null);
};
goog.inherits(proto.common.Slip, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Slip.displayName = 'proto.common.Slip';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EventTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EventTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EventTime.displayName = 'proto.common.EventTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EventCurrentPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EventCurrentPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EventCurrentPhase.displayName = 'proto.common.EventCurrentPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EventCurrentResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EventCurrentResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EventCurrentResult.displayName = 'proto.common.EventCurrentResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EventLiveResultHistoryElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EventLiveResultHistoryElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EventLiveResultHistoryElement.displayName = 'proto.common.EventLiveResultHistoryElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EventResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.EventResult.repeatedFields_, null);
};
goog.inherits(proto.common.EventResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EventResult.displayName = 'proto.common.EventResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EventResultPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EventResultPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EventResultPeriod.displayName = 'proto.common.EventResultPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FastOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.FastOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FastOdd.displayName = 'proto.common.FastOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FastSlipGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.FastSlipGroup.repeatedFields_, null);
};
goog.inherits(proto.common.FastSlipGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FastSlipGroup.displayName = 'proto.common.FastSlipGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FastSlipSubGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.FastSlipSubGroup.repeatedFields_, null);
};
goog.inherits(proto.common.FastSlipSubGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FastSlipSubGroup.displayName = 'proto.common.FastSlipSubGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FastSlipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.FastSlipRequest.repeatedFields_, null);
};
goog.inherits(proto.common.FastSlipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FastSlipRequest.displayName = 'proto.common.FastSlipRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.SlipGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SlipGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SlipGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SlipGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SlipGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subGroupsList: jspb.Message.toObjectList(msg.getSubGroupsList(),
    proto.common.SlipSubGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SlipGroup}
 */
proto.common.SlipGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SlipGroup;
  return proto.common.SlipGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SlipGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SlipGroup}
 */
proto.common.SlipGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.common.SlipSubGroup;
      reader.readMessage(value,proto.common.SlipSubGroup.deserializeBinaryFromReader);
      msg.addSubGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SlipGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SlipGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SlipGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SlipGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.SlipSubGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.SlipGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipGroup} returns this
 */
proto.common.SlipGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SlipSubGroup sub_groups = 3;
 * @return {!Array<!proto.common.SlipSubGroup>}
 */
proto.common.SlipGroup.prototype.getSubGroupsList = function() {
  return /** @type{!Array<!proto.common.SlipSubGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.SlipSubGroup, 3));
};


/**
 * @param {!Array<!proto.common.SlipSubGroup>} value
 * @return {!proto.common.SlipGroup} returns this
*/
proto.common.SlipGroup.prototype.setSubGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.SlipSubGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SlipSubGroup}
 */
proto.common.SlipGroup.prototype.addSubGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.SlipSubGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.SlipGroup} returns this
 */
proto.common.SlipGroup.prototype.clearSubGroupsList = function() {
  return this.setSubGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.SlipSubGroup.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SlipSubGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SlipSubGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SlipSubGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SlipSubGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oddsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    oddValuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SlipSubGroup}
 */
proto.common.SlipSubGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SlipSubGroup;
  return proto.common.SlipSubGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SlipSubGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SlipSubGroup}
 */
proto.common.SlipSubGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOdds(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOddValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SlipSubGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SlipSubGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SlipSubGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SlipSubGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getOddValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.SlipSubGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.common.SlipSubGroup.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 odds = 8;
 * @return {!Array<number>}
 */
proto.common.SlipSubGroup.prototype.getOddsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.setOddsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.addOdds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * repeated double odd_values = 9;
 * @return {!Array<number>}
 */
proto.common.SlipSubGroup.prototype.getOddValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.setOddValuesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.addOddValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.SlipSubGroup} returns this
 */
proto.common.SlipSubGroup.prototype.clearOddValuesList = function() {
  return this.setOddValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.SlipRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SlipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SlipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SlipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SlipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    credit: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    creditee: jspb.Message.getFieldWithDefault(msg, 13, ""),
    crediteeUuid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    creditNote: jspb.Message.getFieldWithDefault(msg, 12, ""),
    slipGroupsList: jspb.Message.toObjectList(msg.getSlipGroupsList(),
    proto.common.SlipGroup.toObject, includeInstance),
    approvalReason: jspb.Message.getFieldWithDefault(msg, 10, ""),
    walletPrefix: jspb.Message.getFieldWithDefault(msg, 7, ""),
    noLiveChanges: jspb.Message.getBooleanFieldWithDefault(msg, 53, false),
    ssn: jspb.Message.getFieldWithDefault(msg, 15, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    identificationDocumentCountry: jspb.Message.getFieldWithDefault(msg, 17, ""),
    identificationDocumentType: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SlipRequest}
 */
proto.common.SlipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SlipRequest;
  return proto.common.SlipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SlipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SlipRequest}
 */
proto.common.SlipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCredit(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditee(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrediteeUuid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditNote(value);
      break;
    case 4:
      var value = new proto.common.SlipGroup;
      reader.readMessage(value,proto.common.SlipGroup.deserializeBinaryFromReader);
      msg.addSlipGroups(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovalReason(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWalletPrefix(value);
      break;
    case 53:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoLiveChanges(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsn(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentificationDocumentCountry(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentificationDocumentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SlipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SlipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SlipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SlipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCredit();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCreditee();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCrediteeUuid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreditNote();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSlipGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.common.SlipGroup.serializeBinaryToWriter
    );
  }
  f = message.getApprovalReason();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWalletPrefix();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNoLiveChanges();
  if (f) {
    writer.writeBool(
      53,
      f
    );
  }
  f = message.getSsn();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPassportNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIdentificationDocumentCountry();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIdentificationDocumentType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.common.SlipRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool credit = 11;
 * @return {boolean}
 */
proto.common.SlipRequest.prototype.getCredit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setCredit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string creditee = 13;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getCreditee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setCreditee = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string creditee_uuid = 14;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getCrediteeUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setCrediteeUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string credit_note = 12;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getCreditNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setCreditNote = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated SlipGroup slip_groups = 4;
 * @return {!Array<!proto.common.SlipGroup>}
 */
proto.common.SlipRequest.prototype.getSlipGroupsList = function() {
  return /** @type{!Array<!proto.common.SlipGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.SlipGroup, 4));
};


/**
 * @param {!Array<!proto.common.SlipGroup>} value
 * @return {!proto.common.SlipRequest} returns this
*/
proto.common.SlipRequest.prototype.setSlipGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.SlipGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SlipGroup}
 */
proto.common.SlipRequest.prototype.addSlipGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.SlipGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.clearSlipGroupsList = function() {
  return this.setSlipGroupsList([]);
};


/**
 * optional string approval_reason = 10;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getApprovalReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setApprovalReason = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string wallet_prefix = 7;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getWalletPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setWalletPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool no_live_changes = 53;
 * @return {boolean}
 */
proto.common.SlipRequest.prototype.getNoLiveChanges = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 53, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setNoLiveChanges = function(value) {
  return jspb.Message.setProto3BooleanField(this, 53, value);
};


/**
 * optional string ssn = 15;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getSsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setSsn = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string passport_number = 16;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setPassportNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string identification_document_country = 17;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getIdentificationDocumentCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setIdentificationDocumentCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string identification_document_type = 18;
 * @return {string}
 */
proto.common.SlipRequest.prototype.getIdentificationDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SlipRequest} returns this
 */
proto.common.SlipRequest.prototype.setIdentificationDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Error.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    grpcCode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    httpCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    messageList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Error}
 */
proto.common.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Error;
  return proto.common.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Error}
 */
proto.common.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGrpcCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHttpCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGrpcCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHttpCode();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMessageList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional int32 grpc_code = 5;
 * @return {number}
 */
proto.common.Error.prototype.getGrpcCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.setGrpcCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 http_code = 6;
 * @return {number}
 */
proto.common.Error.prototype.getHttpCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.setHttpCode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string message = 7;
 * @return {!Array<string>}
 */
proto.common.Error.prototype.getMessageList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.setMessageList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.addMessage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.clearMessageList = function() {
  return this.setMessageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EmptyError.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EmptyError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EmptyError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EmptyError.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.common.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EmptyError}
 */
proto.common.EmptyError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EmptyError;
  return proto.common.EmptyError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EmptyError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EmptyError}
 */
proto.common.EmptyError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = new proto.common.Error;
      reader.readMessage(value,proto.common.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EmptyError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EmptyError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EmptyError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EmptyError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.common.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 9;
 * @return {?proto.common.Error}
 */
proto.common.EmptyError.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.common.EmptyError} returns this
*/
proto.common.EmptyError.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EmptyError} returns this
 */
proto.common.EmptyError.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EmptyError.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SortBy.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SortBy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SortBy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SortBy.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SortBy}
 */
proto.common.SortBy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SortBy;
  return proto.common.SortBy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SortBy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SortBy}
 */
proto.common.SortBy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SortBy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SortBy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SortBy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SortBy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.common.SortBy.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SortBy} returns this
 */
proto.common.SortBy.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool desc = 2;
 * @return {boolean}
 */
proto.common.SortBy.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.SortBy} returns this
 */
proto.common.SortBy.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.UserSlipOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.common.UserSlipOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.UserSlipOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 10, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    oldValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 86, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 110, 0),
    marketName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    outcomeTic: jspb.Message.getFieldWithDefault(msg, 119, ""),
    outcomeGroup: jspb.Message.getFieldWithDefault(msg, 100, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    oldLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 87, 0.0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 111, 0),
    currentResult: jspb.Message.getFieldWithDefault(msg, 112, ""),
    livePlayer: jspb.Message.getFieldWithDefault(msg, 113, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.UserSlipOdd}
 */
proto.common.UserSlipOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.UserSlipOdd;
  return proto.common.UserSlipOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.UserSlipOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.UserSlipOdd}
 */
proto.common.UserSlipOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 86:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOldValue(value);
      break;
    case 110:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    case 119:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeTic(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeGroup(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 87:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOldLimit(value);
      break;
    case 111:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameNumber(value);
      break;
    case 112:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentResult(value);
      break;
    case 113:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivePlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.UserSlipOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.UserSlipOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.UserSlipOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getOldValue();
  if (f !== 0.0) {
    writer.writeDouble(
      86,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      110,
      f
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOutcomeTic();
  if (f.length > 0) {
    writer.writeString(
      119,
      f
    );
  }
  f = message.getOutcomeGroup();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      41,
      f
    );
  }
  f = message.getOldLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      87,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeInt32(
      111,
      f
    );
  }
  f = message.getCurrentResult();
  if (f.length > 0) {
    writer.writeString(
      112,
      f
    );
  }
  f = message.getLivePlayer();
  if (f.length > 0) {
    writer.writeString(
      113,
      f
    );
  }
};


/**
 * optional string id = 10;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double value = 6;
 * @return {number}
 */
proto.common.UserSlipOdd.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double old_value = 86;
 * @return {number}
 */
proto.common.UserSlipOdd.prototype.getOldValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 86, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setOldValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 86, value);
};


/**
 * optional int32 outcome_id = 110;
 * @return {number}
 */
proto.common.UserSlipOdd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 110, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 110, value);
};


/**
 * optional string market_name = 7;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string outcome_name = 8;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string outcome_tic = 119;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getOutcomeTic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 119, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setOutcomeTic = function(value) {
  return jspb.Message.setProto3StringField(this, 119, value);
};


/**
 * optional string outcome_group = 100;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getOutcomeGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setOutcomeGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double limit = 41;
 * @return {number}
 */
proto.common.UserSlipOdd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional double old_limit = 87;
 * @return {number}
 */
proto.common.UserSlipOdd.prototype.getOldLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 87, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setOldLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 87, value);
};


/**
 * optional int32 frame_number = 111;
 * @return {number}
 */
proto.common.UserSlipOdd.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 111, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 111, value);
};


/**
 * optional string current_result = 112;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getCurrentResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 112, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setCurrentResult = function(value) {
  return jspb.Message.setProto3StringField(this, 112, value);
};


/**
 * optional string live_player = 113;
 * @return {string}
 */
proto.common.UserSlipOdd.prototype.getLivePlayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 113, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipOdd} returns this
 */
proto.common.UserSlipOdd.prototype.setLivePlayer = function(value) {
  return jspb.Message.setProto3StringField(this, 113, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.UserSlipEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.common.UserSlipEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.UserSlipEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isSuspicious: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isSingleApproval: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    note: jspb.Message.getFieldWithDefault(msg, 11, ""),
    result: (f = msg.getResult()) && proto.common.EventResult.toObject(includeInstance, f),
    placeBetResult: (f = msg.getPlaceBetResult()) && proto.common.EventResult.toObject(includeInstance, f),
    paSport: jspb.Message.getFieldWithDefault(msg, 14, 0),
    paType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    exefeedId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    competitionShortName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    flagCode: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.UserSlipEvent}
 */
proto.common.UserSlipEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.UserSlipEvent;
  return proto.common.UserSlipEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.UserSlipEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.UserSlipEvent}
 */
proto.common.UserSlipEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspicious(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleApproval(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandbaseCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 12:
      var value = new proto.common.EventResult;
      reader.readMessage(value,proto.common.EventResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 13:
      var value = new proto.common.EventResult;
      reader.readMessage(value,proto.common.EventResult.deserializeBinaryFromReader);
      msg.setPlaceBetResult(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaSport(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaType(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExefeedId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionShortName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.UserSlipEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.UserSlipEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.UserSlipEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsSuspicious();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsSingleApproval();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.common.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getPlaceBetResult();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.common.EventResult.serializeBinaryToWriter
    );
  }
  f = message.getPaSport();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPaType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExefeedId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getCompetitionShortName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFlagCode();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 competition_id = 2;
 * @return {number}
 */
proto.common.UserSlipEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.common.UserSlipEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.UserSlipEvent.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.UserSlipEvent} returns this
*/
proto.common.UserSlipEvent.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.UserSlipEvent.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_live = 6;
 * @return {boolean}
 */
proto.common.UserSlipEvent.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_suspicious = 7;
 * @return {boolean}
 */
proto.common.UserSlipEvent.prototype.getIsSuspicious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setIsSuspicious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_single_approval = 8;
 * @return {boolean}
 */
proto.common.UserSlipEvent.prototype.getIsSingleApproval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setIsSingleApproval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string landbase_code = 10;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getLandbaseCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string note = 11;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional EventResult result = 12;
 * @return {?proto.common.EventResult}
 */
proto.common.UserSlipEvent.prototype.getResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.common.EventResult, 12));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.common.UserSlipEvent} returns this
*/
proto.common.UserSlipEvent.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.UserSlipEvent.prototype.hasResult = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional EventResult place_bet_result = 13;
 * @return {?proto.common.EventResult}
 */
proto.common.UserSlipEvent.prototype.getPlaceBetResult = function() {
  return /** @type{?proto.common.EventResult} */ (
    jspb.Message.getWrapperField(this, proto.common.EventResult, 13));
};


/**
 * @param {?proto.common.EventResult|undefined} value
 * @return {!proto.common.UserSlipEvent} returns this
*/
proto.common.UserSlipEvent.prototype.setPlaceBetResult = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.clearPlaceBetResult = function() {
  return this.setPlaceBetResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.UserSlipEvent.prototype.hasPlaceBetResult = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 pa_sport = 14;
 * @return {number}
 */
proto.common.UserSlipEvent.prototype.getPaSport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setPaSport = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string pa_type = 15;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getPaType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setPaType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 exefeed_id = 16;
 * @return {number}
 */
proto.common.UserSlipEvent.prototype.getExefeedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setExefeedId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 location_id = 17;
 * @return {number}
 */
proto.common.UserSlipEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string competition_short_name = 18;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getCompetitionShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setCompetitionShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string flag_code = 19;
 * @return {string}
 */
proto.common.UserSlipEvent.prototype.getFlagCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipEvent} returns this
 */
proto.common.UserSlipEvent.prototype.setFlagCode = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.UserSlipSubGroup.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.UserSlipSubGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.common.UserSlipSubGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.UserSlipSubGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipSubGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 15, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    event: (f = msg.getEvent()) && proto.common.UserSlipEvent.toObject(includeInstance, f),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.common.UserSlipOdd.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.UserSlipSubGroup}
 */
proto.common.UserSlipSubGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.UserSlipSubGroup;
  return proto.common.UserSlipSubGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.UserSlipSubGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.UserSlipSubGroup}
 */
proto.common.UserSlipSubGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 16:
      var value = new proto.common.UserSlipEvent;
      reader.readMessage(value,proto.common.UserSlipEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 19:
      var value = new proto.common.UserSlipOdd;
      reader.readMessage(value,proto.common.UserSlipOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.UserSlipSubGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.UserSlipSubGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.UserSlipSubGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipSubGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.common.UserSlipEvent.serializeBinaryToWriter
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.common.UserSlipOdd.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 15;
 * @return {string}
 */
proto.common.UserSlipSubGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipSubGroup} returns this
 */
proto.common.UserSlipSubGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.common.UserSlipSubGroup.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipSubGroup} returns this
 */
proto.common.UserSlipSubGroup.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional UserSlipEvent event = 16;
 * @return {?proto.common.UserSlipEvent}
 */
proto.common.UserSlipSubGroup.prototype.getEvent = function() {
  return /** @type{?proto.common.UserSlipEvent} */ (
    jspb.Message.getWrapperField(this, proto.common.UserSlipEvent, 16));
};


/**
 * @param {?proto.common.UserSlipEvent|undefined} value
 * @return {!proto.common.UserSlipSubGroup} returns this
*/
proto.common.UserSlipSubGroup.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.UserSlipSubGroup} returns this
 */
proto.common.UserSlipSubGroup.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.UserSlipSubGroup.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated UserSlipOdd odds = 19;
 * @return {!Array<!proto.common.UserSlipOdd>}
 */
proto.common.UserSlipSubGroup.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.common.UserSlipOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.UserSlipOdd, 19));
};


/**
 * @param {!Array<!proto.common.UserSlipOdd>} value
 * @return {!proto.common.UserSlipSubGroup} returns this
*/
proto.common.UserSlipSubGroup.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.common.UserSlipOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.UserSlipOdd}
 */
proto.common.UserSlipSubGroup.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.common.UserSlipOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.UserSlipSubGroup} returns this
 */
proto.common.UserSlipSubGroup.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.UserSlipGroup.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.UserSlipGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.common.UserSlipGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.UserSlipGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 20, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subGroupsList: jspb.Message.toObjectList(msg.getSubGroupsList(),
    proto.common.UserSlipSubGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.UserSlipGroup}
 */
proto.common.UserSlipGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.UserSlipGroup;
  return proto.common.UserSlipGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.UserSlipGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.UserSlipGroup}
 */
proto.common.UserSlipGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 21:
      var value = new proto.common.UserSlipSubGroup;
      reader.readMessage(value,proto.common.UserSlipSubGroup.deserializeBinaryFromReader);
      msg.addSubGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.UserSlipGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.UserSlipGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.UserSlipGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserSlipGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.common.UserSlipSubGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 20;
 * @return {string}
 */
proto.common.UserSlipGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipGroup} returns this
 */
proto.common.UserSlipGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.common.UserSlipGroup.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.UserSlipGroup} returns this
 */
proto.common.UserSlipGroup.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated UserSlipSubGroup sub_groups = 21;
 * @return {!Array<!proto.common.UserSlipSubGroup>}
 */
proto.common.UserSlipGroup.prototype.getSubGroupsList = function() {
  return /** @type{!Array<!proto.common.UserSlipSubGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.UserSlipSubGroup, 21));
};


/**
 * @param {!Array<!proto.common.UserSlipSubGroup>} value
 * @return {!proto.common.UserSlipGroup} returns this
*/
proto.common.UserSlipGroup.prototype.setSubGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.common.UserSlipSubGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.UserSlipSubGroup}
 */
proto.common.UserSlipGroup.prototype.addSubGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.common.UserSlipSubGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.UserSlipGroup} returns this
 */
proto.common.UserSlipGroup.prototype.clearSubGroupsList = function() {
  return this.setSubGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Slip.repeatedFields_ = [28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Slip.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Slip.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Slip} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Slip.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 22, ""),
    shortUuid: jspb.Message.getFieldWithDefault(msg, 77, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 57, 0),
    bettingPlaceName: jspb.Message.getFieldWithDefault(msg, 70, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 52, 0),
    userFirstName: jspb.Message.getFieldWithDefault(msg, 71, ""),
    userLastName: jspb.Message.getFieldWithDefault(msg, 72, ""),
    userUsername: jspb.Message.getFieldWithDefault(msg, 303, ""),
    bettingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    oldBettingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 85, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bettingTime: (f = msg.getBettingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accountType: jspb.Message.getFieldWithDefault(msg, 27, ""),
    slipGroupsList: jspb.Message.toObjectList(msg.getSlipGroupsList(),
    proto.common.UserSlipGroup.toObject, includeInstance),
    bettingAmountPerCombination: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    numberOfCombinations: jspb.Message.getFieldWithDefault(msg, 47, 0),
    maxWinningTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 112, 0.0),
    minWinningTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 113, 0.0),
    maxWinningAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    minWinningAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    maxBonusAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    minBonusAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    bonusPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 103, 0.0),
    maxOddsValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    minOddsValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    isSystem: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    slipType: jspb.Message.getFieldWithDefault(msg, 50, ""),
    error: (f = msg.getError()) && proto.common.Error.toObject(includeInstance, f),
    actualWinningAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    actualWinningNoBonus: jspb.Message.getFloatingPointFieldWithDefault(msg, 301, 0.0),
    hasCashout: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
    cashout: jspb.Message.getBooleanFieldWithDefault(msg, 73, false),
    cashoutEstimate: jspb.Message.getFloatingPointFieldWithDefault(msg, 121, 0.0),
    cashback: jspb.Message.getBooleanFieldWithDefault(msg, 74, false),
    hasStartedEvents: jspb.Message.getBooleanFieldWithDefault(msg, 75, false),
    approvalReason: jspb.Message.getFieldWithDefault(msg, 79, ""),
    approvedBy: jspb.Message.getFieldWithDefault(msg, 88, 0),
    approvedByName: jspb.Message.getFieldWithDefault(msg, 126, ""),
    numberOfMatches: jspb.Message.getFieldWithDefault(msg, 99, 0),
    creditee: jspb.Message.getFieldWithDefault(msg, 101, ""),
    creditNote: jspb.Message.getFieldWithDefault(msg, 102, ""),
    canceledBy: jspb.Message.getFieldWithDefault(msg, 114, ""),
    canceledByName: jspb.Message.getFieldWithDefault(msg, 127, ""),
    cancelTime: (f = msg.getCancelTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paidBy: jspb.Message.getFieldWithDefault(msg, 116, ""),
    paidByName: jspb.Message.getFieldWithDefault(msg, 128, ""),
    paidBettingPlaceName: jspb.Message.getFieldWithDefault(msg, 117, ""),
    paidTime: (f = msg.getPaidTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    resolvedTime: (f = msg.getResolvedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    walletPrefix: jspb.Message.getFieldWithDefault(msg, 130, ""),
    manualChangeTime: (f = msg.getManualChangeTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isPrinted: jspb.Message.getBooleanFieldWithDefault(msg, 302, false),
    cashoutPaidOut: jspb.Message.getBooleanFieldWithDefault(msg, 304, false),
    roleId: jspb.Message.getFieldWithDefault(msg, 444, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Slip}
 */
proto.common.Slip.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Slip;
  return proto.common.Slip.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Slip} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Slip}
 */
proto.common.Slip.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortUuid(value);
      break;
    case 104:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setBettingPlaceName(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFirstName(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserLastName(value);
      break;
    case 303:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserUsername(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBettingAmount(value);
      break;
    case 85:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOldBettingAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 26:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBettingTime(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 28:
      var value = new proto.common.UserSlipGroup;
      reader.readMessage(value,proto.common.UserSlipGroup.deserializeBinaryFromReader);
      msg.addSlipGroups(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBettingAmountPerCombination(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfCombinations(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxWinningTotal(value);
      break;
    case 113:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinWinningTotal(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxWinningAmount(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinWinningAmount(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxBonusAmount(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinBonusAmount(value);
      break;
    case 103:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBonusPercent(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxOddsValue(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinOddsValue(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSystem(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipType(value);
      break;
    case 1:
      var value = new proto.common.Error;
      reader.readMessage(value,proto.common.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualWinningAmount(value);
      break;
    case 301:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualWinningNoBonus(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCashout(value);
      break;
    case 73:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCashout(value);
      break;
    case 121:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCashoutEstimate(value);
      break;
    case 74:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCashback(value);
      break;
    case 75:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStartedEvents(value);
      break;
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovalReason(value);
      break;
    case 88:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovedBy(value);
      break;
    case 126:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedByName(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfMatches(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditee(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditNote(value);
      break;
    case 114:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanceledBy(value);
      break;
    case 127:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanceledByName(value);
      break;
    case 115:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCancelTime(value);
      break;
    case 116:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidBy(value);
      break;
    case 128:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidByName(value);
      break;
    case 117:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidBettingPlaceName(value);
      break;
    case 118:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaidTime(value);
      break;
    case 129:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResolvedTime(value);
      break;
    case 130:
      var value = /** @type {string} */ (reader.readString());
      msg.setWalletPrefix(value);
      break;
    case 300:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setManualChangeTime(value);
      break;
    case 302:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrinted(value);
      break;
    case 304:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCashoutPaidOut(value);
      break;
    case 444:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Slip.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Slip.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Slip} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Slip.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getShortUuid();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      57,
      f
    );
  }
  f = message.getBettingPlaceName();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      52,
      f
    );
  }
  f = message.getUserFirstName();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getUserLastName();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getUserUsername();
  if (f.length > 0) {
    writer.writeString(
      303,
      f
    );
  }
  f = message.getBettingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getOldBettingAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      85,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBettingTime();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getSlipGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.common.UserSlipGroup.serializeBinaryToWriter
    );
  }
  f = message.getBettingAmountPerCombination();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getNumberOfCombinations();
  if (f !== 0) {
    writer.writeInt32(
      47,
      f
    );
  }
  f = message.getMaxWinningTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      112,
      f
    );
  }
  f = message.getMinWinningTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      113,
      f
    );
  }
  f = message.getMaxWinningAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getMinWinningAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getMaxBonusAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getMinBonusAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      44,
      f
    );
  }
  f = message.getBonusPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      103,
      f
    );
  }
  f = message.getMaxOddsValue();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getMinOddsValue();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getIsSystem();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getSlipType();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Error.serializeBinaryToWriter
    );
  }
  f = message.getActualWinningAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      40,
      f
    );
  }
  f = message.getActualWinningNoBonus();
  if (f !== 0.0) {
    writer.writeDouble(
      301,
      f
    );
  }
  f = message.getHasCashout();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getCashout();
  if (f) {
    writer.writeBool(
      73,
      f
    );
  }
  f = message.getCashoutEstimate();
  if (f !== 0.0) {
    writer.writeDouble(
      121,
      f
    );
  }
  f = message.getCashback();
  if (f) {
    writer.writeBool(
      74,
      f
    );
  }
  f = message.getHasStartedEvents();
  if (f) {
    writer.writeBool(
      75,
      f
    );
  }
  f = message.getApprovalReason();
  if (f.length > 0) {
    writer.writeString(
      79,
      f
    );
  }
  f = message.getApprovedBy();
  if (f !== 0) {
    writer.writeInt32(
      88,
      f
    );
  }
  f = message.getApprovedByName();
  if (f.length > 0) {
    writer.writeString(
      126,
      f
    );
  }
  f = message.getNumberOfMatches();
  if (f !== 0) {
    writer.writeInt32(
      99,
      f
    );
  }
  f = message.getCreditee();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getCreditNote();
  if (f.length > 0) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getCanceledBy();
  if (f.length > 0) {
    writer.writeString(
      114,
      f
    );
  }
  f = message.getCanceledByName();
  if (f.length > 0) {
    writer.writeString(
      127,
      f
    );
  }
  f = message.getCancelTime();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaidBy();
  if (f.length > 0) {
    writer.writeString(
      116,
      f
    );
  }
  f = message.getPaidByName();
  if (f.length > 0) {
    writer.writeString(
      128,
      f
    );
  }
  f = message.getPaidBettingPlaceName();
  if (f.length > 0) {
    writer.writeString(
      117,
      f
    );
  }
  f = message.getPaidTime();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResolvedTime();
  if (f != null) {
    writer.writeMessage(
      129,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWalletPrefix();
  if (f.length > 0) {
    writer.writeString(
      130,
      f
    );
  }
  f = message.getManualChangeTime();
  if (f != null) {
    writer.writeMessage(
      300,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsPrinted();
  if (f) {
    writer.writeBool(
      302,
      f
    );
  }
  f = message.getCashoutPaidOut();
  if (f) {
    writer.writeBool(
      304,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeInt32(
      444,
      f
    );
  }
};


/**
 * optional string id = 22;
 * @return {string}
 */
proto.common.Slip.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string short_uuid = 77;
 * @return {string}
 */
proto.common.Slip.prototype.getShortUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setShortUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 104;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Slip.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 104));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 104, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional int32 betting_place_id = 57;
 * @return {number}
 */
proto.common.Slip.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 57, value);
};


/**
 * optional string betting_place_name = 70;
 * @return {string}
 */
proto.common.Slip.prototype.getBettingPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setBettingPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional int32 user_id = 52;
 * @return {number}
 */
proto.common.Slip.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 52, value);
};


/**
 * optional string user_first_name = 71;
 * @return {string}
 */
proto.common.Slip.prototype.getUserFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setUserFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string user_last_name = 72;
 * @return {string}
 */
proto.common.Slip.prototype.getUserLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setUserLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string user_username = 303;
 * @return {string}
 */
proto.common.Slip.prototype.getUserUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 303, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setUserUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 303, value);
};


/**
 * optional double betting_amount = 23;
 * @return {number}
 */
proto.common.Slip.prototype.getBettingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setBettingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double old_betting_amount = 85;
 * @return {number}
 */
proto.common.Slip.prototype.getOldBettingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 85, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setOldBettingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 85, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.common.Slip.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp betting_time = 26;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Slip.prototype.getBettingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 26));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setBettingTime = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearBettingTime = function() {
  return this.setBettingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasBettingTime = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string account_type = 27;
 * @return {string}
 */
proto.common.Slip.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * repeated UserSlipGroup slip_groups = 28;
 * @return {!Array<!proto.common.UserSlipGroup>}
 */
proto.common.Slip.prototype.getSlipGroupsList = function() {
  return /** @type{!Array<!proto.common.UserSlipGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.UserSlipGroup, 28));
};


/**
 * @param {!Array<!proto.common.UserSlipGroup>} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setSlipGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.common.UserSlipGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.UserSlipGroup}
 */
proto.common.Slip.prototype.addSlipGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.common.UserSlipGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearSlipGroupsList = function() {
  return this.setSlipGroupsList([]);
};


/**
 * optional double betting_amount_per_combination = 29;
 * @return {number}
 */
proto.common.Slip.prototype.getBettingAmountPerCombination = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setBettingAmountPerCombination = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional int32 number_of_combinations = 47;
 * @return {number}
 */
proto.common.Slip.prototype.getNumberOfCombinations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setNumberOfCombinations = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional double max_winning_total = 112;
 * @return {number}
 */
proto.common.Slip.prototype.getMaxWinningTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 112, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMaxWinningTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 112, value);
};


/**
 * optional double min_winning_total = 113;
 * @return {number}
 */
proto.common.Slip.prototype.getMinWinningTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 113, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMinWinningTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 113, value);
};


/**
 * optional double max_winning_amount = 24;
 * @return {number}
 */
proto.common.Slip.prototype.getMaxWinningAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMaxWinningAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double min_winning_amount = 30;
 * @return {number}
 */
proto.common.Slip.prototype.getMinWinningAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMinWinningAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double max_bonus_amount = 31;
 * @return {number}
 */
proto.common.Slip.prototype.getMaxBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMaxBonusAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double min_bonus_amount = 44;
 * @return {number}
 */
proto.common.Slip.prototype.getMinBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMinBonusAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 44, value);
};


/**
 * optional double bonus_percent = 103;
 * @return {number}
 */
proto.common.Slip.prototype.getBonusPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 103, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setBonusPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 103, value);
};


/**
 * optional double max_odds_value = 48;
 * @return {number}
 */
proto.common.Slip.prototype.getMaxOddsValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMaxOddsValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional double min_odds_value = 49;
 * @return {number}
 */
proto.common.Slip.prototype.getMinOddsValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setMinOddsValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional bool is_system = 39;
 * @return {boolean}
 */
proto.common.Slip.prototype.getIsSystem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setIsSystem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional string slip_type = 50;
 * @return {string}
 */
proto.common.Slip.prototype.getSlipType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setSlipType = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional Error error = 1;
 * @return {?proto.common.Error}
 */
proto.common.Slip.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Error, 1));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double actual_winning_amount = 40;
 * @return {number}
 */
proto.common.Slip.prototype.getActualWinningAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setActualWinningAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional double actual_winning_no_bonus = 301;
 * @return {number}
 */
proto.common.Slip.prototype.getActualWinningNoBonus = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 301, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setActualWinningNoBonus = function(value) {
  return jspb.Message.setProto3FloatField(this, 301, value);
};


/**
 * optional bool has_cashout = 56;
 * @return {boolean}
 */
proto.common.Slip.prototype.getHasCashout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 56, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setHasCashout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional bool cashout = 73;
 * @return {boolean}
 */
proto.common.Slip.prototype.getCashout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 73, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCashout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 73, value);
};


/**
 * optional double cashout_estimate = 121;
 * @return {number}
 */
proto.common.Slip.prototype.getCashoutEstimate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 121, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCashoutEstimate = function(value) {
  return jspb.Message.setProto3FloatField(this, 121, value);
};


/**
 * optional bool cashback = 74;
 * @return {boolean}
 */
proto.common.Slip.prototype.getCashback = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 74, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCashback = function(value) {
  return jspb.Message.setProto3BooleanField(this, 74, value);
};


/**
 * optional bool has_started_events = 75;
 * @return {boolean}
 */
proto.common.Slip.prototype.getHasStartedEvents = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 75, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setHasStartedEvents = function(value) {
  return jspb.Message.setProto3BooleanField(this, 75, value);
};


/**
 * optional string approval_reason = 79;
 * @return {string}
 */
proto.common.Slip.prototype.getApprovalReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setApprovalReason = function(value) {
  return jspb.Message.setProto3StringField(this, 79, value);
};


/**
 * optional int32 approved_by = 88;
 * @return {number}
 */
proto.common.Slip.prototype.getApprovedBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 88, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setApprovedBy = function(value) {
  return jspb.Message.setProto3IntField(this, 88, value);
};


/**
 * optional string approved_by_name = 126;
 * @return {string}
 */
proto.common.Slip.prototype.getApprovedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 126, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setApprovedByName = function(value) {
  return jspb.Message.setProto3StringField(this, 126, value);
};


/**
 * optional int32 number_of_matches = 99;
 * @return {number}
 */
proto.common.Slip.prototype.getNumberOfMatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setNumberOfMatches = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional string creditee = 101;
 * @return {string}
 */
proto.common.Slip.prototype.getCreditee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCreditee = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string credit_note = 102;
 * @return {string}
 */
proto.common.Slip.prototype.getCreditNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCreditNote = function(value) {
  return jspb.Message.setProto3StringField(this, 102, value);
};


/**
 * optional string canceled_by = 114;
 * @return {string}
 */
proto.common.Slip.prototype.getCanceledBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 114, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCanceledBy = function(value) {
  return jspb.Message.setProto3StringField(this, 114, value);
};


/**
 * optional string canceled_by_name = 127;
 * @return {string}
 */
proto.common.Slip.prototype.getCanceledByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 127, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCanceledByName = function(value) {
  return jspb.Message.setProto3StringField(this, 127, value);
};


/**
 * optional google.protobuf.Timestamp cancel_time = 115;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Slip.prototype.getCancelTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 115));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setCancelTime = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearCancelTime = function() {
  return this.setCancelTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasCancelTime = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional string paid_by = 116;
 * @return {string}
 */
proto.common.Slip.prototype.getPaidBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 116, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setPaidBy = function(value) {
  return jspb.Message.setProto3StringField(this, 116, value);
};


/**
 * optional string paid_by_name = 128;
 * @return {string}
 */
proto.common.Slip.prototype.getPaidByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 128, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setPaidByName = function(value) {
  return jspb.Message.setProto3StringField(this, 128, value);
};


/**
 * optional string paid_betting_place_name = 117;
 * @return {string}
 */
proto.common.Slip.prototype.getPaidBettingPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 117, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setPaidBettingPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 117, value);
};


/**
 * optional google.protobuf.Timestamp paid_time = 118;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Slip.prototype.getPaidTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 118));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setPaidTime = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearPaidTime = function() {
  return this.setPaidTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasPaidTime = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional google.protobuf.Timestamp resolved_time = 129;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Slip.prototype.getResolvedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 129));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setResolvedTime = function(value) {
  return jspb.Message.setWrapperField(this, 129, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearResolvedTime = function() {
  return this.setResolvedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasResolvedTime = function() {
  return jspb.Message.getField(this, 129) != null;
};


/**
 * optional string wallet_prefix = 130;
 * @return {string}
 */
proto.common.Slip.prototype.getWalletPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 130, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setWalletPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 130, value);
};


/**
 * optional google.protobuf.Timestamp manual_change_time = 300;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.common.Slip.prototype.getManualChangeTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 300));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.common.Slip} returns this
*/
proto.common.Slip.prototype.setManualChangeTime = function(value) {
  return jspb.Message.setWrapperField(this, 300, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.clearManualChangeTime = function() {
  return this.setManualChangeTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Slip.prototype.hasManualChangeTime = function() {
  return jspb.Message.getField(this, 300) != null;
};


/**
 * optional bool is_printed = 302;
 * @return {boolean}
 */
proto.common.Slip.prototype.getIsPrinted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 302, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setIsPrinted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 302, value);
};


/**
 * optional bool cashout_paid_out = 304;
 * @return {boolean}
 */
proto.common.Slip.prototype.getCashoutPaidOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 304, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setCashoutPaidOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 304, value);
};


/**
 * optional int32 role_id = 444;
 * @return {number}
 */
proto.common.Slip.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 444, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Slip} returns this
 */
proto.common.Slip.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 444, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EventTime.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EventTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EventTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    m: jspb.Message.getFieldWithDefault(msg, 202, ""),
    s: jspb.Message.getFieldWithDefault(msg, 203, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EventTime}
 */
proto.common.EventTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EventTime;
  return proto.common.EventTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EventTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EventTime}
 */
proto.common.EventTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 202:
      var value = /** @type {string} */ (reader.readString());
      msg.setM(value);
      break;
    case 203:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EventTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EventTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EventTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getM();
  if (f.length > 0) {
    writer.writeString(
      202,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      203,
      f
    );
  }
};


/**
 * optional string M = 202;
 * @return {string}
 */
proto.common.EventTime.prototype.getM = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 202, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventTime} returns this
 */
proto.common.EventTime.prototype.setM = function(value) {
  return jspb.Message.setProto3StringField(this, 202, value);
};


/**
 * optional string S = 203;
 * @return {string}
 */
proto.common.EventTime.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 203, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventTime} returns this
 */
proto.common.EventTime.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 203, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EventCurrentPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EventCurrentPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EventCurrentPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventCurrentPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    n: jspb.Message.getFieldWithDefault(msg, 200, ""),
    s: jspb.Message.getFieldWithDefault(msg, 201, ""),
    t: (f = msg.getT()) && proto.common.EventTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EventCurrentPhase}
 */
proto.common.EventCurrentPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EventCurrentPhase;
  return proto.common.EventCurrentPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EventCurrentPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EventCurrentPhase}
 */
proto.common.EventCurrentPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 200:
      var value = /** @type {string} */ (reader.readString());
      msg.setN(value);
      break;
    case 201:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    case 204:
      var value = new proto.common.EventTime;
      reader.readMessage(value,proto.common.EventTime.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EventCurrentPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EventCurrentPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EventCurrentPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventCurrentPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getN();
  if (f.length > 0) {
    writer.writeString(
      200,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      201,
      f
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      204,
      f,
      proto.common.EventTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string N = 200;
 * @return {string}
 */
proto.common.EventCurrentPhase.prototype.getN = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 200, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentPhase} returns this
 */
proto.common.EventCurrentPhase.prototype.setN = function(value) {
  return jspb.Message.setProto3StringField(this, 200, value);
};


/**
 * optional string S = 201;
 * @return {string}
 */
proto.common.EventCurrentPhase.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 201, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentPhase} returns this
 */
proto.common.EventCurrentPhase.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 201, value);
};


/**
 * optional EventTime T = 204;
 * @return {?proto.common.EventTime}
 */
proto.common.EventCurrentPhase.prototype.getT = function() {
  return /** @type{?proto.common.EventTime} */ (
    jspb.Message.getWrapperField(this, proto.common.EventTime, 204));
};


/**
 * @param {?proto.common.EventTime|undefined} value
 * @return {!proto.common.EventCurrentPhase} returns this
*/
proto.common.EventCurrentPhase.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 204, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EventCurrentPhase} returns this
 */
proto.common.EventCurrentPhase.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EventCurrentPhase.prototype.hasT = function() {
  return jspb.Message.getField(this, 204) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EventCurrentResult.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EventCurrentResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EventCurrentResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventCurrentResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    g: jspb.Message.getFieldWithDefault(msg, 205, ""),
    c: jspb.Message.getFieldWithDefault(msg, 206, ""),
    rc: jspb.Message.getFieldWithDefault(msg, 207, ""),
    yc: jspb.Message.getFieldWithDefault(msg, 208, ""),
    p: jspb.Message.getFieldWithDefault(msg, 209, ""),
    s: jspb.Message.getFieldWithDefault(msg, 210, ""),
    l: jspb.Message.getFieldWithDefault(msg, 267, ""),
    s180: jspb.Message.getFieldWithDefault(msg, 268, ""),
    fg: jspb.Message.getFieldWithDefault(msg, 269, ""),
    pn: jspb.Message.getFieldWithDefault(msg, 270, ""),
    td: jspb.Message.getFieldWithDefault(msg, 271, ""),
    sc: jspb.Message.getFieldWithDefault(msg, 272, ""),
    sh: jspb.Message.getFieldWithDefault(msg, 273, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EventCurrentResult}
 */
proto.common.EventCurrentResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EventCurrentResult;
  return proto.common.EventCurrentResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EventCurrentResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EventCurrentResult}
 */
proto.common.EventCurrentResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 205:
      var value = /** @type {string} */ (reader.readString());
      msg.setG(value);
      break;
    case 206:
      var value = /** @type {string} */ (reader.readString());
      msg.setC(value);
      break;
    case 207:
      var value = /** @type {string} */ (reader.readString());
      msg.setRc(value);
      break;
    case 208:
      var value = /** @type {string} */ (reader.readString());
      msg.setYc(value);
      break;
    case 209:
      var value = /** @type {string} */ (reader.readString());
      msg.setP(value);
      break;
    case 210:
      var value = /** @type {string} */ (reader.readString());
      msg.setS(value);
      break;
    case 267:
      var value = /** @type {string} */ (reader.readString());
      msg.setL(value);
      break;
    case 268:
      var value = /** @type {string} */ (reader.readString());
      msg.setS180(value);
      break;
    case 269:
      var value = /** @type {string} */ (reader.readString());
      msg.setFg(value);
      break;
    case 270:
      var value = /** @type {string} */ (reader.readString());
      msg.setPn(value);
      break;
    case 271:
      var value = /** @type {string} */ (reader.readString());
      msg.setTd(value);
      break;
    case 272:
      var value = /** @type {string} */ (reader.readString());
      msg.setSc(value);
      break;
    case 273:
      var value = /** @type {string} */ (reader.readString());
      msg.setSh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EventCurrentResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EventCurrentResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EventCurrentResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventCurrentResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getG();
  if (f.length > 0) {
    writer.writeString(
      205,
      f
    );
  }
  f = message.getC();
  if (f.length > 0) {
    writer.writeString(
      206,
      f
    );
  }
  f = message.getRc();
  if (f.length > 0) {
    writer.writeString(
      207,
      f
    );
  }
  f = message.getYc();
  if (f.length > 0) {
    writer.writeString(
      208,
      f
    );
  }
  f = message.getP();
  if (f.length > 0) {
    writer.writeString(
      209,
      f
    );
  }
  f = message.getS();
  if (f.length > 0) {
    writer.writeString(
      210,
      f
    );
  }
  f = message.getL();
  if (f.length > 0) {
    writer.writeString(
      267,
      f
    );
  }
  f = message.getS180();
  if (f.length > 0) {
    writer.writeString(
      268,
      f
    );
  }
  f = message.getFg();
  if (f.length > 0) {
    writer.writeString(
      269,
      f
    );
  }
  f = message.getPn();
  if (f.length > 0) {
    writer.writeString(
      270,
      f
    );
  }
  f = message.getTd();
  if (f.length > 0) {
    writer.writeString(
      271,
      f
    );
  }
  f = message.getSc();
  if (f.length > 0) {
    writer.writeString(
      272,
      f
    );
  }
  f = message.getSh();
  if (f.length > 0) {
    writer.writeString(
      273,
      f
    );
  }
};


/**
 * optional string G = 205;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getG = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 205, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setG = function(value) {
  return jspb.Message.setProto3StringField(this, 205, value);
};


/**
 * optional string C = 206;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getC = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 206, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setC = function(value) {
  return jspb.Message.setProto3StringField(this, 206, value);
};


/**
 * optional string RC = 207;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getRc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 207, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setRc = function(value) {
  return jspb.Message.setProto3StringField(this, 207, value);
};


/**
 * optional string YC = 208;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getYc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 208, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setYc = function(value) {
  return jspb.Message.setProto3StringField(this, 208, value);
};


/**
 * optional string P = 209;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getP = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 209, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setP = function(value) {
  return jspb.Message.setProto3StringField(this, 209, value);
};


/**
 * optional string S = 210;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getS = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 210, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setS = function(value) {
  return jspb.Message.setProto3StringField(this, 210, value);
};


/**
 * optional string L = 267;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getL = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 267, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setL = function(value) {
  return jspb.Message.setProto3StringField(this, 267, value);
};


/**
 * optional string S180 = 268;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getS180 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 268, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setS180 = function(value) {
  return jspb.Message.setProto3StringField(this, 268, value);
};


/**
 * optional string FG = 269;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getFg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 269, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setFg = function(value) {
  return jspb.Message.setProto3StringField(this, 269, value);
};


/**
 * optional string PN = 270;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getPn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 270, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setPn = function(value) {
  return jspb.Message.setProto3StringField(this, 270, value);
};


/**
 * optional string TD = 271;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getTd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 271, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setTd = function(value) {
  return jspb.Message.setProto3StringField(this, 271, value);
};


/**
 * optional string SC = 272;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getSc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 272, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setSc = function(value) {
  return jspb.Message.setProto3StringField(this, 272, value);
};


/**
 * optional string SH = 273;
 * @return {string}
 */
proto.common.EventCurrentResult.prototype.getSh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 273, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventCurrentResult} returns this
 */
proto.common.EventCurrentResult.prototype.setSh = function(value) {
  return jspb.Message.setProto3StringField(this, 273, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EventLiveResultHistoryElement.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EventLiveResultHistoryElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EventLiveResultHistoryElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventLiveResultHistoryElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    p: (f = msg.getP()) && proto.common.EventCurrentPhase.toObject(includeInstance, f),
    r: (f = msg.getR()) && proto.common.EventCurrentResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EventLiveResultHistoryElement}
 */
proto.common.EventLiveResultHistoryElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EventLiveResultHistoryElement;
  return proto.common.EventLiveResultHistoryElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EventLiveResultHistoryElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EventLiveResultHistoryElement}
 */
proto.common.EventLiveResultHistoryElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 211:
      var value = new proto.common.EventCurrentPhase;
      reader.readMessage(value,proto.common.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setP(value);
      break;
    case 212:
      var value = new proto.common.EventCurrentResult;
      reader.readMessage(value,proto.common.EventCurrentResult.deserializeBinaryFromReader);
      msg.setR(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EventLiveResultHistoryElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EventLiveResultHistoryElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EventLiveResultHistoryElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventLiveResultHistoryElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getP();
  if (f != null) {
    writer.writeMessage(
      211,
      f,
      proto.common.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getR();
  if (f != null) {
    writer.writeMessage(
      212,
      f,
      proto.common.EventCurrentResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCurrentPhase P = 211;
 * @return {?proto.common.EventCurrentPhase}
 */
proto.common.EventLiveResultHistoryElement.prototype.getP = function() {
  return /** @type{?proto.common.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.common.EventCurrentPhase, 211));
};


/**
 * @param {?proto.common.EventCurrentPhase|undefined} value
 * @return {!proto.common.EventLiveResultHistoryElement} returns this
*/
proto.common.EventLiveResultHistoryElement.prototype.setP = function(value) {
  return jspb.Message.setWrapperField(this, 211, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EventLiveResultHistoryElement} returns this
 */
proto.common.EventLiveResultHistoryElement.prototype.clearP = function() {
  return this.setP(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EventLiveResultHistoryElement.prototype.hasP = function() {
  return jspb.Message.getField(this, 211) != null;
};


/**
 * optional EventCurrentResult R = 212;
 * @return {?proto.common.EventCurrentResult}
 */
proto.common.EventLiveResultHistoryElement.prototype.getR = function() {
  return /** @type{?proto.common.EventCurrentResult} */ (
    jspb.Message.getWrapperField(this, proto.common.EventCurrentResult, 212));
};


/**
 * @param {?proto.common.EventCurrentResult|undefined} value
 * @return {!proto.common.EventLiveResultHistoryElement} returns this
*/
proto.common.EventLiveResultHistoryElement.prototype.setR = function(value) {
  return jspb.Message.setWrapperField(this, 212, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EventLiveResultHistoryElement} returns this
 */
proto.common.EventLiveResultHistoryElement.prototype.clearR = function() {
  return this.setR(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EventLiveResultHistoryElement.prototype.hasR = function() {
  return jspb.Message.getField(this, 212) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.EventResult.repeatedFields_ = [215];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EventResult.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EventResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EventResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPhase: (f = msg.getCurrentPhase()) && proto.common.EventCurrentPhase.toObject(includeInstance, f),
    currentResult: (f = msg.getCurrentResult()) && proto.common.EventCurrentResult.toObject(includeInstance, f),
    liveResultHistoryList: jspb.Message.toObjectList(msg.getLiveResultHistoryList(),
    proto.common.EventLiveResultHistoryElement.toObject, includeInstance),
    liveResultStats: jspb.Message.getFieldWithDefault(msg, 216, ""),
    prematchResult: jspb.Message.getFieldWithDefault(msg, 217, ""),
    liveResult: jspb.Message.getFieldWithDefault(msg, 218, ""),
    confirmed: jspb.Message.getBooleanFieldWithDefault(msg, 219, false),
    error: (f = msg.getError()) && proto.common.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EventResult}
 */
proto.common.EventResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EventResult;
  return proto.common.EventResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EventResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EventResult}
 */
proto.common.EventResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 213:
      var value = new proto.common.EventCurrentPhase;
      reader.readMessage(value,proto.common.EventCurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 214:
      var value = new proto.common.EventCurrentResult;
      reader.readMessage(value,proto.common.EventCurrentResult.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 215:
      var value = new proto.common.EventLiveResultHistoryElement;
      reader.readMessage(value,proto.common.EventLiveResultHistoryElement.deserializeBinaryFromReader);
      msg.addLiveResultHistory(value);
      break;
    case 216:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveResultStats(value);
      break;
    case 217:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchResult(value);
      break;
    case 218:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveResult(value);
      break;
    case 219:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    case 78:
      var value = new proto.common.Error;
      reader.readMessage(value,proto.common.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EventResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EventResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EventResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      213,
      f,
      proto.common.EventCurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      214,
      f,
      proto.common.EventCurrentResult.serializeBinaryToWriter
    );
  }
  f = message.getLiveResultHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      215,
      f,
      proto.common.EventLiveResultHistoryElement.serializeBinaryToWriter
    );
  }
  f = message.getLiveResultStats();
  if (f.length > 0) {
    writer.writeString(
      216,
      f
    );
  }
  f = message.getPrematchResult();
  if (f.length > 0) {
    writer.writeString(
      217,
      f
    );
  }
  f = message.getLiveResult();
  if (f.length > 0) {
    writer.writeString(
      218,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      219,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto.common.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCurrentPhase current_phase = 213;
 * @return {?proto.common.EventCurrentPhase}
 */
proto.common.EventResult.prototype.getCurrentPhase = function() {
  return /** @type{?proto.common.EventCurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.common.EventCurrentPhase, 213));
};


/**
 * @param {?proto.common.EventCurrentPhase|undefined} value
 * @return {!proto.common.EventResult} returns this
*/
proto.common.EventResult.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 213, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EventResult.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 213) != null;
};


/**
 * optional EventCurrentResult current_result = 214;
 * @return {?proto.common.EventCurrentResult}
 */
proto.common.EventResult.prototype.getCurrentResult = function() {
  return /** @type{?proto.common.EventCurrentResult} */ (
    jspb.Message.getWrapperField(this, proto.common.EventCurrentResult, 214));
};


/**
 * @param {?proto.common.EventCurrentResult|undefined} value
 * @return {!proto.common.EventResult} returns this
*/
proto.common.EventResult.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 214, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EventResult.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 214) != null;
};


/**
 * repeated EventLiveResultHistoryElement live_result_history = 215;
 * @return {!Array<!proto.common.EventLiveResultHistoryElement>}
 */
proto.common.EventResult.prototype.getLiveResultHistoryList = function() {
  return /** @type{!Array<!proto.common.EventLiveResultHistoryElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.EventLiveResultHistoryElement, 215));
};


/**
 * @param {!Array<!proto.common.EventLiveResultHistoryElement>} value
 * @return {!proto.common.EventResult} returns this
*/
proto.common.EventResult.prototype.setLiveResultHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 215, value);
};


/**
 * @param {!proto.common.EventLiveResultHistoryElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.EventLiveResultHistoryElement}
 */
proto.common.EventResult.prototype.addLiveResultHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 215, opt_value, proto.common.EventLiveResultHistoryElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.clearLiveResultHistoryList = function() {
  return this.setLiveResultHistoryList([]);
};


/**
 * optional string live_result_stats = 216;
 * @return {string}
 */
proto.common.EventResult.prototype.getLiveResultStats = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 216, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.setLiveResultStats = function(value) {
  return jspb.Message.setProto3StringField(this, 216, value);
};


/**
 * optional string prematch_result = 217;
 * @return {string}
 */
proto.common.EventResult.prototype.getPrematchResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 217, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.setPrematchResult = function(value) {
  return jspb.Message.setProto3StringField(this, 217, value);
};


/**
 * optional string live_result = 218;
 * @return {string}
 */
proto.common.EventResult.prototype.getLiveResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 218, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.setLiveResult = function(value) {
  return jspb.Message.setProto3StringField(this, 218, value);
};


/**
 * optional bool confirmed = 219;
 * @return {boolean}
 */
proto.common.EventResult.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 219, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.setConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 219, value);
};


/**
 * optional Error error = 78;
 * @return {?proto.common.Error}
 */
proto.common.EventResult.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Error, 78));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.common.EventResult} returns this
*/
proto.common.EventResult.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.EventResult} returns this
 */
proto.common.EventResult.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.EventResult.prototype.hasError = function() {
  return jspb.Message.getField(this, 78) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EventResultPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EventResultPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EventResultPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventResultPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    points: jspb.Message.getFieldWithDefault(msg, 141, ""),
    games: jspb.Message.getFieldWithDefault(msg, 142, ""),
    sets: jspb.Message.getFieldWithDefault(msg, 143, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EventResultPeriod}
 */
proto.common.EventResultPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EventResultPeriod;
  return proto.common.EventResultPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EventResultPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EventResultPeriod}
 */
proto.common.EventResultPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 141:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoints(value);
      break;
    case 142:
      var value = /** @type {string} */ (reader.readString());
      msg.setGames(value);
      break;
    case 143:
      var value = /** @type {string} */ (reader.readString());
      msg.setSets(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EventResultPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EventResultPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EventResultPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EventResultPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoints();
  if (f.length > 0) {
    writer.writeString(
      141,
      f
    );
  }
  f = message.getGames();
  if (f.length > 0) {
    writer.writeString(
      142,
      f
    );
  }
  f = message.getSets();
  if (f.length > 0) {
    writer.writeString(
      143,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string points = 141;
 * @return {string}
 */
proto.common.EventResultPeriod.prototype.getPoints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 141, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResultPeriod} returns this
 */
proto.common.EventResultPeriod.prototype.setPoints = function(value) {
  return jspb.Message.setProto3StringField(this, 141, value);
};


/**
 * optional string games = 142;
 * @return {string}
 */
proto.common.EventResultPeriod.prototype.getGames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 142, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResultPeriod} returns this
 */
proto.common.EventResultPeriod.prototype.setGames = function(value) {
  return jspb.Message.setProto3StringField(this, 142, value);
};


/**
 * optional string sets = 143;
 * @return {string}
 */
proto.common.EventResultPeriod.prototype.getSets = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 143, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResultPeriod} returns this
 */
proto.common.EventResultPeriod.prototype.setSets = function(value) {
  return jspb.Message.setProto3StringField(this, 143, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.common.EventResultPeriod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.EventResultPeriod} returns this
 */
proto.common.EventResultPeriod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FastOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FastOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FastOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    oddId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FastOdd}
 */
proto.common.FastOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FastOdd;
  return proto.common.FastOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FastOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FastOdd}
 */
proto.common.FastOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOddId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FastOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FastOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FastOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOddId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 odd_id = 1;
 * @return {number}
 */
proto.common.FastOdd.prototype.getOddId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.FastOdd} returns this
 */
proto.common.FastOdd.prototype.setOddId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.common.FastOdd.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FastOdd} returns this
 */
proto.common.FastOdd.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.FastSlipGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FastSlipGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FastSlipGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FastSlipGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastSlipGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subGroupsList: jspb.Message.toObjectList(msg.getSubGroupsList(),
    proto.common.FastSlipSubGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FastSlipGroup}
 */
proto.common.FastSlipGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FastSlipGroup;
  return proto.common.FastSlipGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FastSlipGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FastSlipGroup}
 */
proto.common.FastSlipGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.common.FastSlipSubGroup;
      reader.readMessage(value,proto.common.FastSlipSubGroup.deserializeBinaryFromReader);
      msg.addSubGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FastSlipGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FastSlipGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FastSlipGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastSlipGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.FastSlipSubGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.FastSlipGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FastSlipGroup} returns this
 */
proto.common.FastSlipGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FastSlipSubGroup sub_groups = 2;
 * @return {!Array<!proto.common.FastSlipSubGroup>}
 */
proto.common.FastSlipGroup.prototype.getSubGroupsList = function() {
  return /** @type{!Array<!proto.common.FastSlipSubGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.FastSlipSubGroup, 2));
};


/**
 * @param {!Array<!proto.common.FastSlipSubGroup>} value
 * @return {!proto.common.FastSlipGroup} returns this
*/
proto.common.FastSlipGroup.prototype.setSubGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.FastSlipSubGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FastSlipSubGroup}
 */
proto.common.FastSlipGroup.prototype.addSubGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.FastSlipSubGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.FastSlipGroup} returns this
 */
proto.common.FastSlipGroup.prototype.clearSubGroupsList = function() {
  return this.setSubGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.FastSlipSubGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FastSlipSubGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FastSlipSubGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FastSlipSubGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastSlipSubGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.common.FastOdd.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FastSlipSubGroup}
 */
proto.common.FastSlipSubGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FastSlipSubGroup;
  return proto.common.FastSlipSubGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FastSlipSubGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FastSlipSubGroup}
 */
proto.common.FastSlipSubGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.common.FastOdd;
      reader.readMessage(value,proto.common.FastOdd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FastSlipSubGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FastSlipSubGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FastSlipSubGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastSlipSubGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.FastOdd.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.FastSlipSubGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FastSlipSubGroup} returns this
 */
proto.common.FastSlipSubGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FastOdd odds = 2;
 * @return {!Array<!proto.common.FastOdd>}
 */
proto.common.FastSlipSubGroup.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.common.FastOdd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.FastOdd, 2));
};


/**
 * @param {!Array<!proto.common.FastOdd>} value
 * @return {!proto.common.FastSlipSubGroup} returns this
*/
proto.common.FastSlipSubGroup.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.FastOdd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FastOdd}
 */
proto.common.FastSlipSubGroup.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.FastOdd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.FastSlipSubGroup} returns this
 */
proto.common.FastSlipSubGroup.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.FastSlipRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FastSlipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FastSlipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FastSlipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastSlipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    slipGroupsList: jspb.Message.toObjectList(msg.getSlipGroupsList(),
    proto.common.FastSlipGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FastSlipRequest}
 */
proto.common.FastSlipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FastSlipRequest;
  return proto.common.FastSlipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FastSlipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FastSlipRequest}
 */
proto.common.FastSlipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = new proto.common.FastSlipGroup;
      reader.readMessage(value,proto.common.FastSlipGroup.deserializeBinaryFromReader);
      msg.addSlipGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FastSlipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FastSlipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FastSlipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FastSlipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSlipGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.FastSlipGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.common.FastSlipRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.FastSlipRequest} returns this
 */
proto.common.FastSlipRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * repeated FastSlipGroup slip_groups = 2;
 * @return {!Array<!proto.common.FastSlipGroup>}
 */
proto.common.FastSlipRequest.prototype.getSlipGroupsList = function() {
  return /** @type{!Array<!proto.common.FastSlipGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.FastSlipGroup, 2));
};


/**
 * @param {!Array<!proto.common.FastSlipGroup>} value
 * @return {!proto.common.FastSlipRequest} returns this
*/
proto.common.FastSlipRequest.prototype.setSlipGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.FastSlipGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FastSlipGroup}
 */
proto.common.FastSlipRequest.prototype.addSlipGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.FastSlipGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.FastSlipRequest} returns this
 */
proto.common.FastSlipRequest.prototype.clearSlipGroupsList = function() {
  return this.setSlipGroupsList([]);
};


goog.object.extend(exports, proto.common);
