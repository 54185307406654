/* React modules */
import { useMemo, useEffect, useState, useCallback } from 'react';

/* Our modules */
import { User } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import loaderStore from 'components/Loader/loader.store';
import { paymentStore } from 'modules/payment/payment.store';
import { Input, Button, Checkbox, Icon, Select, DatePicker } from 'components';
import ActivationSent from 'modules/auth/ui/ActivationSent';
import { SelectOption } from 'components/Select/Select';
import { LoaderWrapper } from 'components/Loader';
import { useCountryOptions } from 'libs/useCountryOptions';
import { montenegroCities } from 'libs/country-list';
import Validators from 'libs/validations';
import { formatDate, get18YearsAgo } from 'libs/datetime';
import { hasProperty, logger } from 'libs/common-helpers';
import './Register.scss';

/* 3rd Party modules */
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface RegisterForm extends User {
  emailConfirmation?: string;
  passwordConfirmation?: string;
  area_code?: string;
  // hasSsn: boolean;
  gender: any;
  country: any;
  city: any;
}

const fieldsByStep: { 1: string[]; 2: string[] } = {
  1: [
    'username',
    'first_name',
    'last_name',
    'email',
    'password',
    'passwordConfirmation',
    'promo_code',
    'terms_and_conditions',
  ],
  2: [
    'country',
    'city',
    'address',
    'gender',
    'date_of_birth',
    'phone_number',
    'ssn',
  ],
};

const getYear = (ssnYear: number) => {
  return ssnYear > 900 ? ssnYear + 1000 : ssnYear + 2000;
};

const formatFieldValue = (value: any, checkboxId?: string) => {
  if (value?.target?.type === 'checkbox') {
    if (checkboxId === 'male' || checkboxId === 'female') {
      return checkboxId;
    } else {
      return value.target.checked;
    }
  } else if (value?.target) {
    return value.target.value;
  } else if (Array.isArray(value) && value[0] instanceof Date) {
    return formatDate(value[0]);
  } else {
    return value;
  }
};

const formatPayload = (data: any): User => {
  const payload = { ...data };
  const [day, month, year] = data.date_of_birth.split('.');
  payload.date_of_birth = `${year}-${month}-${day}`;
  payload.gender = data.gender.value;
  payload.country = data.country.value;
  payload.city = data.country.value === 'MNE' ? data.city.value : data.city;

  if (data.country.value === 'MNE') {
    payload.city = data.city.value;
  } else {
    payload.city = data.city;

    payload.document_id = data.ssn;
    delete payload.ssn;
  }

  delete payload.passwordConfirmation;
  // delete payload.hasSsn;

  return payload;
};

interface RegisterProps {
  continueIdle: () => void;
}

const Register = observer(({ continueIdle }: RegisterProps) => {
  const { authStore, overlayStore } = useStores();

  const countryOptions = useCountryOptions();

  const [step, setStep] = useState<1 | 2>(1);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      continueIdle();
    };
  }, []);

  const maxDate = get18YearsAgo();

  const userInfo: RegisterForm = {
    username: '',
    last_name: '',
    first_name: '',
    email: '',
    emailConfirmation: '',
    password: '',
    passwordConfirmation: '',
    gender: 'male',
    date_of_birth: '',
    phone_number: '',
    country: '',
    ssn: '',
    // hasSsn: true,
    city: '',
    nickname: '',
    address: '',
    subscribed: false,
    subscribed_sms: true,
    subscribed_email: true,
    terms_and_conditions: false,
    promo_code: '',
  };

  const genderOptions: SelectOption[] = useMemo(
    () => [
      { value: 'male', label: t('userData.male') },
      { value: 'female', label: t('userData.female') },
    ],
    [t]
  );

  const getGenderOptions = useCallback(
    (ssn: string) => {
      const genderCode = ssn.substring(9, 12);
      return +genderCode < 500 ? genderOptions[0] : genderOptions[1];
    },
    [genderOptions]
  );

  const [formData, setFormData] = useState<RegisterForm>(userInfo);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [disableSsnFields, setDisableSsnFields] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [checkInProgress, setCheckInProgress] = useState<boolean>(false);

  const fieldValidations = {
    username: [Validators.required, Validators.noSpace],
    first_name: [Validators.required, Validators.onlyLetters],
    last_name: [Validators.required, Validators.onlyLetters],
    email: [Validators.required, Validators.email],
    gender: [Validators.required],
    phone_number: [Validators.required, Validators.onlyNumbers],
    date_of_birth: [Validators.required],
    password: [
      Validators.required,
      Validators.minLength(6, t('errors.min-password', { len: 6 })),
    ],
    passwordConfirmation: [
      Validators.required,
      Validators.match(t('errors.passwords-dont-match'), {
        value: formData.password,
        confirmValue: formData.passwordConfirmation,
      }),
    ],
    terms_and_conditions: [Validators.required],
    country: [Validators.required],
    city: [Validators.required],
    address: [Validators.required],
    ssn: [
      Validators.required,
      Validators.onlyNumbers,
      formData.country.value === 'MNE'
        ? Validators.minLength(13, t('errors.jmbg-min'))
        : null,
      formData.country.value === 'MNE'
        ? Validators.maxLength(13, t('errors.jmbg-max'))
        : null,
    ],
  };

  useEffect(() => {
    if (formData?.password !== '') {
      setFormData({ ...formData, password: formData.password });
      validateField('password')({ target: { value: formData.password } });
      if (formData.passwordConfirmation !== '') {
        validateField('passwordConfirmation')({
          target: { value: formData.passwordConfirmation },
        });
      }
    }
  }, [formData.password]);

  useEffect(() => {
    if (formData.passwordConfirmation !== '') {
      setFormData({
        ...formData,
        passwordConfirmation: formData.passwordConfirmation,
      });
      validateField('passwordConfirmation')({
        target: { value: formData.passwordConfirmation },
      });
    }
  }, [formData.passwordConfirmation]);

  const updateField = (key: string, checkboxId?: string) => (value: any) => {
    if (!isFormDirty) {
      /* More details can be found in 'Sportradar Tag Manager Tracking Funnel' document */
      // @ts-ignore
      srtmCommands.push({
        event: 'track.user.registration',
        payload: { action: 'start' },
      });

      setIsFormDirty(true);
    }

    if (value?.target?.value?.length > 32) return;
    const formattedFieldValue = formatFieldValue(value, checkboxId);
    setFormData({ ...formData, [key]: formattedFieldValue });
    validateField(key)({ target: { value: formattedFieldValue } });
  };

  const validateField = (key: string) => (e?: any) => {
    const validations = fieldValidations[key as keyof typeof fieldValidations];
    if (validations) {
      const error = (validations as Array<any>).find(
        (v: any) => v && !v.validate(e.target.value)
      );
      if (error) {
        setErrors({ ...errors, [key]: error.message });
      } else {
        setErrors({ ...errors, [key]: null });
      }
    }
  };

  const checkIfUserExists = async (
    key: 'username' | 'email',
    value: string,
    errorMessage: string
  ) => {
    const exists = await authStore.userExistsCheck({ [key]: value });

    if (exists) {
      setErrors({ ...errors, [key]: errorMessage });
    } else {
      setErrors({ ...errors, [key]: null });
    }
  };

  const validateUsername = async (e: any) => {
    validateField('username')(e);

    if (!errors.username && e.target.value) {
      checkIfUserExists(
        'username',
        e.target.value,
        t('errors.username-exists')
      );
    }
  };

  const validateEmail = (e: any) => {
    validateField('email')(e);

    if (!errors.email && e.target.value) {
      checkIfUserExists('email', e.target.value, t('errors.email-exists'));
    }
  };

  const validatePromoCode = async (e: any) => {
    if (e.target.value) {
      setCheckInProgress(true);

      try {
        await paymentStore.checkPromoCode(e.target.value);
        setErrors({ ...errors, promo_code: null });
      } catch (exception: any) {
        if (exception?.data?.detail === 'ERR_INVALID_CODE') {
          setErrors({ ...errors, promo_code: t('errors.promo-code-invalid') });
        }

        logger('Register -> validatePromoCode -> exception', exception);
      } finally {
        setCheckInProgress(false);
      }
    }
  };

  const populateDate = useCallback(
    (ssn: string) => {
      if (ssn.length === 13) {
        const day = +ssn.slice(0, 2);
        const month = +ssn.slice(2, 4) - 1;
        const year = getYear(+ssn.slice(4, 7));
        const dob = new Date(year, month, day);

        if (dob <= maxDate) {
          formData.date_of_birth = formatDate(dob);
        } else {
          setErrors({ ...errors, ssn: t('verification.validation-18-year') });
        }
      }
    },
    [formData]
  );

  const register = async () => {
    if (step === 1) {
      setStep(2);
      return;
    }

    loaderStore.addLoader('register-form');

    try {
      const { data } = await authStore.register(formatPayload(formData));

      const { user_id } = data || {};

      if (user_id) {
        /* More details can be found in 'Sportradar Tag Manager Tracking Funnel' document */
        // @ts-ignore
        srtmCommands.push({
          event: 'track.user.registration',
          payload: { action: 'complete', userId: user_id },
        });
      }

      overlayStore.closeModal();
      openActivationSentModal();

      if (window.innerWidth >= 1200 && pathname === '/') {
        navigate('/sport');
      }

      // this were used for user activation after registration, now we send email for acitvation
      // authStore.activateAccount(response.data, openActivateModal);
    } catch (exception: any) {
      // Currently 409 will be returned only when user exists.
      // In the future our backend should return some error status in details
      // indicating that user exists and we should handle error based on that status,
      // not based on http status code.
      if (exception.status === 409) {
        toast.error(t('errors.user-exists'));
      } else {
        toast.error(exception.data?.detail);
      }

      logger('Register -> register -> exception', exception);
    } finally {
      loaderStore.removeLoader('register-form');
    }
  };

  const openActivationSentModal = () => {
    overlayStore.openModal(<ActivationSent />, {
      width: 'medium',
    });
  };

  /* const openActivateModal = useCallback(() => {
    overlayStore.openModal(<ActivationSuccess />, {
      width: 'small',
      title: t('verification.account-activated'),
    });
  }, [overlayStore, t]); */

  useEffect(() => {
    let formValid = true;
    fieldsByStep[step].forEach((key) => {
      if (!hasProperty(errors, key) || errors[key]) {
        formValid = false;
      }
    });
    setIsValid(formValid);
  }, [errors, step]);

  useEffect(() => {
    if (formData.country.value === 'MNE' && formData.ssn && !errors.ssn) {
      formData.gender = getGenderOptions(formData.ssn);
      populateDate(formData.ssn);
      setDisableSsnFields(true);
      errors.gender = null;
      errors.date_of_birth = null;
    }
  }, [formData, getGenderOptions, errors, populateDate, setDisableSsnFields]);

  useEffect(() => {
    formData.ssn = '';
    if (formData.country.value !== 'MNE') {
      setDisableSsnFields(false);
      formData.gender = undefined;
      formData.date_of_birth = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.country.value]);

  useEffect(() => {
    if (!formData.promo_code) {
      setErrors({ ...errors, promo_code: null });
    }
  }, [formData.promo_code]);

  useEffect(() => {
    if (authStore.registerError) {
      toast.error(authStore.registerError);
    }
  }, [authStore.registerError]);

  const goBack = () => {
    setStep(1);
  };

  // @ts-ignore
  const CustomDatePickerInput = ({ ref }) => {
    return (
      <Input
        ref={ref}
        bg="grey"
        name="birth"
        disabled={disableSsnFields || formData.country.value === 'MNE'}
        placeholder={`${t('userData.date-of-birth')} *`}
        error={errors.date_of_birth}
        height="large"
        value={formData.date_of_birth}
        onChange={() =>
          validateField('date_of_birth')({
            target: { value: formData.date_of_birth },
          })
        }
        addon={
          <Icon
            onClick={() => updateField('date_of_birth')('')}
            size={formData.date_of_birth ? 'small' : 'large'}
            name={formData.date_of_birth ? 'cancel' : 'calendarFill'}
            style={{ cursor: 'pointer' }}
          />
        }
      />
    );
  };

  return (
    <>
      <div className="register-modal__header">
        {step === 2 && <Icon name="caretLeft" onClick={() => goBack()} />}

        <div className="register-modal__title">
          <h3 className="heading-lg">{t('globals.register')}</h3>
        </div>
      </div>
      <div className="register-modal__step">
        {`${t('globals.step')} ${step}/2`}
      </div>
      <div className="register-form-wrapper">
        <LoaderWrapper name="register-form">
          <div className="register-form">
            {/* STEP 1 */}

            {step === 1 && (
              <>
                <div className="register-form__row">
                  <Input
                    bg="grey"
                    name="username"
                    placeholder={`${t('userData.username')} *`}
                    error={errors.username}
                    height="large"
                    value={formData.username}
                    onChange={updateField('username')}
                    onBlur={validateUsername}
                    addon={<Icon size="large" name="userFill" />}
                  />
                </div>

                <div className="register-form__row">
                  <div className="d-flex">
                    <div className="w-50 mr-6">
                      <Input
                        bg="grey"
                        name="first_name"
                        className=""
                        placeholder={`${t('userData.first-name')} *`}
                        error={errors.first_name}
                        height="large"
                        value={formData.first_name}
                        onChange={updateField('first_name')}
                        onBlur={validateField('first_name')}
                      />
                    </div>

                    <div className="w-50">
                      <Input
                        bg="grey"
                        name="last_name"
                        className=""
                        placeholder={`${t('userData.last-name')} *`}
                        height="large"
                        error={errors.last_name}
                        value={formData.last_name}
                        onChange={updateField('last_name')}
                        onBlur={validateField('last_name')}
                        addon={<Icon size="large" name="userFill" />}
                      />
                    </div>
                  </div>
                </div>

                <div className="register-form__row">
                  <Input
                    bg="grey"
                    name="register-email"
                    type="email"
                    error={errors.email}
                    placeholder={`${t('userData.email-adress')} *`}
                    height="large"
                    value={formData.email}
                    onChange={updateField('email')}
                    onBlur={validateEmail}
                    addon={<Icon size="large" name="mailFill" />}
                  />
                </div>

                <div className="register-form__row">
                  <Input
                    bg="grey"
                    name="register-password"
                    type="password"
                    placeholder={`${t('userData.password')} *`}
                    height="large"
                    error={errors.password}
                    value={formData.password}
                    onChange={updateField('password')}
                    onBlur={validateField('password')}
                    addon={<Icon size="large" name="lockFill" />}
                  />
                </div>

                <div className="register-form__row">
                  <Input
                    bg="grey"
                    name="register-password-confirmation"
                    type="password"
                    placeholder={`${t('userData.confirm-password')} *`}
                    error={errors.passwordConfirmation}
                    height="large"
                    value={formData.passwordConfirmation}
                    onChange={updateField('passwordConfirmation')}
                    onBlur={validateField('passwordConfirmation')}
                    addon={<Icon size="large" name="lockFill" />}
                  />
                </div>

                <div className="register-form__row short-row">
                  <Input
                    bg="grey"
                    name="promo_code"
                    placeholder={t('globals.promo-code')}
                    height="large"
                    error={errors.promo_code}
                    value={formData.promo_code}
                    onChange={updateField('promo_code')}
                    onBlur={validatePromoCode}
                    addon={<Icon size="large" name="promoCodeFill" />}
                  />
                </div>

                <div className="register-form__radio-row mb-3">
                  <Checkbox
                    onChange={updateField('terms_and_conditions')}
                    position="right"
                    variant="circle"
                    value={formData.terms_and_conditions}
                    textEllipsis={false}
                    label={t('verification.accept-terms')}
                  />
                </div>
              </>
            )}

            {/* STEP 2 */}

            {step === 2 && (
              <>
                <div className="register-form__row">
                  <Select
                    bg="grey"
                    placeholder={t('userData.country')}
                    options={countryOptions}
                    value={formData.country}
                    error={errors.country}
                    onChange={updateField('country')}
                    shape="squared"
                  />
                </div>

                {/* <div className="register-form__radio-row mb-4">
                  <Checkbox
                    onChange={updateField('hasSsn')}
                    position="right"
                    variant="circle"
                    value={formData.hasSsn}
                    textEllipsis={false}
                    label={t('userData.i-have-jmbg')}
                  />
                </div> */}

                <div className="register-form__row">
                  <Input
                    bg="grey"
                    name="ssn"
                    placeholder={
                      formData.country.value === 'MNE'
                        ? t('userData.jmbg')
                        : t('userData.document-id')
                    }
                    height="large"
                    value={formData.ssn}
                    onBlur={validateField('ssn')}
                    onChange={updateField('ssn')}
                    error={errors.ssn}
                  />
                </div>

                <div className="register-form__row">
                  <Select
                    bg="grey"
                    placeholder={t('userData.gender')}
                    options={genderOptions}
                    value={formData.gender}
                    disabled={disableSsnFields}
                    onChange={updateField('gender')}
                    onBlur={validateField('gender')}
                  />
                </div>

                <div className="register-form__row">
                  <DatePicker
                    placeholderText="Filtriraj po datumu"
                    className="ticket-status-filter__datepicker"
                    selectsRange
                    disabled={
                      disableSsnFields || formData.country.value === 'MNE'
                    }
                    value={formData.date_of_birth || maxDate}
                    onChange={updateField('date_of_birth')}
                    onBlur={validateField('date_of_birth')}
                    isClearable={true}
                    id="filter-datepicker"
                    maximumDate={{
                      day: maxDate.getDate(),
                      month: maxDate.getMonth() + 1,
                      year: maxDate.getFullYear(),
                    }}
                    customInput={CustomDatePickerInput}
                  />
                </div>

                <div className="register-form__row">
                  {formData.country.value !== 'MNE' && (
                    <Input
                      bg="grey"
                      name="city"
                      placeholder={`${t('userData.city')} *`}
                      height="large"
                      value={
                        typeof formData.city !== 'string'
                          ? formData.city.label
                          : formData.city
                      }
                      error={errors.city}
                      onChange={updateField('city')}
                      onBlur={validateField('city')}
                      addon={<Icon size="large" name="city" variant="dark" />}
                    />
                  )}

                  {formData.country.value === 'MNE' && (
                    <Select
                      bg="grey"
                      placeholder={`${t('userData.city')} *`}
                      options={montenegroCities}
                      value={formData.city}
                      error={errors.city}
                      onChange={updateField('city')}
                      onBlur={validateField('city')}
                      shape="squared"
                    />
                  )}
                </div>

                <div className="register-form__row">
                  <Input
                    bg="grey"
                    name="address"
                    placeholder={`${t('userData.address')} *`}
                    height="large"
                    value={formData.address}
                    error={errors.address}
                    onChange={updateField('address')}
                    onBlur={validateField('address')}
                    addon={<Icon size="large" name="building" variant="dark" />}
                  />
                </div>

                <div className="register-form__row short-row">
                  <Input
                    bg="grey"
                    name="phone_number"
                    placeholder={t('userData.phone')}
                    error={errors.phone_number}
                    height="large"
                    value={formData.phone_number}
                    onChange={updateField('phone_number')}
                    onBlur={validateField('phone_number')}
                    addon={<Icon name="phone" size="large" />}
                  />
                </div>

                <div className="register-form__radio-row mb-3">
                  <span>{t('errors.fields-obligated')}</span>
                </div>

                <div className="register-form__radio-row mb-3">
                  <Checkbox
                    onChange={updateField('subscribed_email')}
                    position="right"
                    variant="circle"
                    value={formData.subscribed_email}
                    textEllipsis={false}
                    label={t('verification.agree-email-notif')}
                  />
                </div>

                <div className="register-form__radio-row mb-3">
                  <Checkbox
                    onChange={updateField('subscribed_sms')}
                    position="right"
                    variant="circle"
                    value={formData.subscribed_sms}
                    textEllipsis={false}
                    label={t('verification.agree-sms-notif')}
                  />
                </div>
              </>
            )}

            <div className="d-flex justify-center align-items mt-1">
              <Button
                bg="green"
                onClick={register}
                size="large"
                disabled={!isValid || checkInProgress}
              >
                <div className="d-flex align-items justify-center">
                  <Icon name="signIn" />
                  <span className="ml-2 text-regular text-bold">
                    {step === 1
                      ? t('verification.confirm')
                      : t('login.sign-in')}
                  </span>
                </div>
              </Button>
            </div>
          </div>
        </LoaderWrapper>
      </div>
    </>
  );
});

export { Register };
