export const calculateSliderConfig = (arrLength: number) => {
  return {
    infinite: true,
    swipeToSlide: true,
    slidesToShow: arrLength < 6 ? arrLength : 6,
    slidesToScroll: 1,
    cssEase: 'linear',
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 800,
    draggable: false,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1866,
        settings: {
          slidesToShow: arrLength < 5 ? arrLength : 5,
        },
      },
      {
        breakpoint: 1732,
        settings: {
          slidesToShow: arrLength < 4 ? arrLength : 4,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: arrLength < 3 ? arrLength : 3,
        },
      },
      {
        breakpoint: 898,
        settings: {
          slidesToShow: arrLength < 2 ? arrLength : 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: arrLength < 3 ? arrLength : 3,
        },
      },
    ],
  };
};

export const SLIDER_CONFIG = {
  infinite: true,
  swipeToSlide: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  cssEase: 'linear',
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
  speed: 800,
  draggable: false,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1866,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1732,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 898,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

export const SLIDER_CONFIG_MOBILE_PROMO = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'linear',
  arrows: false,
  dots: true,
};

export const SLIDER_CONFIG_LEADERBOARD = {
  infinite: true,
  autoplay: true,
  autoplayspeed: 500,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  cssEase: 'linear',
  arrows: false,
  dots: false,
};
