/* React modules */

/* Our modules */
import { EventType } from 'modules/sports/sports.types';
import OfferBuilder from 'modules/offer/services/offer-builder.service';
import offerEventsService from 'modules/offer/services/offer-events.service';

/* 3rd Party modules */

class DesktopOfferFactory {
  private builder: OfferBuilder;

  constructor() {
    this.builder = new OfferBuilder();
  }

  buildTable(sportId: number, type: EventType) {
    this.builder.setType(type);
    this.builder.setSport(sportId);
    this.builder.setEvents();
    this.builder.applySort();
    this.builder.groupEvents();
    this.builder.buildRows();

    return this.builder.getTable();
  }

  getOfferForSport(sportId: number, config: any) {
    const { offerState, eventTypes } = config;

    return eventTypes
      .filter(
        (eventType: any) =>
          !offerEventsService.isEventTypeHidden(eventType, offerState)
      )
      .map((eventType: any) => this.buildTable(sportId, eventType))
      .filter((t: any) => !!t.events.length);
  }

  build(sports: number[], config: any) {
    return sports.reduce((tables: any[], sportId: number) => {
      return tables.concat(this.getOfferForSport(sportId, config));
    }, []);
  }
}

export default DesktopOfferFactory;
