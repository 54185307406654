/* React modules */

/* Our modules */
import SectionHeader from 'modules/user/ui/SectionHeader';
import AccountDetails from 'modules/user/ui/UserSettings/AccountDetails';
import './UserSettings.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

const UserSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="user-settings max-height">
      <SectionHeader title={t('userData.user-details')} />
      <AccountDetails />
    </div>
  );
};

export default UserSettings;
