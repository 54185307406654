import { makeAutoObservable, observable } from 'mobx';

export enum PaymentDirections {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export type PaymentDirection =
  | PaymentDirections.CREDIT
  | PaymentDirections.DEBIT;

class CreditDebitFilters {
  $paymentDirection: PaymentDirection;

  constructor() {
    this.$paymentDirection = PaymentDirections.CREDIT;
    makeAutoObservable(
      this,
      {
        $paymentDirection: observable,
      },
      { deep: false }
    );
  }

  setPaymentDirection = (direction: PaymentDirection) => {
    this.$paymentDirection = direction;
  };

  get paymentDirection() {
    return this.$paymentDirection;
  }
}

export default CreditDebitFilters;
