import React from 'react';
import './CreditDebitInfoBar.scss';
import { useTranslation } from 'react-i18next';
import { currencyWithoutSymbol } from 'libs/currency-formatter';

const CreditDebitInfoBar = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="info-bar">
      <div className="description">
        <div className="wrapper d-flex justify-center align-items">
          <p className="description__item w-25 d-flex justify-center text-center">
            {props.isDebit
              ? t('payments.minimum-debit')
              : t('payments.minimum-credit')}
          </p>
          <p className="description__item w-25 d-flex justify-center text-center">
            {props.isDebit
              ? t('payments.maximum-debit')
              : t('payments.maximum-credit')}
          </p>
          <p className="description__item w-25 d-flex justify-center text-center">
            {t('payments.commission')}
          </p>
          <p className="description__item w-25 d-flex justify-center text-center">
            {t('globals.time')}
          </p>
        </div>
      </div>
      <div className="value">
        <div className="wrapper d-flex justify-center align-items">
          <p className="w-25 d-flex justify-center">
            {props.min && currencyWithoutSymbol(props.min)}
          </p>
          <p className="w-25 d-flex justify-center">
            {props.max && currencyWithoutSymbol(props.max)}
          </p>
          <p className="w-25 d-flex justify-center">{t('globals.free')}</p>
          <p className="w-25 d-flex justify-center">
            {t('globals.immediately')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditDebitInfoBar;
