export const sortObj = (obj: any) => {
  return Object.keys(obj)
    .sort((a: any, b: any) => {
      return +a - +b;
    })
    .reduce(function (result: any, key: any) {
      result[key] = obj[key];
      return result;
    }, {});
};

export const findLowestDifference = (sortedOddsByLimits: any) => {
  let lowestDifferenceKey = '';
  let lowestDifference = 1000;
  let targetKey = {};

  Object.keys(sortedOddsByLimits).forEach((key: any) => {
    if (
      sortedOddsByLimits[key].some(
        (odd: any) => odd && odd.limit && !odd.isDisabled
      )
    ) {
      if (
        sortedOddsByLimits[key][0]?.value > sortedOddsByLimits[key][1]?.value &&
        sortedOddsByLimits[key][0]?.value - sortedOddsByLimits[key][1]?.value <
          lowestDifference
      ) {
        lowestDifferenceKey = key;
        lowestDifference =
          sortedOddsByLimits[key][0].value - sortedOddsByLimits[key][1].value;
        targetKey = { [key]: sortedOddsByLimits[key] };
      } else if (
        sortedOddsByLimits[key][1]?.value > sortedOddsByLimits[key][0]?.value &&
        sortedOddsByLimits[key][1]?.value - sortedOddsByLimits[key][0]?.value <
          lowestDifference
      ) {
        lowestDifferenceKey = key;
        lowestDifference =
          sortedOddsByLimits[key][1].value - sortedOddsByLimits[key][0].value;
        targetKey = { [key]: sortedOddsByLimits[key] };
      }
    }
  });

  return { lowestDifferenceKey, targetKey };
};

export const sortByLowestDifference = (
  sortedOddsByLimits: any,
  lowestDifferenceKey: any,
  targetKey: any
) => {
  Object.keys(sortedOddsByLimits).forEach((key: any) => {
    if (key === lowestDifferenceKey) {
      delete sortedOddsByLimits[key];
    }
  });

  return (sortedOddsByLimits = {
    ...targetKey,
    ...sortedOddsByLimits,
  });
};
