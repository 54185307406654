/* React modules */

/* Our modules */
import { SystemGroup } from 'modules/ticket/system-ticket';

/* 3rd Party modules */

export const renderCombinations = (
  system: number[],
  events: any[],
  isFix: boolean
) => {
  if (!system.length) return '';

  if (isFix) return `${events.length}/${events.length}`;

  const combinations = system.reduce(
    (all: string, combination: number, i: number) => {
      if (i === 0) return `${combination} `;
      return `${all} + ${combination} `;
    },
    ''
  );

  return `${combinations} / ${events.length}`;
};

export const getGroups = (
  systemGroups: SystemGroup[],
  events: any[],
  isFix: boolean
) => {
  const groups = systemGroups.filter((g: any) => !!g.events.length);

  return groups.map((group: any) => {
    const groupEvents = group.events.map(({ id }: any) =>
      events.find((e: any) => e.id === id)
    );
    const system = renderCombinations(group.system, group.events, isFix);

    return { events: groupEvents, system };
  });
};

export const calculateTotalOdd = (events: any[]) => {
  return events.reduce((total: number, event: any) => {
    if (!event || !event.selectedOdds[0]) return total;
    return total * event.selectedOdds[0].value;
  }, 1);
};
