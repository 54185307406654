import Tabs, { TabsProps } from 'rc-tabs';

import './Tabs.scss';

interface TabsComponentProps {
  className?:
    | 'event-stats'
    | 'green-small'
    | 'yellow-big'
    | 'yellow-transparent-border'
    | 'transparent'
    | 'transparent-grey-border';
}

const TabsComponent = ({
  items,
  animated,
  className,
  activeKey,
  defaultActiveKey,
  onChange,
  onTabClick,
  style,
  id,
}: TabsProps & TabsComponentProps) => {
  return (
    <Tabs
      items={items}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      animated={animated}
      prefixCls="custom-tabs"
      className={className}
      onChange={onChange}
      onTabClick={onTabClick}
      style={style}
      id={id}
    />
  );
};

export default TabsComponent;
