/**
 * @fileoverview gRPC-Web generated client stub for web_slip
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as proto_common_common_pb from '../../proto/common/common_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_slip_web_slip_pb from '../../proto/slip/web_slip_pb';


export class WebSlipServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoPlaceBetWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.Slip,
    (request: proto_common_common_pb.SlipRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.Slip.deserializeBinary
  );

  placeBetWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.Slip>;

  placeBetWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.Slip>;

  placeBetWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/PlaceBetWeb',
        request,
        metadata || {},
        this.methodInfoPlaceBetWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/PlaceBetWeb',
    request,
    metadata || {},
    this.methodInfoPlaceBetWeb);
  }

  methodInfoPlaceBetLiveWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.Slip,
    (request: proto_common_common_pb.SlipRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.Slip.deserializeBinary
  );

  placeBetLiveWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.Slip>;

  placeBetLiveWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.Slip>;

  placeBetLiveWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/PlaceBetLiveWeb',
        request,
        metadata || {},
        this.methodInfoPlaceBetLiveWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/PlaceBetLiveWeb',
    request,
    metadata || {},
    this.methodInfoPlaceBetLiveWeb);
  }

  methodInfoManualChangeUserAcceptWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  manualChangeUserAcceptWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  manualChangeUserAcceptWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  manualChangeUserAcceptWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/ManualChangeUserAcceptWeb',
        request,
        metadata || {},
        this.methodInfoManualChangeUserAcceptWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/ManualChangeUserAcceptWeb',
    request,
    metadata || {},
    this.methodInfoManualChangeUserAcceptWeb);
  }

  methodInfoManualChangeUserDenyWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  manualChangeUserDenyWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  manualChangeUserDenyWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  manualChangeUserDenyWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/ManualChangeUserDenyWeb',
        request,
        metadata || {},
        this.methodInfoManualChangeUserDenyWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/ManualChangeUserDenyWeb',
    request,
    metadata || {},
    this.methodInfoManualChangeUserDenyWeb);
  }

  methodInfoSlipSendForCashOutWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  slipSendForCashOutWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  slipSendForCashOutWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  slipSendForCashOutWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/SlipSendForCashOutWeb',
        request,
        metadata || {},
        this.methodInfoSlipSendForCashOutWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/SlipSendForCashOutWeb',
    request,
    metadata || {},
    this.methodInfoSlipSendForCashOutWeb);
  }

  methodInfoSlipCashOutUserAcceptWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  slipCashOutUserAcceptWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  slipCashOutUserAcceptWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  slipCashOutUserAcceptWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/SlipCashOutUserAcceptWeb',
        request,
        metadata || {},
        this.methodInfoSlipCashOutUserAcceptWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/SlipCashOutUserAcceptWeb',
    request,
    metadata || {},
    this.methodInfoSlipCashOutUserAcceptWeb);
  }

  methodInfoSlipCashOutUserDenyWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  slipCashOutUserDenyWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  slipCashOutUserDenyWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  slipCashOutUserDenyWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/SlipCashOutUserDenyWeb',
        request,
        metadata || {},
        this.methodInfoSlipCashOutUserDenyWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/SlipCashOutUserDenyWeb',
    request,
    metadata || {},
    this.methodInfoSlipCashOutUserDenyWeb);
  }

  methodInfoSlipCashOutUserCancel = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  slipCashOutUserCancel(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  slipCashOutUserCancel(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  slipCashOutUserCancel(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/SlipCashOutUserCancel',
        request,
        metadata || {},
        this.methodInfoSlipCashOutUserCancel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/SlipCashOutUserCancel',
    request,
    metadata || {},
    this.methodInfoSlipCashOutUserCancel);
  }

  methodInfoGetValidationRulesWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.Rules,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.Rules.deserializeBinary
  );

  getValidationRulesWeb(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.Rules>;

  getValidationRulesWeb(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.Rules) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.Rules>;

  getValidationRulesWeb(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.Rules) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetValidationRulesWeb',
        request,
        metadata || {},
        this.methodInfoGetValidationRulesWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetValidationRulesWeb',
    request,
    metadata || {},
    this.methodInfoGetValidationRulesWeb);
  }

  methodInfoGetUserSlipsWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.PaginatedSlips,
    (request: proto_slip_web_slip_pb.GetUserSlipsRequest) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.PaginatedSlips.deserializeBinary
  );

  getUserSlipsWeb(
    request: proto_slip_web_slip_pb.GetUserSlipsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.PaginatedSlips>;

  getUserSlipsWeb(
    request: proto_slip_web_slip_pb.GetUserSlipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.PaginatedSlips) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.PaginatedSlips>;

  getUserSlipsWeb(
    request: proto_slip_web_slip_pb.GetUserSlipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.PaginatedSlips) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetUserSlipsWeb',
        request,
        metadata || {},
        this.methodInfoGetUserSlipsWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetUserSlipsWeb',
    request,
    metadata || {},
    this.methodInfoGetUserSlipsWeb);
  }

  methodInfoGetSingleSlipWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.Slip,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.Slip.deserializeBinary
  );

  getSingleSlipWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.Slip>;

  getSingleSlipWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.Slip>;

  getSingleSlipWeb(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetSingleSlipWeb',
        request,
        metadata || {},
        this.methodInfoGetSingleSlipWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetSingleSlipWeb',
    request,
    metadata || {},
    this.methodInfoGetSingleSlipWeb);
  }

  methodInfoGetSharedSlip = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.Slip,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.Slip.deserializeBinary
  );

  getSharedSlip(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.Slip>;

  getSharedSlip(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.Slip>;

  getSharedSlip(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetSharedSlip',
        request,
        metadata || {},
        this.methodInfoGetSharedSlip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetSharedSlip',
    request,
    metadata || {},
    this.methodInfoGetSharedSlip);
  }

  methodInfoGetTopWinningSlips = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.PaginatedSlips,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.PaginatedSlips.deserializeBinary
  );

  getTopWinningSlips(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.PaginatedSlips>;

  getTopWinningSlips(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.PaginatedSlips) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.PaginatedSlips>;

  getTopWinningSlips(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.PaginatedSlips) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetTopWinningSlips',
        request,
        metadata || {},
        this.methodInfoGetTopWinningSlips,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetTopWinningSlips',
    request,
    metadata || {},
    this.methodInfoGetTopWinningSlips);
  }

  methodInfoCalculateSlipValuesWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.CalculatedValues,
    (request: proto_common_common_pb.SlipRequest) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.CalculatedValues.deserializeBinary
  );

  calculateSlipValuesWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.CalculatedValues>;

  calculateSlipValuesWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.CalculatedValues) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.CalculatedValues>;

  calculateSlipValuesWeb(
    request: proto_common_common_pb.SlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.CalculatedValues) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/CalculateSlipValuesWeb',
        request,
        metadata || {},
        this.methodInfoCalculateSlipValuesWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/CalculateSlipValuesWeb',
    request,
    metadata || {},
    this.methodInfoCalculateSlipValuesWeb);
  }

  methodInfoScanSlip = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.Slip,
    (request: proto_slip_web_slip_pb.SlipId) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.Slip.deserializeBinary
  );

  scanSlip(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.Slip>;

  scanSlip(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.Slip>;

  scanSlip(
    request: proto_slip_web_slip_pb.SlipId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/ScanSlip',
        request,
        metadata || {},
        this.methodInfoScanSlip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/ScanSlip',
    request,
    metadata || {},
    this.methodInfoScanSlip);
  }

  methodInfoGetBonusConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.BonusConfig,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.BonusConfig.deserializeBinary
  );

  getBonusConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.BonusConfig>;

  getBonusConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.BonusConfig) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.BonusConfig>;

  getBonusConfig(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.BonusConfig) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetBonusConfig',
        request,
        metadata || {},
        this.methodInfoGetBonusConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetBonusConfig',
    request,
    metadata || {},
    this.methodInfoGetBonusConfig);
  }

  methodInfoCreateFastSlip = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.FastSlip,
    (request: proto_common_common_pb.FastSlipRequest) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.FastSlip.deserializeBinary
  );

  createFastSlip(
    request: proto_common_common_pb.FastSlipRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.FastSlip>;

  createFastSlip(
    request: proto_common_common_pb.FastSlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.FastSlip) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.FastSlip>;

  createFastSlip(
    request: proto_common_common_pb.FastSlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.FastSlip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/CreateFastSlip',
        request,
        metadata || {},
        this.methodInfoCreateFastSlip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/CreateFastSlip',
    request,
    metadata || {},
    this.methodInfoCreateFastSlip);
  }

  methodInfoCreateFastSlipUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.FastSlip,
    (request: proto_common_common_pb.FastSlipRequest) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.FastSlip.deserializeBinary
  );

  createFastSlipUser(
    request: proto_common_common_pb.FastSlipRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.FastSlip>;

  createFastSlipUser(
    request: proto_common_common_pb.FastSlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.FastSlip) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.FastSlip>;

  createFastSlipUser(
    request: proto_common_common_pb.FastSlipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.FastSlip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/CreateFastSlipUser',
        request,
        metadata || {},
        this.methodInfoCreateFastSlipUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/CreateFastSlipUser',
    request,
    metadata || {},
    this.methodInfoCreateFastSlipUser);
  }

  methodInfoGetFastSlipsForUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_slip_web_slip_pb.FastSlips,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_slip_web_slip_pb.FastSlips.deserializeBinary
  );

  getFastSlipsForUser(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_slip_web_slip_pb.FastSlips>;

  getFastSlipsForUser(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.FastSlips) => void): grpcWeb.ClientReadableStream<proto_slip_web_slip_pb.FastSlips>;

  getFastSlipsForUser(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_slip_web_slip_pb.FastSlips) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetFastSlipsForUser',
        request,
        metadata || {},
        this.methodInfoGetFastSlipsForUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetFastSlipsForUser',
    request,
    metadata || {},
    this.methodInfoGetFastSlipsForUser);
  }

  methodInfoGetFastSlipWeb = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.Slip,
    (request: proto_slip_web_slip_pb.FastSlip) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.Slip.deserializeBinary
  );

  getFastSlipWeb(
    request: proto_slip_web_slip_pb.FastSlip,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.Slip>;

  getFastSlipWeb(
    request: proto_slip_web_slip_pb.FastSlip,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.Slip>;

  getFastSlipWeb(
    request: proto_slip_web_slip_pb.FastSlip,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.Slip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_slip.WebSlipService/GetFastSlipWeb',
        request,
        metadata || {},
        this.methodInfoGetFastSlipWeb,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_slip.WebSlipService/GetFastSlipWeb',
    request,
    metadata || {},
    this.methodInfoGetFastSlipWeb);
  }

}

