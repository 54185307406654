// source: proto/loyalty/cashdrop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
goog.exportSymbol('proto.cashdrop.CashDropDisabled', null, global);
goog.exportSymbol('proto.cashdrop.Drop', null, global);
goog.exportSymbol('proto.cashdrop.DropGroup', null, global);
goog.exportSymbol('proto.cashdrop.DropGroups', null, global);
goog.exportSymbol('proto.cashdrop.Drops', null, global);
goog.exportSymbol('proto.cashdrop.GetDropGroups', null, global);
goog.exportSymbol('proto.cashdrop.WebDrop', null, global);
goog.exportSymbol('proto.cashdrop.WebDrops', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.CashDropDisabled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cashdrop.CashDropDisabled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.CashDropDisabled.displayName = 'proto.cashdrop.CashDropDisabled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.Drop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cashdrop.Drop.repeatedFields_, null);
};
goog.inherits(proto.cashdrop.Drop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.Drop.displayName = 'proto.cashdrop.Drop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.WebDrop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cashdrop.WebDrop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.WebDrop.displayName = 'proto.cashdrop.WebDrop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.Drops = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cashdrop.Drops.repeatedFields_, null);
};
goog.inherits(proto.cashdrop.Drops, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.Drops.displayName = 'proto.cashdrop.Drops';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.WebDrops = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cashdrop.WebDrops.repeatedFields_, null);
};
goog.inherits(proto.cashdrop.WebDrops, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.WebDrops.displayName = 'proto.cashdrop.WebDrops';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.DropGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cashdrop.DropGroup.repeatedFields_, null);
};
goog.inherits(proto.cashdrop.DropGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.DropGroup.displayName = 'proto.cashdrop.DropGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.DropGroups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cashdrop.DropGroups.repeatedFields_, null);
};
goog.inherits(proto.cashdrop.DropGroups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.DropGroups.displayName = 'proto.cashdrop.DropGroups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cashdrop.GetDropGroups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cashdrop.GetDropGroups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cashdrop.GetDropGroups.displayName = 'proto.cashdrop.GetDropGroups';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.CashDropDisabled.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.CashDropDisabled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.CashDropDisabled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.CashDropDisabled.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.CashDropDisabled}
 */
proto.cashdrop.CashDropDisabled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.CashDropDisabled;
  return proto.cashdrop.CashDropDisabled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.CashDropDisabled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.CashDropDisabled}
 */
proto.cashdrop.CashDropDisabled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.CashDropDisabled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.CashDropDisabled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.CashDropDisabled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.CashDropDisabled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.cashdrop.CashDropDisabled.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cashdrop.CashDropDisabled} returns this
 */
proto.cashdrop.CashDropDisabled.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cashdrop.Drop.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.Drop.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.Drop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.Drop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.Drop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    collectable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    collectedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.Drop}
 */
proto.cashdrop.Drop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.Drop;
  return proto.cashdrop.Drop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.Drop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.Drop}
 */
proto.cashdrop.Drop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectable(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCollectedBy(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.Drop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.Drop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.Drop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.Drop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCollectable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCollectedByList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cashdrop.Drop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 start = 2;
 * @return {number}
 */
proto.cashdrop.Drop.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool collectable = 3;
 * @return {boolean}
 */
proto.cashdrop.Drop.prototype.getCollectable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.setCollectable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated int32 collected_by = 4;
 * @return {!Array<number>}
 */
proto.cashdrop.Drop.prototype.getCollectedByList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.setCollectedByList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.addCollectedBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.clearCollectedByList = function() {
  return this.setCollectedByList([]);
};


/**
 * optional bool is_disabled = 5;
 * @return {boolean}
 */
proto.cashdrop.Drop.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.cashdrop.Drop.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.cashdrop.Drop} returns this
*/
proto.cashdrop.Drop.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cashdrop.Drop} returns this
 */
proto.cashdrop.Drop.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cashdrop.Drop.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.WebDrop.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.WebDrop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.WebDrop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.WebDrop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    awardType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    awardAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    collected: jspb.Message.getFieldWithDefault(msg, 5, 0),
    collectedByUser: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    total: jspb.Message.getFieldWithDefault(msg, 7, 0),
    start: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.WebDrop}
 */
proto.cashdrop.WebDrop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.WebDrop;
  return proto.cashdrop.WebDrop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.WebDrop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.WebDrop}
 */
proto.cashdrop.WebDrop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwardType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAwardAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCollected(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectedByUser(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.WebDrop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.WebDrop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.WebDrop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.WebDrop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAwardType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAwardAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCollected();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCollectedByUser();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cashdrop.WebDrop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.cashdrop.WebDrop.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string award_type = 3;
 * @return {string}
 */
proto.cashdrop.WebDrop.prototype.getAwardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setAwardType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double award_amount = 4;
 * @return {number}
 */
proto.cashdrop.WebDrop.prototype.getAwardAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setAwardAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 collected = 5;
 * @return {number}
 */
proto.cashdrop.WebDrop.prototype.getCollected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setCollected = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool collected_by_user = 6;
 * @return {boolean}
 */
proto.cashdrop.WebDrop.prototype.getCollectedByUser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setCollectedByUser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 total = 7;
 * @return {number}
 */
proto.cashdrop.WebDrop.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 start = 8;
 * @return {number}
 */
proto.cashdrop.WebDrop.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.WebDrop} returns this
 */
proto.cashdrop.WebDrop.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cashdrop.Drops.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.Drops.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.Drops.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.Drops} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.Drops.toObject = function(includeInstance, msg) {
  var f, obj = {
    dropsList: jspb.Message.toObjectList(msg.getDropsList(),
    proto.cashdrop.Drop.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.Drops}
 */
proto.cashdrop.Drops.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.Drops;
  return proto.cashdrop.Drops.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.Drops} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.Drops}
 */
proto.cashdrop.Drops.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cashdrop.Drop;
      reader.readMessage(value,proto.cashdrop.Drop.deserializeBinaryFromReader);
      msg.addDrops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.Drops.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.Drops.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.Drops} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.Drops.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDropsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cashdrop.Drop.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Drop drops = 1;
 * @return {!Array<!proto.cashdrop.Drop>}
 */
proto.cashdrop.Drops.prototype.getDropsList = function() {
  return /** @type{!Array<!proto.cashdrop.Drop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cashdrop.Drop, 1));
};


/**
 * @param {!Array<!proto.cashdrop.Drop>} value
 * @return {!proto.cashdrop.Drops} returns this
*/
proto.cashdrop.Drops.prototype.setDropsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cashdrop.Drop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cashdrop.Drop}
 */
proto.cashdrop.Drops.prototype.addDrops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cashdrop.Drop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cashdrop.Drops} returns this
 */
proto.cashdrop.Drops.prototype.clearDropsList = function() {
  return this.setDropsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cashdrop.WebDrops.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.WebDrops.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.WebDrops.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.WebDrops} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.WebDrops.toObject = function(includeInstance, msg) {
  var f, obj = {
    dropsList: jspb.Message.toObjectList(msg.getDropsList(),
    proto.cashdrop.WebDrop.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.WebDrops}
 */
proto.cashdrop.WebDrops.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.WebDrops;
  return proto.cashdrop.WebDrops.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.WebDrops} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.WebDrops}
 */
proto.cashdrop.WebDrops.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cashdrop.WebDrop;
      reader.readMessage(value,proto.cashdrop.WebDrop.deserializeBinaryFromReader);
      msg.addDrops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.WebDrops.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.WebDrops.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.WebDrops} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.WebDrops.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDropsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cashdrop.WebDrop.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebDrop drops = 1;
 * @return {!Array<!proto.cashdrop.WebDrop>}
 */
proto.cashdrop.WebDrops.prototype.getDropsList = function() {
  return /** @type{!Array<!proto.cashdrop.WebDrop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cashdrop.WebDrop, 1));
};


/**
 * @param {!Array<!proto.cashdrop.WebDrop>} value
 * @return {!proto.cashdrop.WebDrops} returns this
*/
proto.cashdrop.WebDrops.prototype.setDropsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cashdrop.WebDrop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cashdrop.WebDrop}
 */
proto.cashdrop.WebDrops.prototype.addDrops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cashdrop.WebDrop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cashdrop.WebDrops} returns this
 */
proto.cashdrop.WebDrops.prototype.clearDropsList = function() {
  return this.setDropsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cashdrop.DropGroup.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.DropGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.DropGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.DropGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.DropGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    end: jspb.Message.getFieldWithDefault(msg, 3, 0),
    awardType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    awardAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    dropDailyCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dropDuration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dropAwardCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dropUserMaxCollect: jspb.Message.getFieldWithDefault(msg, 9, 0),
    minUserBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    minUserBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    minUserBetPeriod: jspb.Message.getFieldWithDefault(msg, 12, 0),
    dropsList: jspb.Message.toObjectList(msg.getDropsList(),
    proto.cashdrop.Drop.toObject, includeInstance),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.DropGroup}
 */
proto.cashdrop.DropGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.DropGroup;
  return proto.cashdrop.DropGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.DropGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.DropGroup}
 */
proto.cashdrop.DropGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwardType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAwardAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDropDailyCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDropDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDropAwardCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDropUserMaxCollect(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinUserBalance(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinUserBet(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinUserBetPeriod(value);
      break;
    case 13:
      var value = new proto.cashdrop.Drop;
      reader.readMessage(value,proto.cashdrop.Drop.deserializeBinaryFromReader);
      msg.addDrops(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 19:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.DropGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.DropGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.DropGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.DropGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAwardType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAwardAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDropDailyCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDropDuration();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDropAwardCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDropUserMaxCollect();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMinUserBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getMinUserBet();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getMinUserBetPeriod();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getDropsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.cashdrop.Drop.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 start = 2;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 end = 3;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string award_type = 4;
 * @return {string}
 */
proto.cashdrop.DropGroup.prototype.getAwardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setAwardType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double award_amount = 5;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getAwardAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setAwardAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 drop_daily_count = 6;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getDropDailyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setDropDailyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 drop_duration = 7;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getDropDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setDropDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 drop_award_count = 8;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getDropAwardCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setDropAwardCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 drop_user_max_collect = 9;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getDropUserMaxCollect = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setDropUserMaxCollect = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double min_user_balance = 10;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getMinUserBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setMinUserBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double min_user_bet = 11;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getMinUserBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setMinUserBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int32 min_user_bet_period = 12;
 * @return {number}
 */
proto.cashdrop.DropGroup.prototype.getMinUserBetPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setMinUserBetPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Drop drops = 13;
 * @return {!Array<!proto.cashdrop.Drop>}
 */
proto.cashdrop.DropGroup.prototype.getDropsList = function() {
  return /** @type{!Array<!proto.cashdrop.Drop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cashdrop.Drop, 13));
};


/**
 * @param {!Array<!proto.cashdrop.Drop>} value
 * @return {!proto.cashdrop.DropGroup} returns this
*/
proto.cashdrop.DropGroup.prototype.setDropsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.cashdrop.Drop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cashdrop.Drop}
 */
proto.cashdrop.DropGroup.prototype.addDrops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.cashdrop.Drop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.clearDropsList = function() {
  return this.setDropsList([]);
};


/**
 * optional bool is_disabled = 14;
 * @return {boolean}
 */
proto.cashdrop.DropGroup.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional common.Error error = 19;
 * @return {?proto.common.Error}
 */
proto.cashdrop.DropGroup.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 19));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.cashdrop.DropGroup} returns this
*/
proto.cashdrop.DropGroup.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cashdrop.DropGroup} returns this
 */
proto.cashdrop.DropGroup.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cashdrop.DropGroup.prototype.hasError = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cashdrop.DropGroups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.DropGroups.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.DropGroups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.DropGroups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.DropGroups.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.cashdrop.DropGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.DropGroups}
 */
proto.cashdrop.DropGroups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.DropGroups;
  return proto.cashdrop.DropGroups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.DropGroups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.DropGroups}
 */
proto.cashdrop.DropGroups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cashdrop.DropGroup;
      reader.readMessage(value,proto.cashdrop.DropGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.DropGroups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.DropGroups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.DropGroups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.DropGroups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cashdrop.DropGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DropGroup groups = 1;
 * @return {!Array<!proto.cashdrop.DropGroup>}
 */
proto.cashdrop.DropGroups.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.cashdrop.DropGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cashdrop.DropGroup, 1));
};


/**
 * @param {!Array<!proto.cashdrop.DropGroup>} value
 * @return {!proto.cashdrop.DropGroups} returns this
*/
proto.cashdrop.DropGroups.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cashdrop.DropGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cashdrop.DropGroup}
 */
proto.cashdrop.DropGroups.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cashdrop.DropGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cashdrop.DropGroups} returns this
 */
proto.cashdrop.DropGroups.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cashdrop.GetDropGroups.prototype.toObject = function(opt_includeInstance) {
  return proto.cashdrop.GetDropGroups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cashdrop.GetDropGroups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.GetDropGroups.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cashdrop.GetDropGroups}
 */
proto.cashdrop.GetDropGroups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cashdrop.GetDropGroups;
  return proto.cashdrop.GetDropGroups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cashdrop.GetDropGroups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cashdrop.GetDropGroups}
 */
proto.cashdrop.GetDropGroups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cashdrop.GetDropGroups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cashdrop.GetDropGroups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cashdrop.GetDropGroups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cashdrop.GetDropGroups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.cashdrop.GetDropGroups.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.GetDropGroups} returns this
 */
proto.cashdrop.GetDropGroups.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.cashdrop.GetDropGroups.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cashdrop.GetDropGroups} returns this
 */
proto.cashdrop.GetDropGroups.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_disabled = 3;
 * @return {boolean}
 */
proto.cashdrop.GetDropGroups.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cashdrop.GetDropGroups} returns this
 */
proto.cashdrop.GetDropGroups.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.cashdrop);
