/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Button } from 'components';
import './CancelWithdrawalConfirm.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const CancelWithdrawalConfirm = observer(({ onConfirm }: any) => {
  const { t } = useTranslation();
  const { overlayStore } = useStores();

  const handleConfirm = () => {
    overlayStore.closeModal();
    onConfirm();
  };

  return (
    <div className="cancel-withdrawal">
      {/* <div>
        <p>{t('payments.cancel-withdrawal')}</p>
      </div> */}
      <div>
        <p className="cancel-withdrawal__header">
          {t('payments.cancel-withdrawal-question')}
        </p>
        <div className="cancel-withdrawal__content">
          <Button bg="grey" className="mr-8" onClick={overlayStore.closeModal}>
            <div className="d-flex align-items">
              <span className="text-regular text-bold">
                {t('globals.cancel')}
              </span>
            </div>
          </Button>

          <Button bg="green" onClick={handleConfirm}>
            <div className="d-flex align-items">
              <span className="text-regular text-bold">
                {t('verification.confirm')}
              </span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
});

export default CancelWithdrawalConfirm;
