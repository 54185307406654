/* React modules */
import React from 'react';

/* Our modules */
import i18n from 'app/localization/i18n';
import { CasinoErrors } from 'modules/gambling/gambling.types';
import useStores from 'common/hooks/useStores';
import Login from 'modules/auth/ui/Login/Login';
import GamblingCasinoModal from 'modules/gambling/ui/GamblingCasinoModal/GamblingCasinoModal';
import { logger } from 'libs/common-helpers';
import styles from 'components/PromoBanner/SinglePromo.module.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface SinglePromoProps {
  promo: any;
  isOpen: boolean;
  sidebarBanner?: boolean;
}

const SinglePromo = ({ promo, isOpen, sidebarBanner }: SinglePromoProps) => {
  const { authStore, overlayStore, gamblingStore, userStore } = useStores();

  const { t } = useTranslation();

  const setWidth = (isOpen: boolean): string => {
    return isOpen ? '90%' : '20%';
  };

  const playGame = () => {
    if (authStore.isLoggedIn) {
      startGame();
    } else {
      openLoginModal();
    }
  };

  const startGame = async () => {
    overlayStore.openModal(<GamblingCasinoModal />, {
      wrapClassName: 'gambling-casino-modal-wrap',
      gameName: promo.casino_game.game_name,
      onClose: () => userStore.getUserData(),
      width: 'full-screen',
    });
    try {
      await gamblingStore.getGameUrl(promo.casino_game.id, authStore.token);
    } catch (exception) {
      overlayStore.closeModal();
      handleCasinoError(exception);

      logger('SinglePromo -> startGame -> exception', exception);
    }
  };

  const openLoginModal = () => {
    const modalContent = (
      <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
    );

    overlayStore.openModal(modalContent, {
      className: 'login-modal',
      width: 'small',
    });
  };

  const handleCasinoError = (error: any) => {
    if (error?.data.detail === CasinoErrors.USER_BLOCKED) {
      toast.error(t('errors.user-blocked-error'));
    }
    if (error.status === 500) {
      toast.error(t('errors.error-starting-game'));
    }
  };

  if (sidebarBanner && promo.banner_type === 'no_action') {
    return (
      <a href="#" target="_self">
        <img
          src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
          alt=""
        />
      </a>
    );
  }

  if (sidebarBanner && promo.banner_type === 'external_link') {
    return (
      <a href={promo.link} target="_blank" rel="noreferrer">
        <img
          src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
          alt=""
        />
      </a>
    );
  }

  if (sidebarBanner && promo.banner_type === 'internal_link') {
    return (
      <a href={promo.link} target="_self">
        <img
          className="single-banner"
          src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
          alt=""
        />
      </a>
    );
  }

  if (sidebarBanner && promo.banner_type === 'casino_game') {
    return (
      <a onClick={playGame}>
        <img
          className="single-banner"
          src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
          alt=""
        />
      </a>
    );
  }

  return (
    <div className={styles['single-promo']} style={{ width: setWidth(isOpen) }}>
      {isOpen ? (
        <div className={styles['single-promo__content']}>
          <div className={styles['single-promo__image']}>
            {promo.banner_type === null && (
              <a href="#" target="_self">
                <img
                  src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
                  alt=""
                />
              </a>
            )}

            {promo.banner_type === 'no_action' && (
              <a href="#" target="_self">
                <img
                  src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
                  alt=""
                />
              </a>
            )}

            {promo.banner_type === 'external_link' && (
              <a href={promo.link} target="_blank" rel="noreferrer">
                <img
                  src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
                  alt=""
                />
              </a>
            )}

            {promo.banner_type === 'internal_link' && (
              <a href={promo.link} target="_self">
                <img
                  src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
                  alt=""
                />
              </a>
            )}

            {promo.banner_type === 'casino_game' && (
              // @ts-ignore
              <a onClick={playGame}>
                <img
                  src={process.env.REACT_APP_BANNER_URL + '/' + promo.img_url}
                  alt=""
                />
              </a>
            )}
          </div>
        </div>
      ) : (
        <div className={styles['single-promo__collapsed']}>
          {promo.banner_type === 'no_action' && (
            <a href="#" target="_self">
              {!!promo.promo_status_me && (
                <React.Fragment>
                  <div className={styles['single-promo__collapsed__status']}>
                    <span
                      className={
                        styles['single-promo__collapsed__status__text']
                      }
                    >
                      {i18n.language === 'me'
                        ? promo.promo_status_me
                        : promo.promo_status_en}
                    </span>
                  </div>
                  <div
                    className={`${styles['single-promo__collapsed__title']} text-left`}
                  >
                    <span>
                      {i18n.language === 'me'
                        ? promo.promo_text_me
                        : promo.promo_text_en}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </a>
          )}

          {promo.banner_type === 'external_link' && (
            <a href={promo.link} target="_blank" rel="noreferrer">
              {!!promo.promo_status_me && (
                <React.Fragment>
                  <div className={styles['single-promo__collapsed__status']}>
                    <span
                      className={
                        styles['single-promo__collapsed__status__text']
                      }
                    >
                      {i18n.language === 'me'
                        ? promo.promo_status_me
                        : promo.promo_status_en}
                    </span>
                  </div>
                  <div
                    className={`${styles['single-promo__collapsed__title']} text-left`}
                  >
                    <span>
                      {i18n.language === 'me'
                        ? promo.promo_text_me
                        : promo.promo_text_en}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </a>
          )}

          {promo.banner_type === 'internal_link' && (
            <a href={promo.link} target="_self">
              {!!promo.promo_status_me && (
                <React.Fragment>
                  <div className={styles['single-promo__collapsed__status']}>
                    <span
                      className={
                        styles['single-promo__collapsed__status__text']
                      }
                    >
                      {i18n.language === 'me'
                        ? promo.promo_status_me
                        : promo.promo_status_en}
                    </span>
                  </div>
                  <div
                    className={`${styles['single-promo__collapsed__title']} text-left`}
                  >
                    <span>
                      {i18n.language === 'me'
                        ? promo.promo_text_me
                        : promo.promo_text_en}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </a>
          )}

          {promo.banner_type === 'casino_game' && (
            <a onClick={playGame}>
              {!!promo.promo_status_me && (
                <React.Fragment>
                  <div className={styles['single-promo__collapsed__status']}>
                    <span
                      className={
                        styles['single-promo__collapsed__status__text']
                      }
                    >
                      {i18n.language === 'me'
                        ? promo.promo_status_me
                        : promo.promo_status_en}
                    </span>
                  </div>
                  <div
                    className={`${styles['single-promo__collapsed__title']} text-left`}
                  >
                    <span>
                      {i18n.language === 'me'
                        ? promo.promo_text_me
                        : promo.promo_text_en}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

SinglePromo.defaultProps = {
  sidebarBanner: false,
};

export default observer(SinglePromo);
