import React, { ReactNode, useEffect, useState } from 'react';

import { Button, Drawer } from 'components';

import './MobileFilter.scss';

interface MobileFilterProps {
  prefix: string;
  onClick: () => void;
  onClose: () => void;
  children: ReactNode;
  isDrawerOpen: boolean;
  dropdownContent: ReactNode;
  background?: any;
  buttonColor?: 'silver' | 'green';
  drawerPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

const MobileFilter = ({
  prefix,
  onClick,
  onClose,
  children,
  isDrawerOpen,
  dropdownContent,
  buttonColor,
  drawerPlacement,
}: MobileFilterProps) => {
  const [isOpen, toggleIsOpen] = useState<boolean>(true);

  useEffect(() => {
    toggleIsOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  return (
    <div className={`${prefix}-filter-mobile filter-mobile`}>
      <Button
        size="small"
        className={`${prefix}-filter-mobile__btn filter-mobile__btn`}
        onClick={onClick}
        bg={buttonColor}
        rounded={false}
      >
        {children}
      </Button>
      <Drawer
        placement={drawerPlacement}
        width="100%"
        height="100%"
        visible={isOpen}
        onClose={onClose}
      >
        <div className={`${prefix}-filter-mobile__body filter-mobile__body`}>
          {dropdownContent}
        </div>
      </Drawer>
    </div>
  );
};

MobileFilter.defaultProps = {
  background: 'transparent',
  buttonColor: 'green',
  drawerPlacement: 'top',
};

export { MobileFilter };
