/* React modules */

/* Our modules */
import {
  FEED_SOCKET_ENDPOINT,
  USER_LIVE_CHANNEL,
  USER_PREMATCH_CHANNEL,
  GUEST_LIVE_CHANNEL,
  GUEST_PREMATCH_CHANNEL,
} from 'modules/feed/feed-constants';
import authStore from 'modules/auth/auth.store';
import { getSocketUrl } from 'libs/urlBuilder';

/* 3rd Party modules */
import { Centrifuge } from 'centrifuge';

export enum EventMessageActions {
  CREATE = 'C',
  DELETE = 'D',
  ENABLE = 'EN',
  DISABLE = 'DI',
}

export enum FeedMessageType {
  EVENT = 'event',
  DISABLED_SPORT = 'disable_sport',
  DISABLED_ALL = 'disable_all',
  COMPETITION = 'competition',
  AMUSNET_JACKPOT = 'jackpot',
}

class FeedApi {
  private socket: Centrifuge | null = null;

  isFeedSocketInitialized() {
    return !!this.socket;
  }

  initFeedSocket() {
    if (!this.socket) {
      const socketUrl = getSocketUrl(FEED_SOCKET_ENDPOINT);
      this.socket = new Centrifuge(socketUrl);
    }

    if (this.socket) {
      this.socket.connect();
    }
  }

  connectFeedSocket(onMessage: (type: FeedMessageType, message: any) => void) {
    this.initFeedSocket();

    if (this.socket) {
      this.subscribeFeedSocketChannel(
        authStore.isLoggedIn ? USER_PREMATCH_CHANNEL : GUEST_PREMATCH_CHANNEL,
        onMessage
      );

      this.subscribeFeedSocketChannel(
        authStore.isLoggedIn ? USER_LIVE_CHANNEL : GUEST_LIVE_CHANNEL,
        onMessage
      );
    }
  }

  disconnectFeedSocket = () => {
    if (this.socket) {
      /*
      After calling this client will not try to reestablish connection periodically.
      You must call .connect() method manually again.
      (docs: https://github.com/centrifugal/centrifuge-js?tab=readme-ov-file#disconnect-method)
      */
      this.socket.disconnect();
    }
  };

  subscribeFeedSocketChannel(
    channel: string,
    onMessage: (type: FeedMessageType, message: any) => void
  ) {
    if (this.socket && channel) {
      let currentSubscription = this.socket.getSubscription(channel);

      if (!currentSubscription) {
        currentSubscription = this.socket.newSubscription(channel);
      }

      currentSubscription.subscribe();
      this.listen(currentSubscription, onMessage);
    }
  }

  unsubscribeFeedSocketChannel(channel: string) {
    if (this.socket && channel) {
      const currentSubscription = this.socket.getSubscription(channel);

      if (currentSubscription) {
        currentSubscription.unsubscribe();
        currentSubscription.removeAllListeners();
      }
    }
  }

  listen(
    subscription: any,
    onMessage: (t: FeedMessageType, message: any) => void
  ) {
    if (subscription) {
      subscription.on('publication', (ctx: any) => {
        if (ctx) {
          const { data } = ctx;

          if (data) {
            const { type, message } = data;

            onMessage(type, message);
          }
        }
      });

      subscription.on('disconnected', () => {
        subscription.disconnect();
      });
    }
  }
}

export default FeedApi;
