/* React modules */
import { useState, useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import overlayStore from 'libs/overlay-store';
import { Tabs } from 'components';
import Ticket from 'modules/ticket/ui/TicketSidebar/Ticket';
import Status from 'modules/ticket/ui/TicketSidebar/Status';
import SinglePromo from 'components/PromoBanner/SinglePromo/SinglePromo';
import { httpBanner } from 'app/axios-config';
import './TicketSidebar.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface TicketSidebarProps {
  isMobile?: boolean;
}

const TicketSidebar = ({ isMobile }: TicketSidebarProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const { ticketBuilder } = useStores();
  let eventsCount = ticketBuilder.slipEventsCount;

  const [sportSidebarBanners, setSportSidebarBanners] = useState<any>(null);

  const onTabClick = (key: string) => {
    setActiveTab(+key);
  };

  const fetchBanners = async () => {
    const { data } = await httpBanner.get(`web/data.json`);
    if (data) {
      setSportSidebarBanners(data.sport_slip_sidebar);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div className="ticket-sidebar" data-testid="ticket-sidebar">
      {isMobile && (
        <div className="ticket-sidebar__header">
          <span onClick={overlayStore.closeDrawer}>&times;</span>
        </div>
      )}
      <div className="ticket-sidebar__ticket-wrapper">
        <Tabs
          items={[
            {
              key: '1',
              label: `${t('slips.ticket')} ${
                eventsCount ? `(${eventsCount})` : ''
              }`,
              children: <Ticket />,
            },
            {
              key: '2',
              label: t('slips.status').toUpperCase(),
              children: <>{activeTab === 2 && <Status />}</>,
            },
          ]}
          animated={false}
          className="yellow-big"
          defaultActiveKey="1"
          onTabClick={onTabClick}
        />
      </div>
      <div className="ticket-sidebar__promo">
        {sportSidebarBanners?.map(
          (banner: any, index: number) =>
            banner && (
              <SinglePromo
                key={`TicketSidebar-SinglePromo-${index}`}
                promo={banner}
                isOpen={true}
                sidebarBanner={true}
              />
            )
        )}
      </div>
    </div>
  );
};

export default observer(TicketSidebar);
