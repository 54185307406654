import React, { ReactNode } from 'react';
import RcCheckbox from 'rc-checkbox';
import classNames from 'classnames';

import 'rc-checkbox/assets/index.css';
import './Checkbox.scss';
import Tooltip from 'rc-tooltip';

type LabelPosition = 'left' | 'right';

export interface CheckboxProps {
  label?: string | ReactNode;
  position?: LabelPosition;
  value: boolean | undefined;
  error?: string;
  onChange: ((e: any) => void) | undefined;
  tooltip?: string;
  indent?: boolean;
  textEllipsis?: boolean;
  className?: string;
  variant?: 'default' | 'gambling' | 'circle';
}

const Checkbox = ({
  label,
  position,
  value,
  error,
  className,
  onChange,
  indent,
  tooltip,
  textEllipsis,
  variant,
}: CheckboxProps) => {
  return (
    <div
      className={classNames(className, {
        checkbox: variant === 'default',
        'gambling-checkbox': variant === 'gambling',
        'circle-checkbox': variant === 'circle',
      })}
    >
      {tooltip ? (
        <Tooltip placement="right" overlay={tooltip} overlayClassName="tooltip">
          <label className="d-flex align-items">
            {position === 'left' && (
              <span
                className={classNames({
                  'text-ellipsis': textEllipsis,
                  'mr-1': !indent,
                  'mr-4': indent,
                })}
              >
                {label}
              </span>
            )}
            <RcCheckbox checked={value} onChange={onChange} />
            {position === 'right' && (
              <span
                className={classNames({
                  'text-ellipsis': textEllipsis,
                  'ml-1': !indent,
                  'ml-4': indent,
                })}
              >
                {label}
              </span>
            )}
          </label>
        </Tooltip>
      ) : (
        <label
          className={classNames('d-flex align-items', {
            'text-ellipsis': textEllipsis,
          })}
        >
          {position === 'left' && (
            <span
              className={classNames({
                'text-ellipsis': textEllipsis,
                'label-active': value,
                'mr-1': !indent,
                'mr-4': indent,
              })}
            >
              {label}
            </span>
          )}
          <RcCheckbox checked={value} onChange={onChange} />
          {position === 'right' && (
            <span
              className={classNames({
                'text-ellipsis': textEllipsis,
                'ml-1': !indent,
                'ml-4': indent,
              })}
            >
              {label}
            </span>
          )}
        </label>
      )}
      {error ? (
        <span className={`input__error m${position === 'right' ? 'l' : 'r'}-6`}>
          {error}
        </span>
      ) : null}
    </div>
  );
};

Checkbox.defaultProps = {
  position: 'right',
  label: '',
  tooltip: '',
  indent: false,
  textEllipsis: true,
  variant: 'default',
};

export default Checkbox;
