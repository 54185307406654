/* React modules */

/* Our modules */
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */

class ProtobufApi {
  private service: any;

  constructor(service: any) {
    this.service = service;
  }

  request<T>(method: any, args: any[]): Promise<T> {
    return new Promise((resolve, reject) => {
      this.service[method](...args, (error: any, response: any) => {
        if (error) {
          // TODO: Handle server errors
          logger('ProtobufApi -> request -> error', error);

          reject(error);
        } else {
          const data = response.toObject();

          resolve(data);
        }
      });
    });
  }

  stream(method: any, onData: any, onEnd: any, args: any[]): void {
    const streamObject = this.service[method](...args);

    streamObject.on('data', (data: any) => {
      onData(data);
    });

    streamObject.on('end', () => {
      onEnd();
    });
  }
}

export default ProtobufApi;
