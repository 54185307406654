/* React modules */
import { useMemo } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { TYPE_FILTER_OPTIONS } from 'modules/user/ui/UserTransactions/TransactionsFilter/TransactionsFilter';
import { SimpleTable } from 'components';
import Footer from 'components/Footer';
import { currencyWithoutSymbol } from 'libs/currency-formatter';
import { formatDate, formatTime, toDateObj } from 'libs/datetime';
import './TransactionsTable.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const TransactionsTable = () => {
  const { t } = useTranslation();
  const {
    userStore: { transactionFilters },
  } = useStores();
  const { transactions, loadNextPage, hasNext } = transactionFilters;

  const typeFilterOptions = TYPE_FILTER_OPTIONS(t);

  const renderTransactionDate = ({ value }: { value: string }) => {
    const dateISOString = value.endsWith('Z') ? value : `${value}Z`;
    const date = toDateObj(dateISOString);
    return `${formatDate(date)} ${formatTime(date)}`;
  };

  const renderTransactionType = ({ value }: any) => {
    return (
      typeFilterOptions.find((type: any) => type.value === value)?.label || null
    );
  };

  const renderAmount = ({ value }: any) => {
    if (!value && value !== 0) return '';

    return currencyWithoutSymbol(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      Header: t('wallet.wallet'),
      accessor: 'wallet_prefix',
      Cell: ({ value }: any) =>
        value === 'free_bet'
          ? 'PROMO'
          : value === 'promo_bet'
          ? (t('wallet.free-bet-bonus') || '').toUpperCase()
          : 'STANDARD',
    },
    {
      Header: t('payments.transaction-date'),
      accessor: 'created_at',
      Cell: renderTransactionDate,
    },
    {
      Header: t('payments.amount'),
      accessor: 'amount',
      Cell: renderAmount,
    },
    {
      Header: t('payments.balance-before'),
      accessor: 'balance_before',
      Cell: renderAmount,
    },
    {
      Header: t('payments.balance-after'),
      accessor: 'balance_after',
      Cell: renderAmount,
    },
    {
      Header: t('payments.transaction-type'),
      accessor: 'transaction_type',
      Cell: renderTransactionType,
    },
  ];

  const data = useMemo(() => transactions, [transactions]);

  return (
    <div className="transactions-table">
      <SimpleTable
        loadMore={loadNextPage}
        columns={columns}
        data={data}
        tableFooter={!hasNext ? Footer : null}
      />
    </div>
  );
};

export default observer(TransactionsTable);
