/* React modules */
import { ReactNode } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import overlayStore from 'libs/overlay-store';
import 'rc-dialog/assets/index.css';
import './Modal.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import Dialog from 'rc-dialog';

type ModalWidth = 'small' | 'medium' | 'large';

interface ModalProps {
  visible: boolean;
  children: ReactNode;
  closable: boolean;
  maskClosable: boolean;
  className?: string;
  width?: ModalWidth;
  wrapClassName?: string;
  style?: Object;
  title?: string | ReactNode;
  headerChildren?: ReactNode;
}

const Modal = observer(
  ({
    children,
    visible,
    closable,
    maskClosable,
    className,
    width,
    wrapClassName,
    style,
    title,
    headerChildren,
  }: ModalProps) => {
    const { loaderStore } = useStores();

    return (
      <Dialog
        className={`modal modal-${width} ${className}`}
        destroyOnClose={true}
        maskClosable={maskClosable}
        wrapClassName={wrapClassName}
        closable={loaderStore.isActive ? false : closable}
        visible={visible}
        onClose={() => overlayStore.closeModal()}
        style={style}
        bodyStyle={{ padding: 0 }}
      >
        {title && (
          <div className="modal__header">
            <div className="modal__title">{title}</div>
            {headerChildren}
          </div>
        )}
        {children}
      </Dialog>
    );
  }
);

/* Modal.defaultProps = {
  closable: true,
  maskClosable: true,
  className: '',
  title: undefined,
  headerChildren: null,
}; */

export { Modal };
