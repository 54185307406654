/* React modules */

/* Our modules */
import { CashDropWebServiceClient } from 'proto/loyalty/CashdropServiceClientPb';
import { Drop } from 'proto/loyalty/cashdrop_pb';
import ProtobufApi from 'libs/protobufApi';
import { getApiUrl } from 'libs/urlBuilder';

/* 3rd Party modules */
import Empty from 'google-protobuf/google/protobuf/empty_pb';

class CashDropApi {
  private client: CashDropWebServiceClient;
  private api: ProtobufApi;

  constructor() {
    this.client = new CashDropWebServiceClient(getApiUrl(), {}, {});
    this.api = new ProtobufApi(this.client);
  }

  getDailyDrops(accessToken: any): Promise<any> {
    const request = new Empty.Empty();
    return this.api.request('getDailyDrops', [request, { accessToken }]);
  }

  collect(dropId: number, accessToken: any): Promise<any> {
    const request = new Drop();
    request.setId(dropId);
    return this.api.request('collect', [request, { accessToken }]);
  }

  getCashDropStatus(accessToken: any): Promise<any> {
    const request = new Empty.Empty();
    return this.api.request('getCashDropGlobalDisabled', [
      request,
      { accessToken },
    ]);
  }
}

export default CashDropApi;
