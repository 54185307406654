/* React modules */

/* Our modules */
import { EventType } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import { SportMarket } from 'modules/sports/services/sports-markets.service';
import { Dropdown, Icon } from 'components';
import './MarketCell.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface MarketCellProps {
  market: SportMarket;
  sport: number;
  eventType: EventType;
  position: number;
}

const capitalize = (market: string) =>
  `${market.slice(0, 1).toUpperCase()}${market.slice(1)}`;

const MarketCell = ({
  market,
  sport,
  eventType,
  position,
}: MarketCellProps) => {
  const { i18n } = useTranslation();
  const { marketSwitcher } = useStores();

  const hiddenMarkets = marketSwitcher
    .getHiddenOnDesktop(sport, eventType)
    .map((m: any) => ({ label: m.getName(i18n.language), value: m.name }));

  const onMarketSwitch = (value: string) => {
    const selected = hiddenMarkets.find((m: any) => m.value === value);

    if (selected) {
      marketSwitcher.switchOnDesktop(
        sport,
        eventType,
        selected.value,
        position
      );
    }
  };

  return (
    <div className="w-100  pt-2 market-cell">
      {hiddenMarkets.length ? (
        <Dropdown
          handleSelect={onMarketSwitch}
          options={hiddenMarkets}
          className="market-cell__switcher"
        >
          <div className="market-cell__switcher-trigger">
            <div className="text-ellipsis">{market.getName(i18n.language)}</div>

            <Icon name="caretDown" size="extra-small" />
          </div>
        </Dropdown>
      ) : (
        capitalize(market.getName(i18n.language))
      )}
    </div>
  );
};

export default observer(MarketCell);
