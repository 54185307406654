/* React modules */

/* Our modules */
import EventMobile from 'modules/offer/ui/OfferMobile/EventMobile';

/* 3rd Party modules */
import { Virtuoso } from 'react-virtuoso';

export interface RenderEventsProps {
  events: any;
}

const RenderNoData = () => (
  <div className="offer-mobile__no-data">Nema događaja</div>
);

const RenderEvents = ({ events }: RenderEventsProps) => {
  if (!events.length) {
    return <RenderNoData />;
  }

  return (
    <div className="offer-mobile__render-events">
      <Virtuoso
        data={events}
        style={{ height: 'calc(100vh - 275px)', width: '100%' }}
        itemContent={(index, event) => (
          <EventMobile
            key={`RenderEvents-EventMobile-${event.id}`}
            event={event}
            events={events}
            rowIndex={index}
          />
        )}
      />
    </div>
  );
};

export default RenderEvents;
