/* React modules */

/* Our modules */
import { getTimestampFromDateString, getCurrentTimestamp } from 'libs/datetime';

/* 3rd Party modules */

const DAY_IN_SECONDS = 24 * 60 * 60;
const MONTH_IN_DAYS = 30;
const YEAR_IN_DAYS = MONTH_IN_DAYS * 12;

const getDaysLabel = (days: number) => {
  if (days === 0) return 'Danas';
  if (days === 1) return 'Juce';
  if (days === 21) return 'Pre 21 dan';
  return `Pre ${days} dana`;
};

const getMonthsLabel = (months: number) => {
  let suffix = 'meseci';

  if (months === 1) {
    suffix = 'mesec';
  } else if (months < 5) {
    suffix = 'meseca';
  }

  return `Pre ${months} ${suffix}`;
};

const getYearsLabel = (years: number) => {
  let suffix = 'godina';

  if (years === 1) {
    suffix = 'godinu';
  } else if (years < 5) {
    suffix = 'godine';
  }

  return `Pre ${years} ${suffix}`;
};

const getDiffInDays = (current: number, from: number) => {
  return Math.floor((current - from) / DAY_IN_SECONDS / 1000);
};

const calculateTimeSince = (diffInSeconds: number, intervalInDays: number) => {
  return Math.floor(diffInSeconds / intervalInDays);
};

const getLabel = (daySince: number) => {
  if (daySince > YEAR_IN_DAYS) {
    const yearsSince = calculateTimeSince(daySince, YEAR_IN_DAYS);
    return getYearsLabel(yearsSince);
  } else if (daySince > MONTH_IN_DAYS) {
    const monthsSince = calculateTimeSince(daySince, MONTH_IN_DAYS);
    return getMonthsLabel(monthsSince);
  }

  return getDaysLabel(daySince);
};

const timeAgo = (fromDate: string) => {
  const fromDateTimestamp = getTimestampFromDateString(fromDate);
  if (!fromDateTimestamp) return '';

  const currentTimestamp = getCurrentTimestamp();
  const daySince = getDiffInDays(currentTimestamp, fromDateTimestamp);

  return getLabel(daySince);
};

export default timeAgo;
