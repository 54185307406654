const days = ['Ned', 'Pon', 'Uto', 'Sre', 'Cet', 'Pet', 'Sub'];
const englishDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Maj',
  'Jun',
  'Jul',
  'Avg',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
];

export const engMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Avg',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
];

export const toDate = (seconds: number) => {
  return new Date(new Date(seconds * 1000).toUTCString());
};

export const getMinutes = (date: Date) => {
  return date.getMinutes().toLocaleString();
};

export const getHours = (date: Date) => {
  return date.getHours().toLocaleString();
};

export const getDayString = (date: Date, english: boolean = false) => {
  if (english) {
    return englishDays[date.getDay()];
  }

  return days[date.getDay()];
};

export const getMonthString = (date: Date) => {
  return months[date.getMonth()];
};

/**
 * Return current timestamp in seconds
 */
export const getCurrentTime = () => {
  return Math.floor(Date.now() / 1000);
};

export const getSeconds = (time: string): number => {
  return new Date(time).getTime() / 1000;
};

export const toDateObj = (date: string) => {
  return new Date(new Date(date).toUTCString());
};

export const getCurrentTimestamp = () => Date.now();

export const getTimestampFromDateString = (date: string) => {
  const timestamp = Date.parse(date);
  return !isNaN(timestamp) && timestamp;
};

export const formatDate = (date: Date, displayYear: boolean = true) => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return displayYear ? `${day}.${month}.${year}.` : `${day}.${month}.`;
};

export const formatDateQuery = (date: Date) => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const formatTime = (date: Date) => {
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minute =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${hour}:${minute}`;
};

export const get18YearsAgo = () => {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const formatDatePickerDate = (date: any) => {
  if (!date || !(date instanceof Date)) return null;
  return {
    day: date.getDay(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

export const setDateFromDatePicker = (value: any) => {
  if (value) {
    const date = new Date();
    date.setDate(value.day);
    date.setMonth(value.month - 1);
    date.setFullYear(value.year);
    return date;
  }
  return null;
};

export const getOneMonthAgo = () => {
  const date = new Date(); // Now
  let rawStartDate = date.setDate(date.getDate() - 30); // Set now - 30 days as the new date
  let rawEndDate = date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
  const startDate = new Date(rawStartDate);
  const endDate = new Date(rawEndDate);

  return { startDate, endDate };
};
