/* React modules */

/* Our modules */
import { Carousel, Icon } from 'components';
import SingleGame from 'modules/gambling/ui/SingleGame/SingleGame';
import { CasinoGameSize } from 'modules/gambling/gambling.types';
import './GamesCarousel.scss';

/* 3rd Party modules */
import classNames from 'classnames';

interface GamesCarouselProps {
  carouselConfig: object;
  gamesList: any[];
  refHandler?: (arg: any) => any;
  hasJackpot?: boolean;
  size: CasinoGameSize;
  hover: boolean;
  controls?: 'horizontal' | 'vertical';
  nextHandler?: () => void;
  previousHandler?: () => void;
  variant?: 'horizontal' | 'biggest-win' | 'most-played';
  displayName?: boolean;
  biggestWin?: boolean;
  displayAmount?: boolean;
}

const GamesCarousel = ({
  carouselConfig,
  gamesList,
  refHandler,
  hasJackpot,
  size,
  hover,
  controls,
  nextHandler,
  previousHandler,
  variant,
  displayName,
  displayAmount,
  biggestWin,
}: GamesCarouselProps) => (
  <div
    className={classNames('games-carousel', {
      horizontal: variant === 'horizontal',
      'biggest-win': variant === 'biggest-win',
      'most-played': variant === 'most-played',
    })}
  >
    {controls && (
      <div className="games-carousel__controls">
        <div className="arrow arrow__prev">
          <Icon
            container="gambling"
            size="small"
            onClick={previousHandler}
            name="caretLeft"
          />
        </div>
        <div className="arrow arrow__next">
          <Icon
            container="gambling"
            size="small"
            onClick={nextHandler}
            name="caretRight"
          />
        </div>
      </div>
    )}
    <Carousel sliderConfig={carouselConfig} refHandler={refHandler}>
      {gamesList?.map((game, index) => (
        <div
          key={`GamesCarousel-div-${index}`}
          className={`games-carousel__wrapper ${
            hasJackpot ? 'show-jackpot' : ''
          } ${size}`}
        >
          <SingleGame
            biggestWin={biggestWin}
            displayName={displayName}
            displayAmount={displayAmount}
            {...game}
            hover={hover}
            size={size}
          />
        </div>
      ))}
    </Carousel>
  </div>
);

GamesCarousel.defaultProps = {
  hasJackpot: false,
  size: 'small',
  displayName: false,
  biggestWin: false,
  displayAmount: false,
};

export { GamesCarousel };
