/* React modules */

/* Our modules */
import Spinner from 'components/Loader/Spinner';
import './Loader.scss';

/* 3rd Party modules */

interface LoaderProp {
  height?: string;
  width?: string;
}

const Loader = ({ height, width }: LoaderProp) => (
  <div
    className="loader"
    style={{ height: height, width: width ? width : '100%' }}
  >
    <Spinner size="regular"></Spinner>
  </div>
);

export default Loader;
