/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import i18n from 'app/localization/i18n';
import { ResetPassErrors } from 'modules/auth/auth.models';
import useStores from 'common/hooks/useStores';
import Input from 'components/Input';
import { Button, Icon } from 'components';
import Validators from 'libs/validations';
import useQuery from 'libs/useQuery';
import { logger } from 'libs/common-helpers';
import './NewPassword.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ERROR_MESSAGES: any = {
  [ResetPassErrors.CAN_NOT_FIND_USER]: i18n.t('errors.can-not-find-user'),
  [ResetPassErrors.TOKEN_EXPIRED]: i18n.t('errors.token-expired'),
};

const NewPassword = observer(() => {
  const { t } = useTranslation();
  const { authStore } = useStores();

  let queryParams = useQuery();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<any>({});

  const fieldValidations = {
    password: [
      Validators.required,
      Validators.minLength(6, t('errors.min-password')),
    ],
    confirmPassword: [
      Validators.required,
      Validators.match(t('errors.passwords-dont-match'), {
        value: password,
        confirmValue: confirmPassword,
      }),
    ],
  };

  const updateField = (key: string, checkboxId?: string) => (e: any) => {
    const value = e.target.value;
    switch (key) {
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
    }
    validateField(key)({ target: { value: value } });
  };

  useEffect(() => {
    if (password !== '') {
      setPassword(password);
      validateField('password')({ target: { value: password } });
      if (confirmPassword !== '') {
        validateField('confirmPassword')({
          target: { value: confirmPassword },
        });
      }
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword !== '') {
      setConfirmPassword(confirmPassword);
      validateField('confirmPassword')({ target: { value: confirmPassword } });
    }
  }, [confirmPassword]);

  const validateField = (key: string) => (e?: any) => {
    const validations = fieldValidations[key as keyof typeof fieldValidations];

    if (validations) {
      const error = (validations as Array<any>).find(
        (v: any) => v && !v.validate(e.target.value)
      );
      if (error) {
        setErrors({ ...errors, [key]: error.message });
      } else {
        setErrors({ ...errors, [key]: null });
      }
    }
  };

  const isSubmitDisabled =
    errors.confirmPassword ||
    confirmPassword === '' ||
    errors.password ||
    password === '';

  const submit = (data: object) => {
    const tokenFromURL = queryParams.get('token');

    if (data && tokenFromURL) {
      // TODO: this is current flow, it will be changed when backend finish restart password from email
      authStore
        .submitResetPassword(data, queryParams.get('token') || '')
        .then(() => {
          toast.success(t('verification.successfully-changed-password'));
        })
        .catch((error: any) => {
          logger('NewPassword -> submit -> error', error);

          if (error?.status === 404) {
            toast.error(ERROR_MESSAGES[ResetPassErrors.CAN_NOT_FIND_USER]);
          } else if (error?.status === 403) {
            toast.error(ERROR_MESSAGES[ResetPassErrors.TOKEN_EXPIRED]);
          }
        })
        .finally(() => {
          const { REACT_APP_FRONT_URL } = process.env;

          if (REACT_APP_FRONT_URL) {
            // navigate("/sport");
            window.location.replace(REACT_APP_FRONT_URL);
          }
        });
    }
  };

  return (
    <div className="new-password">
      <div className="new-password__form">
        <div className="">
          <img src={process.env.PUBLIC_URL + '/assets/logo.svg'} alt="SBBet" />
        </div>
        <div className="mt-4">
          <Input
            bg="grey"
            name="password"
            placeholder={t('userData.new-password')}
            height="large"
            value={password}
            error={errors.password}
            type="password"
            rounded={false}
            onChange={updateField('password')}
            onBlur={validateField('password')}
            addon={<Icon size="large" name="lockFill" />}
          />
        </div>
        <div className="mt-4">
          <Input
            bg="grey"
            name="confirmPassword"
            placeholder={t('validations.repeat-password')}
            height="large"
            value={confirmPassword}
            error={errors.confirmPassword}
            type="password"
            rounded={false}
            onChange={updateField('confirmPassword')}
            onBlur={validateField('confirmPassword')}
            addon={<Icon size="large" name="lockFill" />}
          />
        </div>
        <div className="d-flex justify-center align-items mt-4">
          <Button
            size="large"
            bg="green"
            disabled={isSubmitDisabled}
            className="w-100"
            onClick={() => submit({ password: password })}
          >
            {t('globals.submit')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default NewPassword;
