/* React modules */
import { useState } from 'react';

/* Our modules */
import { Icon } from 'components';
import Select from 'components/Select';
import { SelectOption } from 'components/Select/Select';
import CountryIcon from 'components/CountryIcon';
import './LanguageSelect.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

interface LanguageOptionTypes {
  label: string;
  country: string;
}

const LanguageOption = ({ label, country }: LanguageOptionTypes) => (
  <div className="language-option">
    <div className="visible-tablet-down">
      <CountryIcon original={true} size="regular" country={country} />
    </div>
    <span className="language-option__label ml-2 mr-2">{label}</span>
    <div className="visible-tablet-up">
      <CountryIcon original={true} size="regular" country={country} />
    </div>
  </div>
);

const mockLanguageOptions = [
  {
    value: 'me',
    label: <LanguageOption label="Crnogorski" country="me" />,
  },
  {
    value: 'en',
    label: <LanguageOption label="English" country="gb" />,
  },
  {
    value: 'al',
    label: <LanguageOption label="Shqipe" country="al" />,
  },
  {
    value: 'tr',
    label: <LanguageOption label="Türk" country="tr" />,
  },
  {
    value: 'ru',
    label: <LanguageOption label="Русский" country="ru" />,
  },
  {
    value: 'ua',
    label: <LanguageOption label="Українська" country="ua" />,
  },
  {
    value: 'it',
    label: <LanguageOption label="Italiana" country="it" />,
  },
  {
    value: 'de',
    label: <LanguageOption label="Deutsche" country="de" />,
  },
];

const LanguageSelect = () => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState<SelectOption | undefined>(
    mockLanguageOptions[0]
  );

  const onLanguageChange = (language: SelectOption) => {
    i18n.changeLanguage(language.value as string);
    setLanguage(language);
  };

  return (
    <div>
      <p className="w-80 mx-auto text-sb-extra-small mb-4 visible-desktop-down">
        {t('language.select-language')}
      </p>
      <Select
        size="sb-language"
        components={{
          DropdownIndicator: () => (
            <Icon
              name="caretDown"
              size="extra-small"
              className="mr-2 visible-desktop-down"
            />
          ),
          IndicatorSeparator: () => null,
        }}
        shape="squared"
        options={mockLanguageOptions}
        value={language}
        onChange={onLanguageChange}
      />
    </div>
  );
};

export default LanguageSelect;
