// source: proto/slip/web_slip.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.web_slip.BonusConfig', null, global);
goog.exportSymbol('proto.web_slip.CalculatedValues', null, global);
goog.exportSymbol('proto.web_slip.FastSlip', null, global);
goog.exportSymbol('proto.web_slip.FastSlips', null, global);
goog.exportSymbol('proto.web_slip.GetUserSlipsRequest', null, global);
goog.exportSymbol('proto.web_slip.PaginatedSlips', null, global);
goog.exportSymbol('proto.web_slip.Rules', null, global);
goog.exportSymbol('proto.web_slip.SlipId', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.BonusConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_slip.BonusConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.BonusConfig.displayName = 'proto.web_slip.BonusConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_slip.Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.Rules.displayName = 'proto.web_slip.Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.GetUserSlipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_slip.GetUserSlipsRequest.repeatedFields_, null);
};
goog.inherits(proto.web_slip.GetUserSlipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.GetUserSlipsRequest.displayName = 'proto.web_slip.GetUserSlipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.PaginatedSlips = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_slip.PaginatedSlips.repeatedFields_, null);
};
goog.inherits(proto.web_slip.PaginatedSlips, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.PaginatedSlips.displayName = 'proto.web_slip.PaginatedSlips';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.SlipId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_slip.SlipId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.SlipId.displayName = 'proto.web_slip.SlipId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.CalculatedValues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_slip.CalculatedValues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.CalculatedValues.displayName = 'proto.web_slip.CalculatedValues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.FastSlip = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web_slip.FastSlip, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.FastSlip.displayName = 'proto.web_slip.FastSlip';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web_slip.FastSlips = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web_slip.FastSlips.repeatedFields_, null);
};
goog.inherits(proto.web_slip.FastSlips, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web_slip.FastSlips.displayName = 'proto.web_slip.FastSlips';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.BonusConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.BonusConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.BonusConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.BonusConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusConfig: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.BonusConfig}
 */
proto.web_slip.BonusConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.BonusConfig;
  return proto.web_slip.BonusConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.BonusConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.BonusConfig}
 */
proto.web_slip.BonusConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.BonusConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.BonusConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.BonusConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.BonusConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusConfig();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string bonus_config = 1;
 * @return {string}
 */
proto.web_slip.BonusConfig.prototype.getBonusConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_slip.BonusConfig} returns this
 */
proto.web_slip.BonusConfig.prototype.setBonusConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    rules: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.Rules}
 */
proto.web_slip.Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.Rules;
  return proto.web_slip.Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.Rules}
 */
proto.web_slip.Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRules(value);
      break;
    case 4:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRules();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string rules = 1;
 * @return {string}
 */
proto.web_slip.Rules.prototype.getRules = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_slip.Rules} returns this
 */
proto.web_slip.Rules.prototype.setRules = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.Error error = 4;
 * @return {?proto.common.Error}
 */
proto.web_slip.Rules.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 4));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.web_slip.Rules} returns this
*/
proto.web_slip.Rules.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_slip.Rules} returns this
 */
proto.web_slip.Rules.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_slip.Rules.prototype.hasError = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_slip.GetUserSlipsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.GetUserSlipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.GetUserSlipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.GetUserSlipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.GetUserSlipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    shortUuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    perPage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.GetUserSlipsRequest}
 */
proto.web_slip.GetUserSlipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.GetUserSlipsRequest;
  return proto.web_slip.GetUserSlipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.GetUserSlipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.GetUserSlipsRequest}
 */
proto.web_slip.GetUserSlipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatuses(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortUuid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.GetUserSlipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.GetUserSlipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.GetUserSlipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.GetUserSlipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getShortUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.web_slip.GetUserSlipsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web_slip.GetUserSlipsRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
*/
proto.web_slip.GetUserSlipsRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_slip.GetUserSlipsRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web_slip.GetUserSlipsRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
*/
proto.web_slip.GetUserSlipsRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_slip.GetUserSlipsRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string statuses = 4;
 * @return {!Array<string>}
 */
proto.web_slip.GetUserSlipsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional string short_uuid = 5;
 * @return {string}
 */
proto.web_slip.GetUserSlipsRequest.prototype.getShortUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.setShortUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 per_page = 6;
 * @return {number}
 */
proto.web_slip.GetUserSlipsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.GetUserSlipsRequest} returns this
 */
proto.web_slip.GetUserSlipsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_slip.PaginatedSlips.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.PaginatedSlips.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.PaginatedSlips.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.PaginatedSlips} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.PaginatedSlips.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 2, 0),
    slipsList: jspb.Message.toObjectList(msg.getSlipsList(),
    proto_common_common_pb.Slip.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.PaginatedSlips}
 */
proto.web_slip.PaginatedSlips.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.PaginatedSlips;
  return proto.web_slip.PaginatedSlips.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.PaginatedSlips} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.PaginatedSlips}
 */
proto.web_slip.PaginatedSlips.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 3:
      var value = new proto_common_common_pb.Slip;
      reader.readMessage(value,proto_common_common_pb.Slip.deserializeBinaryFromReader);
      msg.addSlips(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.PaginatedSlips.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.PaginatedSlips.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.PaginatedSlips} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.PaginatedSlips.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSlipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_common_common_pb.Slip.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.web_slip.PaginatedSlips.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.PaginatedSlips} returns this
 */
proto.web_slip.PaginatedSlips.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_pages = 2;
 * @return {number}
 */
proto.web_slip.PaginatedSlips.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.PaginatedSlips} returns this
 */
proto.web_slip.PaginatedSlips.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated common.Slip slips = 3;
 * @return {!Array<!proto.common.Slip>}
 */
proto.web_slip.PaginatedSlips.prototype.getSlipsList = function() {
  return /** @type{!Array<!proto.common.Slip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_common_common_pb.Slip, 3));
};


/**
 * @param {!Array<!proto.common.Slip>} value
 * @return {!proto.web_slip.PaginatedSlips} returns this
*/
proto.web_slip.PaginatedSlips.prototype.setSlipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Slip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Slip}
 */
proto.web_slip.PaginatedSlips.prototype.addSlips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Slip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_slip.PaginatedSlips} returns this
 */
proto.web_slip.PaginatedSlips.prototype.clearSlipsList = function() {
  return this.setSlipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.SlipId.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.SlipId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.SlipId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.SlipId.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shortUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkCashout: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.SlipId}
 */
proto.web_slip.SlipId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.SlipId;
  return proto.web_slip.SlipId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.SlipId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.SlipId}
 */
proto.web_slip.SlipId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckCashout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.SlipId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.SlipId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.SlipId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.SlipId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShortUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckCashout();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string slip_id = 1;
 * @return {string}
 */
proto.web_slip.SlipId.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_slip.SlipId} returns this
 */
proto.web_slip.SlipId.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string short_uuid = 2;
 * @return {string}
 */
proto.web_slip.SlipId.prototype.getShortUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_slip.SlipId} returns this
 */
proto.web_slip.SlipId.prototype.setShortUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool check_cashout = 3;
 * @return {boolean}
 */
proto.web_slip.SlipId.prototype.getCheckCashout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web_slip.SlipId} returns this
 */
proto.web_slip.SlipId.prototype.setCheckCashout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.CalculatedValues.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.CalculatedValues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.CalculatedValues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.CalculatedValues.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfCombinations: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxOddsValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    minOddsValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxWinningAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    minWinningAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxBonusAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    minBonusAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    bonusPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.CalculatedValues}
 */
proto.web_slip.CalculatedValues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.CalculatedValues;
  return proto.web_slip.CalculatedValues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.CalculatedValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.CalculatedValues}
 */
proto.web_slip.CalculatedValues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfCombinations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxOddsValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinOddsValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxWinningAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinWinningAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxBonusAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinBonusAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBonusPercent(value);
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.CalculatedValues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.CalculatedValues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.CalculatedValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.CalculatedValues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfCombinations();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxOddsValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMinOddsValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxWinningAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMinWinningAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxBonusAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMinBonusAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBonusPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 number_of_combinations = 1;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getNumberOfCombinations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setNumberOfCombinations = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double max_odds_value = 2;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getMaxOddsValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setMaxOddsValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double min_odds_value = 3;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getMinOddsValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setMinOddsValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double max_winning_amount = 4;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getMaxWinningAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setMaxWinningAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double min_winning_amount = 5;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getMinWinningAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setMinWinningAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double max_bonus_amount = 6;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getMaxBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setMaxBonusAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double min_bonus_amount = 7;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getMinBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setMinBonusAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double bonus_percent = 8;
 * @return {number}
 */
proto.web_slip.CalculatedValues.prototype.getBonusPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.setBonusPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.web_slip.CalculatedValues.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.web_slip.CalculatedValues} returns this
*/
proto.web_slip.CalculatedValues.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_slip.CalculatedValues} returns this
 */
proto.web_slip.CalculatedValues.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_slip.CalculatedValues.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.FastSlip.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.FastSlip.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.FastSlip} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.FastSlip.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    request: (f = msg.getRequest()) && proto_common_common_pb.FastSlipRequest.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.FastSlip}
 */
proto.web_slip.FastSlip.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.FastSlip;
  return proto.web_slip.FastSlip.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.FastSlip} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.FastSlip}
 */
proto.web_slip.FastSlip.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto_common_common_pb.FastSlipRequest;
      reader.readMessage(value,proto_common_common_pb.FastSlipRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 4:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.FastSlip.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.FastSlip.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.FastSlip} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.FastSlip.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_common_common_pb.FastSlipRequest.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.web_slip.FastSlip.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web_slip.FastSlip} returns this
 */
proto.web_slip.FastSlip.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.web_slip.FastSlip.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web_slip.FastSlip} returns this
 */
proto.web_slip.FastSlip.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.FastSlipRequest request = 3;
 * @return {?proto.common.FastSlipRequest}
 */
proto.web_slip.FastSlip.prototype.getRequest = function() {
  return /** @type{?proto.common.FastSlipRequest} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.FastSlipRequest, 3));
};


/**
 * @param {?proto.common.FastSlipRequest|undefined} value
 * @return {!proto.web_slip.FastSlip} returns this
*/
proto.web_slip.FastSlip.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_slip.FastSlip} returns this
 */
proto.web_slip.FastSlip.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_slip.FastSlip.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.Error error = 4;
 * @return {?proto.common.Error}
 */
proto.web_slip.FastSlip.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 4));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.web_slip.FastSlip} returns this
*/
proto.web_slip.FastSlip.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web_slip.FastSlip} returns this
 */
proto.web_slip.FastSlip.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web_slip.FastSlip.prototype.hasError = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web_slip.FastSlips.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web_slip.FastSlips.prototype.toObject = function(opt_includeInstance) {
  return proto.web_slip.FastSlips.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web_slip.FastSlips} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.FastSlips.toObject = function(includeInstance, msg) {
  var f, obj = {
    fastSlipsList: jspb.Message.toObjectList(msg.getFastSlipsList(),
    proto.web_slip.FastSlip.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web_slip.FastSlips}
 */
proto.web_slip.FastSlips.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web_slip.FastSlips;
  return proto.web_slip.FastSlips.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web_slip.FastSlips} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web_slip.FastSlips}
 */
proto.web_slip.FastSlips.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web_slip.FastSlip;
      reader.readMessage(value,proto.web_slip.FastSlip.deserializeBinaryFromReader);
      msg.addFastSlips(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web_slip.FastSlips.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web_slip.FastSlips.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web_slip.FastSlips} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web_slip.FastSlips.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFastSlipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web_slip.FastSlip.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FastSlip fast_slips = 1;
 * @return {!Array<!proto.web_slip.FastSlip>}
 */
proto.web_slip.FastSlips.prototype.getFastSlipsList = function() {
  return /** @type{!Array<!proto.web_slip.FastSlip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web_slip.FastSlip, 1));
};


/**
 * @param {!Array<!proto.web_slip.FastSlip>} value
 * @return {!proto.web_slip.FastSlips} returns this
*/
proto.web_slip.FastSlips.prototype.setFastSlipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web_slip.FastSlip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web_slip.FastSlip}
 */
proto.web_slip.FastSlips.prototype.addFastSlips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web_slip.FastSlip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web_slip.FastSlips} returns this
 */
proto.web_slip.FastSlips.prototype.clearFastSlipsList = function() {
  return this.setFastSlipsList([]);
};


goog.object.extend(exports, proto.web_slip);
