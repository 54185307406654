/* React modules */
import { useState, ChangeEvent, useEffect } from 'react';

/* Our modules */
import i18n from 'app/localization/i18n';
import { ResetPassErrors } from 'modules/auth/auth.models';
import useStores from 'common/hooks/useStores';
import { Button, Input } from 'components';
import { isEmail } from 'libs/validations';
import { logger } from 'libs/common-helpers';
import './ResetPassword.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ERROR_MESSAGES: any = {
  [ResetPassErrors.CAN_NOT_FIND_USER]: i18n.t('errors.can-not-find-user'),
};

const ResetPassword = observer(() => {
  const { t } = useTranslation();

  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let error = '';
    if (email === '') {
      error = t('errors.field-required');
    } else if (typeof email === 'string' && !isEmail(email)) {
      error = t('errors.email-invalid');
    }
    setError(error);
  }, [email, t]);

  const { authStore } = useStores();

  const submit = (email: object) => {
    authStore
      .resetPassword(email)
      .then((resp: any) => {
        setEmailSent(true);
      })
      .catch((error: any) => {
        toast.error(ERROR_MESSAGES[ResetPassErrors.CAN_NOT_FIND_USER]);

        logger('ResetPassword -> submit -> error', error);
      });
  };

  return (
    <div className="reset-password">
      <div className="reset-password__header">
        <h3>{t('login.forgot-password-caps')}</h3>
      </div>
      <div className="reset-password__content">
        {!emailSent && (
          <>
            <span>{t('verification.enter-recovery-email')}</span>
            <Input
              bg="grey"
              name="email"
              placeholder="E-mail"
              height="regular"
              value={email}
              error={error}
              className="reset-password__input"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <div className="d-flex justify-center align-items mt-5 mb-5">
              <Button
                bg="green"
                size="large"
                onClick={() => submit({ email: email })}
                disabled={typeof email === 'string' && !isEmail(email)}
              >
                <span className="ml-2 text-regular text-bold">
                  {t('globals.send')}
                </span>
              </Button>
            </div>
          </>
        )}

        {emailSent && (
          <div className="text-center sb-heading text-green-200 mb-4">
            {t('verification.recovery-email-sent')}
          </div>
        )}
      </div>
    </div>
  );
});

export { ResetPassword };
