import React from 'react';
import { Circle } from 'rc-progress';

interface TimerProps {
  percent: number;
}

const Timer = ({ percent }: TimerProps) => {
  return <Circle percent={percent} strokeColor="#acf439" strokeWidth={9} />;
};

export default Timer;
