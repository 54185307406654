/* React modules */

/* Our modules */
import {
  GamblingFilter,
  GamblingFilterParams,
  CasinoSection,
} from 'modules/gambling/gambling.types';

/* 3rd Party modules */
import { makeAutoObservable, observable } from 'mobx';

class GamblingFilters {
  $gameTypeFilters: Set<string> = new Set();
  $systemFilters: Set<string> = new Set();
  $sectionFilters: Set<number> = new Set();
  $mobile: boolean = false;
  $search: string = '';
  $min: number = 0;
  $max: number = 0;

  $filters: any = {
    game_name: null,
    min_bet: null,
    max_bet: null,
  };

  constructor() {
    makeAutoObservable(
      this,
      {
        $gameTypeFilters: observable,
        $systemFilters: observable,
        $mobile: observable,
      },
      { deep: true }
    );
  }

  toggleGameTypeFilter(filter: GamblingFilter) {
    if (this.$gameTypeFilters.has(filter.name)) {
      this.$gameTypeFilters.delete(filter.name);
    } else {
      this.$gameTypeFilters.add(filter.name);
    }
  }

  toggleSystemFilter(filter: GamblingFilter) {
    if (this.$systemFilters.has(filter.value)) {
      this.$systemFilters.delete(filter.value);
    } else {
      this.$systemFilters.add(filter.value);
    }
  }

  addSystemFilter(filter: GamblingFilter) {
    this.$systemFilters.add(filter.value);
  }

  toggleSectionFilter(filter: CasinoSection) {
    if (this.$sectionFilters.has(filter.id)) {
      this.$sectionFilters.delete(filter.id);
    } else {
      this.$sectionFilters.clear();
      this.$sectionFilters.add(filter.id);
    }
  }

  setMobileFilter = (isMobile: boolean) => {
    this.$mobile = isMobile;
  };

  setSearchTerm = (searchTerm: string) => {
    this.$search = searchTerm;
  };

  setMax = (max: number) => {
    this.$max = max;
  };

  setMin = (min: number) => {
    this.$min = min;
  };

  setFilters = (filters: any) => {
    Object.keys(filters).forEach((key: string) => {
      this.$filters[key] = filters[key];
    });
  };

  clearFilters = () => {
    this.$gameTypeFilters.clear();
    this.$systemFilters.clear();
    this.$sectionFilters.clear();
  };

  getActiveFilters = () => {
    const activeFilters: any = {};

    Object.keys(this.$filters).forEach((key: string) => {
      if (!!this.$filters[key]) {
        activeFilters[key] = this.$filters[key];
      }
    });

    return activeFilters;
  };

  get activeFilters(): GamblingFilterParams {
    return {
      ...(this.$gameTypeFilters.size > 0 && {
        game_type: [...this.$gameTypeFilters],
      }),
      ...(this.$systemFilters.size > 0 && {
        provider: [...this.$systemFilters],
      }),
      ...(this.$sectionFilters.size > 0 && {
        tag_id: [...this.$sectionFilters],
      }),
      ...(this.$mobile && {
        mobile: this.$mobile,
      }),
      ...(!this.$mobile && {
        desktop: true,
      }),
      ...this.getActiveFilters(),
    };
  }

  get hasActiveFilters(): boolean {
    return !!(
      this.$gameTypeFilters.size > 0 ||
      this.$systemFilters.size > 0 ||
      this.$sectionFilters.size > 0 ||
      (this.$filters.game_name !== null && this.$filters.game_name.length) >
        2 ||
      this.$filters.min_bet > 0 ||
      this.$filters.max_bet > 0
    );
  }
}

export default GamblingFilters;
