const countryList = (t: any) => [
  { value: 'MNE', label: t('countries.montenegro') },
  { value: 'SRB', label: t('countries.serbia') },
  { value: 'SRB', label: t('countries.kosovo') },
  { value: 'ALB', label: t('countries.albania') },
  { value: 'HRV', label: t('countries.croatia') },
  { value: 'BIH', label: t('countries.bosnia-and-herzegovina') },
  { value: 'MKD', label: t('countries.republic-of-north-macedonia') },
  { value: 'RUS', label: t('countries.russia') },
  { value: 'UKR', label: t('countries.ukraine') },
  { value: 'TUR', label: t('countries.turkey') },
];

const montenegroCities = [
  { label: 'Podgorica', value: 'Podgorica' },
  { label: 'Bar', value: 'Bar' },
  { label: 'Berane', value: 'Berane' },
  { label: 'Bijelo Polje', value: 'Bijelo Polje' },
  { label: 'Budva', value: 'Budva' },
  { label: 'Cetinje', value: 'Cetinje' },
  { label: 'Danilovgrad', value: 'Danilovgrad' },
  { label: 'Herceg Novi', value: 'Herceg Novi' },
  { label: 'Kolašin', value: 'Kolašin' },
  { label: 'Kotor', value: 'Kotor' },
  { label: 'Mojkovac', value: 'Mojkovac' },
  { label: 'Nikšić', value: 'Nikšić' },
  { label: 'Pljevlja', value: 'Pljevlja' },
  { label: 'Rožaje', value: 'Rožaje' },
  { label: 'Tivat', value: 'Tivat' },
  { label: 'Ulcinj', value: 'Ulcinj' },
  { label: 'Žabljak', value: 'Žabljak' },
];

export { countryList, montenegroCities };
