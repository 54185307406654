/* React modules */

/* Our modules */
import { Sport, Location, Competition } from 'modules/sports/sports.types';
import Event from 'modules/sports/store/event.store';

/* 3rd Party modules */

export enum NODE_TYPE {
  SPORT,
  LOCATION,
  COMPETITION,
}

export type NodeType =
  | NODE_TYPE.SPORT
  | NODE_TYPE.LOCATION
  | NODE_TYPE.COMPETITION;

class SportNode {
  private type: NodeType;

  public children: any[] = [];
  public name: string;
  public id: number;

  constructor(node: Sport | Location | Competition, type: NodeType) {
    this.name = node.name;
    this.id = node.id;
    this.type = type;
  }

  get isLeaf(): boolean {
    return this.type === NODE_TYPE.COMPETITION;
  }

  getEventsCountPerType(filter?: any, eventType?: string): number {
    return this.getEvents((e) => e.type === eventType && (!filter || filter(e)))
      .length;
  }

  hasEvents(filter?: any): boolean {
    return this.getEventsCount(filter) > 0;
  }

  getEventsCount(filter?: any, eventTypes?: any[]): number {
    let count = 0;

    if (eventTypes?.includes('upcoming')) {
      count += this.getEventsCountPerType(filter, 'upcoming');
    }

    if (eventTypes?.includes('live')) {
      count += this.getEventsCountPerType(filter, 'live');
    }

    if (eventTypes?.includes('antepost')) {
      count += this.getEventsCountPerType(filter, 'antepost');
    }

    if (eventTypes?.includes('player')) {
      count += this.getEventsCountPerType(filter, 'player');
    }
    if (eventTypes?.includes('special')) {
      count += this.getEventsCountPerType(filter, 'special');
    }

    return count;
  }

  push(children: any[]) {
    this.children = [...this.children, ...children];
  }

  addEvent(event: Event) {
    if (this.isLeaf) {
      if (event.competitionId === this.id) {
        this.children.push(event);
      }
    } else {
      this.children.forEach((node: any) => {
        node.addEvent(event);
      });
    }
  }

  removeEvent(event: any) {
    if (event) {
      this.children = this.children.filter((e) => e.id !== event.id);
    }
  }

  getEvents(filter?: (e: Event) => boolean) {
    if (this.isLeaf) {
      return filter ? this.children.filter(filter) : this.children;
    } else {
      return this.children.reduce((events: Event[], node: any) => {
        return [...events, ...node.getEvents(filter)];
      }, []);
    }
  }

  getCompetitions() {
    if (this.isLeaf && this.children.length) {
      return [this];
    } else {
      return this.children
        .reduce((competitions: SportNode[], node: any) => {
          return [...competitions, ...node.getCompetitions()];
        }, [])
        .sort((a: any, b: any) => {
          return Number(b.isFavorite) - Number(a.isFavorite);
        })
        .sort((a: any, b: any) => {
          return a.order - b.order;
        })
        .sort((a: any, b: any) => {
          return a.locationOrder - b.locationOrder;
        });
    }
  }

  getLocations() {
    if (this.type !== NODE_TYPE.SPORT) return [];

    return this.children
      .sort((current: SportNode, next: SportNode) => {
        const currentIsFavorite = current.children.some(
          (competition: any) => competition.isFavorite
        );
        const nextIsFavorite = next.children.some(
          (competition: any) => competition.isFavorite
        );

        return Number(nextIsFavorite) - Number(currentIsFavorite);
      })
      .sort((current: SportNode, next: SportNode) => {
        const currentCompetitionOrder =
          current.children.slice().sort((c1, c2) => c1.order - c2.order)[0]
            ?.order || 0;
        const nextCompetitionOrder =
          next.children.slice().sort((c1, c2) => c1.order - c2.order)[0]
            ?.order || 0;

        return currentCompetitionOrder - nextCompetitionOrder;
      });
  }

  findNode(type: NodeType, id: number) {
    let result: any;

    if (this.id === id && this.type === type) {
      result = this;
    } else {
      for (let child of this.children) {
        if (child.id === id && child.type === type) result = child;
        if (!child.isLeaf) result = child.findNode(type, id);
        if (result) break;
      }
    }

    return result;
  }

  removeNode(id: number) {
    this.children = this.children.filter((c) => c.id !== id);
  }

  addNode = (node: SportNode) => {
    this.children.push(node);
  };
}

export default SportNode;
