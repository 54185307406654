/* React modules */
import { useEffect, useCallback } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Icon } from 'components';
import TicketStatus from 'modules/ticket/ui/TicketStatus';
import './TopWinningTicket.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const TopTicketRow = observer(({ ticket }: any) => {
  const { t } = useTranslation();
  const { overlayStore, ticketsStore } = useStores();

  const openPreview = useCallback(() => {
    ticketsStore.preview(ticket, { fromApi: true });
    overlayStore.openModal(<TicketStatus hideFooter={true} />, {
      className: 'visibe-tablet-down',
      wrapClassName: 'ticket-preview-modal-wrap',
      name: 'ticket-preview',
      width: 'large',
    });
  }, [overlayStore, ticketsStore, ticket]);

  return (
    <div className="top-ticket__row">
      <div>
        <div>
          <span>{t('slips.payin')} </span>
          <span>{ticket.bettingAmount} EUR</span>
        </div>
        <div>
          <span>{t('slips.payout')} </span>
          <span>{ticket.actualWinningAmount} EUR</span>
        </div>
      </div>
      <div onClick={openPreview}>
        <Icon size="small" name="magnifyingGlass" />
      </div>
    </div>
  );
});

const TopWinningTickets = () => {
  const { t } = useTranslation();
  const { ticketsStore } = useStores();
  const { topWinningTickets, getTopWinningTickets } = ticketsStore;

  useEffect(() => {
    getTopWinningTickets();
  }, [getTopWinningTickets]);

  return (
    <div className="top-ticket">
      <div className="top-ticket__title">{t('slips.top-tickets')}</div>

      {topWinningTickets.map((ticket: any) => (
        <TopTicketRow
          key={`TopWinningTickets-TopTicketRow-${ticket.shortUuid}`}
          ticket={ticket}
        />
      ))}
    </div>
  );
};

export default observer(TopWinningTickets);
