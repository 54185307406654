/* React modules */
import { createContext } from 'react';

/* Our modules */
import RootStore from 'app/root-store';

/* 3rd Party modules */

const storeContext = createContext({ ...new RootStore() });

export default storeContext;
