/* React modules */

/* Our modules */
import {
  PlaceBetPayload,
  SlipPayloadGroup,
  SlipPayloadGroupEvent,
  SlipPayloadOdd,
  TicketStatuses,
} from 'modules/ticket/ticket.types';
import { SLIP_FEED_SOCKET_ENDPOINT } from 'modules/ticket/ticket.constants';
import {
  SlipRequest,
  FastSlipRequest,
  SlipGroup,
  FastSlipGroup,
  SlipSubGroup,
  FastSlipSubGroup,
  FastOdd,
  Slip,
} from 'proto/common/common_pb';
import { WebSlipServiceClient } from 'proto/slip/Web_slipServiceClientPb';
import { GetUserSlipsRequest, SlipId } from 'proto/slip/web_slip_pb';
import ProtobufApi from 'libs/protobufApi';
import { getApiUrl, getSocketUrl } from 'libs/urlBuilder';
import { hasProperty, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import * as timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb';
import Empty from 'google-protobuf/google/protobuf/empty_pb';

class TicketApi {
  private client: WebSlipServiceClient;
  private api: ProtobufApi;
  socket: WebSocket | null = null;

  constructor() {
    this.client = new WebSlipServiceClient(getApiUrl(), {}, {});
    this.api = new ProtobufApi(this.client);
  }

  getTickets(
    pagination: { pageSize: number; page: number },
    status: TicketStatuses | TicketStatuses[],
    dateFilter?: [Date, Date],
    id?: string,
    accessToken: string | null = ''
  ): Promise<any> {
    const [start, end] = dateFilter || [];
    const request = new GetUserSlipsRequest();
    request.setStatusesList(status as string[]);
    request.setPage(pagination.page);
    request.setPerPage(pagination.pageSize);

    if (id) {
      request.setShortUuid(id);
    }

    if (start && end) {
      const date = new Date(end);
      const endDate = new Date(date.getTime() + 86400000); // + 1 day in ms

      request.setStart(this.getTimestamp(start));
      request.setEnd(this.getTimestamp(endDate));
    }

    return this.api.request('getUserSlipsWeb', [request, { accessToken }]);
  }

  getTimestamp(date: Date) {
    date.setHours(0, 0, 0, 0);

    const timestamp = new timestamp_pb.Timestamp();
    timestamp.setSeconds(
      (date.getTime() + date.getTimezoneOffset() * 60 * 1000) / 1000
    );
    return timestamp;
  }

  getTicket(id: string, accessToken: string | null) {
    const request = new SlipId();
    request.setShortUuid(id);

    return this.api.request<Slip.AsObject>('getSingleSlipWeb', [
      request,
      { accessToken },
    ]);
  }

  getSharedTicket(id: string) {
    const request = new SlipId();
    request.setSlipId(id);

    return this.api.request<Slip.AsObject>('getSharedSlip', [request, {}]);
  }

  getQuickTicket(id: string, accessToken: string | null): Promise<any> {
    const request = new SlipId();
    request.setShortUuid(id);
    return this.api.request('getFastSlipWeb', [request, { accessToken }]);
  }

  sendSlipForCashout(id: string, accessToken: string | null): Promise<any> {
    const request = new SlipId();
    request.setSlipId(id);
    return this.api.request('slipSendForCashOutWeb', [
      request,
      { accessToken },
    ]);
  }

  acceptCashout(id: string, accessToken: string | null): Promise<any> {
    const request = new SlipId();
    request.setSlipId(id);
    return this.api.request('slipCashOutUserAcceptWeb', [
      request,
      { accessToken },
    ]);
  }

  denyCashout(id: string, accessToken: string | null): Promise<any> {
    const request = new SlipId();
    request.setSlipId(id);
    return this.api.request('slipCashOutUserDenyWeb', [
      request,
      { accessToken },
    ]);
  }

  cancelCashout(id: string, accessToken: string | null): Promise<any> {
    const request = new SlipId();
    request.setSlipId(id);
    return this.api.request('slipCashOutUserCancel', [
      request,
      { accessToken },
    ]);
  }

  placeBet(
    ticket: PlaceBetPayload,
    accessToken: string,
    isLive: boolean = false
  ): Promise<any> {
    const request = new SlipRequest();
    const method = isLive ? 'placeBetLiveWeb' : 'placeBetWeb';

    const { slip_groups, wallet, wallet_prefix } = ticket;

    const slipGroupsList: any = [];
    slip_groups.forEach((group: SlipPayloadGroup) => {
      const slipGroup = new SlipGroup();
      const subGroups: any = [];
      group.events.forEach((group: SlipPayloadGroupEvent) => {
        const sub = new SlipSubGroup();
        const odds = group.odds.map((odd: SlipPayloadOdd) => odd.id);
        sub.setType(`1/${odds.length}`);
        sub.setOddsList(odds);
        sub.setEventId(group.event_id);
        subGroups.push(sub);
      });
      slipGroup.setSubGroupsList(subGroups);
      slipGroup.setType(group.system);
      slipGroupsList.push(slipGroup);
    });
    request.setSlipGroupsList(slipGroupsList);
    // parse to Int and set amount
    request.setAmount(ticket.amount * 1);

    request.setWalletPrefix(wallet || wallet_prefix || '');

    if (hasProperty(ticket, 'shouldAcceptChanges')) {
      request.setNoLiveChanges(!ticket.shouldAcceptChanges);
    }

    return this.api.request<any>(method, [request, { accessToken }]);
  }

  calculateSlipValues(ticket: any, accessToken: string | null): Promise<any> {
    const request = new SlipRequest();

    const { slip_groups } = ticket;
    const slipGroupsList: any = [];
    slip_groups.forEach((group: SlipPayloadGroup) => {
      const slipGroup = new SlipGroup();
      const subGroups: any = [];
      group.events.forEach((group: SlipPayloadGroupEvent) => {
        const sub = new SlipSubGroup();
        const odds = group.odds.map((odd: SlipPayloadOdd) => +odd.id);
        sub.setType(`1/${odds.length}`);
        sub.setEventId(group.event_id);
        sub.setOddValuesList(odds);
        subGroups.push(sub);
      });
      slipGroup.setSubGroupsList(subGroups);
      slipGroup.setType(group.system);
      slipGroupsList.push(slipGroup);
    });
    request.setSlipGroupsList(slipGroupsList);
    request.setAmount(ticket.amount * 1);
    return this.api.request<any>('calculateSlipValuesWeb', [
      request,
      { accessToken },
    ]);
  }

  getQuickCode(ticket: any, accessToken: string | null): Promise<any> {
    const request = new FastSlipRequest();
    const method = accessToken ? 'createFastSlipUser' : 'createFastSlip';

    const { slip_groups } = ticket;
    const slipGroupsList: any = [];
    slip_groups.forEach((group: SlipPayloadGroup) => {
      const slipGroup = new FastSlipGroup();
      const subGroups: any = [];

      group.events.forEach((group: SlipPayloadGroupEvent) => {
        const sub = new FastSlipSubGroup();
        const slipOdds: any = [];

        group.odds.forEach((odd: any) => {
          const slipOdd = new FastOdd();
          slipOdd.setOddId(odd.id);
          slipOdd.setEventId(group.event_id);
          slipOdds.push(slipOdd);
        });

        sub.setType(`1/${slipOdds.length}`);
        sub.setOddsList(slipOdds);
        subGroups.push(sub);
      });
      slipGroup.setSubGroupsList(subGroups);
      slipGroup.setType(group.system);
      slipGroupsList.push(slipGroup);
    });
    request.setSlipGroupsList(slipGroupsList);
    // parse to Int and set amount
    request.setAmount(ticket.amount * 1);

    const options = accessToken ? { accessToken } : {};

    return this.api.request<any>(method, [request, options]);
  }

  getUserQuickCodes(accessToken: string) {
    const request = new Empty.Empty();
    return this.api.request<any>('getFastSlipsForUser', [
      request,
      { accessToken },
    ]);
  }

  getValidationRules() {
    const request = new Empty.Empty();
    return this.api.request<any>('getValidationRulesWeb', [request, {}]);
  }

  getTopWinningTickets() {
    const request = new Empty.Empty();
    return this.api.request<any>('getTopWinningSlips', [request, {}]);
  }

  getBonusConfig() {
    const request = new Empty.Empty();
    return this.api.request<any>('getBonusConfig', [request, {}]);
  }

  acceptTicketChanges(slipId: string, accessToken: string) {
    const request = new SlipId();
    request.setSlipId(slipId);
    return this.api.request('manualChangeUserAcceptWeb', [
      request,
      { accessToken },
    ]);
  }

  denyTicketChanges(slipId: string, accessToken: string) {
    const request = new SlipId();
    request.setSlipId(slipId);
    return this.api.request('manualChangeUserDenyWeb', [
      request,
      { accessToken },
    ]);
  }

  listenForApprovalChanges(
    token: string | null,
    onApprovalStatusChange: (status: string, slipId: string) => void
  ) {
    if (!token) {
      console.error('Cannot open socket because token is not provided');
    }

    const url = getSocketUrl(`${SLIP_FEED_SOCKET_ENDPOINT}/${token}/`);
    this.socket = new WebSocket(url);

    this.socket.addEventListener('message', ({ data }: any) => {
      const parsedData = parseJSONString(data);

      if (parsedData) {
        const { type, message } = parsedData;

        if (type && message && message.id) {
          onApprovalStatusChange(type, message.id);
        }
      }
    });
  }

  closeSocket() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export default TicketApi;
