/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { EventsFilters, EVENT_FILTER } from 'modules/offer/store/offer.filters';
import { getEventsCountPerType } from 'modules/offer/services/offer-helpers.service';
import './EventFilter.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface EventFilterProps {
  filters: EVENT_FILTER[];
  selected: EVENT_FILTER;
  onChange: (filter: EVENT_FILTER) => void;
  eventsCount: any;
}

const activeTypeColors: any = {
  [EventsFilters.LIVE]: 'green',
  [EventsFilters.UPCOMING]: 'yellow',
  [EventsFilters.PLAYER]: 'blue',
  [EventsFilters.ANTEPOST]: 'pink',
  default: 'green',
};

const getTypeColorClass = (type: EVENT_FILTER) => {
  return activeTypeColors[type] || activeTypeColors.default;
};

const EventFilter = observer(
  ({ filters, selected, onChange, eventsCount }: EventFilterProps) => {
    const { t } = useTranslation();
    const { sportsStore, offerFilters } = useStores();
    const { offerCounters, allEventsLoaded } = sportsStore;

    const [streamCountersLoaded, setStreamCounterLoaded] = useState(false);

    useEffect(() => {
      if (sportsStore.offerCounters.size === 0) return;
      else setStreamCounterLoaded(true);
    }, [sportsStore.offerCounters.size]);

    const renderEventsCount = (
      activeSport: any,
      offerCounters: any,
      timeFilter: any,
      filter: any
    ) => {
      if (allEventsLoaded) {
        return eventsCount[filter];
      }

      return getEventsCountPerType(
        activeSport,
        offerCounters,
        timeFilter,
        filter
      );
    };

    return (
      <ul className="event-filter">
        {filters.map((filter) => (
          <li
            key={`EventFilter-li-${filter}`}
            className={classNames(
              'pointer',
              'event-filter__choice',
              getTypeColorClass(filter),
              'd-flex',
              'align-items',
              {
                'event-filter__active': filter === selected,
              }
            )}
            onClick={() => onChange(filter)}
          >
            <span>{t(`sportsPage.${filter}`)}</span>
            <span className="ml-1 event-filter__count">
              {streamCountersLoaded &&
                renderEventsCount(
                  offerFilters.activeSports[0],
                  offerCounters,
                  offerFilters.timeFilter,
                  filter
                )}
              {/*{ renderEventsCount(offerFilters.activeSports[0], offerCounters, offerFilters.timeFilter, filter) }*/}
            </span>
          </li>
        ))}
      </ul>
    );
  }
);

export default EventFilter;
