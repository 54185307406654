/* React modules */

/* Our modules */
import Button from 'components/Button';
import './CookiesBanner.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

interface CookiesProps {
  onClick: () => void;
}

const CookiesBanner = ({ onClick }: CookiesProps) => {
  const { t } = useTranslation();

  return (
    <div className="cookies-banner">
      <div>
        <p className="cookies-banner__description">
          {t('globals.accept-cookies')}
        </p>
        <Button
          className="cookies-banner__btn"
          size="regular"
          bg="warning"
          onClick={onClick}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default CookiesBanner;
