/* React modules */
import { Fragment } from 'react';

/* Our modules */
import i18n from 'app/localization/i18n';
import { EVENT_TYPE, OddStringStatuses } from 'modules/sports/sports.types';
import { TicketStatus } from 'modules/ticket/ticket.types';
import { getMarketNameTranslationKey } from 'modules/offer/services/offer-helpers.service';
import CountryIcon, { COUNTRY_ICONS } from 'components/CountryIcon';
import { Icon } from 'components';
import { SPORT_ICONS } from 'components/Icon/Icon';
import Result from 'modules/offer/ui/Offer/Result';
import { formatDate, formatTime, getDayString, toDate } from 'libs/datetime';
import useWindowSize from 'libs/viewport';
import DefaultLocationIcon from 'images/defaultLocationIcon.svg';
import './TicketStatusBody.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const EventTimeCell = ({ start, liveTime }: any) => {
  const { width } = useWindowSize();
  const dateObj = toDate(start.seconds);
  const [date, dateMobile, time] = [
    formatDate(dateObj),
    formatDate(dateObj, false),
    formatTime(dateObj),
  ];
  const mobileBreakpoint = 768;

  return (
    <div className="d-flex">
      <div className="d-flex flex-column text-left">
        {liveTime ? (
          <div className="text-green-600 text-strong d-flex justify-center">
            {liveTime}
          </div>
        ) : (
          <>
            {width < mobileBreakpoint && <span>{getDayString(dateObj)}</span>}
            <span>{time}h</span>
            <span>{width < mobileBreakpoint ? dateMobile : date}</span>
          </>
        )}
      </div>
    </div>
  );
};

const SportIconCell = ({ sport }: any) => {
  const iconName = SPORT_ICONS[sport] as any;

  return (
    <div className="d-flex px-1">
      <div className="mr-2 d-flex align-items">
        {iconName && <Icon size="large" name={SPORT_ICONS[sport] as any} />}
      </div>
    </div>
  );
};

const CompetitionCell = ({ event }: any) => {
  return (
    <div title={event.competitionName} className="competition-cell">
      {COUNTRY_ICONS[event.locationName] ? (
        <CountryIcon
          original
          size="extra-large"
          country={COUNTRY_ICONS[event.locationName]}
        />
      ) : (
        <img alt={event.locationName} src={DefaultLocationIcon} />
      )}
      <div className="text-small">{event.competitionName?.slice(0, 3)}</div>
    </div>
  );
};

const EventResultCell = observer(({ event }: any) => {
  const displayFullName = [
    EVENT_TYPE.ANTEPOST,
    EVENT_TYPE.PLAYER,
    EVENT_TYPE.SPECIAL,
  ].includes(event.type);

  const [home, away] = event.name.split(' - ');
  const { suspended } = event;

  return (
    <div className="d-flex">
      <div className="ticket-status-body__event-result text-ellipsis">
        <div title={home} className="mb-1 w-100 text-ellipsis">
          {displayFullName ? event.name : home}
        </div>
        <div title={away} className="w-100 text-ellipsis">
          {!displayFullName && away}
        </div>
      </div>

      {suspended && <Icon className="ml-2" name="lock" />}
    </div>
  );
});

const LiveResultCell = ({ event }: any) => {
  return (
    <Result
      current={event?.currentResult}
      periods={event?.periodsShort || []}
    />
  );
};

const OutcomeCell = observer(
  ({
    odd,
    market,
    outcome,
    highlightChanges,
    ticketStatus,
    placeBetResult,
    frameNumber,
  }: any) => {
    const { displayValue, limit, oldLimit, oldValue, status } = odd;

    const classes = {
      'text-green-600': highlightChanges && !!oldLimit && oldLimit < limit,
      'text-red-400': highlightChanges && !!oldLimit && oldLimit > limit,
    };

    const oddValueClasses = {
      'text-red-400':
        (+oldValue !== 0 && +displayValue < +oldValue) ||
        status === OddStringStatuses.FAILED,
      'text-green-600':
        status === OddStringStatuses.PASSED ||
        (+oldValue !== 0 && +displayValue > +oldValue),
    };

    return (
      <div className="ticket-status-body__event-result--row text-yellow">
        <div className="text-capitalize ticket-status-body__market">
          {market} {odd.livePlayer ? ' - ' : ''} {odd.livePlayer}
        </div>
        {placeBetResult && (
          <div className="text-capitalize ticket-status-body__market">
            {placeBetResult}
          </div>
        )}
        {frameNumber > 0 && status !== TicketStatus.UNCONFIRMED && (
          <div className="text-capitalize ticket-status-body__market">
            {frameNumber}
          </div>
        )}
        <div className="text-strong ticket-status-body__outcome">
          {outcome}
          {!!limit && (
            <span className={classNames(classes)}>&nbsp; | {limit}</span>
          )}
        </div>
        <span
          className={classNames(
            'text-strong ticket-status-body__display-value',
            oddValueClasses
          )}
        >
          {displayValue}
          {(!odd.visible || odd.isDisabled) &&
            ticketStatus === TicketStatus.UNCONFIRMED && (
              <Icon
                variant="warning"
                className="mr-2"
                name="lock"
                size="small"
              />
            )}
        </span>
      </div>
    );
  }
);

const displayResultHeader = (period: any) => {
  const { homeScore, awayScore } = period;
  return (!!homeScore || homeScore === 0) && (!!awayScore || awayScore === 0);
};

const LiveResultHeader = ({ periods }: any) => {
  const { width } = useWindowSize();
  const mobileBreakpoint = 768;

  return (
    <div
      className={classNames('ticket-status-body__table-col flex-start', {
        'flex-2': width < mobileBreakpoint,
        'flex-3': width > mobileBreakpoint,
      })}
    >
      <div className="justify-end w-100 mr-1 d-flex">
        {periods?.map((period: any, index: number) =>
          displayResultHeader(period) ? (
            <div
              key={`LiveResultHeader-div-${index}`}
              className={classNames('d-flex result-cell', {
                'text-yellow': index === 0,
                'text-green-600': index !== 0,
              })}
            >
              {period.name}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

const MobileEvent = ({ event, status }: any) => {
  const { i18n } = useTranslation();
  const eventOdd = event.selectedOdds[0];

  return (
    <div className="flex-column ticket-status-body__event-data">
      <div className="ticket-status-body__table__header">
        <div className="ticket-status-body__table-col flex-1">
          {i18n.t('globals.time')}
        </div>
        <div className="ticket-status-body__table-col flex-1">
          <span
            title={i18n.t('sportsPage.competition')}
            className="text-ellipsis"
          >
            {i18n.t('sportsPage.competition')}
          </span>
        </div>
        <div className="ticket-status-body__table-col flex-start flex-3">
          {i18n.t('sportsPage.event')}
        </div>
        <LiveResultHeader
          periods={[
            ...(eventOdd.event?.currentResult || []),
            ...(eventOdd.event?.periodsShort || []),
          ]}
        />
      </div>

      <div className="d-flex ticket-status-body__table__body">
        <div className="ticket-status-body__table-col flex-1">
          <EventTimeCell
            event={event}
            start={event.start}
            liveTime={eventOdd.event?.eventTime}
          />
        </div>
        <div className="ticket-status-body__table-col flex-1">
          <CompetitionCell event={event} />
        </div>

        <div className="ticket-status-body__table-col w-100 flex-start flex-3">
          <EventResultCell event={event} />
        </div>

        <div className="ticket-status-body__table-col flex-end flex-2">
          <LiveResultCell event={eventOdd.event} />
        </div>
      </div>

      <div className="ticket-status-body__event-data">
        <div className="flex-2"></div>

        <div className="flex-5">
          <OutcomeCell
            odd={eventOdd}
            highlightChanges={status === TicketStatus.MANUAL_CHANGED}
            outcome={eventOdd.outcomeDisplay}
            market={
              eventOdd &&
              eventOdd.market &&
              getMarketNameTranslationKey(i18n.language)
                ? eventOdd.market[getMarketNameTranslationKey(i18n.language)]
                : ''
            }
            ticketStatus={status}
            placeBetResult={event?.odd?.currentResult}
            frameNumber={event?.odd?.frameNumber}
          />
        </div>
      </div>
    </div>
  );
};

const SlipGroup = observer(({ events, status }: any) => {
  const { i18n } = useTranslation();

  const renderBody = () =>
    events.map((event: any, index: number) => (
      <Fragment key={`renderBody-Fragment-1-${event.id}`}>
        {event.selectedOdds.map((odd: any, i: number) => (
          <Fragment key={`renderBody-Fragment-2-${i}`}>
            {i === 0 && (
              <>
                <div className="ticket-status-body__table__header">
                  <div className="ticket-status-body__table-col flex-1">#</div>
                  <div className="ticket-status-body__table-col time-cell flex-start flex-2">
                    {i18n.t('globals.time')}
                  </div>
                  <div className="ticket-status-body__table-col"></div>
                  <div className="ticket-status-body__table-col flex-1">
                    <span
                      title={i18n.t('sportsPage.competition')}
                      className="text-ellipsis"
                    >
                      {i18n.t('sportsPage.competition')}
                    </span>
                  </div>
                  <div className="ticket-status-body__table-col flex-1"></div>
                  <div className="ticket-status-body__table-col flex-start flex-7">
                    {i18n.t('sportsPage.event')}
                  </div>

                  <LiveResultHeader
                    key={`renderBody-LiveResultHeader-${i}`}
                    periods={[
                      ...(odd.event?.currentResult || []),
                      ...(odd.event?.periodsShort || []),
                    ]}
                  />
                </div>

                <div className="ticket-status-body__event-data">
                  <div className="ticket-status-body__table-col flex-1">
                    {i === 0 && <div>{index + 1}</div>}
                  </div>

                  <div className="ticket-status-body__table-col time-cell flex-2">
                    {i === 0 && (
                      <EventTimeCell
                        liveTime={odd.event?.liveTime}
                        start={event.start}
                      />
                    )}
                  </div>

                  <div className="ticket-status-body__table-col flex-1">
                    {i === 0 && <CompetitionCell event={event} />}
                  </div>

                  <div className="ticket-status-body__table-col flex-1">
                    {i === 0 && (
                      <SportIconCell sport={event.sportName?.toLowerCase()} />
                    )}
                  </div>

                  <div className="ticket-status-body__table-col flex-start visible-mobile-up flex-7">
                    {i === 0 && (
                      <EventResultCell
                        shortResult={odd.event?.currentResult}
                        event={event}
                        home={odd.event?.home}
                        away={odd.event?.away}
                      />
                    )}
                  </div>

                  <div className="ticket-status-body__table-col flex-end flex-3">
                    {i === 0 && <LiveResultCell event={odd.event} />}
                  </div>
                </div>
              </>
            )}

            <div className="ticket-status-body__event-data">
              <div className="flex-5">{/* Fill space */}</div>

              <div className="flex-10">
                <OutcomeCell
                  odd={odd}
                  highlightChanges={status === TicketStatus.MANUAL_CHANGED}
                  outcome={odd.outcomeDisplay}
                  market={odd.market?.getName(i18n.language)}
                  ticketStatus={status}
                  placeBetResult={event?.odd?.currentResult}
                  frameNumber={event?.odd?.frameNumber}
                />
              </div>
            </div>
          </Fragment>
        ))}
      </Fragment>
    ));

  return (
    <>
      <div className="visible-mobile-up ticket-status-body__table">
        <div className="ticket-status-body__table__body">{renderBody()}</div>
      </div>

      <div className="visible-mobile-down ticket-status-body__table">
        {events.map((event: any, index: number) => (
          <MobileEvent
            key={`SlipGroup-MobileEvent-${index}`}
            status={status}
            event={event}
          />
        ))}
      </div>
    </>
  );
});

const TicketStatusBody = ({ status, isFix, systemGroups }: any) => {
  const renderGroups = () => {
    return systemGroups.map((group: any, i: number) => {
      if (!group.events.length) return null;

      return (
        <Fragment key={`renderGroups-Fragment-${i}`}>
          {!isFix && (
            <div className="text-strong ml-3 my-2">
              {i18n.t('slips.system')} {group.system}
            </div>
          )}
          <SlipGroup events={group.events} status={status} />
        </Fragment>
      );
    });
  };

  return (
    <div className="ticket-status-body">
      <div className="flex-column">{renderGroups()}</div>
    </div>
  );
};

export default observer(TicketStatusBody);
