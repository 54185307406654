export const VALIDATION_REGEX = [
  {
    regex: new RegExp(/^ERR_USER_NOT_VALIDATED$/),
    messageKey: 'errors.ERR_USER_NOT_VALIDATED',
  },
  {
    regex: new RegExp(/^ERR_BETTING_BLOCKED_FOR_USER$/),
    messageKey: 'errors.user-blocked-error',
  },
  {
    regex: new RegExp(/^ERR_BETTING_IS_DISABLED_ON_EVENT:(?<a>.*)$/),
    messageKey: 'validations.event-disabled',
  },
  {
    regex: new RegExp(/^ERR_BETTING_IS_DISABLED_ON_ODD:(?<a>.*)$/),
    messageKey: 'validations.odd-disabled',
  },
  {
    regex: new RegExp(/^ERR_NO_LIVE_ODDS_CHANGE$/),
    messageKey: 'validations.live-odds-change',
  },
  {
    regex: new RegExp(/^ERR_MAX_BET_AMOUNT_IS_LIMITED_TO:(?<a>.*)$/),
    messageKey: 'validations.max-bet-amount',
  },
  {
    regex: new RegExp(/^ERR_NOT_ENOUGH_FUNDS$/),
    messageKey: 'validations.validation-balance',
  },
  {
    regex: new RegExp(/^ERR_UNVALIDATED_CANNOT_FREE_BET$/),
    messageKey: 'validations.free-bet-unvalidated',
  },
  {
    regex: new RegExp(/^VALIDATION_MIN_PAYMENT$/),
    messageKey: 'validations.validation-min-payment',
  },
  {
    regex: new RegExp(/^VALIDATION_MAX_PAYMENT$/),
    messageKey: 'validations.validation-max-payment',
  },
  {
    regex: new RegExp(/^VALIDATION_EVERY_ODD_HAS_TO_BE_HIGHER_THAN_1$/),
    messageKey: 'validations.odd-higher-than-one',
  },
  {
    regex: new RegExp(/^VALIDATION_MAX_NUMBER_OF_ODDS_EXCEEDED:(?<a>.*)$/),
    messageKey: 'validations.max-number-of-events',
  },
  {
    regex: new RegExp(
      /^VALIDATION_MAX_NUMBER_OF_COMBINATIONS_EXCEEDED:(?<a>.*)$/
    ),
    messageKey: 'validations.max-number-of-combinations',
  },
  {
    regex: new RegExp(/^VALIDATION_MIN_AMOUNT_PER_COMBINATION:(?<a>.*)$/),
    messageKey: 'validations.min-amount-per-combination',
  },
  {
    regex: new RegExp(
      /^VALIDATION_MAX_NUMBER_OF_SAME_DUAL_EVENTS:(?<a>.*)_EXCEEDED_FOR_EVENT:(?<b>.*)$/
    ),
    messageKey: 'validations.max-number-of-same-events',
  },
  {
    regex: new RegExp(
      /^VALIDATION_MIN_PAYMENT:(?<a>.*)_FOR_NUMBER_OF_MATCHES_LESS_THAN:(?<b>.*)$/
    ),
    messageKey: 'validations.min-payment-for-number-of-matches',
  },
  {
    regex: new RegExp(
      /^VALIDATION_EVENT:(?<a>.*)_STARTS_IN_LESS_THAN:(?<b>.*)_SECONDS$/
    ),
    messageKey: 'validations.event-starts-in-less-than',
  },
  {
    regex: new RegExp(
      /^VALIDATION_CANNOT_MIX_LONG_TERM_EVENTS:(?<a>.*)_AND_SHORT_TERM_EVENTS:(?<b>.*)$/
    ),
    messageKey: 'validations.validation-mix-long-short-term',
  },
  {
    regex: new RegExp(/^VALIDATION_MAX_WIN:(?<a>.*)$/),
    messageKey: 'validations.validation-max-win',
  },
  {
    regex: new RegExp(/^VALIDATION_MAX_NUMBER_OF_SYSTEMS_FOR_SLIP:(?<a>.*)$/),
    messageKey: 'validations.validation-max-systems',
  },
  {
    regex: new RegExp(
      /^VALIDATION_MARKETS:(?<a>.*)_AND:(?<b>.*)_CANNOT_BE_MIXED_ON_SAME_SLIP$/
    ),
    messageKey: 'validations.validation-mixed-markets',
  },
  {
    regex: new RegExp(/^VALIDATION_MAXIMUM:(?<a>.*)_SAME_SLIP_ALLOWED$/),
    messageKey: 'validations.max-same-slips',
  },
  {
    regex: new RegExp(/^VALIDATION_ONLY_SINGLES:(?<a>.*)$/),
    messageKey: 'validations.validation-only-singles',
  },
  {
    regex: new RegExp(/^VALIDATION_SINGLE_CHOICE:(?<a>.*)$/),
    messageKey: 'validations.validation-single-choice',
  },
  {
    regex: new RegExp(
      /^VALIDATION_CANNOT_MIX_GROUP_EVENT_AND_EVENTS_FROM_GROUP$/
    ),
    messageKey: 'validations.cannot-mix-group-events',
  },
];

export enum ValidationRule {
  MIN_AMOUNT = 'minimum_betting_amount',
  MAX_AMOUNT = 'maximum_betting_amount',
  MAX_WIN_AMOUNT = 'maximum_winning_amount',
  MAX_SLIP_ODD = 'maximum_number_of_different_odds',
  MAX_COMBINATIONS = 'maximum_number_of_combinations',
  MIN_COMBINATION_AMOUNT = 'minimum_betting_amount_per_combination',
  MAX_EVENTS_SYSTEM = 'maximum_number_of_events_systems',
  MAX_EVENTS = 'maximum_number_of_events',
  MAX_EVENT_ODDS = 'number_of_same_events',
  MAX_PLAYER_EVENTS = 'max_event_player_types',
  BALANCE_ERROR = 'balance_error',
  SYSTEM_WITH_ANTEPOST = 'system_ticket_with_antepost_events',
  MIXED_EVENT_TYPES = 'mixed_event_types',
  FREE_BET_ERROR = 'free_bet_error',
  SERVICE_ERROR = 'SERVICE_ERROR',
  ANTEPOST_ERROR = 'VALIDATION_CANNOT_MIX_LANDBASE_CODES',
}

export const VALIDATION_KEYS = [
  {
    description: 'Maximum betting amount',
    key: ValidationRule.MAX_AMOUNT,
    accessKey: ValidationRule.MAX_AMOUNT,
  },
  {
    description: 'Minimum betting amount',
    key: ValidationRule.MIN_AMOUNT,
    accessKey: ValidationRule.MIN_AMOUNT,
  },
  {
    description: 'Maximum number of combinations for system slip',
    key: ValidationRule.MAX_COMBINATIONS,
    accessKey: ValidationRule.MAX_COMBINATIONS,
  },
  {
    description: 'Maximum number of different odds on a system slip',
    key: 'maximum_number_of_different_odds',
    accessKey: ValidationRule.MAX_EVENTS_SYSTEM,
  },
  {
    description: 'Maximum number of different odds on non system slip',
    key: 'maximum_number_of_different_odds',
    accessKey: ValidationRule.MAX_EVENTS,
  },
  {
    description: 'Maximum winning amount',
    key: ValidationRule.MAX_WIN_AMOUNT,
    accessKey: ValidationRule.MAX_WIN_AMOUNT,
  },
  {
    description: 'Minimum betting amount per combination for system slip',
    key: ValidationRule.MIN_COMBINATION_AMOUNT,
    accessKey: ValidationRule.MIN_COMBINATION_AMOUNT,
  },
  {
    description: 'Number of same dual events on one slip',
    key: ValidationRule.MAX_EVENT_ODDS,
    accessKey: ValidationRule.MAX_EVENT_ODDS,
  },
  {
    description: 'Service error',
    key: ValidationRule.SERVICE_ERROR,
    accessKey: ValidationRule.SERVICE_ERROR,
  },
];
