import React from 'react';

import { Icon } from 'components';

interface MobileFilterLabelProps {
  label: string;
}

const MobileFilterLabel = ({ label }: MobileFilterLabelProps) => (
  <div className="d-flex space-between align-items text-ellipsis">
    <span className="mr-2">{label}</span>
    <Icon size="extra-small" name="caretDown" />
  </div>
);

export { MobileFilterLabel };
