const tr = {
  translation: {
    casino: {
      'all-games': 'TÜM OYUNLAR',
      award: 'Ödül',
      back: 'GERİ',
      'biggest-wins': 'EN BÜYÜK KAZANÇLAR',
      'casino-brands': 'KUMARHANE MARKALARI',
      categories: 'KATEGORİLER',
      category: 'KATEGORİ',
      close: 'Kapat',
      demo: 'DEMO OYUNU',
      'favourite-games': 'FAVORİ OYUNLAR',
      'game-of-week': 'HAFTANIN OYUNU',
      'last-played': 'SON OYNANAN',
      'most-played': 'EN ÇOK OYNANAN',
      play: 'OYNA',
      points: 'Puanlar',
      provider: 'MARKA',
      sections: 'BÖLÜMLER',
      'see-all': 'HEPSİNİ GÖR',
      'use-free-casino': 'Bedava döndürmeyi kullan',
      user: 'Kullanıcı',
    },
    countries: {
      albania: 'Arnavutluk',
      'bosnia-and-herzegovina': 'Bosna-Hersek',
      croatia: 'Hırvatistan',
      kosovo: 'Kosova',
      montenegro: 'Karadağ',
      'republic-of-north-macedonia': 'Kuzey Makedonya',
      russia: 'Rusya',
      serbia: 'Sırbistan',
      turkey: 'Türkiye',
      ukraine: 'Ukrayna',
    },
    errorPage: {
      'reload-page': 'Lütfen sayfayı yenileyin.',
      'something-went-wrong': 'Hata oluştu.',
    },
    errors: {
      'activation-link-exp':
        'Kaydı tamamlamak için hesabınızı etkinleştirmeniz gerekmektedir. Aktivasyon linki E-posta adresinize gönderildi.',
      'another-order-exists': 'Başka bir sipariş zaten var',
      'bad-password': 'Geçersiz şifre',
      'bet-not-possible': 'Bu etkinliğe şu anda bahis oynamak mümkün değil.',
      'betting-blocked': 'Bahis bloke edildi',
      'can-not-find-user': 'Kullanıcı bulunamıyor',
      'cancel-funds': 'Planlanan ödeme bulunamadı',
      'cannot-add-more-than-one-digital-item':
        'Birden fazla dijital ürün sepete eklenemez',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Zaten mevcut bir casino bonusunuz varsa, bir casino bonusu satın alamazsınız',
      'cannot-cancel-order': 'Siparişi iptal edemezsiniz',
      'cannot-order-mixed-items': 'Farklı ürün türlerini sipariş edemezsiniz',
      'cannot-order-more-than-one-digital-item':
        'Birden fazla dijital ürün sipariş edemezsiniz',
      'choose-betting-place': 'İndirme yeri seçiniz',
      'connection-offline': 'İnternet bağlantısı kesildi',
      'credentials-error': 'Kullanıcı adı veya şifre yanlış',
      'data-error-reading': 'Veri kaydetme hatası. Lütfen tekrar deneyiniz.',
      'email-exists': 'Bu e-posta adresine sahip bir kullanıcı var',
      'email-invalid': 'Geçersiz e-posta',
      'err-cash-drop-already-collected':
        'Birisi sizden daha hızlıydı. CashDrop kullanılmış ve artık mevcut değil.',
      'err-cash-drop-already-collected-by-user': 'CashDrop toplanmış durumda',
      'err-cash-drop-group-already-collected-by-user':
        'Bu gruptan CashDrop alamazsınız',
      'err-cash-drop-not-active':
        "CashDrop'un süresi dpldu ve artık mevcut değil",
      'err-cash-drop-not-found': 'CashDrop bulunamadı',
      'err-cash-drop-user-min-balance':
        'Bu indirimi toplamak için bakiyenizde  {{a}} EUR olması gerekmektedir.',
      'err-cash-drop-user-min-bet':
        "CashDrop'u toplayabilmek için son {{b}} saat içinde minimum {{a}} EUR harcamanız gerekiyor",
      ERR_BETTING_MACHINE_BLOCKED: 'Bu işlem bloke edildi',
      ERR_CANT_PAYOUT_BETTING_PLACE: 'Kupon bu ödeme noktasında kullanılamaz',
      ERR_CANT_PAYOUT_MUNICIPALITY: 'Kupon bu belediyede kullanılamaz',
      ERR_CASINO_BLOCKED: 'Casino oyunları engellendi',
      ERR_DEPOSIT_BLOCKED: 'Kullanıcının ödemesi engellendi',
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE:
        'Bahis makinesi için depozito bloke edildi',
      ERR_INVALID_GAME: 'Geçersiz oyun',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        'Bilet artık ödeme için geçerli değil. Lütfen operatörle iletişime geçin.',
      ERR_USER_BLOCKED_ALREADY: 'Hesabınız zaten engelenmiştir',
      ERR_USER_NOT_VALIDATED: 'Kullanıcı doğrulanmadı',
      ERR_VIRTUAL_GAMES_BLOCKED: 'Sanal oyunlar engellendi',
      ERR_VOUCHER_CONFIG_NOT_FOUND: 'Sanal yapılandırma bulunamadı',
      ERR_VOUCHER_EXPIRED: 'Kuponun süresi doldu',
      ERR_VOUCHER_NOT_FOUND: 'Kupon bulunamadı',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT:
        'Kupon durumu ödeme için geçerli değil',
      ERR_STREAM_NOT_AVAILABLE: 'Canlı yayın şu anda kullanılamıyor',
      ERR_STREAM_TYPE_NOT_AVAILABLE: 'Bu tür canlı yayın desteklenmiyor',
      ERR_LOCATION_NOT_SUPPORTED:
        'Canlı yayın bulunduğunuz yerde desteklenmiyor',
      ERR_IP_NOT_ALLOWED: 'IP adresiniz için canlı yayına izin verilmiyor',
      ERR_STREAM_NOT_ALLOWED: 'Canlı yayın yapılmasına izin verilmemektedir.',
      ERR_STREAM_NOT_ALLOWED_FOR_UNVALIDATED_USER:
        'Doğrulanmamış kullanıcılar için canlı yayın yapılmasına izin verilmez',
      ERR_MINIMUM_STREAMING_BALANCE:
        'Hesabınızda en az {{errorArgument}} EUR varsa canlı yayına katılabilirsiniz.',
      'error-starting-game': 'Oyun başlatılamıyor',
      'error-unknown': 'Bir sorun oluştu. Lütfen tekrar deneyiniz.',
      'field-invalid': 'Giriş geçersiz',
      'field-required': 'Zorunlu alan',
      'fields-obligated': '* ile gösterilen alanlar zorunludur.',
      'invalid-system-slip': 'Geçersiz sistem kayması',
      'invalid-user': 'Geçersiz kullanıcı',
      'item-not-available': 'Ürün mevcut değil',
      'item-not-found': 'Ürün bulunamadı',
      'jmbg-max': "JMBG 13'ten fazla karakter içeremez.",
      'jmbg-min': 'JMBG en az 13 karakterden oluşmalıdır.',
      'min-password': 'Şifre minimum 6 karakterden oluşmalıdır.',
      'no-betting-place-id': 'Bir şube seçiniz',
      'no-events-for-copy': 'Kopyalanabilecek etkinlik yok',
      'no-items-in-order': 'Siparişte ürün yok',
      'not-enough-points': 'Yeterli puanınız yok',
      'order-does-not-exist': 'Sipariş mevcut değil',
      'passwords-dont-match': 'Şifre eşleşmiyor.',
      'payout-error': 'Ödeme hatası',
      'place-bet-error':
        'Bahis oynamaya çalışırken bir hata oluştu. Tekrar deneyiniz.',
      'poker-not-responding': 'Poker sunucusu şu anda kullanılamıyor',
      'poker-success-false': 'Poker sunucusu işlemi başarısız oldu',
      'print-error':
        'Yazdırmaya çalışırken bir hata oluştu. Kupon yazdırılmamışsa iletişime geçin.',
      'print-not-operational': 'YAZICI ÇALIŞMIYOR',
      'promo-code-invalid': 'Promosyon kodu geçersiz',
      'reservation-locked': 'Rezerve edilen fonlar kilitlendi',
      'session-expired': 'Oturumun süresi doldu, lütfen tekrar giriş yapın.',
      'system-error': 'Bir sistem hatası oluştu. Lütfen tekrar deneyin.',
      TEST_USER_CANNOT_DEPOSIT_MONEY_WITH_CARD:
        'Test kullanıcısı kartla para yatıramıyor.',
      TEST_USERS_CANNOT_WITHDRAW_MONEY: 'Test kullanıcıları para çekemezler.',
      'ticket-changed': 'Bahisiniz değişti.',
      'ticket-denied': 'Bahis bileti onaylanmış değil.',
      'ticket-error-reading': 'Bahis bileti okunurken hata oluştu.',
      TICKET_NOT_FOUND: 'Kupon bulunamadı',
      TICKET_NOT_VALID_FOR_PAYOUT:
        'Bilet artık ödeme için geçerli değil. Lütfen operatörle iletişime geçin.',
      'token-expired': 'Tokenın süresi doldu',
      'user-blocked-error':
        'Hesabınız askıya alındı. Lütfen müşteri desteğiyle iletişime geçin.',
      'user-exists': 'Kullanıcı zaten var.',
      'user-not-active':
        'Kullanıcı etkinleştirilmedi. Lütfen hesabınızı etkinleştirin.',
      'user-update-error':
        'Veriler kaydedilirken hata oluştu. Lütfen tekrar deneyiniz.',
      'username-exists': 'Bu kullanıcı adına sahip bir kullanıcı zaten mevcut.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Antepost etkinlikleri tek birbahis biletine birleştirilemez',
      'withdrawal-canceled-or-paid':
        'Ödeme iptal edildi veya ödendi. Lütfen sayfayı yenileyiniz.',
      'withdrawal-max-amount-exceeded':
        "İzin verilen maksimum ödeme {{a}}€'dur",
      'withdrawal-min-amount-exceeded': "İzin verilen minimum ödeme {{a}}€'dur",
      'zero-item-quantity': 'Stokta ürün yok',
    },
    footer: {
      'about-us': 'Hakkımızda',
      'account-id': 'Hesap ID',
      'all-rights-reserved': 'Tüm hakları Saklıdır',
      'available-for-payment': 'Ödeme için kullanılabilir',
      aviator: 'Aviator',
      betting: 'Bahis',
      bonuses: 'Bonuslar',
      casino: 'Kumarhane  ',
      'casino-live': 'Canlı kumarhane',
      contact: 'İletişim',
      cookies: 'Çerezler (Cookies)',
      description:
        "SBbet, 1997 yılından bu yana şans oyunları düzenlemekte ve müşterilerine en üst düzeyde hizmet sunmaktadır.Karadağ'da 100'ün üzerinde ödeme noktasından oluşan ağımızla pazar lideri olarak konumlanıyoruz ve bu da bizi, işimizi şans oyunları alanında birinci sınıf eğlence yaratmaya yönlendirmeye daha da motive ediyor. SBbet sitesi, en iyi kullanıcı deneyimiyle bahis oynamanıza olanak sağlayacak şekilde tasarlanmıştır. Çok çeşitli spor ve yarışmalar, cazip oranlar, bonuslar, bedava bahisler ve Jackpotlarımız aracılığıyla büyük miktarda para kazanma olasılığı, 20 yılı aşkın deneyime sahip Karadağ'ın en eski bahisçisine güvenmenin nedenlerinden sadece birkaçı. Pek çok casino oyunundan birinde, bingomuzda, sanal spor etkinliklerimizde veya gerçek bir casino masasında şansınızı deneyiniz. SBbet.me, Mediteranska 21, Budva, Karadağ adresinde bulunan Sporting Group doo'nun bir parçasıdır ve Karadağ yasalarına tabidir ve Şans Oyunları İdaresi tarafından verilen lisansın sahibidir: 02/01-702/4. SBbet ekibi size iyi şanslar diler - Emin ...",
      'download-list': 'Listeyi indirin',
      'follow-us': 'Bizi takip edin',
      'frequently-questions': 'Sık sorulan sorular',
      'game-rules': 'Oyun kuralları',
      informations: 'Bilgiler',
      'lucky-6': 'Lucky6',
      'pay-all': 'HEPSİNİ ÖDE',
      'payment-to-web': "WEB'e ödeme",
      'play-responsibly': 'Sorumlu oynayın',
      privacy: 'Gizlilik',
      'promo-code': 'Promo kodu',
      promotions: 'Promozsyonlar',
      'site-map': 'Site haritası',
      sport: 'Spor',
      'ticket-check': 'Bilet kontrolü',
      transactions: 'İşlemler',
      'using-conditions': 'Kullanım koşulları',
    },
    globals: {
      accept: 'KABUL  ET',
      'accept-cookies':
        'Bu site, hizmetleri geliştirmek ve size ilgili içeriği göstermek için çerezleri kullanır. Sitede gezinmeye devam ettiğiniz takdirde çerez kullanımını kabul ettiğinizi düşünüyoruz.',
      account: 'Hesap',
      'account-type': 'Hesap türü',
      activation: 'AKTİVASYON',
      'add-to-cart': 'Sepete ekle',
      all: 'Her şey',
      'amount-caps': 'MİKTAR',
      'app-idle-msg':
        "Bir süredir aktif değildiniz. Güncel bir teklif için yenile'yi tıklayınız.",
      approved: 'Onaylandı',
      'betting-history': 'Bahis geçmişi',
      'booking-an-order': 'Sipariş rezervasyonu',
      braon: 'Kahverengi',
      cancel: 'VAZGEÇ',
      canceled: 'Reddedilmiş',
      cart: 'Sepet',
      'cart-is-empty': 'Sepet boş',
      Cet: 'Prş.',
      'choose-a-betting-place': 'Şube seçiniz',
      'choose-a-city': 'Şehir seçiniz',
      'choose-order-place': 'Seçilen sbbet şubesindeki teslimat yerini seçin',
      'click-for-more-info': 'Daha fazla bilgi için tıklayınız',
      close: 'KAPAT',
      'code-caps': 'KOD',
      collect: 'Topla',
      copy: 'Kopyala',
      'copy-ticket': 'Bahis biletini kopyala',
      'customer-support': 'Müşteri desteği',
      date: 'Tarih',
      'date-caps': 'TARİH',
      delivered: 'Teslim edildi',
      'filter-by-date': 'Tarihe göre filtrele',
      free: 'Ücretsiz',
      'id-caps': 'ID',
      immediately: 'Hemen',
      'installation-guide': 'Kurulum Talimatları',
      loading: 'Yükleniyor...',
      'mark-all-as-read': 'Tümünü okundu olarak işaretle',
      'my-account': 'Hesabım',
      'necessary-reload': 'Teklifin senkronize edilmesi gerekiyor',
      Ned: 'Pzr',
      nepar: 'Tek ',
      no: 'HAYIR',
      'no-info-about-event': 'Etkinlik hakkında bilgi yok',
      notifications: 'Bildirim',
      numbers: 'SAYILAR',
      'order-history': 'Sipariş Geçmişi',
      par: 'Eşit',
      pending: 'Beklemede',
      Pet: 'Cum',
      'pick-date': 'Tarih seçiniz',
      'place-order': 'Ürünleri sipariş edin',
      Pon: 'Pzt',
      proceed: 'Yenile',
      profile: 'Profil',
      'promo-code': 'Promo kodu',
      register: 'KAYIT',
      'remove-from-cart': 'Sepetten çıkart',
      save: 'Kaydet',
      'sbbet-promo-code': 'SBbet PROMO KODU',
      search: 'Arama',
      'search-1': 'Ara ',
      'search-events': 'Etkinlikleri ara',
      send: 'İLET ',
      'send-1': 'GÖNDER',
      Sre: 'Çrş',
      statistics: 'İSTATİSTİK',
      'status-caps': 'DURUM',
      step: 'Adım',
      Sub: 'Cmt',
      submit: 'KABUL ET',
      success: 'İndirildi',
      'successfully-collected-cash-drop': "CashDrop'u başarıyla aldınız",
      'ticket-copied': 'Bahis bileti kopyalandı',
      time: 'Saat',
      'transaction-history': 'İşlem geçmişi',
      type: 'Tip',
      'type-caps': 'TIP',
      'user-id-caps': 'KULLANICI ID',
      Uto: 'Sal',
      wallets: 'Hesap',
      welcome: 'Hoşgeldiniz!',
      yes: 'EVET',
      zelena: 'Yeşil',
      žuta: 'Sarı',
    },
    header: {
      betting: 'Bahis',
      casino: 'Kumarhane',
      'casino-live': 'Canlı kumarhane',
      live: 'Canlı',
      'quick-games': 'Hızlı oyunlar',
    },
    home: {
      '100-spins': '100 SPIN',
      'be-in-the-game-with-app': 'BAHİS UYGULAMASIYLA OYUNDA OLUN',
      betting: 'BAHİS',
      'betting-description':
        'En yüksek oranlar ve en çeşitli oyun ile özel teklifler sizi bekliyor.',
      'call-center': 'CALL CENTAR',
      'casino-description':
        'En popüler casino oyunları büyük ikramiyeler getiriyor',
      'follow-us': 'BİZİ TAKİP ET',
      'live-betting': 'CANLI BAHİS',
      'live-betting-description':
        'Canlı bahis artık iki kat daha iyi çünkü en geniş canlı spor seçenekleri ve geniş bir oyunseçenekleri SBBET-te sizi bekliyor.',
      'place-bet': 'Bahis yeri',
      'promotions-description':
        'Yeni promosyonlar ve haberler hakkında daha fazla bilgi edinin',
      'quick-games-description':
        'En geniş sanal seçim: sayılar, futbol, yaarış ve çok daha fazlası.',
      register: 'Üye ol',
      'up-to-250': "250'ye kadar",
      'we-double-your-first-deposit':
        'İLK PARA YATIRMA İŞLEMİNİZİ İKİYE KATLIYORUZ',
    },
    language: {
      english: 'İngilizce',
      language: 'Dil',
      montenegrin: 'Karadağca',
      'select-language': 'Dil seç',
    },
    login: {
      'forgot-password': 'Şifrenizi mi unuttunuz?',
      'forgot-password-caps': 'Şifrenizi mi unuttunuz?',
      'log-in': 'GİRİŞ YAP',
      'log-out': 'Oturumu kapat',
      login: 'OTURUMU AÇ',
      logout: 'OTURUMU KAPAT',
      'logout-1': 'OTURUMU KAPAT',
      'logout-question': 'Oturumu kapatmak istediğinizden emin misiniz?',
      'sign-in': 'GİRİŞ YAP',
    },
    navigation: {
      aviator: 'AVIATOR',
      betting: 'BAHİS',
      casino: 'KUMARHANE',
      liveCasino: 'CANLI KUMARHANE',
      lucky6: 'LUCKY6',
      numbers: 'SAYILAR',
      offer: 'TEKLİF ',
      poker: 'POKER',
      promotions: 'PROMOSYON  ',
      quickGames: 'HIZLI OYUNLAR',
      sport: 'SPOR',
    },
    payments: {
      'account-id-confirm': 'Hesap ID onayı',
      amount: 'Miktar',
      'available-for-payment': 'Ödeme için kullanılabilir',
      balance: 'Balans',
      'balance-after': 'Sonraki miktar',
      'balance-before': 'Önceki miktar',
      'bonus-balance': 'Bonus bakiyesi',
      'cancel-withdrawal': 'Ödemeyi iptal et',
      'cancel-withdrawal-question':
        'Ödemeyi iptal etmek istediğinizden emin misiniz?',
      'cancel-withdrawal-success': 'Ödemeyi başarıyla iptal ettiniz.',
      'card-expired': 'İşlem reddedildi. Kartın süresi doldu.',
      'card-not-found': 'Kart bulunamadı. Lütfen tekrar deneyiniz.',
      'card-suspended': 'İşlem reddedildi. Kart askıya alındı.',
      commission: 'komisyon',
      credit: 'Ödeme',
      'credit-card': 'Kredi kartı',
      debit: 'Ödeme',
      'e-wallet': 'E-cüzdan',
      'funds-available-for-payment': 'Ödeme için mevcut fonlar',
      'funds-that-can-be-raised': 'ÇEKİLEBİLECEK FONLAR',
      'in-the-bank': 'BANKADA',
      'in-the-bank-note':
        'Adınız ve soyadınız doğru değilse lütfen Destek ile iletişime geçin',
      'in-the-office': 'Dükkanda',
      'invalid-amount': 'İşlem reddedildi. Tutar geçerli değil.',
      'invalid-card-number': 'İşlem reddedildi. Kart numarası geçerli değil.',
      'maximum-credit': 'Maksimum kredi',
      'maximum-debit': 'Maksimum ödeme',
      'minimum-credit': 'Minimum kredi',
      'minimum-debit': 'Minimum ödeme',
      pay: 'ÖDE',
      'payment-default-error': 'İşlem reddedildi. Lütfen tekrar deneyiniz.',
      'payment-destination': 'Ödeme yeri',
      'payment-success': 'Ödeme başarıyla gerçekleştirildi.',
      'physical-payment': 'Fiziki Ödeme',
      'poker-withdrawal-success': 'Ödeme başarıyla yapıldı',
      'potential-payout': 'potansiyel ödeme',
      'proceed-with-payment': 'Butona tıklayarak ödemeye devam edin',
      skrill: 'Skrill',
      tabaccopress: {
        'deposit-step-1':
          'Tobacco S Press satış noktalarından birinden, ödeme terminalleri aracılığıyla Sbbet hesabınıza depozito ödemesini isteyiniz.',
        'deposit-step-2':
          'SBBet hesabınızın ID numarasını ve ödenmesi istediğiniz tutarı satıcıya bildirmeniz gerekmektedir.',
        'deposit-step-3':
          'Iznos koji ste uplatili će Vam biti raspoloživ na Vašem SBBet nalogu najčešće u roku od nekoliko minuta od trenutka uplate.',
        'deposit-steps':
          'Ödeme terminalleri aracılığıyla depozito nasıl ödenir?',
        'instruction-1':
          'Ödeme terminalleri aracılığıyla ödeme yapmak için yalnızca SBbet hesabınızın kullanıcı numarası (ID numarası) gereklidir.',
        'instruction-2':
          "Ödemenizi Tobacco S Press'in 300'den fazla satış noktasındaki ödeme terminallerinden yapabilirsiniz.",
        'instruction-3':
          "Ödeme terminallerinden minimum ödeme tutarı 1,00 Euro, işlem başına maksimum ödeme tutarı ise 500,00 Euro'dur.",
        'instruction-4':
          'Hata durumunda, satış noktasında işlemi geri alma süresi 10 dakikadır (hesaptan para harcanmamışsa).',
        'instruction-5':
          'Aşağıdaki bağlantıdan depozito ödeyebileceğiniz TOBACCO SPRESS tesislerine bakın.',
      },
      terminals: {
        note: "NOT: Herhangi bir nedenle para, SBBet hesabınıza ödenmezse, lütfen J&A hizmetiyle  sabah 9'dan akşam 5'e kadar 067/788-588 numaralı telefondan ve akşam 5'ten akşam 9'a kadar ile hafta sonları 068/488-588 telefondan iletişime geçin.",
        'step-1': 'J&A terminalinde SBbet logosunu seçin',
        'step-2':
          'Kullanıcı hesabı kimliğinizi girin ve "ÖDEME" düğmesini tıklayınız',
        'step-3':
          'İstenilen miktardaki parayı terminale yerleştirin; cihaz ne kadar para koyduğunuzu okuyacak ve gösterecektir.',
        'step-4':
          '"ONAYLA" butonuna tıkladığınızda ödemeyi başarıyla gerçekleştirecek ve işleminize ilişkin makbuzun çıktısını alacaksınız.',
      },
      'transaction-date': 'İşlem tarihi',
      'transaction-deposit': 'Depozito',
      'transaction-id': 'İşlem ID',
      'transaction-type': 'İşlem türü',
      'transaction-web-bet': 'Web bet',
      'transaction-web-bet-rollback': 'Web bet rollback',
      'transaction-web-casino-credit': 'Casino credit',
      'transaction-web-casino-debit': 'Casino debit',
      'transaction-web-casino-rollback': 'Casino rollback',
      'transaction-web-game-bet': 'Web game bet',
      'transaction-web-game-rollback': 'Web game rollback',
      'transaction-web-game-win': 'Web game win',
      'transaction-web-win': 'Web win',
      'transaction-withdraw': 'Para çekme',
      transactions: 'İşlem',
      withdraw: 'ÖDE',
      'withdraw-on-the-bank': 'BANKA YOLUYLA ÖDEME',
      'withdraw-on-the-betting-place': 'ŞUBEDE ÖDEME',
      'withdrawal-pending': 'Onay işlemi bekleniyor',
      'withdrawal-ready': 'Çekilmeye hazır',
      'withdrawal-reserved': 'Planlamış ödeme',
      'withdrawal-success': 'Ödemeyi başarıyla planladınız.',
      'your-transaction-has-been-confirmed': 'İşleminiz onaylandı',
    },
    promoBanner: {
      aviator: 'Aviator',
      betting: 'Bahis',
      blackjack: 'Blackjack',
      cashout: 'Cashout',
      'casino-tournament': 'kumarhane turnuvası',
      'defeat-goalkeeper-for-bonuses': 'BONUSLAR için kaleciyi yen',
      'double-first-payin': 'Her ilk ödemeyi ikiye katlıyoruz',
      'download-app': 'Uygulamayı İndir',
      'fly-to-win': 'Kazanmak  için uç',
      'happy-monday-bonus': 'Happy Monday Bonus',
      'lucky-6': 'Lucky 6',
      'netent-casino-games': 'NetEnt Kumarhane Oyunları',
      new: 'Yeni ',
      'pay-the-ticket-at-any-time': 'Bahıs biletini istediğin zaman öde',
      popular: 'popüler',
      promotion: 'Promosyon',
      roulette: 'Rulet',
      'win-5000-jackpot': '5.000 Euro tutarında bir jackpotu kazanın',
      'win-apartment': "Podgorica'da bir apartman dairesini kazanın",
      'win-iphone-13': 'Iphone 13 kazanın',
      'win-jackpot-up-to-5000': "5000 Euro'ya kadar olan jackpotu kazanın",
    },
    slips: {
      'accept-all-changes': 'Tüm değişiklikleri kabul et',
      'accept-cashout': 'CASHOUT KABUL ET',
      'accept-changes': 'Değişiklikleri kabul et',
      'accept-changes-after-place-bet':
        'Ödeme sonrasında değişiklikleri kabul et',
      active: 'Aktif',
      'actual-winning-amount': 'Ödeme',
      'all-tickets': 'TÜM BİLETLER',
      approving: 'Onay Bekleniyor',
      bet: 'İddia',
      'bet-type': 'Bahis türü',
      bonus: 'Bonus:',
      'cancel-cashout': 'cashout-u iptal et',
      cashout: 'CASHOUT',
      'cashout-accepted': 'Cashout kabul edildi',
      'cashout-denied': 'Cashout reddedildi',
      'cashout-denied-timer':
        'Cashout, kabul için zaman aşımı nedeniyle reddedildi',
      changed: 'Değiştirildi',
      'confirm-ticket': 'BAHİS KUPONUNU ONAYLA',
      'delete-all': 'Tümünü Sil',
      'delete-ticket': 'Bhis kuponu sil',
      'delete-ticket-info': 'Bu kuponu silmek istiyor musun?',
      denied: 'Reddedildi',
      'deny-cashout': 'CASHOUT-u reddet',
      'erase-multiple-odds':
        'Bu işlemle, birden fazla karakter silinecek ve her maçta yalnızca ilk karakter kalacaktır.',
      failed: 'Başarısız',
      fix: 'FIKS',
      'group-number': 'Grup no.',
      id: 'Şifre',
      'max-pot-payout': 'Max. pot. Ödeme',
      'max-pot-win': 'Max. Pot. Kazanç',
      'max-potential-bonus': 'Max. pot. bonus',
      'min-max-bonus': 'Min/Max Bonus',
      'min-max-odd': 'Min/Max Kvota',
      'min-max-pot-win': 'Min/Max pot. Kazanç',
      'min-pot-win': 'Min. Kazanç',
      'min-potential-bonus': 'Min. pot. bonus',
      'min-winning-total': 'Min. pot. Ödeme',
      multiplier: 'Çarpan',
      'my-tickets': 'KUPONLARIM',
      'no-slip-events': 'Slipte hiçbir etkinlik yok',
      'no-tickets': 'Kupon yok',
      'number-of-combinations': 'kombinasyon sayısı:',
      'number-of-matches': 'çift sayısı:',
      'number-of-pairs': 'çift no:',
      'number-of-tickets': 'Kupon no::',
      odd: 'Çarpan:',
      paid: 'Ödenmiş',
      passed: 'Kazanç',
      payin: 'UplataÖdeme tutarı:',
      'payin-time': 'Ödeme saati:',
      'payment-time': 'Ödeme saati ',
      payout: 'Ödeme:',
      'place-bet': 'Bahis oyna ',
      'possible-winning': 'Olası kazanç',
      'pot-winning-amount': 'potansiyel ödeme',
      'pot-winning-amount-short': 'Pot. Ödeme',
      'potential-bonus': 'Potansiyel bonus',
      'potential-gain': 'Potansiyel kaazanç',
      'quick-ticket-code': 'Hızlı kupon için kod',
      'quick-ticket-live': 'Canlı maçlarda hızlı kupon oynamak mümkün değildir',
      'quick-ticket-pay':
        'Herhangi bir SBBet ödeme noktasında şu şifreyle hızlı kupon ödeyebilirsiniz:',
      'quick-ticket-terms':
        'Kupon ödemesi yapılırken ödeme noktalarımızdan koşullar ve olası kısıtlamalar kontrol edilir.',
      'quick-tickets': 'Hızlı kupon  ',
      regular: 'SIRADAN',
      'regular-ticket': 'SIRADAN KUPON',
      'remove-match': 'Maçı kaldır',
      'remove-odd': 'Çarpanı kaldır',
      round: 'Tur',
      'save-games': 'Maçları kaydet',
      'share-ticket-success': 'Bilet bağlantısı başarıyla kopyalandı',
      'sign-in-status': 'Kupon durumunu görüntülemek için oturum açın.',
      'slip-not-eligible-for-cashout':
        'Bahis kuponu  cashout işlemine uygun değildir. ',
      'slip-payout': 'Kupon ödemesi',
      stake: 'Bahis miktarı',
      'stake-per-combination': 'Kombinasyon başına bahis miktarı:',
      status: 'Durum',
      'status-capitalize': 'Durum:',
      'switch-ticket': 'Kuponu normale çevirmek istiyor musunuz?',
      system: 'SİSTEMSEL',
      'system-bonus-calculation':
        'Sistem biletindeki bonus hesaplaması tüm etkinliklerin gerçekleşmesinden sonra yapılacaktır..',
      ticket: 'BAHİS KUPONU',
      'ticket-date': 'Fiş tarihi',
      'ticket-details': 'BAHİS KUPONU AYRINTILARI',
      'ticket-id': 'Bahis kuponunun kodu',
      'ticket-id-search': 'Bahis Kuponunun kodu:',
      'ticket-overview': 'Bahis Kuponunun bakılması',
      'ticket-status': 'Kuponun durumu',
      'ticket-type': 'Fiş türü',
      tickets: 'KUPONLAR ',
      'top-tickets': 'EN İYİ KUPONLAR',
      'total-odd': 'Toplam çarpan:',
      unconfirmed: 'Onaylanmamış',
      win: 'Kazanç:',
      'winning-amount-caps': 'KAZANÇ',
    },
    sports: {
      'americki fudbal': 'Amerikan futbolu',
      atletika: 'Atletik',
      badminton: 'Badminton',
      bejzbol: 'Beyzbol',
      biciklizam: 'Bisikletçilik',
      boks: 'Boks',
      efudbal: 'eFutbol',
      esport: 'eSport',
      evrovizija: 'Eurovizyon',
      fudbal: 'Futbol',
      futsal: 'Futsal',
      golf: 'Golf',
      hokej: 'Hokey',
      košarka: 'Basketbol',
      mma: 'MMA',
      odbojka: 'Voleybol',
      olimpijada: 'Olimpiyat',
      oskar: 'Oskar',
      pikado: 'Dart',
      ragbi: 'Ragbi',
      'ragbi liga': 'Ragbi ligi',
      rukomet: 'Hentbol',
      snuker: 'Bilardo',
      'stoni tenis': 'Masa tenisi',
      tenis: 'Tenis',
      trke: 'Yarışlar',
      'trke konja': 'At yarışı',
      vaterpolo: 'Su topu',
      'virtuelni fudbal': 'Sanal futbol',
      'zimski sportovi': 'Kış sporları',
    },
    sportsPage: {
      '1d': '1d',
      '1h': '1h',
      '3d': '3d',
      '3h': '3h',
      all: 'HEPSİ ',
      antepost: 'ANTEPOST',
      competition: 'Lig',
      'configure-offer': 'Teklifi Yapılandır',
      event: 'Etkinlik',
      limit: 'GR',
      live: 'CANLI  ',
      'live-match-tracker': 'Maçı Canlı İzle',
      'no-events': 'Etkinlik yok',
      'no-odds': 'Şu anda herhangi bir teklif yok',
      odds: 'ÇARPAN',
      player: 'OYUNCU',
      'sort-by-competition': 'Liglere göre',
      'sort-by-time': 'Saate göre',
      special: 'ÖZEL',
      upcoming: 'YAKLAŞAN',
    },
    userData: {
      address: 'Adres',
      adress: 'Adres',
      'bank-account-number': 'Banka hesabı numarası',
      'bank-name': 'Banka adı',
      'betting-win-loss': 'Betting win/loss',
      'casino-and-virtuals-win-loss': 'Casino ve sanal win/loss',
      'change-password': 'Şifre değiştir',
      'changes-active-in-24-hrs':
        'Değişiklikleriniz 24 saat içinde etkinleşecek',
      city: 'Şehir  ',
      code: 'Kod',
      'confirm-password': 'Şifreyi Onayla',
      country: 'Država',
      'current-password': 'Mevcut Şifre',
      'current-spending': 'Şu andaki tüketim',
      'daily-limits': 'Günlük limit',
      'date-of-birth': 'Doğum tarihi',
      deposit: 'Depozito',
      'document-id': 'Pasaport kimliği',
      documents: 'Belgeler',
      'driver-license': 'Ehliyet',
      'email-address': 'E-posta adresi',
      'email-adress': 'E-posta adresi',
      female: 'Kadın',
      'first-name': 'Adı  ',
      gender: 'Cinsiyet',
      'i-have-jmbg': 'Kimlik Numaram var (sadece Karadağ vatandaşları için)',
      jmbg: 'JMBG',
      'last-name': 'Soyadı',
      male: 'Erkek',
      'monthly-limits': 'Aylık limitler',
      'new-limit-value-is': 'Yeni limit değeri',
      'new-limits-take-effect-in': 'Yeni kısıtlamalar yürürlüğe girdi',
      'new-password': 'Yeni şifre',
      passport: 'Pasaport',
      password: 'Şifre',
      'pause-gambling-confirmation':
        'Bununla birlikte spor bahisleri, casino ve sanal oyunlar seşilen tairhe kadar devre dışı bırakılacaktır. Devam etmek istediğine emin misiniz?',
      'pause-gambling-until': 'Bahis şu tarihe kadar duraklatılıyor',
      'personal-data': 'Kişisel bilgiler',
      'personal-id': 'Kimlik kartı',
      phone: 'Telefon',
      place: 'Yer',
      'responsible-gambling': 'Sorumlu bahis',
      save: 'Kaydet',
      'street-and-number': 'Sokak ve numarası',
      telephone: 'Telefon',
      'user-details': 'Kişisel detaylar',
      username: 'Kullanıcı adı',
      'weekly-limits': 'Haftalık limitler',
    },
    userMarket: {
      cancel: 'İptal et',
      code: 'Kod',
      doctor: 'Doktor',
      expert: 'Ekspert',
      items: 'Öğeler',
      order: 'Sipariş',
      'order-success': 'Başarılı sipariş',
      place: 'Yer',
      professor: 'Profesor',
      'sbbet-points': 'SBBet puanları',
      'successfuly-canceled-order': 'Siparişinizi başarıyla iptal ettiniz',
    },
    validations: {
      'amount-greater-than-available':
        'Girilen tutar mevcut olan tutardan daha yüksek',
      'cannot-mix-group-events':
        'Grup etkinliklerini ve gruptaki etkinlikleri aynı kuponla oynamak mümkün değildir',
      'event-disabled': 'Etkinliğe bahis oynamak: {{a}} mümkün değildir.',
      'event-starts-in-less-than':
        '{{a}} etkinliği {{b}} saniyeden kısa sürede başlıyor',
      'free-bet-error':
        "Ücretsiz bahis kupondaki minimum etkinlik sayısı {{minEvents}}'tir. Minimum toplam oran {{minTotalOdd}}'dur.",
      'free-bet-unvalidated':
        'Promosyon fonlarından yararlanmak için lütfen hesabınızı doğrulayın.',
      'live-odds-change': 'Lütfen tüm değişiklikleri kabul edin.',
      'max-bet-amount': 'Maksimum bahis miktarı {{a}} ile sınırlıdır',
      'max-credit': 'Maksimum kredi tutarı {{max}} €',
      'max-debit': 'Maksimum ödeme tutarı {{max}}',
      'max-number-of-combinations': 'Maksimum kombinasyon sayısı {{a}}',
      'max-number-of-events': 'Maksimum etkinlik sayısı {{a}}',
      'max-number-of-same-events': 'Maksimum integral sayısı {{a}}',
      'max-same-slips': 'Zaten {{a}} aynı bahis kuponunu yatırdınız.',
      'min-amount-per-combination':
        'Kombinasyon başına minimum bahis miktarı: {{a}}',
      'min-credit': 'Minimum kredi tutarı {{min}} €',
      'min-debit': 'Minimum borç tutarı {{min}}',
      'min-payment-for-number-of-matches':
        "{{b}} veya daha az etkinliğe bahis yaparken minimum bahis tutarı {{a}}'dır.",
      'no-space': 'Boşluklara izin verilmiyor',
      'odd-disabled': '{{a}} oranına bahis oynamak mümkün değildir.',
      'odd-higher-than-one': "Herhangi bir çarpa 1'den büyük olmalıdır.",
      'only-letters-and-spaces-allowed':
        'Sadece harflere ve boşluklara izin veriliyor',
      'only-numbers-allowed': 'Sadece sayılara izin veriliyor',
      'repeat-password': 'Şifreyi tekrarla',
      'validation-antepost-system':
        'Sistem bahis kuponunda birden fazla antepostu birleştirmek mümkün değildir.',
      'validation-balance': 'Hesabınızda yeterli para yok.',
      'validation-max-payment':
        'Ödeme, tanımlanan maksimum ödemeden daha yüksek.',
      'validation-max-systems': 'Maksimum sistem sayısı: {{a}}',
      'validation-max-win': "Maksimum potansiyel kazanç {{a}}'dır",
      'validation-min-payment': "Minimum bahis tutarı {{minAmount}} EUR'dur.",
      'validation-mix-long-short-term':
        'Anteppost {{a}} ile {{b}} etkinliğini aynı bahis kuponunda birleştirmek mümkün değildir.',
      'validation-mixed-event-types':
        'Antepost etkinlikleri sadece tekli olarak oynanabilir.',
      'validation-mixed-markets':
        'Aynı bahis kuponunda {{a}} pazarına ve {{b}} pazarına bahis yapılamıyor.',
      'validation-not-validated': 'Doğrulanmamış bir kullanıcı parayı çekemez.',
      'validation-only-singles':
        '{{a}} etkinliği yalnızca tekli olarak oynanabilir.',
      'validation-single-choice':
        '{{a}} etkinliği birden fazla karakter içeremez.',
    },
    verification: {
      'accept-terms': 'Kullanım koşullarını kabul ediyorum',
      'account-activated': 'Hesap başarıyla etkinleştirildi',
      'account-success': 'Hesabınızı başarıyla etkinleştirdiniz!',
      'agree-email-notif': 'Bildirimleri e-postayla almak istiyorum',
      'agree-sms-notif': 'SMS yoluyla bildirim almak istiyorum',
      confirm: 'ONAYLA',
      'connection-online': 'İnternet bağlantısı kuruldu!',
      'data-saved': 'Veriler başarıyla kaydedildi',
      'deny-changes': 'Kuponda yapılan değişiklikleri başarıyla reddettiniz.',
      'deny-changes-timer':
        'Değişikliklerin kabul edilmesinde zaman aşımı oluştuğundan değişiklikler reddedildi.',
      'email-verified-success': 'E-posta hesabınızı başarıyla doğruladınız',
      'enter-recovery-email':
        'Hesabı oluşturduğunuzda kullandığınız e-posta adresini girin.',
      instruction:
        '1818 numarasına şu formatta SMS mesajı gönderin: SbbKimliknumarasıYatırılanmiktar',
      'login-activation': 'Uygulamayı kullanmak için oturum açın',
      'promo-code-activated':
        'Promosyon kodunu başarıyla etkinleştirdiniz. Promosyon hesabınıza {{amount}} {{currency}} aldınız.',
      'promo-code-activated-multiple-wallets':
        'Promo kod başarıyla etkinleştirildi.Free bet hesabınızda par ve miktarı miktarını ve free spin hesabınızdaki (para)(miktarı) aldınız',
      'promo-code-already-activated': 'Promosyon kodu zaten etkinleştirildi',
      'promo-code-not-for-existing-users':
        'Promosyon kodu eski kullanıcılara yönelik değildir',
      'promo-code-not-for-new-users':
        'Promo kodu yeni kullanıcılara yönelik değildir.',
      'promo-code-used-up': 'Promosyon kodu zaten kullanıldı.',
      'recovery-email-sent': 'E-posta adresinize bir e-posta gönderdik',
      'successfully-changed-password': 'Şifrenizi başarıyla değiştirdiniz',
      'ticket-approved': 'Kuponunuz onaylandı. Bileti görmek için tıklayın.',
      'ticket-paid': 'Kupon başarıyla ödendi.',
      'ticket-waiting-approval': 'Kuponunuz onaylanıyor.',
      'upload-document': {
        'choose-document': 'Bir belge seçin',
        'document-type': 'Doğrulama belgesi türü:',
        'first-page': 'Belgenin ön/ilk sayfası:',
        'second-page': 'Belgenin arka/ikinci sayfası:',
        submit: 'Gönder',
        title: 'Belge yükleniyor',
        'upload-first-page': 'Belgenin ön/ilk sayfasını yükleyin',
        'upload-second-page': 'Belgenin arka/ikinci tarafını yükleyin',
        'user-update-success': 'Veri başarıyla kaydedildi',
      },
      'validation-18-year': '18 yaşında değilsin.',
      'verification-complete': 'Siparişin doğrulanması başarıyla tamamlandı',
      'verification-in-progress': 'Doğrulama devam ediyor',
      'verification-modal': {
        activate: 'Hesap doğrulama',
        desc: 'Hesabınızı hesap sayfanızda doğrulayabilirsiniz: Hesabım > Hesap > Belgeler',
        'doc-uploaded': 'Doğrulama işlemi devam ediyor',
        Name: 'Adı',
        proceed: 'Doğrulama olmadan devam et',
        validate: 'Kullanıcı doğrulama',
      },
    },
    wallet: {
      'activate-bonus-info':
        '{{bonus}} EUR tutarında bir promosyon bonusunu etkinleştirin',
      'casino-bonus-info':
        'Kumarhane bonusunun kilidini açmak için {{amountToUnlock}} EUR daha harcamanız gerekiyor',
      'casino-to-unlock': 'Kilidini açmak için Casino Bonus',
      'free-bet': 'Free bet',
      'free-bet-bonus': 'Free bet bonus',
      'free-bet-bonus-info':
        'Ücretsiz bahsin kilidini açmak için {{amountToUnlock}} EUR daha harcamanız gerekiyor',
      'free-bet-bonus-to-unlock': 'Kilidi açtıktan sonra free bet bonusu',
      'free-bet-bonus-unlock-message':
        'Free bet bonusunun kilidini açmak için (para)(miktarı)Avro daha harcamanız gerekiyor.',
      'free-spin': 'Free spin',
      'free-spin-to-unlock': 'Kilidini açmak için Free spin ',
      'free-spin-unlock-message':
        "free spin'in kilidini açmak için {{amountToUnlock}} EUR daha harcamanız gerekiyor",
      'games-ticket-payout-info': 'Bir sonraki kuponu ödemek ister misiniz?',
      'payout-success': 'Ödeme başarıyla yapıldı',
      'poker-balance': 'Poker bakiyesi',
      'promo-bill': 'Promo hesap',
      'promo-bonus': 'Promo Bonus',
      'promo-to-unlock': 'Kilidini açacak promosyon',
      'reserved-funds': 'Ayrılmış fonlar',
      'standard-account': 'Standart hesap',
      'standard-bill': 'Standard Hesap',
      'submit-for-print-success': 'Yazdırmaya başarıyla gönderildi',
      'ticket-successfully-paid': 'Kupon başarıyla ödendi',
      'valid-to': '{{validTo}} tarihine kadar geçerlidir',
      'voucher-payout-info': 'Bir sonraki KUPONU kullanmak ister misiniz?',
      'voucher-print': 'KUPONU YAZDIR',
      'voucher-print-confirm': 'Devam etmek istiyor musunuz?',
      'voucher-print-info':
        'Bakiyedeki tüm fonlar kaldırılacak ve aynı değerde bir kupon basılacaktır.',
      wallet: 'Hesap',
      'wallet-locked': 'Hesap kilitlendi',
      'wallets-refresh': 'Hesap bilgilerinizi güncelleyiniz',
    },
  },
};

export default tr;
