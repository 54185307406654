/* React modules */

/* Our modules */
import carousel1 from 'images/home/carousel-1.png';
import carousel2 from 'images/home/carousel-2.png';
import './_home-carousel.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const HomeCarousel = observer(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="home-carousel d-flex space-between text-center">
      <div className="w-100 h-full d-flex justify-center align-items flex-column carousel-content">
        <p>{t('home.register')}</p>

        <p className="mt-4">{t('home.we-double-your-first-deposit')}</p>

        <p className="mt-5 text-green-700 fw-bold">
          {t('home.up-to-250')} &euro; +
        </p>

        <p className="text-green-700 fw-bold">{t('home.100-spins')}</p>

        <p
          className="mt-4 px-10 py-2 bg-green-700 text-black-400 place-bet-button"
          onClick={() => navigate('/sport')}
        >
          {t('home.place-bet')}
        </p>
      </div>

      <img
        src={carousel1}
        alt="Carousel part 1"
        className="w-35 carousel-image"
      />

      <img
        src={carousel2}
        alt="Carousel part 2"
        className="w-35 carousel-image"
      />
    </div>
  );
});

export default HomeCarousel;
