/* React modules */

/* Our modules */
import { Competition as CompetitionType } from 'modules/sports/sports.types';
import SportNode, {
  NODE_TYPE,
} from 'modules/sports/services/sport-node.service';

/* 3rd Party modules */
import { action, autorun, makeObservable, observable } from 'mobx';

class Competition extends SportNode {
  isFavorite: boolean = false;
  order: number = 0;
  locationOrder: number = 0;
  locationId: number;
  shortName: string;

  constructor(node: CompetitionType, locationId: number) {
    super(node, NODE_TYPE.COMPETITION);

    /*
    makeObservable(this, {
      name: observable,
      shortName: observable,
      order: observable,
      locationOrder: observable,
      isFavorite: observable,
      children: observable,
      removeEvent: action,
      addEvent: action,
      update: action,
    });
    */

    makeObservable(this, {
      // from super class
      children: observable,
      name: observable,
      addEvent: action,
      removeEvent: action,

      // from this class
      isFavorite: observable,
      order: observable,
      locationOrder: observable,
      locationId: false,
      shortName: observable,
      watchEvents: action,
      update: action,
      hasEvent: action,
    });

    this.isFavorite = node.isFavorite;
    this.order = node.competitionOrder;
    this.locationOrder = node.locationOrder;
    this.locationId = locationId;
    this.shortName = node.shortName;
    this.watchEvents();
  }

  watchEvents() {
    autorun(() => {
      const removedEvent = this.children.find((e) => e.deleted);
      this.removeEvent(removedEvent);
    });
  }

  update = (data: any) => {
    this.isFavorite = data.is_favorite;
    this.order = data.competition_order;
    this.locationOrder = data.location_order;
    this.name = data.name;
    this.locationId = data.location_id;
    this.shortName = data.short_name;
  };

  hasEvent(eventId: string) {
    return this.children.some((event: any) => event.id === eventId);
  }
}

export default Competition;
