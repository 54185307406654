/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import useQuery from 'libs/useQuery';
import { MonriComponentsForm } from 'modules/payment/ui/MonriComponentsForm/MonriComponentsForm';
import { isNumber } from 'libs/common-helpers';
import './MonriPaymentMobile.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const MonriPaymentMobile = observer(() => {
  const { t } = useTranslation();

  const { authStore } = useStores();

  let queryParams = useQuery();

  const [amount, setAmount] = useState<number>(0);

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  useEffect(() => {
    const tokenParameter = getParam('token') || '';
    const amountParameter = getParam('amount') || '';

    if (tokenParameter && amountParameter) {
      authStore.setToken(tokenParameter);

      if (isNumber(+amountParameter)) {
        setAmount(+amountParameter);
      }
    } else {
      toast.error(t('errors.system-error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="monri-payment-mobile">
      {amount && <MonriComponentsForm amount={amount} isFromMobile />}
    </div>
  );
});

export { MonriPaymentMobile };
