/* React modules */
import { useEffect } from 'react';

/* Our modules */
import HomeCarousel from 'pages/home/components/HomeCarousel/HomeCarousel';
import PageCard from 'pages/home/components/PageCard/PageCard';
import OurApps from 'pages/home/components/OurApps/OurApps';
import HomeFooter from 'pages/home/components/HomeFooter/HomeFooter';
import './_home.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

export interface HomeCard {
  title: string;
  description: string;
  path: string;
}

const Home = observer(() => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const cards: HomeCard[] = [
    {
      title: t('navigation.betting'),
      description: t('home.betting-description'),
      path: '/sport',
    },
    {
      title: t('navigation.casino'),
      description: t('home.casino-description'),
      path: '/gambling',
    },
    {
      title: t('navigation.quickGames'),
      description: t('home.quick-games-description'),
      path: '/quick-games',
    },
    {
      title: t('navigation.promotions'),
      description: t('home.promotions-description'),
      path: 'https://promo.sbbet.me/',
    },
  ];

  useEffect(() => {
    const dateVisited = window.localStorage.getItem('LandingPageVisitedDate');
    const currentDate = new Date().toISOString().split('T')[0];

    if (dateVisited && dateVisited === currentDate) {
      navigate('/sport');
    } else {
      window.localStorage.setItem('LandingPageVisitedDate', currentDate);
    }
  }, []);

  return (
    <div className="home h-full pt-3">
      <div className="mx-20">
        <div className="cards d-flex">
          {(cards || []).map((item) => (
            <PageCard data={item} />
          ))}
        </div>

        <HomeCarousel />

        <OurApps />
      </div>

      <HomeFooter />
    </div>
  );
});

export default Home;
