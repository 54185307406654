/* React modules */

/* Our modules */

/* 3rd Party modules */
import classnames from 'classnames';
import { useFlexLayout, useTable } from 'react-table';
import { Virtuoso } from 'react-virtuoso';

export interface SimpleTableProps {}

const SimpleTable = ({
  columns,
  data,
  loadMore,
  tableFooter,
  onRowClick,
}: any) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFlexLayout
    );

  const renderRow = (row: any, i: number) => {
    prepareRow(row);

    return (
      <div
        onClick={() => (onRowClick ? onRowClick(row.original) : null)}
        className={classnames('tr', {
          even: i % 2 === 0,
          pointer: !!onRowClick,
        })}
        {...row.getRowProps()}
      >
        {row.cells.map((cell: any, index: number) => {
          return (
            <div
              key={`renderRow-div-${index}`}
              className="td"
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="table" {...getTableProps()}>
      <div className="thead">
        {headerGroups.map((headerGroup, i) => (
          <div
            className="tr uppercase"
            {...headerGroup.getHeaderGroupProps()}
            key={`SimpleTable-div-1-${i}`}
          >
            {headerGroup.headers.map((column, index) => (
              <div
                className="th"
                {...column.getHeaderProps()}
                key={`SimpleTable-div-2-${index}`}
              >
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody" {...getTableBodyProps()}>
        {loadMore && (
          <Virtuoso
            components={tableFooter ? { Footer: tableFooter } : {}}
            style={{
              width: '100%',
              height: 'calc(100vh - 320px)',
              overflowX: 'hidden',
            }}
            endReached={loadMore}
            data={rows}
            itemContent={(i: number, row: any) => renderRow(row, i)}
          />
        )}

        {!loadMore && rows.map(renderRow)}
      </div>
    </div>
  );
};

export default SimpleTable;
