/* React modules */

/* Our modules */
import { EventMessageActions, FeedMessageType } from 'modules/feed/feed.api';
import {
  Competition,
  Event as EventType,
  Location,
  Sport,
} from 'modules/sports/sports.types';
import Event from 'modules/sports/store/event.store';
import CompetitionNode from 'modules/sports/store/competition.store';
import feedStore from 'modules/feed/feed.store';
import sportsStore from 'modules/sports/store/sports.store';
import LocationNode from 'modules/sports/store/location.store';
import SportNode, {
  NODE_TYPE,
} from 'modules/sports/services/sport-node.service';
import { hasProperty } from 'libs/common-helpers';

/* 3rd Party modules */

const SOCKET_EVENT_TYPES: any = {
  P: 'player',
  O: 'antepost',
  G: 'special',
};

const getEventType = (event: any) => {
  if (event.i_l) {
    return 'live';
  }

  if (hasProperty(event, 'pa')) {
    return event.pa ? SOCKET_EVENT_TYPES[event.pa] : 'upcoming';
  }

  return 'upcoming';
};

export const normalizeFeedEvent = (event: any) => ({
  id: event.e_id,
  name: event.e_n,
  start: { seconds: event.e_s },
  type: getEventType(event),
  sportId: event.pas ? event.pas : event.s,
  competitionId: event.c,
  locationId: event.l,
  eventCode: event.code,
  result: event.r,
  oddsList: event.odds?.map((odd: any) => ({
    isDisabled: odd.d,
    id: odd.id,
    outcomeId: odd.o,
    value: odd.v,
    limit: odd.l,
    status: odd.s,
  })),
});

class SportsData {
  sportsList: SportNode[] = [];
  sportMap: Map<number, Sport> = new Map();
  competitionBySportAndLocationIdNodeMap: Map<
    number,
    Map<number, Map<number, SportNode>>
  > = new Map();

  constructor(sports: Sport[], events: EventType[]) {
    this.initialize(sports, events);
    this.connectToFeed();
  }

  initialize = (sports: Sport[], events: EventType[]) => {
    this.setupSports(sports);
    this.setupEvents(events);
  };

  connectToFeed = () => {
    feedStore.registerHandler(FeedMessageType.EVENT, this.onEventUpdate);
    feedStore.registerHandler(
      FeedMessageType.COMPETITION,
      this.onCompetitionUpdate
    );
  };

  getSpecials = (sport: Sport) => {
    return this.getLocationNodes(
      sport,
      sportsStore.specialsSport.locationsList
    );
  };

  setupSports = (sports: Sport[]) => {
    this.sportsList = sports.map((sport) => {
      if (!this.sportMap) {
        this.sportMap = new Map();
      }
      this.sportMap.set(sport.id, sport);

      const sportNode = new SportNode(sport, NODE_TYPE.SPORT);
      const { locationsList } = sport;

      const locationNodes = locationsList.map((location) => {
        if (!sport.locationMap) {
          sport.locationMap = new Map();
        }
        sport.locationMap.set(location.id, location);

        const { competitionsList } = location;
        const locationNode = new LocationNode(location);

        const competitionNodes = competitionsList.map((competition) => {
          const comNode = new CompetitionNode(competition, location.id);

          if (!location.competitionMap) {
            location.competitionMap = new Map();
          }

          location.competitionMap.set(competition.id, competition);

          if (!this.competitionBySportAndLocationIdNodeMap.get(sport.id)) {
            this.competitionBySportAndLocationIdNodeMap.set(
              sport.id,
              new Map()
            );
          }
          if (
            !this.competitionBySportAndLocationIdNodeMap
              .get(sport.id)
              ?.get(location.id)
          ) {
            this.competitionBySportAndLocationIdNodeMap
              .get(sport.id)
              ?.set(location.id, new Map());
          }

          this.competitionBySportAndLocationIdNodeMap
            .get(sport.id)
            ?.get(location.id)
            ?.set(competition.id, comNode);

          return comNode;
        });

        locationNode.push(competitionNodes);
        return locationNode;
      });

      sportNode.push(locationNodes);
      sportNode.push(this.getSpecials(sport));
      return sportNode;
    });
  };

  getLocationNodes = (sport: Sport, locations: Location[]) => {
    const sportId = sport.id;
    return locations.map((location) => {
      const { competitionsList } = location;

      if (!sport.locationMap) {
        sport.locationMap = new Map();
      }

      sport.locationMap.set(location.id, location);

      if (!location.competitionMap) {
        location.competitionMap = new Map();
      }

      const locationNode = new LocationNode(location);

      const competitionNodes = competitionsList.map((competition) => {
        const comNode = new CompetitionNode(competition, location.id);

        location.competitionMap.set(competition.id, competition);

        if (!this.competitionBySportAndLocationIdNodeMap.get(sportId)) {
          this.competitionBySportAndLocationIdNodeMap.set(sportId, new Map());
        }
        if (
          !this.competitionBySportAndLocationIdNodeMap
            .get(sportId)
            ?.get(location.id)
        ) {
          this.competitionBySportAndLocationIdNodeMap
            .get(sportId)
            ?.set(location.id, new Map());
        }

        this.competitionBySportAndLocationIdNodeMap
          .get(sportId)
          ?.get(location.id)
          ?.set(competition.id, comNode);

        return comNode;
      });

      locationNode.push(competitionNodes);
      return locationNode;
    });
  };

  getCompetitionNodes = (
    competitionsList: Competition[],
    locationId: number
  ) => {
    return competitionsList.map(
      (competition) => new CompetitionNode(competition, locationId)
    );
  };

  setupEvents = (events: EventType[]) => {
    events.forEach((event: any) => {
      this.addEvent(event);
    });
  };

  findSportById = (id: number) => {
    return this.sportsList.find((sport) => sport.id === id);
  };

  onEventUpdate = (feedData: any) => {
    const { a } = feedData;

    if (a === EventMessageActions.CREATE) {
      const event = normalizeFeedEvent(feedData);
      const sport = this.findSportById(event.sportId);
      const competition = sport?.findNode(
        NODE_TYPE.COMPETITION,
        event.competitionId
      );

      if (!competition) {
        sportsStore
          .getMissingCompetition(event.competitionId)
          .then((data: any) => {
            if (sport && data && data.competition) {
              const location = sport.findNode(
                NODE_TYPE.LOCATION,
                event.locationId
              );

              if (location) {
                const competitionNode = new CompetitionNode(
                  data.competition,
                  location.id
                );
                location.addNode(competitionNode);
                this.addEvent(event);
              }
            }
          });
      } else {
        if (competition.hasEvent(event.id)) {
          competition.removeEvent(event);
        }
        this.addEvent(event);
      }
    }
  };

  addEvent = (event: any) => {
    if (!this.sportMap) {
      return;
    }

    const sport = this.sportMap.get(event.sportId);

    if (sport) {
      const eventInstance = this.getEventInstance(event, sport);

      if (eventInstance) {
        this.competitionBySportAndLocationIdNodeMap
          .get(event.sportId)
          ?.get(event.locationId)
          ?.get(event.competitionId)
          ?.addEvent(eventInstance);
      }
    }
  };

  getEventInstance = (event: EventType, sport: Sport) => {
    const { locationId, competitionId } = event;

    if (!sport.locationMap) return;

    const location = sport.locationMap.get(locationId);
    const competition = location?.competitionMap.get(competitionId);

    return new Event({
      ...event,
      sportName: sport.name,
      competition: competition,
      locationName: location?.name ?? '',
    });
  };

  onCompetitionUpdate = (data: any) => {
    const sport = this.findSportById(data.sport_id);

    if (sport) {
      const competition = sport.findNode(NODE_TYPE.COMPETITION, data.id);

      if (competition) {
        this.updateCompetition(sport, competition, data);
      } else {
        this.addNewCompetition(sport, data);
      }
    }
  };

  updateCompetition = (
    sport: SportNode,
    competition: CompetitionNode,
    data: any
  ) => {
    const oldLocation = sport.findNode(
      NODE_TYPE.LOCATION,
      competition.locationId
    );
    competition.update(data);

    const location = sport.findNode(NODE_TYPE.LOCATION, data.location_id);
    const isSameLocation = location.findNode(
      NODE_TYPE.COMPETITION,
      competition.id
    );

    if (!isSameLocation) {
      oldLocation.removeNode(competition.id);
      location.addNode(competition);
    }

    competition.children.forEach((event) => {
      event.assignCompetition(competition.id);
    });
  };

  addNewCompetition = (sport: SportNode, competition: any) => {
    const location = sport.findNode(
      NODE_TYPE.LOCATION,
      competition.location_id
    );

    location?.addNode(
      new CompetitionNode(
        {
          ...competition,
          shortName: competition.short_name,
          isFavorite: competition.is_favorite,
          competitionOrder: competition.competition_order,
        },
        location.id
      )
    );
  };
}

export default SportsData;
