/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { PokerErrors, PokerError } from 'modules/payment/payment.types';
import SectionHeader from 'modules/user/ui/SectionHeader';
import CreditDebitAmountPicker from 'modules/user/ui/UserCreditDebit/CreditDebitAmountPicker/CreditDebitAmountPicker';
import { logger } from 'libs/common-helpers';
import 'modules/user/ui/UserCreditDebit/CreditDebitBody/CreditDebitBody.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PokerCredit = () => {
  const { t } = useTranslation();

  const {
    userStore: { pokerBalance, setPokerWithdrawal },
  } = useStores();

  const handleSubmitCreditError = (error: string | PokerError) => {
    switch (error) {
      case PokerErrors.INVALID_USER:
        toast.error(t('errors.invalid-user'));
        break;
      case PokerErrors.NOT_ENOUGH_FUNDS:
        toast.error(t('validations.validation-balance'));
        break;
      case PokerErrors.POKER_NOT_RESPONDING:
        toast.error(t('errors.poker-not-responding'));
        break;
      case PokerErrors.POKER_SUCCESS_FALSE:
        toast.error(t('errors.poker-success-false'));
        break;
      case PokerErrors.CANCEL_FUNDS:
        toast.error(t('errors.cancel-funds'));
        break;
      default:
        toast.error(t('errors.system-error'));
        break;
    }
  };

  const handleSubmitCredit = async (amount: number) => {
    try {
      await setPokerWithdrawal(amount);

      toast.success(t('payments.poker-withdrawal-success'));
    } catch (exception: any) {
      handleSubmitCreditError(exception.data.detail);

      logger('PokerCredit -> handleSubmitCredit -> exception', exception);
    }
  };

  return (
    <div className="user-credit-debit">
      <SectionHeader title={t('payments.credit')} />

      <div className="user-credit-debit__body w-100">
        <CreditDebitAmountPicker
          isPoker
          max={pokerBalance}
          buttonText={t('payments.withdraw')}
          isDebit={false}
          onSubmit={handleSubmitCredit}
        />
      </div>
    </div>
  );
};

export default observer(PokerCredit);
