/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Icon from 'components/Icon';
import { LoaderWrapper } from 'components/Loader';
import './NoEvents.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const NoEvents = observer(() => {
  const { sportsStore, loaderStore } = useStores();
  const { allEventsLoaded } = sportsStore;
  const { t } = useTranslation();

  useEffect(() => {
    if (!allEventsLoaded) loaderStore.addLoader('stream-loader');
    else loaderStore.removeLoader('stream-loader');
  }, [allEventsLoaded]);

  return (
    <div className="noEvents">
      <LoaderWrapper name="stream-loader">
        <div>
          <div className="d-flex justify-center align-items">
            <Icon size="2-extra-large" name="information" />
          </div>
          <p className="text-center sb-text-regular">
            {t('sportsPage.no-events')}
          </p>
        </div>
      </LoaderWrapper>
    </div>
  );
});

export default NoEvents;
