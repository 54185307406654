/* React modules */

/* Our modules */
import {
  TIME_FILTER,
  TIME_FILTER_OPTION,
} from 'modules/offer/store/offer.filters';
import './TimeFilter.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface TimeFilterProps {
  filters: TIME_FILTER_OPTION[];
  selected: TIME_FILTER;
  onChange: (filter: TIME_FILTER) => void;
}

const TimeFilter = ({ filters, selected, onChange }: TimeFilterProps) => {
  const { t } = useTranslation();

  return (
    <div className="sports-time-filter">
      {filters.map((filter) => (
        <span
          key={`TimeFilter-span-${filter.value}`}
          className={classNames({
            active: selected === filter.value,
          })}
          onClick={() => onChange(filter.value)}
        >
          {t(`sportsPage.${filter.label}`)}
        </span>
      ))}
    </div>
  );
};

export default TimeFilter;
