/* React modules */
import { useState, useEffect, Fragment } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SectionHeader from 'modules/user/ui/SectionHeader';
import MobileItem from 'modules/user/ui/UserMarket/Market/MobileItem/MobileItem';
import './Market.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Tooltip from 'rc-tooltip';

const Market = () => {
  const { t } = useTranslation();
  const { marketStore, userStore } = useStores();
  const { casinoBonus } = userStore;
  const {
    storeItems,
    fetchStoreItems,
    getCart,
    addToCart,
    removeFromCart,
    isDigital,
    cartCount,
  } = marketStore;

  const [hoverEl, setHoverEl] = useState<number | null>(null);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const handleOnMouseEnter = (id: number) => setHoverEl(id);
  const handleOnMouseLeave = () => setHoverEl(null);

  const handleExpand = (id: number) => {
    if (!expandedItems.includes(id)) {
      setExpandedItems([...expandedItems, id]);
    } else {
      const newArray = expandedItems.filter((item: any) => item !== id);
      setExpandedItems(newArray);
    }
  };

  useEffect(() => {
    if (!storeItems.length) fetchStoreItems();
  }, []);

  const addToCartHandler = (item: any) => {
    if (isDigital && item.digital && cartCount > 0) {
      toast.error(t('errors.cannot-add-more-than-one-digital-item'));
    } else if (isDigital && !item.digital && cartCount > 0) {
      toast.error(t('errors.cannot-order-mixed-items'));
    } else if (!isDigital && item.digital && cartCount > 0) {
      toast.error(t('errors.cannot-order-mixed-items'));
    } else if (item.digitalType === 'casino' && casinoBonus) {
      toast.error(t('errors.cannot-buy-casino-bonus-if-you-has-one'));
    } else {
      addToCart(item);
    }
  };

  return (
    <div>
      <SectionHeader title={'MARKET'} hasCart />

      <div className="market">
        {storeItems &&
          storeItems.map((item: any, index: number) => (
            <Fragment key={`Market-Fragment-${index}`}>
              <MobileItem
                expandedItems={expandedItems}
                item={item}
                onClick={handleExpand}
              />

              {/*DESKTOP ITEM*/}
              <div
                onMouseEnter={() => handleOnMouseEnter(item.id)}
                onMouseLeave={handleOnMouseLeave}
                className="market__item-desktop pointer"
              >
                {getCart.filter((i: any) => i.id === item.id).length > 0 && (
                  <div className="market__item-desktop-flag">
                    <Icon size="regular" name="cartGreen" />
                  </div>
                )}
                {hoverEl === item.id && (
                  <div className="market__item-desktop-hover d-flex align-items justify-center">
                    {getCart.filter((i: any) => i.id === item.id).length ===
                      0 && (
                      <Button
                        bg="green"
                        size="small"
                        className="text-sb-extra-small uppercase"
                        onClick={() => addToCartHandler(item)}
                      >
                        {t('globals.add-to-cart')}
                      </Button>
                    )}

                    {getCart.filter((i: any) => i.id === item.id).length >
                      0 && (
                      <Button
                        bg="grey"
                        size="small"
                        className="text-sb-extra-small uppercase"
                        onClick={() => removeFromCart(item.id)}
                      >
                        {t('globals.remove-from-cart')}
                      </Button>
                    )}
                  </div>
                )}
                <div
                  className={classnames(
                    hoverEl === item.id
                      ? 'market__item-desktop-image-hover'
                      : ''
                  )}
                >
                  <img
                    className="market__item-desktop-image"
                    src={item.imageUrl}
                    alt=""
                  />
                </div>
                <div className="sb-text-regular">
                  <div className="d-flex align-items space-between mt-2">
                    <Tooltip
                      key={`Market-Tooltip-1-${item.id}`}
                      placement="bottom"
                      overlay={item.name}
                      overlayClassName=""
                    >
                      <p className="text-yellow-500 text-ellipsis">
                        {item.name}
                      </p>
                    </Tooltip>

                    <p className="text-white w-38 text-right">
                      Poena:{' '}
                      <span className="text-green-800 text-extra-strong">
                        {item.price}
                      </span>
                    </p>
                  </div>

                  <Tooltip
                    key={`Market-Tooltip-2-${item.id}`}
                    placement="bottom"
                    overlay={item.description}
                    overlayClassName="w-12"
                  >
                    <p className="mt-2 text-white text-ellipsis">
                      {item.description}
                    </p>
                  </Tooltip>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default observer(Market);
