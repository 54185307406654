/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import Input from 'components/Input';
import SearchEventCell from 'pages/sport/EventSearchModal/SearchEventCell';
import './EventSearchModal.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const EventSearchModal = observer(() => {
  const { t } = useTranslation();
  const { offerStore, offerFilters } = useStores();
  const [searchString, setSearchString] = useState('');
  const [events, setEvents] = useState([]);

  const handleSearchInput = (e: any) => {
    setSearchString(e.target.value);
  };

  const allEvents: any = [];
  offerStore.offer.forEach((types: any, i: number) => {
    types.events.forEach((event: any) => {
      allEvents.push(event);
    });
  });

  useEffect(() => {
    setEvents(
      allEvents.filter(
        (e: any) =>
          e.type !== EVENT_TYPE.ANTEPOST &&
          !!e.name.toLowerCase().includes(searchString.toLowerCase())
      )
    );
  }, [searchString]);

  return (
    <div className="search-modal">
      <h4 className="px-5 pt-3">
        {t('globals.search-events')}: "
        {t(`sportsPage.${offerFilters.eventFilter}`).toLowerCase()}"
      </h4>
      <div className="pt-5 px-5">
        <Input
          onChange={handleSearchInput}
          placeholder={t('globals.search-1')}
          name="input"
          bg="light"
        />
      </div>
      {searchString.length > 1 && (
        <ul className="search-modal__list mt-5 pb-5 sb-text-small">
          {events.map((event: any) => (
            <li
              key={`EventSearchModal-li-${event.id}`}
              className="search-modal__list-item py-3 px-6"
            >
              <SearchEventCell value={{ event: event || [] }} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default EventSearchModal;
