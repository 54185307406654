/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { TICKET_STATUS_OPTIONS } from 'modules/ticket/ticket.constants';
import { TicketStatuses } from 'modules/ticket/ticket.types';
import useStores from 'common/hooks/useStores';
import StatusAccordion from 'modules/ticket/ui/TicketSidebar/Status/StatusAccordion';
import { Input, Select } from 'components';
import Loader, { LoaderWrapper } from 'components/Loader';
import './Status.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import AutoSizer from 'react-virtualized-auto-sizer';

interface TicketCountProps {
  count: number | undefined;
}

const TicketCount = ({ count }: TicketCountProps) => {
  const { t } = useTranslation();

  return (
    <div className="status__count ml-2">
      <div className="d-flex">
        <span className="mr-2">{t('slips.number-of-tickets')}</span>
        <span>{count || 0}</span>
      </div>
    </div>
  );
};

interface TicketStatusListProps {
  status: TicketStatuses;
  onSearch: (e: any) => void;
  search: string;
}

interface TicketStatusOptionProps {
  value: TicketStatuses;
  label: string;
}

let searchDebounce: any;

const TicketStatusList = observer(
  ({ status, search }: TicketStatusListProps) => {
    const { t } = useTranslation();
    const { ticketsStore } = useStores();
    const {
      getTickets,
      setTicketsPage,
      resetTickets,
      tickets,
      ticketsPagination,
      ticketsRequestInProgress,
    } = ticketsStore;

    useEffect(() => {
      resetTickets();
      loadMore();
    }, [getTickets, status, resetTickets]);

    useEffect(() => {
      resetTickets();

      if (typeof searchDebounce === 'number') {
        clearTimeout(searchDebounce);
      }

      searchDebounce = setTimeout(() => loadMore(), 300);
    }, [getTickets, search, resetTickets]);

    const loadMore = () => {
      if (ticketsPagination.hasNext) {
        setTicketsPage(ticketsPagination.page + 1);
        getTickets(status as any, undefined, search);
      }
    };

    if (ticketsRequestInProgress)
      return (
        <div className="status__empty">
          <Loader />
        </div>
      );

    return !!tickets.length ? (
      <>
        <TicketCount count={tickets.length} />
        <div className="status-tab-wrapper">
          <AutoSizer>
            {({ height, width }: any) => (
              <Virtuoso
                className="status-tab-wrapper__tickets"
                style={{ height, width }}
                endReached={loadMore}
                data={tickets}
                itemContent={(_index: number, ticket: any) => (
                  <StatusAccordion ticket={ticket} />
                )}
              />
            )}
          </AutoSizer>
        </div>
      </>
    ) : (
      <div className="status__empty">
        <span>{t('slips.no-tickets')}</span>
      </div>
    );
  }
);

const Status = () => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const {
    authStore: { isLoggedIn },
  } = useStores();
  const statusOptions = TICKET_STATUS_OPTIONS(t);
  const [status, setStatus] = useState<TicketStatusOptionProps>(
    statusOptions[0]
  );

  const onStatusChange = (status: TicketStatusOptionProps) => {
    setStatus(status);
  };

  const updateInput = (changeEvent: any) => {
    const inputValue = changeEvent.target.value;
    setInput(inputValue);
  };

  return (
    <LoaderWrapper name="ticketsStatus">
      <div className="status" data-testid="status">
        {isLoggedIn ? (
          <>
            <div className="status__search">
              <div className="d-flex">
                <div className="status__search--half text-strong">
                  <Select
                    onChange={onStatusChange}
                    options={statusOptions}
                    value={status}
                    size="sb-market"
                    bg="sb-dark"
                  />
                </div>
                <div className="status__search--half">
                  <Input
                    name="ticket-search"
                    placeholder={t('slips.ticket-id')}
                    height="small"
                    bg="transparent"
                    className="text-center"
                    onChange={updateInput}
                    value={input}
                    theme="search-small"
                  />
                </div>
              </div>
            </div>
            <TicketStatusList
              status={status.value}
              onSearch={updateInput}
              search={input}
            />
          </>
        ) : (
          <div className="status__empty status__empty-sign-in">
            <span>
              {isLoggedIn ? t('slips.no-tickets') : t('slips.sign-in-status')}
            </span>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
};

export default observer(Status);
