/* React modules */
import { useEffect, createContext } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import GamblingSidebar from 'modules/gambling/ui/GamblingSidebar';
import GamesFilterMobile from 'modules/gambling/ui/GamesFilterMobile';
import GamblingHomepage from 'modules/gambling/ui/GamblingHomepage';
import PromoSidebar from 'modules/gambling/ui/PromoSidebar';
import { LoaderWrapper } from 'components/Loader';
import useQuery from 'libs/useQuery';
import './Gambling.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

export const GridViewContext = createContext(() => {});

const Gambling = ({ live, mobile }: any) => {
  const {
    gamblingStore: { initGambling, setIsLive, filters },
    authStore: { setToken },
    userStore: { getUserData },
  } = useStores();

  const navigate = useNavigate();
  let queryParams = useQuery();

  useEffect(() => {
    return () => {
      filters.clearFilters();
      filters.setFilters({ game_name: '' });
    };
  }, []);

  useEffect(() => {
    setIsLive(live);
    initGambling();
  }, [initGambling, setIsLive, live]);

  useEffect(() => {
    const token = queryParams.get('token') || '';

    if (token) {
      setToken(token);
      getUserData();
      if (!live) {
        navigate('/gambling-mobile');
      } else {
        navigate('/gambling-mobile-live');
      }
    }
  }, []);

  return (
    <div className={!mobile ? 'gambling' : 'gambling__mobile'}>
      <div className="gambling__sidebar d-flex flex-column  visible-mobile-up">
        <GamblingSidebar live={live} />
      </div>
      <div className="visible-mobile-down">
        <GamesFilterMobile mobile={mobile} />
      </div>
      {/*<div className="gambling__content text-align">*/}
      <div
        className={!mobile ? 'gambling__content' : 'gambling__content-mobile'}
      >
        <LoaderWrapper name="gambling">
          <GamblingHomepage live={live} mobile={mobile} />
        </LoaderWrapper>
      </div>
      <div className="gambling__sidebar d-flex flex-column visible-desktop-up">
        <PromoSidebar />
      </div>
    </div>
  );
};

Gambling.defaultProps = {
  live: false,
  mobile: false,
};

export default observer(Gambling);
