/* React modules */

/* Our modules */
import SportsApi from 'modules/sports/services/sports.api';
import {
  Event as IEvent,
  EventOdd,
  EVENT_TYPE,
  EventStart,
} from 'modules/sports/sports.types';
import { RESULTS_MAP } from 'modules/sports/constants';
import sportsStore from 'modules/sports/store/sports.store';
import Competition from 'modules/sports/store/competition.store';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import loaderStore from 'components/Loader/loader.store';
import Odd from 'modules/sports/store/odd.store';
import eventFeedService from 'modules/sports/services/event-feed.service';
import { NODE_TYPE } from 'modules/sports/services/sport-node.service';
import {
  formatTime,
  getDayString,
  getMonthString,
  toDate,
} from 'libs/datetime';
import { hasProperty, logger, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import { runInAction, autorun, makeAutoObservable } from 'mobx';

const getEventDateTime = (
  event: IEvent
): [date: string, day: string, englishDay: string, time: string] => {
  const datetime = toDate(event.start.seconds);
  const hours_minutes = formatTime(datetime);
  const datestring = `${datetime.getDate()}. ${getMonthString(
    datetime
  )}. ${datetime.getFullYear()}.`;

  return [
    datestring,
    getDayString(datetime),
    getDayString(datetime, true),
    `${hours_minutes}`,
  ];
};

export const getEventCompetitors = (
  event: IEvent
): [home: string, away: string] => {
  const [home, away] = event.name.split(' - ');
  return [home, away];
};

const getPeriodName = (time: any, pauseValues: any) => {
  let name = time ? (hasProperty(time, 'n') ? time.n : time.N) : '';

  if (pauseValues && name && pauseValues[name]) {
    return pauseValues[name];
  }

  return name || '';
};

const getMinutes = (time: any, pausePhaseValues: any) => {
  const currentTime = time ? (hasProperty(time, 'T') ? time.T : time.t) : null;
  const period = time ? (hasProperty(time, 'N') ? time.N : time.n) : '';

  if (!currentTime || hasProperty(pausePhaseValues, period)) return '';

  return currentTime.M || currentTime.m || '';
};

const getCurrentTime = (sport: string, time: any) => {
  const pausePhaseValues = RESULTS_MAP[sport]?.pausePhaseValues;
  const periodName = getPeriodName(time, pausePhaseValues);
  const minutes = getMinutes(time, pausePhaseValues);

  return `${periodName} ${minutes ? minutes + "'" : ''}`;
};

export const parseResult = (result: any, replacingScoreValues: any) => {
  if (!result) return ['', ''];

  return (result.split('-') || []).map((score: string) => {
    if (replacingScoreValues[score]) return replacingScoreValues[score];
    return score;
  });
};

export interface ResultPeriod {
  name: string;
  homeScore: string;
  awayScore: string;
}

class Event {
  sportsApi: SportsApi;

  // Event data
  id = '';
  home = '';
  away = '';
  name = '';
  day = '';
  englishDay = '';
  time = '';
  date = '';
  start: EventStart = { nanos: 0, seconds: 0 };
  eventCode = 0;
  locationId = 0;
  locationName = '';
  competitionId = 0;
  competition: Competition | null = null;
  sportName = '';
  sportId = 0;
  type: any = EVENT_TYPE.UPCOMING;
  rawOddsList: EventOdd[] = [];
  odds: Odd[] = [];
  allOdds: Odd[] = [];
  result: any = null;
  liveTime: string | null = null;
  isBreak = false;
  suspendedMarkets: number[] = [];
  allOddsLoaded: boolean = false;
  deleted = false;
  disabled = false;
  exefeedId = '';
  betradarId = '';
  socketMessage: number | null = null;
  profile: number = 0;
  liveStreamId = '';

  constructor(rawData: IEvent) {
    this.setData(rawData);
    this.sportsApi = new SportsApi();

    eventFeedService.connectEvent(this.id, {
      onResultChange: this.onResultChange,
      onTimeChange: this.onTimeChange,
      onDelete: this.onDeleted,
      toggleDisabled: this.toggleDisabled,
      onUpdate: this.onSocketMessage,
    });

    autorun(() => {
      if (this.isSuspended) {
        ticketBuilder.onEventDisabled(this.id);
      } else {
        ticketBuilder.onEventEnabled(this.id);
      }
    });

    /*
    makeObservable(this, {
      competition: observable,
      odds: observable,
      result: observable,
      type: observable,
      liveTime: observable,
      isBreak: observable,
      deleted: observable,
      disabled: observable,
      exefeedId: observable,
      betradarId: observable,
      assignCompetition: action,
      onResultChange: action,
      getAllOdds: action,
      onDeleted: action,
      toggleDisabled: action,
      onSocketMessage: action,
      isSuspended: computed,
      eventTime: computed,
      currentResult: computed,
      periodsShort: computed,
      periods: computed,
      competitionName: computed,
      competitionShortDisplay: computed,
      allOddsLoaded: observable,
    });
    */
    makeAutoObservable(this);
  }

  setData = (event: IEvent) => {
    const [date, day, englishDay, time] = getEventDateTime(event);
    const [home, away] = getEventCompetitors(event);

    this.id = event.id;
    this.type = event.type;
    this.day = day;
    this.englishDay = englishDay;
    this.date = date;
    this.time = time;
    this.name = event.name;
    this.start = event.start;
    this.home = home;
    this.away = away;
    this.eventCode = event.eventCode;
    this.locationId = event.locationId;
    this.competitionId = event.competitionId;
    this.competition = event.competition;
    this.sportName = event.sportName;
    this.locationName = event.locationName;
    this.sportId = event.sportId;
    this.rawOddsList = event.oddsList;
    this.disabled = event.isDisabled;
    this.exefeedId = event.exefeedId;
    this.betradarId = event.betradarId;
    this.liveStreamId = event.betradarStreamId || '';

    if (this.type === EVENT_TYPE.LIVE && event.result) {
      this.onTimeChange(event.result.currentPhase);
      this.onResultChange(event.result);
    }

    if (event.profile) {
      this.profile = event.profile === 11 ? 0 : event.profile;
    }
  };

  getAllOdds = async () => {
    loaderStore.addLoader(this.id);
    try {
      const response = await this.sportsApi.getEventOdds(this.id);

      runInAction(() => {
        this.allOdds = (response.oddsList || []).map((odd: any) => {
          const initial = this.odds.find((o: Odd) => o.id === odd.id);
          if (initial) return initial;
          return new Odd(this, {
            value: odd.value,
            id: odd.id,
            outcomeId: odd.outcomeId,
            isDisabled: odd.isDisabled,
            limit: odd.limit,
            frameNumber: odd.frameNumber,
            livePlayer: odd.livePlayer,
            status: odd.status,
          } as EventOdd);
        });
      });
    } catch (exception: any) {
      logger('event.store -> getAllOdds -> exception', exception);
    } finally {
      runInAction(() => {
        this.allOddsLoaded = true;
      });
      loaderStore.removeLoader(this.id);
    }
  };

  assignCompetition = (id: number) => {
    const { sportsData } = sportsStore;

    if (sportsData) {
      const sport = sportsData.findSportById(this.sportId);
      this.competition = sport?.findNode(NODE_TYPE.COMPETITION, id);
    }
  };

  initOdds = () => {
    if (!this.odds.length) {
      runInAction(() => {
        this.odds = (this.rawOddsList || []).map((odd) => new Odd(this, odd));
      });
    }
  };

  onStart = () => {
    this.type = EVENT_TYPE.LIVE;
  };

  onDeleted = () => {
    runInAction(() => {
      this.deleted = true;
    });
  };

  onSocketMessage = (messageNumber: number) => {
    if (
      this.socketMessage &&
      this.socketMessage + 1 !== messageNumber &&
      !this.deleted
    ) {
      sportsStore.refetchEvent(this.sportId, this.competitionId, this.id);
    } else {
      this.socketMessage = messageNumber;
    }
  };

  toggleDisabled = (isDisabled: boolean) => {
    runInAction(() => {
      this.disabled = isDisabled;
    });
  };

  onResultChange = (result: any) => {
    runInAction(() => {
      let liveResultStats = {};

      if (result) {
        if (hasProperty(result, 'l_r_s') && result.l_r_s) {
          liveResultStats = result.l_r_s;
        } else if (
          hasProperty(result, 'liveResultStats') &&
          result.liveResultStats
        ) {
          const parsedData = parseJSONString(result.liveResultStats);

          liveResultStats = parsedData ? parsedData : {};
        }
      }

      this.result = {
        currentResult: result
          ? hasProperty(result, 'c_r')
            ? result.c_r
            : result.currentResult
          : {},
        liveResultStats,
      };
    });
  };

  onTimeChange = (timeDetails: any) => {
    const time = getCurrentTime(this.sportName, timeDetails);

    if (time && time !== this.liveTime) {
      runInAction(() => {
        this.liveTime = time;
      });
    }
  };

  get competitionName() {
    if (this.competition) {
      return this.competition.name;
    }

    return '';
  }

  get competitionShortDisplay() {
    if (this.competition) {
      return this.competition.shortName
        ? this.competition.shortName.toUpperCase()
        : this.competition.name.substr(0, 3).toUpperCase();
    }

    return '';
  }

  get isSuspended() {
    return this.disabled;
  }

  get eventTime() {
    if (this.type === EVENT_TYPE.LIVE) {
      return this.liveTime;
    }

    return this.time;
  }

  get currentResult(): ResultPeriod[] {
    const resultMap = RESULTS_MAP[this.sportName];
    if (!this.result || !resultMap) return [];

    const { currentResult } = this.result;
    const { currentScoringTypes, replacingScoreValues } = resultMap;

    return (currentScoringTypes || []).map((scoringType: any) => {
      const { key, name } = scoringType;

      const result =
        currentResult && key
          ? currentResult[key] || currentResult[key.toLowerCase()]
          : null;

      const parsed = parseResult(result, replacingScoreValues);

      return {
        name,
        homeScore: parsed[0],
        awayScore: parsed[1],
      };
    });
  }

  get periodsShort(): ResultPeriod[] {
    const resultMap = RESULTS_MAP[this.sportName];
    if (!this.result || !resultMap) return [];

    const { liveResultStats } = this.result;
    const { replacingScoreValues, shortPeriodKeys, periodScoringKey } =
      resultMap;

    return (shortPeriodKeys || [])
      .map((key: string) => {
        const periodResult = liveResultStats[key];

        if (!periodResult) return null;

        const result = periodScoringKey
          ? periodResult[periodScoringKey] ||
            periodResult[periodScoringKey.toLowerCase()]
          : null;

        const parsed = parseResult(result, replacingScoreValues);

        return {
          name: key,
          homeScore: parsed[0],
          awayScore: parsed[1],
        };
      })
      .filter((r: any) => !!r);
  }

  get periods(): ResultPeriod[] {
    const resultMap = RESULTS_MAP[this.sportName];
    if (!this.result || !resultMap) return [];

    const { liveResultStats } = this.result;

    return (Object.keys(liveResultStats || {}) || []).map((period: string) => {
      const { periodScoringKey, replacingScoreValues } = resultMap;

      const result = periodScoringKey
        ? liveResultStats[period][periodScoringKey] ||
          liveResultStats[period][periodScoringKey.toLowerCase()]
        : null;

      const parsed = parseResult(result, replacingScoreValues);

      return {
        name: period,
        homeScore: parsed[0],
        awayScore: parsed[1],
      };
    });
  }
}

export default Event;
