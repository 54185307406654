/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { CasinoErrors } from 'modules/gambling/gambling.types';
import useStores from 'common/hooks/useStores';
import { Button, Icon } from 'components';
import GameInfoModal from 'modules/gambling/ui/GameInfoModal';
import GamblingCasinoModal from 'modules/gambling/ui/GamblingCasinoModal';
import Login from 'modules/auth/ui/Login';
import { useExternalLink } from 'libs/useExternalLink';
import { logger } from 'libs/common-helpers';
import defaultGameImg from 'images/default-game.png';
import './SingleGame.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const SingleGame = ({
  id,
  image,
  image_preview,
  image_filled,
  game_name,
  provider,
  lines,
  size,
  hover,
  displayAmount,
  displayName,
  play_for_fun,
  biggestWin,
  total,
  max_bet,
  min_bet,
}: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { overlayStore, gamblingStore, authStore, userStore } = useStores();
  const [imageUrl, setImageUrl] = useState('');
  const { handleExternalLink } = useExternalLink();

  const handleOnMouseEnter = () => setIsOpen(true);
  const handleOnMouseLeave = () => setIsOpen(false);
  const openLoginModal = () => {
    const modalContent = (
      <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
    );

    overlayStore.openModal(modalContent, {
      className: 'login-modal',
      width: 'small',
    });
  };

  const handleCasinoError = (error: string) => {
    switch (error) {
      case CasinoErrors.USER_BLOCKED:
        toast.error(t('errors.user-blocked-error'));
        break;
      case CasinoErrors.USER_NOT_VALIDATED:
        toast.error(t('errors.ERR_USER_NOT_VALIDATED'));
        break;
      default:
        toast.error(t('errors.error-starting-game'));
        break;
    }
  };

  const startGame = async () => {
    overlayStore.openModal(<GamblingCasinoModal />, {
      wrapClassName: 'gambling-casino-modal-wrap',
      gameName: game_name,
      onClose: () => userStore.getUserData(),
      width: 'full-screen',
    });

    try {
      await gamblingStore.getGameUrl(id, authStore.token);
    } catch (exception: any) {
      overlayStore.closeModal();

      if ((exception || {}).data) {
        const { detail } = exception.data || {};

        handleCasinoError(detail);
      }

      logger('SingleGame -> startGame -> exception', exception);
    }
  };

  const tryGame = () => {
    gamblingStore.tryGameUrl(id);
    overlayStore.openModal(<GamblingCasinoModal />, {
      wrapClassName: 'gambling-casino-modal-wrap',
      gameName: game_name,
      width: 'full-screen',
    });
  };

  const playGame = () => {
    if (authStore.isLoggedIn) {
      if (game_name && game_name.toLowerCase() === 'poker') {
        handleExternalLink(null, game_name, null);
      } else {
        startGame();
      }
    } else {
      openLoginModal();
    }
  };

  const setImage = (url: string) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      if (image.width > 0) {
        setImageUrl(url);
      }
    };
    image.onerror = () => {
      setImageUrl(defaultGameImg);
    };
  };

  const setFavouriteGame = async (gameId: number) => {
    await gamblingStore.setFavouriteGame(gameId).then(() => {
      gamblingStore.getFavouriteGames();
    });
  };

  const removeFavouriteGame = async (gameId: number) => {
    await gamblingStore.removeFavouriteGame(gameId).then(() => {
      gamblingStore.getFavouriteGames();
    });
  };

  useEffect(() => {
    if (biggestWin) {
      setImage(image);
    } else {
      setImage(image_filled);
    }
  }, [image_filled, image, biggestWin]);

  const checkIsFavouriteGame = (id: number) => {
    if (gamblingStore.favouriteGames.some((game: any) => game.id === id)) {
      return true;
    } else {
      return false;
    }
  };

  const isFavouriteGame = checkIsFavouriteGame(id);

  return (
    //@ts-ignore
    <div className={`single-game__wrapper pointer ${size}`}>
      <div
        onClick={
          size === 'medium' ||
          size === 'small' ||
          size === 'extra-small' ||
          biggestWin
            ? () =>
                overlayStore.openModal(
                  <GameInfoModal
                    setFavouriteGame={() => setFavouriteGame(id)}
                    removeFavouriteGame={() => removeFavouriteGame(id)}
                    tryGame={tryGame}
                    playGame={playGame}
                  />,
                  {
                    image,
                    max_bet,
                    min_bet,
                    total,
                    image_filled,
                    image_preview,
                    provider,
                    id: id,
                    removeFavouriteGame,
                    setFavouriteGame,
                    isFavouriteGame: isFavouriteGame,
                    name: game_name,
                    lines: lines,
                    play_for_fun: play_for_fun,
                    closable: false,
                    className: 'game-info-modal',
                  }
                )
            : undefined
        }
        onMouseEnter={hover ? handleOnMouseEnter : undefined}
        onMouseLeave={hover ? handleOnMouseLeave : undefined}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
        className={`single-game ${size} `}
      >
        {size === 'large' ? (
          <div
            className={`single-game__view ${
              isOpen ? 'open' : 'closed'
            } ${size}`}
          >
            <div className="single-game__top-bar">
              <span className="ml-2">{game_name}</span>
              {authStore.isLoggedIn && (
                <>
                  {!isFavouriteGame && (
                    <div className="mr-2" onClick={() => setFavouriteGame(id)}>
                      <Icon name="starUnselected" />
                    </div>
                  )}
                  {isFavouriteGame && (
                    <div
                      className="mr-2"
                      onClick={() => removeFavouriteGame(id)}
                    >
                      <Icon name="starSelected" />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="single-game__buttons">
              <Button
                onClick={playGame}
                className="single-game__buttons--play"
                bg="green"
                size="small"
              >
                {t('casino.play')}
              </Button>
              {play_for_fun && (
                <Button
                  onClick={tryGame}
                  className="single-game__buttons--demo"
                  bg="dark"
                  size="small"
                >
                  {t('casino.demo')}
                </Button>
              )}
            </div>
            {/* Use free spin */}
            {/*<div className="single-game__use-free-casino-btn text-white sb-text-small">*/}
            {/*  <Checkbox*/}
            {/*    key="use-free-casino"*/}
            {/*    label={t('casino.use-free-casino')}*/}
            {/*    value={isActiveFreeCasino}*/}
            {/*    onChange={() => setFreeCasino(!isActiveFreeCasino)}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        ) : null}
        {size !== 'large' && (
          <div className="single-game__top-bar mobile">
            <span>{game_name}</span>
          </div>
        )}
      </div>
      <div className={displayName ? 'single-game__name-wrapper' : ''}>
        {displayName && (
          <div className="single-game__name-wrapper--name">{game_name}</div>
        )}

        {/* JACKPOT not yet implemented on BE */}
        {displayAmount && total && (
          <div className={`single-game__jackpot text-ellipsis ${size}`}>
            {total} EUR
          </div>
        )}
      </div>
    </div>
  );
};

SingleGame.defaultProps = {
  size: 'small',
  hover: true,
  displayName: false,
};

export default observer(SingleGame);
