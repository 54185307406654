/* React modules */

/* Our modules */
import { Icon } from 'components';
import {
  customStyles,
  languageStyles,
  sbMarketStyles,
} from 'components/Select/CustomStyles';
import './Select.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import ReactSelect from 'react-select';

type SelectBg = 'light' | 'dark' | 'sb-dark' | 'grey';
type SelectSize = 'regular' | 'small' | 'sb-language' | 'sb-market';
type SelectShape = 'rounded' | 'squared';

export interface SelectOption {
  label: string | JSX.Element;
  value: number | string | boolean;
}

interface SelectProps {
  options: Array<SelectOption>;
  bg?: SelectBg;
  multiSelect?: boolean;
  className?: string;
  components?: any;
  size: SelectSize;
  placeholder: string;
  disabled: boolean;
  value: any;
  shape: SelectShape;
  testid?: string;
  onChange: (arg: any) => void;
  error?: string;
  onBlur?: (arg: any) => void;
  isSearchable?: boolean;
}

const getStyle = (size: string) => {
  switch (size) {
    case 'sb-market':
      return sbMarketStyles;
    case 'sb-language':
      return languageStyles;
    default:
      return customStyles;
  }
};

const dropdownArrowWhite = {
  IndicatorSeparator: () => null,
  DropdownIndicator: () => <Icon name="caretDown" />,
};

const Select = ({
  options,
  components,
  bg,
  className,
  size,
  placeholder,
  onChange,
  shape,
  multiSelect,
  value,
  testid,
  error,
  onBlur,
  isSearchable,
  disabled,
}: SelectProps) => (
  <div className="__select-wrapper">
    <ReactSelect
      components={size === 'sb-market' ? dropdownArrowWhite : components}
      data-testid={testid}
      styles={getStyle(size)}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      value={value}
      isDisabled={disabled}
      isSearchable={isSearchable}
      className={classnames('select', {
        [`select-${bg}`]: bg,
        [`select-${size}`]: size,
        [`select-${shape}`]: shape,
        'select-error': error && !value,
        className,
      })}
      onBlur={() => (onBlur ? onBlur({ target: { value } }) : null)}
      isMulti={multiSelect}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...(bg === 'dark' && { neutral0: '#30323a' }),
          primary25: '#a9acbe',
          primary: '#00a551',
        },
      })}
    />
    {error && !value ? <div className="input__error">{error}</div> : null}
  </div>
);

Select.defaultProps = {
  options: [],
  bg: 'light',
  size: 'regular',
  placeholder: '',
  className: '',
  value: null,
  disabled: false,
  shape: 'rounded',
  multiSelect: false,
  testid: '',
  isSearchable: false,
  components: {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => <Icon name="caretDownFill" />,
  },
};

export default Select;
