/* React modules */
import { ReactNode } from 'react';

/* Our modules */
import { EVENT_TYPE, EventType } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import { DUPLICATE_MARKETS } from 'modules/offer/store/market-switcher';
import { filterAntepostOutcomes } from 'modules/offer/services/offer-helpers.service';
import MarketCell from 'modules/offer/ui/Offer/MarketCell';
import TableTitle from 'modules/offer/ui/Offer/TableTitle';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Market = ({ children }: { children: ReactNode }) => (
  <div className={classNames('offer__header__market')}>{children}</div>
);

const Outcomes = ({
  outcomes,
  type,
  hasFrame = false,
}: {
  outcomes: any[];
  type: EventType;
  hasFrame?: any;
}) => {
  const { t } = useTranslation();

  const outcomeGroupsToShow = [1509, 1510, 1511, 1512, 1513, 1514, 1515, 1516];

  return (
    <div className="offer__header__outcomes">
      {hasFrame && (
        <div className="cell offer__header__outcome-name text-grey">FR</div>
      )}
      {(outcomes || []).some(
        (o) => (o?.exeLimit && type === EVENT_TYPE.LIVE) || o?.hasLimit
      ) && (
        <div className="cell offer__header__outcome-name text-grey">
          {t('sportsPage.limit')}
        </div>
      )}

      {(outcomes || []).map((outcome: any) => (
        <div
          key={`Outcomes-div-${outcome?.name}`}
          className="cell offer__header__outcome-name"
        >
          {outcomeGroupsToShow.includes(outcome?.id) && (
            <div>{t(`globals.${outcome.outcomeGroup}`)}&nbsp;</div>
          )}
          {type === EVENT_TYPE.LIVE ? outcome?.displayName : outcome?.name}
        </div>
      ))}
    </div>
  );
};

const findLimitOutcomeByName = (outcomes: any, name: string) => {
  return outcomes.find((o: any) => o.displayName === name);
};

const RenderMarket = (
  type: EventType,
  sport: number,
  market: any,
  i: any,
  marketSwitcher: boolean,
  defaultMarket: any,
  sportName: string
) => {
  const hasFrame = market.odds.some((o: any) => !!o.frameNumber);

  return (
    <Market key={`RenderMarket-Market-${market.name}-${i}`}>
      {marketSwitcher && type !== EVENT_TYPE.PLAYER && (
        <MarketCell
          market={market}
          eventType={type}
          sport={sport}
          position={i}
        />
      )}
      {type === EVENT_TYPE.PLAYER && <div>{market?.name}</div>}
      {DUPLICATE_MARKETS.includes(market.id) && type === EVENT_TYPE.LIVE ? (
        <Outcomes
          type={type}
          hasFrame={hasFrame}
          outcomes={[
            findLimitOutcomeByName(market.outcomes, '<'),
            findLimitOutcomeByName(market.outcomes, '>'),
          ]}
        />
      ) : (
        <Outcomes hasFrame={hasFrame} type={type} outcomes={market.outcomes} />
      )}
    </Market>
  );
};

const renderSpecialOutcomes = (type: string, defaultMarket: any) => {
  let outcomes = defaultMarket?.outcomes;
  outcomes?.sort((a: any, b: any) => a.id - b.id);

  const hiddenOutcomes = [1600];
  if (defaultMarket?.name?.toLowerCase() === EVENT_TYPE.ANTEPOST) {
    outcomes = defaultMarket.outcomes.filter(
      (o: any) => !hiddenOutcomes.includes(o.id)
    );
  }

  return (
    <Market key={`renderSpecialOutcomes-Market-${defaultMarket?.name}`}>
      {/* maybe will use for displaying market name */}
      {/*<div className="mb-1 mt-2">{defaultMarket ? defaultMarket.name : "-"}</div>*/}
      <Outcomes type={EVENT_TYPE.SPECIAL} outcomes={outcomes} />
    </Market>
  );
};

const renderFootballOutcomes = (
  outcome: any,
  i: number,
  type: string,
  defaultMarket: any
) => {
  return (
    <Market key={`renderFootballOutcomes-Market-${outcome.name}-${i}`}>
      {type === EVENT_TYPE.PLAYER && <div>{defaultMarket?.name}</div>}
      <div className="mb-1 mt-2">{outcome ? outcome.name : '-'}</div>
    </Market>
  );
};

const OfferHeader = (
  {
    data,
    periods,
    footballOutcomes,
    playerFootball = false,
    basketballPlayer = false,
  }: any,
  specialOutcomes: any
) => {
  const { marketSwitcher } = useStores();
  const shouldRenderMarketSwitcher = ![
    EVENT_TYPE.SPECIAL,
    EVENT_TYPE.PLAYER,
  ].includes(data.type);
  let markets = marketSwitcher.getFromDesktopSelection(data.sportId, data.type);

  // uses for single market headers
  const defaultMarket = periods.odds[0]?.market;

  const isSpecial = data.type === EVENT_TYPE.SPECIAL;
  const isAntepost = data.type === EVENT_TYPE.ANTEPOST;

  if (isAntepost) {
    markets = filterAntepostOutcomes(markets || [], data.events);
  }

  if (playerFootball) {
    footballOutcomes.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  let shortResult: any = [];
  if (periods) {
    shortResult = [...periods.currentResult, ...periods.periodsShort];
  }

  const tableTitle = data.subType || data.type;

  // TODO: Refacotr
  data.events?.forEach((event: any) => {
    markets?.forEach((market: any) => {
      market.odds = [];
      event?.odds?.forEach((odd: any) => {
        if (odd?.market?.id === market?.id) {
          market.odds.push(odd);
        }
      });
    });
  });

  return (
    <div key={`OfferHeader-div-1-${data?.sport}`} className="offer__header">
      <div
        className="d-flex align-items space-between"
        style={{ width: '300px' }}
      >
        <TableTitle
          tableTitle={tableTitle}
          type={data?.type}
          sport={data?.sport}
        />
        <div className="d-flex justify-end align-end pt-6 offer__header__periods">
          {shortResult.map((period: any) => (
            <div
              key={`OfferHeader-div-2-${period?.name}`}
              className="px-1 offer__header__period-name"
            >
              {period?.name}
            </div>
          ))}
        </div>
      </div>

      {!playerFootball &&
        !isSpecial &&
        markets &&
        markets.map((m: any, i: number) =>
          RenderMarket(
            data.type,
            data.sportId,
            m,
            i,
            shouldRenderMarketSwitcher,
            defaultMarket,
            data.sport
          )
        )}

      {isSpecial && renderSpecialOutcomes(data.type, defaultMarket)}

      {playerFootball &&
        footballOutcomes &&
        footballOutcomes
          .slice(0, marketSwitcher.desktopMarketsNumber)
          .map((o: any, i: number) =>
            renderFootballOutcomes(o, i, data.type, defaultMarket)
          )}
      <div style={{ height: '60px' }} />
    </div>
  );
};

export default observer(OfferHeader);
