import React from 'react';
import Tooltip from 'rc-tooltip';

import { Icon } from 'components';

export interface StatsButtonProps {
  collapsed: boolean;
  onClick: () => void;
}

const StatsButton = ({ onClick, collapsed }: StatsButtonProps) => (
  <Tooltip
    placement="top"
    overlay={collapsed ? 'Otvori statistiku' : 'Sakrij statistiku'}
    overlayClassName="tooltip"
  >
    <div onClick={onClick} className="all-odds-overlay__stats-button">
      <Icon name={collapsed ? 'angleLeft' : 'angleRight'} className="ml-2" />
      <span className="ml-1">STATS</span>
      <Icon className="ml-6" name="stats" />
    </div>
  </Tooltip>
);

export default StatsButton;
