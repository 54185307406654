export const customStyles = {
  control: (styles: any) => ({
    ...styles,
    borderRadius: 2,
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    height: 'inherit',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#2b2b2b',
    height: 'unset',
    fontSize: '16px',
    padding: 0,
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#00a551',
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    backgroundColor: '#00a551',
    ':hover': {
      backgroundColor: '#61d077',
      color: 'white',
    },
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    paddingRight: 13,
  }),
  option: (styles: any) => ({
    ...styles,
    color: '#ffffff',
    cursor: 'pointer',
    backgroundColor: '#454545',
    ':hover': {
      background: '#2b2b2b',
    },
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '8px 12px 8px 10px',
    fontSize: '16px',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '16px',
    color: '#2b2b2b',
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: '#454545',
    zIndex: '200',
  }),
  menuList: (styles: any) => ({
    ...styles,
    backgroundColor: '#454545',
    borderRadius: '2px',
  }),
};

export const sbOptionStyle = {
  color: '#ffffff',
  padding: '8px 12px 8px 10px',
  cursor: 'pointer',
  background: '#454545',
  ':hover': {
    background: '#2b2b2b',
  },
  fontSize: '12px',
};

export const sbMarketStyles = {
  ...customStyles,
  control: () => ({
    height: 25,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    backgroundColor: '#454545',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#fff',
    fontSize: '12px',
  }),
  valueContainer: (styles: any) => ({
    ...styles,
  }),
  option: (styles: any) => ({
    ...styles,
    ...sbOptionStyle,
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    paddingRight: 10,
  }),
};

export const languageStyles = {
  ...customStyles,
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '2px 0',
    justifyContent: 'flex-start',
  }),
  control: (styles: any) => ({
    ...styles,
    height: '30px',
    minHeight: 'unset',
    paddingRight: 'unset',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#ffffff',
    fontSize: '10px',
  }),
  indicatorsContainer: (styles: any) => ({
    padding: 0,
  }),
  option: (styles: any) => ({
    ...styles,
    ...sbOptionStyle,
    fontSize: '10px',
    padding: '8px 12px 8px 2px',
  }),
  menu: (styles: any) => ({
    ...styles,
    top: '20px',
  }),
};
