import React from 'react';

import { Button } from 'components';

import './GamesListHeader.scss';
import { useTranslation } from 'react-i18next';

interface GamesListHeaderProps {
  hasNavigation?: boolean;
  title: string;
  onClick: () => void;
  nextHandler: () => void;
  previousHandler: () => void;
  back?: (() => void) | null;
}

const GamesListHeader = ({
  hasNavigation,
  title,
  onClick,
  back,
}: GamesListHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="games-header">
      <div className={`games-header__title`}>
        <span className="text-green-800">{title}</span>
      </div>
      <div className="games-header__separator"></div>
      <div className="ml-auto">
        {hasNavigation && (
          <>
            <div className="games-header__actions">
              <Button
                testId="all"
                size="medium"
                bg="ultra-black"
                className="text-extra-strong"
                onClick={onClick}
              >
                <span>{t('casino.see-all')}</span>
              </Button>
            </div>
          </>
        )}
        {!!back && (
          <div className="games-header__actions">
            <Button
              testId="back"
              bg="ultra-black"
              size="medium"
              className="text-extra-strong"
              onClick={back}
            >
              {t('casino.back')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

GamesListHeader.defaultProps = {
  hasNavigation: true,
  isMobile: false,
  nextHandler: () => null,
  previousHandler: () => null,
  onClick: () => null,
};

export { GamesListHeader };
