export const offerRoutes = [
  { label: 'navigation.betting', route: '/sport', icon: 'mobileSports' },
  { label: 'navigation.casino', route: '/gambling', icon: 'mobileCasino' },
  {
    label: 'navigation.liveCasino',
    route: '/gambling-live',
    icon: 'mobileCasinoLive',
  },
  {
    label: 'navigation.quickGames',
    route: '/quick-games',
    icon: 'mobileNumbers',
  },
  { label: 'navigation.aviator', route: '/aviator' },
  {
    label: 'navigation.poker',
    route: 'https://html2.theonlypoker.com/#/login',
    externalLink: true,
  },
  {
    label: 'navigation.promotions',
    route: 'https://promo.sbbet.me/',
    externalLink: true,
  },
];

export const mobileFooterRoutes = [
  { labelKey: 'navigation.betting', route: '/sport', icon: 'mobileSports' },
  { labelKey: 'navigation.casino', route: '/gambling', icon: 'mobileCasino' },
  {
    labelKey: 'navigation.casino',
    route: '/gambling-live',
    icon: 'mobileCasinoLive',
  },
  {
    labelKey: 'navigation.quickGames',
    route: '/quick-games',
    icon: 'mobileNumbers',
  },
];
