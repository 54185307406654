/* React modules */

/* Our modules */
import { BonusType, BonusTypes } from 'modules/wallet/wallet.types';
import useStores from 'common/hooks/useStores';
import { Button } from 'components';
import { logger } from 'libs/common-helpers';
import './BonusActivation.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const BonusActivation = () => {
  const { t } = useTranslation();
  const { userStore, overlayStore } = useStores();
  const { user } = userStore;
  const { eligible_bonus_amount } = user || {};

  const selectBonus = async (type: BonusType) => {
    try {
      const success = await userStore.selectBonus(type);

      if (success) {
        overlayStore.closeModal();
      }
    } catch (exception: any) {
      toast.error(t('errors.system-error'));
      overlayStore.closeModal();

      logger('BonusActivation -> selectBonus -> exception', exception);
    }
  };

  return (
    <div className="user-bonus">
      <div className="user-bonus__header">
        {t('wallet.promo-bonus').toUpperCase()}
      </div>

      <div className="user-bonus__body py-6 d-flex flex-column align-items">
        <p>
          {t('wallet.activate-bonus-info', { bonus: eligible_bonus_amount })}
        </p>

        <div className="mt-10 d-flex space-around">
          <Button
            bg="success"
            className="mr-4"
            onClick={() => selectBonus(BonusTypes.FREE_BET)}
          >
            FREE BET BONUS
          </Button>

          <Button bg="success" onClick={() => selectBonus(BonusTypes.CASINO)}>
            CASINO BONUS
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(BonusActivation);
