import React, { ReactNode } from 'react';
import i18n from 'i18next';

import { Icon } from 'components';

interface WrapperHeaderProps {
  label: string;
  icon: 'calendarMobile' | 'animatedLiveGreen';
}

export const OfferWrapperHeader = ({ label, icon }: WrapperHeaderProps) => (
  <div className="offer-mobile__wrapper-header">
    <div className="mr-2">{i18n.t('sportsPage.' + label)}</div>
    <Icon
      size="small"
      name={icon}
      variant={icon === 'animatedLiveGreen' ? 'sbgreen' : 'warning'}
    />
  </div>
);

interface OfferWrapperProps extends WrapperHeaderProps {
  children: ReactNode;
}

const OfferMobileWrapper = ({ icon, label, children }: OfferWrapperProps) => (
  <div className="offer-mobile__wrapper">
    <OfferWrapperHeader icon={icon} label={label} />
    <div className="offer-mobile__wrapper-body">{children}</div>
  </div>
);

export default OfferMobileWrapper;
