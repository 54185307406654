/* React modules */
import { useState } from 'react';

/* Our modules */
import SectionHeader from 'modules/user/ui/SectionHeader';
import CreditDebitFilter from 'modules/user/ui/UserCreditDebit/CreditDebitFilter';
import OfficeCredit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Credit/OfficeCredit';
import BankCredit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Credit/BankCredit';
import 'modules/user/ui/UserCreditDebit/CreditDebitBody/CreditDebitBody.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

const Credit = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t('payments.in-the-office'), value: 0 },
    { label: t('payments.in-the-bank'), value: 1 },
  ];

  const [activeTab, setActiveTab] = useState<number>(0);

  const onSelect = (tabId: number) => {
    setActiveTab(tabId);
  };

  return (
    <div className="user-credit-debit">
      <SectionHeader title="ISPLATA" />
      <div className="user-credit-debit__header">
        <CreditDebitFilter tabs={tabs} onTabSelected={onSelect} />
      </div>
      <div className="user-credit-debit__body w-100">
        {activeTab === 0 && <OfficeCredit />}
        {activeTab === 1 && <BankCredit />}
      </div>
    </div>
  );
};

export default Credit;
