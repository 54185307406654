/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { SportMarketGroup } from 'modules/sports/services/sports-markets.service';
import Carousel from 'components/Carousel';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const MarketGroupFilters = observer(() => {
  const { i18n } = useTranslation();
  const { offerEvent } = useStores();

  const isLive = offerEvent.event?.type === 'live' ? true : false;
  // const disabledMarketGroups = [10000, 11000, 33000];
  const disabledMarketGroups = [666666];

  const marketGroups = offerEvent.marketGroups.filter((mg: any) => {
    if (!offerEvent.hasOdds(mg)) return false;

    if (isLive) return true;

    return !isLive && !disabledMarketGroups.includes(mg.id);
  });

  useEffect(() => {
    offerEvent.setGroupsFilter(marketGroups[0]);
  }, []);

  const setActiveFilter = (index: number) => {
    offerEvent.setGroupsFilter(marketGroups[index]);
  };

  const sliderConfigClosed = {
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    afterChange: setActiveFilter,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="w-100">
      <div className="all-odds-overlay__filters">
        <div className="all-odds-overlay__filters__options mx-auto">
          <Carousel sliderConfig={sliderConfigClosed}>
            {marketGroups.map((marketGroup: SportMarketGroup) => (
              <div
                key={`MarketGroupFilters-div-${marketGroup.id}`}
                onClick={() => offerEvent.setGroupsFilter(marketGroup)}
                className={classnames('all-odds-overlay__choice choice-width', {
                  active: offerEvent.marketGroupsFilter?.id === marketGroup.id,
                })}
              >
                {marketGroup.getName(i18n.language)}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
});

export default MarketGroupFilters;
