import { ReactNode } from 'react';
import { makeAutoObservable } from 'mobx';

class OverlayStore {
  modalProps: any;
  modalContent: (() => ReactNode) | null = null;
  drawerProps: any;
  drawerContent: (() => ReactNode) | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  openModal = (content: ReactNode, props: any) => {
    this.modalProps = props;
    this.modalContent = () => content;
  };

  closeModal = () => {
    if (
      this.modalProps?.onClose &&
      typeof this.modalProps?.onClose === 'function'
    ) {
      this.modalProps.onClose();
    }

    this.modalProps = {};
    this.modalContent = null;
  };

  openDrawer = (content: ReactNode, props: any) => {
    this.drawerProps = props;
    this.drawerContent = () => content;
  };

  closeDrawer = () => {
    this.drawerProps = {};
    this.drawerContent = null;
  };
}

export default new OverlayStore();
