/* React modules */

/* Our modules */
import './LeaderboardModal.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

interface LeaderboardModalProps {
  leaderboard: any;
}

const LeaderboardModal = (leaderboard: LeaderboardModalProps) => {
  const { t } = useTranslation();

  const { top, user_position } = leaderboard.leaderboard;

  let user: any = null;

  top.find((player: any) => {
    if (player.username === leaderboard?.leaderboard?.user_position?.username) {
      user = leaderboard.leaderboard.user_position;
    }
  });

  return (
    <div className="leaderboard-popup">
      <p className="leaderboard-popup__title">LEADERBOARD</p>
      <div className="leaderboard-popup__body">
        <ul className="leaderboard-popup__list">
          <li className="leaderboard-popup__list__item">
            <div className="text-center text-ellipsis px-2">
              {t('casino.user')}
            </div>
            <div className="text-center text-ellipsis px-2">
              {t('casino.points')}
            </div>
            <div className="text-center text-ellipsis px-2">
              {t('casino.award')}
            </div>
          </li>

          {top &&
            top.map((player: any, index: number) => (
              <li
                key={`LeaderboardModal-li-${index}`}
                className={
                  user && player.username === user?.username
                    ? 'leaderboard-popup__list__user'
                    : 'leaderboard-popup__list__item'
                }
              >
                <div className="text-center text-ellipsis px-2 ">
                  <span className="text-extra-strong text-white">
                    {player.pos}. &nbsp; &nbsp;
                  </span>
                  {user_position && user_position.username === player.username
                    ? user_position.id
                    : player.id}
                </div>

                <div className="text-center text-ellipsis px-2">
                  {player.points}
                </div>
                <div className="text-center text-ellipsis px-2">
                  {player.award}
                </div>
              </li>
            ))}

          {user_position && user_position?.pos > top.length && (
            <>
              <li className="d-flex align-items space-around py-6">
                <div>&bull;</div>
                <div>&bull;</div>
                <div>&bull;</div>
              </li>
              <li className="leaderboard-popup__list__last-item">
                <div className="text-center text-ellipsis px-2">
                  <span className="text-extra-strong">
                    {user_position.pos}. &nbsp;
                  </span>
                  {user_position.id}
                </div>
                <div className="text-center text-ellipsis px-2">
                  {user_position.points}
                </div>
                {/* PLACEHOLDER */}
                <div className="text-center text-ellipsis px-2">-</div>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LeaderboardModal;
