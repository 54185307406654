/* React modules */

/* Our modules */
import { Transaction } from 'modules/user/user.models';
import UserApi from 'modules/user/user.api';
import { SelectOption } from 'components/Select/Select';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable, observable } from 'mobx';
import { toast } from 'react-toastify';

const PAGINATION_LIMIT = 30;

const initialFilters: any = {
  transaction_type: null,
  from_date: null,
  to_date: null,
};

class TransactionFilters {
  api: UserApi;
  $selected: Array<SelectOption | undefined> = [];
  transactions: Array<Transaction> = [];
  $filteredData: Array<Transaction> = [];

  filters: any = initialFilters;

  page = 0;
  hasNext = false;
  totalCount = 0;

  constructor(api: UserApi) {
    this.api = api;

    makeAutoObservable(
      this,
      {
        transactions: observable,
        filters: observable,
      },
      { deep: false }
    );
  }

  getFilterParams = () => {
    return Object.keys(this.filters).reduce((query: any, filter: string) => {
      if (this.filters[filter]) {
        query[filter] = this.filters[filter];
      }

      return query;
    }, {});
  };

  getPaginationParams = () => {
    return {
      limit: PAGINATION_LIMIT,
      offset: this.page * PAGINATION_LIMIT,
    };
  };

  reset = () => {
    this.transactions = [];
    this.$filteredData = [];
    this.filters = initialFilters;
    this.page = 0;
    this.hasNext = false;
  };

  setTotalCount = (count: number) => {
    this.totalCount = count;
  };

  getTransactions = async () => {
    const queryParams = {
      ...this.getFilterParams(),
      ...this.getPaginationParams(),
    };

    if (queryParams.from_date && queryParams.to_date) {
      queryParams.from_date = `${queryParams.from_date} 00:00:00`;
      queryParams.to_date = `${queryParams.to_date} 23:59:59`;
    }

    try {
      const { data } = await this.api.getTransactions(queryParams);

      if (data) {
        const { items, total } = data;

        this.setTransactions(items);
        this.setTotalCount(total);

        this.hasNext = this.transactions.length < this.totalCount;
      }
    } catch (exception: any) {
      toast.error('Error while loading transactions. Please refresh the page.');

      logger('TransactionFilters -> getTransactions -> exception', exception);
    }
  };

  setFilter = (filter: any) => {
    this.filters = {
      ...this.filters,
      ...filter,
    };
    this.page = 0;
    this.transactions = [];
    this.getTransactions();
  };

  setTransactions = (transactions: Array<Transaction>) => {
    this.transactions = [...this.transactions, ...transactions];
  };

  loadNextPage = async () => {
    if (this.hasNext) {
      this.page += 1;
      await this.getTransactions();
    }
  };

  get selected() {
    return this.$selected;
  }

  formatSelectedValues = (): Array<number | undefined> => {
    return this.$selected ? this.$selected.map((e: any) => e.value) : [];
  };
}

export default TransactionFilters;
