import classNames from 'classnames';

import { Icon } from 'components';
import './Wallet.scss';
import { useTranslation } from 'react-i18next';
import { WalletTypes } from 'modules/wallet/wallet.types';

export interface WalletProps {
  data: any;
  inline: boolean;
}

const Wallet = ({ data, inline }: WalletProps) => {
  const { t } = useTranslation();

  const {
    type,
    available,
    amountToUnlock,
    locked,
    currency,
    freeBetValidTo,
    promoBetValidTo,
  } = data;

  const getWalletName = () => {
    switch (type) {
      case WalletTypes.STANDARD:
        return t('wallet.standard-bill');
      case WalletTypes.RESERVED_FUNDS:
        return t('wallet.reserved-funds');
      case WalletTypes.PROMO:
        return t('wallet.free-bet');
      case WalletTypes.FREE_BET_BONUS:
        return t('wallet.promo-to-unlock');
      case WalletTypes.CASINO_BONUS:
        return t('wallet.casino-to-unlock');
      case WalletTypes.FREE_CASINO:
        return t('wallet.free-spin');
      case WalletTypes.FREE_CASINO_TO_UNLOCK:
        return t('wallet.free-spin-to-unlock');
      case WalletTypes.PROMO_BET_BONUS:
        return t('wallet.free-bet-bonus');
      case WalletTypes.PROMO_BET_BONUS_TO_UNLOCK:
        return t('wallet.free-bet-bonus-to-unlock');
    }
  };

  const parseAvailableAmount = (amount: number) => {
    const rawAmount = String(amount);
    const firstPart = rawAmount.split('.')[0];
    const secondPart = rawAmount.split('.')[1];

    if (!secondPart) return firstPart;

    if (secondPart && secondPart.length > 2) {
      return `${firstPart}.${secondPart.substr(0, 2)}`;
    }

    return `${firstPart}.${secondPart}`;
  };

  const renderBalance = () => {
    return (
      <div>
        <div className={classNames('wallet__amount', { inline })}>
          {parseAvailableAmount(available)} {currency}
          {locked && (
            <Icon
              className="ml-2"
              tooltip={getUnlockMessage()}
              name="lock"
              size="extra-small"
            />
          )}
        </div>
      </div>
    );
  };

  const getUnlockMessage = () => {
    if (type === WalletTypes.FREE_BET_BONUS) {
      return t('wallet.free-bet-bonus-info', { amountToUnlock });
    } else if (type === WalletTypes.CASINO_BONUS) {
      return t('wallet.casino-bonus-info', { amountToUnlock });
    } else if (type === WalletTypes.FREE_CASINO) {
      return t('wallet.free-spin', { amountToUnlock });
    } else if (type === WalletTypes.FREE_CASINO_TO_UNLOCK) {
      return t('wallet.free-spin-unlock-message', { amountToUnlock });
    } else if (type === WalletTypes.PROMO_BET_BONUS) {
      return t('wallet.free-bet-bonus', { amountToUnlock });
    } else if (type === WalletTypes.PROMO_BET_BONUS_TO_UNLOCK) {
      return t('wallet.free-bet-bonus-unlock-message', { amountToUnlock });
    }

    return t('wallet.wallet-locked');
  };

  return (
    <>
      <div className={classNames('wallet', { locked, inline })}>
        <div
          className={classNames('d-flex', 'text-small', { 'mb-2': !inline })}
        >
          <p className="balance-label">{getWalletName()}</p>

          {inline && freeBetValidTo && type === WalletTypes.PROMO && (
            <Icon
              className="ml-1"
              variant="sbgreen-800"
              name="info"
              size="extra-small"
              tooltip={t('wallet.valid-to', { validTo: freeBetValidTo })}
            />
          )}

          {inline &&
            promoBetValidTo &&
            type === WalletTypes.PROMO_BET_BONUS && (
              <Icon
                className="ml-1"
                variant="sbgreen-800"
                name="info"
                size="extra-small"
                tooltip={t('wallet.valid-to', { validTo: promoBetValidTo })}
              />
            )}
        </div>

        <div className="text-center">{renderBalance()}</div>

        {amountToUnlock && !inline && (
          <span className="text-yellow text-extra-small">
            {getUnlockMessage()}
          </span>
        )}
      </div>

      {!inline && freeBetValidTo && type === WalletTypes.PROMO && (
        <div className="d-flex justify-end text-sb-extra-small">
          {t('wallet.valid-to', { validTo: freeBetValidTo })}
        </div>
      )}

      {!inline && promoBetValidTo && type === WalletTypes.PROMO_BET_BONUS && (
        <div className="d-flex justify-end text-sb-extra-small">
          {t('wallet.valid-to', { validTo: promoBetValidTo })}
        </div>
      )}
    </>
  );
};

Wallet.defaultProps = {
  inline: false,
};

export default Wallet;
