/**
 * @fileoverview gRPC-Web generated client stub for cashdrop
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_loyalty_cashdrop_pb from '../../proto/loyalty/cashdrop_pb';


export class CashDropBOServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetCashDropGroups = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.DropGroups,
    (request: proto_loyalty_cashdrop_pb.GetDropGroups) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.DropGroups.deserializeBinary
  );

  getCashDropGroups(
    request: proto_loyalty_cashdrop_pb.GetDropGroups,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.DropGroups>;

  getCashDropGroups(
    request: proto_loyalty_cashdrop_pb.GetDropGroups,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.DropGroups) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.DropGroups>;

  getCashDropGroups(
    request: proto_loyalty_cashdrop_pb.GetDropGroups,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.DropGroups) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/GetCashDropGroups',
        request,
        metadata || {},
        this.methodInfoGetCashDropGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/GetCashDropGroups',
    request,
    metadata || {},
    this.methodInfoGetCashDropGroups);
  }

  methodInfoCreateCashDropGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.DropGroup,
    (request: proto_loyalty_cashdrop_pb.DropGroup) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.DropGroup.deserializeBinary
  );

  createCashDropGroup(
    request: proto_loyalty_cashdrop_pb.DropGroup,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.DropGroup>;

  createCashDropGroup(
    request: proto_loyalty_cashdrop_pb.DropGroup,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.DropGroup) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.DropGroup>;

  createCashDropGroup(
    request: proto_loyalty_cashdrop_pb.DropGroup,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.DropGroup) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/CreateCashDropGroup',
        request,
        metadata || {},
        this.methodInfoCreateCashDropGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/CreateCashDropGroup',
    request,
    metadata || {},
    this.methodInfoCreateCashDropGroup);
  }

  methodInfoEditCashDropStart = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.Drops,
    (request: proto_loyalty_cashdrop_pb.Drops) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.Drops.deserializeBinary
  );

  editCashDropStart(
    request: proto_loyalty_cashdrop_pb.Drops,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.Drops>;

  editCashDropStart(
    request: proto_loyalty_cashdrop_pb.Drops,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.Drops) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.Drops>;

  editCashDropStart(
    request: proto_loyalty_cashdrop_pb.Drops,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.Drops) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/EditCashDropStart',
        request,
        metadata || {},
        this.methodInfoEditCashDropStart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/EditCashDropStart',
    request,
    metadata || {},
    this.methodInfoEditCashDropStart);
  }

  methodInfoDisableCashDrop = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.Drop,
    (request: proto_loyalty_cashdrop_pb.Drop) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.Drop.deserializeBinary
  );

  disableCashDrop(
    request: proto_loyalty_cashdrop_pb.Drop,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.Drop>;

  disableCashDrop(
    request: proto_loyalty_cashdrop_pb.Drop,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.Drop) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.Drop>;

  disableCashDrop(
    request: proto_loyalty_cashdrop_pb.Drop,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.Drop) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/DisableCashDrop',
        request,
        metadata || {},
        this.methodInfoDisableCashDrop,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/DisableCashDrop',
    request,
    metadata || {},
    this.methodInfoDisableCashDrop);
  }

  methodInfoDisableCashDropGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.DropGroup,
    (request: proto_loyalty_cashdrop_pb.DropGroup) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.DropGroup.deserializeBinary
  );

  disableCashDropGroup(
    request: proto_loyalty_cashdrop_pb.DropGroup,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.DropGroup>;

  disableCashDropGroup(
    request: proto_loyalty_cashdrop_pb.DropGroup,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.DropGroup) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.DropGroup>;

  disableCashDropGroup(
    request: proto_loyalty_cashdrop_pb.DropGroup,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.DropGroup) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/DisableCashDropGroup',
        request,
        metadata || {},
        this.methodInfoDisableCashDropGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/DisableCashDropGroup',
    request,
    metadata || {},
    this.methodInfoDisableCashDropGroup);
  }

  methodInfoGetCashDropDisabledBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.CashDropDisabled,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.CashDropDisabled.deserializeBinary
  );

  getCashDropDisabledBO(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.CashDropDisabled>;

  getCashDropDisabledBO(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.CashDropDisabled) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.CashDropDisabled>;

  getCashDropDisabledBO(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.CashDropDisabled) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/GetCashDropDisabledBO',
        request,
        metadata || {},
        this.methodInfoGetCashDropDisabledBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/GetCashDropDisabledBO',
    request,
    metadata || {},
    this.methodInfoGetCashDropDisabledBO);
  }

  methodInfoSetCashDropDisabled = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.CashDropDisabled,
    (request: proto_loyalty_cashdrop_pb.CashDropDisabled) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.CashDropDisabled.deserializeBinary
  );

  setCashDropDisabled(
    request: proto_loyalty_cashdrop_pb.CashDropDisabled,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.CashDropDisabled>;

  setCashDropDisabled(
    request: proto_loyalty_cashdrop_pb.CashDropDisabled,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.CashDropDisabled) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.CashDropDisabled>;

  setCashDropDisabled(
    request: proto_loyalty_cashdrop_pb.CashDropDisabled,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.CashDropDisabled) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropBOService/SetCashDropDisabled',
        request,
        metadata || {},
        this.methodInfoSetCashDropDisabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropBOService/SetCashDropDisabled',
    request,
    metadata || {},
    this.methodInfoSetCashDropDisabled);
  }

}

export class CashDropWebServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetDailyDrops = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.WebDrops,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.WebDrops.deserializeBinary
  );

  getDailyDrops(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.WebDrops>;

  getDailyDrops(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.WebDrops) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.WebDrops>;

  getDailyDrops(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.WebDrops) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropWebService/GetDailyDrops',
        request,
        metadata || {},
        this.methodInfoGetDailyDrops,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropWebService/GetDailyDrops',
    request,
    metadata || {},
    this.methodInfoGetDailyDrops);
  }

  methodInfoCollect = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.Drop,
    (request: proto_loyalty_cashdrop_pb.Drop) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.Drop.deserializeBinary
  );

  collect(
    request: proto_loyalty_cashdrop_pb.Drop,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.Drop>;

  collect(
    request: proto_loyalty_cashdrop_pb.Drop,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.Drop) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.Drop>;

  collect(
    request: proto_loyalty_cashdrop_pb.Drop,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.Drop) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropWebService/Collect',
        request,
        metadata || {},
        this.methodInfoCollect,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropWebService/Collect',
    request,
    metadata || {},
    this.methodInfoCollect);
  }

  methodInfoGetCashDropGlobalDisabled = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_loyalty_cashdrop_pb.CashDropDisabled,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_loyalty_cashdrop_pb.CashDropDisabled.deserializeBinary
  );

  getCashDropGlobalDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_loyalty_cashdrop_pb.CashDropDisabled>;

  getCashDropGlobalDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.CashDropDisabled) => void): grpcWeb.ClientReadableStream<proto_loyalty_cashdrop_pb.CashDropDisabled>;

  getCashDropGlobalDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_loyalty_cashdrop_pb.CashDropDisabled) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cashdrop.CashDropWebService/GetCashDropGlobalDisabled',
        request,
        metadata || {},
        this.methodInfoGetCashDropGlobalDisabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cashdrop.CashDropWebService/GetCashDropGlobalDisabled',
    request,
    metadata || {},
    this.methodInfoGetCashDropGlobalDisabled);
  }

}

