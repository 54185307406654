/* React modules */

/* Our modules */
import { Checkbox } from 'components';
import Icon from 'components/Icon';
import Pill from 'components/Pill';
import { IconName } from 'components/Icon/Icon';
import { PillSize, PillType } from 'components/Pill/Pill';
import './AccordionHeader.scss';

/* 3rd Party modules */

export interface BasicHeaderProps {
  isActive: boolean;
  testId: string;
  toggleAccordion: () => void;
}

export interface AccordionHeaderProps extends BasicHeaderProps {
  bg?: string;
  size?: string;
  label: string;
  count?: number;
  hasPill?: boolean;
  pillVariant: PillType;
  pillSize: PillSize;
  icon?: IconName;
  onClick?: () => void;
  className?: string;
  hasCheckbox?: boolean;
  onCheck?: (e: any) => void;
  checkboxActive?: boolean;
}

const AccordionHeader = ({
  bg,
  size,
  toggleAccordion,
  label,
  count,
  isActive,
  testId,
  hasPill,
  pillVariant,
  pillSize,
  icon,
  onClick,
  className,
  hasCheckbox,
  onCheck,
  checkboxActive,
}: AccordionHeaderProps) => (
  <div
    className={`noselect accordion__header ${className} bg-${bg} ${size} ${
      isActive ? `active-${className}` : ''
    }`}
    onClick={onClick || toggleAccordion}
    data-testid={`${testId}-header`}
  >
    {icon && <Icon className="mr-4" size="large" name={icon} />}
    <div
      className="title"
      style={{ marginBottom: icon ? '2px' : 0 }}
      title={label}
    >
      {label}
    </div>
    {hasCheckbox && (
      <Checkbox className="mr-1" value={checkboxActive} onChange={onCheck} />
    )}
    {hasPill ? (
      <Pill size={pillSize} type={pillVariant}>
        {count}
      </Pill>
    ) : null}
    <div
      className="pl-2 accordion__header__icon"
      onClick={
        onCheck || onClick
          ? (e: any) => {
              e.stopPropagation();
              toggleAccordion();
            }
          : () => null
      }
    >
      <Icon size="extra-small" name={isActive ? 'caretUp' : 'caretDown'} />
    </div>
  </div>
);

AccordionHeader.defaultProps = {
  bg: 'green',
  size: 'regular',
  hasPill: true,
  pillVariant: 'success',
  pillSize: 'large',
  className: 'default',
  hasCheckbox: false,
  onCheck: (e: any) => null,
  checkboxActive: false,
};

export default AccordionHeader;
